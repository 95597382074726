import { ShopComponent } from './shop/shop.component';
import { TemplateSetComponent } from './template-set/template-set.component';

export const containers: any[] = [
    ShopComponent,
    TemplateSetComponent
];

export * from './shop/shop.component';
export * from './template-set/template-set.component';
