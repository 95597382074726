/* eslint-disable @typescript-eslint/no-explicit-any */
import * as fromActions from '../actions';
import { StartingPageRO } from '../../models';

export interface StartingPageState {
    loading: boolean;
    response: StartingPageRO;
    error: string;
}

export const initialState: StartingPageState = {
    loading: false,
    response: null,
    error: null
};

export function reducer(state = initialState, action: fromActions.StartingPageAction): StartingPageState {
    switch (action.type) {
        case fromActions.STARTING_PAGE_GET: {
            return {
                ...state,
                loading: true,
                response: null,
                error: null
            };
        }

        case fromActions.STARTING_PAGE_GET_SUCCESS: {
            const { payload } = action as any;

            return {
                ...state,
                loading: false,
                response: payload
            };
        }

        case fromActions.STARTING_PAGE_GET_FAIL: {
            const { payload } = action as any;

            return {
                ...state,
                loading: false,
                error: payload
            };
        }

        case fromActions.STARTING_PAGE_RESET_DASHBOARD: {
            const { payload } = action as any;

            return {
                ...state,
                response: {
                    ...state.response,
                    dashboard: payload
                }
            };
        }

        case fromActions.STARTING_PAGE_GET_RESET: {
            return {
                ...state,
                ...initialState
            };
        }
    }
    return state;
}

export const getStartingPageGetLoading = (state: StartingPageState) => state.loading;
export const getStartingPageGetLoaded = (state: StartingPageState) => state.response;
export const getStartingPageGetError = (state: StartingPageState) => state.error;
export const getStartingPageTabs = (state: StartingPageState) =>
    state.response && state.response.meta ? state.response.meta.tabs : [];
