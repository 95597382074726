import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import { DashboardPatient } from '@alii-web/models/dashboard-patient.model';
import { DashboardPatientActions, DashboardPatientActionTypes } from './../actions/dashboard-patient.actions';
import { PatientOption } from '@alii-web/models/dashboard-patient-option.model';
import { Protocol } from '@alii-web/models/protocol.model';

export interface DashboardPatienState extends EntityState<DashboardPatient> {
    entities: Dictionary<any>;
    options: PatientOption[];
    protocols: Protocol[];
}

export const adapter: EntityAdapter<DashboardPatient> = createEntityAdapter<DashboardPatient>();

export const initialState: DashboardPatienState = adapter.getInitialState({
    options: null,
    protocols: null
});

export function reducer(state = initialState, action: DashboardPatientActions): DashboardPatienState {
    switch (action.type) {
        case DashboardPatientActionTypes.AddDashboardPatient: {
            return adapter.addOne(action.payload.dashboardPatient, state);
        }

        case DashboardPatientActionTypes.UpdateDashboardPatient: {
            return adapter.updateOne(action.payload.dashboardPatient, state);
        }

        case DashboardPatientActionTypes.DeleteDashboardPatient: {
            return adapter.removeOne(action.payload.id, state);
        }

        case DashboardPatientActionTypes.LoadDashboardPatientOptionsSuccess: {
            const { payload: patientOptions } = action as any;

            return {
                ...state,
                options: patientOptions
            };
        }

        case DashboardPatientActionTypes.LoadDashboardPatientProtocolsSuccess: {
            const { payload } = action as any;

            return {
                ...state,
                protocols: payload
            };
        }

        case DashboardPatientActionTypes.LoadDashboardPatients: {
            return adapter.addAll(action.payload.dashboardPatients, state);
        }

        case DashboardPatientActionTypes.ClearDashboardPatients: {
            return adapter.removeAll(state);
        }

        default: {
            return state;
        }
    }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getPatientOptions = (state: DashboardPatienState) => state.options;
export const getPatientProtocols = (state: DashboardPatienState) => state.protocols;
