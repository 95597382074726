import * as fromTerms from '../actions/terms.action';
import { TermsGetRO } from '@alii-web/models/terms.interface';

export interface TermsState {
    get: {
        loaded: TermsGetRO;
        loading: boolean;
        error: string;
    };
    accept: {
        loaded: boolean;
        loading: boolean;
        error: string;
    };
}

export const initialState: TermsState = {
    get: {
        loaded: null,
        loading: false,
        error: null
    },
    accept: {
        loaded: false,
        loading: false,
        error: null
    }
};

export function reducer(state = initialState, action: fromTerms.TermsAction): TermsState {
    switch (action.type) {
        case fromTerms.TERMS_GET: {
            return {
                ...state,
                get: {
                    loaded: null,
                    loading: true,
                    error: null
                }
            };
        }

        case fromTerms.TERMS_GET_SUCCESS: {
            return {
                ...state,
                get: {
                    loaded: action.payload,
                    loading: false,
                    error: null
                }
            };
        }

        case fromTerms.TERMS_GET_FAIL: {
            const {
                payload: { message }
            } = action as any;

            return {
                ...state,
                get: {
                    loaded: null,
                    loading: false,
                    error: message
                }
            };
        }

        case fromTerms.TERMS_ACCEPT: {
            return {
                ...state,
                accept: {
                    loaded: false,
                    loading: true,
                    error: null
                }
            };
        }

        case fromTerms.TERMS_ACCEPT_SUCCESS: {
            return {
                ...state,
                accept: {
                    loaded: true,
                    loading: false,
                    error: null
                }
            };
        }

        case fromTerms.TERMS_ACCEPT_FAIL: {
            const {
                payload: { message }
            } = action as any;

            return {
                ...state,
                accept: {
                    loaded: false,
                    loading: false,
                    error: message
                }
            };
        }
    }
    return state;
}

export const getTermsGetLoading = (state: TermsState) => state.get.loading;
export const getTermsGetLoaded = (state: TermsState) => state.get.loaded;
export const getTermsGetError = (state: TermsState) => state.get.error;
export const getTermsAcceptLoading = (state: TermsState) => state.accept.loading;
export const getTermsAcceptLoaded = (state: TermsState) => state.accept.loaded;
export const getTermsAcceptError = (state: TermsState) => state.accept.error;
