import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

// Translation stuff, see: https://github.com/ngx-translate/core
import { TranslateModule } from '@ngx-translate/core';

import { reducers, effects } from './store';
import * as fromContainers from './containers';
import * as fromServices from '../../services';
import { LoginRoutingModule } from './login-routing.module';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('LoginFeature', reducers),
        EffectsModule.forFeature(effects),
        FormsModule,
        LoginRoutingModule,
        ReactiveFormsModule,
        TranslateModule.forChild({ extend: true })
    ],
    providers: [...fromServices.services],
    declarations: [...fromContainers.containers],
    exports: [...fromContainers.containers]
})
export class LoginModule {}
