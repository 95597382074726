import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromSearch from '../../services/search/search.service';
import * as SearchActions from '../../store/actions/search.action';

@Injectable()
export class SearchEffects {
    constructor(private actions$: Actions, private searchService: fromSearch.SearchService) {}

    @Effect()
    SearchGetEffect$ = this.actions$.pipe(
        ofType(SearchActions.SEARCH_GET),
        switchMap(action => {
            const { payload } = action as any;
            return this.searchService.getSearch(payload).pipe(
                map(response => {
                    return new SearchActions.SearchGetSucces(response);
                }),
                catchError(() => {
                    const message = 'Get search failed';
                    return of(new SearchActions.SearchGetFail(message));
                })
            );
        })
    );

}
