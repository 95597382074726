<div class="rule-group-container">
    <div *ngFor="let question of ruleGroup.questionsAndAnswers | keyvalue; let q = index">
        <div class="rule-row">
            <div class="question">
                {{ question.key }}
            </div>
            {{ ruleGroup.isExcluded ? '=X' : '=' }}
            <div class="answer-options">
                <div class="" *ngFor="let answer of question.value">
                    {{ answer }}
                </div>
            </div>
        </div>

        <div *ngIf="shouldDisplayQuestionOperator(q)" class="rule-row">
            <div class="question"></div>
                {{ ruleGroup.operator }}
            <div class="answer-options"></div>
        </div>
    </div>

    <div *ngFor="let nestedRuleGroup of ruleGroup.ruleGroups; let r = index">
        <alii-outcome-rule-group [ruleGroup]="nestedRuleGroup"></alii-outcome-rule-group>

        <div *ngIf="shouldDisplayRuleGroupOperator(r)" class="rule-row">
            <div class="question"></div>
                {{ ruleGroup.operator }}
            <div class="answer-options"></div>
        </div>
    </div>
</div>