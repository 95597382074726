import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-finding-add-modal',
    templateUrl: './model-sheet-update-modal.component.html'
})
export class ModelSheetUpdateModalComponent implements OnInit {
    ppdId: string;
    sheetId: string;
    action: string;
    form: FormGroup;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            sheetId: [this.sheetId, Validators.required],
            ppdId: [this.ppdId, Validators.required],
            action: [this.action, Validators.required]
        });
    }
    handleSubmit() {
        if (this.form.valid) {
            const data = {
                ...this.form.value
            };
            this.activeModal.close(data);
        }
    }
}
