import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Language } from '../../models/language.model';
import { LanguageService } from '../../services';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-language-switcher',
    templateUrl: './language-switcher.component.html',
    styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit, OnDestroy {
    supportedLanguages: Language[];
    currentLanguage: Language;
    tooltips: string[];

    private subscriptions: Subscription[] = [];

    constructor(
        private languageService: LanguageService,
        private translate: TranslateService,
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.tooltips = [];
        this.supportedLanguages = this.languageService.supportedLanguages();
        this.subscriptions.push(
            this.languageService.languageChanged.subscribe(language => (this.currentLanguage = language))
        );
        setTimeout(() => {
            this.supportedLanguages.forEach(language => {
                this.translate.get(language.toUpperCase()).subscribe(value => this.tooltips.push(value));
            });
            this.cdr.markForCheck();
        }, 1000);
    }

    switchLanguage(event: MouseEvent, language: string) {
        if (this.currentLanguage.toUpperCase() !== language.toUpperCase()) {
            this.languageService.set(language.toLowerCase() as Language);
            this.translate.get('LANGUAGE.SWITCH.' + language.toUpperCase()).subscribe(key => {
                this.toastr.success(key);
            });
        }
        event.stopPropagation();
        return false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }
}
