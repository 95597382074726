import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { convertTypeTagToCategory, SearchPage, SearchResult, SearchRO, SearchType } from '../../models';
import * as fromStore from '../../store';
import { convertSelect, navigateToPage } from '../../lib';

// Data grid service
import { SearchDataGridService } from './search-data-grid.service';
import { SearchSortableHeaderDirective, SortEvent } from './search-sortable-header.directive';

const cn = 'SearchComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren(SearchSortableHeaderDirective) headers: QueryList<SearchSortableHeaderDirective>;

    searchGetSuccess$: Observable<SearchRO>;
    searchGetLoading$: Observable<boolean>;
    searchGetFail$: Observable<string>;

    showColumns = [
        'title',
        'type'
        // 'id',
        // 'protocol',
        // 'anchorId',
        // 'protocolId',
        // 'ppdId'
    ];

    showTypes: SearchType[] = [
        'all',
        'protocol',
        // 'in this protocol',
        'paragraph',
        'category',
        'file'
        // 'tag',
        // 'go to',
        // 'outcome'
    ];

    allResults: SearchRO = [];
    filteredResults: SearchRO = [];
    pagedResults: SearchRO = [];
    query = '';
    selectText: boolean;

    // Paging
    totalSize = 0;
    searchPage: SearchPage;

    navigatingTo: boolean;

    subscriptions: Subscription[] = [];

    sortColumn: string;

    constructor(
        private router: Router,
        private store: Store<fromStore.SearchFeatureState>,
        private cdr: ChangeDetectorRef,
        public dataGridService: SearchDataGridService
    ) {}

    ngOnInit() {

        this.navigatingTo = false;
        this.searchGetSuccess$ = this.store
            .select(fromStore.getSearchGetSuccess)
            .pipe(tap(results => this._handleResults(results)));
        this.searchGetLoading$ = this.store.select(fromStore.getSearchGetLoading);
        this.searchGetFail$ = this.store.select(fromStore.getSearchGetFail);

        this.subscriptions.push(
            this.store
                .select(fromStore.getSearchGetSearchPage)
                .subscribe(searchPage => this._handleSearchPage(searchPage))
        );

        // setTimeout(() => {
        //     // Sort by title by default
        //     const th = document.getElementById('th-title') as HTMLTableHeaderCellElement;
        //     if (th) {
        //         th.click();
        //     }
        // }, 1000);
    }

    ngAfterViewInit() {

        this.navigatingTo = false;
        this.subscriptions.push(
            this.store.select(fromStore.getSearchGetQuery).subscribe(query => {
                this.query = query || '';
                if (this.query !== '') {
                    let searchDTO = {q: this.query, protocolId: ''}
                    this.store.dispatch(new fromStore.SearchGet(searchDTO));
                    this.selectText = true;
                    setTimeout(() => {
                        this.cdr.markForCheck();
                    }, 0);
                }
            })
        );

        this._handleSearchPage(this.searchPage);

        // Reset other headers
        this.headers.forEach(header => {
            if (header.sortable !== this.searchPage.sortColumn) {
                header.reset();
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    onFocus() {}

    onBlur() {}

    onSort({ sortColumn, sortDirection }: SortEvent) {
        this.store.dispatch(new fromStore.SearchPageSet({ sortEvent: { sortColumn, sortDirection } }));
    }

    onSelect(searchResult: SearchResult): void {
        const searchBoxMatch = convertSelect(searchResult);
        if (navigateToPage(searchBoxMatch, this.router)) {
            if (searchBoxMatch.anchorId) {
                this.store.dispatch(new fromStore.SearchSetAnchorId(searchBoxMatch.anchorId));
            }
            this.navigatingTo = true;
            this.store.dispatch(new fromStore.SearchPageSet({ selected: +searchResult['index'] }));
        }
    }

    changePageSize(event: Event) {
        // index: value
        const value = (event.target as HTMLSelectElement).value;
        const m = value.match(/^(\d+): (\d+)$/);
        if (m && m[2]) {
            this.store.dispatch(new fromStore.SearchPageSet({ page: 1, pageSize: +m[2] }));
        } else {
            console.warn(`${cn} changePageSize() invalid value='${value}'`);
        }
    }

    changePage(page: number) {
        this.store.dispatch(new fromStore.SearchPageSet({ page }));
    }

    changeTypeFilter(type: SearchType) {
        this.store.dispatch(new fromStore.SearchPageSet({ type }));
    }

    private _handleSearchPage(searchPage: SearchPage) {
        this.searchPage = searchPage;
        this._filterResults();
        this._paginateResults();
        this._sortResults();
    }

    private _filterResults() {
        if (this.searchPage.type === 'all') {
            this.filteredResults = this.allResults;
        } else {
            if (this.searchPage.type === 'paragraph') {
                this.filteredResults = this.allResults.filter(r => ['outcome', 'paragraph'].includes(r.type));
            } else {
                this.filteredResults = this.allResults.filter(r => r.type === this.searchPage.type);
            }
        }

        if (this.filteredResults && this.filteredResults.length) {
            this._paginateResults();
        } else {
            this.cdr.markForCheck();
        }
        this.dataGridService.init(this.filteredResults);
    }

    private _paginateResults() {
        const sp = this.searchPage;
        this.pagedResults = this.filteredResults.slice(
            (sp.page - 1) * sp.pageSize,
            (sp.page - 1) * sp.pageSize + sp.pageSize
        );
        this.cdr.markForCheck();
    }

    private _sortResults() {
        this.dataGridService.searchPage = this.searchPage;
        this.sortColumn = this.searchPage.sortColumn;
    }

    private _handleResults(results: SearchRO) {
        results = results || [];
        this.allResults = results.map((result, i) => ({ index: i + 1, ...result }));
        this.allResults = convertTypeTagToCategory(this.allResults);
        this.totalSize = results.length;
        this._filterResults();
    }

    trackByFn = (index, item) => item.id || index;
}
