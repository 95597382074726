import { Action } from '@ngrx/store';

export const PROT_LOAD_DASHBOARD_CATEGORIES = '[Protocol] Load Dashboard Categories';
export const PROT_LOAD_DASHBOARD_CATEGORIES_FAIL = '[Protocol] Load Dashboard Categories Fail';
export const PROT_LOAD_DASHBOARD_CATEGORIES_SUCCESS = '[Protocol] Load Dashboard Categories Success';

interface DashboardCategory {
    id?: string;
    title?: string;
}

export class LoadDashboardCategories implements Action {
    readonly type = PROT_LOAD_DASHBOARD_CATEGORIES;
}

export class LoadDashboardCategoriesSucces implements Action {
    readonly type = PROT_LOAD_DASHBOARD_CATEGORIES_SUCCESS;
    constructor(public payload: { categories: DashboardCategory[] }) {}
}

export class LoadDashboardCategoriesFail implements Action {
    readonly type = PROT_LOAD_DASHBOARD_CATEGORIES_FAIL;
    constructor(public payload: any) {}
}

export type DashboardCategoryAction =
    | LoadDashboardCategories
    | LoadDashboardCategoriesFail
    | LoadDashboardCategoriesSucces;
