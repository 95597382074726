import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as paragraphsActions from '../actions/paragraphs.action';
import * as paragraphsFilesActions from '../actions/paragraphs-files.action';
import * as paragraphGradeActions from '../actions/grade-assessment.action';
import * as protocolActions from '../actions/protocols.action';
import * as parReferencesActions from '../actions/paragraphs-references.action';
import * as fromService from '../../../../services';

import * as modelFindingActions from '../actions/models-findings.action';
import * as modelOutcomeActions from '../actions/models-outcomes.action';
import * as gradeActions from '../actions/grade-assessment.action';
import * as eventbusChannelActions from '../actions/eventbus-channel.action';

@Injectable()
export class ParagraphsEffects {
    constructor(private actions$: Actions,
        private ParagraphsService: fromService.ParagraphsService,
        private CommentService: fromService.CommentService) {}


    @Effect()
    loadKeyQuestionImportEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.LOAD_KEYQUESTION_IMPORT),
        switchMap(action => {
            const { payload } = action as any;

            return this.ParagraphsService.getListOfImports(payload).pipe(
                map(data => new paragraphsActions.LoadKeyQuestionImportSuccess(data)),
                catchError(error => of(new paragraphsActions.LoadKeyQuestionImportFail(error)))
            );
        })
    );


    @Effect()
    AddParagraphEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.ADD_PARAGRAPH),
        switchMap(action => {
            const {
                payload: { protocolId, parentId = null, options }
            } = action as any;
            if (options.type === "chapter") {
                return this.ParagraphsService.addParagraph({ options, protocolId, parentId }).pipe(
                    map(paragraph => new paragraphsActions.AddChapterSuccess({ parentId, paragraph })),
                    catchError(error => of(new paragraphsActions.AddParagraphFail(error)))
                );
            }
            return this.ParagraphsService.addParagraph({ options, protocolId, parentId }).pipe(
                map(paragraph => new paragraphsActions.AddParagraphSucces({ parentId, paragraph })),
                catchError(error => of(new paragraphsActions.AddParagraphFail(error)))
            );
        })
    );

    @Effect()
    AddParagraphSuccessEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.ADD_CHAPTER_SUCCESS, paragraphsActions.IMPORT_MODEL_BY_SHEET_ID_SUCCESS),
        mergeMap(action => {
            const {
                payload: { parentId, paragraph }
            } = action as any;
            const nextActions = [];
            nextActions.push(new protocolActions.StoreAddParagraph({ parentId, paragraph }));
            nextActions.push(new paragraphsFilesActions.AddNewParagraphFilesEntries({ paragraph }));
            nextActions.push(new paragraphGradeActions.AddNewGradeEntries({ paragraph }));

            if ('questions' in paragraph) {
                nextActions.push(new modelFindingActions.AddFindingSucces({
                    parentId: paragraph.id,
                    findings: paragraph.questions
                }));
            }

            return nextActions;
        })
    );

    @Effect()
    ImportElementToSummaryEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.IMPORT_ELEMENT_TO_SUMMARY),
        switchMap(action => {
            const {
                payload: { protocolId, parentId = null, copyFromPpdId, type }
            } = action as any;

            return this.ParagraphsService.addParagraph({
                options: { copyFromPpdId, type },
                protocolId,
                parentId
            }).pipe(
                map(
                    paragraph =>
                        new paragraphsActions.ImportElementToSummarySucces({
                            parentId,
                            paragraph
                        })
                ),
                catchError(error => of(new paragraphsActions.ImportElementToSummaryFail(error)))
            );
        })
    );

    @Effect()
    ImportElementToSummarySuccessEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.IMPORT_ELEMENT_TO_SUMMARY_SUCCESS),
        mergeMap(action => {
            const {
                payload: { parentId, paragraph }
            } = action as any;
            return [
                new modelFindingActions.AddFindingSucces({
                    parentId,
                    findings: {
                        [paragraph.id]: {
                            ...paragraph,
                            options: paragraph.options ? paragraph.options : {}
                        }
                    }
                })
            ];
        })
    );

    @Effect()
    UpdateParagraphEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.UPDATE_PARAGRAPH),
        switchMap(action => {
            const {
                payload: { protocolId, ppdId, parentId, options }
            } = action as any;

            return this.ParagraphsService.updateParagraph({ protocolId, ppdId, options }).pipe(
                map(paragraph => new paragraphsActions.UpdateParagraphSucces({ parentId, paragraph })),
                catchError(error => of(new paragraphsActions.UpdateParagraphFail(error)))
            );
        })
    );

    @Effect()
    loadEvidenceEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.LOAD_EVIDENCE),
        mergeMap(action => {
            const {
                payload: {ppdId, protocolId, parentId }
                } = action as any;
            return this.ParagraphsService.loadEvidence({ ppdId, protocolId}).pipe(
                map(
                    response => new protocolActions.StoreEvidence({evidence: response.evidence, ppdId, protocolId, parentId}),
                    catchError(error => of(new paragraphsActions.LoadEvidenceFail(error)))
            )
            );
        })
    );

    @Effect()
    storeEvidenceEffect$ = this.actions$.pipe(
        ofType(protocolActions.STORE_EVIDENCE),
        map(action => {
            const { payload: {ppdId, evidence } } = action as any;
                return new gradeActions.LoadGradesSucces({ ppdId, questions: evidence.questions, gradeRowData: evidence.gradeRowData, gradeColumnDefs: evidence.gradeColumnDefs })
            })
        );


    @Effect()
    UpdateParPositionEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.UPDATE_PAR_POSITION),
        switchMap(action => {
            const {
                payload: { protocolId, ppdId, parentId, position }
            } = action as any;
            return this.ParagraphsService.updateParagraph({
                protocolId,
                ppdId,
                options: { parentId, position }
            }).pipe(
                map(
                    () =>
                        new paragraphsActions.UpdateParPositionSucces({
                            protocolId
                        })
                ),
                catchError(error => of(new paragraphsActions.UpdateParPositionFail(error)))
            );
        })
    );

    @Effect()
    UpdateParagraphToModelEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.UPDATE_PARAGRAPH_TO_MODEL),
        switchMap(action => {
            const {
                payload: { protocolId, ppdId, parentId }
            } = action as any;

            return this.ParagraphsService.updateParagraphToModel({ protocolId, ppdId }).pipe(
                map(paragraph => new paragraphsActions.UpdateParagraphToModelSucces({ parentId, paragraph })),
                catchError(error => of(new paragraphsActions.UpdateParagraphToModelFail(error)))
            );
        })
    );

    @Effect()
    DeleteParagraphEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.DELETE_PARAGRAPH),
        switchMap(action => {
            const {
                payload: { protocolId, ppdId, parentId }
            } = action as any;
            return this.ParagraphsService.deleteParagraph({ protocolId, ppdId }).pipe(
                map(response => {
                    if(response?.paragraphs) {
                        let paragraph = response?.paragraphs[0]
                        return new paragraphsActions.DeleteParagraphSucces({ ppdId, parentId, paragraph  })
                    } else {
                        return new paragraphsActions.DeleteParagraphSucces({ ppdId, parentId})
                    }}),
                catchError(error => of(new paragraphsActions.DeleteParagraphFail(error))
                )
        )}
    ));

    @Effect()
    DeleteParagraphSuccessEffect$ = this.actions$.pipe(

        ofType(paragraphsActions.DELETE_PARAGRAPH_SUCCESS),
        mergeMap(action => {
            const {
                payload: { ppdId, parentId, paragraph }
            } = action as any;
            if(paragraph) {
                return [new protocolActions.StoreUpdateParagraph({ paragraph })];
            } else {
                return [new protocolActions.StoreDeleteParagraph({ ppdId, parentId })];
            }

        })
    );

    @Effect()
    DeleteParagraphFailEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.DELETE_PARAGRAPH_FAIL),
        mergeMap(action => {
            const {
                payload: { error }
            } = action as any;
            return [];
        })
    );

    @Effect()
    AddNonPubmedReferencesEffect$ = this.actions$.pipe(
        ofType(parReferencesActions.ADD_NON_PUBMED_REFERENCES),
        switchMap(action => {
            const { payload } = action as any;
            const { protocolId, ppdId, parentId, url, anchorText, author, year, title } = payload;
            return this.ParagraphsService.addReferenceNonPubmed({
                protocolId,
                ppdId,
                url,
                anchorText,
                author,
                year,
                title
            }).pipe(
                map(
                    response => new protocolActions.StoreEvidence({evidence: response.evidence, ppdId, protocolId, parentId})),
//                    paragraph => new parReferencesActions.AddNonPubmedReferenceSuccess({ parentId, paragraph })),
                    catchError(error => of(new parReferencesActions.AddNonPubmedReferenceFail(error)))
            );
        })
    );

    @Effect()
    AddReferencesEffect$ = this.actions$.pipe(
        ofType(parReferencesActions.ADD_REFERENCES_TO_PARAGRAPH),
        switchMap(action => {
            const {
                payload: { protocolId, ppdId, parentId, articleIds, findingIds }
            } = action as any;
            return this.ParagraphsService.addReference({ protocolId, ppdId, articleIds, findingIds, parentId }).pipe(

                map(
                    response => new protocolActions.StoreEvidence({evidence: response.evidence, ppdId, protocolId, parentId}),

                    // paragraph =>
                    //     new parReferencesActions.AddReferenceToParagraphSuccess({
                    //         parentId,
                    //         paragraph: paragraph[0]
                    //     })
                ),
                catchError(error => of(new parReferencesActions.AddReferenceToParagraphFail(error)))
            );
        })
    );


    @Effect()
    RemoveReferencesEffect$ = this.actions$.pipe(
        ofType(parReferencesActions.REMOVE_REFERENCES_FROM_PARAGRAPH),
        switchMap(action => {
            const {
                payload: { ppdId, parentId, findingIds }
            } = action as any;
            return this.ParagraphsService.removeReference({ ppdId, findingIds, action }).pipe(
                map(
                    response => new protocolActions.StoreEvidence({evidence: response.evidence, ppdId, parentId}),

                    // paragraph =>
                    //     new parReferencesActions.RemoveReferenceFromParagraphSuccess({
                    //         parentId,
                    //         paragraph: paragraph[0]
                    //     })
                ),
                catchError(error => of(new parReferencesActions.RemoveReferenceFromParagraphFail(error)))
            );
        })
    );

    @Effect()
    EditReferencesEffect$ = this.actions$.pipe(
        ofType(parReferencesActions.EDIT_REFERENCE_FOR_PARAGRAPH),
        switchMap(action => {
            const {
                payload: { reviewId, ppdId, field, value }
            } = action as any;
            return this.ParagraphsService.editReference({reviewId, ppdId, field, value, action}).pipe(
                map(
                    response => new protocolActions.StoreEvidence({evidence: response.evidence, ppdId}),
                ),
                catchError(error => of(new parReferencesActions.EditReferenceForParagraphFail(error)))
            );
        })
    );

    @Effect()
    AddCommentEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.ADD_COMMENT),
        switchMap(action => {
            const {
                payload: { text, ppdId, parentId, comment, status }
            } = action as any;

            return this.CommentService.createComment({ text, ppdId, comment, status }).pipe(
                map(response => new paragraphsActions.AddCommentSuccess({ paragraph: response.paragraphs[0], parentId})),
                catchError(error => of(new paragraphsActions.AddCommentFail(error)))
            );
        })
    );


    @Effect()
    ImportModelBySheetIdEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.IMPORT_MODEL_BY_SHEET_ID),
        switchMap(action => {
            const {
                payload: { protocolId, parentId, sheetId }
            } = action as any;

            return this.ParagraphsService.importModelBySheetId({ protocolId, parentId, sheetId }).pipe(
                map(paragraph => new paragraphsActions.ImportModelBySheetIdSucces({ parentId, paragraph })),
                catchError(error => of(new paragraphsActions.ImportModelBySheetIdFail(error)))
            );
        })
    );

    @Effect()
    UpdateModelBySheetIdEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.UPDATE_MODEL_BY_SHEET_ID),
        switchMap(action => {
            const {
                payload: { protocolId, parentId, ppdId, sheetId, action: actionName }
            } = action as any;

            return this.ParagraphsService.updateModelBySheetId({ protocolId, ppdId, sheetId, action: actionName }).pipe(
                map(paragraph => new paragraphsActions.UpdateModelBySheetIdSucces({ parentId, paragraph })),
                catchError(error => of(new paragraphsActions.UpdateModelBySheetIdFail(error)))
            );
        })
    );

    @Effect()
    LinkToProtocolEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.LINK_TO_PROTOCOL),
        switchMap(action => {
            const {
                payload: { protocolId, ppdId, connectProtocolId }
            } = action as any;

            return this.ParagraphsService.linkToProtocol({ protocolId, ppdId, connectProtocolId }).pipe(
                map(paragraph => new paragraphsActions.LinkToProtocolSucces({ paragraph })),
                catchError(error => of(new paragraphsActions.LinkToProtocolFail(error)))
            );
        })
    );

    @Effect()
    RemoveLinkToProtocolEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.REMOVE_LINK_TO_PROTOCOL),
        switchMap(action => {
            const {
                payload: { paragraphId, ppdId }
            } = action as any;

            return this.ParagraphsService.removeLinkToProtocol({ paragraphId, ppdId }).pipe(
                map(paragraph => new paragraphsActions.RemoveLinkToProtocolSuccess({ paragraph, ppdId })),
                catchError(error => of(new paragraphsActions.RemoveLinkToProtocolFail(error)))
            );
        })
    );

    @Effect()
    CreateCalculationFormulaEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.CREATE_CALCULATION_FORMULA),
        switchMap(action => {
            const {
                payload: { parentId, ppdId }
            } = action as any;

            return this.ParagraphsService.createCalculationFormula({ ppdId }).pipe(
                map(paragraph => new paragraphsActions.CreateCalculationFormulaSuccess({ parentId, paragraph })),
                catchError(error => of(new paragraphsActions.CreateCalculationFormulaFail(error)))
            );
        })
    );

    @Effect()
    ModelUpdateBySheetSuccessEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.UPDATE_MODEL_BY_SHEET_ID_SUCCESS),
        mergeMap(action => {
            const {
                payload: { paragraph }
            } = action as any;

            return [
                new modelFindingActions.AddFindingSucces({
                    parentId: paragraph.id,
                    findings: paragraph.questions
                }),
                new modelOutcomeActions.AddOutcomeSucces({
                    parentId: paragraph.id,
                    outcomes: paragraph.outcomes
                }),
                new gradeActions.AddNewModelGradeEntries({ paragraph })
            ];
        })
    );

    @Effect()
    UpdateParagraphSuccessEffect$ = this.actions$.pipe(
        ofType(
            paragraphsActions.UPDATE_PARAGRAPH_SUCCESS,
            paragraphsActions.ADD_PARAGRAPH_SUCCESS,
            paragraphsActions.UPDATE_PARAGRAPH_TO_MODEL_SUCCESS,
            paragraphsActions.UPDATE_MODEL_BY_SHEET_ID_SUCCESS,
            paragraphsActions.LINK_TO_PROTOCOL_SUCCESS,
            paragraphsActions.REMOVE_LINK_TO_PROTOCOL_SUCCESS,
            paragraphsActions.CREATE_CALCULATION_FORMULA_SUCCESS,
            paragraphsActions.ADD_COMMENT_SUCCESS,

        ),
        mergeMap(action => {
            const {
                payload: { parentId, paragraph }
            } = action as any;
            if(paragraph.id == parentId || parentId === 0) {
                return [new protocolActions.StoreUpdateParagraph({ parentId: null, paragraph })];
            } else {
                return [new protocolActions.StoreUpdateParagraph({ parentId, paragraph })];
            }


        })
    );
    @Effect()
    ModelSuccessEffect$ = this.actions$.pipe(
        ofType(paragraphsActions.DONE_EDIT_SORT),
        mergeMap(action => {
            const {
                payload: { protocolId }
            } = action as any;
            return [new protocolActions.LoadProtocol({ protocolId, versionId: null, version: 'Draft' })];
        })
    );
}
