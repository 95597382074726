import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';

import * as fromActions from '../actions/protocol-articles.action';
import * as protocolActions from '../actions/protocols.action';

import * as fromService from '../../../../services';

import { of } from 'rxjs';

@Injectable()
export class ProtocolArticlesEffects {
    constructor(private actions$: Actions, private articleService: fromService.ArticleService) {}

    @Effect()
    loadProtocolArticle$ = this.actions$.pipe(
        ofType(fromActions.LOAD_PROTOCOL_ARTICLE),
        switchMap(action => {
            const {
                payload: { protocolId, articleId }
            } = action as any;
            return this.articleService.getArticle({ protocolId, articleId }).pipe(
                map(article => new fromActions.LoadProtocolArticleSucces({ article })),
                catchError(error => of(new fromActions.LoadProtocolArticleFail(error)))
            );
        })
    );

    @Effect()
    AddProtocolArticle$ = this.actions$.pipe(
        ofType(fromActions.ADD_PROTOCOL_ARTICLE),
        switchMap(action => {
            const {
                payload: { protocolId, ppdId, parentId, articleId, action: actionType, sourceId }
            } = action as any;
            return this.articleService.addReview({ protocolId, ppdId, articleId, action: actionType, sourceId }).pipe(
                map(paragraph => new fromActions.AddProtocolArticleSucces({ parentId, paragraph })),
                catchError(error => of(new fromActions.AddProtocolArticleFail(error)))
            );
        })
    );
    @Effect()
    RemoveProtocolArticle$ = this.actions$.pipe(
        ofType(fromActions.REMOVE_PROTOCOL_ARTICLE),
        switchMap(action => {
            const {
                payload: { protocolId, ppdId, parentId, articleId, action: actionType, sourceId }
            } = action as any;
            return this.articleService.addReview({ protocolId, ppdId, articleId, action: actionType, sourceId }).pipe(
                map(paragraph => new fromActions.AddProtocolArticleSucces({ parentId, paragraph })),
                catchError(error => of(new fromActions.AddProtocolArticleFail(error)))
            );
        })
    );
    @Effect()
    UpdateParagraphSuccessEffect$ = this.actions$.pipe(
        ofType(fromActions.ADD_PROTOCOL_ARTICLE_SUCCESS, fromActions.REMOVE_PROTOCOL_ARTICLE_SUCCESS),
        mergeMap(action => {
            const {
                payload: { parentId, paragraph }
            } = action as any;

            return [new protocolActions.StoreUpdateParagraph({ parentId, paragraph })];
        })
    );
}
