import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as pubmedsAction from '../actions/pubmed.actions';
import * as fromService from '../../../../services';

@Injectable()
export class PubmedEffects {
    constructor(private actions$: Actions, private PubmedService: fromService.PubmedService) {}

    @Effect()
    loadPubmedEffect$ = this.actions$.pipe(
        ofType(pubmedsAction.PubmedActionTypes.LoadPubmeds),
        switchMap(action => {
            const {
                payload: { query }
            } = action as any;
            return this.PubmedService.search(query).pipe(
                map(pubmeds => new pubmedsAction.LoadPubmedsSucces({ pubmeds })),
                catchError(error => of(new pubmedsAction.LoadPubmedsFail(error)))
            );
        })
    );
}
