import { createSelector } from '@ngrx/store';

import * as fromIndexReducers from '../reducers';
import * as fromReducer from '../reducers/dashboard-categories.reducer';

const getDashboardCategoriesState = createSelector(
    fromIndexReducers.getProtocolsFeatureState,
    (state: fromIndexReducers.ProtocolsFeatureState) => state.dashboardCategories
);
export const getDashboardCategories = createSelector(
    getDashboardCategoriesState,
    fromReducer.getDashboardCategoriesEntry
);
export const getDashboardCategoriesLoading = createSelector(
    getDashboardCategoriesState,
    fromReducer.getDashboardCategoriesLoading
);
