<div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="handleCancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="form" [formGroup]="form">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-8">
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label"></label>
                        <div class="col-sm-10">
                            <div class="form-check form-check-inline">
                                <input
                                    formControlName="cardContentType"
                                    class="form-check-input"
                                    type="radio"
                                    id="inlineCheckbox2"
                                    value="paragraphContent"
                                />
                                <label class="form-check-label" for="inlineCheckbox2">existing paragraph</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    formControlName="cardContentType"
                                    class="form-check-input"
                                    type="radio"
                                    id="inlineCheckbox3"
                                    value="protocolContent"
                                />
                                <label class="form-check-label" for="inlineCheckbox3">existing protocol</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-sm text-center"><h5>Short Arrow</h5></div>
                        <div class="col-sm text-center"><h5>Invert arrow</h5></div>
                        <div class="col-sm text-center"><h5>Border-less</h5></div>
                        <div class="col-sm text-center"><h5>Hide steps</h5></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8" *ngIf="form.get('cardContentType').value === 'newContent'">
                    <div class="form-group row">
                        <label for="card-title-10" class="col-sm-2 col-form-label">Title</label>
                        <div class="col-sm-10">
                            <input
                                type="text"
                                id="card-title-10"
                                class="form-control"
                                formControlName="title"
                                placeholder="Title"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-8" *ngIf="form.get('cardContentType').value === 'paragraphContent'">
                    <div class="form-group row">
                        <label for="ppdId" class="col-sm-2 col-form-label">Content</label>
                        <div class="col-sm-10">
                            <select
                                class="form-control"
                                formControlName="ppdId"
                                id="ppdId"
                                (change)="onChangeContentParagraph($event)"
                            >
                                <option value="">replace with a paragraph from protocol</option>
                                <option
                                    *ngFor="let par of contentOptions.paragraphs; trackBy: trackByFn"
                                    [value]="par.id"
                                >
                                    {{ par['protocol_paragraph.title'] }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="card-title-11" class="col-sm-2 col-form-label">Title</label>
                        <div class="col-sm-10">
                            <input
                                type="text"
                                id="card-title-11"
                                class="form-control"
                                formControlName="title"
                                placeholder="Title"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-8" *ngIf="form.get('cardContentType').value === 'protocolContent'">
                    <div class="form-group row">
                        <label for="contentLink" class="col-sm-2 col-form-label">Content</label>
                        <div class="col-sm-10">
                            <select
                                class="form-control"
                                formControlName="contentLink"
                                id="contentLink"
                                (change)="onChangeContentLink($event)"
                            >
                                <option value="">replace with a link to an existing protocol</option>

                                <option
                                    *ngFor="let protocol of contentOptions.protocols; trackBy: trackByFn"
                                    [value]="protocol.id"
                                >
                                    {{ protocol.title }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="card-title-12" class="col-sm-2 col-form-label">Title</label>
                        <div class="col-sm-10">
                            <input
                                type="text"
                                id="card-title-12"
                                class="form-control"
                                formControlName="title"
                                placeholder="Title"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 ">
                    <div class="row">
                        <div class="col-sm text-center">
                            <div class="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    formControlName="shortRightArrow"
                                    id="shortRightArrow"
                                />
                                <label class="custom-control-label" for="shortRightArrow"></label>
                            </div>
                        </div>

                        <div class="col-sm text-center">
                            <div class="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    formControlName="invertArrows"
                                    id="invertArrows"
                                />
                                <label class="custom-control-label" for="invertArrows"></label>
                            </div>
                        </div>
                        <div class="col-sm text-center">
                            <div class="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    formControlName="borderless"
                                    id="borderless"
                                />
                                <label class="custom-control-label" for="borderless"></label>
                            </div>
                        </div>
                        <div class="col-sm text-center">
                            <div class="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    formControlName="hideSteps"
                                    id="hideSteps"
                                />
                                <label class="custom-control-label" for="hideSteps"></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-100">
                <hr />
            </div>
            <div class="row">
                <div
                    class="col-sm-3 h-100 text-center"
                    (click)="onClickToggleBlockType('decision', 2, false)"
                >
                    <p class="decision_title all-titles">Decision Block</p>
                    <input type="radio" class="invisible" id="decision" />
                    <label class="label-item" for="decision">
                        <img
                            [ngClass]="{ shadow: blockType === 'decision' }"
                            class="img-fluid img-thumbnail decisionImage"
                            [style.width]="imgThumbnailWidth"
                            src="/assets/images/Decision_block.png"
                            alt="Decision"
                        />
                    </label>
                </div>

                <div class="col-sm-3 text-center" (click)="onClickToggleBlockType('step', 1,
                false)">
                    <p class="step_title all-titles">Step Block</p>
                    <input type="radio" class="invisible" id="step" />
                    <label class="label-item" for="step">
                        <img
                            [ngClass]="{ shadow: blockType === 'step' }"
                            class="img-fluid img-thumbnail stepImage"
                            [style.width]="imgThumbnailWidth"
                            src="/assets/images/Step.png"
                            alt="Step"
                        />
                    </label>
                </div>

                <div class="col-sm-3 text-center" (click)="onClickToggleBlockType('endpoint', 0,
                false)">
                    <p class="end_title all-titles">End Block</p>
                    <input type="radio" class="invisible" id="endpoint" />
                    <label class="label-item" for="endpoint">
                        <img
                            [ngClass]="{ shadow: blockType === 'endpoint' }"
                            class="img-fluid img-thumbnail endImage"
                            [style.width]="imgThumbnailWidth"
                            src="/assets/images/Endpoint.png"
                            alt="Endpoint"
                        />
                    </label>
                </div>

                <div class="col-sm-3 text-center" (click)="onClickToggleBlockType('parallel', 2,
                true)">
                    <p class="parallel_title all-titles">Parallel Block</p>

                    <input type="radio" class="invisible" id="parallel" />
                    <label class="label-item" for="parallel">
                        <img
                            [ngClass]="{ shadow: blockType === 'parallel' }"
                            class="img-fluid img-thumbnail parallelImage"
                            src="/assets/images/Parallel_block.png"
                            [style.width]="imgThumbnailWidth"
                            alt="Parallel Block"
                        />
                    </label>
                </div>
            </div>
            <ng-container *ngIf="countOfArrow !== 0">
                <div class="w-100">
                    <hr />
                </div>
                <ng-container *ngIf="countOfArrow > 0">
                    <div class="row">
                        <div class="col-md-8"><h5>Arrow 1</h5></div>
                        <div class="col-md-4 ">
                            <div class="row">
                                <div class="col-sm text-center"><h5>Hide steps</h5></div>
                                <div class="col-sm text-center"><h5>New block</h5></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-10">
                                    <div class="form-check form-check-inline">
                                        <input
                                            formControlName="yesArrowContentType"
                                            class="form-check-input"
                                            type="radio"
                                            id="yesArrowCheckbox1"
                                            value="newContent"
                                        />
                                        <label class="form-check-label" for="yesArrowCheckbox1">new block</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            formControlName="yesArrowContentType"
                                            class="form-check-input"
                                            type="radio"
                                            id="yesArrowCheckbox2"
                                            value="paragraphContent"
                                        />
                                        <label class="form-check-label" for="yesArrowCheckbox2"
                                        >existing paragraph</label
                                        >
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            formControlName="yesArrowContentType"
                                            class="form-check-input"
                                            type="radio"
                                            id="yesArrowCheckbox3"
                                            value="protocolContent"
                                        />
                                        <label class="form-check-label" for="yesArrowCheckbox3"
                                        >existing protocol</label
                                        >
                                    </div>
                                </div>
                            </div>
                            <div
                                class="form-group row"
                                *ngIf="form.get('yesArrowContentType').value === 'paragraphContent'"
                            >
                                <label class="col-sm-2 col-form-label">Content</label>
                                <div class="col-sm-10">
                                    <label for="yes-arrow-id"></label>
                                    <select
                                        id="yes-arrow-id"
                                        class="form-control"
                                        formControlName="yesArrowId"
                                        (change)="onChangeYesArrow($event)"
                                    >
                                        <optgroup label="link to a existing block">
                                            <option
                                                *ngFor="let card of contentOptions.cards; trackBy: trackByFn"
                                                [value]="card.id"
                                            >
                                                {{ card.title }} ({{ card.id }})
                                            </option
                                            >
                                        </optgroup>
                                        <optgroup label="link to a paragraph in the protocol">
                                            <option
                                                *ngFor="let par of contentOptions.paragraphs; trackBy: trackByFn"
                                                [value]="par.id"
                                            >
                                                {{ par['protocol_paragraph.title'] }} ({{ par.id }})
                                            </option
                                            >
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                            <div
                                class="form-group row"
                                *ngIf="form.get('yesArrowContentType').value === 'protocolContent'"
                            >
                                <label class="col-sm-2 col-form-label">Content Link</label>
                                <div class="col-sm-10">
                                    <label for="yes-content-link"></label>
                                    <select
                                        id="yes-content-link"
                                        class="form-control"
                                        formControlName="yesContentLink"
                                        (change)="onChangeYesArrow($event)"
                                    >
                                        <option value="">link to an existing protocol</option>
                                        <option
                                            *ngFor="let protocol of contentOptions.protocols; trackBy: trackByFn"
                                            [value]="protocol.id"
                                        >
                                            {{ protocol.title }}</option
                                        >
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-2 pt-4">
                                    <label>Title</label>
                                </div>
                                <div class="col-sm-10">
                                    <label for="yes-arrow-title"></label>
                                    <input
                                        id="yes-arrow-title"
                                        type="text"
                                        class="form-control"
                                        formControlName="yesArrowTitle"
                                        placeholder="Content"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="row">
                                <div class="col-sm text-center">
                                    <div class="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            formControlName="yesArrowNoSteps"
                                            id="yesArrowNoSteps"
                                        />
                                        <label class="custom-control-label" for="yesArrowNoSteps"></label>
                                    </div>
                                </div>
                                <div class="col-sm text-center">
                                    <div class="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            formControlName="yesArrowNewBlock"
                                            id="yesArrowNewBlock"
                                        />
                                        <label class="custom-control-label" for="yesArrowNewBlock"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="countOfArrow === 2">
                    <div class="row">
                        <div class="col-md-8"><h5>Arrow 2</h5></div>
                        <div class="col-md-4 ">
                            <div class="row">
                                <div class="col-sm text-center"><h5>Hide steps</h5></div>
                                <div class="col-sm text-center"><h5>New block</h5></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-10">
                                    <div class="form-check form-check-inline">
                                        <input
                                            formControlName="noArrowContentType"
                                            class="form-check-input"
                                            type="radio"
                                            id="noArrowinlineCheckbox1"
                                            value="newContent"
                                        />
                                        <label class="form-check-label" for="noArrowinlineCheckbox1">new block</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            formControlName="noArrowContentType"
                                            class="form-check-input"
                                            type="radio"
                                            id="noArrowinlineCheckbox2"
                                            value="paragraphContent"
                                        />
                                        <label class="form-check-label" for="noArrowinlineCheckbox2"
                                        >existing paragraph</label
                                        >
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            formControlName="noArrowContentType"
                                            class="form-check-input"
                                            type="radio"
                                            id="noArrowinlineCheckbox3"
                                            value="protocolContent"
                                        />
                                        <label class="form-check-label" for="noArrowinlineCheckbox3"
                                        >existing protocol</label
                                        >
                                    </div>
                                </div>
                            </div>
                            <div
                                class="form-group row"
                                *ngIf="form.get('noArrowContentType').value === 'paragraphContent'"
                            >
                                <label class="col-sm-2 col-form-label">Content</label>
                                <div class="col-sm-10">
                                    <label for="no-arrow-id"></label>
                                    <select
                                        id="no-arrow-id"
                                        class="form-control"
                                        formControlName="noArrowId"
                                        (change)="onChangeNoArrow($event)"
                                    >
                                        <optgroup label="link to a existing block">
                                            <option *ngFor="let card of contentOptions.cards" [value]="card.id">
                                                {{ card.title }} ({{card.id}})
                                            </option
                                            >
                                        </optgroup>
                                        <optgroup label="link to a paragraph in the protocol">
                                            <option
                                                *ngFor="let par of contentOptions.paragraphs; trackBy: trackByFn"
                                                [value]="par.id"
                                            >
                                                {{ par['protocol_paragraph.title'] }} ({{ par.id }})
                                            </option
                                            >
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                            <div
                                class="form-group row"
                                *ngIf="form.get('noArrowContentType').value === 'protocolContent'"
                            >
                                <label class="col-sm-2 col-form-label">Content Link</label>
                                <div class="col-sm-10">
                                    <label for="no-content-link"></label>
                                    <select
                                        id="no-content-link"
                                        class="form-control"
                                        formControlName="noContentLink"
                                        (change)="onChangeNoArrow($event)"
                                    >
                                        <option value="">link to an existing protocol</option>
                                        <option
                                            *ngFor="let protocol of contentOptions.protocols; trackBy: trackByFn"
                                            [value]="protocol.id"
                                        >
                                            {{ protocol.title }}</option
                                        >
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-2 pt-4">
                                    <label>Title</label>
                                </div>
                                <div class="col-sm-10">
                                    <label for="no-arrow-title"></label>
                                    <input
                                        id="no-arrow-title"
                                        type="text"
                                        class="form-control"
                                        formControlName="noArrowTitle"
                                        placeholder="Content"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="row">
                                <div class="col-sm text-center">
                                    <div class="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            formControlName="noArrowNoSteps"
                                            id="noArrowNoSteps"
                                        />
                                        <label class="custom-control-label" for="noArrowNoSteps"></label>
                                    </div>
                                </div>
                                <div class="col-sm text-center">
                                    <div class="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            formControlName="noArrowNewBlock"
                                            id="noArrowNewBlock"
                                        />
                                        <label class="custom-control-label" for="noArrowNewBlock"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <div class="modal-footer">
            <div class="pull-left">
                <button [disabled]="form.invalid" class="btn btn-info" type="button" (click)="handleSubmit()">Submit
                </button>
                <button class="btn btn-default" type="button" (click)="handleCancel()">Cancel</button>
            </div>
        </div>
    </div>
</div>
