import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    ChangeDetectorRef,
    OnDestroy,
    ViewChild,
    ElementRef
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import * as fromStore from './../../store';
import { Team } from '@alii-web/models/team.interface';
import { Hospital } from '@alii-web/models/organization.interface';
import { BooleanLiteral } from 'typescript';

const cn = 'RegisterComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
    public registerForm: FormGroup;

    loggedIn: boolean;
    formFields: any[] = [];

    hospitals: Hospital[] = [];
    teams: Team[] = [];

    selectedHospital: Hospital;
    selectedTeam: Team;
    selectedTeamId: string;

    registerInitLoading$: Observable<boolean>;
    registerInitLoaded$: Observable<boolean>;
    registerInitError$: Observable<string>;

    registerLoading$: Observable<boolean>;
    registerLoaded$: Observable<boolean>;
    registerError$: Observable<string>;
    registerWelcomeMessage$: Observable<string>;

    subscriptions: Subscription[] = [];

    validHospital: boolean;
    validTeam: boolean;
    newTeam: boolean;
    newOrganization: boolean;

    banner: string;
    terms;

    @ViewChild('selectTeamId') selectTeamId: ElementRef;

    constructor(
        private store: Store<fromStore.RegisterFeatureState>,
        private fb: FormBuilder,
        private http: HttpClient,
        private router: Router,
        private authService: AuthService,
        private route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {}

    ngOnInit() {
        this.registerInitLoading$ = this.store.select(fromStore.getRegisterInitLoading);
        this.registerInitLoaded$ = this.store.select(fromStore.getRegisterInitLoaded);
        this.registerInitError$ = this.store.select(fromStore.getRegisterInitError);

        this.registerLoading$ = this.store.select(fromStore.getRegisterLoading);
        this.registerLoaded$ = this.store.select(fromStore.getRegisterLoaded);
        this.registerError$ = this.store.select(fromStore.getRegisterError);
        this.registerWelcomeMessage$ = this.store.select(fromStore.getRegisterWelcomeMessage);


        this.registerForm = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            telephone: [''],
            hospitalIndex: ['', [Validators.required]],
            hospitalName: '',
            status: [''],
            // teamIndex: ['', [Validators.required]],
            teamIndex: '',
            teamName: '',
            acceptTerms: ['', [Validators.required]]
        });

        this.subscriptions.push(
            this.store.select(fromStore.getRegisterInit).subscribe(result => {
                this.formFields = result.formFields;
                this.banner = result.banner || 'Register for an Alii account';
                const found = this.formFields.find(ff => ff.value === 'hospitalId');
                if (found) {
                    this.hospitals = [];
                    found.options.forEach(option =>
                        this.hospitals.push({
                            hospitalName: option.hospitalName,
                            hospitalId: option.hospitalId,
                            sector: option.sector,
                            choose: option.choose,
                            teams: option.teams
                        })
                    );
                }
                this.terms = this.formFields.find(ff => ff.value === 'acceptTerms');
                this.changeDetectorRef.markForCheck();
            })
        );

        // Optional query parameters are passed to backend on first call.
        const queryParams = this.route.snapshot.queryParams;
        this.store.dispatch(new fromStore.RegisterInit({ queryParams }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    changeHospital(event) {
        this.selectedHospital = this.hospitals[+event.target.value];
        this._updateNameInput('hospitalName', this.selectedHospital.hospitalId);
        this.teams = this.selectedHospital.teams || [];
        if (this.teams.length) {
            this._changeTeam(0, true);
        } else {
            this.selectedTeam = null;
        }
        if (this.selectedHospital.hospitalId === '00000000-0000-0000-0000-000000000000' || !this.selectedHospital.hospitalId) {
                this.newOrganization = true
            } else {
                this.newOrganization = false
            }

    }



    changeTeam(event) {
        this._changeTeam(+event.target.value, false);
    }

    _changeTeam(index: number, reset: boolean) {
        this.selectedTeam = this.teams[index];
        this.selectedTeamId = this.selectedTeam.teamId;
       
        if(this.selectedTeam) {
            if (this.selectedTeamId === '00000000-0000-0000-0000-000000000000' || !this.selectedTeamId) {
                    this.newTeam = true
                } else {
                    
                    this.newTeam = false   
                }

            if (this.selectedTeamId) {
                if (reset) {
                    if (this.selectTeamId && this.selectTeamId.nativeElement) {
                        const controlTeamId = this.registerForm.get('teamIndex');
                        this.selectTeamId.nativeElement.value = index;
                        controlTeamId.setValue(index);
                    } else {
                        console.warn(
                            `${cn} _changeTeam() selectTeamId${this.selectTeamId ? '.nativeElement' : ''} is undefined`
                        );
                    }
                }
                this._updateNameInput('teamName', this.selectedTeamId);
            } else {
                console.warn(`${cn} _changeTeam() this.selectedTeamId is undefined`);
            }
        } else {
            console.warn(`${cn} _changeTeam() selectedTeam is undefined`);
            this.selectedTeamId = null;
        }
    }

    _updateNameInput(controlName: string, id: string, value: string = '') {
        const isZero = false;
        const controlNameInput = this.registerForm.get(controlName);
        controlNameInput.setValue(value);
        controlNameInput.setErrors(isZero ? { required: true } : null);
        controlNameInput.setValidators(isZero ? [Validators.required] : null);
        this.changeDetectorRef.markForCheck();
    }

    handleSubmit() {
        const {
            firstName,
            lastName,
            email,
            telephone,
            hospitalIndex,
            teamIndex,
            status,
            acceptTerms
        } = this.registerForm.value;

        const hospital = this.hospitals[+hospitalIndex];
        const hospitalId = hospital.hospitalId.toString();
        let hospitalName = ''
        if(this.newOrganization) {
           hospitalName = this.registerForm.value.hospitalName
        } else {
            hospitalName = hospital.hospitalName
        }
        const team = this.teams[+teamIndex];
        const teamId = team.teamId.toString();
        let teamName = ''
        if (this.newTeam) {
            teamName = this.registerForm.value.teamName
        } else {
            teamName = team.teamName
        }
        const sector = hospital.sector;

        this.store.dispatch(
            new fromStore.Register({
                firstName,
                lastName,
                email,
                telephone,
                hospitalId,
                hospitalName,
                sector, 
                status,
                teamId,
                teamName,
                acceptTerms
            })
        );

        this.googleAnalyticsService.event('Register', 'Registration', 'Submit');
    }

    login(event: MouseEvent) {
        this.router.navigate(['/users/login']);
        event.stopPropagation();
        return false;
    }

    isLoggedIn() {
        this.authService.isLoggedIn()
    }

    reset(event: MouseEvent) {
        this.selectedHospital = null;
        this.selectedTeam = null;
        this.teams = [];
        this.registerForm.reset();
        event.stopPropagation();
        return false;
    }

    trackByFn = (index, item) => item.id || index;
}
