import { Action } from '@ngrx/store';

import { StartingPageRO, SetDashboardRO } from '../../models';

export const STARTING_PAGE_GET = '[Starting Page] Get';
export const STARTING_PAGE_GET_SUCCESS = '[Starting Page] Get Success';
export const STARTING_PAGE_GET_FAIL = '[Starting Page] Get Fail';
export const STARTING_PAGE_RESET_DASHBOARD = '[Starting Page] Reset Dashboard';
export const STARTING_PAGE_GET_RESET = '[Starting Page] Get Reset';

export class StartingPageGet implements Action {
    readonly type = STARTING_PAGE_GET;
}

export class StartingPageGetSucces implements Action {
    readonly type = STARTING_PAGE_GET_SUCCESS;

    constructor(public payload: StartingPageRO) {}
}

export class StartingPageGetFail implements Action {
    readonly type = STARTING_PAGE_GET_FAIL;

    constructor(public payload: { message: string }) {}
}

export class StartingPageResetDashboard implements Action {
    readonly type = STARTING_PAGE_RESET_DASHBOARD;

    constructor(public payload: SetDashboardRO) {}
}

export class StartingPageGetReset implements Action {
    readonly type = STARTING_PAGE_GET_RESET;
}

export type StartingPageAction =
    | StartingPageGet
    | StartingPageGetFail
    | StartingPageGetSucces
    | StartingPageResetDashboard
    | StartingPageGetReset;
