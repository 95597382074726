import * as fromActions from '../actions/paragraphs-files.action';
import { GradeAssessmentQuestion } from '../../../../models/grade-assesment.interface';

export interface ParagraphsFilesState {
    entries: {};
    uploadEvent: {
        ppdId: string;
        isUploading: boolean;
        status: string;
        progress: number;
        url: string;
    };
}

export const initialState: ParagraphsFilesState = {
    entries: {},
    uploadEvent: {
        ppdId: null,
        isUploading: false,
        status: null,
        progress: null,
        url: null
    }
};

export function reducer(state = initialState, action: fromActions.ParagraphsFIlesAction): ParagraphsFilesState {
    switch (action.type) {
        case fromActions.MAP_PARAGRAPHS_FILES: {
            const { files } = action.payload;

            return { ...state, entries: files };
        }
        case fromActions.ADD_NEW_PARAGRAPHS_FILES_ENTRY: {
            const { paragraph } = action.payload;
            const entries = {
                ...state.entries,
                [paragraph.id]: []
            };
            return { ...state, entries };
        }
        case fromActions.UPLOAD_FILE_TO_BUCKET: {
            const { ppdId } = action.payload;
            return {
                ...state,
                uploadEvent: {
                    isUploading: true,
                    ppdId,
                    status: 'progress',
                    progress: 0,
                    url: null
                }
            };
        }
        case fromActions.UPLOAD_FILE_TO_BUCKET_UPLOADING: {
            const { ppdId, progress } = action.payload;
            return {
                ...state,
                uploadEvent: {
                    ...state.uploadEvent,
                    isUploading: true,
                    ppdId,
                    progress
                }
            };
        }
        case fromActions.UPLOAD_FILE_TO_BUCKET_SUCCESS: {
            return {
                ...state,
                uploadEvent: {
                    ...state.uploadEvent,
                    isUploading: true,
                    status: null,
                    progress: null
                }
            };
        }
        case fromActions.UPLOAD_FILE_TO_BUCKET_FAIL: {
            return {
                ...state,
                uploadEvent: {
                    isUploading: false,
                    ppdId: null,
                    status: null,
                    progress: null,
                    url: null
                }
            };
        }
        case fromActions.ADD_FILE_TO_PARAGRAPH_SUCCESS: {
            const { ppdId, url, title, id } = action.payload;
            return {
                ...state,
                uploadEvent: {
                    isUploading: false,
                    ppdId: null,
                    status: null,
                    progress: null,
                    url: null
                },
                entries: Object.assign(
                    { ...state.entries },
                    {
                        [ppdId]: [
                            ...state.entries[ppdId],
                            {
                                id,
                                title,
                                file_url: url
                            }
                        ]
                    }
                )
            };
        }
        case fromActions.ADD_FILE_TO_PARAGRAPH_FAIL: {
            return {
                ...state,
                uploadEvent: {
                    isUploading: false,
                    ppdId: null,
                    status: null,
                    progress: null,
                    url: null
                }
            };
        }
        case fromActions.DELETE_FILE: {
            return state;
        }
        case fromActions.DELETE_FILE_SUCCESS: {
            const { protocolId, ppdId, fileId } = action.payload;
            return {
                ...state,
                entries: {
                    ...state.entries,
                    [ppdId]: state.entries[ppdId].filter(file => file.id !== fileId)
                }
            };
        }
        case fromActions.DELETE_FILE_FAIL: {
            return {
                ...state
            };
        }
    }
    return state;
}

export const getParagraphsFiles = (state: ParagraphsFilesState) => state;
