import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'connect-keyquestion-modal',
    templateUrl: './connect-keyquestion-modal.component.html'
})
export class ConnectKeyQuestionModalComponent implements OnInit {

    @Output() eventBus = new EventEmitter<any>();
    @Input() keyQuestions: any[];

    searchForm: FormGroup;
    query: string;
    
    searchLoading: boolean;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}
    ngOnInit() {
        this.searchForm = this.fb.group({
            query: [this.query, Validators.required]
        });
    }

    onSubmitSearch() {
        if (this.searchForm.valid) {
            this.searchLoading = true;
            const event = {
                type: 'handleGetKeyQSearch',
                payload: {
                    ...this.searchForm.value
                }
            };

            this.eventBus.emit(event);
        }
    }
    onClickKQ(keyQuestionId) {
        const event = {
            type: 'handleSubmitConnectKeyQuestion',
            payload: {
                keyQuestionId
            }
        };

        this.eventBus.emit(event);
        this.activeModal.dismiss();
    }

    trackByFn = (index, item) => item.id || index;
}
