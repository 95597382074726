import { Action } from '@ngrx/store';

import { Shop } from '../../../../models/shop.interface';

export const LOAD_SHOP = '[Shop] Load Shop';
export const LOAD_SHOP_FAIL = '[Shop] Load Shop Fail';
export const LOAD_SHOP_SUCCESS = '[Shop] Load Shop Success';

export class LoadShop implements Action {
    readonly type = LOAD_SHOP;

    constructor(public payload: any = null) {
    }
}

export class LoadShopFail implements Action {
    readonly type = LOAD_SHOP_FAIL;

    constructor(public payload: any) {
    }
}

export class LoadShopSucces implements Action {
    readonly type = LOAD_SHOP_SUCCESS;

    constructor(public payload: Shop) {
    }
}

export type StoreAction =
    | LoadShop
    | LoadShopFail
    | LoadShopSucces
