import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbTimeStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { OrganisationService } from '../../../../services';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'manage-calendar',
    templateUrl: './manage-calendar.component.html',
    styleUrls: ['./manage-calendar.component.scss']
})
export class ManageCalendarComponent implements OnInit {
    messages$: Observable<any>;
    public options = {
        key: environment.froala.key,
        attribution: false,
        heightMin: 120,
        quickInsertTags: [''],
        pastePlain: true,
        charCounterCount: false,
        toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'formatUL', 'formatOL', 'insertLink'],
        events: {
            // Froala bugfix insert table from google sheets
            'paste.afterCleanup': html => html.replace(';width:0px;', ';')
        }
    };
    newMessageText: string;
    // usergroups$: Observable<any>;
    dateToday: string;
    private id: string;
    editing: boolean;
    message$: Observable<any>;
    messageForm: FormGroup;
    text: string;

    startDate: NgbDateStruct;
    endDate: NgbDateStruct;

    startTime: NgbTimeStruct;
    // endTime: NgbTimeStruct;

    date: { year: number; month: number };
    calendarItems$: Observable<any>;
    filters: Observable<any>;
    isAllday: boolean;
    minDate: NgbDateStruct;

    constructor(
        private service: OrganisationService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private router: Router
    ) {}

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');

        if (this.id) {
            this.editing = true;
        }

        this.dateToday = new Date().toISOString().slice(0, 10);
        this.messageForm = this.fb.group({
            title: ['', Validators.required],
            text: ['', Validators.required],
            location: ['', Validators.required],
            messageId: [],
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            isAllday: ['', Validators.required],
            startTime: [{ hour: 12, minute: 0, second: 0 }, Validators.required],
            endTime: [{ hour: 12, minute: 0, second: 0 }, Validators.required]
        });

        this.calendarItems$ = this.service.getCalendarItems();

        if (this.editing) {
            this.message$ = this.calendarItems$.pipe(
                map(items => items.calendar_items[0].list.find(item => item.id === this.id))
            );

            this.message$.subscribe(message => {
                const startDate: NgbDateStruct = {
                    year: +message.startDate.slice(0, 4),
                    month: +message.startDate.slice(5, 7),
                    day: +message.startDate.slice(8, 10)
                };
                this.minDate = startDate;

                const startTime: NgbTimeStruct = {
                    hour: +message.startDate.slice(11, 13),
                    minute: +message.startDate.slice(14, 16),
                    second: 0
                };

                const endDate: NgbDateStruct = {
                    year: +message.endDate.slice(0, 4),
                    month: +message.endDate.slice(5, 7),
                    day: +message.endDate.slice(8, 10)
                };

                this.isAllday = message.isAllday;

                const endTime: NgbTimeStruct = {
                    hour: +message.endDate.slice(11, 13),
                    minute: +message.endDate.slice(14, 16),
                    second: 0
                };

                const text = message.expanded_text ? message.expanded_text : message.text;
                this.messageForm = this.fb.group({
                    title: [message.title, Validators.required],
                    text: [text, Validators.required],
                    messageId: [message.id, Validators.required],
                    location: [message.location, Validators.required],
                    startDate: [startDate, Validators.required],
                    endDate: [endDate, Validators.required],
                    isAllday: [message.isAllday, Validators.required],
                    startTime: [startTime, Validators.required],
                    endTime: [endTime, Validators.required]
                });
            });
        }
    }

    onSelectingStartDate(date: NgbDate) {
        this.minDate = date;
        const currentEndDate = this.messageForm.get('endDate').value;
        if (date.after(currentEndDate)) {
            this.messageForm.patchValue({
                endDate: date
            });
        }
    }

    toggleisAllday(event) {
        this.isAllday = event.target.checked;
    }

    formatTime(time: NgbTimeStruct): string {
        return this.pad(time.hour) + ':' + this.pad(time.minute);
    }

    formatDate(date: NgbDateStruct): string {
        return date.year + '-' + this.pad(date.month) + '-' + this.pad(date.day);
    }

    submit() {
        const data = { ...this.messageForm.value, isCalendar: true };
        data.startDate = this.formatDate(data.startDate);
        data.endDate = this.formatDate(data.endDate);

        if (!data.isAllday) {
            data.startTime = this.formatTime(data.startTime);
            data.endTime = this.formatTime(data.endTime);
        } else {
            delete data.startTime;
            delete data.endTime;
        }

        this.service.postMessage(data).subscribe(() => {
            this.router.navigate(['/calendar']);
        });

        return false;
    }

    pad(number, size = 2): string {
        let output = number + '';
        while (output.length < size) {
            output = '0' + output;
        }
        return output;
    }

    removeItem(id) {
        this.service.removeMessage(id).subscribe(() => {
            this.router.navigate(['/calendar']);
        });
        return false;
    }

    trackByFn = (index, item) => item.id || index;
}
