import { Action } from '@ngrx/store';
import { GradeAssessmentQuestion } from '../../../../models/grade-assesment.interface';
import { Paragraph } from '../../../../models/paragraph.interface';

export const MAP_GRADEASSESSMENT_REVIEWS = '[Protocols] Map Grade Assesments reviews';
export const ADD_NEW_GRADEASSESSMENT_ENTRY = '[Protocols] Add New Grade Assesments Entry';
export const ADD_NEW_MODEL_GRADEASSESSMENT_ENTRY = '[Protocols] Add New Model Grade Assesments Entry';
export const LOAD_GRADEASSESSMENT = '[Protocols] Load Grade Assesments';
export const LOAD_GRADEASSESSMENT_FAIL = '[Protocols] Load Grade Assesments Fail';
export const LOAD_GRADEASSESSMENT_SUCCESS = '[Protocols] Load Grade Assesments Success';
export const SELECT_GRADE_QUESTION = '[Protocols] Select Grade Question';

export class MapGradesReviews implements Action {
    readonly type = MAP_GRADEASSESSMENT_REVIEWS;
    constructor(public payload: { gradeAssessment: any[] }) {}
}

export class AddNewGradeEntries implements Action {
    readonly type = ADD_NEW_GRADEASSESSMENT_ENTRY;
    constructor(public payload: { paragraph: any }) {}
}

export class AddNewModelGradeEntries implements Action {
    readonly type = ADD_NEW_MODEL_GRADEASSESSMENT_ENTRY;
    constructor(public payload: { paragraph: any }) {}
}

export class LoadGrades implements Action {
    readonly type = LOAD_GRADEASSESSMENT;
    constructor(public payload: { protocolId?: string | boolean; ppdId: string | boolean; gradeType?: string; gradeId?: string }) {}
}

export class LoadGradesSucces implements Action {
    readonly type = LOAD_GRADEASSESSMENT_SUCCESS;
    constructor(public payload: { ppdId: string; questions: GradeAssessmentQuestion[], gradeRowData: any[], gradeColumnDefs: any[] }) {}
}

export class LoadGradesFail implements Action {
    readonly type = LOAD_GRADEASSESSMENT_FAIL;
    constructor(public payload: any) {}
}

export class SelectGradeQuestion implements Action {
    readonly type = SELECT_GRADE_QUESTION;
    constructor(public payload: { selectedQuestionField: string }) {}
}

export const CREATE_GRADEASSESSMENT = '[Protocols] Create Grade Assesments';
export const CREATE_GRADEASSESSMENT_FAIL = '[Protocols] Create Grade Assesments Fail';
export const CREATE_GRADEASSESSMENT_SUCCESS = '[Protocols] Create Grade Assesments Success';

export class CreateGradeValue implements Action {
    readonly type = CREATE_GRADEASSESSMENT;
    constructor(public payload: { ppdId, gradeRowData, gradeColumnDefs, questions, gradeId: string, comment, parentId?  }) {}
}

export class CreateGradeValueSucces implements Action {
    readonly type = CREATE_GRADEASSESSMENT_SUCCESS;
    constructor(public payload: {  ppdId, gradeRowData, gradeColumnDefs, questions, gradeId: string, parentId? }) {}
}

export class CreateGradeValueFail implements Action {
    readonly type = CREATE_GRADEASSESSMENT_FAIL;
    constructor(public payload: any) {}
}

export const UPDATE_GRADEASSESSMENT = '[Protocols] Update Grade Assesments';
export const UPDATE_GRADEASSESSMENT_FAIL = '[Protocols] Update Grade Assesments Fail';
export const UPDATE_GRADEASSESSMENT_SUCCESS = '[Protocols] Update Grade Assesments Success';

export class UpdateGradeValue implements Action {
    readonly type = UPDATE_GRADEASSESSMENT;
    constructor(public payload: {  ppdId, gradeRowData, gradeColumnDefs, questions, gradeId: string }) {}
}

export class UpdateGradeValueSucces implements Action {
    readonly type = UPDATE_GRADEASSESSMENT_SUCCESS;
    constructor(public payload: { ppdId, gradeRowData, gradeColumnDefs, questions }) {}
}

export class UpdateGradeValueFail implements Action {
    readonly type = UPDATE_GRADEASSESSMENT_FAIL;
    constructor(public payload: any) {}
}

export const DELETE_GRADEASSESSMENT = '[Protocols] Delete Grade Assesments';
export const DELETE_GRADEASSESSMENT_FAIL = '[Protocols] Delete Grade Assesments Fail';
export const DELETE_GRADEASSESSMENT_SUCCESS = '[Protocols] Delete Grade Assesments Success';

export class DeleteGrade implements Action {
    readonly type = DELETE_GRADEASSESSMENT;
    constructor(public payload: {  ppdId, parentId? }) {}
}

export class DeleteGradeSuccess implements Action {
    readonly type = DELETE_GRADEASSESSMENT_SUCCESS;
    constructor(public payload: { ppdId, parentId? }) {}
}

export class DeleteGradeFail implements Action {
    readonly type = DELETE_GRADEASSESSMENT_SUCCESS;
    constructor(public payload: any) {}
}

export const UPDATE_GRADEROW = '[Protocols] Update Grade Row';
export const UPDATE_GRADEROW_FAIL = '[Protocols] Update Grade Row Fail';
export const UPDATE_GRADEROW_SUCCESS = '[Protocols] Update Grade Row Success';

export class UpdateGradeRow implements Action {
    readonly type = UPDATE_GRADEROW;
    constructor(public payload: { ppdId: string; values: any; gradeId: string }) {}
}

export class UpdateGradeRowSuccess implements Action {
    readonly type = UPDATE_GRADEROW_SUCCESS;
    constructor(public payload: { ppdId: string; values: any; gradeId: string }) {}
}

export class UpdateGradeRowFail implements Action {
    readonly type = UPDATE_GRADEROW_FAIL;
    constructor(public payload: any) {}
}


export type GradesAssesmentAction =
    | MapGradesReviews
    | AddNewGradeEntries
    | AddNewModelGradeEntries
    | LoadGrades
    | LoadGradesFail
    | LoadGradesSucces
    | SelectGradeQuestion
    | UpdateGradeRow
    | UpdateGradeRowFail
    | UpdateGradeRowSuccess
    | UpdateGradeValue
    | UpdateGradeValueFail
    | UpdateGradeValueSucces
    | CreateGradeValue
    | CreateGradeValueFail
    | CreateGradeValueSucces
    | DeleteGrade
    | DeleteGradeFail
    | DeleteGradeSuccess;
