import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    OnInit,
    Renderer2,
} from '@angular/core';
import { Action, Protocol } from '../../../../models/protocol.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const cn = 'ProtocolMenubarComponent';


@Component({
    
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-protocol-menubar',
    templateUrl: './protocol-menubar.component.html',
    styleUrls: ['./protocol-sidebar.component.scss']
})
export class ProtocolMenubarComponent implements OnInit {
    
    @Input() protocol: Protocol;
    @Input() pane: string;
    @Input() version: string;
    @Input() views: []
    showAvatars: boolean;


    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private modalService: NgbModal,
        private renderer: Renderer2) {}

    ngOnInit() {
        this.showAvatars= false
    }

    handleSwitchView(type) {
        
        const sidebar = document.getElementById('protocolSidebar')
        if(type == 'flowchart') {
            // if (!sidebar.classList.contains('-is-collapsed')) {
            //     this.renderer.addClass(sidebar, '-is-collapsed');
            // };
            this.handleOpenPane('content')
        } else if (type == 'text') {
            if (sidebar.classList.contains('-is-collapsed')) {
                this.renderer.removeClass(sidebar, '-is-collapsed');
            };
            this.handleOpenPane('content')
        }
        const action = { type: 'handleSwitchView', payload: type };
        this.eventBus.emit(action);
    }

    toggleSidebar() {
        const sidebar = document.getElementById('protocolSidebar')
        if (!sidebar.classList.contains('-is-open')) {
            this.renderer.addClass(sidebar, '-is-open');
        } else {
            this.renderer.removeClass(sidebar, '-is-open');
        }

     }


    handleOpenPane(name) {
        this.pane = name 
        const action = { type: 'handleOpenPane', payload: { name } };
        this.eventBus.emit(action);
    }

    handlePrintPdf() {
        const action = { type: 'open-print', payload: {} };
        this.eventBus.emit(action);
    }

    handleToggleBookmark() {
        const bookmark = this.protocol.bookmark ? 'remove' : 'set';
        const action = { type: 'handleUpdateSetting', payload: { bookmark } };
        this.eventBus.emit(action);
    }

    scrollToInfo() {

        const action = { type: 'handleSwitchView', payload: 'text' };
        this.eventBus.emit(action);
        
        const TIMEOUT = 50;
        const MAX = 5000;
        let msecs = 0;
        // TODO: Replace with RXJS timer take until which is more elegant.
        const timerId = setInterval(() => {
            const el = document.getElementById('info');
            if (el) {
                // Found, scroll to anchor into view.
                // IMPORTANT: You MUST use setTimeout here otherwise it will not work.
                setTimeout(() => el.scrollIntoView({ behavior: 'smooth' }), 200);
                clearInterval(timerId);
            } else {
                // Not found.
                msecs += TIMEOUT;
                if (msecs >= MAX) {
                    clearInterval(timerId);
                }
            }
        }, TIMEOUT);
    }

    trackByFn = (index, item) => item.id || index;
}
