import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap, switchMap, withLatestFrom, concatMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as parPopAction from '../actions/paragraphs-populations.actions';
import * as ProtocolAction from '../actions/protocols.action';
import * as ModelOutcomesAction from '../actions/models-outcomes.action';
import * as ModelFindingsAction from '../actions/models-findings.action';
import * as fromService from '../../../../services';
import * as ModelFindingsSelector from '../../store/selectors/models-findings.selector';
import * as ParPopSelector from '../../store/selectors/paragraphs-population.selector';
import * as fromStore from './../../store';
import * as eventbusChannelActions from '../actions/eventbus-channel.action';

const selectStore = (selectors: any) => {
    return switchMap(action => {
        return of([]).pipe(
            withLatestFrom(...selectors, (initial, ...selectorsState) => {
                return [action, ...selectorsState];
            }),
            take(1) // needed, since without it, subscription not killed until next action that hit effect
        );
    });
};

@Injectable()
export class ParPopulationsEffects {
    constructor(
        private store$: Store<any>,
        private actions$: Actions,
        private ModelsService: fromService.ModelsService,
        private store: Store<fromStore.ProtocolsFeatureState>
    ) {}

    @Effect()
    SetPopulationIdEffect$ = this.actions$.pipe(
        ofType(parPopAction.PopulationActionTypes.SetPopulationId),
        mergeMap(action => {
            const { payload } = action as any;
            this.ModelsService.setPopulationIdSession(payload.populationId);
            return [];
        })
    );

    @Effect()
    ModelCalculatedSuccesEffect$ = this.actions$.pipe(
        ofType(parPopAction.PopulationActionTypes.ModelCalculatedSuccess),
        mergeMap(action => {
            const { payload } = action as any;

            const { isCopiedPopulation, populationId } = payload;
            if (isCopiedPopulation) {
                return [];
            }

            this.ModelsService.setPopulationIdSession(populationId);

            const { updated_paragraphs: pars = [] } = payload;
            
            const { outcomes, questions: findings, tagList } = pars.reduce((acc, par) => {
                const arrQuestions = par.questions ? [...par.questions] : [];
                return {
                    outcomes: {
                        ...acc.outcomes,
                        [par.id]: par.outcomes ? par.outcomes : []
                    },
                    questions: {
                        ...acc.questions,
                        [par.id]: par.questions
                            ? arrQuestions.reduce((accQuestion, question) => {
                                  const options = question.options.reduce((accOption, option) => {
                                      return {
                                          ...accOption,
                                          [option.id]: option
                                      };
                                  }, {});
                                  return {
                                      ...accQuestion,
                                      [question.id]: {
                                          ...question,
                                          options
                                      }
                                  };
                              }, {})
                            : {}
                    },
                    tagList: {
                        ...acc.tagList,
                        [par.id]: par.tagList ? par.tagList : []
                    }
                };
            }, {});

            return [
                new ModelOutcomesAction.UpdateOutcomes({ outcomes }),
                new ModelOutcomesAction.UpdateTagList({ tagList }),
                new ModelFindingsAction.UpdateFindings({ findings })
            ];
        })
    );

    @Effect()
    UpdateCopiedPopulationEffect$ = this.actions$.pipe(
        ofType(parPopAction.PopulationActionTypes.UpdateCopiedPopulation),
        mergeMap(action => {
            const { payload } = action as any;

            const { ppdId, updated_paragraphs: pars = [] } = payload;

            const { questions: findings } = pars.reduce((acc, par) => {
                const arrQuestions = par.questions ? [...par.questions] : [];
                return {
                    questions: {
                        ...acc.questions,
                        [par.id]: par.questions
                            ? arrQuestions.reduce((accQuestion, question) => {
                                  const options = question.options.reduce((accOption, option) => {
                                      return { ...accOption, [option.id]: option };
                                  }, {});
                                  return { ...accQuestion, [question.id]: { ...question, options } };
                              }, {})
                            : {}
                    }
                };
            }, {});

            return [new parPopAction.UpdateCopiedPopulationSuccess({ findings: findings[ppdId] })];
        })
    );

    @Effect()
    UpdateCopiedPopulationSuccessEffect$ = this.actions$.pipe(
        ofType(parPopAction.PopulationActionTypes.UpdateCopiedPopulationSuccess),
        selectStore([this.store$.pipe(select(ParPopSelector.getCopiedPopulation))]),
        mergeMap(([action, state]) => {
            const { payload } = action as any;
            const { population, outcome, findings, ppdId } = state;

            return [
                new eventbusChannelActions.PublishMessage({
                    channel: 'openEditPopulationModal',
                    message: {
                        population,
                        outcome,
                        findings: {
                            ...findings,
                            ...payload.findings
                        },
                        ppdId
                    }
                })
            ];
        })
    );

    @Effect()
    SelectOutcomeEffect$ = this.actions$.pipe(
        ofType(parPopAction.PopulationActionTypes.SelectOutcome),
        mergeMap(action => {
            const { payload } = action as any;
            const { populationId, outcomeId, selected } = payload;

            return this.ModelsService.selectOutcome(populationId, outcomeId, selected).pipe(
                map(response => {
                    return new parPopAction.SelectOutcomeSuccess(response);
                }),
                catchError(error => of(new parPopAction.ModelCalculatedFail(error)))
            );
        })
    );

    @Effect()
    SelectOutcomeListEffect$ = this.actions$.pipe(
        ofType(parPopAction.PopulationActionTypes.SelectOutcomeList),
        mergeMap(_action => {
            const { payload } = _action as any;
            const { populationId, outcomeId, modelId, action } = payload;

            return this.ModelsService.selectOutcomeList(populationId, outcomeId, modelId, action).pipe(
                map(response => {
                    return new parPopAction.SelectOutcomeListSuccess(response);
                }),
                catchError(error => of(new parPopAction.ModelCalculatedFail(error)))
            );
        })
    );

    @Effect()
    UpdateSummaryEffect$ = this.actions$.pipe(
        ofType(
            parPopAction.PopulationActionTypes.ModelCalculatedSuccess,
            parPopAction.PopulationActionTypes.UpdateOutcomeSummaryText,
            parPopAction.PopulationActionTypes.SelectOutcomeSuccess,
        ),
        mergeMap(action => {
            const { payload } = action as any;
            const { updated_paragraphs: pars = [] } = payload;
            return pars
                .filter(par => 'summary' in par && par['summary'] || par['summaryHeader'])
                .map(par => {
                    return new ProtocolAction.StoreUpdateParagraph({ parentId: par.parentId ?? null , paragraph: par });
                });
        })
    );

    @Effect()
    UserUpdateModelSuccessEffect$ = this.actions$.pipe(
        ofType(parPopAction.PopulationActionTypes.UserUpdateModelSuccess, ModelFindingsAction.RESET_MODEL),
        map((action: any) => action.payload),
        concatMap(action =>
            of(action).pipe(
                withLatestFrom(this.store.pipe(select(ModelFindingsSelector.getModelFindingSimplified(action.modelId))))
            )
        ),
        switchMap(([action, state]) => {
            const { modelId, populationId, isCopiedPopulation = false } = action;
            return this.ModelsService.updateModel({
                ppdId: modelId,
                findings: state,
                populationId,
                calculate: !isCopiedPopulation
            }).pipe(
                map(response => {
                    if (isCopiedPopulation) {
                        return new parPopAction.UpdateCopiedPopulation({ ...response, ppdId: modelId });
                    }

                    return new parPopAction.ModelCalculatedSucces({
                        ...response,
                        isCopiedPopulation
                    });
                }),
                catchError(error => of(new parPopAction.ModelCalculatedFail(error)))
            );
        })
    );

    @Effect()
    SelectOutcomeListSuccessEffect$ = this.actions$.pipe(
        ofType(parPopAction.PopulationActionTypes.SelectOutcomeListSuccess),
        map((action: any) => {
            const { payload } = action;
            return new parPopAction.ModelCalculatedSucces(payload);
        })
    );

    @Effect()
    UpdateOutcomeSummaryText$ = this.actions$.pipe(
        ofType(parPopAction.PopulationActionTypes.UpdateOutcomeSummaryText),
        mergeMap(action => {
            const { payload } = action as any;
            const { outcomeId, populationId, text, modelId } = payload;
        
            return this.ModelsService.updateOutcomeSummaryText(outcomeId, populationId, text, modelId).pipe(
                map(response => {
                    return new parPopAction.ModelCalculatedSucces(response);
                }),
                catchError(error => of(new parPopAction.ModelCalculatedFail(error)))
            );
        })
    );

    @Effect()
    UserUpdateModelEffect$ = this.actions$.pipe(
        ofType(ModelFindingsAction.USER_UPDATE_MODEL),
        map((action: any) => {
            const { payload } = action;
            return new parPopAction.UserUpdateModelSuccess(payload);
        })
    );
}
