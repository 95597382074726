import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParagraphsService } from 'apps/web/src/app/services';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'edit-tags-modal',
    templateUrl: './edit-tags.component.html'
})
export class EditTagsModalComponent implements OnInit {
    form: FormGroup;

    @Input() conclusionId: string
    @Input() tags: string


    @Output()
    eventBus?: EventEmitter<any> = new EventEmitter<any>();
    
    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private parService: ParagraphsService) {}

    ngOnInit() {
        this.form = this.fb.group({
            tags: [this.tags]
        });

    }

    onSubmit() {
        const tags = this.form.get('tags').value;
        this.parService.saveConclusionTags({"conclusionId": this.conclusionId, tags}).subscribe((result) => this.activeModal.close());
    }
}
