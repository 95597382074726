import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { DashboardPatient } from '@alii-web/models/dashboard-patient.model';
import { PatientOption } from '@alii-web/models/dashboard-patient-option.model';

export enum DashboardPatientActionTypes {
    LoadDashboardPatients = '[DashboardPatient] Load DashboardPatients',
    LoadDashboardPatientsSuccess = '[DashboardPatient] Load DashboardPatients Success',

    LoadDashboardPatientOptions = '[DashboardPatient] Load DashboardPatientOptions',
    LoadDashboardPatientOptionsSuccess = '[DashboardPatient] Load DashboardPatientOptions Success',

    LoadDashboardPatientProtocols = '[DashboardPatient] Load DashboardPatientProtocols',
    LoadDashboardPatientProtocolsSuccess = '[DashboardPatient] Load DashboardPatientProtocols Success',

    AddDashboardPatient = '[DashboardPatient] Add DashboardPatient',
    AddDashboardPatientSuccess = '[DashboardPatient] Add DashboardPatient Success',

    AddDashboardPatientToProtocol = '[DashboardPatient] Add DashboardPatient To Protocol',
    AddDashboardPatientToProtocolSuccess = '[DashboardPatient] Add DashboardPatient To Protocol Success',

    UpdateDashboardPatient = '[DashboardPatient] Update DashboardPatient',
    UpdateDashboardPatientSuccess = '[DashboardPatient] Update DashboardPatient Success',

    DeleteDashboardPatient = '[DashboardPatient] Delete DashboardPatient',
    DeleteDashboardPatientSuccess = '[DashboardPatient] Delete DashboardPatient Success',
    ClearDashboardPatients = '[DashboardPatient] Clear DashboardPatients'
}

export class LoadDashboardPatients implements Action {
    readonly type = DashboardPatientActionTypes.LoadDashboardPatients;

    constructor(public payload: { dashboardPatients: DashboardPatient[] }) {}
}
export class LoadDashboardPatientsSuccess implements Action {
    readonly type = DashboardPatientActionTypes.LoadDashboardPatientsSuccess;
}

export class LoadDashboardPatientOptions implements Action {
    readonly type = DashboardPatientActionTypes.LoadDashboardPatientOptions;
}

export class LoadDashboardPatientOptionsSuccess implements Action {
    readonly type = DashboardPatientActionTypes.LoadDashboardPatientOptionsSuccess;

    constructor(public payload: PatientOption[]) {}
}

export class LoadDashboardPatientProtocols implements Action {
    readonly type = DashboardPatientActionTypes.LoadDashboardPatientProtocols;
}

export class LoadDashboardPatientProtocolsSuccess implements Action {
    readonly type = DashboardPatientActionTypes.LoadDashboardPatientProtocolsSuccess;

    constructor(public payload: any[]) {}
}

export class AddDashboardPatientToProtocol implements Action {
    readonly type = DashboardPatientActionTypes.AddDashboardPatientToProtocol;

    constructor(public payload: { patientId: string | number; protocolId: string | number }) {}
}

export class AddDashboardPatientToProtocolSuccess implements Action {
    readonly type = DashboardPatientActionTypes.AddDashboardPatientToProtocolSuccess;
}

export class AddDashboardPatient implements Action {
    readonly type = DashboardPatientActionTypes.AddDashboardPatient;

    constructor(public payload: { dashboardPatient: DashboardPatient }) {}
}

export class AddDashboardPatientSuccess implements Action {
    readonly type = DashboardPatientActionTypes.AddDashboardPatientSuccess;
}

export class UpdateDashboardPatient implements Action {
    readonly type = DashboardPatientActionTypes.UpdateDashboardPatient;

    constructor(public payload: { dashboardPatient: Update<DashboardPatient> }) {}
}

export class UpdateDashboardPatientSuccess implements Action {
    readonly type = DashboardPatientActionTypes.UpdateDashboardPatientSuccess;

    constructor(public payload: { dashboardPatient: Update<DashboardPatient> }) {}
}

export class DeleteDashboardPatient implements Action {
    readonly type = DashboardPatientActionTypes.DeleteDashboardPatient;

    constructor(public payload: { id: string }) {}
}

export class DeleteDashboardPatientSuccess implements Action {
    readonly type = DashboardPatientActionTypes.DeleteDashboardPatientSuccess;
}

export class ClearDashboardPatients implements Action {
    readonly type = DashboardPatientActionTypes.ClearDashboardPatients;
}

export type DashboardPatientActions =
    | LoadDashboardPatients
    | LoadDashboardPatientOptions
    | LoadDashboardPatientOptionsSuccess
    | LoadDashboardPatientProtocols
    | LoadDashboardPatientProtocolsSuccess
    | AddDashboardPatient
    | UpdateDashboardPatient
    | UpdateDashboardPatientSuccess
    | DeleteDashboardPatient
    | DeleteDashboardPatientSuccess
    | ClearDashboardPatients;
