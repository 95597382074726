<div class="modal-header">
    <h4 class="modal-title">Opgeslagen</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <a href="{{link}}">{{ link }}</a>
    <br>
    <br>
    <span (click)="copyLink()" class="button cursor-pointer"><span class="icon-copy"></span> Kopieer link</span>

</div>
