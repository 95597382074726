import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { DashboardExisting } from '@alii-web/models/dashboard-existing.model';

export enum DashboardExistingActionTypes {
    LoadDashboardExistings = '[DashboardExisting] Load DashboardExistings',
    AddDashboardExisting = '[DashboardExisting] Add DashboardExisting',
    UpdateDashboardExisting = '[DashboardExisting] Update DashboardExisting',
    UpdateDashboardExistingSuccess = '[DashboardExisting] Update DashboardExisting Success',
    DeleteDashboardExisting = '[DashboardExisting] Delete DashboardExisting',
    DeleteDashboardExistingSuccess = '[DashboardExisting] Delete DashboardExisting Success',
    ClearDashboardExistings = '[DashboardExisting] Clear DashboardExistings',
    SelectDashboardExisting = '[DashboardExisting] DashboardExisting By Id'
}

export class LoadDashboardExistings implements Action {
    readonly type = DashboardExistingActionTypes.LoadDashboardExistings;

    constructor(public payload: { dashboardExistings: DashboardExisting[] }) {}
}

export class AddDashboardExisting implements Action {
    readonly type = DashboardExistingActionTypes.AddDashboardExisting;

    constructor(public payload: { dashboardExisting: DashboardExisting }) {}
}

export class UpdateDashboardExisting implements Action {
    readonly type = DashboardExistingActionTypes.UpdateDashboardExisting;

    constructor(public payload: { dashboardExisting: Update<DashboardExisting> }) {}
}

export class UpdateDashboardExistingSuccess implements Action {
    readonly type = DashboardExistingActionTypes.UpdateDashboardExistingSuccess;

    constructor(public payload: { dashboardExisting: Update<DashboardExisting> }) {}
}

export class DeleteDashboardExisting implements Action {
    readonly type = DashboardExistingActionTypes.DeleteDashboardExisting;

    constructor(public payload: { id: string }) {}
}
export class DeleteDashboardExistingSuccess implements Action {
    readonly type = DashboardExistingActionTypes.DeleteDashboardExistingSuccess;
}
export class ClearDashboardExistings implements Action {
    readonly type = DashboardExistingActionTypes.ClearDashboardExistings;
}
export class SelectDashboardExisting implements Action {
    readonly type = DashboardExistingActionTypes.SelectDashboardExisting;
    constructor(public payload: { dashboardId: string | number }) {}
}

export type DashboardExistingActions =
    | LoadDashboardExistings
    | AddDashboardExisting
    | UpdateDashboardExisting
    | UpdateDashboardExistingSuccess
    | DeleteDashboardExisting
    | DeleteDashboardExistingSuccess
    | ClearDashboardExistings
    | SelectDashboardExisting;
