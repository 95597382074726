import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import * as fromContainers from './containers';

const routes: Routes = [
    {
        path: '',
        component: fromContainers.ShopComponent
    },
    {
        path: ':id/sets',
        component: fromContainers.ShopComponent
    },
    {
        path: ':id',
        component: fromContainers.TemplateSetComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ShopRoutingModule {}
