<div class="modal-remove-data">
    <div class="modal-header">
        <h4 class="modal-title" [innerHTML]="headerTitle"></h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-form [formGroup]="form">
            <div class="form-group" [innerHTML]="bodyText"></div>
        </ng-form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default" type="button"
                (click)="handleCancel()">{{ 'CANCEL' | translate }}</button>
        <button class="btn btn-info" type="button" (click)="handleSubmit()">{{ 'SUBMIT' | translate }}</button>
    </div>
</div>
