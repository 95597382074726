import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { RuleGroup } from '@alii-web/models/outcome.interface';

export interface RuleGroupComponent {
    ruleGroup: RuleGroup;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-outcome-rule-group',
    templateUrl: './rule-group.component.html',
    styleUrls: ['./rule-group.component.scss']
})

export class RuleGroupComponent implements OnInit {
  @Input() 
  ruleGroup: RuleGroup;

  constructor(
    ) {
    }

    ngOnInit() {
    }

    shouldDisplayQuestionOperator(index: number) {
        const questionsAndAnswersLength = this.ruleGroup.questionsAndAnswers ? Object.keys(this.ruleGroup.questionsAndAnswers).length : 0;
        const ruleGroupsLength = this.ruleGroup.ruleGroups ? this.ruleGroup.ruleGroups.length : 0;
        const amountOfItems = questionsAndAnswersLength + ruleGroupsLength;
        return index < amountOfItems - 1;
        }

    shouldDisplayRuleGroupOperator(index: number) {
        return index < this.ruleGroup.ruleGroups.length - 1;
    }

}