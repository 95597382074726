import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class CommentService {
    constructor(private http: HttpClient) {}

    createComment(payload): Observable<any> {
        var action = ''
        var commentId = ''
        var replyTo = ''
        const { text, ppdId, comment, status } = payload;
        const url = `${environment.baseUrl}/api/protocol/addComment.vm`;
        if (status)
            {action = status === 'done' ? 'markAsDone' : 'markAsUndone'}
        if (comment) {
            commentId = comment.id
            replyTo = comment.id
            }
        const data = {
            text,
            ppdId: ppdId,
            action: action,
            commentId,
            replyTo
        };
        return this.http.post<any>(url, data).pipe(catchError((error: any) => throwError(error)));
    }
}
