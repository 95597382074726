<div class="modal-header">
    <h4 class="modal-title">{{ 'PARAGRAPH.EDIT.IMPORTREFERENCE'| translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-form [formGroup]="form">
        <div class="form-group">
            <label for="sheet-id">Sheet ID</label>
            <input id="sheet-id" class="form-control" formControlName="sheetId" (keyup.enter)="handleSubmit()"
                   placeholder="Insert sheet id here"/>
        </div>

        <button [disabled]="form.invalid" class="btn btn-primary" type="submit" (click)="handleSubmit()">{{ 'SUBMIT'| translate }}</button>
    </ng-form>
</div>
