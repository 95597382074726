import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBoxComponent } from './search-box.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

@NgModule({
    imports: [CommonModule, NgbModule, FormsModule, TypeaheadModule.forRoot()],
    declarations: [SearchBoxComponent],
    exports: [SearchBoxComponent]
})
export class SearchBoxModule {}
