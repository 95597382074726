import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { OrganisationService } from '../../../../services';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'manage-messages',
    templateUrl: './manage-messages.component.html',
    styleUrls: ['./manage-messages.component.scss']
})
export class ManageMessagesComponent implements OnInit {
    messages$: Observable<any>;
    public options = {
        key: environment.froala.key,
        attribution: false,
        heightMin: 120,
        quickInsertTags: [''],
        pastePlain: true,
        charCounterCount: false,
        toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'formatUL', 'formatOL', 'insertLink'],
        events: {
            // Froala bugfix insert table from google sheets
            'paste.afterCleanup': html => html.replace(';width:0px;', ';')
        }
    };
    newMessageText: string;
    usergroups$: Observable<any>;
    dateToday: string;
    private id: string;
    editing: boolean;
    message$: Observable<any>;
    messageForm: FormGroup;
    text: string;

    constructor(
        private service: OrganisationService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private router: Router
    ) {}

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');

        if (this.id) {
            this.editing = true;
        }

        this.dateToday = new Date().toISOString().slice(0, 10);
        this.messageForm = this.fb.group({
            title: ['', Validators.required],
            text: ['', Validators.required],
            messageId: [],
            category: [],
            usergroupIds: [],
            date: [this.dateToday, Validators.required],
            push: []
        });
        this.messages$ = this.service.getMessages();

        if (this.editing) {
            this.message$ = this.messages$.pipe(
                map(messages => messages.messages.find(currentmessage => currentmessage.id === this.id))
            );

            this.message$.subscribe(message => {
                this.messageForm = this.fb.group({
                    title: [message.title, Validators.required],
                    messageId: [message.id, Validators.required],
                    text: [message.text, Validators.required],
                    usergroupIds: [],
                    category: [message.category]
                });
            });
        }

        this.usergroups$ = this.service.getUserGroups();
    }

    onSearch(query) {
        this.messages$ = this.service.getMessages(query);
    }

    submit() {
        if (this.messageForm.value.usergroupIds[0] === '') {
            this.messageForm.value.usergroupIds = [];
        }

        const payload = { ...this.messageForm.value };
        this.service.postMessage(payload).subscribe(() => {
            this.router.navigate(['/messages']);
        });

        return false;
    }

    removeMessage(id) {
        this.service.removeMessage(id).subscribe(() => {
            document.location.reload();
        });
        return false;
    }

    trackByFn = (index, item) => item.id || index;
}
