<div style="text-align:right">
    <a (click)="resetModel()" class="tag">Reset Model</a>
</div>
<ng-container *ngIf="!viewByPopulation">
    <ng-container *ngTemplateOutlet="defaultView"></ng-container>
</ng-container>
<ng-container *ngIf="viewByPopulation">
    <ng-container *ngTemplateOutlet="byPopulationView"></ng-container>
</ng-container>

<ng-template #ShowAllOutcomeSwitch>
    <br />
    <div *ngIf="modelSource !== 'evidencio' && ObjectToArray(findings).length > 0" class="pull-right">
        <a
            class="tag"
            [ngClass]="{ '-primary': showAllOutcomes }"
            (click)="onToggleShowAllOutcomes()"
        >Show all outcomes</a
        >
    </div>
    <div class="clearfix"></div>
    <br />
</ng-template>

<ng-template #defaultView>
    <div style="padding-top: 6px; padding-bottom: 6px;">
        <div
            *ngFor="let finding of FindingsObjectToArray(findings); trackBy: trackByFn"
            class="row"
            style="border: 1px solid #efefef; padding: 2px; margin-top: 6px;"
        >
            <div class="col-sm-6">
                <div class="RowTitle">
                    <question-title-edit
                        [protocolId]="''+protocolId"
                        [id]="finding.id"
                        [type]="'finding'"
                        [parentId]="''+parentId"
                        [title]="finding['protocol_paragraph.title']"
                        (eventBus)="handleEventBus($event)"
                    >
                    </question-title-edit>
                    <!--todo: styling (font too big)-->
                    <alii-web-text-edit
                        [text]="finding['protocol_paragraph.text']"
                        [parId]="finding.id"
                        [isEditAble]="true"
                    >
                    </alii-web-text-edit>
                    <a class="tag addSlider" (click)="onClickRemoveFinding(finding.id)">
                        Remove
                    </a>
                </div>
            </div>

            <div class="col-sm-6">
                <ng-container *ngFor="let option of ObjectToArray(finding.options); trackBy: trackByFn">
                    <div style="text-align:right;">
                        <ng-container *ngIf="option.type === 'categorical' || option.type === 'discrete'">
                            <div class="row mb-2">
                                <div class="col-sm-9" style="padding-right: 0 !important;">
                                    <div
                                        (click)="onChangeOption($event, option, finding.id)"
                                        style="cursor:pointer;"
                                        class="button storeCase"
                                        [ngClass]="!!option.selected && option.selected ? '-primary' : '-secondary'"
                                        style="text-align: center; padding-bottom: 2px; padding-top: 2px;"
                                    >
                                        {{ option.title | asHtml }}
                                    </div>
                                </div>
                                <div class="col-sm-3" style="padding-left: 0 !important;">
                                    <a
                                        style="cursor:pointer;"
                                        class="tag btn-info"
                                        (click)="onClickOpenOptionValueEditModal(finding.id, option)"
                                    >
                                        <span class="icon-pencil-square-o"></span>
                                    </a>
                                    <a
                                        style="cursor:pointer;"
                                        class="tag btn-danger"
                                        (click)="onClickRemoveOptionValueModal(finding.id, option.id)"
                                    >
                                        <span class="icon-trash-o"></span>
                                    </a>
                                </div>
                            </div>
                        </ng-container>

                        <div *ngIf="option.type === 'continuous'" style="text-align:right;">
                            <div class="input-group mb-3">
                                <input
                                    type="number"
                                    (input)="onClickOption($event, option, finding.id)"
                                    class="numberEntry pull_right form-control"
                                />
                                <div
                                    class="input-group-prepend"
                                    (click)="onClickOpenSliderEditModal(finding.id, option)"
                                >
                                    <span class="input-group-text btn btn-info">
                                        <span class="icon-pencil-square-o"></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="ObjectToArray(finding.options).length === 0">
                    <div style="text-align:right;">
                        <a
                            class="tag addSlider"
                            (click)="onClickOpenSliderAddModal(finding.id)"
                        >
                            Add slider
                        </a>
                        <a
                            class="tag addOption"
                            (click)="onClickOpenOptionValueAddModal(finding.id)"
                        >
                            Add option
                        </a>
                    </div>
                </ng-container>
                <ng-container *ngIf="finding.options.length !== 0">
                    <div style="text-align:right;">
                        <a
                            *ngIf="checkOptionsType(finding.options, 'categorical') || checkOptionsType(finding.options, 'continuous')"
                            class="tag addOption"
                            (click)="onClickOpenOptionValueAddModal(finding.id)"
                        >
                            Add option
                        </a>
                        <a
                            *ngIf="checkOptionsType(finding.options, 'continuous') || checkOptionsType(finding.options, 'categorical')"
                            class="tag addSlider"
                            (click)="onClickOpenSliderAddModal(finding.id)"
                        >
                            Add slider
                        </a>
                    </div>
                </ng-container>
            </div>
        </div>
        <ng-container *ngTemplateOutlet="ShowAllOutcomeSwitch"></ng-container>
        <ng-container *ngFor="let outcome of outcomes; trackBy: trackByFn">
            <div class="row" style="border: 1px solid #efefef; padding: 0; border-bottom: 0; margin-top: 8px;">
                <div class="col-sm-12" style="padding:0; margin: 0">
                    <div class="RowTitle" style="background: #C2E3DC; padding: 5px;">
                        <div class="btn-group" ngbDropdown placement="bottom-right" style="float:right;">
                            <button
                                type="button"
                                class="button -light"
                                style="background-color: transparent; border: 0; color: #706F6E;"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                ngbDropdownToggle
                            >
                                <i class="fa fa-cog"></i>
                            </button>
                            <ul class="dropdown-menu right" ngbDropdownMenu>
                                <li>
                                    <a
                                        class="dropdown-item"
                                        style="cursor: pointer;"
                                        (click)="onClickAddPopulation(outcome)"
                                    >
                                        Add population
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <question-title-edit
                            [protocolId]="protocolId"
                            [id]="outcome.id"
                            [parentId]="parentId"
                            [title]="outcome.title"
                            [type]="'outcome'"
                            (eventBus)="handleEventBus($event)"
                        >
                        </question-title-edit>
                    </div>
                </div>
            </div>
            <div class="row wysiwyg" style="border: 1px solid #efefef; border-bottom: 0">
                <div class="col-sm-12" style="padding-top: 8px">
                    <alii-web-text-edit
                        [text]="outcome.text"
                        [parId]="outcome.id"
                        [isEditAble]="true"
                        [title]="outcome.title"
                    >
                    </alii-web-text-edit>
                </div>
            </div>
            <div class="row" style="border: 1px solid #efefef; padding: 0; border-top: 0">
                <div class="col-sm-12 wysiwyg">
                    <strong
                        *ngIf="outcome.findingsToProcess === true"
                        title="Has pending findings"
                        class="icon-exclamation-circle small"
                    ></strong
                    >&nbsp;
                    <a
                        href="javascript:void(0)"
                        (click)="toggleShowPopulations(outcome.id)"
                        class="small"
                        *ngIf="outcome.populations.length"
                    >{{ !populationsVisible(outcome.id) ? 'Show' : 'Hide' }} relevant situations
                    </a>
                </div>

                <div *ngIf="populationsVisible(outcome.id)" class="col-sm-12">
                    <ng-container *ngFor="let population of outcome.populations; let i = index; trackBy: trackByFn">
                        <div class="wysiwyg" *ngIf="i === 0">
                            <p class="small"><strong> This outcome is shown in the following situations</strong></p>
                        </div>

                        <div class="small" style="border: 0px solid #efefef;">
                            <div id="population-{{ population.id }}">
                                <div
                                    class="btn-group"
                                    ngbDropdown
                                    placement="bottom-right"
                                    style="float:right; margin-right: -10px"
                                >
                                    <button
                                        type="button"
                                        class="button no-after"
                                        style="background-color: transparent; border: 0; color: #706F6E;"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        ngbDropdownToggle
                                    >
                                        <i class="fa fa-cog"></i>
                                    </button>
                                    <ul class="dropdown-menu right" ngbDropdownMenu>
                                        <li *ngIf="getPopulationGradeAction(population.id) === 'editGradeAssessment'">
                                            <a
                                                class="dropdown-item"
                                                style="cursor: pointer;"
                                                (click)="onClickCreateGrade({ populationId: population.id })"
                                            >
                                                Edit grade review
                                            </a>
                                        </li>
                                        <li *ngIf="getPopulationGradeAction(population.id) === 'addGradeAssessment'">
                                            <a
                                                class="dropdown-item"
                                                style="cursor: pointer;"
                                                (click)="onClickAddPopulationGrade({ populationId: population.id })"
                                            >
                                                Add grade review
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                class="dropdown-item"
                                                style="cursor: pointer;"
                                                (click)="onClickEditPopulation(population, outcome)"
                                            >
                                                Edit population
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                class="dropdown-item"
                                                style="cursor: pointer;"
                                                (click)="onClickDeletePopulation(outcome.id, population.id)"
                                            >
                                                Delete population
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul>
                                <li class="wysiwyg">
                                    {{ population.name }}
                                    <div>
                                        <div
                                            *ngFor="let prop of population.population_properties; trackBy: trackByFn"
                                            style="cursor:pointer;"
                                            class="button -secondary"
                                            style="text-align: center; margin: 4px; padding-bottom: 2px; padding-top: 2px; float: left !important;"
                                            data-toggle="tooltip"
                                            title="{{ prop.question }}"
                                        >
                                            {{ prop.choice }}
                                        </div>
                                    </div>
                                    <br style="clear: both;" />
                                    <div
                                        class="col-sm-4"
                                        style="float: left; margin-left:0; padding-left:0"
                                        *ngIf="!!population.gradeAssessment.quality_of_evidence"
                                    >
                                        <div style="float: left; margin-right:10px">Quality of evidence</div>
                                        <div
                                            *ngFor="
                                                let num of numberToList(population.gradeAssessment.quality_of_evidence)
                                            "
                                            class="icon-circle"
                                            style="color:#C2E3DC; float:left; margin-top:5px; margin-right:1px;"
                                        ></div>
                                        <div
                                            *ngFor="
                                                let num of numberToList(
                                                    4 - population.gradeAssessment.quality_of_evidence
                                                )
                                            "
                                            class="icon-circle"
                                            style="color:#ddd; float:left; margin-top:5px; margin-right:1px;"
                                        ></div>
                                    </div>
                                    <div
                                        *ngIf="checkShowEvidencePopulation(population)"
                                        style="line-height: 2em; float: left;"
                                    >
                                        <a
                                            href="#"
                                            (click)="
                                                onClickReferencesOnPopulation({
                                                    populationId: population.id,
                                                    ppdId: population.id
                                                })
                                            "
                                        >
                                            {{
                                            arrayIsCollapsedPopulations[population.id] ? 'Show' : 'Hide'
                                            }}
                                            evidence</a
                                        >
                                    </div>
                                    <br style="clear: both;" />
                                </li>
                            </ul>
                        </div>
                        <div class="row pl-4">
                            <div
                                class="col-sm-12"
                                id="collapseExample"
                                [ngbCollapse]="arrayIsCollapsedPopulations[population.id]"
                            >
                                <ng-container
                                    *ngTemplateOutlet="
                                        gradesAssesmentView;
                                        context: getGradeViewTemplateContext(population.id)
                                    "
                                >
                                </ng-container>
                            </div>
                        </div>
                        <div class="row pl-4" style="margin-bottom: 10px;">
                            <div
                                class="col-sm-12"
                                id="collapseExample"
                                [ngbCollapse]="arrayIsCollapsedPopulations[population.id]"
                            >
                                <ng-container
                                    *ngTemplateOutlet="listOfSources; context: { sources: population.sources }"
                                >
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>

    <div style="padding-top: 6px; padding-bottom: 6px;"></div>
    <div style="padding: 8px;">
        <div *ngIf="modelMessages?.score">Score: {{ modelMessages?.score }}</div>
    </div>
    <div class="float-right" *ngIf="modelSource === 'evidencio'">
        <span class="small"
        >powered by:
            <a href="https://www.evidencio.com/" target="_blank"
            ><img style="height:1.5em" src="/assets/images/evidencio.svg"
            /></a>
        </span>
    </div>
</ng-template>

<ng-template #byPopulationView>
    <div style="padding-top: 6px; padding-bottom: 6px;">
        <div
            *ngFor="let population of populations; trackBy: trackByFn"
            class="container"
            style="border: 1px solid #efefef;"
        >
            <div class="row">
                <div
                    class="wysiwyg w-100"
                    style="background: #C2E3DC; max-width: 100%; padding-left: 15px; margin-bottom: 10px;"
                >
                    <h5>
                        <population-name-edit
                            (eventBus)="handleEventBus($event)"
                            [outcomeId]="population.outcome.id"
                            [populationId]="population.id"
                            [name]="population.name"
                        ></population-name-edit>
                    </h5>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 wysiwyg">
                    <div class="RowTitle">
                        <question-title-edit
                            [protocolId]="''+protocolId"
                            [id]="population.outcome.id"
                            [parentId]="''+parentId"
                            [title]="population.outcome.title"
                            [type]="'outcome'"
                            (eventBus)="handleEventBus($event)"
                            [inputType]="'textarea'"
                        >
                        </question-title-edit>
                        <alii-web-text-edit
                            [text]="population.outcome.text"
                            [parId]="population.outcome.id"
                            [isEditAble]="true"
                            [title]="population.outcome.title"
                        >
                        </alii-web-text-edit>
                    </div>
                </div>
                <div class="col-sm-4 pr-0" *ngIf="population.outcome.gradeAssessment.action === 'addGradeAssessment'">
                    <div
                        *ngIf="population.outcome.gradeAssessment.action === 'addGradeAssessment'"
                        class="button-group pull-right"
                    >
                        <div class="btn-group" ngbDropdown placement="bottom-right">
                            <button
                                type="button"
                                class="button -light"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                ngbDropdownToggle
                            >
                                <i class="fa fa-cog"></i>
                            </button>
                            <ul class="dropdown-menu right" ngbDropdownMenu>
                                <li>
                                    <a
                                        *ngIf="population.outcome.gradeAssessment.action === 'addGradeAssessment'"
                                        class="dropdown-item"
                                        style="cursor: pointer;"
                                        (click)="onClickCreateGrade({ ppdId: population.outcome.id })"
                                    >
                                        add grade review
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div style="margin-bottom: 25px;">
                        <ng-container *ngIf="!!population.outcome.gradeAssessment.quality_of_evidence">
                            <div class="small user-text">quality of evidence</div>
                            <div
                                *ngFor="let num of numberToList(population.outcome.gradeAssessment.quality_of_evidence)"
                                class="icon-circle"
                                style="color:#C2E3DC; float:left"
                            ></div>
                            <div
                                *ngFor="
                                    let num of numberToList(4 - population.outcome.gradeAssessment.quality_of_evidence)
                                "
                                class="icon-circle"
                                style="color:#ddd; float:left"
                            ></div>
                        </ng-container>
                    </div>

                    <div
                        *ngFor="let prop of population.population_properties; trackBy: trackByFn"
                        style="text-align:right; clear:both"
                    >
                        <div
                            style="cursor:pointer;"
                            class="button -secondary"
                            style="text-align: center; margin: 4px; padding-bottom: 2px; padding-top: 2px;"
                        >
                            {{ prop.choice }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="button-group -borderless -toolbar">
                        <i
                            class="button is-empty -nopaddingLeft"
                            [ngClass]="{ 'is-active': newFindings(population.outcome.sources) }"
                            (click)="
                                onClickReferencesOnPopulation({
                                    populationId: population.id,
                                    ppdId: population.outcome.id
                                })
                            "
                            [attr.aria-expanded]="!arrayIsCollapsedPopulations[population.id]"
                            aria-controls="collapseExample"
                            style="cursor:pointer;"
                        >
                            <span class="icon-alii-book"></span> references ({{ population.outcome.sources.length }})
                        </i>
                    </div>
                </div>
                <div class="col-md-6"></div>
            </div>
            <div class="row pl-4" *ngIf="population.outcome.gradeAssessment.action === 'editGradeAssessment'">
                <div class="col-sm-12" id="collapseExample" [ngbCollapse]="arrayIsCollapsedPopulations[population.id]">
                    <ng-container *ngTemplateOutlet="listOfSources; context: { sources: population.outcome.sources }">
                    </ng-container>
                </div>
            </div>
            <div class="row pl-4" *ngIf="population.outcome.gradeAssessment.action === 'editGradeAssessment'">
                <div class="col-sm-12" id="collapseExample" [ngbCollapse]="arrayIsCollapsedPopulations[population.id]">
                    <ng-container
                        *ngTemplateOutlet="
                            gradesAssesmentView;
                            context: getGradeViewTemplateContext(population.outcome.id)
                        "
                    >
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="ShowAllOutcomeSwitch"></ng-container>
</ng-template>

<ng-template #gradesAssesmentView let-gradeAssessment="gradeAssessment" let-ppdId="ppdId">
    <table class="table table-striped" *ngIf="hasSelectedGrade()">
        <tbody>
        <ng-container *ngFor="let gradeValue of convertToArray(gradeAssessment.values); index as i">
            <tr class="d-flex">
                <td class="col-6 text-capitalize">{{ formatGradeKey(gradeValue.key) }}</td>
                <td class="col-4">{{ gradeValue.value }}</td>

                <td class="col-2">
                        <span
                            *ngIf="isQuestionExist(gradeValue.key)"
                            class="tag -primary pull-right"
                            (click)="onClickUpdateGrade({ ppdId: ppdId, field: gradeValue.key })"
                        >
                            Update
                        </span>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</ng-template>
<ng-template #listOfSources let-sources="sources">
    <div style="margin-left:15px" *ngIf="sources.length">
        <strong>Studies</strong>
        <ng-container *ngFor="let article of sources; trackBy: trackByFn">
            <h5
                (click)="toggleFindings(article.id)"
                class="wysiwyg small user-text"
                style="cursor: pointer; font-weight: bold;"
            >
                {{ article.label }}

                &nbsp; <a (click)="onClickArticle(article)" href="#" style="font-weight: normal;">Show article</a>
            </h5>
            <ng-container>
                <table
                    class="table -aliiTable"
                    style="margin-bottom: 30px; font-size:.8em"
                    *ngIf="article.finding.length"
                >
                    <tbody>
                    <tr *ngFor="let finding of article.finding; let i = index; trackBy: trackByFn">
                        <td style="padding-left: 0">
                            <ng-container *ngFor="let row of summaryOfFinding(finding); trackBy: trackByFn">
                                <div class="form-group row mb-0">
                                    <label class="col-sm-5 col-form-label text-sentence wysiwyg">
                                        {{ formatGradeKey(row.key) }}
                                    </label>
                                    <div class="col-sm-7">
                                        <span class="form-control-plaintext wysiwyg" [innerHTML]="row.value"></span>
                                    </div>
                                </div>
                            </ng-container>
                        </td>
                        <td class="text-right">
                            {{ finding.status }}

                            <span
                                class="tag -primary ml-2"
                                (click)="handleClickIncludeSource(finding, article.articleId)"
                            >
                                    {{ finding.actionLabel }}
                                </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
