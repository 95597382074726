import * as fromDashboardCategory from '../actions/dashboard-category.action';
import { DashboardCategory } from '@alii-web/models/dashboard-category.model';

export interface DashboardCategoryState {
    dashboardId: string;
    data: DashboardCategory[];
    loaded: boolean;
    loading: boolean;
}

export const initialState: DashboardCategoryState = {
    dashboardId: null,
    data: [],
    loaded: false,
    loading: false
};

export function reducer(
    state = initialState,
    action: fromDashboardCategory.DashboardCategoriesAction
): DashboardCategoryState {
    switch (action.type) {
        case fromDashboardCategory.LOAD_DASHBOARD_CATEGORIES: {
            const dashboardId = action.dashboardId;
            return {
                ...state,
                dashboardId,
                loading: true
            };
        }

        case fromDashboardCategory.LOAD_DASHBOARD_CATEGORIES_SUCCESS: {
            const data = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                data
            };
        }

        case fromDashboardCategory.LOAD_DASHBOARD_CATEGORIES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false
            };
        }
    }

    return state;
}

export const getDashboardCategoryLoading = (state: DashboardCategoryState) => state.loading;
export const getDashboardCategoryLoaded = (state: DashboardCategoryState) => state.loaded;
export const getDashboardCategory = (state: DashboardCategoryState) => state.data;
