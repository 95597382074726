<h3>Dashboards</h3>
<h4>Edit dashboard</h4>
<p>
    <b>Title </b>: {{ dashboard?.name }}
    <a type="tag" class="tag" (click)="onClickEditDashboard()"> edit </a>
    <a class="tag" (click)="onClickDeleteDashboard()">remove</a>
</p>
<p></p>
<div class="table-responsive">
    <table class="table -aliiTable">
        <thead>
        <tr>
            <th class="table__text table__title table__column-80" scope="col">
                Categories in this dashboard
            </th>
            <th class="table__text table__column-30" scope="col">Options</th>
        </tr>
        </thead>

        <tbody>
        <tbody [sortablejs]="items" [sortablejsOptions]="options">
        <tr *ngFor="let category of categories; trackBy: trackByFn" [id]="category.id">
            <td class="table__text table__title table__actions">{{ category.name }}</td>
            <td class="table__text table__title table__actions">
                <a
                    class="tag"
                    style="cursor: pointer;"
                    (click)="onClickRemoveDashboardTag(category.id)"
                >
                    remove
                </a>
            </td>
        </tr>
        </tbody>
    </table>
</div>
<div class="col-sm-8">
    <ng-form [formGroup]="addTagForm">
        <div class="input-group">
            <select
                id="tagSelect"
                type="dropdown"
                formControlName="tagId"
                class="tagSelect form-control"
            >
                <option value="" selected="" disabled="">Add category</option>
                <option
                    [value]="category.id"
                    *ngFor="let category of allCategories?.tags; trackBy: trackByFn"
                >
                    {{ category.title }}
                </option>
            </select>

            <button class="btn btn-primary" type="submit" (click)="onSubmitAddDashboardTag()">
                submit
            </button>

            <button class="btn btn-primary" type="submit" (click)="onClickCreateDashboardTag()">
                new
            </button>
        </div>
    </ng-form>
</div>
