import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromTerms from './terms.reducer';

export interface TermsFeatureState {
    terms: fromTerms.TermsState;
}

export const reducers: ActionReducerMap<TermsFeatureState> = {
    terms: fromTerms.reducer
};

export const getTermsFeatureState = createFeatureSelector<TermsFeatureState>('TermsFeature');
