<alii-web-spinner-loading *ngIf="(loading$ | async)"></alii-web-spinner-loading>
<section *ngIf="(profile$ | async) as profile" class="page__section -no-bottom -transparent">
    <div class="sectionTitle">
        <div class="sectionTitle__title">Profile</div>
        <div class="sectionTitle__actions">
            <a href="#" class="btn btn-info" role="button" [routerLink]="['edit']" *ngIf="profile.canEditDetails">
                Update profile
            </a>
        </div>
    </div>
    <article class="card p-2">
        <header class="card__header">
            <span class="card__header-heading"> {{ profile.last_name }}, {{ profile.first_name }} </span>
        </header>
        <div class="card__main">
            <div class="card__main-inner">
                <table class="table">
                    <tbody>
                    <tr>
                        <td>Email</td>
                        <td> : </td>
                        <td [innerHTML]="profile.email"></td>
                    </tr>
                    <tr>
                        <td>Hospital</td>
                        <td> : </td>
                        <td [innerHTML]="profile.hospital_label"></td>
                    </tr>
                    <tr>
                        <td>Organization</td>
                        <td> : </td>
                        <td [innerHTML]="profile.organization_label"></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </article>
</section>
