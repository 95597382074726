import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { AuthService, NavigationService } from '@alii-web/services';
import { NavigationTab } from '@alii-web/models/navigation.interface';
import { SearchBoxMatch } from '@alii-web/modules/search/models';
import { navigateToPage } from '@alii-web/modules/search/lib';
import * as fromStore from '@alii-web/modules/search/store';
import { ConfirmModalComponent } from '@alii-web/modules/protocols/entry-components';
import { ThemingService } from '../../services/theming.service';

const cn = 'NavigationComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() maxSearchResults = 5;

    protocolId: string;
    current: string;

    tabs: NavigationTab[];
    collapsed: boolean;
    defaultCollapsed: boolean;

    query = '';
    language: string;

    prevLoggedIn: boolean;

    subscriptions: Subscription[] = [];

    tabs$: Observable<NavigationTab[]>;
    ntabs: number;

    @HostListener('window:resize', ['$event']) handleResize() {
        this._handleResize();
    }

    constructor(
        private themingService: ThemingService,
        private modalService: NgbModal,
        private router: Router,
        private renderer: Renderer2,
        private navigationService: NavigationService,
        private hostElement: ElementRef,
        private cdr: ChangeDetectorRef,
        private translate: TranslateService,
        private store: Store<fromStore.SearchFeatureState>,
        private auth: AuthService,
    ) {}

    ngOnInit() {
        this.collapsed = false;
        this.defaultCollapsed = false;
        this.prevLoggedIn = false;

        this._handleLanguage();
        this._setupNavigationEnd();
        this.current = this._convertCurrent(this.current);

        this._handleResize();
        this.tabs$ = this.navigationService.tabsChanged$.pipe(
            tap(tabs => {
                this.ntabs = (tabs || []).length;
            })
        );
    }

    ngAfterViewInit() {
        this._handleResize();
        setTimeout(() => !this.ntabs && this.navigationService.getTabs().subscribe(), 1000);
        let theme = this.themingService.getTheme()
        this.themingService.setTheme(theme)
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    setLang(lang: string) {
        this.translate.use(lang);
    }

    verifyLogout() {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        if (modalRef) {
            modalRef.componentInstance.data = {
                title: 'LOGOUT.CONFIRM.TITLE',
                text: 'LOGOUT.CONFIRM.TEXT'
            };
            modalRef.result.then(
                () => this.auth.logout(),
                () => {}
            );
        } else {
            console.error(`${cn} confirm() cannot open confirm modal component`);
            return false;
        }
    }

    onFocus(): void {
        this._expand();
    }

    expand() {
        this._expand();
    }

    onBlur(): void {
        // Only collapse if the default is collapsed.
        if (this.defaultCollapsed) {
            this._collapse();
        }
    }

    collapse() {
        this._collapse();
    }

    onSelect(searchBoxMatch: SearchBoxMatch) {
        if (searchBoxMatch.anchorId) {
            this.store.dispatch(new fromStore.SearchSetAnchorId(searchBoxMatch.anchorId));
        }
        navigateToPage(searchBoxMatch, this.router);
    }

    private _setupNavigationEnd() {
        this.subscriptions.push(
            this.router.events
                // We only want to trigger on navigation end events.
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe((event: NavigationEnd) => {
                    const url = event.url;
                    const segments = url.split('/');
                    this.protocolId = segments.length > 2 && segments[1] === 'protocols' ? segments[2] : null;
                    if (segments.length > 1) {
                        this.current = this._convertCurrent(segments[1]);
                    }
                    this.defaultCollapsed = segments[1] === 'protocols';
                    if (this.defaultCollapsed) {
                        this._collapse();
                    } else {
                        this._expand();
                    }
                    setTimeout(() => {
                        this._handleResize();
                        this.cdr.markForCheck();
                    });
                })
        );
    }

    private _convertCurrent(current: string): string {
        const convert = {
            dashboard: 'home',
            calendar: 'agenda',
            community: 'team',
            messages: 'news',
            shop: 'bibliotheek'
        }[current];

        return convert || current;
    }

    private _handleLanguage() {
        this.subscriptions.push(
            this.translate.onLangChange.subscribe((e: LangChangeEvent) => {
                this.language = e.lang;
                this.cdr.detectChanges();
            })
        );
    }

    private _expand(): void {
        if (this.collapsed) {
            // Expand the sidebar if not already collapsed.
            const sidebar = this.hostElement.nativeElement.closest('.header');
            if (sidebar.classList.contains('-is-collapsed')) {
                this.renderer.removeClass(sidebar, '-is-collapsed');
                this.collapsed = false;
            }
        }
    }

    private _collapse(): void {
        if (!this.collapsed) {
            // Collapse the sidebar if not already collapsed.
            const sidebar = this.hostElement.nativeElement.closest('.header');
            if (!sidebar.classList.contains('-is-collapsed')) {
                this.renderer.addClass(sidebar, '-is-collapsed');
                setTimeout(() => {
                    this.collapsed = true;
                    this.cdr.detectChanges();
                }, 1000);
            }
        }
    }


    private _handleResize() {
        // TODO: This is not the best solution, but for the time being it works fine. Later need to use css flex to
        // get things right and make sure it is also supported on IE11 (flex doesn't work).
        const TIMEOUT = 200;
        const MAX = 5000;
        let msecs = 0;
        // TODO: Replace with RXJS timer take until which is more elegant.
        const timerId = setInterval(() => {
            const elMainHeader = document.getElementById('main-header');
            const elHeaderBranding = document.getElementById('header-branding');
            const elSideMenu = document.getElementById('navigation-sidemenu');
            if (elMainHeader && elHeaderBranding && elSideMenu) {
                // Found so resize if not already resized.
                
                if (elSideMenu.offsetHeight !== elMainHeader.offsetHeight - elHeaderBranding.offsetHeight - 5) {
                    this.renderer.setStyle(
                        elSideMenu,
                        'height',
                        elMainHeader.offsetHeight - elHeaderBranding.offsetHeight - 5 + 'px'
                    );
                }
                clearInterval(timerId);
            } else {
                // Not found.
                msecs += TIMEOUT;
                if (msecs >= MAX) {
                    console.warn(`${cn} _handleResize() missing element`);
                    clearInterval(timerId);
                }
            }
        }, TIMEOUT);
    }
}
