import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as protocolActions from '../actions/protocols.action';
import * as gradeActions from '../actions/grade-assessment.action';
import * as fromService from '../../../../services';

@Injectable()
export class GradeAssessmentEffects {
    constructor(private actions$: Actions, private LiteraturesService: fromService.LiteraturesService) {}

    @Effect()
    loadGradeQuestionsEffect$ = this.actions$.pipe(
        ofType(gradeActions.LOAD_GRADEASSESSMENT),
        switchMap(action => {
            const {
                payload: { gradeId, ppdId, gradeType = 'ppd' }
            } = action as any;
            return this.LiteraturesService.getGradeAssessment({ gradeId, ppdId, gradeType }).pipe(
                map(response => new gradeActions.LoadGradesSucces({ ppdId, questions: response.questions, gradeRowData: response.gradeRowData, gradeColumnDefs: response.gradeColumnDefs })),
                catchError(error => of(new gradeActions.LoadGradesFail(error)))
            );
        })
    );

    @Effect()
    createGradeValueEffect$ = this.actions$.pipe(
        ofType(gradeActions.CREATE_GRADEASSESSMENT),
        switchMap(action => {
            const { payload } = action as any;
            return this.LiteraturesService.createGradeValue(payload).pipe(
                map(message => 
                    {   
                        message.parentId = payload.parentId
                        return new gradeActions.CreateGradeValueSucces(message)
                    },
                catchError(error => of(new gradeActions.CreateGradeValueFail(error)))
                ))}
        )
    );

    @Effect()
    updateGradeValueEffect$ = this.actions$.pipe(
        ofType(gradeActions.UPDATE_GRADEASSESSMENT),
        mergeMap(action => {
            const { payload } = action as any;
            return this.LiteraturesService.updateGradeValue(payload).pipe(
                map(response => new gradeActions.UpdateGradeValueSucces(response)),
                catchError(error => of(new gradeActions.UpdateGradeValueFail(error)))
            );
        })
    );

    @Effect()
    deleteGradeEffect$ = this.actions$.pipe(
        ofType(gradeActions.DELETE_GRADEASSESSMENT),
        mergeMap(action => {
            const { payload } = action as any;
            return this.LiteraturesService.deleteGrade(payload).pipe(
                map(_response => new gradeActions.DeleteGradeSuccess(payload)),
                catchError(error => of(new gradeActions.DeleteGradeFail(error)))
            );
        })
    );

    @Effect()
    deleteGradeSuccessEffect$ = this.actions$.pipe(
        ofType(gradeActions.DELETE_GRADEASSESSMENT_SUCCESS),
        map(action => {
            const { payload } = action as any;
            return new protocolActions.SetGradeStatus({...payload, status: 'hasNoGrade'})
        })
    );

    @Effect()
    createGradeSuccessEffect$ = this.actions$.pipe(
        ofType(gradeActions.CREATE_GRADEASSESSMENT_SUCCESS),
        map(action => {
            const { payload } = action as any;
            return new protocolActions.SetGradeStatus({...payload, status: 'hasGrade'})
        })
    );


    @Effect()
    updateGradeRowEffect$ = this.actions$.pipe(
        ofType(gradeActions.UPDATE_GRADEROW),
        mergeMap(action => {
            const { payload } = action as any;
            return this.LiteraturesService.updateGradeRow(payload).pipe(
                map(() => new gradeActions.UpdateGradeRowSuccess(payload)),
                catchError(error => of(new gradeActions.UpdateGradeValueFail(error)))
            );
        })
    );

}
