import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable } from 'rxjs';

import { Protocol } from '../../../../models/protocol.model';

interface PayloadQuery {
    query: string;
}
interface PayloadProtocolId {
    protocolId: string;
}
interface EventEmitterData {
    type: string;
    payload: PayloadQuery | PayloadProtocolId;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-protocol-import',
    templateUrl: './protocol-import.component.html'
})
export class ProtocolImportComponent implements OnInit {
    @Input()
    protocols: Observable<Protocol[]>;

    @Output()
    eventBus: EventEmitter<EventEmitterData> = new EventEmitter<EventEmitterData>();

    typeaheadLoading: boolean;

    form: FormGroup;
    loading: boolean;

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.loading = false;
        this.form = this.fb.group({
            protocolId: ['', Validators.required],
            query: ['']
        });
    }
    onSearch(e: Event) {
        const query = (e.target as HTMLInputElement).value;
        const event = {
            type: 'handleGetProtocolsByQuery',
            payload: {
                query
            }
        };
        this.eventBus.emit(event);
    }

    changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

    typeaheadOnSelect(e: TypeaheadMatch): void {
        const item = e.item;
        this.form.controls['protocolId'].setValue(item.id);
    }
    onSubmit() {
        if (this.form.valid) {
            const { protocolId } = this.form.value;
            const event = {
                type: 'handleImportProtocol',
                payload: {
                    protocolId
                }
            };
            this.eventBus.emit(event);
        }
    }
}
