                    <h3>{{ 'DASHBOARD.MANAGE.DASHBOARDS' | translate }}</h3>
<h3>{{ 'DASHBOARD.MANAGE.DASHBOARDS' | translate }}</h3>
<h4>{{ 'DASHBOARD.MANAGE.NEW-DASHBOARD' | translate }}</h4>
<div>
    <ng-form [formGroup]="createDashboardForm" class="form-inline">
        <p>
            {{ 'DASHBOARD.MANAGE.DASHBOARD-NAME' | translate }}: <input
            class="form-control-s" type="text" formControlName="title" />
            <button class="btn btn-info createNewPop" type="submit" (click)="handleSubmit()">
                                    {{ 'SUBMIT' | translate }}
            </button>
        </p>
        <br />
    </ng-form>
    <p><i>{{ 'DASHBOARD.MANAGE.DASHBOARD-NAMES' | translate }}.</i></p>
</div>
<br />

<h3>{{ 'DASHBOARD.MANAGE.MODIFY-EXISTING' | translate }}</h3>
<div>
    <ul>
        <li *ngFor="let dashboard of (dashboards$ | async); trackBy: trackByFn">
            <a
                (click)="handleDashboardClicked(dashboard)"
                routerLink="/dashboard/{{ dashboard.id }}/edit"
                class="cursor-pointer"
                [innerHTML]="dashboard.name"
            ></a>
        </li>
    </ul>
</div>
