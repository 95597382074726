import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'labelToIcon' })
export class LabelToIconPipe implements PipeTransform {
    constructor() {}

    transform(label: string): string {
        const name =
            {
                home: 'house',
                store: 'shop',
                news: 'news',
                team: 'team',
                agenda: 'calendar',
                bibliotheek: 'book'
            }[label] || 'question';
        return `icon-alii-${name}`;
    }
}
