import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'upload-file-modal',
    templateUrl: './upload-file.component.html'
})
export class UploadFileModalComponent implements OnInit {
    form: FormGroup;
    uploadResponse: any = { status: '', message: '', filePath: '', error: '' };
    file: any;
    files: any[];

    @Output()
    eventBus?: EventEmitter<any> = new EventEmitter<any>();

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            file: ['']
        });
    }
    onFileChange(event) {
        if (event.target.files.length > 0) {
            this.file = event.target.files[0];
            this.form.get('file').setValue(this.file);
        }
    }

    onSubmit() {
        const formData = new FormData();
        formData.append('file', this.form.get('file').value);

        this.eventBus.emit({ type: 'handleUploadFile', payload: { formData, title: this.file.name } });
    }

    trackByFn = (index, item) => item.id || index;
}
