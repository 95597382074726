import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface OutcomeTableModalData {
    title: string;
    table: HTMLTableElement;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-outcome-table-modal',
    templateUrl: './outcome-table.component.html',
    styleUrls: ['./outcome-table.component.scss']
})
export class OutcomeTableModalComponent implements OnInit, AfterViewInit {
    data: OutcomeTableModalData;

    constructor(public activeModal: NgbActiveModal, private renderer: Renderer2, private hostElement: ElementRef) {}

    ngOnInit() {}

    ngAfterViewInit() {
        const table = this.data.table;
        const div = this.hostElement.nativeElement.querySelector('.modal-body > .row');
        table.querySelectorAll('td').forEach(td => this.renderer.removeStyle(td, 'width'));
        this.renderer.setStyle(table, 'display', 'block');
        this.renderer.setStyle(table, 'table-layout', 'auto');
        this.renderer.setStyle(table, 'width', '100%');
        this.renderer.appendChild(div, table);
    }
}
