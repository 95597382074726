import { createSelector } from '@ngrx/store';

import * as fromIndexReducers from '../reducers';
import * as fromReducer from '../reducers/dashboard-category.reducer';

const getDashboardCategoriesState = createSelector(
    fromIndexReducers.getDashboardState,
    (state: fromIndexReducers.DashboardState) => state.dashboardCategories
);
export const getDashboardCategories = createSelector(
    getDashboardCategoriesState,
    fromReducer.getDashboardCategory
);
