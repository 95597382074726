import { createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';
import * as fromProtocolCategories from '../reducers/protocol-categories.reducer';

export const getProtocolCategoriesState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.protocolCategories
);

export const getProtocolCategories = createSelector(
    getProtocolCategoriesState,
    fromProtocolCategories.getProtocolCategories
);

export const getProtocolCategoriesLoaded = createSelector(
    getProtocolCategoriesState,
    fromProtocolCategories.getProtocolCategoriesLoaded
);

export const getProtocolCategoriesLoading = createSelector(
    getProtocolCategoriesState,
    fromProtocolCategories.getProtocolCategoriesLoading
);
