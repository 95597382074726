import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../models/user.interface';

@Injectable()
export class ProfileService {
    constructor(private http: HttpClient) {}

    getUser(userId): Observable<User> {
        return this.http.get<any>(`${environment.baseUrl}/api/protocol/user/getUser.vm?userId=${userId}`).pipe(
            map(response => {
                return response;
            }),
            catchError((error: any) => throwError(error))
        );
    }
    updateUser(data): Observable<any> {
        return this.http.post<any>(`${environment.baseUrl}/api/protocol/user/updateUser.vm`, data).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    getFeedbackForm(): Observable<{ title: string; url: string }> {
        const trackingId = localStorage.getItem('anId');
        const url = environment.baseUrl + `/api/akwaFeedbackForm/getFormInfo?s=${trackingId}`;
        return this.http.get<{ title: string; url: string }>(url);
      }
    
      // Determine if the user should be asked for feedback based on user ID
      shouldAskForFeedback(): Observable<boolean> {        
        const trackingId = localStorage.getItem('anId');

        const url = environment.baseUrl + `/api/akwaFeedbackForm/isFormActive?s=${trackingId}`;
        return this.http.get<boolean>(url);
    }
}
