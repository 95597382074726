import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromDashboardCategories from './dashboard-categories.reducer';
import * as fromEventbusChannel from './eventbus-channel.reducer';
import * as fromFlowchart from './flowchart.reducer';
import * as fromGradeAssessment from './grade-assessment.reducer';
import * as fromModel from './model.reducer';
import * as fromModelsFindings from './models-findings.reducer';
import * as fromModelsOutcomes from './models-outcomes.reducer';
import * as fromModelsTagList from './models-taglist.reducer';
import * as fromOutcome from './outcome.reducer';
import * as fromParFiles from './paragraphs-files.reducer';
import * as fromParPopulations from './paragraphs-populations.reducer';
import * as fromParagraphs from './paragraphs.reducer';
import * as fromParagraphsReferences from './paragraphs-references.reducer';
import * as fromProtocolAllOutcomes from './protocol-all-outcomes.reducer';
import * as fromProtocolArticles from './protocol-articles.reducer';
import * as fromProtocolCategories from './protocol-categories.reducer';
import * as fromProtocols from './protocols.reducer';
import * as fromProtocolsExisting from './protocols-existing.reducer';
import * as fromPubmed from './pubmed.reducer';
import * as fromUsers from './users.reducer';

export interface ProtocolsFeatureState {
    dashboardCategories: fromDashboardCategories.DashboardCategoriesState;
    eventbusChannel: fromEventbusChannel.EventbusChannelState;
    flowchart: fromFlowchart.FlowchartState;
    gradeAssessment: fromGradeAssessment.GradesState;
    model: fromModel.ModelState;
    modelsFindings: fromModelsFindings.ModelsFindingsState;
    modelsOutcomes: fromModelsOutcomes.ModelsOutcomesState;
    modelsTagList: fromModelsTagList.ModelsTagListState;
    outcome: fromOutcome.OutcomeState;
    parPopulations: fromParPopulations.ParPopulationsPropState;
    paragraphs: fromParagraphs.ParagraphState;
    paragraphsReferences: fromParagraphsReferences.ParagraphsReferencesState;
    pararagraphFiles: fromParFiles.ParagraphsFilesState;
    protocolAllOutcomes: fromProtocolAllOutcomes.ProtocolAllOutcomesState;
    protocolArticles: fromProtocolArticles.ProtocolArticlesState;
    protocolCategories: fromProtocolCategories.ProtocolCategoriesState;
    protocols: fromProtocols.ProtocolState;
    protocolsExisting: fromProtocolsExisting.ProtocosExistingState;
    pubmeds: fromPubmed.PubmedState;
    users: fromUsers.UsersState;
}

export const reducers: ActionReducerMap<ProtocolsFeatureState> = {
    dashboardCategories: fromDashboardCategories.reducer,
    eventbusChannel: fromEventbusChannel.reducer,
    flowchart: fromFlowchart.reducer,
    gradeAssessment: fromGradeAssessment.reducer,
    model: fromModel.reducer,
    modelsFindings: fromModelsFindings.reducer,
    modelsOutcomes: fromModelsOutcomes.reducer,
    modelsTagList: fromModelsTagList.reducer,
    outcome: fromOutcome.reducer,
    parPopulations: fromParPopulations.reducer,
    paragraphs: fromParagraphs.reducer,
    paragraphsReferences: fromParagraphsReferences.reducer,
    pararagraphFiles: fromParFiles.reducer,
    protocolAllOutcomes: fromProtocolAllOutcomes.reducer,
    protocolArticles: fromProtocolArticles.reducer,
    protocolCategories: fromProtocolCategories.reducer,
    protocols: fromProtocols.reducer,
    protocolsExisting: fromProtocolsExisting.reducer,
    pubmeds: fromPubmed.reducer,
    users: fromUsers.reducer
};

export const getProtocolsFeatureState = createFeatureSelector<ProtocolsFeatureState>('protocolsFeature');
