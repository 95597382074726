<div #content>
    <ng-container *ngIf="viewByPopulation">
        <ng-container *ngTemplateOutlet="byPopulationView"></ng-container>
    </ng-container>

    <ng-container *ngIf="!viewByPopulation">
        <ng-container *ngTemplateOutlet="defaultView"></ng-container>
    </ng-container>
</div>

<ng-template #ShowAllOutcomeSwitch>
    <div class="pb-2">
        <div *ngIf="modelSource !== 'evidencio' && objectToArray(findings).length > 0" class="pull-right button-group">
            <a
                class="button showAllOutcomes smallButton"
                [ngClass]="{ 'selected': showAllOutcomes }"
                (click)="onToggleShowAllOutcomes()"
            >{{'MODEL.DETAIL.SHOWALL' | translate}}</a
            >
            <a
                class="button showAllOutcomes selected smallButton"
                [ngClass]="{ 'selected': !showAllOutcomes }"
                (click)="onToggleShowAllOutcomes()"
            >{{'MODEL.DETAIL.SHOWSELECTED' | translate}}</a
            >
        </div>
        <div *ngIf="tagList && tagList.length" class="button-group">
            <a   class="button smallButton" 
                    [ngClass]="{'selected': !hasActiveFilters()}" 
                    (click)="resetFilters()">
                {{'MODEL.DETAIL.NOFILTERS' | translate}}
            </a> 
            <a *ngFor="let tag of tagList" 
                    class="button  smallButton" 
                    [ngClass]="{'selected': isActiveTag(tag)}"  
                    (click)="filterOutComes(tag)">
                {{tag.title}}
            </a>
        </div>
        
    </div>
    <div class="clearfix"></div>
    <br />
</ng-template>

<ng-template #defaultView>
    <ng-container *ngIf="showModel">
        <ng-container *ngIf="outcomesView === 'horizontal'">
            <ng-container *ngTemplateOutlet="defaultViewOrg"></ng-container>
        </ng-container>
        <ng-container *ngIf="outcomesView === 'vertical'">
            <div class="model-buttons" [ngStyle]="showModel && { float: 'right' }">
                <a *ngIf="modelSource !== 'evidencio' && objectToArray(findings).length > 0"
                   class="tag showAllOutcomes"
                   [ngClass]="{ '-primary': showAllOutcomes }"
                   (click)="onToggleShowAllOutcomes()"
                >
                    {{'MODEL.DETAIL.SHOWALL' | translate}}
                </a>
                <a (click)="toggleShow()" [innerHTML]="(showModel ? 'Hide' : 'Show') + ' model'" class="tag"
                   [hidden]="true"></a>
                <a
                    *ngIf="showModel"
                    (click)="resetPatient()"
                    class="tag resetPatient"
                >
                    {{'MODEL.DETAIL.RESET' | translate}}
                </a>
                <a
                    *ngIf="outcomesShowToggleView"
                    class="tag"
                    (click)="toggleOutcomesView()"
                    [ngbTooltip]="'Display outcomes below'"
                >
                    <i class="fa fa-caret-down"></i>
                </a>
            </div>
            <div style="padding-top: 6px; padding-bottom: 6px; clear: both;">
                <div class="row">
                    <div [id]="'model-view-outer-block-' + paragraphId" class="col-6">
                        <ng-container *ngTemplateOutlet="modelViewBlock"></ng-container>
                    </div>
                    <div [id]="'outcome-view-outer-block-' + paragraphId" class="col-6">
                        <ng-container *ngTemplateOutlet="outcomeViewBlock"></ng-container>
                    </div>
                </div>
            </div>

            <div class="float-right" *ngIf="modelSource === 'evidencio'">
            <span class="small">
                powered by:
                <a href="https://www.evidencio.com/" target="_blank">
                    <img style="height: 1.5em;" src="/assets/images/evidencio.svg" alt="evidencio" />
                </a>
            </span>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #modelViewBlock>
    <div [id]="'model-view-inner-block-' + paragraphId">
        <ng-container *ngFor="let finding of findingsObjectToArray(findings); let i = index; trackBy: trackByFn">
            <div
                *ngIf="!finding.hide"
                [ngClass]="{ 'highlight-fade': finding.new, disappear: finding.hide }"
                style="padding: 2px; border: 1px solid #efefef;"
            >
                <a [id]="finding.anchorId" [name]="finding.anchorId"></a>
                <div class="row">
                    <div class="col-sm-12 wysiwyg mx-1" [id]="'question-' + finding.id">
                        <h5>
                            <span class="question-title">{{ finding['protocol_paragraph.title'] }}</span>
                            <a
                                href="#"
                                class="help-text ml-1"
                                *ngIf="finding['protocol_paragraph.expanded_text']"
                                (click)="onShowHelpText($event, finding['protocol_paragraph.expanded_text'])"
                                [title]="lang['information']"
                            >
                                <span class="button__icon"><span class="icon-question"></span></span>
                            </a>
                        </h5>
                        <p
                            class="small user-text"
                            *ngIf="!!finding['protocol_paragraph.text']"
                            [innerHTML]="finding['protocol_paragraph.text']"
                        ></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
                        <ng-container
                            *ngFor="let option of objectToArray(finding.options); trackBy: trackByFn; let j = index;">
                            <div
                                *ngIf="(option.type === 'categorical' || option.type === 'discrete') && showOption(i, option.title)"
                                class="text-right"
                            >
                                <div
                                    [id]="getOptionId(i,j)"
                                    (click)="
                                        onChangeOption(
                                            $event,
                                            i,
                                            option,
                                            finding.id,
                                            finding['protocol_paragraph.question_type']
                                        )
                                    "
                                    style="padding-top: 2px; padding-bottom: 2px; margin: 4px; text-align: center;"
                                    class="button storeCase cursor-pointer"
                                    [ngClass]="!!option.selected && option.selected ? '-primary' : '-secondary'"
                                >
                                    {{ option.title | asHtml }}
                                    <a
                                        href="#"
                                        class="help-text ml-1"
                                        *ngIf="option.expanded_text"
                                        (click)="onShowHelpText($event, option.expanded_text )"
                                        [title]="lang['information']"
                                    >
                                        <span class="button__icon"><span
                                            class="icon-question icon-button"></span></span>
                                    </a>
                                </div>
                            </div>
                            <div *ngIf="option.type === 'continuous'">
                                <div style="padding: 6px 4px 4px;">
                                    <label [for]="getOptionId(i, j)">
                                        
                                        <input
                                            [min]=option.min
                                            [max]=option.max
                                            [id]="getOptionId(i,j)"
                                            (change)="onChangeOption($event, i, option, finding.id)"
                                            type="number"
                                            style="height: 24px; padding: 0 0 0 12px; font-size: 12px; text-align: center;"
                                            class="numberEntry pull_right form-control"
                                            [value]="option.selected ? option.value : ''"
                                        />
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="option.type === 'date'">
                                <div style="padding: 6px 4px 4px;">
                                    <input type="text" ngbDatepicker 
                                        style="padding: 0 0 0 12px; font-size: 14px; text-align: center;"      
                                        #d="ngbDatepicker"
                                        class="numberEntry pull_right form-control"
                                        placeholder="yyyy-mm-dd"
                                        (click)="d.toggle()"
                                        [id]="getOptionId(i,j)"
                                        (dateSelect)="onSelectDate($event, i, option, finding.id)"
                                        [value]="option.selected ? option.value : ''"
                                    >
                                </div>
                            </div>
                            <div *ngIf="option.type === 'string'">
                                <div style="padding: 6px 4px 4px;">
                                    <textarea 
                                        rows="3"
                                        style="font-size: 14px; text-align: left;"      
                                        class="numberEntry pull_right form-control"
                                        [id]="getOptionId(i,j)"
                                        (change)="onChangeOption($event, i, option, finding.id)"
                                        [value]="option.selected ? option.value : ''"
                                    ></textarea>
                                </div>
                            </div>
                        </ng-container>
                        <div class="mx-1">
                            <alii-web-model-detail-typeahead
                                *ngIf="showTypeahead(i)"
                                [id]="paragraphId + '-' + i"
                                [items]="dropdownOptions[i]"
                                (selected)="onSelected($event)"
                                [placeholder]="finding['protocol_paragraph.question_type'] === 'multiple' ? lang['select.multiple'] : lang['select.single']"
                            ></alii-web-model-detail-typeahead>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #outcomeViewBlock>
    <div [id]="'outcome-view-inner-block-' + paragraphId">
        <div *ngIf="paragraph.outcomeTextHeader" class="report-header side-by-side">
            <div class="row mb-3">
                <span class="title" [innerHTML]="safeText(paragraph.outcomeTextHeader)"></span>
            </div>
            <div *ngIf="paragraph.outcomeText" class="row mb-3">
                <span class="text" [innerHTML]="safeText(paragraph.outcomeText)"></span>
            </div>
        </div>

        <div *ngIf="pending">
            <div class="row matrixOutcome selectedOutcome height-100">
                <div class="col-sm-12 wysiwyg">
                    <div class="RowTitle outcome">
                        <h5>
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        </h5>
                    </div>
                    <p>{{'LOADING' | translate}} ...</p>
                </div>
            </div>
        </div>

        <div #outcomes *ngIf="!pending && !outcomes?.length">
            <div class="row matrixOutcome selectedOutcome height-100">
                <div class="col-sm-12 wysiwyg">
                    <div class="RowTitle outcome">
                        <h5 id="noOutcomesTitle">{{'MODEL.DETAIL.TITLE' | translate }}</h5>
                    </div>
                    <p id="noOutcomesText">{{'MODEL.DETAIL.TEXT' | translate}}.</p>
                </div>
            </div>
        </div>


        <div class="row matrixOutcome" 
            style="border:none"
            *ngIf="tagList && tagList.length">
            <div class="button-group">
                <a   class="button smallButton" 
                        [ngClass]="{'selected': !hasActiveFilters()}" 
                        (click)="resetFilters()">
                    {{'MODEL.DETAIL.NOFILTERS' | translate}}
                </a> 
                <a *ngFor="let tag of tagList" 
                        class="button smallButton" 
                        [ngClass]="{'selected': isActiveTag(tag)}"  
                        (click)="filterOutComes(tag)">
                    {{tag.title}}
                </a>
            </div>
        </div>

        <div
            class="row matrixOutcome"
            *ngFor="let outcome of outcomes; trackBy: trackByFn"
            [class.hidden]="pending"
            [ngClass]="{ selectedOutcome: outcome.selected || outcomeSelected(outcome.id) }"
            (click)="onSelectOutcome(outcome)"
        >
            <div class="col-sm-12 wysiwyg">
                <a [id]="outcome.anchorId" [name]="outcome.anchorId"></a>
                <div class="RowTitle outcome">
                    <h5>
                        <a
                            *ngIf="outcome.outcomeType !== 'recommendation'"
                            href="#"
                            class="icon-outcome-type mr-1"
                            (click)="clickIconOutcomeType($event, outcome.outcomeType)"
                            [innerHTML]="iconOutcomeType(outcome.outcomeType)"
                            [title]="outcome.outcomeType"
                        >
                        </a>
                        {{ outcome['title'] }} 
                    </h5>
                    <ng-container *ngIf="outcome.linked_protocol">
                        (<a [routerLink]="['/protocols/', outcome.linked_protocol]">
                            open 
                            <span class="protocol-icon cursor-pointer icon-external-link"></span>
                        </a>)
                    </ng-container>
                    <ng-container *ngIf="outcome.linked_paragraph">
                        (<a (click)="onClickRelatedParagraph(outcome.linked_paragraph)" > 
                            <span class="protocol-icon cursor-pointer icon-external-link"></span>
                        </a>)
                    </ng-container>

                </div>
                <alii-web-model-detail-outcome
                    [outcome]="outcome"
                    [placeholders]="outcomesView === 'vertical'"
                ></alii-web-model-detail-outcome>
                <ng-container *ngIf="!outcome.outcomeList; else outcomeList">
                    <div class="text-right">
                        <a
                            href="#"
                            class="help-text"
                            *ngIf="outcome.expanded_text || outcome.treeview"
                            (click)="openOutcomeTreeview($event, outcome)"
                            [title]="lang['treeview']"
                        >
                            <span class="button__icon"><span class="icon-info"></span></span>
                        </a>
                    </div>
                </ng-container>
                <ng-template #outcomeList>
                    
                    <div *ngFor="let outcome of sortByScore(outcome.outcomeList)" class="outcomeItem form-check clear-both">
                        <span *ngIf="hasOutcomeIcon(outcome)">
                            <span [class]="outcomeIcon(outcome)"></span>
                            {{startOf(outcome.title)}}
                            <span class="whitespace-nowrap">
                                {{endOf(outcome.title)}}

                                <ng-container *ngIf="outcome.linked_protocol">
                                    (<a [routerLink]="['/protocols/', outcome.linked_protocol]">open 
                                        <span class="protocol-icon cursor-pointer icon-external-link"></span>
                                    </a>)
                                </ng-container>
                                <ng-container *ngIf="outcome.linked_paragraph">
                                    (<a (click)="onClickRelatedParagraph(outcome.linked_paragraph)" > 
                                        <span class="protocol-icon cursor-pointer icon-external-link"></span>
                                    </a>)
                                </ng-container> 
                                <a
                                    href="#"
                                    class="help-text"
                                    *ngIf="outcome.outcomeType == 'editable' || outcome.editable"
                                    (click)="openOutcomeEdit($event, outcome)"
                                    [title]="lang['treeview']"
                                >
                                    <span class="button__icon"><span class="icon-pencil-square-o"></span></span>
                                </a> 
                                <a
                                    href="#"
                                    class="help-text"
                                    *ngIf="outcome.text || outcome.treeview"
                                    (click)="openOutcomeTreeview($event, outcome)"
                                    [title]="lang['treeview']"
                                >
                                    <span class="button__icon"><span class="icon-info"></span></span>
                                </a>

                                <ng-container *ngIf="outcome.summaryText">
                                    <p class="ellipsis">{{outcome.summaryText}}</p>
                                </ng-container> 
                            </span>  
                        </span>
                        <span class="outcomeLabel form-check-label" [for]="'outcome-list-recommended' +
                            outcome.id" *ngIf="!hasOutcomeIcon(outcome)">
                            <input
                                [id]="'outcome-list-recommended' + outcome.id"
                                [name]="'group-para-' + paragraphId + '-' + outcome.selectionType"
                                class="form-check-input"
                                [type]="outcome.selectionType === 'select' ? 'radio' : 'checkbox'"
                                [checked]="outcomeListSelected[outcome.id]"
                                (click)="onSelectOutcomeList(outcome)"
                            />
                            {{startOf(outcome.title)}}
                            <span class="whitespace-nowrap">
                                {{endOf(outcome.title)}}

                                <ng-container *ngIf="outcome.linked_protocol">
                                    (<a [routerLink]="['/protocols/', outcome.linked_protocol]">open 
                                        <span class="protocol-icon cursor-pointer icon-external-link"></span>
                                    </a>)
                                </ng-container>
                                <ng-container *ngIf="outcome.linked_paragraph">
                                    (<a (click)="onClickRelatedParagraph(outcome.linked_paragraph)" > 
                                        <span class="protocol-icon cursor-pointer icon-external-link"></span>
                                    </a>)
                                </ng-container> 
                                <a
                                    href="#"
                                    class="help-text"
                                    *ngIf="outcome.outcomeType == 'editable' || outcome.editable"
                                    (click)="openOutcomeEdit($event, outcome)"
                                    [title]="lang['treeview']"
                                >
                                    <span class="button__icon"><span class="icon-pencil-square-o"></span></span>
                                </a> 
                                <a
                                    href="#"
                                    class="help-text"
                                    *ngIf="outcome.text || outcome.treeview"
                                    (click)="openOutcomeTreeview($event, outcome)"
                                    [title]="lang['treeview']"
                                >
                                    <span class="button__icon"><span class="icon-info"></span></span>
                                </a>

                                <ng-container *ngIf="outcome.summaryText">
                                    <p class="ellipsis">{{outcome.summaryText}}</p>
                                </ng-container>
                            </span>     
                        </span>
                        <div *ngIf="!!outcome.text" class="outcomeText wysiwyg" [innerHtml]="outcome.text"></div>

                        <div >
                            <span *ngFor="let tag of outcome.tags" class="outcomeTag {{tag.title}}">
                                {{tag.title}}
                            </span>
                            <br *ngIf="outcome.tags?.length && !!outcome.quality">
                            <span *ngIf="outcome.quality" style="margin: 2px;" [ngbTooltip]="evidenceQualityToText(outcome.quality)">
                                <span class="coloredBall" [style.backgroundColor]="getScoreColor(outcome.quality)" *ngFor="let ball of getColoredBalls(outcome.quality)"></span>
                                <span class="greyBall" *ngFor="let ball of getGreyBalls(outcome.quality)"></span>
                            </span>
                        </div>
                    </div>   
                </ng-template>
            </div>
        </div>

        <div class="row matrixOutcome" *ngIf="showNextModelLink() && !pending && outcomes?.length">
            <div class="col-sm-12 wysiwyg mb-2">
                <h4>Weergave mmZvP</h4>
                <a 
                [href]="linkToNextModel()" 
                class="button -primary"
                target="_blank"
                >Toon aanbevelingen in weergave voor mmZvP</a>
            </div>
        </div>
    </div>

    <div *ngIf="paragraph.reportHeaderText" class="report-header">
        <div class="row mt-3 mb-3">
            <span class="title" [innerHTML]="safeText(paragraph.reportHeaderText)"></span>
        </div>
        <div *ngIf="paragraph.reportText" class="row mb-3">
            <span class="text" [innerHTML]="safeText(paragraph.reportText)"></span>
        </div>
    </div>
</ng-template>

<ng-template #byPopulationView>
    <div style="padding-top: 6px; padding-bottom: 6px;">
        <div
            *ngFor="let population of populations; trackBy: trackByFn"
            class="container"
            style="border: 1px solid #efefef;"
        >
            <div class="row">
                <div
                    class="wysiwyg w-100"
                    style="max-width: 100%; padding-left: 15px; margin-bottom: 10px; background: #c2e3dc;"
                >
                    <h5>{{ population.name }}</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 wysiwyg">
                    <p class="small user-text">{{ population.outcome.title }}</p>
                    <alii-web-text-detail
                        [parId]="population.outcome.id"
                        [isEditAble]="isEditAble"
                        [title]="population.outcome.title"
                        [text]="population.outcome.text"
                    >
                    </alii-web-text-detail>
                </div>
                <div class="col-sm-4">
                    <div style="margin-bottom: 25px;">
                        <ng-container *ngIf="!!population.outcome.quality">
                            <div class="small user-text">quality of evidence</div>
                            <div
                                *ngFor="let num of numberToList(population.outcome.quality)"
                                class="icon-circle"
                                style="float: left; color: #c2e3dc;"
                            ></div>
                            <div
                                *ngFor="let num of numberToList(4 - population.outcome.quality)"
                                class="icon-circle"
                                style="float: left; color: #ddd;"
                            ></div>
                        </ng-container>

                        <div
                            class="wysiwyg small user-text"
                            (click)="togglePopulationProperty(population.id)"
                            style="float: right; color: #aaa;"
                        >
                            <span class="icon-alii-populations"></span>
                        </div>
                    </div>

                    <div
                        *ngFor="let prop of population.population_properties; trackBy: trackByFn"
                        style="clear: both; text-align: right;"
                    >
                        <div
                            style="padding-top: 2px; padding-bottom: 2px; margin: 4px; text-align: center;"
                            class="button -secondary cursor-pointer"
                            *ngIf="populationPropertiesVisible(population.id)"
                        >
                            {{ prop.choice }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="button-group -borderless -toolbar">
                        <i
                            class="button is-empty -nopaddingLeft cursor-pointer"
                            [ngClass]="{ 'is-active': newFindings(population.outcome.sources) }"
                            (click)="
                                arrayIsCollapsedPopulations[population.id] = !arrayIsCollapsedPopulations[population.id]
                            "
                            [attr.aria-expanded]="!arrayIsCollapsedPopulations[population.id]"
                            aria-controls="collapseExample"
                        >
                            <span class="icon-alii-book"></span> references ({{ population.outcome.sources.length }})
                        </i>
                    </div>
                </div>
                <div class="col-md-6"></div>
            </div>
            <div class="row pl-4" *ngIf="population.outcome.sources.length > 0">
                <div class="col-sm-12" id="collapseExample" [ngbCollapse]="arrayIsCollapsedPopulations[population.id]">
                    <ng-container *ngTemplateOutlet="listOfSources; context: population"></ng-container>
                </div>
            </div>
            <div class="row pl-4" *ngIf="population.outcome.gradeAssessment.action === 'editGradeAssessment'">
                <div class="col-sm-12" id="collapseExample" [ngbCollapse]="arrayIsCollapsedPopulations[population.id]">
                    <ng-container *ngTemplateOutlet="gradesAssesmentView; context: population"></ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #listOfSources let-sources="outcome.sources">
    <div>
        <h4>Studies</h4>
        <ng-container *ngFor="let article of sources; trackBy: trackByFn">
            <h5
                (click)="toggleFindings(article.id)"
                class="wysiwyg small user-text cursor-pointer"
                style="font-weight: bold;"
            >
                {{ article.label }}
            </h5>
            <ng-container *ngIf="findingsVisible(article.id)">
                <span (click)="onClickArticle(article)">Read article</span>
                <table class="table -aliiTable" style="margin-bottom: 30px;" *ngIf="article.finding.length">
                    <tbody>
                    <tr *ngFor="let finding of article.finding; let i = index; trackBy: trackByFn">
                        <td>
                            <ng-container *ngFor="let row of summaryOfFinding(finding); trackBy: trackByFn">
                                <div class="form-group row mb-0">
                                    <label class="col-sm-5 col-form-label text-sentence wysiwyg">
                                        {{ formatGradeKey(row.key) }}
                                    </label>
                                    <div class="col-sm-7">
                                        <span class="form-control-plaintext wysiwyg" [innerHTML]="row.value"></span>
                                    </div>
                                </div>
                            </ng-container>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #gradesAssesmentView let-outcome="outcome">
    <div>
        <h4>Grade Assessment</h4>
        <table class="table table-striped">
            <tbody>
            <ng-container *ngFor="let grade of (outcome.gradeAssessment.questions | keyvalue)">
                <tr class="d-flex">
                    <td class="col-6 text-capitalize">{{ formatGradeKey(grade.key) }}</td>
                    <td class="col-4">{{ grade.value }}</td>
                </tr>
            </ng-container>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #defaultViewOrg>
    <div class="model-buttons" [ngStyle]="showModel && { float: 'right' }">
        <a (click)="toggleShow()" [innerHTML]="(showModel ? 'Hide' : 'Show') + ' model'" class="tag" [hidden]="true"></a
        >&nbsp; <a *ngIf="showModel" (click)="resetPatient()"
                   class="tag resetPatient">{{'MODEL.DETAIL.RESET' | translate}}</a>
        <a
            *ngIf="outcomesShowToggleView"
            class="tag"
            (click)="toggleOutcomesView()"
        >
            <i class="fa fa-caret-right"></i>
        </a>
    </div>
    <ng-container *ngIf="showModel">
        <div style="padding-top: 6px; padding-bottom: 6px; clear: both;">
            <ng-container *ngFor="let finding of findingsObjectToArray(findings); let i = index; trackBy: trackByFn">
                <div
                    *ngIf="!finding.hide"
                    [ngClass]="{ 'highlight-fade': finding.new, disappear: finding.hide }"
                    class="row"
                    style="padding: 2px; border: 1px solid #efefef;"
                >
                    <div class="col-sm-8 wysiwyg" id="question-{{ finding.id }}">
                        <div class="RowTitle question">
                            <h5>{{ finding['protocol_paragraph.title'] }}</h5>
                            <p
                                class="small user-text"
                                *ngIf="!!finding['protocol_paragraph.text']"
                                [innerHTML]="finding['protocol_paragraph.text']"
                            ></p>
                            <a
                                href="#"
                                class="help-text"
                                *ngIf="finding['protocol_paragraph.expanded_text']"
                                (click)="onShowHelpText($event, finding['protocol_paragraph.expanded_text'])"
                                [title]="lang['information']"
                            >
                                <span class="button__icon"><span class="icon-question"></span></span>
                            </a>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <ng-container
                            *ngFor="let option of objectToArray(finding.options); trackBy: trackByFn; let j = index;">
                            <div class="text-right">
                                <div
                                    *ngIf="(option.type === 'categorical' || option.type === 'discrete') &&
                                    showOption(i, option.title)"
                                    [id]="getOptionId(i,j)"
                                    (click)="
                                        onChangeOption(
                                            $event,
                                            i,
                                            option,
                                            finding.id,
                                            finding['protocol_paragraph.question_type']
                                        )
                                    "
                                    style="padding-top: 2px; padding-bottom: 2px; margin: 4px; text-align: center;"
                                    class="button storeCase cursor-pointer"
                                    [ngClass]="!!option.selected && option.selected ? '-primary' : '-secondary'"
                                >
                                    {{ option.title | asHtml }}
                                    <a
                                        href="#"
                                        class="help-text ml-1"
                                        *ngIf="option.expanded_text"
                                        (click)="onShowHelpText($event, option.expanded_text, option.identifier)"
                                        [title]="lang['information']"
                                    >
                                        <span class="button__icon"><span
                                            class="icon-question icon-button"></span></span>
                                    </a>
                                    <a
                                        href="#"
                                        class="help-text ml-1"
                                        *ngIf="showIdentifiers && !option.expanded_text && option.identifier"
                                        (click)="onShowHelpText($event, option.expanded_text, option.identifier)"
                                        [title]="lang['information']"
                                    >
                                        <span class="button__icon"><span
                                            class="icon-question icon-button"></span></span>
                                    </a>
                                </div>
                                <div *ngIf="option.type === 'continuous'"
                                     style="text-align: right;">
                                    <div style="text-align: right;">
                                        <div style="padding: 6px 4px 4px;">
                                            <label [for]="getOptionId(i, j)">
                                                <input
                                                    [min]=option.min
                                                    [max]=option.max
                                                    [id]="getOptionId(i,j)"
                                                    (change)="onChangeOption($event, i, option, finding.id)"
                                                    type="number"
                                                    style="height: 24px; padding: 0 0 0 12px; font-size: 12px; text-align: center;"
                                                    class="numberEntry pull_right form-control"
                                                    [value]="option.selected ? option.value : ''"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="option.type === 'date'">
                                    <div style="padding: 6px 4px 4px;">
                                        <input type="text" ngbDatepicker 
                                            style="padding: 0 0 0 12px; font-size: 14px; text-align: center;"      
                                            #d="ngbDatepicker"
                                            class="numberEntry pull_right form-control"
                                            placeholder="yyyy-mm-dd"
                                            (click)="d.toggle()"
                                            [id]="getOptionId(i,j)"
                                            (dateSelect)="onSelectDate($event, i, option, finding.id)"
                                            [value]="option.selected ? option.value : ''"
                                        >
                                    </div>
                                </div>
                                <div *ngIf="option.type === 'string'">
                                    <div style="padding: 6px 4px 4px;">
                                        <textarea 
                                            rows="3"
                                            style="font-size: 14px; text-align: left;"      
                                            class="numberEntry pull_right form-control"
                                            [id]="getOptionId(i,j)"
                                            (change)="onChangeOption($event, i, option, finding.id)"
                                            [value]="option.selected ? option.value : ''"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="mx-1">
                            <alii-web-model-detail-typeahead
                                *ngIf="showTypeahead(i)"
                                [id]="paragraphId + '-' + i"
                                [items]="dropdownOptions[i]"
                                (selected)="onSelected($event)"
                                [placeholder]="finding['protocol_paragraph.question_type'] === 'multiple' ? lang['select.multiple'] : lang['select.single']"
                            ></alii-web-model-detail-typeahead>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <ng-container *ngTemplateOutlet="ShowAllOutcomeSwitch"></ng-container>

        <div *ngIf="paragraph.outcomeTextHeader" class="report-header">
            <div class="row mb-3">
                <span class="title" [innerHTML]="safeText(paragraph.outcomeTextHeader)"></span>
            </div>
            <div *ngIf="paragraph.outcomeText" class="row mb-3">
                <span class="text" [innerHTML]="safeText(paragraph.outcomeText)"></span>
            </div>
        </div>

        <div *ngIf="pending">
            <div class="row matrixOutcome selectedOutcome height-100">
                <div class="col-sm-12 wysiwyg">
                    <div class="RowTitle outcome">
                        <h5>
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        </h5>
                    </div>
                    <p>{{'LOADING' | translate}} ...</p>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!pending"> 
            <div
                class="row matrixOutcome"
                *ngFor="let outcome of outcomes; trackBy: trackByFn"
                [ngClass]="{ selectedOutcome: outcome.selected || outcomeSelected(outcome.id) }"
            >
                <div class="col-sm-12 wysiwyg">
                    <div class="RowTitle outcome">
                        <h5>
                            <a
                                *ngIf="outcome.outcomeType !== 'recommendation'"
                                href="#"
                                class="icon-outcome-type mr-1"
                                (click)="clickIconOutcomeType($event, outcome.outcomeType)"
                                [innerHTML]="iconOutcomeType(outcome.outcomeType)"
                                [title]="outcome.outcomeType"
                            >
                            </a>
                            {{ outcome['title'] }}
                        </h5>
                        <ng-container *ngIf="outcome.linked_protocol">
                            (<a [routerLink]="['/protocols/', outcome.linked_protocol]">open 
                                <span class="protocol-icon cursor-pointer icon-external-link"></span>
                            </a>)
                        </ng-container>
                        <ng-container *ngIf="outcome.linked_paragraph">
                            (<a (click)="onClickRelatedParagraph(outcome.linked_paragraph)" > 
                                <span class="protocol-icon cursor-pointer icon-external-link"></span>
                            </a>)
                        </ng-container>  

                    </div>
                    <alii-web-model-detail-outcome
                        [outcome]="outcome"
                        [placeholders]="outcomesView === 'vertical'">
                    </alii-web-model-detail-outcome>
                    <ng-container *ngIf="!outcome.outcomeList; else outcomeList">
                        <div class="text-right">
                            <a
                                href="#"
                                class="help-text"
                                *ngIf="outcome.expanded_text || outcome.treeview"
                                (click)="openOutcomeTreeview($event, outcome)"
                                [title]="lang['treeview']"
                            >
                                <span class="button__icon"><span class="icon-info"></span></span>
                            </a>
                        </div>
                    </ng-container>
                    <ng-template #outcomeList>
                        
                        <div *ngFor="let outcome of sortByScore(outcome.outcomeList)" class="outcomeItem form-check clear-both">
                            <span *ngIf="hasOutcomeIcon(outcome)">
                                <span [class]="outcomeIcon(outcome)"></span>
                                {{startOf(outcome.title)}}
                                <span class="whitespace-nowrap">
                                    {{endOf(outcome.title)}}
                                    <ng-container *ngIf="outcome.linked_protocol">
                                        (<a [routerLink]="['/protocols/', outcome.linked_protocol]">open 
                                            <span class="protocol-icon cursor-pointer icon-external-link"></span>
                                        </a>)
                                    </ng-container>
                                    <ng-container *ngIf="outcome.linked_paragraph">
                                        (<a (click)="onClickRelatedParagraph(outcome.linked_paragraph)" > 
                                            <span class="protocol-icon cursor-pointer icon-external-link"></span>
                                        </a>)
                                    </ng-container> 
                                    <a
                                        href="#"
                                        class="help-text"
                                        *ngIf="outcome.outcomeType == 'editable' || outcome.editable"
                                        (click)="openOutcomeEdit($event, outcome)"
                                        [title]="lang['treeview']"
                                    >
                                        <span class="button__icon"><span class="icon-pencil-square-o"></span></span>
                                    </a> 
                                    <a
                                        href="#"
                                        class="help-text"
                                        *ngIf="outcome.text || outcome.treeview"
                                        (click)="openOutcomeTreeview($event, outcome)"
                                        [title]="lang['treeview']"
                                    >
                                        <span class="button__icon"><span class="icon-info"></span></span>
                                    </a>
                                    

                                    <ng-container *ngIf="outcome.summaryText">
                                        <p class="ellipsis">{{outcome.summaryText}}</p>
                                    </ng-container> 
                                </span>
                            </span>
                            

                            <label class="outcomeLabel form-check-label" [for]="'outcome-list-recommended' +
                                outcome.id" *ngIf="!hasOutcomeIcon(outcome)">
                                <input
                                    [id]="'outcome-list-recommended' + outcome.id"
                                    [name]="'group-para-' + paragraphId + '-' + outcome.selectionType"
                                    class="form-check-input"
                                    [type]="outcome.selectionType === 'select' ? 'radio' : 'checkbox'"
                                    [checked]="outcomeListSelected[outcome.id]"
                                    (click)="onSelectOutcomeList(outcome)"
                                />
                                {{startOf(outcome.title)}}
                                <span class="whitespace-nowrap">
                                    {{endOf(outcome.title)}}

                                    <ng-container *ngIf="outcome.linked_protocol">
                                        (<a [routerLink]="['/protocols/', outcome.linked_protocol]">open 
                                            <span class="protocol-icon cursor-pointer icon-external-link"></span>
                                        </a>)
                                    </ng-container>
                                    <ng-container *ngIf="outcome.linked_paragraph">
                                        (<a (click)="onClickRelatedParagraph(outcome.linked_paragraph)" > 
                                            <span class="protocol-icon cursor-pointer icon-external-link"></span>
                                        </a>)
                                    </ng-container> 
                                    <a
                                        href="#"
                                        class="help-text"
                                        *ngIf="outcome.outcomeType == 'editable' || outcome.editable"
                                        (click)="openOutcomeEdit($event, outcome)"
                                        [title]="lang['treeview']"
                                    >
                                        <span class="button__icon"><span class="icon-pencil-square-o"></span></span>
                                    </a> 
                                    <a
                                        href="#"
                                        class="help-text"
                                        *ngIf="outcome.text || outcome.treeview"
                                        (click)="openOutcomeTreeview($event, outcome)"
                                        [title]="lang['treeview']"
                                    >
                                        <span class="button__icon"><span class="icon-info"></span></span>
                                    </a>

                                    <ng-container *ngIf="outcome.summaryText">
                                        <p class="ellipsis">{{outcome.summaryText}}</p>
                                    </ng-container> 
                                </span>    
                            </label>
                            <div *ngIf="!!outcome.text" class="outcomeText wysiwyg" [innerHtml]="outcome.text"></div>

                            <div>
                                <span *ngFor="let tag of outcome.tags" class="outcomeTag {{tag.title}}">
                                    {{tag.title}}
                                </span>
                                <br *ngIf="outcome.tags?.length && !!outcome.quality">
                                <span *ngIf="outcome.quality" style="margin: 2px;" [ngbTooltip]="evidenceQualityToText(outcome.quality)">
                                    <span class="coloredBall" [style.backgroundColor]="getScoreColor(outcome.quality)" *ngFor="let ball of getColoredBalls(outcome.quality)"></span>
                                    <span class="greyBall" *ngFor="let ball of getGreyBalls(outcome.quality)"></span>
                                </span>
                            </div>
                        </div>
                        
                        <div *ngIf="outcome.showMoreButton" class="mt-2">
                            <a href="#" (click)="onShowMoreOutcomes($event)">
                                {{outcome.showMoreText || 'Show more' }} <i
                                [ngClass]="{'fa-caret-down': !showMoreOutcomes,
                                    'fa-caret-up': showMoreOutcomes}" class="fa m-1"></i>
                            </a>
                            <div *ngIf="showMoreOutcomes" class="mt-2">
                                <div *ngFor="let outcome of sortByTitle(outcome.notRecommendedOutcomeList)"
                                    class="form-check">
                                    <label class="form-check-label"
                                        [for]="'outcome-list-not-recommended-' +
                                                        outcome.id">
                                        <input
                                            [id]="'outcome-list-not-recommended-' + outcome.id"
                                            [name]="'group-para-' + paragraphId + '-' + outcome.selectionType"
                                            class="form-check-input"
                                            [type]="outcome.selectionType === 'select' ? 'radio' : 'checkbox'"
                                            [checked]="outcomeListSelected[outcome.id]"
                                            (click)="onSelectOutcomeList(outcome)"
                                        />
                                        {{outcome.title}}</label>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="row matrixOutcome" *ngIf="showNextModelLink() && !pending && outcomes?.length">
                <div class="col-sm-12 wysiwyg mb-2">
                    <h4>Weergave mmZvP</h4>
                    <a 
                    [href]="linkToNextModel()" 
                    class="button -primary"
                    target="_blank"
                    >Toon aanbevelingen in weergave voor mmZvP</a>
                </div>
            </div>
        
        </ng-container>


        <div *ngIf="paragraph.reportHeaderText" class="report-header">
            <div class="row mt-3 mb-3">
                <span class="title" [innerHTML]="safeText(paragraph.reportHeaderText)"></span>
            </div>
            <div *ngIf="paragraph.reportText" class="row mb-3">
                <span class="text" [innerHTML]="safeText(paragraph.reportText)"></span>
            </div>
        </div>

        <div class="float-right" *ngIf="modelSource === 'evidencio'">
            <span class="small">
                powered by:
                <a href="https://www.evidencio.com/" target="_blank">
                    <img style="height: 1.5em;" src="/assets/images/evidencio.svg" alt="evidencio" />
                </a>
            </span>
        </div>
    </ng-container>
</ng-template>

<ng-template #subOutcomelist let-outcomeList="outcomeList">
    <div *ngFor="let outcome of sortByScore(outcomeList)" class="form-check clear-both">
        <span *ngIf="hasOutcomeIcon(outcome)">
            <span [class]="outcomeIcon(outcome)"></span>
            {{startOf(outcome.title)}}
            <span class="whitespace-nowrap">
                {{endOf(outcome.title)}}

                <ng-container *ngIf="outcome.linked_protocol">
                    (<a [routerLink]="['/protocols/', outcome.linked_protocol]">open 
                        <span class="protocol-icon cursor-pointer icon-external-link"></span>
                    </a>)
                </ng-container>
                <ng-container *ngIf="outcome.linked_paragraph">
                    (<a (click)="onClickRelatedParagraph(outcome.linked_paragraph)" > 
                        <span class="protocol-icon cursor-pointer icon-external-link"></span>
                    </a>)
                </ng-container> 
                <a
                    href="#"
                    class="help-text"
                    *ngIf="outcome.outcomeType == 'editable' || outcome.editable"
                    (click)="openOutcomeEdit($event, outcome)"
                    [title]="lang['treeview']"
                >
                    <span class="button__icon"><span class="icon-pencil-square-o"></span></span>
                </a> 
                <a
                    href="#"
                    class="help-text"
                    *ngIf="outcome.text || outcome.treeview"
                    (click)="openOutcomeTreeview($event, outcome)"
                    [title]="lang['treeview']"
                >
                    <span class="button__icon"><span class="icon-info"></span></span>
                </a>

                <ng-container *ngIf="outcome.summaryText">
                    <p class="ellipsis">{{outcome.summaryText}}</p>
                </ng-container> 
            </span>
        </span>
        <div *ngIf="!!outcome.text" class="outcomeText wysiwyg" [innerHtml]="outcome.text"></div>
        <label class="outcomeLabel form-check-label" [for]="'outcome-list-recommended' +
            outcome.id" *ngIf="!hasOutcomeIcon(outcome)">
            <input
                [id]="'outcome-list-recommended' + outcome.id"
                [name]="'group-para-' + paragraphId + '-' + outcome.selectionType"
                class="form-check-input"
                [type]="outcome.selectionType === 'select' ? 'radio' : 'checkbox'"
                [checked]="outcomeListSelected[outcome.id]"
                (click)="onSelectOutcomeList(outcome)"
            />
            {{startOf(outcome.title)}}
            <span class="whitespace-nowrap">
                {{endOf(outcome.title)}}

                <ng-container *ngIf="outcome.linked_protocol">
                    (<a [routerLink]="['/protocols/', outcome.linked_protocol]">open 
                        <span class="protocol-icon cursor-pointer icon-external-link"></span>
                    </a>)
                </ng-container>
                <ng-container *ngIf="outcome.linked_paragraph">
                    (<a (click)="onClickRelatedParagraph(outcome.linked_paragraph)" > 
                        <span class="protocol-icon cursor-pointer icon-external-link"></span>
                    </a>)
                </ng-container> 
                <a
                    href="#"
                    class="help-text"
                    *ngIf="outcome.outcomeType == 'editable' || outcome.editable"
                    (click)="openOutcomeEdit($event, outcome)"
                    [title]="lang['treeview']"
                >
                    <span class="button__icon"><span class="icon-pencil-square-o"></span></span>
                </a> 
                <a
                    href="#"
                    class="help-text"
                    *ngIf="outcome.text || outcome.treeview"
                    (click)="openOutcomeTreeview($event, outcome)"
                    [title]="lang['treeview']"
                >
                    <span class="button__icon"><span class="icon-info"></span></span>
                </a>

                <ng-container *ngIf="outcome.summaryText">
                    <p class="ellipsis">{{outcome.summaryText}}</p>
                </ng-container> 
            </span>     
        </label>
        <div *ngIf="!!outcome.text" class="outcomeText wysiwyg" [innerHtml]="outcome.text"></div>

    </div>
</ng-template>

<ng-template #outcomeInfo let-outcome>
    {{startOf(outcome.title)}}
    <span class="whitespace-nowrap">
        {{endOf(outcome.title)}}

        <ng-container *ngIf="outcome.linked_protocol">
            (<a [routerLink]="['/protocols/', outcome.linked_protocol]">open 
                <span class="protocol-icon cursor-pointer icon-external-link"></span>
            </a>)
        </ng-container>
        <ng-container *ngIf="outcome.linked_paragraph">
            (<a (click)="onClickRelatedParagraph(outcome.linked_paragraph)" > 
                <span class="protocol-icon cursor-pointer icon-external-link"></span>
            </a>)
        </ng-container> 
        <a
            href="#"
            class="help-text"
            *ngIf="outcome.outcomeType == 'editable' || outcome.editable"
            (click)="openOutcomeEdit($event, outcome)"
            [title]="lang['treeview']"
        >
            <span class="button__icon"><span class="icon-pencil-square-o"></span></span>
        </a> 
        <a
            href="#"
            class="help-text"
            *ngIf="outcome.text || outcome.treeview"
            (click)="openOutcomeTreeview($event, outcome)"
            [title]="lang['treeview']"
        >
            <span class="button__icon"><span class="icon-info"></span></span>
        </a>


        <ng-container *ngIf="outcome.summaryText">
            <p class="ellipsis">{{outcome.summaryText}}</p>
        </ng-container> 
    </span>

    <div *ngIf="!!outcome.text" class="outcomeText wysiwyg" [innerHtml]="outcome.text"></div>
</ng-template>
