import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { Protocol } from '../../../../models/protocol.interface';
import { User } from '../../../../models/user.interface';
import * as fromStore from './../../store';

import { FormSettingsComponent } from './form-settings/form-settings.component';
import { FormSearchTermComponent } from './form-search-term/form-search-term.component';
import { FormInclusionCriteriaComponent } from './form-inclusion-criteria/form-inclusion-criteria.component';
import { OrganisationService } from '../../../../services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalComponent } from '../../entry-components';
import { Observable, of } from 'rxjs';

const cn = 'ProtocolSettingsComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-protocol-settings',
    templateUrl: './protocol-settings.component.html'
})
export class ProtocolSettingsComponent implements OnInit {
    @Input() protocol: Protocol;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    searchResults$: Observable<any>;

    userForm;

    constructor(
        private modalService: NgbModal,
        private fb: FormBuilder,
        private store: Store<fromStore.ProtocolsFeatureState>,
        private router: Router,
        private toastr: ToastrService,
        private organisationService: OrganisationService
    ) {}

    ngOnInit() {
        this.store.dispatch(new fromStore.LoadUsers());
        this.userForm = this.fb.group({
            setAuthor: ['', Validators.required]
        });

        this._deleteProtocolSelect();
        console.log(this.protocol);
    }

    openHeaderForm() {
        const modalRef = this.modalService.open(FormSettingsComponent);
        modalRef.result.then(
            result => {
                const action = { type: 'handleUpdateSetting', payload: { ...result, protocolId: this.protocol.id } };
                this.eventBus.emit(action);
            },
            () => {}
        );
        modalRef.componentInstance.protocol = this.protocol;
    }

    required(name: string) {
        return this.userForm.get(name).hasError('required') && this.userForm.get(name).touched;
    }

    selectAuthor(event) {
        const name = event.target.value;
        this.searchResults$ = name.length > 2 ? this.organisationService.getUsers(name, null, this.protocol.id as any) : of([]);
        console.log(this.protocol);
    }

    addAuthor(user: User) {
        const data = { setAuthor: user.id, protocolId: this.protocol.id };
        this.searchResults$ = of([]);
        this.userForm.patchValue({ setAuthor: '' });
        this.handleUpdate(data);
    }

    removeAuthor(authorId: string) {
        const data = { removeAuthor: authorId, protocolId: this.protocol.id };
        this.handleUpdate(data);
    }

    hasRole(user: User, role) {
        let list = []
        if(role === 'reviewer') {
            list = this.protocol.reviewerlist }
        else if (role === 'owner') {
            list = this.protocol.ownerlist }    
        else if (role === 'author') {
            list = this.protocol.authorlist }
        else if (role === 'subscriber') {
            list = this.protocol.notificationlist }

        return list.findIndex(u => user.id == '' + u.id) != -1;
    }

    setOwner(authorId: string) {
        const data = { setOwner: authorId, protocolId: this.protocol.id };
        this.handleUpdate(data);
    }

    removeOwner(authorId: string) {
        const data = { removeOwner: authorId, protocolId: this.protocol.id };
        this.handleUpdate(data);
    }

    setSubscriber(authorId: string) {
        const data = { setSubscriber: authorId, protocolId: this.protocol.id };
        this.handleUpdate(data);
    }

    removeSubscriber(authorId: string) {
        const data = { removeSubscriber: authorId, protocolId: this.protocol.id };
        this.handleUpdate(data);
    }

    setReviewer(authorId: string) {
        const data = { setReviewer: authorId, protocolId: this.protocol.id };
        this.handleUpdate(data);
    }

    removeReviewer(authorId: string) {
        const data = { removeReviewer: authorId, protocolId: this.protocol.id };
        this.handleUpdate(data);
    }


    openSearchTermForm() {
        const modalRef = this.modalService.open(FormSearchTermComponent);
        modalRef.result.then(
            result => {
                const action = {
                    type: 'handleUpdateSetting',
                    payload: { ...result, protocolId: this.protocol.id }
                };
                this.eventBus.emit(action);
            },
            () => {}
        );
        modalRef.componentInstance.protocol = this.protocol;
    }

    openInclusionCriteriaForm() {
        const modalRef = this.modalService.open(FormInclusionCriteriaComponent);
        modalRef.result.then(
            result => {
                const action = {
                    type: 'handleUpdateSetting',
                    payload: { inclusionCriteria: result, protocolId: this.protocol.id }
                };
                this.eventBus.emit(action);
            },
            () => {}
        );
        modalRef.componentInstance.protocol = this.protocol;
    }

    handleUpdate(data: any) {
        const action = {
            type: 'handleUpdateSetting',
            payload: data
        };
        this.eventBus.emit(action);
    }

    addTag(tagId: string) {
        this.handleUpdate({
            addTag: tagId
        });
    }

    removeTag(tagId: string) {
        this.handleUpdate({
            removeTag: tagId
        });
    }

    // --- Delete Protocol --- //

    deleteProtocol() {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        if (modalRef) {
            modalRef.componentInstance.data = {
                title: 'PROTOCOLS.DELETE.TITLE',
                text: 'PROTOCOLS.DELETE.TEXT'
            };
            modalRef.result.then(
                () => this._deleteProtocol(),
                () => {}
            );
        } else {
            console.error(`${cn} confirm() cannot open confirm modal component`);
            return false;
        }
    }

    _deleteProtocolSelect() {
        this.store.select(fromStore.getDeleteProtocolResponse).subscribe(response => {
            if (response.protocolId === this.protocol.id.toString()) {
                if (response.message) {
                    this.toastr.success(response.message, 'Success');
                    this.router.navigate([response.url]);
                } else if (response.error) {
                    this.toastr.error(response.error.message, 'An error occurred');
                }
            }
        });
    }

    _deleteProtocol() {
        const protocolId = this.protocol.id.toString();
        this.store.dispatch(new fromStore.DeleteProtocol({ protocolId }));
    }

    trackByFn = (index, item) => item.id || index;
}
