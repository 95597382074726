import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SortablejsModule } from 'ngx-sortablejs';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BlockUIModule } from 'ng-block-ui';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AgGridModule } from 'ag-grid-angular';

// Translation stuff, see: https://github.com/ngx-translate/core
import { TranslateModule } from '@ngx-translate/core';

import { reducers, effects } from './store';
import * as fromServices from '../../services';
import * as fromComponents from './components';
import * as fromContainers from './containers';
import * as fromEntryComponents from './entry-components';
import { PartialsModule } from '../../partials/partials.module';
import { AsHtmlPipe } from '../../pipes/as-html.pipe';
import { SafePipe } from '../../pipes/safe.pipe';
import { ProtocolFilesComponent } from './components/protocol-detail/protocol-files/protocol-files.component';
import { TreeviewModule } from '../../shared';
import { ProtocolsRoutingModule } from './protocols-routing.module';
import { ProtocolMenubarComponent } from './components/protocol-sidebar/protocol-menubar.component';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        SortablejsModule.forRoot({
            animation: 200
        }),

        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        AgGridModule,
        TypeaheadModule.forRoot(),
        NgxPermissionsModule.forChild(),

        FormsModule,
        ReactiveFormsModule,
        StoreModule.forFeature('protocolsFeature', reducers),
        EffectsModule.forFeature(effects),
        PartialsModule,
        ProtocolsRoutingModule,

        BlockUIModule.forRoot(),
        TreeviewModule.forRoot(),

        TranslateModule.forChild({ extend: true })
    ],
    providers: [...fromServices.services],

    declarations: [
        ...fromContainers.containers,
        ...fromComponents.components,
        ...fromEntryComponents.entryComponents,
        AsHtmlPipe,
        SafePipe,
        ProtocolFilesComponent,
        ProtocolMenubarComponent
    ],

    exports: [...fromContainers.containers, ...fromComponents.components]
})
export class ProtocolsModule {}
