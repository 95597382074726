import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromHelp from './help.reducer';

export interface HelpFeatureState {
    help: fromHelp.HelpState;
}

export const reducers: ActionReducerMap<HelpFeatureState> = {
    help: fromHelp.reducer,
};

export const getHelpFeatureState = createFeatureSelector<HelpFeatureState>('HelpFeature');
