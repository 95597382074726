import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromService from '../../../../services';

import * as fromStore from './../../store';
import { DashboardTag } from '../../../../models/dashboard-tag.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-dashboard-tag-manage',
    templateUrl: './dashboard-tag-manage.component.html'
})
export class DashboardTagManageComponent implements OnInit {
    id: string;
    tags$: Observable<DashboardTag[]>;
    createTagForm: FormGroup;

    constructor(
        private store: Store<fromStore.DashboardState>,
        private fb: FormBuilder,
        private DashboardService: fromService.DashboardService
    ) {}

    ngOnInit() {
        this.tags$ = this.store.select(fromStore.getDashboardTags);
        this.store.dispatch(new fromStore.LoadDashboardSummary());

        this.createTagForm = this.fb.group({
            title: ['', Validators.required]
        });
    }
    handleSubmit() {
        if (this.createTagForm.valid) {
            const data = this.createTagForm.value;
            this.DashboardService.createCategory(data.title).subscribe(response => {
                this.store.dispatch(new fromStore.LoadDashboardSummary());
                this.createTagForm.reset();
            });
        }
    }

    trackByFn = (index, item) => item.id || index;
}
