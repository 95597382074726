import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface ConfirmModalData {
    title: string;
    text: string;
    icon?: string;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
    data: ConfirmModalData;

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit() {
        if (!this.data.icon) {
            this.data.icon = 'exclamation-triangle';
        }
    }
}
