import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../../../../models/field.interface';
import { FieldConfig } from '../../../../../models/field-config.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'form-select',
    styleUrls: ['./../dynamic-form.component.scss'],
    template: `
        <div class="dynamic-field form-select" [formGroup]="group">
            <label>{{ config.label }} </label>
            <div *ngIf="config.info" class="field-info">{{ config.info }}</div>
           
            <ng-container *ngIf="config.options.length < 5">
            <select
                [size]="+config.options.length+1"
                [formControlName]="getControlName(config)"
                class="form-control"
                (change)="config.submitOnChange ? config.submitOnChange($event) : false"
            >
                <option value="">{{ config.placeholder }}</option>
                <option *ngFor="let option of config.options" [value]="option.value"> {{ option.title }} </option>
            </select>
            </ng-container>
            <ng-container *ngIf="config.options.length > 4">
                <select
                    [formControlName]="getControlName(config)"
                    class="form-control"
                    (change)="config.submitOnChange ? config.submitOnChange($event) : false"
                >
                    <option value="">{{ config.placeholder }}</option>
                    <option *ngFor="let option of config.options" [value]="option.value"> {{ option.title }} </option>
                </select>
            </ng-container>
        </div>
    `
})
export class FormSelectComponent implements Field {
    config: FieldConfig;
    group: FormGroup;

    getControlName(config) {
        if (config.questionId)
            return config.questionId
        else {
            return config.name
        }
    }
}
