<section class="page__section -no-bottom -transparent">
    <ng-container *ngIf="!editOrgView">
        <span class="btn pull-right" *ngxPermissionsOnly="['Admin']" (click)="startEditOrg()"> edit organization details</span>
    </ng-container>
    <span class="btn pull-right" (click)="stopEditOrg()" *ngIf="editOrgView">close</span>
    <div class="sectionTitle -no-bottom" >
        <div class="sectionTitle__title">{{ (organization$|async)?.name }}</div>
    </div>
    <div class="row" *ngIf="!editOrgView">
        <div class="col">{{(organization$|async)?.description}}</div>
    </div>

    <div *ngIf="editOrgView">      
        <section>
            <div class="card-group">
                <div class="timeline_card  w-100">
                    <article class="card">
                        <div class="card__header">
                            <ng-form [formGroup]="orgForm">
                                <div class="formRow row">
                                    <div class="col-md-4"> 
                                        Organization name 
                                    </div>
                                    <div class="col-md-8">
                                        <input type="text" formControlName="name" class="form-control" />
                                    </div>
                                </div>
                                <div class="formRow row">
                                    <div class="col-md-4">
                                        SSO type
                                    </div>
                                    <div class="col-md-8">
                                        <input type="text" formControlName="ssoName" class="form-control" />
                                    </div>
                                </div>
                                <div class="formRow row">
                                    <div class="col-md-4">
                                        SSO url
                                    </div>
                                    <div class="col-md-8">
                                        <input type="text" formControlName="ssoUrl" class="form-control" />
                                    </div>
                                </div>
                                <div class="formRow row">
                                    <div class="col-md-4">
                                        Organization image
                                    </div>
                                    <div class="col-md-8">
                                        <a (click)="onClickAddImage(organization, 'organization')">
                                            <img [src]="organization.logo" style="height: 100px" /><br />
                                            Upload image
                                        </a>
                                    </div>
                                </div>
                                <br />
                                <div style="padding-left: 0; margin-bottom: 20px">
                                    <button type="button" (click)="handleSubmitOrgForm()" class="btn btn-info" [disabled]="!orgForm.valid">
                                        Save
                                    </button>
                                </div>
                            </ng-form>
                        </div>
                    </article>
                </div>
            </div>
        </section>
    </div>
    <section *ngIf="!editOrgView">
        <div class="card-group">
            <div class="timeline_card w-100" *ngFor="let team of (organization$|async)?.teams; trackBy: trackByFn">
                <article class="card">

                    <header class="card__header">
                        <h4>{{team.name}}</h4>
                        <span class="btn pull-right" *ngIf="editTeamOpen(team)" (click)="stopEditTeam()">close</span>
                        <ng-container *ngIf="!editTeamOpen(team)">
                            <span class="btn pull-right" *ngIf="(organization$|async)?.canEditDetails" (click)="startEdit(team)"> edit </span> 
                        </ng-container>
                    </header> 
                    <div class="row card__main" *ngIf="!editTeamOpen(team)">
                        <div class="col-md-8">{{team.description}}</div>
                        <div class="col-md-4"><img class="float-right team-image" src="{{team.imageUrl}}"></div>
                    </div>
                    <div *ngIf="editTeamOpen(team)" style="padding: 2em">
                        
                        <ng-form [formGroup]="teamForm">
                            <div class="formRow row">
                                <div class="col-md-4">Team name</div>
                                <div class="col-md-8">
                                    <input type="text" formControlName="name" class="form-control" />
                                </div>
                            </div>
                            <div class="formRow row">
                                <div class="col-md-4">
                                    Team description
                                </div>
                                <div class="col-md-8">
                                    <input type="text" formControlName="description" class="form-control" />
                                </div>
                            </div>
                            <div class="formRow row">
                                <div class="col-md-4">
                                    Team image
                                </div>
                                <div class="col-md-8">
                                    <a (click)="onClickAddImage(team, 'team')">
                                    <img [src]="team.imageUrl" style="height: 100px" /><br />
                                        Upload image
                                    </a>
                                </div>
                            </div>
                            <br />
                            <div class="col-sm-4" style="padding-left: 0; margin-bottom: 20px">
                                <button type="button" (click)="handleSubmitTeamForm()" class="btn btn-info" [disabled]="!teamForm.valid">
                                    Save profile
                                </button>
                            </div>
                        </ng-form>
                    </div>
                    <div class="card__footer">
                        <div class="pull-right" *ngxPermissionsOnly="['Admin']">
                            <a role="button" class="btn btn-info" 
                                [routerLink]="['/organization/teams/', team.id, 'users']">manage users</a> &nbsp;
                            <a role="button" class="btn btn-info" routerLink="/organization/groups"> manage groups </a>&nbsp;
                        </div>
                        <div class="pull-right" *ngxPermissionsExcept="['Admin']">
                            <a role="button" class="btn btn-info" 
                                [routerLink]="['/organization/teams/', team.id, 'users']">users</a> &nbsp;
                        </div>
                        
                    </div>
                </article>
            </div>
        </div>
    </section>
</section>