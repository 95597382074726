import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ShopTemplateSetActions from '../actions/shop-template-set.action';
import * as fromService from '../../../../services/shop.service';

@Injectable()
export class ShopTemplateSetEffects {
    constructor(private actions$: Actions, private shopService: fromService.ShopService) {}

    @Effect()
    loadShopTemplateSet$ = this.actions$.pipe(
        ofType(ShopTemplateSetActions.LOAD_SHOP_TEMPLATE_SET),
        switchMap((action: any) => {
            return this.shopService.getShopTemplateSet(action.payload).pipe(
                map(shopTemplateSet => new ShopTemplateSetActions.LoadShopTemplateSetSuccess(shopTemplateSet)),
                catchError(error => of(new ShopTemplateSetActions.LoadShopTemplateSetFail(error)))
            );
        })
    );

    @Effect()
    updateShopTemplateSet$ = this.actions$.pipe(
        ofType(ShopTemplateSetActions.UPDATE_SHOP_TEMPLATE_SET),
        switchMap((action: any) => {
            return this.shopService.updateShopTemplateSet(action.payload).pipe(
                map(result => new ShopTemplateSetActions.UpdateShopTemplateSetSuccess(result)),
                catchError(error => of(new ShopTemplateSetActions.UpdateShopTemplateSetFail(error)))
            );
        })
    );
}
