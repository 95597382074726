import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { User } from '../models/user.interface';

@Injectable()
export class OrganisationsService {
    constructor(private http: HttpClient) {}

    getUsers(): Observable<any> {
        return this.http.get<any>(`${environment.baseUrl}/api/protocol/organization/getUsers.vm`).pipe(
            map(response => response.users),
            catchError((error: any) => throwError(error))
        );
    }
}
