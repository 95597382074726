import {
    Component,
    Input,
    Output,
    OnInit,
    EventEmitter,
    ViewChild,
    
} from '@angular/core';

import { HttpClient } from '@angular/common/http';
import * as fromService from '../../../../services';
import {AgGridAngular} from 'ag-grid-angular';
import { RenderHTML } from './render-html.component';
import { EditHTML } from './edit-html.component';

@Component({
    selector: 'alii-web-review-edit',
    templateUrl: './review-edit.component.html'
})
export class ReviewEditComponent implements OnInit {
    public frameworkComponents;
    private gridApi;
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private http: HttpClient,
        private literaturesService: fromService.LiteraturesService) {
            this.frameworkComponents = {
                renderHTML: RenderHTML,
                editHTML: EditHTML,
              };
        }

    @Input() rowData: [];
    @Input() status: string;
    @Input() rowBased: boolean;
    @Input() columnDefs: [];
    @Input() reviewId: any;
    @Input() protocolId: any;

      

    defaultColDef: any = {
        valueSetter: (params) => {
            let newVal = params.newValue;

            var values =  params.newValue.rowData
            const field = params.colDef.field;
            const label = params.colDef.label;
            const nodeId = params.node.id;

            var protocolId = this.protocolId
            var reviewId = this.reviewId
            var action = "updateReview"
        
            values = {id: params.data.id}
            values[field] = newVal;
            values[label] = label;
         
            const eventPayload = {
              type: 'handleLiteratureSubmitAction',
              payload: {
                    protocolId, action, values, reviewId,
                    nextArticleId: false,
                    isVote: false
              }
          };
          this.eventBus.emit(eventPayload);
          ;


         // this.store.dispatch(new UpdateRow(nodeId, colId, newVal));
          return false;
        },
        resizable: true,
        cellStyle: {
          display: 'flex',
          'align-items': 'center'
        }
      };

      
    @ViewChild('agGrid') agGrid: AgGridAngular;

    ngOnInit() {

    }

    getSelectedRowData() {
      if(this.agGrid) {
        let selectedNodes = this.agGrid.api.getSelectedNodes(); 
        if(selectedNodes) {
          let selectedData = selectedNodes.map(node => node.data);
          if (selectedData.length) {
            return selectedData;
          } 
        }
      }
      return false
    }

    connectToDifferentPar() {
      let data = this.getSelectedRowData()
      const action = {
          type: 'connectToDifferentPico',
          payload: {
              data,
              ppdId: data[0].paragraphId
          }
      };
      this.eventBus.emit(action);
    }

    deleteSelectedRows() {
      let data = this.getSelectedRowData()
      const action = {
          type: 'handleDeleteRows',
          payload: {
              data,
              ppdId: data[0].paragraphId
          }
      };
      this.eventBus.emit(action);
    }

  copyRows() {
    let data = this.getSelectedRowData()
    const action = {
        type: 'handleCopyRows',
        payload: {
            data,
            ppdId: data[0].paragraphId
        }
    };
    this.eventBus.emit(action);
}

    getRowNodeId(data) {
        return data.id;
      }


    handleEventBus(event) {
        this.eventBus.emit(event);
    }
          
}
