import { createSelector } from '@ngrx/store';

import * as fromUserReducer from '../reducers/users.reducer';
import * as fromReducers from '../reducers';

export const getUserState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.users
);

export const getUsers = createSelector(
    getUserState,
    fromUserReducer.getUsers
);
