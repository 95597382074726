import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { Category } from '../../../../models/category.interface';
import { toolbarButtons } from './toolbar-buttons';
import { environment } from '../../../../../environments/environment';

interface EventEmitterData {
    type: string;
    payload: { title: string; helpText: string };
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-protocol-form',
    templateUrl: './protocol-form.component.html',
    styleUrls: ['./protocol-form.component.scss']
})
export class ProtocolFormComponent implements OnInit {
    @Input()
    categories: Category[];

    @Input()
    categoriesLoading: boolean;

    @Input()
    createProtocolLoading: boolean;

    @Output()
    eventBus: EventEmitter<EventEmitterData> = new EventEmitter<EventEmitterData>();

    form: FormGroup;

    options: unknown = {
        key: environment.froala.key,
        attribution: false,
        heightMin: 120,
        charCounterCount: false,
        toolbarButtons,
        placeholderText: '',
        events: {
            // Froala bugfix insert table from google sheets
            'paste.afterCleanup': html => html.replace(';width:0px;', ';')
        }
    };

    // --- Typeahead: start --- //
    maxCategories = -1; // TODO: For now simply disable the typeahead

    model: never;

    @ViewChild('inputCategory') instance: NgbTypeahead;

    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map(term =>
                (term === ''
                    ? this.categories.map(c => c.title)
                    : this.categories.filter(c => c.title.toLowerCase().indexOf(term.toLowerCase()) > -1)
                ).slice(0, 10)
            )
        );
    };

    // --- Typeahead: finish --- //

    constructor(private fb: FormBuilder, private translate: TranslateService) {}

    ngOnInit() {
        this.form = this.fb.group({
            title: ['', Validators.required],
            category: ['', Validators.required],
            html: ''
        });
    }

    onShowHelpText() {
        this.translate.get('PROTOCOLS.CREATE.HELP').subscribe(key => {
            const [title, helpText] = key.split('|');
            if (title && helpText) {
                const helpTextAction = {
                    type: 'openHelpText',
                    payload: { title, helpText }
                };
                this.eventBus.emit(helpTextAction);
            }
        });
        return false;
    }

    onSubmit() {
        if (this.form.valid) {
            const event = {
                type: 'handleCreateProtocol',
                payload: {
                    ...this.form.value
                }
            };
            this.eventBus.emit(event);
        }
    }

    trackByFn = (index, item) => item.id || index;
}
