<ng-container *ngIf="filteredDashboards.length">
    <div class="btn-group" ngbDropdown placement="bottom-right">
        <button type="button" class="button -light" ngbTooltip="{{ 'DASHBOARD.ACTIONS.SELECT.TOOLTIP' | translate}}" ngbDropdownToggle>
            {{ 'DASHBOARD.ACTIONS.SELECT.TITLE' | translate }}
        </button>
        <ul class="dropdown-menu right" ngbDropdownMenu>
            <li *ngFor="let dashboard of filteredDashboards; trackBy: trackByFn" (click)="clickSelection(dashboard.id)">
                <a class="dropdown-item cursor-pointer">
                    <span>{{ dashboard.name }}</span>
                    <span class="badge badge-pill badge-secondary">{{ dashboard.number_of_protocols }}</span>
                </a>
            </li>
        </ul>
    </div>
</ng-container>
