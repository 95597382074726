<div class="top-navigationBar" [ngClass]="version == 'Draft' ? 'draftBar' : 'currentBar'">
    <div class="col-8" style="vertical-align: middle;">
        <a (click)="toggleSidebar()">
            <span class="-mobile-only icon-bars" style="padding-right: 6px;"></span>
            <span class="protocol-title" style="padding-right: 6px;">{{ protocol.title }}</span><span>| </span>
        </a>
        <span class="mainAuthors">
            {{protocol.authors}}
        </span>

        <a (click)="scrollToInfo()" style="padding-left: 4px;">
            <alii-web-avatar   
                showInLine = true
                [ownerlist]="protocol.ownerlist">
            </alii-web-avatar>
            <ng-container *ngIf="protocol.protocol_status.originalProtocol?.originalOwners.length > 0">        
                <alii-web-avatar   
                    showInLine = true
                    [ownerlist]="protocol.protocol_status.originalProtocol.originalOwners">
                </alii-web-avatar>           
            </ng-container>
        </a>
    </div>
    <div class="protocolTabs col-4">
        <div class="pull-right">
                <a
                *ngIf="views.length"
                class="topbarLink"
                id="content"
                (click)="handleSwitchView('text')">
                <span id="protocolStatus"> {{'PROTOCOLS.MARGIN.BUTTON.TEXT'| translate}}</span>
                </a>
                <a *ngIf="protocol.flowchart.start && protocol.showFlowchart && version != 'Draft'"  
                    class="topbarLink"
                    id="flowchart"
                    (click)="handleSwitchView('flowchart')"
                    >
                | 
                {{'PROTOCOLS.MARGIN.BUTTON.FLOW' | translate}}
                </a>
                <a *ngIf="protocol.flowchart.start && protocol.showInteractive && version != 'Draft'"   
                    class="topbarLink"
                    id="interactive"
                    (click)="handleSwitchView('interactive')"
                    >
                | 
                {{'PROTOCOLS.MARGIN.BUTTON.INTERACTIVE' | translate}}
                </a>
                <a *ngIf="protocol.isLiterature || protocol.hasLiterature"
                    class="topbarLink"
                    id="protocolStatus"
                    routerLink="/protocols/{{ protocol.id }}/literatures"
                    > 
                | {{'PROTOCOLS.MARGIN.BUTTON.REVIEW-LIT' | translate}}
                </a>

            <ng-container *ngxPermissionsOnly="['show_settings']">  |
                <a
                    *ngxPermissionsOnly="['show_settings']"
                    class="topbarLink"
                    id="settings"
                    (click)="handleOpenPane('settings')"
                >
                    {{'PROTOCOLS.MARGIN.BUTTON.SETTINGS' | translate}}
                </a>
            </ng-container>
                
        </div>
    </div>
</div>