import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../services';
import { environment } from '@environments/environment';

/* this component contains a logout-button and is useful in case of
custom styling that doesn't contain the main navigation bar and you
still want to show a logout button. default styling is display:none */

@Component({
    selector: 'alii-web-log-out-button',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
    constructor(private changeDetectorRef: ChangeDetectorRef, private authService: AuthService) {}
    
    public gerimedicaUser = false;

    private intervalId: any;

    ngOnInit() {
        this.intervalId = setInterval(() => {
            this.gerimedicaUser = this.isGerimedica();
            this.changeDetectorRef.detectChanges();
        }, 1000); 
      }

      
      private isGerimedica(): boolean {
        return sessionStorage.getItem('isGerimedica') == "true";
      }

      ngOnDestroy() {
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }
      }

    onLogout() {
        if (this.gerimedicaUser) {
            const externalPatientId = sessionStorage.getItem('gerimedicaId');
            sessionStorage.removeItem('populationId');
            sessionStorage.removeItem('gerimedicaId');
            sessionStorage.removeItem('isGerimedica');
    
            const baseUrl = environment.production ? 'https://sensire.ysis.nl/care/clients/' : 'https://sensire.acceptatie2.ysis.nl/';
            window.location.href = baseUrl + externalPatientId;    
        }
        
        else {
            this.authService.logout();
        }
    }
}
