/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { StartingPageRO, DashboardSelection, SetDashboardRO } from '../models';

@Injectable()
export class StartingPageService {
    constructor(private http: HttpClient) {}

    get(): Observable<StartingPageRO> {
        const url = `${environment.baseUrl}/api/v2/getStartingPage.vm?app=web`;
        return this.http.get<StartingPageRO>(url).pipe(catchError((error: any) => throwError(error)));
    }

    setDashboard(selected: DashboardSelection): Observable<SetDashboardRO> {
        const url = `${environment.baseUrl}/api/protocol/setDashboards.vm`;

        const data = {
            dashboard: selected.id
        };

        return this.http.post<SetDashboardRO>(url, data).pipe(
            map(response => response['message list'].dashboard),
            catchError((error: any) => throwError(error))
        );
    }
}
