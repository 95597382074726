import {
    Component,
    ChangeDetectionStrategy,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    OnInit
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {AgGridAngular} from 'ag-grid-angular';
import { RenderHTML } from '../../../../../literatures/components/reviews/render-html.component';
import { EditHTML } from '../../../../../literatures/components/reviews/edit-html.component';
import * as fromService from '../../../../../../services';
import { ControllersService } from 'ag-grid-community';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-paragraph-recommendation-table',
    templateUrl: './paragraph-recommendation-table.component.html',
    styleUrls: ['./paragraph-recommendation-table.component.scss']
})


export class ParagraphRecommendationTableComponent {
    @Input() isEditAble: boolean;
    @Input() parentId: string;
    @Input() sources: any[] = [];
    @Input() columnDefs: any[];
    @Input() rowData: any[];
    @Input() edit: boolean;
    @Input() index: number;
    @Input() protocolId: string;
    @Input() hasNumberedIndex: boolean;
    
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('agGrid') agGrid: AgGridAngular;
    
    actionsShown: boolean;
    frameworkComponents;
    

    constructor(
        private modalService: NgbModal,
        private literaturesService: fromService.LiteraturesService) {
        this.frameworkComponents = {
            renderHTML: RenderHTML,
            editHTML: EditHTML,
          };
    } 
    
    // If a footnote is clicked, open sources so that hilited item is visible.
    
    gridOptions: any = {
        rowHeight: 150
    }

    defaultColDef: any = {
        valueSetter: (params) => {            
            let newVal = params.newValue;
            const field = params.colDef.field;

            var values =  params.newValue.rowData
            const label = params.colDef.field;
            const nodeId = params.node.id;

            var action = "updateRecommendation"
        
            
            const eventPayload = {
              type: 'handleUpdateRecommendationAction',
              payload: {
                    action,
                    ppdId: nodeId,
                    field: label,
                    value: newVal,
                    protocolId: this.protocolId,
                    parent: this.parentId
              }
          };
          this.eventBus.emit(eventPayload);
          params.api.onRowHeightChanged();
          return false;
        },
        resizable: true,
        cellStyle: {
          display: 'flex',
          'align-items': 'center',
          'autoHeight': 'true' 
        }
    };


    showTable() {
        if (this.edit) {return true}
        else {
            let tableIsFilled = false
            this.rowData.forEach(element => {
                
                for (let key in element) {
                    if (key != 'id' && element[key]) {
                        tableIsFilled = true
                    }
                }                
            }); 
            return tableIsFilled
        }
    }

    resetRowHeight() {
        //this.agGrid.api.resetRowHeights();
    }

    deleteRecommendationRow() {
        let data = this.getSelectedRowData()
        const action = {
            type: 'handleDeleteRecommendationRow',
            payload: {
                data
            }
        };
        this.eventBus.emit(action);

    }

    showActions() {
        this.actionsShown = true
    }

    getSelectedRowData() {
        let selectedNodes = this.agGrid.api.getSelectedNodes();
        let selectedData = selectedNodes.map(node => node.data);
        return selectedData;
    }

    getRowNodeId(data) {
    return data.id;
    }

    handleEventBus(event) {
        this.eventBus.emit(event);
    }

    trackByFn = (index, item) => item.id || index;
}
