import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as TeamActions from '../actions/teams.action';
import * as fromService from '../../../../services/organisation.service';
import { of } from 'rxjs';

@Injectable()
export class TeamEffects {
    constructor(private actions$: Actions, private organizationService: fromService.OrganisationService) {}

    @Effect()
    UpdateTeamEffect$ = this.actions$.pipe(
        ofType(TeamActions.UPDATE_TEAM),
        switchMap(action => {
            const { payload } = action as any;
            return this.organizationService.UpdateTeam({ payload }).pipe(
                map(response => new TeamActions.UpdateTeamSuccess (response)),
                catchError(error => of(new TeamActions.UpdateTeamFail(error)))
            );
        })
    );
}
