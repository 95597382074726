import { Pubmed } from '../../../../models/pubmed.interface';
import { PubmedActions, PubmedActionTypes } from '../actions/pubmed.actions';

export interface PubmedState {
    query: string;
    parentId: string;
    ppdId: string;
    results: Pubmed[];
    loading: boolean;
    loaded: boolean;
    error: any;
}

export const initialState: PubmedState = {
    query: '',
    parentId: null,
    ppdId: null,
    results: [],
    loading: false,
    loaded: false,
    error: null
};

export function reducer(state = initialState, action: PubmedActions): PubmedState {
    switch (action.type) {
        case PubmedActionTypes.LoadPubmeds: {
            const { query, parentId, ppdId } = action.payload;
            return {
                ...state,
                query,
                parentId,
                ppdId,
                results: [],
                loading: true,
                loaded: false,
                error: null
            };
        }
        case PubmedActionTypes.LoadPubmedsSucces: {
            const { pubmeds } = action.payload;
            return {
                ...state,
                results: pubmeds,
                loading: false,
                loaded: true
            };
        }

        case PubmedActionTypes.LoadPubmedsFail: {
            const { error } = action.payload;
            return {
                ...state,
                loading: false,
                loaded: false,
                error
            };
        }

        case PubmedActionTypes.ClearPubmeds: {
            return {
                ...initialState
            };
        }

        default: {
            return state;
        }
    }
}

export const LoadPubmeds = (state: PubmedState) => state;
