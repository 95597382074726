import { Component, ChangeDetectionStrategy, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Pubmed } from '../../../../models/pubmed.interface';
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'search-articles-modal',
    templateUrl: './search-articles-modal.component.html'
})
export class SearchArticlesModalComponent implements OnInit {
    pubmeds: Pubmed[];

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    form: FormGroup;
    searchForm: FormGroup;
    query: string;
    searchLoading: boolean;
    formIsValid: boolean;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({});
        this.searchForm = this.fb.group({
            query: [this.query, Validators.required]
        });
        this.buildUids();
        this.formIsValid = false;
        this.form.valueChanges.subscribe(() => {
            this.formIsValid = this.getCheckedIds().length > 0;
        });
    }

    buildUids() {
        this.pubmeds.map(pubmed => {
            this.form.addControl(pubmed.uid, this.fb.control(false));
        });
    }

    onSubmitSearch() {
        if (this.searchForm.valid) {
            const { query = '' } = {
                ...this.searchForm.value
            };
            this.searchLoading = true;
            const event = { type: 'handleSubmitSearch', payload: { query } };
            this.eventBus.emit(event);
        }
    }

    onSubmitAddArticles() {
        if (this.form.valid && this.formIsValid) {
            const uids = this.getCheckedIds();

            if (uids.length > 0) {
                const event = { type: 'handleAddArticles', payload: { uids } };
                this.eventBus.emit(event);
            }
        }
    }

    getCheckedIds() {
        const data = { ...this.form.value };
        return Object.keys(data).filter(item => data[item]);
    }

    onCancel() {
        const event = { type: 'handleClearPubmedSearch', payload: {} };
        this.eventBus.emit(event);
        this.activeModal.close();
    }

    trackByFn = (index, item) => item.id || index;
}
