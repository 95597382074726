import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SupportFormModalComponent } from '../../entry-components';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-help-users-guide',
    templateUrl: './users-guide.component.html',
    styleUrls: ['./users-guide.component.scss']
})
export class UsersGuideComponent implements OnInit {
    @Input() dashboardTitle = 'GebruikersHandboek';
    @Input() messageTitle =
        'Het Alii Platform is ideaal om kennis te structureren. In het gebruikershandboek vind je uitleg over de mogelijkheden.';
    @Input() messageBody = ' ';
    @Input() ownerName = 'Alii';
    @Input() ownerText = ' ';
    @Input() ownerImageUrl = '/assets/images/alii-logo.jpg';

    ownerExists;

    constructor(private modalService: NgbModal) {}

    ngOnInit() {
        this.ownerExists =
            (this.ownerName && this.ownerName.length) ||
            (this.ownerText && this.ownerText.length) ||
            (this.ownerImageUrl && this.ownerImageUrl.length);
    }

    getSupport() {
        const modalRef = this.modalService.open(SupportFormModalComponent);
        modalRef.result.then(
            // result => this.store.dispatch(new fromStore.UpdateShopTemplateSet(result)),
            () => {},
            () => {}
        );
    }
}
