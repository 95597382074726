import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IHttpState {
    url: string;
    state: HttpProgressState;
}

export enum HttpProgressState {
    start,
    end
}

@Injectable({
    providedIn: 'root'
})
export class HttpStateService {
    public state = new BehaviorSubject<IHttpState>({} as IHttpState);

    constructor() {}
}
