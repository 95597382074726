import { DashboardManageComponent } from './dashboard-manage/dashboard-manage.component';
import { DashboardEditContainer } from './dashboard-edit/dashboard-edit-container.component';
import { DashboardTagManageComponent } from './dashboard-tag-manage/dashboard-tag-manage.component';
import { DashboardStatisticsComponent } from './dashboard-statistics/dashboard-statistics.component';
import { DashboardTagEditContainerComponent } from './dashboard-tag-edit/dashboard-tag-edit-container.component';

// V2 API
import { CategoryManageV2Component } from './v2/category-manage/category-manage.component';
// import { DashboardEditV2Component } from './v2/dashboard-edit/dashboard-edit.component';
// import { DashboardCreateV2Component } from './v2/dashboard-create/dashboard-create.component';
import { DashboardManageV2Component } from './v2/dashboard-manage/dashboard-manage.component';

export const containers = [
    DashboardManageComponent,
    DashboardEditContainer,
    DashboardTagManageComponent,
    DashboardStatisticsComponent,
    DashboardTagEditContainerComponent,

    // V2 API
    CategoryManageV2Component,
    // DashboardCreateV2Component,
    // DashboardEditV2Component,
    DashboardManageV2Component
];

export * from './dashboard-manage/dashboard-manage.component';
export * from './dashboard-edit/dashboard-edit-container.component';
export * from './dashboard-tag-manage/dashboard-tag-manage.component';
export * from './dashboard-statistics/dashboard-statistics.component';
export * from './dashboard-tag-edit/dashboard-tag-edit-container.component';

// V2 API
export * from './v2/category-manage/category-manage.component';
// export * from './v2/dashboard-create/dashboard-create.component';
// export * from './v2/dashboard-edit/dashboard-edit.component';
export * from './v2/dashboard-manage/dashboard-manage.component';
