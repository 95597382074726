import * as fromSetDashboard from '../actions/set-dashboard.action';

export interface SetDashboardState {
    loading: boolean;
    loaded: boolean;
    error: string;
}

export const initialState: SetDashboardState = {
    loading: false,
    loaded: false,
    error: null
};

export function reducer(state = initialState, action: fromSetDashboard.SetDashboardAction): SetDashboardState {
    switch (action.type) {
        case fromSetDashboard.SET_DASHBOARD: {
            return {
                ...state,
                ...initialState,
                loading: true
            };
        }

        case fromSetDashboard.SET_DASHBOARD_SUCCESS: {
            return {
                ...state,
                loaded: true,
                loading: false
            };
        }

        case fromSetDashboard.SET_DASHBOARD_FAIL: {
            const { payload } = action as any;
            return {
                ...state,
                loading: false,
                error: payload
            };
        }
    }

    return state;
}

export const getSetDashboardLoading = (state: SetDashboardState) => state.loading;
