import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { EditTagModalComponent, RemoveDataModalComponent } from '../../entry-components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardTag } from '../../../../models/dashboard-tag.model';
import { Protocol } from '../../../../models/protocol.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dashboard-tag-edit',
    templateUrl: './dashboard-tag-edit.component.html'
})
export class DashboardTagEditComponent implements OnInit {
    @Input()
    values: DashboardTag;

    @Input()
    tagProtocols: Protocol[];

    @Input()
    orgProtocols: Protocol[];

    @Input()
    nonOrgProtocols: Protocol[];

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    addOrgProtocolForm: FormGroup;
    addNonOrgProtocolForm: FormGroup;

    constructor(private modalService: NgbModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.addOrgProtocolForm = this.fb.group({
            protocolId: [null, Validators.required]
        });
        this.addNonOrgProtocolForm = this.fb.group({
            protocolId: [null, Validators.required]
        });
    }
    handleSubmitOrg() {
        if (this.addOrgProtocolForm.valid) {
            const data = this.addOrgProtocolForm.value;
            const action = { type: 'addProtocol', payload: { protocolId: data.protocolId } };
            this.eventBus.emit(action);
            this.addOrgProtocolForm.reset();
        }
    }
    handleSubmitNonOrg() {
        if (this.addNonOrgProtocolForm.valid) {
            const values = this.addNonOrgProtocolForm.value;
            const action = { type: 'addProtocol', payload: { protocolId: values.protocolId } };
            this.eventBus.emit(action);
            this.addNonOrgProtocolForm.reset();
        }
    }
    removeProtocol(protocolId) {
        const action = { type: 'removeProtocol', payload: { protocolId } };
        this.eventBus.emit(action);
    }

    handleOpenForm() {
        const modalRef = this.modalService.open(EditTagModalComponent);
        modalRef.result.then(
            result => {
                const action = {
                    type: 'updateTag',
                    payload: {
                        id: this.values.id,
                        title: result.title
                    }
                };
                this.eventBus.emit(action);
            },
            reason => {}
        );
        modalRef.componentInstance.title = this.values.title;
    }
    handleOpenDelete() {
        const modalRef = this.modalService.open(RemoveDataModalComponent);
        modalRef.result.then(
            result => {
                const action = {
                    type: 'removeTag',
                    payload: {
                        tagId: this.values.id
                    }
                };
                this.eventBus.emit(action);
            },
            reason => {}
        );
        modalRef.componentInstance.headerTitle = 'Remove Tag';
        modalRef.componentInstance.bodyText = 'Are you sure you want to delete this category?';
    }

    trackByFn = (index, item) => item.id || index;
}
