<div class="dashboard">
    
    <div class="dashboard__title">{{'DASHBOARD.CHOOSETEAM.INTRO' | translate }}</div>
    <div class="dashboard__items">
        <div class="dashboard__item" *ngFor="let team of teamList; trackBy: trackByFn">
            <article class="card">
                <header #onboardingCard class="card__header">
                    <span class="card__header-heading">{{ team.label }}</span>             
                </header>
                <footer class="card__footer">
                    <span class="card__main">
                        <div class="row" *ngFor="let dashboard of team.dashboardList">
                            <div class="col-9">{{dashboard.label}}</div>
                            <div class="col-3">
                                <span class="tag " (click)="handleSelectDashboard(dashboard.id)">select</span>
                            </div>
                        </div>
                   </span>
                </footer>
            </article>
        </div>
    </div>
</div>
