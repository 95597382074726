import { Action } from '@ngrx/store';
import { User } from '../../../../models/user.interface';

export const LOAD_USERS = '[Organisation] Load Users';
export const LOAD_USERS_FAIL = '[Organisation] Load Users Fail';
export const LOAD_USERS_SUCCESS = '[Organisation] Load Users Success';

export class LoadUsers implements Action {
    readonly type = LOAD_USERS;
    constructor(public payload: any) {}
}

export class LoadUsersFail implements Action {
    readonly type = LOAD_USERS_FAIL;
    constructor(public payload: any) {}
}

export class LoadUsersSucces implements Action {
    readonly type = LOAD_USERS_SUCCESS;
    constructor(public payload: User[]) {}
}

export const CREATE_USER = '[Organisation] Create User';
export const CREATE_USER_SUCCESS = '[Organisation] Create User Success';
export const CREATE_USER_FAIL = '[Organisation] Create User Fail';

export class CreateUser implements Action {
    readonly type = CREATE_USER;
    constructor(public payload: any) {}
}

export class CreateUserFail implements Action {
    readonly type = CREATE_USER_FAIL;
    constructor(public payload: any) {}
}

export class CreateUserSuccess implements Action {
    readonly type = CREATE_USER_SUCCESS;
    constructor(public payload: any) {}
}

export const REMOVE_USER = '[Organisation] Remove User';
export const REMOVE_USER_SUCCESS = '[Organisation] Remove User Success';
export const REMOVE_USER_FAIL = '[Organisation] Remove User Fail';

export class RemoveUser implements Action {
    readonly type = REMOVE_USER;
    constructor(public payload: any) {}
}

export class RemoveUserFail implements Action {
    readonly type = REMOVE_USER_FAIL;
    constructor(public payload: any) {}
}

export class RemoveUserSuccess implements Action {
    readonly type = REMOVE_USER_SUCCESS;
    constructor(public payload: any) {}
}

export const ADD_OR_REMOVE_ROLE = '[Organisation] Remove Role Admin';
export const ADD_OR_REMOVE_ROLE_SUCCESS = '[Organisation] Remove Role Success';
export const ADD_OR_REMOVE_ROLE_FAIL = '[Organisation] Remove Role Fail';

export class AddOrRemoveRole implements Action {
    readonly type = ADD_OR_REMOVE_ROLE;
    constructor(public payload: any) {}
}

export class AddOrRemoveRoleFail implements Action {
    readonly type = ADD_OR_REMOVE_ROLE_FAIL;
    constructor(public payload: any) {}
}

export class AddOrRemoveRoleSuccess implements Action {
    readonly type = ADD_OR_REMOVE_ROLE_SUCCESS;
    constructor(public payload: any) {}
}




export type UsersAction =
    | LoadUsers
    | LoadUsersFail
    | LoadUsersSucces
    | CreateUser
    | CreateUserSuccess
    | CreateUserFail
    | RemoveUser
    | RemoveUserSuccess
    | RemoveUserFail
    | AddOrRemoveRoleFail
    | AddOrRemoveRoleSuccess
    | AddOrRemoveRole
