<h1>Beheer gebruikersgroepen</h1>
Maak gebruikersgroep aan en voeg leden toe. <br /><br />
<h3>Gebruikersgroepen</h3>
<ul>
    <ul *ngFor="let group of (usergroups$ | async)?.usergroup">
        <li>
            <a href="/organization/groups/{{ group.id }}">{{ group.title }}</a>
        </li>
    </ul>
    <br />

    <br style="clear: both;" />
    &nbsp;
</ul>

<br />
<h3>Nieuwe groep aanmaken</h3>
<form>
    <div class="form-group row">
        <div class="col-sm-8">
            <input
                type="text"
                placeholder="Naam van nieuwe groep"
                class="form-control"
                style="border: 1px solid #ddd"
                #groupName
            />
        </div>
        <button class="btn btn-info" style="height: 2.25rem" type="submit" (click)="onCreateUserGroup(groupName.value)">
            Groep aanmaken
        </button>
    </div>
</form>
