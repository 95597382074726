import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromStartingPage from '../reducers/starting-page.reducer';

export const getStartingPageState = createSelector(
    fromFeature.getStartingPageFeatureState,
    (state: fromFeature.StartingPageFeatureState) => {
        return state.startingPage;
    }
);
export const getStartingPageGetLoaded = createSelector(getStartingPageState, fromStartingPage.getStartingPageGetLoaded);
export const getStartingPageGetLoading = createSelector(
    getStartingPageState,
    fromStartingPage.getStartingPageGetLoading
);
export const getStartingPageGetError = createSelector(getStartingPageState, fromStartingPage.getStartingPageGetError);
export const getStartingPageActions = createSelector(getStartingPageState, state => state.response.actions);
export const getStartingPageTabs = createSelector(getStartingPageState, fromStartingPage.getStartingPageTabs);
export const getStartingPageDashboards = createSelector(getStartingPageActions, actions => {
    let result = [];
    if (actions) {
        const action = actions.find(action => action.action === 'change_dashboard');
        if (action) {
            result = action.dashboards || [];
        }
    }
    return result;
});
