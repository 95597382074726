import { Component, ChangeDetectionStrategy, EventEmitter, OnInit, Output, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RenderHTML } from '../../../literatures/components/reviews/render-html.component';
import { EditHTML } from '../../../literatures/components/reviews/edit-html.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'grade-form-modal',
    templateUrl: './grade-form-modal.component.html',
    styleUrls: ['./grade-form-modal.component.scss']
})
export class GradeFormModalComponent implements OnInit {
    @Output() handleNavigate = new EventEmitter<number>();
    @Output() eventBus: EventEmitter<any> = new EventEmitter<any>();
    public frameworkComponents;

    showUploadForm: boolean;
    expandedImage: boolean; 
    gradeAssessmentPar: any;
    isCreateGrade: boolean;
    waitingForUpload: boolean;
    @Input() ppdId: string;
    @Input() parentId: string;
    selectedQuestion: any;
    selectedQuestionIndex: number;
    previousQuestionIndex: number;
    nextQuestionIndex: number; 
    form: FormGroup;
    commentForm: FormGroup;
    fileForm: FormGroup;
    rowData: [];
    fieldsRowData: [];
    @Input() edit: boolean;
    file: any;
    uploadResponse: any = { status: '', message: '', filePath: '', error: '' };

    regex = /_/gi;
    readOnlycolumnDefs: any[];
    readOnlySummaryColumnDefs: any[];

    constructor(
        public activeModal: NgbActiveModal, 
        private fb: FormBuilder,
        ) {this.frameworkComponents = {
            renderHTML: RenderHTML,
            editHTML: EditHTML,
          };}

    @ViewChild('agGrid') agGrid: AgGridAngular;

    ngOnInit() {
        this.fileForm = this.fb.group({
            file: ['']
        });
        let commentText = ''
        if (this.selectedQuestion.comment) {commentText = this.selectedQuestion.comment}
        this.commentForm = this.fb.group({
            comment: [commentText]
        });
        
        let selectedValue = ''
        if(this.selectedQuestion.options) {
            let index = this.selectedQuestion.options.findIndex(option => option.selected )
            if (index !== -1) {selectedValue = this.selectedQuestion.options[index].value}
        }
        this.form = this.fb.group({
            select: [selectedValue]
        });
        this.previousQuestionIndex = this.gradeAssessmentPar.questions[this.selectedQuestionIndex - 1]
            ? this.selectedQuestionIndex - 1
            : null;
        this.nextQuestionIndex = this.gradeAssessmentPar.questions[this.selectedQuestionIndex + 1]
            ? this.selectedQuestionIndex + 1
            : null;

        // Make cell read only when not in edit-mode
        if (!this.edit && this.selectedQuestion?.columnDefs) {
            this.readOnlycolumnDefs = [];
            this.selectedQuestion.columnDefs.forEach(col => {
                var newCol = {... col, 'editable': false};
                this.readOnlycolumnDefs.push(newCol);
            });
        }
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            this.file = event.target.files[0];
            this.fileForm.get('file').setValue(this.file);
        }
    }
    convertToArray(obj) {
        return Object.keys(obj).map(i => {
            return { key: i, value: obj[i] };
        });
    }

    onSubmitFile() {
        let ppdId = ''
        if(this.ppdId) {
            ppdId = this.ppdId
        } else {
            ppdId = this.gradeAssessmentPar.ppdId
        }
        const formData = new FormData();
        formData.append('file', this.fileForm.get('file').value);
        this.eventBus.emit({ type: 'handleUploadFileToGrade', payload: { formData, title: this.file.name, reviewQuestion: this.selectedQuestion.field, ppdId } });
        this.waitingForUpload = true
        this.showUploadForm = false
    }

    navigateQuestion(questionIndex) {
        const data = {
            ...this.form.value
        };
        let value = data.select
        this.handleSubmit(value);
        this.handleNavigate.emit(questionIndex);
    }

    closeModal() {
        if (this.edit) {
            const data = {
                ...this.form.value
            };
            let value = data.select
            this.handleSubmit(value)    
        }
        this.activeModal.dismiss()
    }

     onChangeValue(value) {
        this.handleSubmit(value);
    }


    handleSubmit(payload) {
        if (!this.edit) {
            return;
        }

        let comment = ''
        if (this.commentForm.value) {}
        const data = {
            ...this.commentForm.value
        };
        comment = data.comment
        let ppdId = ''
        if(this.ppdId) {
            ppdId = this.ppdId
        } else {
            ppdId = this.gradeAssessmentPar.ppdId
        }
        let action = {}
        if (this.isCreateGrade) {
             action = {
                type: 'handleSubmitCreateGrade',
                payload: {
                    ppdId,
                    key: this.selectedQuestion.field,
                    value: payload,
                    parentId: this.parentId,
                    type: this.gradeAssessmentPar.type, 
                    comment
                }
            };
        } else {
             action = {
                type: 'handleSubmitUpdateGrade',
                payload: {
                    ppdId,
                    key: this.selectedQuestion.field,
                    value: payload,
                    type: this.gradeAssessmentPar.type, 
                    gradeId: this.gradeAssessmentPar.id,
                    parentId: this.parentId,
                    comment
                }
                
            };
        }
        this.eventBus.emit(action);
    }

    summaryColDef: any = {
        valueSetter: (params) => {
            let newVal = params.newValue;
            const field = params.data.label;
            
            params.data['value'] = newVal

            var reviewId = this.gradeAssessmentPar.id
            var action = "updateReview"
         
            let question = field
            if(params.data.id) {
                question = params.data.id
            }

            let ppdId = ''
            if(this.ppdId) {
                ppdId = this.ppdId
            } else {
                ppdId = this.gradeAssessmentPar.ppdId
            }
            const eventPayload = {
              type: 'handleGradeUpdateRowAction',
              payload: {
                    ppdId,
                    action, 
                    question, 
                    answer: newVal, 
                    reviewId, 
                    parentQuestion: this.selectedQuestion.field

              }
          };
          this.eventBus.emit(eventPayload);
          return true;
        },
        resizable: true,
        flex: 2,
        autoHeight: true,
        wrapText: true,
        autoSizeColumns:true,
        cellStyle: {
          display: 'flex',
          'align-items': 'center',
          'autoHeight': 'true' 
        }
      };


    defaultColDef: any = {
        valueSetter: (params) => {
            let newVal = params.newValue;
            const field = params.colDef.field;

            params.data[field] = newVal

            var articleId = params.data.articleId
            var reviewId = this.gradeAssessmentPar.id
            var action = "updateReview"
         
            let ppdId = ''
            if(this.ppdId) {
                ppdId = this.ppdId
            } else {
                ppdId = this.gradeAssessmentPar.ppdId
            }
            const eventPayload = {
              type: 'handleGradeUpdateRowAction',
              payload: {
                    ppdId,
                    sourceId: params.data.id,
                    action, 
                    question: field, 
                    answer: newVal, 
                    reviewId, 
                    articleId,
                    parentQuestion: this.selectedQuestion.field

              }
          };
          this.eventBus.emit(eventPayload);
          this.agGrid.api.redrawRows();
          return false;
        },
        resizable: true, 
        autoHeight: true,
        wrapText: true,
        autoSizeColumns:true,
        cellStyle: {
          display: 'flex',
          'align-items': 'top',
          'autoHeight': 'true' 
        }
      };

    getRowNodeId(data) {
        return data.id;
      }

    handleEventBus(event) {
        this.eventBus.emit(event);
    }
 

    trackByFn = (index, item) => item.id || index;
}
