import * as fromDashboardForm from '../actions/dashboard-form.action';
import { DashboardDetail } from '@alii-web/models/dashboard-detail.model';

export interface DashboarFormState {
    values: DashboardDetail;
    loaded: boolean;
    submited: boolean;
    loading: boolean;
}

export const initialState: DashboarFormState = {
    values: {
        id: null,
        name: ''
    },
    loaded: false,
    submited: false,
    loading: false
};

export function reducer(state = initialState, action: fromDashboardForm.DashboardFormAction): DashboarFormState {
    switch (action.type) {
        case fromDashboardForm.LOAD_DASHBOARD_FORM: {
            const values = action.payload;
            return {
                ...state,
                loaded: true,
                values
            };
        }
    }

    return state;
}

export const getDashboardFormValues = (state: DashboarFormState) => state.values;

export const getDashboardFromLoading = (state: DashboarFormState) => state.loading;
export const getDashboardFromLoaded = (state: DashboarFormState) => state.loaded;
export const getDashboardFromSubmited = (state: DashboarFormState) => state.submited;
