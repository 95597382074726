import {
    Component,
    ChangeDetectionStrategy,
    EventEmitter,
    Input,
    Output,
    OnInit,
    SimpleChanges,
    OnChanges
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Paragraph } from '../../../../../../models/paragraph.interface';
import produce from 'immer';

const cn = 'ModelEditComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-model-edit',
    templateUrl: './model-edit.component.html',
    styleUrls: ['./model-edit.component.scss']
})
export class ModelEditComponent implements OnInit {
    @Input()
    channelMessages?: any;

    @Input()
    protocolId: string;

    @Input()
    parentId: string;

    @Input()
    paragraph: Paragraph;

    @Input()
    findings: any[];

    @Input()
    outcomes: any[];

    @Input()
    modelSource: string;

    @Input()
    modelMessages: any;

    @Input()
    isEditAble: boolean;

    @Input()
    viewByPopulation: boolean;

    @Input()
    populations: any;

    @Input() gradeAssessment: any;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    arrayIsCollapsedPopulations: boolean[];
    populationAddedGrade: any = [];

    showAllOutcomes = false;

    constructor(private modalService: NgbModal) {}

    ngOnInit(): void {
        if (!this.populations) {
            this.arrayIsCollapsedPopulations = [];
        } else {
            this.arrayIsCollapsedPopulations = this.populations.reduce((acc, pop) => {
                return {
                    ...acc,
                    [pop.id]: true
                };
            }, {});
        }
    }


    getPopulationGradeAction(populationId) {
        const grade = this.gradeAssessment.entries[populationId] ? this.gradeAssessment.entries[populationId] : {};
        const { action = 'addGradeAssessment' } = grade ? grade : {};
        return action;
    }

    getGradeViewTemplateContext(ppdId) {
        const gradeAssessment = this.gradeAssessment.entries[ppdId] ? this.gradeAssessment.entries[ppdId] : {};
        return { ppdId, gradeAssessment };
    }

    formatGradeKey(key: string) {
        return key.replace(/_/g, ' ').replace('GRADE - ', '');
    }

    // TODO: Duplicated code paragraph-footer.component.ts
    summaryOfFinding(finding) {
        const ignoreColumns = ['action', 'id', 'user', 'user_name', 'status', 'actionLabel'];

        return Object.keys(finding)
            .filter(key => !ignoreColumns.includes(key))
            .filter(key => finding[key] !== null)
            .filter(key => finding[key].toString() !== '')
            .map(key => {
                // TODO: fix this in backend
                const value = finding[key].toString().replace('illegal reference: ', '');
                return {
                    key,
                    value
                };
            });
    }

    toggleShowPopulations(outcomeId) {
        this.populationsVisible[outcomeId] = !this.populationsVisible[outcomeId];
    }

    populationsVisible(outcomeId) {
        return outcomeId in this.populationsVisible && this.populationsVisible[outcomeId];
    }

    checkShowEvidencePopulation(population) {
        return (
            population.sources.length ||
            population.gradeAssessment.action === 'editGradeAssessment' ||
            this.populationAddedGrade.includes(population.id)
        );
    }

    onClickReferencesOnPopulation(payload) {
        const { ppdId, populationId } = payload;
        const getQuestionsAction = {
            type: 'handleGetGradeQuestions',
            payload: {
                protocolId: this.protocolId,
                populationId: ppdId
            }
        };
        this.eventBus.emit(getQuestionsAction);
        this.togglePopulation(populationId);
        return false;
    }

    onClickAddPopulationGrade(payload) {
        const { populationId } = payload;
        this.populationAddedGrade.push(populationId);
        this.showEvidence(populationId);
        this.onClickCreateGrade(payload);
    }

    showEvidence(populationId) {
        this.arrayIsCollapsedPopulations[populationId] = false;
    }

    /* this is a temporary solution, forcing always one population to be shown at once */
    togglePopulation(populationId) {
        const beforeState = this.arrayIsCollapsedPopulations[populationId];

        if (this.populations) {
            this.arrayIsCollapsedPopulations = this.populations.reduce((acc, pop) => {
                return {
                    ...acc,
                    [pop.id]: true
                };
            }, {});

            this.arrayIsCollapsedPopulations[populationId] = !beforeState;
        } else {
            console.warn(`${cn} togglePopulation() populationId='${populationId}' populations is undefined`);
            this.arrayIsCollapsedPopulations = [];
        }
    }

    onClickUpdateGrade(payload) {
        const { ppdId, field } = payload;
        const action = {
            type: 'onClickUpdateGrade',
            payload: {
                ppdId,
                field
            }
        };
        this.eventBus.emit(action);
    }

    handleClickIncludeSource(source, articleId) {
        const action = {
            type: 'handleClickIncludeSource',
            payload: {
                action: source.action,
                sourceId: source.id,
                articleId,
                protocolId: this.protocolId,
                ppdId: this.paragraph.id,
                parentId: this.parentId
            }
        };
        this.eventBus.emit(action);
    }

    handleEventBus(event) {
        const { type } = event;
        switch (type) {
            default:
                this.eventBus.emit(event);
                break;
        }
    }

    onClickAddReference() {
        const action = {
            type: 'onClickAddReference',
            payload: {}
        };
        this.eventBus.emit(action);
    }

    onClickCreateGrade(payload) {
        const action = {
            type: 'onClickCreateGrade',
            payload
        };
        this.eventBus.emit(action);
    }

    onClickOption(event, option, questionId) {
        const value = option.type === 'continuous' ? event.target.value : option.value;
        const action = { type: 'handleClickOption', payload: { option: { ...option, value }, questionId } };

        return this.eventBus.emit(action);
    }


    onClickArticle(article) {
        const { articleId } = article;

        const viewArticleAction = {
            type: 'onClickArticle',
            payload: {
                articleId
            }
        };
        this.eventBus.emit(viewArticleAction);
        return false;
    }

    newFindings(sources) {
        sources.forEach(source => {
            source.finding.forEach(finding => {
                if (finding.status === 'new') {
                    return true;
                }
            });
        });
        return false;
    }

    toggleFindings(articleId) {
        this.findingsVisible[articleId] = !this.findingsVisible[articleId];
    }

    findingsVisible(articleId) {
        return articleId in this.findingsVisible && this.findingsVisible[articleId];
    }

    numberToList(num) {
        return Array.from(Array(+num), (x, i) => i);
    }

    ObjectToArray(o) {
        return o ? Object.values(o) : [];
    }

    FindingsObjectToArray(findingsObject) {
        const findings = this.ObjectToArray(findingsObject);
        findings.sort((a, b) => (a['index'] > b['index'] ? 1 : -1));
        return findings;
    }

    checkOptionsType(options, type) {
        const types = Object.keys(options).find(opt => options[opt].type === type);
        return types;
    }

    onToggleShowAllOutcomes() {
        this.showAllOutcomes = !this.showAllOutcomes;

        const action = this.showAllOutcomes
            ? {
                  type: 'handleShowAllOutcomes',
                  payload: {
                      modelId: this.paragraph.id
                  }
              }
            : {
                  type: 'handleShowOutcomesByPopulationId',
                  payload: {
                      modelId: this.paragraph.id
                  }
              };

        this.eventBus.emit(action);
    }

    isQuestionExist(field) {
        return this.getQuestionIndex(field) > -1;
    }

    getQuestionIndex(field) {
        if (!this.gradeAssessment.selected.questions) {
            return -1;
        }
        return this.gradeAssessment.selected.questions.findIndex(question => question.field === field);
    }

    hasSelectedGrade() {
        return 'selected' in this.gradeAssessment && this.gradeAssessment.selected;
    }

    convertToArray(obj) {
        const ignoreColumns = ['fields'];
        let columns = Object.keys(obj);
        columns = columns.filter(column => !ignoreColumns.includes(column));

        return columns.map(i => {
            return { key: i, value: obj[i] };
        });
    }

    resetModel() {
        const modelId = this.paragraph.id;
        this.eventBus.emit({
            type: 'resetModel',
            payload: { modelId }
        });
    }

    trackByFn = (index, item) => item.id || index;
}
