import { StringMap } from "@angular/compiler/src/compiler_facade_interface";

export const getFlowchartOptions = (flowchart: any, primaryColor: string, secondaryColor: string) => ({
    'x': 10,
    'y': 10,
    'line-width': 3,
    'maxWidth': 320,
    'line-length': 72,
    'text-margin': 24,
    'font-size': 24,
    'font': 'normal',
    'font-family': '"Roboto-Regular", "Avant Garde", sans-serif',
    'font-weight': 'normal',
    'font-color': '#979797',
    'line-color': '#979797',
    'element-color': '#979797',
    'fill': 'white',
    'yes-text': flowchart.yesText || 'yes',
    'no-text': flowchart.noText || 'no',
    'stroke': '#979797',
    'arrow-end': 'classic-wide-long',
    'arrow-end-color': '#fff',
    'scale': 0.5,
    'symbols': {
        start: {
            'font-color': 'red',
            'element-color': 'green',
            'fill': 'yellow'
        },
        end: {
            class: 'end-element'
        }
    },
    'flowstate': {
        decision: { 'fill': secondaryColor, 'font-color': '#FFF', 'text-margin': 24 },
        parallel: { 'fill': primaryColor, 'font-color': '#FFF', 'font-size': 24, 'yes-text': 'parallel', 'no-text': 'parallel' },
        borderLess: {
            'fill': '#f9f9f9',
            'font-size': 24,
            'text-margin': 0.1,
            'line-color': '#f9f9f9',
            'arrow-color': '#fff',
            'element-color': '#f9f9f9',
            'yes-text': ' ',
            'no-text': ' '
        },
        red: { 'fill': '#ff0000', 'font-color': '#FFF', 'text-margin': 24 },
        start: { 'fill': 'white', 'font-color': '#706f6e' },
        linkblock: { 'fill': 'white', 'font-color': '#E7511F' },
        linkdecision: { 'fill': secondaryColor, 'font-color': '#FFF' },
        end: { 'fill': 'white' }
    }
});
