import * as fromOrganization from '../actions/organization.action';

import { Organization } from '../../../../models/organization.interface';

export interface OrganizationState {
    data: Organization;
    loaded: boolean;
    loading: boolean;
}

export const initialState: OrganizationState = {
    data: null,
    loaded: false,
    loading: false
};

export function reducer(state = initialState, action: fromOrganization.OrganizationAction): OrganizationState {
    switch (action.type) {
        case fromOrganization.LOAD_ORGANIZATION: {
            return { ...state, loading: true };
        }

        case fromOrganization.LOAD_ORGANIZATION_SUCCESS: {
            const { payload } = action;
            return { ...state, loading: false, loaded: true, data: payload };
        }

        case fromOrganization.LOAD_ORGANIZATION_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
    }
    return state;
}

export const getOrganization = (state: OrganizationState) => state.data;
export const getOrganizationLoading = (state: OrganizationState) => state.loading;
export const getOrganizationLoaded = (state: OrganizationState) => state.loaded;
