import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';

import * as UsersActions from '../actions/users.action';
import * as fromService from '../../../../services/organisation.service';
import { of } from 'rxjs';

import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UsersEffects {
    constructor(
        private actions$: Actions, 
        private organisationService: fromService.OrganisationService,
        private toastr: ToastrService) {}

    @Effect()
    loadUsers$ = this.actions$.pipe(
        ofType(UsersActions.LOAD_USERS),
        switchMap(action => {
            const teamId = (action as any).payload;
            return this.organisationService.getUsers('', teamId).pipe(
                map(dashboard => new UsersActions.LoadUsersSucces(dashboard)),
                catchError(error => of(new UsersActions.LoadUsersFail(error)))
            );
        })
    );

    @Effect()
    createUser$ = this.actions$.pipe(
        ofType(UsersActions.CREATE_USER),
        switchMap(action => {
            const data = (action as any).payload;
            return this.organisationService.createUser(data).pipe(
                map(response => {
                    if (response.error) { 
                        return new  UsersActions.CreateUserFail(response)
                    } else {
                        return new UsersActions.CreateUserSuccess(response)
                    }
                }),
                catchError(error => of(new UsersActions.CreateUserFail(error)))
            );
        })
    );

    @Effect()
    AddOrRemoveRole$ = this.actions$.pipe(
        ofType(UsersActions.ADD_OR_REMOVE_ROLE),
        switchMap(action => {
            const data = (action as any);
            let payload = data.payload
            return this.organisationService.editRole(payload).pipe(
                map(response => {
                    if (response.error) { 
                        return new  UsersActions.AddOrRemoveRoleFail(response)
                    } else {
                        return new UsersActions.AddOrRemoveRoleSuccess(response)
                    }
                }),
                catchError(error => of(new UsersActions.AddOrRemoveRoleFail(error)))
            );
        })
    );


    @Effect()
    CreateUserFailEffect$ = this.actions$.pipe(
        ofType(
            UsersActions.CREATE_USER_FAIL,
            UsersActions.ADD_OR_REMOVE_ROLE_FAIL

            ),
        mergeMap((response: any) => {
            const msg = response.payload.error
                ? response.payload.error
                : 'Oops something went wrong, try again.';
            this.toastr.error('', msg);
            return [];
        })
    );

    @Effect()
    RemoveUserEffect$ = this.actions$.pipe(
        ofType(UsersActions.REMOVE_USER),
        switchMap(action => {
            const { payload } = action as any;
            const { userId } = payload;
            return this.organisationService.removeUser({ userId }).pipe(
                map(response => new UsersActions.RemoveUserSuccess(response)),
                catchError(error => of(new UsersActions.RemoveUserFail(error)))
            );
        })
    );

    @Effect()
    ReloadUsers$ = this.actions$.pipe(
        ofType(
            UsersActions.CREATE_USER_SUCCESS, 
            UsersActions.REMOVE_USER_SUCCESS, 
            UsersActions.ADD_OR_REMOVE_ROLE_SUCCESS
            ),
            switchMap(response => {
            const { payload } = response as any;
            return [new UsersActions.LoadUsers(payload.teamId)];
        })
    );
}
