import { Router } from '@angular/router';

import { SearchBoxMatch } from '../models';

const cn = 'navigateToPage()';

/**
 *  Navigate to the page based on the result and return true, else if failed return false.
 *
 *  @param {SearchBoxMatch} searchBoxMatch
 *  @param {Router} router
 *  @returns {boolean}
 *
 */
export const navigateToPage = (searchBoxMatch: SearchBoxMatch, router: Router): boolean => {
    let result = true;
    let fragment;
    let queryParams;
    if (searchBoxMatch) {
        switch (searchBoxMatch.type) {
            // Go to the search page.
            case 'go to':
                router.navigate(['/search']);
                break;
            case 'protocol':
                if(searchBoxMatch.version) {
                    queryParams = {version: searchBoxMatch.version};
                    router.navigate(['/protocols', searchBoxMatch.protocolId], {queryParams});
                    }
                else {
                    router.navigate(['/protocols', searchBoxMatch.protocolId]);
                }
                break;
            case 'article':
                router.navigate(['/protocols', searchBoxMatch.protocolId, 'literatures', searchBoxMatch.articleId]);
                break
            case 'outcome':
            case 'paragraph':
            case 'in this protocol':
                fragment = searchBoxMatch.anchorId;
                if(searchBoxMatch.version) {
                    queryParams = {version: searchBoxMatch.version}
                }
                else {
                    queryParams = searchBoxMatch.type === 'outcome' ? { showAllOutcomes: true } : {};
                }
                router.navigate(['/protocols', searchBoxMatch.protocolId], { fragment, queryParams });
                break;
            case 'file':
                queryParams = { fileId: searchBoxMatch.fileId, ppdId: searchBoxMatch.ppdId };
                router.navigate(['/protocols', searchBoxMatch.protocolId], { queryParams });
                break;
            case 'literature':
                router.navigate(['/protocols', searchBoxMatch.protocolId, 'literatures']);
                break;
            case 'category':
                result = false;
                console.warn(`${cn} type='${searchBoxMatch.type}' => ignore`);
                break;
            default:
                result = false;
                console.warn(`${cn} unknown type='${searchBoxMatch.type}' => ignore`);
        }
    } else {
        console.warn(`${cn} searchBoxMatch is undefined => ignore'`);
        result = false;
    }
    return result;
};
