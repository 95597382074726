import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { environment } from '@environments/environment';

import { NavigationTab } from '@alii-web/models/navigation.interface';

@Injectable()
export class NavigationService {
    private _tabsChanged$: BehaviorSubject<NavigationTab[]> = new BehaviorSubject([]);
    public readonly tabsChanged$: Observable<NavigationTab[]> = this._tabsChanged$.asObservable();

    constructor(private http: HttpClient) {}

    getTabs(): Observable<NavigationTab[]> {
        return this.http.get<any>(`${environment.baseUrl}/api/protocol/user/getUser.vm?app=web`).pipe(
            map(response => (response ? response.tabs || [] : [])),
            tap(tabs => this.setTabs(tabs)),
            catchError((error: any) => throwError(error))
        );
    }

    setTabs(_tabs: NavigationTab[] = []): void {
        // Change '/dashboard' to '/home' (starting page)
        const tabs = this._sortTabs([..._tabs]).map(tab => ({
            ...tab,
            url: tab.url === '/dashboard' ? '/home' : tab.url,
            title: this._convertTabTitle(tab.title)
        }));
        this._tabsChanged$.next(tabs);
    }

    // Sort tabs by index
    private _sortTabs(tabs: NavigationTab[] = []): NavigationTab[] {
        return tabs.sort((a, b) => (a.index > b.index ? 1 : b.index < a.index ? -1 : 0));
    }

    // Convert tab title to translation key.
    private _convertTabTitle(title: string) {
        const convert = {
            HOME: 'HOME',
            START: 'HOME',
            TEAM: 'TEAM',
            MESSAGES: 'MESSAGES',
            BERICHTEN: 'MESSAGES',
            AGENDA: 'AGENDA',
            LIBRARY: 'LIBRARY',
            BIBLIOTHEEK: 'LIBRARY'
        }[title.toUpperCase()];

        return convert ? `NAVIGATION.${convert}` : title;
    }
}
