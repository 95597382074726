import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { OrganisationService } from 'apps/web/src/app/services/organisation.service';
import { Observable } from 'rxjs';
import { User } from 'apps/web/src/app/models/user.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as fromStore from './../../store';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadImageModalComponent } from '../../entry-components/upload-image/upload-image.component'

@Component({
    selector: 'whoiswho',
    templateUrl: './whoiswho.component.html',
    styleUrls: ['./whoiswho.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhoIsWhoComponent implements OnInit {
    users$: Observable<User[]>;
    organization$: any;
    organization: any;
    team$: Observable<any>;
    currentTeam: string;
    teamForm: FormGroup;
    orgForm: FormGroup;
    editOrgView: boolean;
    editTeamView: string;
    
    @Output() submit: EventEmitter<User> = new EventEmitter<User>();

    constructor(
        private service: OrganisationService,
        private permissionsService: NgxPermissionsService,
        private store: Store<fromStore.OrganisationsFeatureState>,
        private fb: FormBuilder, 
        private modalService: NgbModal, 
        private ref: ChangeDetectorRef
    ) {}

    ngOnInit() {
        // this.users$ = this.service.getUsers();
        // this.team$ = this.service.getTeam();
        // this.team$.subscribe(team => {
        //     if (team) {
        //         this.currentTeam = team.id;
        //     }
        // });

        this.organization$ = this.store.select(fromStore.getOrganization);
        
        this.store.dispatch(new fromStore.LoadOrganization());
        this.organization$.subscribe(organization => {
            if (organization) {
                this.organization = organization   
                if(this.organization.canEditDetails) {
                    let roles = ['Admin', 'SuperAdmin']
                    this.permissionsService.addPermission(roles);
                } else if (this.organization.canEditUsers) {
                    let roles = ['Admin']
                    this.permissionsService.addPermission(roles);
                }

            }
        });
    }

    public startEdit(team) {
            this.teamForm = this.fb.group({
            id: [team.id],
            name: [team.name],
            status: [team.status],
            language: [team.language],
            accountType: [team.accountType],
            description: [team.description],
            imageUrl: [team.imageUrl]
        });
        this.editTeamView = '' + team.id
    }

    public stopEditTeam() {
        this.editTeamView = ''
    }

    public startEditOrg() {
        this.orgForm = this.fb.group({
            id: [this.organization.id],
            name: [this.organization.name],
            ssoUrl: [this.organization.ssoUrl],
            ssoName: [this.organization.ssoName],
            imageUrl: [this.organization.logo]
        });
        this.editOrgView = true
    }

    public stopEditOrg() {
        this.editOrgView = false
    }

    public editTeamOpen(team) {
        if ('' + team.id ===  this.editTeamView ) {return true}
        return false
    }

    handleSubmitTeamForm() {
        if (this.teamForm.valid) {
            this.store.dispatch(new fromStore.UpdateTeam(this.teamForm.value));
            this.editTeamView = ''
            location.reload();
        }
    }

    handleSubmitOrgForm() {
        if (this.orgForm.valid) {
            this.store.dispatch(new fromStore.UpdateOrganization(this.orgForm.value));
            this.editOrgView = false
            location.reload();
        }
    }

    onClickAddImage(entity, type) {
        const modalRef = this.modalService.open(UploadImageModalComponent, { size: 'lg' });
        modalRef.componentInstance.entity = entity
        modalRef.componentInstance.type = type
        modalRef.result.then(
            result => {
                entity.imageUrl = result.url;
                setTimeout(() => {
                    this.ref.markForCheck();
                }, 1000);
            },
            () => {}
        );
    }


    // onSearch(query) {
    //     this.users$ = this.service.getUsers(query, this.currentTeam);
    // }

    getUserFunctionTitle(user) {
        const position = user.userCard.meta.find(m => m.field === 'position');
        return position ? position.value : '';
    }

    getUserAvatar(user) {
        return user.image_url ? user.image_url : '/assets/images/no_avatar.png';
    }

    trackByFn = (index, item) => item.id || index;
}
