import {
    Component,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    ChangeDetectionStrategy,
    ElementRef,
    AfterViewInit
} from '@angular/core';

import * as flowchartLib from 'flowchart.js';

import { ThemingService } from '../../../../../services/theming.service';
import { getFlowchartOptions } from './flowchart-options';
import { initSvgPanZoom } from '../../libs';
import { collectExternalReferences, ThrowStmt } from '@angular/compiler';

const cn = 'FlowChartAreaComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-flowchart-area',
    templateUrl: './flowchart-area.component.html',
    styleUrls: ['./flowchart-area.component.scss']
})
export class FlowchartAreaComponent implements OnChanges, AfterViewInit {
    @Input()
    scheme: any;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('canvas')
    canvas: any;

    @Input()
    size: string;

    @Input()
    flowchart: any;

    private chart: any;

    constructor(private hostElement: ElementRef, private themingService: ThemingService) {}

    public ngOnChanges(changes: SimpleChanges) {
        const {
            scheme: { currentValue: scheme },
            flowchart: { currentValue: flowchart }
        } = changes;

        this._renderFlowchart(scheme, flowchart);
    }

    ngAfterViewInit() {
        if (this.size) {
            document.getElementById('canvas').classList.add(this.size)
        }
        this._renderFlowchart(this.scheme, this.flowchart);
    }

    private _initAnchors() {
        const interactive = this.canvas.nativeElement.querySelectorAll('a');
        if (interactive) {
            Array.prototype.forEach.call(interactive, element => {
                element.addEventListener('click', event => {
                    event.preventDefault();
                    const paragraphId = element.href.baseVal.replace('#heading-', '');
                    const action = { type: 'handleOpentEdit', payload: { paragraphId } };
                    this.eventBus.emit(action);
                });
            });
        }
    }

    private _renderFlowchart(scheme: string, flowchart) {
        let palette = this.themingService.getCurrentPalette()
        let primaryColor = ""
        let secondaryColor = ""
        palette.forEach(color => {
            if (color.name == '--primary-color') { primaryColor = color.hex }
            if (color.name == '--secondary-color') { secondaryColor = color.hex}
        })
        if (scheme && this.canvas && this.canvas.nativeElement) {
            const flowchartOptions = getFlowchartOptions(flowchart, primaryColor, secondaryColor);
            this.chart = flowchartLib.parse(scheme);
            this.chart.drawSVG(this.canvas.nativeElement, flowchartOptions);
            this._initAnchors();
            initSvgPanZoom(cn, this.hostElement, true);
        }
    }
}
