import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromSearch from '../reducers/search.reducer';

export const getSearchState = createSelector(
    fromFeature.getSearchFeatureState,
    (state: fromFeature.SearchFeatureState) => {
        return state.search;
    }
);

export const getSearchGetPayload = createSelector(getSearchState, fromSearch.getSearchGetPayload);
export const getSearchGetQuery = createSelector(getSearchState, fromSearch.getSearchGetQuery);
export const getSearchGetLoading = createSelector(getSearchState, fromSearch.getSearchGetLoading);
export const getSearchGetSuccess = createSelector(getSearchState, fromSearch.getSearchGetSuccess);
export const getSearchGetFail = createSelector(getSearchState, fromSearch.getSearchGetError);
export const getSearchGetSearchPage = createSelector(getSearchState, fromSearch.getSearchGetSearchPage);
