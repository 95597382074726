<a *ngIf="paragraph.new" class="tag -primary mr-2">{{'PARAGRAPH.EDIT.NEW' | translate}}</a>
<a *ngIf="paragraph.update && hasReallyChanged" (click)="openCompareParagraphsModal()"
   class="tag -secondary cursor-pointer mr-2"
   title="Compare with previous version">{{'PARAGRAPH.EDIT.UPDATED' | translate}}</a>
<a *ngIf="paragraph.modelUpdate && hasReallyChanged" (click)="openCompareParagraphsModal()"
   class="tag -primary cursor-pointer mr-2"
   title="Compare with previous version">{{'PARAGRAPH.EDIT.UPDATED' | translate}}</a>
<a *ngIf="paragraph.modelUpdate" (click)="openUpdatedModelModal()"
   class="tag -secondary cursor-pointer mr-2"
   title="Compare with previous version">{{'PARAGRAPH.EDIT.COMPAREMODEL' | translate}}</a>
<a *ngIf="paragraph.updatedComparedToPreviousDraft && hasReallyChangedComparedToDraft" (click)="openCompareWithPreviousDraftParagraphsModal()"
   class="tag -secondary cursor-pointer mr-2"
   title="Compare with previous version">{{'PARAGRAPH.EDIT.RECENTLYUPDATED' | translate}}</a>
