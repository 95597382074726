import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Protocol } from '../../../../../models/protocol.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'detail-form-settings',
    template: `
        <div class="modal-header">
            <h4 class="modal-title">Edit search term</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="text-align: left;">

                <p></p>

                <div id="formTitle" class="cx_form">
                    <form [formGroup]="myForm">
                        <div class="form-text message">
                            Please enter the new search term: <br />
                            <textarea formControlName="searchTerm" class="form-control" cols="40" rows="1"></textarea>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" (click)="handleSubmit()">Submit</button>
            </div>
        </div>
    `
})
export class FormSearchTermComponent implements OnInit {
    @Input() protocol: Protocol;

    @Output() submit: EventEmitter<Protocol> = new EventEmitter<Protocol>();
    myForm: FormGroup;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}
    ngOnInit() {
        this.createForm();
    }
    private createForm() {
        this.myForm = this.fb.group({
            searchTerm: [this.protocol.search_term]
        });
    }
    handleSubmit() {
        this.activeModal.close(this.myForm.value);
    }
}
