import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnChanges,
    Output,
    EventEmitter,
    SimpleChanges,
    ViewChild,
    ChangeDetectorRef,
    OnInit
} from '@angular/core';

import {  Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {AgGridAngular} from 'ag-grid-angular';
import { SearchArticlesModalComponent, AddNonPubmedArticleModalComponent } from '../../entry-components';
import { UploadFileModalComponent } from '../../entry-components/upload-file/upload-file.component';

import { Literatures, Paragraph } from '../../../../models/literature-collection.interface';
import {UserService} from "@alii-web/services";
import { ModalTriggerGptAbstractComponent } from '../literatures-detail/trigger-gpt/modal-trigger-gpt-abstract';
import { ModalTriggerGptFullTextComponent } from '../literatures-detail/trigger-gpt/modal-trigger-gpt-full-text';
import { AuthService } from 'apps/web/src/app/services';
import { environment } from '../../../../../environments/environment';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'literatures',
    templateUrl: './literatures.component.html',
    styleUrls: ['./literatures.component.scss']
})
export class LiteraturesComponent implements OnChanges, OnInit {
    @Input() protocolId: string;
    @Input() versions: [];
    @Input() protocolDetails: any;
    @Input() literatures: Literatures;

    @Input() paragraphs: Paragraph[];
    @Input() added: string[];
    @Input() pubmeds: any;
    @Input() loading: boolean;
    @Input() uploadEvent: any;

    @Output()


    eventBus: EventEmitter<any> = new EventEmitter<any>();
    searchQuery: string;
    constructor(
        private modalService: NgbModal,
        private userService: UserService,
        private authService: AuthService,
        private router: Router) {
       
        }

    @ViewChild('agGrid') agGrid: AgGridAngular;

    ngOnInit() {
        const action = {
            type: 'handleRefreshLiterature',
            payload: {
                protocolId: this.protocolId
            }
        };
        this.eventBus.emit(action)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pubmeds && !changes.pubmeds.firstChange) {
            if (!changes.pubmeds.currentValue.loading && changes.pubmeds.currentValue.loaded) {
                setTimeout(() => {
                    this.handleOpenSearch();
                });
            }
        }
    }

    isAliiAdmin() : boolean {
        return this.userService.isAliiAdmin();
    }

    private openReviewQuestionsEditor() {
        let authHash = this.authService.getAuthHash();
        let url = environment.blazorUrl + "adminpages/reviewquestions/" + this.protocolId + "?token=" + authHash;
        window.open(url, "_blank");
    }

    getProtocolLink(versions) {
        let params = []
        if(versions && versions.length) {
        versions.forEach(version => {
           if(version.status == "Current") {
                params.push({'version': 'Current'})
           }
           if(version.status == "Draft") {
                params.push({'version': 'Draft'})
            }
        });
        }
        if(!params.length) {params.push(({'version': 'Current'}))}
        return params
    }

    onCellClicked(params) {
        var data =  params.data
        var literatureId = data.id
        this.router.navigate(['/protocols/', this.protocolId, 'literatures', literatureId]);

        const event = {
            type: 'handleViewLiterature',
            payload: {
                protocolId: this.protocolId,
                literatureId
            }
        };
        this.eventBus.emit(event);
    }

    getLiteraturesKeys() {
        return Object.keys(this.literatures);
    }

    scrollToSection(id) {
        var el = document.getElementById(id)
        setTimeout(() => el.scrollIntoView({ behavior: 'smooth' }), 100);
    }


    isActionsHasAddNew(actions) {
        const hasAddNeww = actions.filter(a => a.title === 'addNew');
        return hasAddNeww.length > 0;
    }

    handleViewLiterature(literatureId) {
        const event = {
            type: 'handleViewLiterature',
            payload: {
                protocolId: this.protocolId,
                literatureId
            }
        };
        this.eventBus.emit(event);
    }

    onClickAddArticle() {
        this.handleClearPubmedSearch();
        this.handleOpenSearch(true);
    }

    onClickTestRandomArticles(){
        const emitPayload = {
            type: 'testRandomNewArticles',
            payload: {
                protocolId: this.protocolId
            }
        };
        this.eventBus.emit(emitPayload);
    }

    onClickTestFullTextRandomArticles() {
        const modalRef = this.modalService.open(ModalTriggerGptFullTextComponent, { size: 'lg' });
        modalRef.componentInstance.eventBus.subscribe(event => {
            const { type } = event;
            switch (type) {
                case 'askGptToVoteOnInclusion':
                    const gptEvent = {
                        type: 'testRandomFullTextArticles',
                        payload: { 
                            protocolId: this.protocolId,
                        }
                    };
                    this.eventBus.emit(gptEvent);
                    this.modalService.dismissAll();
                    break;
                default:
                    break;
            }
        });
        modalRef.componentInstance.title = "Trigger Random Full Text Tests?";
    }

    onClickAddRisArticle() {
        const modalRef = this.modalService.open(UploadFileModalComponent, { size: 'lg' });

        modalRef.componentInstance.eventBus.subscribe(eventAction => {
            if (eventAction.type === "handleUploadFile") {
                var emitPayload = {
                    ...eventAction,
                    type: "handleUploadRisFile",
                    payload: {
                        ...eventAction.payload,
                        protocolId: this.protocolId
                    }
                };
                this.eventBus.emit(emitPayload);
                modalRef.close();
            }
        });
        modalRef.result.then(
            () => {}, // on close with result
            () => {} // on dismiss with reason
        );
    }

    onClickAddNonPubmedArticle() {
        this.modalService.dismissAll();

        const modalRef = this.modalService.open(AddNonPubmedArticleModalComponent, { size: 'lg' });
        modalRef.componentInstance.eventBus.subscribe(event => {
            const { type, payload } = event;
            switch (type) {
                case 'handleAddNonPubmedArticle':
                    const literatureEvent = {
                        type: 'handleAddNonPubmedArticle',
                        payload: { ...payload, protocolId: this.protocolId }
                    };
                    this.eventBus.emit(literatureEvent);
                    this.modalService.dismissAll();
                    break;
                default:
                    break;
            }
        });
    }

    handleOpenSearch(clearResult = false) {
        this.modalService.dismissAll();

        const modalRef = this.modalService.open(SearchArticlesModalComponent, { size: 'lg' });
        if (clearResult) {
            modalRef.componentInstance.pubmeds = [];
            modalRef.componentInstance.query = '';
        } else {
            modalRef.componentInstance.pubmeds = this.pubmeds && this.pubmeds.results ? this.pubmeds.results : [];
            modalRef.componentInstance.query =
                this.pubmeds && this.pubmeds.query ? this.pubmeds.query : this.searchQuery;
        }
        modalRef.componentInstance.searchLoading = false;

        modalRef.componentInstance.eventBus.subscribe(event => {
            const { type, payload } = event;
            switch (type) {
                case 'handleSubmitSearch':
                    this.handleSubmitSearch(payload);
                    break;
                case 'handleClearPubmedSearch':
                    this.handleClearPubmedSearch();
                    break;
                case 'handleAddArticles':
                    this.handleAddArticles(payload);
                    break;
                default:
                    break;
            }
        });
        modalRef.result.then(
            values => {},
            reason => {}
        );
    }

    defaultColDef: any = {
        resizable: true,
        cellStyle: {
          display: 'flex',
          'minWidth': '100px',
          'align-items': 'center'
        }
      };

    handleSubmitSearch(payload) {
        const { query } = payload;
        const event = { type: 'handleSubmitSearch', payload: { query } };
        this.eventBus.emit(event);
        this.searchQuery = query;
    }
    handleClearPubmedSearch() {
        const event = { type: 'handleClearPubmedSearch', payload: {} };
        this.eventBus.emit(event);
        this.searchQuery = '';
    }
    handleAddArticles(payload) {
        const { uids } = payload;
        const event = {
            type: 'handleAddArticles',
            payload: {
                articleIds: uids
            }
        };
        this.eventBus.emit(event);
        this.modalService.dismissAll();
    }

    voteOnAllOnAbstract() {
        const modalRef = this.modalService.open(ModalTriggerGptAbstractComponent, { size: 'lg' });
        modalRef.componentInstance.eventBus.subscribe(event => {
            const { type } = event;
            switch (type) {
                case 'askGptToVoteOnInclusion':     
                    const voteOnAllAbstractEvent = {
                        type: 'gptVoteOnAllAbstracts',
                        payload: { protocolId: this.protocolId }
                    };
                    this.eventBus.emit(voteOnAllAbstractEvent);
                    this.modalService.dismissAll();
                    break;
                default:
                    break;
            }
        });
        modalRef.componentInstance.title = "Ask GPT to vote on all abstracts that are able to be voted on?";
        modalRef.componentInstance.modalContent = "This means that if GPT has already cast a vote, it will recast it based in the current inclusion criteria";
    }

    voteOnAllOnFullText() {
        const modalRef = this.modalService.open(ModalTriggerGptFullTextComponent, { size: 'lg' });
        modalRef.componentInstance.eventBus.subscribe(event => {
            const { type } = event;
            switch (type) {
                case 'askGptToVoteOnInclusion':
                    const gptEvent = {
                        type: 'gptVoteOnAllFullTexts',
                        payload: { 
                            protocolId: this.protocolId,
                        }
                    };
                    this.eventBus.emit(gptEvent);
                    this.modalService.dismissAll();
                    break;
                default:
                    break;
            }
        });
        modalRef.componentInstance.title = "Ask GPT to vote on all Full Texts that are able to be voted on?";
        modalRef.componentInstance.modalContent = "This means that if GPT has already cast a vote, it will recast it based in the current inclusion criteria";
    }

    trackByFn = (index, item) => item.id || index;
}
