import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import * as dashboardTagActions from '../actions/dashboard-tag.action';

import * as fromService from '../../../../services/dashboard.service';

@Injectable()
export class DashboardTagEffects {
    constructor(private actions$: Actions, private dashboardService: fromService.DashboardService) {}

    @Effect()
    updateDashboardTag$ = this.actions$.pipe(
        ofType(dashboardTagActions.DashboardTagActionTypes.UpdateDashboardTag),
        switchMap(action => {
            const {
                payload: { dashboardTag }
            } = action as any;
            const data = { tagId: dashboardTag.id, title: dashboardTag.title };
            return this.dashboardService
                .editCategory(data)
                .pipe(map(() => new dashboardTagActions.UpdateDashboardTagSuccess({ dashboardTag })));
        })
    );

    @Effect()
    sortDashboardTag$ = this.actions$.pipe(
        ofType(dashboardTagActions.DashboardTagActionTypes.SortDashboardTag),
        switchMap(action => {
            const {
                payload: { dashboardTag }
            } = action as any;
            const data = {
                tagId: dashboardTag.tagId,
                position: dashboardTag.position,
                dashboardId: dashboardTag.dashboardId
            };
            return this.dashboardService
                .sortCategory(data)
                .pipe(map(() => new dashboardTagActions.SortDashboardTagSuccess({ dashboardTag })));
        })
    );

    @Effect()
    removeDashboardTag$ = this.actions$.pipe(
        ofType(dashboardTagActions.DashboardTagActionTypes.DeleteDashboardTag),
        switchMap(action => {
            const {
                payload: { id }
            } = action as any;
            return this.dashboardService
                .removeCategory(id)
                .pipe(map(() => new dashboardTagActions.DeleteDashboardTagSuccess()));
        })
    );
}
