import {
    Component,
    Input,
    ChangeDetectionStrategy,
    EventEmitter,
    OnInit,
    Output,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { StartingPageActionDashboard } from '@alii-web/modules/starting-page/models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-dashboard-select',
    templateUrl: 'dashboard-select.component.html',
    styleUrls: ['./dashboard-select.component.scss']
})
export class DashboardSelectComponent implements OnInit, OnChanges {
    @Input() dashboards: StartingPageActionDashboard[] = [];
    @Input() dashboardId: string;
    @Output() handleSelectDashoard = new EventEmitter<string>();

    filteredDashboards: StartingPageActionDashboard[] = [];

    constructor() {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        const { dashboardId, dashboards } = changes;
        // Update the list of dashboards so that it excludes the current dashboard.
        if ((dashboards && dashboards.currentValue) || (dashboardId && dashboardId.currentValue)) {
            const _dashboards = dashboards && dashboards.currentValue ? dashboards.currentValue : this.dashboards;
            const _dashboardId = dashboardId && dashboardId.currentValue ? dashboardId.currentValue : this.dashboardId;
            if (_dashboardId) {
                // Sort dashboards by name.
                this.filteredDashboards = _dashboards
                    .filter(d => d.id !== _dashboardId.toString())
                    .sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));
            }
        }
    }

    clickSelection(dashboardId: string) {
        this.handleSelectDashoard.emit(dashboardId);
    }

    trackByFn = (index, item) => item.id || index;
}
