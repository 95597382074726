import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'asHtml'
})
export class AsHtmlPipe implements PipeTransform {
    constructor() {}

    transform(input: string): any {
        if (input) {
            return new DOMParser().parseFromString(input, 'text/html').documentElement.textContent;
        } else {
            return '';
        }
    }
}
