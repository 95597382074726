<alii-web-spinner-loading *ngIf="loading$ | async"></alii-web-spinner-loading>
<ng-container *ngIf="(templateSet$ | async) as templateSet">
    <alii-web-shop-page-section
        [messageTitle]="templateSet.title"
        [messageBody]="templateSet.text"
        [ownerName]="templateSet.owner"
        [ownerText]="templateSet.owner_text"
        [ownerImageUrl]="templateSet.owner_image_url"
    >
        <div *ngIf="templateSet.usedBy" class="row">
            <div class="col">
                <p class="message-body">{{templateSet.usedBy}}</p>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <button *ngIf="templateSet.actions && templateSet.actions[0]" type="button" class="button -primary"
                        (click)="submit(templateSet)">
                    {{ templateSet.actions[0].label | titlecase}}
                </button>
            </div>
        </div>
    </alii-web-shop-page-section>
    <div class="dashboard">
        <div class="dashboard__title">Protocollen</div>
    </div>
    <section class="page__section -no-bottom">
        <div class="table-responsive">
            <table class="table -aliiTable">
                <tbody>
                <tr *ngFor="let protocol of templateSet.protocols; trackBy: trackByFn">
                    <td class="table__title table__text table__column-30">
                        <a [routerLink]="['/protocols', protocol.id]">
                            {{protocol.title}}</a>
                    </td>
                    <td class="table__text table__column-30"><span
                        *ngIf="protocol.owner">{{ protocol.owner }}</span>
                    </td>
                    <td class="table__actions table__column-40 float-right">
                        <a class="tag -primary" [routerLink]="['/protocols', protocol.id]">
                            Preview
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </section>
</ng-container>
