import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { OutcomeTableModalComponent, OutcomeTableModalData } from '../../../../../entry-components';
import { Outcome } from '../../../../../../../models/outcome.interface';
import * as fromStore from '../../../../../../search/store';

const cn = 'ModelDetailOutcomeComponent';
const SEL_PLACEHOLDER = 'mdo-table-placeholder';
const MAX_COLS = 2;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-model-detail-outcome',
    templateUrl: './model-detail-outcome.component.html',
    styleUrls: ['./model-detail-outcome.component.scss']
})
export class ModelDetailOutcomeComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() outcome: Outcome;
    @Input() placeholders: boolean;

    innerHtml: SafeHtml;

    listeners: Array<() => void> = [];

    subscriptions: Subscription[] = [];

    constructor(
        private modalService: NgbModal,
        private renderer: Renderer2,
        private sanitizer: DomSanitizer,
        private hostElement: ElementRef,
        private store: Store<fromStore.SearchFeatureState>
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.hostElement.nativeElement
            .querySelectorAll('.' + SEL_PLACEHOLDER)
            .forEach(el => this.listeners.push(this.renderer.listen(el, 'click', e => this._clickListener(e))));
    }

    ngOnChanges(changes: SimpleChanges) {
        const { outcome } = changes;
        if (outcome && outcome.currentValue) {
            this._insertTablePlaceholders(this.outcome.text);

            // If a search has been done and points to text in this paragraph, highlight all instances of the search
            // query string present in the paragraph.
            this.store
                .select(fromStore.getSearchGetPayload)
                .pipe(take(1))
                .subscribe(searchDTO => {
                    if (searchDTO && searchDTO.anchorId && this.outcome.anchorId === searchDTO.anchorId.toString()) {
                        const regex = new RegExp(searchDTO.q, 'gi');
                        const match = this.outcome.text.match(regex);
                        if (match) {
                            const span = `<span style="background-color: yellow">${match[0]}</span>`;
                            this._insertTablePlaceholders(this.outcome.text.replace(regex, span));
                        }
                    }
                });

            setTimeout(() => {
                this._cleanupListeners();
                this.hostElement.nativeElement.querySelectorAll('.' + SEL_PLACEHOLDER).forEach(el => {
                    this.listeners.push(this.renderer.listen(el, 'click', e => this._clickListener(e)));
                });
            }, 1000);
        }
    }

    ngOnDestroy() {
        this._cleanupListeners();
    }

    private _insertTablePlaceholders(text: string) {
        this._cleanupListeners();
        const div = document.createElement('div');
        div.innerHTML = text;
        // Table icons are used if placeholders true and large tables (more than max columns ).
        if (this.placeholders) {
            const tables = div.getElementsByTagName('table');
            if (tables.length) {
                for (let i = 0; i < tables.length; i++) {
                    const table = tables[i];
                    if (this._getColumns(table) > MAX_COLS) {
                        const id = Math.floor(Math.random() * 10000000000).toString();
                        const p = document.createElement('p');
                        p.innerHTML = `<span class="icon-table ${SEL_PLACEHOLDER}" data-id="${id}"></span>`;
                        this.renderer.setAttribute(p, 'title', 'Display table contents');
                        this.renderer.setStyle(p, 'fontSize', '24px');
                        this.renderer.insertBefore(table.parentElement, p, table);
                        this.renderer.setAttribute(table, 'id', id);
                        this.renderer.setStyle(table, 'display', 'none');
                    }
                }
            }
        }
        this.innerHtml = this.sanitizer.bypassSecurityTrustHtml(div.innerHTML);
    }

    private _clickListener(event: Event): boolean {
        event.stopPropagation();
        const id = (event.target as HTMLElement).getAttribute('data-id');
        const table = document.getElementById(id) as HTMLTableElement;
        if (table) {
            const data: OutcomeTableModalData = {
                title: 'Table',
                table: table.cloneNode(true) as HTMLTableElement
            };
            this.modalService.dismissAll();
            const modalRef = this.modalService.open(OutcomeTableModalComponent, {
                windowClass: 'outcome-table-modal'
            });
            modalRef.componentInstance.data = data;
            modalRef.result.then(
                () => {},
                () => {}
            );
        } else {
            console.warn(`${cn} _clickListener() id='${id}' table is undefined`);
        }
        return false;
    }

    private _getColumns(table: HTMLTableElement): number {
        let n = 0;
        if (table) {
            const header = table.querySelector('tr:first-child');
            if (header) {
                n = (header.children || []).length;
            }
        }
        return n;
    }

    private _cleanupListeners(): void {
        if (this.listeners && this.listeners.length) {
            this.listeners.forEach(listener => listener());
            this.listeners = [];
        }
    }
}
