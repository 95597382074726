import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as TermsActions from '../actions/terms.action';
import * as fromTerms from '../../../../services/terms.service';

@Injectable()
export class TermsEffects {
    constructor(private actions$: Actions, private termsService: fromTerms.TermsService) {}

    @Effect()
    TermsGetEffect$ = this.actions$.pipe(
        ofType(TermsActions.TERMS_GET),
        switchMap(() => {
            return this.termsService.get().pipe(
                map(response => {
                    return new TermsActions.TermsGetSucces(response);
                }),
                catchError(() => {
                    const message = 'Get terms failed';
                    return of(new TermsActions.TermsGetFail({ message }));
                })
            );
        })
    );

    @Effect()
    TermsAcceptEffect$ = this.actions$.pipe(
        ofType(TermsActions.TERMS_ACCEPT),
        switchMap(() => {
            return this.termsService.accept().pipe(
                map(() => {
                    return new TermsActions.TermsAcceptSucces();
                }),
                catchError(() => {
                    const message = 'Accept terms failed';
                    return of(new TermsActions.TermsAcceptFail({ message }));
                })
            );
        })
    );
}
