import { Action } from '@ngrx/store';
import { Pubmed } from '../../../../models/pubmed.interface';

export enum PubmedActionTypes {
    LoadPubmeds = '[Protocol] Load Pubmeds',
    LoadPubmedsSucces = '[Protocol] Load Pubmeds Success',
    LoadPubmedsFail = '[Protocol] Load Pubmeds Fail',
    ClearPubmeds = '[Protocol] Clear Pubmeds',
    SelectPubmed = '[Protocol] Select Pubmed'
}

export class LoadPubmeds implements Action {
    readonly type = PubmedActionTypes.LoadPubmeds;

    constructor(public payload: { parentId: string; ppdId: string; query: string, footnote: boolean }) {}
}

export class LoadPubmedsSucces implements Action {
    readonly type = PubmedActionTypes.LoadPubmedsSucces;

    constructor(public payload: { pubmeds: Pubmed[] }) {}
}

export class LoadPubmedsFail implements Action {
    readonly type = PubmedActionTypes.LoadPubmedsFail;

    constructor(public payload: any) {}
}

export class ClearPubmeds implements Action {
    readonly type = PubmedActionTypes.ClearPubmeds;
}

export class SelectPubmed implements Action {
    readonly type = PubmedActionTypes.SelectPubmed;
    constructor(public payload: { PubmedId: string | number }) {}
}

export type PubmedActions = LoadPubmeds | LoadPubmedsSucces | LoadPubmedsFail | ClearPubmeds | SelectPubmed;
