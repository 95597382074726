import { Action } from '@ngrx/store';

import { Protocol } from '../../../../models/protocol.interface';

export const LOAD_PROTOCOLS_EXISTING = '[Protocols] Load Protocol Existing ';
export const LOAD_PROTOCOLS_EXISTING_FAIL = '[Protocols] Load Protocol Existing  Fail';
export const LOAD_PROTOCOLS_EXISTING_SUCCESS = '[Protocols] Load Protocol Existing  Success';

export class LoadProtocolsExisting implements Action {
    readonly type = LOAD_PROTOCOLS_EXISTING;
}

export class LoadProtocolsExistingSucces implements Action {
    readonly type = LOAD_PROTOCOLS_EXISTING_SUCCESS;
    constructor(public payload: any[]) {}
}

export class LoadProtocolsExistingFail implements Action {
    readonly type = LOAD_PROTOCOLS_EXISTING_FAIL;
    constructor(public payload: any) {}
}

export type ProtocolExistingAction = LoadProtocolsExisting | LoadProtocolsExistingFail | LoadProtocolsExistingSucces;
