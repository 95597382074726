import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    OnInit,
    ChangeDetectorRef
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../../models/user.interface';
import { UploadAvatarModalComponent } from '../../entry-components/upload-avatar/upload-avatar.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-profile-form',
    templateUrl: './profile-form.component.html',
    styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent implements OnInit {
    @Input() profile: User;
    @Input() dashboards: any;

    @Output() submit: EventEmitter<User> = new EventEmitter<User>();

    profileForm: FormGroup;

    updatePasswordForm: FormGroup;
    metaAvailable = false;

    avatarUrl: string;

    constructor(private fb: FormBuilder, private modalService: NgbModal, private ref: ChangeDetectorRef) {}

    ngOnInit() {
        this.profileForm = this.fb.group({
            userId: [this.profile.id],
            telephone: [this.profile.userCard.contactCard.telephone],
            first_name: [this.profile.first_name, Validators.required],
            prefix: [this.profile.prefix],
            dashboard: [this.profile.dashboard, Validators.required],
            last_name: [this.profile.last_name, Validators.required],
            email: [this.profile.email, Validators.required]
        });

        if (this.profile.userCard.meta.length) {
            this.metaAvailable = true;
            this.profile.userCard.meta.forEach(meta => {
                if (meta.field === 'hospital') {
                    this.profileForm.addControl(meta.field, this.fb.control(meta.name));
                } else {
                    this.profileForm.addControl(meta.field, this.fb.control(meta.value));
                }
            });
        }

        this.updatePasswordForm = this.fb.group({
            password: [, Validators.required]
        });

        this.avatarUrl = this.profile.image_url;
    }

    handleUpdatePassword() {
        if (this.updatePasswordForm.valid) {
            this.submit.emit(this.updatePasswordForm.value);
        }
    }

    handleSubmit() {
        if (this.profileForm.valid) {
            this.submit.emit(this.profileForm.value);
        }
    }

    onClickAddAvatar() {
        const modalRef = this.modalService.open(UploadAvatarModalComponent, { size: 'lg' });
        modalRef.componentInstance.userId = this.profile.id;
        modalRef.result.then(
            result => {
                this.avatarUrl = result.url;
                setTimeout(() => {
                    this.ref.markForCheck();
                }, 1000);
            },
            () => {}
        );
    }

    trackByFn = (index, item) => item.id || index;
}
