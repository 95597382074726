import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Card } from 'apps/web/src/app/models/protocol.interface';

@Component({
  selector: 'dialogue-detail',
  styleUrls: ['./dialogue-detail.component.scss'],
  templateUrl: './dialogue-detail.component.html',
})

export class DialogueDetailComponent implements OnInit {
  @Input() cards: any[];
  @Input() paragraphs;
  cardsShown: Card[] = [];
  scrollPosition = 0;
  public activeCard = {
      current: null,
      previous: []
  };
  
  @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();
    
  @ViewChild('cardList') cardList: ElementRef;

  ngOnInit() {
    const indexOfStart = this.cards.findIndex(x => x.startOfFlowChart)
    this.activeCard.current = this.cards[indexOfStart];
    let card = this.activeCard.current
    this.cardsShown.push(card)
    
    do {
      const target = card.options[0].target
      card = this.cards[this.cards.findIndex((card) => card.id === target)]
      if(card) {
        this.cardsShown.push(card) 
      }
    } while (card && (card.options.length > 0 || !card.options[0].target));
  }

  scrollToNextCard(cardId) {
    var el = document.getElementById('card-' +  cardId)
    setTimeout(() => el.scrollIntoView({ behavior: 'smooth' }), 200);
  }

  hasOptions(card) {
    return card.options.length > 1;
  }

  isActive(targetId) {
    return this.cardsShown.findIndex((card) => card.id === targetId) > 0;
  }

  public addCard(targetId, options) {

    options.forEach(option => {
        let removeCard: number       
            removeCard = this.cardsShown.findIndex((card) => card.id === option.target); 
            if(removeCard > 0 ) {
                this.cardsShown.splice(removeCard)
            }
        }); 
    
        let targetCard = this.cards[this.cards.findIndex((card) => card.id === targetId)];
        this.cardsShown.push(targetCard)

        do {
          if (targetCard.options.length === 1) {
            targetCard = this.cards[this.cards.findIndex((card) => card.id === targetCard.options[0].target)];
            if (targetCard) {
              this.cardsShown.push(targetCard)
            } 
          } else {
            targetCard = undefined
          }
        } while (targetCard);
        

        setTimeout(() => this.scrollToNextCard(targetId), 100);
    }

    openParagraph(paragraphId) {       
            const action = { type: 'handleClickParagraphCard', payload: { paragraphId } };
            this.eventBus.emit(action);    
      }


}
