import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../../../../models/field.interface';
import { FieldConfig } from '../../../../../models/field-config.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'form-populationId',
    template: `
        <div class="dynamic-field form-select" [formGroup]="group">
            <label>{{ config.label }}</label>
            <div *ngIf="config.info" class="field-info">{{ config.info }}</div>

            <select [formControlName]="config.name" class="form-control">
                <option value="">-</option>
                <option *ngFor="let option of config.options" [value]="option.value"> {{ option.title }} </option>
            </select>
        </div>
    `
})
export class FormPopulationIdComponent implements Field {
    config: FieldConfig;
    group: FormGroup;
}
