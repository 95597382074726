import {
    Component,
    Input,
    OnInit,
    ViewChild,
    
} from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import * as fromService from '../../../../../../../services';
import {AgGridAngular} from 'ag-grid-angular';

@Component({
    selector: 'alii-web-sources-edit',
    templateUrl: './sources-edit.component.html'
})
export class SourcesEditComponent implements OnInit {

    constructor(
        public activeModal: NgbActiveModal,
        private http: HttpClient,
        private literaturesService: fromService.LiteraturesService) {}

    @Input() rowData: [];
    @Input() columnDefs: [];
    @Input() paragraphId: any;
    clonedData: [];
    clonedColumns: [];


    @ViewChild('agGrid') agGrid: AgGridAngular;

    ngOnInit() {
        this.clonedData = JSON.parse(JSON.stringify(this.rowData));
        this.clonedColumns = JSON.parse(JSON.stringify(this.columnDefs));
    }

    onCellEditingStopped(params) {

        var data =  params.data
        var ppdId = this.paragraphId
        var payload = { ppdId, data}
        this.literaturesService.updateFindings(payload).subscribe(data => {
        });

    }
          

}
