import { Action } from '@ngrx/store';

import { Literatures } from '../../../../models/literature-collection.interface';

export const LOAD_LITERATURES = '[Literatures] Load Literatures Collection';
export const LOAD_LITERATURES_FAIL = '[Literatures] Load Literatures Collection Fail';
export const LOAD_LITERATURES_SUCCESS = '[Literatures] Load Literatures Collection Success';
export const LOAD_LITERATURES_CONTINUE = '[Literatures] Load Literatures Collection Cancel';

export class LoadLiteraturesCollection implements Action {
    readonly type = LOAD_LITERATURES;
    constructor(public payload: { protocolId: string }) {}
}

export class LoadLiteraturesCollectionFail implements Action {
    readonly type = LOAD_LITERATURES_FAIL;
    constructor(public payload: any) {}
}

export class LoadLiteraturesCollectionSucces implements Action {
    readonly type = LOAD_LITERATURES_SUCCESS;
    constructor(public payload: Literatures) {}
}
export class LoadLiteraturesCollectionContinue implements Action {
    readonly type = LOAD_LITERATURES_CONTINUE;
    constructor(public payload: any) {}
}

export const GET_LITERATURE = '[Literatures] Get Literature';
export const GET_LITERATURE_SUCCESS = '[Literatures] Get Literature Success';
export const GET_LITERATURE_FAIL = '[Literatures] Get Literature Fail';

export class GetLiterature implements Action {
    readonly type = GET_LITERATURE;
    constructor(public payload: { protocolId: string; literatureId: string }) {}
}

export class GetLiteratureSucces implements Action {
    readonly type = GET_LITERATURE_SUCCESS;
    constructor(public payload: any) {}
}
export class GetLiteratureFail implements Action {
    readonly type = GET_LITERATURE_FAIL;
    constructor(public payload: any) {}
}

export const SELECT_LITERATURE = '[Literatures] Select Literature';
export class SelectLiterature implements Action {
    readonly type = SELECT_LITERATURE;
    constructor(public payload: { protocolId: string; literatureId: string }) {}
}

export const SUBMIT_LITERATURE_ACTION = '[Literatures] Update Literature';
export const SUBMIT_LITERATURE_ACTION_FAIL = '[Literatures] Update Literature Fail';
export const SUBMIT_LITERATURE_ACTION_SUCCESS = '[Literatures] Update Literature Success';

export class SubmitLiteratureAction implements Action {
    readonly type = SUBMIT_LITERATURE_ACTION;
    constructor(public payload: any) {}
}

export class SubmitLiteratureActionFail implements Action {
    readonly type = SUBMIT_LITERATURE_ACTION_FAIL;
    constructor(public payload: any) {}
}

export class SubmitLiteratureActionSucces implements Action {
    readonly type = SUBMIT_LITERATURE_ACTION_SUCCESS;
    constructor(public payload: any) {}
}

export const GPT_VOTE_ON_ABSTRACT_ACTION = '[Literatures] Vote On Abstract';
export class GptVoteOnAbstractAction implements Action {
    readonly type = GPT_VOTE_ON_ABSTRACT_ACTION;
    constructor(public payload: any) {}
}

export const GPT_VOTE_ON_FULL_TEXT_ACTION = '[Literatures] Vote On Full Text';
export class GptVoteOnFullTextAction implements Action {
    readonly type = GPT_VOTE_ON_FULL_TEXT_ACTION;
    constructor(public payload: any) {}
}

export const GPT_VOTE_ON_ALL_ABSTRACTS_ACTION = '[Literatures] Vote On All Abstracts';

export class GptVoteOnAllAbstractsAction implements Action {
    readonly type = GPT_VOTE_ON_ALL_ABSTRACTS_ACTION;
    constructor(public payload: any) {}
}

export const GPT_VOTE_ON_ALL_FULL_TEXT_ACTION = '[Literatures] Vote On All Full Texts';

export class GptVoteOnAllFullTextAction implements Action {
    readonly type = GPT_VOTE_ON_ALL_FULL_TEXT_ACTION;
    constructor(public payload: any) {}
}

export const ADD_ARTICLES = '[Literatures] Add multiple article';
export const ADD_ARTICLES_SUCCESS = '[Literatures] Add multiple article success';
export const ADD_ARTICLES_FAIL = '[Literatures] Add Articles Fail';

export class AddArticles implements Action {
    readonly type = ADD_ARTICLES;
    constructor(public payload: { protocolId: string; articleIds: string[] }) {}
}
export class AddArticlesSucces implements Action {
    readonly type = ADD_ARTICLES_SUCCESS;
    constructor(public payload: any) {}
}
export class AddArticlesFail implements Action {
    readonly type = ADD_ARTICLES_FAIL;
    constructor(public payload: any) {}
}

export const UPDATE_ADDED_ARTICLES = '[Literatures] Update Added multiple article';

export class UpdateAddedArticles implements Action {
    readonly type = UPDATE_ADDED_ARTICLES;
    constructor(public payload: { articleIds: string[] }) {}
}

export const LOAD_PARAGRAPHS = '[Literatures] Load Paragraphs';
export const LOAD_POPULATIONS = '[Literatures] Load Populations';

export class LoadParagraphs implements Action {
    readonly type = LOAD_PARAGRAPHS;
    constructor(public payload: any) {}
}

export class LoadPopulations implements Action {
    readonly type = LOAD_POPULATIONS;
    constructor(public payload: any) {}
}

export const REMOVE_REFERENCES_FROM_PARAGRAPH = '[Protocols] Remove references from paragraph';
export const REMOVE_REFERENCES_FROM_PARAGRAPH_SUCCESS = '[Protocols] Remove references from paragraph success';
export const REMOVE_REFERENCES_FROM_PARAGRAPH_FAIL = '[Protocols] Remove references from paragraph fail';

export class RemoveReferenceFromParagraph implements Action {
    readonly type = REMOVE_REFERENCES_FROM_PARAGRAPH;
    constructor(public payload: any) {}
}
export class RemoveReferenceFromParagraphSuccess implements Action {
    readonly type = REMOVE_REFERENCES_FROM_PARAGRAPH_SUCCESS;
    constructor(public payload: any) {}
}
export class RemoveReferenceFromParagraphFail implements Action {
    readonly type = REMOVE_REFERENCES_FROM_PARAGRAPH_FAIL;
    constructor(public payload: any) {}
}

export const TRIGGER_RANDOM_ARTICLES_GPT_TEST = '[Literatures] Trigger Random GPT Test';
export class TriggerRandomGPTTest implements Action {
    readonly type = TRIGGER_RANDOM_ARTICLES_GPT_TEST;
    constructor(public payload: {protocolId: string}) {}
}

export const TRIGGER_RANDOM_FULL_TEXT_ARTICLES_GPT_TEST = '[Literatures] Trigger Random GPT Full Test';
export class TriggerRandomGPTFullTextTest implements Action {
    readonly type = TRIGGER_RANDOM_FULL_TEXT_ARTICLES_GPT_TEST;
    constructor(public payload: any) {}
}

export const UPLOAD_RIS_FILE_TO_BUCKET = '[Literatures] Upload Ris file to bucket';
export const UPLOAD_RIS_FILE_TO_BUCKET_UPLOADING = '[Literatures] Upload Ris file to bucket uploading';
export const UPLOAD_RIS_FILE_TO_BUCKET_SUCCESS = '[Literatures] Upload Ris file to bucket success';
export const UPLOAD_RIS_FILE_TO_BUCKET_FAIL = '[Literatures] Upload Ris file to bucket fail';

export class UploadRisFileToBucket implements Action {
    readonly type = UPLOAD_RIS_FILE_TO_BUCKET;
    constructor(public payload: { formData: any; protocolId: string; articleId: string; title: string }) {}
}
export class UploadRisFileToBucketUploading implements Action {
    readonly type = UPLOAD_RIS_FILE_TO_BUCKET_UPLOADING;
    constructor(public payload: { articleId?: string; progress?: number }) {}
}
export class UploadRisFileToBucketSuccess implements Action {
    readonly type = UPLOAD_RIS_FILE_TO_BUCKET_SUCCESS;
    constructor(
        public payload: {
            status?: string;
            progress?: number;
            protocolId: string;
            articleId: string;
            url: string;
            title: string;
        }
    ) {}
}
export class UploadRisFileToBucketFail implements Action {
    readonly type = UPLOAD_RIS_FILE_TO_BUCKET_FAIL;
    constructor(public payload: any) {}
}

export const UPLOAD_FILE_TO_BUCKET = '[Literatures] Upload file to bucket';
export const UPLOAD_FILE_TO_BUCKET_UPLOADING = '[Literatures] Upload file to bucket uploading';
export const UPLOAD_FILE_TO_BUCKET_SUCCESS = '[Literatures] Upload file to bucket success';
export const UPLOAD_FILE_TO_BUCKET_FAIL = '[Literatures] Upload file to bucket fail';

export class UploadFileToBucket implements Action {
    readonly type = UPLOAD_FILE_TO_BUCKET;
    constructor(public payload: { formData: any; protocolId: string; articleId: string; title: string }) {}
}
export class UploadFileToBucketUploading implements Action {
    readonly type = UPLOAD_FILE_TO_BUCKET_UPLOADING;
    constructor(public payload: { articleId?: string; progress?: number }) {}
}
export class UploadFileToBucketSuccess implements Action {
    readonly type = UPLOAD_FILE_TO_BUCKET_SUCCESS;
    constructor(
        public payload: {
            status?: string;
            progress?: number;
            protocolId: string;
            articleId: string;
            url: string;
            title: string;
        }
    ) {}
}
export class UploadFileToBucketFail implements Action {
    readonly type = UPLOAD_FILE_TO_BUCKET_FAIL;
    constructor(public payload: any) {}
}

export const ADD_FILE_TO_ARTICLE = '[Literatures] Add file to article';
export const ADD_FILE_TO_ARTICLE_SUCCESS = '[Literatures] Add file to article success';
export const ADD_FILE_TO_ARTICLE_FAIL = '[Literatures] Add file to article fail';

export class AddFileToArticle implements Action {
    readonly type = ADD_FILE_TO_ARTICLE;
    constructor(public payload: { protocolId: string; articleId: string; url: string; title: string }) {}
}
export class AddFileToArticleSuccess implements Action {
    readonly type = ADD_FILE_TO_ARTICLE_SUCCESS;
    constructor(public payload: { protocolId: string; articleId: string; url: string; title: string; id: string }) {}
}
export class AddFileToArticleFail implements Action {
    readonly type = ADD_FILE_TO_ARTICLE_FAIL;
    constructor(public payload: any) {}
}

export const ADD_RIS_ARTICLE = '[Literatures] Add Ris article';
export const ADD_RIS_ARTICLE_SUCCESS = '[Literatures] Add Ris article success';
export const ADD_RIS_ARTICLE_FAIL = '[Literatures] Add Ris article fail';

export class AddRisArticle implements Action {
    readonly type = ADD_RIS_ARTICLE;
    constructor(public payload: { protocolId: string; url: string; }) {}
}
export class AddRisArticleSuccess implements Action {
    readonly type = ADD_RIS_ARTICLE_SUCCESS;
    constructor(public payload: { protocolId: string; url: string; }) {}
}
export class AddRisArticleFail implements Action {
    readonly type = ADD_RIS_ARTICLE_FAIL;
    constructor(public payload: any) {}
}

export const UPLOAD_FILE_TO_WEB_API = '[Literatures] Upload file to web api';
export const UPLOAD_FILE_TO_WEB_API_SUCCESS = '[Literatures] Upload file to web api success';
export const UPLOAD_FILE_TO_WEB_API_FAIL = '[Literatures] Upload file to web api fail';

export class UploadFileToWebApi implements Action {
    readonly type = UPLOAD_FILE_TO_WEB_API;
    constructor(public payload: { formData: any; protocolId: string; articleId: string; title: string }) {}
}

export class UploadFileToWebApiSuccess implements Action {
    readonly type = UPLOAD_FILE_TO_WEB_API_SUCCESS;
    constructor(
        public payload: {
            status?: string;
            progress?: number;
            articleId: string;
            protocolId: string;
        }
    ) {}
}
export class UploadFileToWebApiFail implements Action {
    readonly type = UPLOAD_FILE_TO_WEB_API_FAIL;
    constructor(public payload: any) {}
}

export const GO_TO_STEP_FIRST_ARTICLE = '[Literatures] Go To Step First Article';

export class GoToStepFirstArticle implements Action {
    readonly type = GO_TO_STEP_FIRST_ARTICLE;
    constructor(public payload: { protocolId: string; stepId: string }) {}
}

export type LiteraturesCollectionAction =
    | LoadLiteraturesCollection
    | LoadLiteraturesCollectionFail
    | LoadLiteraturesCollectionSucces
    | LoadLiteraturesCollectionContinue
    | GetLiterature
    | GetLiteratureFail
    | GetLiteratureSucces
    | SelectLiterature
    | SubmitLiteratureAction
    | SubmitLiteratureActionFail
    | SubmitLiteratureActionSucces
    | AddArticles
    | AddArticlesSucces
    | AddArticlesFail
    | UpdateAddedArticles
    | LoadParagraphs
    | LoadPopulations
    | GoToStepFirstArticle
    | UploadFileToBucket
    | UploadFileToBucketUploading
    | UploadFileToBucketSuccess
    | UploadFileToBucketFail
    | UploadFileToWebApi
    | UploadFileToWebApiSuccess
    | UploadFileToWebApiFail
    | AddFileToArticle
    | AddFileToArticleSuccess
    | AddFileToArticleFail
    | UploadRisFileToBucket
    | UploadRisFileToBucketUploading
    | UploadRisFileToBucketSuccess
    | UploadRisFileToBucketFail
    | AddRisArticle
    | AddRisArticleSuccess
    | AddRisArticleFail
    | TriggerRandomGPTTest
    | TriggerRandomGPTFullTextTest
    | GptVoteOnAbstractAction
    | GptVoteOnFullTextAction
    | GptVoteOnAllAbstractsAction
    | GptVoteOnAllFullTextAction;
