import { createSelector } from '@ngrx/store';

import * as fromIndexReducers from '../reducers';
import * as fromReducer from '../reducers/dashboard-existing.reducer';

const getExistingDashboardsState = createSelector(
    fromIndexReducers.getDashboardState,
    (state: fromIndexReducers.DashboardState) => state.existingDashboards
);
export const getExistingDashboards = createSelector(
    getExistingDashboardsState,
    fromReducer.selectAll
);
export const getExistingDashboardsEntities = createSelector(
    getExistingDashboardsState,
    fromReducer.selectEntities
);
export const getSelectedExistingDashboard = createSelector(
    getExistingDashboardsState,
    fromReducer.getSelectedDashboardExistingId,
    entities => {
        return entities.entities[entities.selectedDashboardExistingId];
    }
);
