<div class="row" *ngIf="!openForm">
    <div
        [ngClass]="{ h1: level === '1', h3: level === '3', h5: level === '5', h6: level === '6'  }"
        [innerHTML]=""
        (click)="onClickOpenForm()"
    >
        {{index}} {{ title }}
    </div>
</div>
<div class="row" *ngIf="openForm">
    <ng-form
        [ngClass]="{ h1: level === '1', h3: level === '3', h5: level === '5', h6: level === '6'  }"
        class="ng-form editTitle"
        width="100%"
        [formGroup]="titleForm"
        *ngIf="openForm"
        (keyup.enter)="onSubmit()"
        (blur)="onSubmit()"
        (focusout)="onSubmit()"
        (keyup.esc)="onClickCloseForm()"
    >
        <div class="">
            <label for="title"></label>
            <textarea
                id="title"
                #titleInput
                type="text"
                rows="2"
                class="editTitle"
                style="border: none;"
                formControlName="title"
            ></textarea>
        </div>
    </ng-form>
</div>
