import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'alii-web-shop-page-section',
    templateUrl: './page-section.component.html',
    styleUrls: ['./page-section.component.scss']
})
export class PageSectionComponent implements OnInit {

    @Input() dashboardTitle: string;
    @Input() messageTitle: string;
    @Input() messageBody: string;
    @Input() ownerName: string;
    @Input() ownerText: string;
    @Input() ownerImageUrl: string;

    ownerExists;

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.ownerExists =
            (this.ownerName && this.ownerName.length) ||
            (this.ownerText && this.ownerText.length) ||
            (this.ownerImageUrl && this.ownerImageUrl.length);
    }

    safeHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
