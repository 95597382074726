import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';

import * as dashboardSummaryActions from '../actions/dashboard-summary.action';
import * as dashboardTagActions from '../actions/dashboard-tag.action';
import * as dashboardPatientActions from '../actions/dashboard-patient.actions';
import * as setDashboardActions from '../../../starting-page/store/actions/set-dashboard.action';
import * as existingDashboardsActions from '../actions/dashboard-existing.action';

import * as fromService from '../../../../services/dashboard.service';
// import { NgxPermissionsService } from 'ngx-permissions';

import { of } from 'rxjs';

@Injectable()
export class DashboardSummaryEffects {
    constructor(
        private actions$: Actions,
        private dashboardService: fromService.DashboardService // private permissionsService: NgxPermissionsService
    ) {}

    @Effect()
    loadDashboardSummarySucces$ = this.actions$.pipe(
        ofType(dashboardSummaryActions.LOAD_DASHBOARD_SUMMARY_SUCCESS),
        mergeMap(action => {
            const {
                payload: { actions, dashboards, tags = [], My_patients }
            } = action as any;
            const { patients = [] } = My_patients ? My_patients : {};
            const permissions = actions.reduce((acc, a) => {
                const trueAction = Object.keys(a).filter(t => {
                    return a[t];
                });
                return [...acc, ...trueAction];
            }, []);
            return [
                new existingDashboardsActions.LoadDashboardExistings(dashboards),
                new dashboardTagActions.LoadDashboardTags({ dashboardTags: tags }),
                new dashboardPatientActions.LoadDashboardPatients({
                    dashboardPatients: patients
                }),
                new dashboardSummaryActions.LoadDashboardPermission({ permissions })
            ];
        })
    );
}
