<alii-web-spinner-loading *ngIf="(loading$ | async)"></alii-web-spinner-loading>
<ng-container *ngIf="!(loading$ | async) && (data$ | async) as data">
    <div *ngIf="data.meta?.banner && showBanner" ngbTooltip="{{ 'DASHBOARD.BANNER.CLOSE' | translate}}">
        <ngb-alert class="banner cursor-pointer" (click)="closeBanner()">{{ data.meta?.banner }}</ngb-alert>
    </div>

    <section class="page__section -no-bottom -transparent">
        <div class="filter-header sectionTitle -small-bottom">
            <div class="sectionTitle__title" id="dashboardTitle">
                {{ data.dashboard?.dashboard_title || 'Dashboard' }}
            </div>

            <div id="rapp_dash_extras" style="width:100%; display: none; background: white;">
                <p style="padding: 1em 2em">
                    Welkom!<br><br>
                    In deze richtlijn met beslisondersteuning vindt u aanbevolen interventies voor de behandeling van mensen met de ziekte van parkinson (mmZvP) en hun naasten door paramedische professionals (fysiotherapeuten, oefentherapeuten, ergotherapeuten, logopedisten en diëtisten). De aanbevelingen zijn gebaseerd op het best beschikbare bewijs uit onderzoek en de praktijk.
                    <br><br>
                    De inhoud van het product is eigendom van ParkinsonNet, het KNGF, VvOCM, EN, NVLF, NVD en de Parkinson Vereniging.
                    <br>
                    Datum van laatste update: 28 juni 2024
                    <br><br>
                    Selecteer een optie om verder te gaan
                </p>

                <section class="page__section -no-bottom -transparent" style="width:100%">
                    <div class="selectView" (click)="setRappView('prof')"
                    [ngClass]="rappView == 'prof' ? 'toggleOn' : 'toggleOff' "
                    >
                        <h3>Ik ben zorgverlener</h3>    
                    </div>

                    <div style="margin-left:1%" class="selectView" (click)="setRappView('patient')"
                        [ngClass]="rappView == 'patient' ? 'toggleOn' : 'toggleOff' "
                    >
                        <h3>Ik leef met parkinson</h3>    
                    </div>
                </section>

                <br style="clear: both;">

                <!--
                <section class="page__section" [hidden]="rappView == 'prof' || rappView == 'patient'" style="margin-bottom: 30px;"> 
                    Selecteer hierboven een optie om de richtlijn met beslisondersteuning te zien
                </section>
                -->    


                <section class="page__section -transparent" [hidden]="rappView != 'prof'">
                    <div>
                        <div  class="dashboard__items">
                            <div  class="dashboard__item"  style="width: 47%">
                                <article  class="card">
                                    <header  class="card__header"><a 
                                            class="card__header-link"><span 
                                                class="card__header-heading">Beslisondersteuning met aanbevelingen voor zorgverleners
                                            </span><span
                                                class="card__header-subheading"></span></a></header>
                                    <footer  class="card__footer"><a 
                                        href="/protocols/90ce114f-69fa-4364-a2a0-efbb57733e76?version=Current"
                                        class="tag mr-1 -primary"> Open beslisondersteuning </a>
                                    </footer>
                                </article>
                            </div>

                            <div  class="dashboard__item" style="width: 47%">
                                <article  class="card">
                                    <header  class="card__header"><a 
                                            class="card__header-link"><span 
                                                class="card__header-heading">Gebruikershandleiding, algemene anamnese en overige informatie voor zorgverleners
                                            </span><span
                                                class="card__header-subheading"></span></a></header>
                                    <footer  class="card__footer"><a 
                                            href="/protocols/2e756552-e27a-4933-8523-d34cc2a3623a?version=Current"
                                            class="tag mr-1 -primary" target="_blank"> Open handleiding </a>
                                    </footer>
                                </article>
                            </div>


                        </div>
                    </div>

                    <div class="wysiwyg" style="margin-top:20px">
                        <ul>
                            <li><a href="/protocols/ad324566-7db7-4a93-a0e8-86caf95e2f3f?version=Current" target="_blank">Overzicht van hoofdproblemen en achterliggende richtlijnteksten</a></li>
                            <li><a href="/protocols/93f6f2f4-8122-494f-8176-dbea870d0eb1?version=Current" target="_blank">Hoe deze beslisondersteuning is ontwikkeld</a></li>
                        </ul>
                    </div>
                </section>


                <section class="page__section -transparent"  [hidden]="rappView != 'patient'">
                    <div>
                        <div  class="dashboard__items">
                            <div  class="dashboard__item"  style="width: 47%">
                                <article  class="card">
                                    <header  class="card__header"><a 
                                            class="card__header-link"><span 
                                                class="card__header-heading">Beslisondersteuning voor mensen die leven met parkinson
                                            </span><span
                                                class="card__header-subheading"></span></a></header>
                                    <footer  class="card__footer"><a 
                                        href="/protocols/d4d63d38-5571-46c6-94a4-092656d04084?version=Current"
                                        class="tag mr-1 -primary"> Open beslisondersteuning </a>
                                    </footer>
                                </article>
                            </div>

                            <div  class="dashboard__item" style="width: 47%">
                                <article  class="card">
                                    <header  class="card__header"><a 
                                            class="card__header-link"><span 
                                                class="card__header-heading">Gebruikershandleiding voor mmZvP
                                            </span><span
                                                class="card__header-subheading"></span></a></header>
                                    <footer  class="card__footer"><a 
                                            href="/protocols/bee0bc15-97ac-442f-8e8a-2f64b7ff7522"
                                            class="tag mr-1 -primary"  target="_blank"> Open handleiding </a>
                                    </footer>
                                </article>
                            </div>


                        </div>
                    </div>


                    <div class="wysiwyg" style="margin-top:20px">
                        <ul>
                            <li><a href="/protocols/3515c951-6b5b-47ef-9e28-8ea5d1c00216?version=Current" target="_blank">Algemene informatie over paramedische behandeling</a></li>
                            <li><a href="/protocols/205d5d97-23f3-47ed-be2d-15d31d024930?version=Current" target="_blank">Hoe deze beslisondersteuning is ontwikkeld</a></li>
                        </ul>
                    </div>
                </section>

                 </div>

            <div class="sectionTitle__actions">
                <div class="button-group" id="dashboardSelect">
                    <span *ngIf="setDashboardLoading$ | async" class="spinner-grow spinner-grow-sm mt-2"></span>
                    <alii-web-dashboard-select
                        *ngxPermissionsOnly="['change_dashboard']"
                        class="ml-2"
                        [dashboards]="(dashboards$ | async)"
                        [dashboardId]="data?.dashboard?.dashboard_id?.toString()"
                        (handleSelectDashoard)="handleSelectDashoard($event)"
                    ></alii-web-dashboard-select>
                    <alii-web-dashboard-manage-menu
                        *ngxPermissionsOnly="['manage_dashboard', 'create_protocol']"
                        class="ml-2"
                        [dashboard]="data.dashboard"
                        [dashboards]="(dashboards$ | async )"
                    ></alii-web-dashboard-manage-menu>
                </div>
            </div>
        </div>
    </section>

    <section *ngIf="data.meta?.onboarding?.onboardingSteps?.length" class="page__section -no-bottom -transparent">
        <alii-web-dashboard-onboarding-steps
            [onboarding]="data.meta.onboarding">
        </alii-web-dashboard-onboarding-steps>
    </section>

    <section *ngIf="!data.dashboard" class="page__section -no-bottom -transparent">
        <alii-web-choose-team
            [teamList]="data.teamList">
        </alii-web-choose-team>
    </section>
    

    <section *ngxPermissionsOnly="['manage_patients']"
            class="page__section -no-bottom -transparent">
        <alii-web-dashboard-patients
            [patients]="data.patients?.patients"
            (eventBus)="handleEventBus($event)"
        ></alii-web-dashboard-patients>
    </section>

    <section *ngIf="data.lastVisited?.protocols?.length" class="page__section -no-bottom -transparent" id="lastVisitedDocuments">
        <alii-web-last-visited [lastVisited]="data.lastVisited"></alii-web-last-visited>
    </section>

    
    <section *ngIf="data.dashboard?.categories?.length" class="page__section -no-bottom -transparent pt-0" id="categoryOverviewDashboard">
        <alii-web-dashboard-category-list
            [dashboardCategories]="data.dashboard.categories"
            [pagination]="false"></alii-web-dashboard-category-list>
    </section>
</ng-container>