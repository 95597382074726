import { Component, ChangeDetectionStrategy, Input, OnInit, EventEmitter, Output} from '@angular/core';

import { ParagraphHelperService } from '../../../../../shared/helpers';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Protocol } from '../../../../../models/protocol.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RejectModalComponent } from './reject-modal/reject-modal.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-protocol-info',
    templateUrl: './protocol-info.component.html',
    styleUrls: ['./protocol-info.component.scss']
})
export class ProtocolInfoComponent implements OnInit {
    @Input() protocol: Protocol;
    @Input() version: string;

    @Output() eventBus: EventEmitter<any> = new EventEmitter<any>();

    status: string;
    protocolStatuses: Array<{ k: string; v: string }> = [];

    isListChangesParCollapsed = false;

    flattenParagraphs: any[];

    displayPreviewButton: boolean;

    constructor(private sanitizer: DomSanitizer, public paragraphHelper: ParagraphHelperService, private modalService: NgbModal ) {}

    ngOnInit(): void {
        this.displayPreviewButton = !['Current', 'Preview'].includes(this.version);
        this.flattenParagraphs =
            this.protocol && this.protocol.paragraphs
                ? this.paragraphHelper.flattenParagraph(this.protocol.paragraphs).map(par => par.id)
                : [];
        this.status = this.protocol.protocol_status.status;
        Object.keys(this.protocol.protocol_status)
            .sort()
            .filter(key => key !== 'status')
            .forEach(key => {
                const value = this.protocol.protocol_status[key];
                if (value !== null && typeof value === 'string') {
                    let v = value;
                    let k = key.charAt(0).toUpperCase() + key.slice(1);
                    if (this.version === 'Current') {
                        k = k.replace(/_/g, ' ');
                        if (k.indexOf(' timestamp') !== -1) {
                            const dt = Date.parse(v);
                            v = new Date(dt).toDateString();
                            k = k.replace(' timestamp', '');
                        } else if (k === 'Timestamp') {
                            const dt = new Date(Date.parse(v));
                            const h = dt.getHours();
                            const hh = (h < 10 ? '0' : '') + h;
                            const s = dt.getSeconds();
                            const ss = (s < 10 ? '0' : '') + s;
                            v = dt.toDateString() + ' at ' + hh + ':' + ss;
                        }
                        this.protocolStatuses.push({ k, v });
                    }
                }
            });
    }

    handleClickAction(actionObject, categoryId = null) {
        const action = {
            type: 'handleSubmitProtocolActionFlow',
            payload: {
                action: actionObject.action,
                protocolId: this.protocol.id
            }
        };
        if (categoryId) {
            action.payload['categoryId'] = categoryId;
        }
        if (actionObject.versionId) {
            action.payload['versionId'] = actionObject.versionId;
        }
        this.eventBus.emit(action);
    }

    handleRejectAction() {
        const modalRef = this.modalService.open(RejectModalComponent);
        modalRef.result.then(
            result => {
                const action = {
                    type: 'handleSubmitProtocolActionFlow',
                    payload: {
                        action: "rejectDraft",
                        protocolId: this.protocol.id,
                        comment: result.message
                    }
                };
                this.eventBus.emit(action);
            },
            () => {}
        );
    }

    onClickToggleListChangesPar() {
        this.isListChangesParCollapsed = !this.isListChangesParCollapsed;
    }

    onClickChangeParTitle(paragraphId) {
        if (!this.flattenParagraphs.includes('' + paragraphId)) {
            return false;
        }
        let parId = '' + paragraphId
        const action = { type: 'handleParagraphSelected', payload: {  paragraphId: parId } };
        this.eventBus.emit(action);
    }

    onClickUrl(url: string) {
        window.open(url, '_blank');
        return false;
    }

    safeHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    trackByFn = (index, item) => item.id || index;
}
