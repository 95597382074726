import { Component, ChangeDetectionStrategy, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../../../../models/field.interface';
import { FieldConfig } from '../../../../../models/field-config.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'form-switch',
    template: `
        <div class="dynamic-field form-input" [formGroup]="group" class="form-group">
            <div *ngIf="config.info" class="field-info">{{ config.info }}</div>
            <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitch" [formControlName]="config.name" />
                <label class="custom-control-label" for="customSwitch">{{ config.label }}</label>
            </div>
        </div>
    `
})
export class FormSwitchComponent implements Field {
    config: FieldConfig;
    group: FormGroup;
}
