import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    OnInit,
    Renderer2,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { Action, Protocol } from '../../../../models/protocol.interface';

import { OrderingParModalComponent } from '../../entry-components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Paragraph } from '../../../../models/paragraph.interface';
import { TranslateService } from '@ngx-translate/core';

const cn = 'ProtocolSidebarComponent';


@Component({
    
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-protocol-sidebar',
    templateUrl: './protocol-sidebar.component.html',
    styleUrls: ['./protocol-sidebar.component.scss']
})
export class ProtocolSidebarComponent implements OnInit, OnChanges {
    
    @Input() isColapsed: boolean;

    @Input() isEditAble: boolean;

    @Input() protocol: Protocol;

    @Input() versionId: string;

    @Input() version: string;

    @Input()
    currentVersion: any;
    
    @Input()
    pane: string;

    @Input()
    draftVersion: any;

    @Input()
    deprecatedVersions: any[] = [];

    @Input()
    storedDraftVersions: any[] = [];

    @Input()
    scrollParagraphId: string;
    _scrollParagraphId: string;
    parClicked: string;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    INDEX_PANEL = '[sidebar] index panel';
    VERSIONS_PANEL = '[sidebar] versions panel';

    panelOpened = this.INDEX_PANEL;

    editOrderDirty = false;

    createLocalVersion: Action;

    constructor(
        private translateService: TranslateService,
        private modalService: NgbModal,
        private renderer: Renderer2) {}

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.protocol && changes.protocol.currentValue) {
            const protocol = changes.protocol.currentValue;
            if (protocol.protocol_actions) {
                this.createLocalVersion = protocol.protocol_actions.find(
                    action => action.action === 'createLocalVersion'
                );
            }
        }
        if (changes.scrollParagraphId && changes.scrollParagraphId.currentValue ) {
            if (!this.parClicked) {
                // scroll
                const pid = changes.scrollParagraphId.currentValue;
                // Only respond to valid paragraph ids.
                if (this._verifyParagraphId(pid, this.protocol.paragraphs)) {
                    this._scrollParagraphId = pid;
                    } 
                }
            if(this.parClicked != null) {
                // click
                this._scrollParagraphId = this.parClicked;
                setTimeout(() => {
                    // wait because the scroll fires if we don't
                    this.parClicked = null
                    }, 1000);
            } 
        }
    }

    toggleSidebar() {
        const sidebar = document.getElementById('protocolSidebar')
        if (!sidebar.classList.contains('-is-open')) {
            this.renderer.addClass(sidebar, '-is-open');
        } else {
            this.renderer.removeClass(sidebar, '-is-open');
        }

     }

    handleOpenOrderingPar() {
        const modalRef = this.modalService.open(OrderingParModalComponent, { size: 'lg' });
        modalRef.componentInstance.paragraphs = this.protocol.paragraphs;
        modalRef.componentInstance.protocolId = this.protocol.id;

        modalRef.componentInstance.eventBus.subscribe(event => {
            switch (event.type) {
                case 'handleUpdateParPosition':
                    this.handleUpdateParPosition(event.payload);
                    break;
                default:
                    break;
            }
        });
        modalRef.result.then(
            () => {
                this.onClickToggleSort();
            },
            () => {
                this.onClickToggleSort();
            }
        );
    }

    getKeyQuestionItems(paragraph) {
        let childrenList = []
        let types = ['conclusion', 'consideration', 'recommendation']
        types.forEach(type => {
            let found = false
            if(this.version == 'Draft') {
                found = true
            }
            else {
                paragraph.children.forEach(child => {
                    if (child.type === type) {
                        found = true
                    } 
                });
            }
            if (found) {
                let title = ''
                if (type == 'consideration') { 
                    this.translateService.get('PARAGRAPH.CONSIDERATIONS').subscribe(translation => title = translation)
                 }
                if (type == 'conclusion') {
                    this.translateService.get('PARAGRAPH.CONCLUSIONS').subscribe(translation => title = translation)
                }
                if (type == 'recommendation') {
                    this.translateService.get('PARAGRAPH.RECOMMENDATIONS').subscribe(translation => title = translation)
                }
                let el = {title, id: type + '-' + paragraph.id}
                childrenList.push( el )
            } else if (paragraph.recommendationList) {
                let title = ''
                this.translateService.get('PARAGRAPH.RECOMMENDATIONS').subscribe(translation => title = translation)
                let el = {title, id: 'recommendation-' + paragraph.id}
                childrenList.push( el )
            }

        }
        )
        return childrenList

    }

    onClickToggleSort() {
        if (this.editOrderDirty) {
            this.editOrderDirty = false;
            this.eventBus.emit({ type: 'handleDoneEditOrder', payload: { protocolId: this.protocol.id } });
        }
    }

    handleUpdateParPosition(payload) {
        this.eventBus.emit({
            type: 'handleUpdateParPosition',
            payload
        });
        this.editOrderDirty = true;
    }

    handleCreateDraft() {
        const action = {
            type: 'handleSubmitProtocolActionFlow',
            payload: {
                action: 'createDraft',
                protocolId: this.protocol.id
            }
        };
        this.eventBus.emit(action);
    }

    showVersionPanelContent() {
        if (this.deprecatedVersions.length > 0 
                || this.protocol.protocol_actions.find(action => action.action === 'createDraft')
                || this.draftVersion && this.currentVersion 
                || this.storedDraftVersions.length > 0
                ) {
            return true
        } else {
            return false
        }
     }

    handleOpenPane(name) {
        this.pane = name
        const action = { type: 'handleOpenPane', payload: { name } };
        this.eventBus.emit(action);
        this.handleToggleOpenedPanel('');
    }

    onToggleColapse() {
        this.isColapsed = !this.isColapsed;
        const action = { type: 'handleToggleColapse', payload: { isColapsed: this.isColapsed } };
        this.eventBus.emit(action);
    }

    handlePrintPdf() {
        const action = { type: 'open-print', payload: {} };
        this.eventBus.emit(action);
    }

    handleToggleBookmark() {
        const bookmark = this.protocol.bookmark ? 'remove' : 'set';
        const action = { type: 'handleUpdateSetting', payload: { bookmark } };
        this.eventBus.emit(action);
    }

    handleParagraphSelected(paragraphId) {
        this.parClicked = paragraphId
        const action = { type: 'handleParagraphSelected', payload: { paragraphId } };
        this.eventBus.emit(action);
    }

    handleOpenByVersion(versionId, version) {
        
        const action = {
            type: 'handleOpenByVersion',
            payload: { versionId, version }
        };
        this.eventBus.emit(action);
    }

    handleOpenCompareVersion() {

        const action = {
            type: 'handleOpenCompareVersion'
        };
        this.eventBus.emit(action);
    }

    handleToggleOpenedPanel(panel) {
        if (this[panel] === this.panelOpened) {
            this.panelOpened = 'text'
            return;
        }
        this.panelOpened = this[panel];
    }

    handleCopyProtocol() {
        const action = {
            type: 'handleCopyProtocol',
            payload: {}
        };
        this.eventBus.emit(action);
    }

    scrollToAnchor(id) {
        var el = document.getElementById(id)
        setTimeout(() => el.scrollIntoView({ behavior: 'smooth' }), 100);
    }

    handleCreateLocalVersion() {
        const action = {
            type: 'handleCreateLocalVersion',
            payload: {}
        };
        this.eventBus.emit(action);
    }

    private _verifyParagraphId(pid: string, paragraphs: Paragraph[]): boolean {
        let checkId = pid
        if (pid.includes('-') )
            {
            return true
         }
        const paragraphIds: string[] = [];
        (paragraphs || []).forEach(p => {
            if(p.show_in_menu) {
                paragraphIds.push(p.id);
            };
            (p.children || []).forEach(c => {
                if(c.show_in_menu) {
                    paragraphIds.push(c.id)
                }
            }
            );
        })
        return paragraphIds.includes(checkId);
    }

    trackByFn = (index, item) => item.id || index;
}
