import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, withLatestFrom, take, map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import * as protocolAction from '../actions/protocol.actions';
import * as fromService from '../../../../services';
import { ToastrService } from 'ngx-toastr';
import { Store, select } from '@ngrx/store';

import * as ProtocolSelector from '../selectors/protocol.selector';

const selectStore = (selectors: any) => {
    return switchMap(action => {
        return of([]).pipe(
            withLatestFrom(...selectors, (initial, ...selectorsState) => {
                return [action, ...selectorsState];
            }),
            take(1) // needed, since without it, subsription not killed until next action that hit effect
        );
    });
};

@Injectable()
export class ProtocolEffects {
    constructor(
        private store$: Store<any>,
        private actions$: Actions,
        private protocolService: fromService.ProtocolService,
        private UploadService: fromService.UploadService,
        private toastr: ToastrService
    ) {}

    @Effect()
    loadProtocolCollectionEffect$ = this.actions$.pipe(
        ofType(protocolAction.ProtocolActionTypes.GetProtocol),
        selectStore([
            this.store$.pipe(select(ProtocolSelector.SelectProtocolId)),
            this.store$.pipe(select(ProtocolSelector.SelectProtocol))
        ]),
        switchMap(([action, protocolId, protocol]) => {
            const {
                payload: { protocolId: targetProtocolId }
            } = action as any;

            if (protocol && protocolId === targetProtocolId) {
                return new Observable(() => {});
            }

            return this.protocolService.getProtocol({ protocolId: targetProtocolId }).pipe(
                map(response => new protocolAction.GetProtocolSucces({ protocol: response })),
                catchError(error => of(new protocolAction.GetProtocolFail(error)))
            );
        })
    );
}
