import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromStartingPage from './starting-page.reducer';
import * as fromSetDashboard from './set-dashboard.reducer';

export interface StartingPageFeatureState {
    startingPage: fromStartingPage.StartingPageState;
    setDashboard: fromSetDashboard.SetDashboardState;
}

export const reducers: ActionReducerMap<StartingPageFeatureState> = {
    startingPage: fromStartingPage.reducer,
    setDashboard: fromSetDashboard.reducer
};

export const getStartingPageFeatureState = createFeatureSelector<StartingPageFeatureState>('StartingPageFeature');
