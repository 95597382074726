import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromOrganization from '../reducers/organization.reducer';

export const getOrganizationState = createSelector(
    fromFeature.getOrganisationFeatureState,
    (state: fromFeature.OrganisationsFeatureState) => state.organization
);
export const getOrganization = createSelector(
    getOrganizationState,
    fromOrganization.getOrganization
);

export const getOrganizationLoaded = createSelector(
    getOrganizationState,
    fromOrganization.getOrganizationLoaded
);

export const getOrganizationLoading = createSelector(
    getOrganizationState,
    fromOrganization.getOrganizationLoading
);
