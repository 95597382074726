import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    AfterViewInit,
    ElementRef,
    Renderer2,
    ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// IMPORTANT: You MUST use the IE11 version of htmldiff here!
import HtmlDiff from 'htmldiff-js-ie11';

export interface CompareParagraph {
    title: string;
    text: string;
}

export interface CompareParagraphs {
    previous: CompareParagraph;
    current: CompareParagraph;
}

export interface CompareParagraphsData {
    level: number;
    paragraphs: CompareParagraphs;
}

interface Legend {
    name: string;
    tag: string;
    qualifier: string;
    class: string;
    selected: boolean;
}

const cn = 'CompareParagraphsModalComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-compare-paragraphs-modal',
    templateUrl: './compare-paragraphs.component.html',
    styleUrls: ['./compare-paragraphs.component.scss']
})
export class CompareParagraphsModalComponent implements OnInit, AfterViewInit {
    // Data passed to this modal
    data: CompareParagraphsData;

    identicalTitles: boolean;
    identicalTexts: boolean;
    paragraphs: CompareParagraphs;
    level: number;

    // HTML elements
    previousTitleHtml: HTMLElement;
    currentTitleHtml: HTMLElement;
    previousTextHtml: HTMLElement;
    currentTextHtml: HTMLElement;

    // Element ids
    idCurrentTitle = 'current-title-html';
    idCurrentText = 'current-text-html';
    idPreviousTitle = 'previous-title-html';
    idPreviousText = 'previous-text-html';
    idDiffTitle = 'diff-title-html';
    idDiffText = 'diff-text-html';

    legend: Legend[] = [
        { name: 'all', class: 'all', tag: '', qualifier: '', selected: true },
        { name: 'new', class: 'ins-diffins', tag: 'ins', qualifier: 'diffins', selected: false },
        { name: 'inserted', class: 'ins-diffmod', tag: 'ins', qualifier: 'diffmod', selected: false },
        { name: 'removed', class: 'del-diffdel', tag: 'del', qualifier: 'diffdel', selected: false },
        { name: 'removed', class: 'del-diffmod', tag: 'del', qualifier: 'diffmod', selected: false }
    ];

    legendDisplay: Legend[] = [];

    activeTab: string;

    constructor(
        public activeModal: NgbActiveModal,
        private hostElement: ElementRef,
        private renderer: Renderer2,
        private changeDetectorRef: ChangeDetectorRef,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.activeTab = 'compare';
        this.level = this.data.level;
        this.paragraphs = this.data.paragraphs;
        this.identicalTitles = this.paragraphs.previous.title === this.paragraphs.current.title;
        this.identicalTexts = this.paragraphs.previous.text === this.paragraphs.current.text;
    }

    ngAfterViewInit() {
        // Attempt to access html elements every 200ms until either it is found or max time has been reached.
        const TIMEOUT = 200;
        const MAX = 5000;
        let msecs = 0;
        // TODO: Replace with RXJS timer take until which is more elegant.
        const intervalId = setInterval(() => {
            this.previousTitleHtml = document.getElementById('previous-title-html');
            this.currentTitleHtml = document.getElementById('current-title-html');
            this.previousTextHtml = document.getElementById('previous-text-html');
            this.currentTextHtml = document.getElementById('current-text-html');
            if (this.previousTitleHtml && this.currentTitleHtml && this.previousTextHtml && this.currentTextHtml) {
                // Found.
                clearInterval(intervalId);
            } else {
                // Not found.
                msecs += TIMEOUT;
                if (msecs >= MAX) {
                    console.warn(`${cn} ngAfterViewInit() failed to find all html elements`);
                    clearInterval(intervalId);
                }
            }
        }, TIMEOUT);
    }

    tabChange(tab: string) {
        if (tab === 'differences') {
            // Attempt to access diff elements every 200ms until either it is found or max time has been reached.
            const TIMEOUT = 200;
            const MAX = 5000;
            let msecs = 0;
            // TODO: Replace with RXJS timer take until which is more elegant.
            const intervalId = setInterval(() => {
                const diffTitleHtml = document.getElementById('diff-title-html');
                const diffTextHtml = document.getElementById('diff-text-html');
                if (diffTitleHtml && diffTextHtml) {
                    // Found.
                    diffTitleHtml.innerHTML = HtmlDiff.execute(
                        this.previousTitleHtml.innerHTML,
                        this.currentTitleHtml.innerHTML
                    );
                    diffTextHtml.innerHTML = HtmlDiff.execute(
                        this.previousTextHtml.innerHTML,
                        this.currentTextHtml.innerHTML
                    );
                    this.clickLegend(null, 0);
                    clearInterval(intervalId);
                } else {
                    // Not found.
                    msecs += TIMEOUT;
                    if (msecs >= MAX) {
                        console.warn(`${cn} tabChange() failed to find both diff elements`);
                        clearInterval(intervalId);
                    }
                }
            }, TIMEOUT);
        }
    }

    clickLegend(event: any, index: number) {
        // IMPORTANT: IE11 does NOT support forEach() so we need to iterate using an old-fashioned for loop.
        const el = this.hostElement.nativeElement;

        // Only display those legend labels whose selectors are found in the diff text.
        if (!this.legendDisplay.length) {
            for (let i = 0; i < this.legend.length; i++) {
                const l = this.legend[i];
                if (i) {
                    const sel = `${l.tag}.${l.qualifier}`;
                    const found = el.querySelectorAll(sel);
                    if (found.length) {
                        this.legendDisplay.push(l);
                    }
                } else {
                    this.legendDisplay.push(l);
                }
            }
            this.changeDetectorRef.markForCheck();
        }

        // The legend is displayed on the differences pages. It consists of a number of colored labels which serve two
        // purposes: show the description of what the color means, and provide a filter to display only those portions
        // of the text which are related to that given type of change. The is done by adding or removed the 'selected'
        // class, see the file compare-paragraphs.component.scss for the details. Clicking the 'all' label will display
        // everything, which is the default view.
        for (let i = 0; i < this.legendDisplay.length; i++) {
            const l = this.legendDisplay[i];
            l.selected = index === 0 || i === index;
        }

        for (let i = 0; i < this.legendDisplay.length; i++) {
            const l = this.legendDisplay[i];
            if (i) {
                const sel = `${l.tag}.${l.qualifier}`;
                const found = el.querySelectorAll(sel);
                if (found) {
                    for (let j = 0; j < found.length; j++) {
                        const f = found[j];
                        if (l.selected) {
                            this.renderer.addClass(f, 'selected');
                        } else {
                            this.renderer.removeClass(f, 'selected');
                        }
                    }
                }
            }
        }

        if (event) {
            event.stopPropagation();
        }
        return false;
    }

    safeHtml(html: string) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
