<div style="color: #706f6e;">
    {{'MODEL.OUTCOME.TREEVIEW.TEXT' | translate}}:
</div>

<div *ngFor="let ruleGroup of ruleGroups; let o = index">
    <alii-outcome-rule-group [ruleGroup]="ruleGroup"></alii-outcome-rule-group>

    <div *ngIf="o < ruleGroups.length - 1" class="rule-row">
        <div class="question"></div>
            Or
        <div class="answer-options"></div>
    </div>
</div>