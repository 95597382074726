<label [for]="inputId"></label>
<ng-template #rt let-r="result" let-t="term">
    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>
<input
    [id]="inputId"
    type="text"
    class="form-control"
    [ngbTypeahead]="search"
    [(ngModel)]="model"
    (selectItem)="selectItem($event)"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    #instance="ngbTypeahead"
    [resultTemplate]="rt"
    [placeholder]="placeholder"
/>
