<h1>Timeline beheren</h1>

<h4>Nieuw bericht plaatsen</h4>
<form [formGroup]="messageForm">
    Titel <input type="text" class="form-control" formControlName="title" /> <br />

    Bericht
    <textarea [froalaEditor]="options" [(froalaModel)]="text" formControlName="text" class="as-html"></textarea> <br />
    <br />

    Selecteer ontvangers
    <select class="multiselect-ui form-control" multiple="multiple" formControlName="usergroupIds" style="resize: both;">
        <option value="" selected>Naar iedereen</option>
        <option *ngFor="let group of (usergroups$ | async)?.usergroup" value="{{ group.id }}">{{ group.title }}</option>
    </select>
    <br />

    Categorie <input type="text" class="form-control" formControlName="category" /> <br />

    <input type="hidden" formControlName="messageId" *ngIf="editing" />

    Verzend als pushnotificatie <input type="checkbox" value="true" formControlName="push" /><br />
    <input
        type="hidden"
        class="form-control"
        value="{{ dateToday }}"
        name="date"
        formControlName="date"
        class="form-control"
    />

    <button type="button" (click)="submit()" class="btn btn-info">Plaats bericht</button>
</form>

<br />
<br />

<h4>Geplaatste berichten</h4>
<input
    type="text"
    placeholder="Doorzoek timeline"
    class="form-control search-field__input"
    style="border: 1px solid #ddd"
    size="100"
    #searchQuery
    (keyup)="onSearch(searchQuery.value)"
/>
<br />
<ul>
    <li *ngFor="let message of (messages$ | async)?.messages; trackBy: trackByFn">
        <strong *ngIf="message.date">{{ message.date?.slice(0, 10) }}</strong> {{ message.title }} &nbsp; <br />
        <a (click)="removeMessage(message.id)" class="tag" href="#">Bericht verwijderen</a> &nbsp;
        <a href="/messages/manage/{{ message.id }}" class="tag">Bericht aanpasssen</a><br /><br />
    </li>
</ul>
<br style="clear: both;" />
&nbsp;
