import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { HttpProgressState, HttpStateService } from '../../services/http-state.service';

@Injectable({
    providedIn: 'root'
})
export class BlockUiSpinnerInterceptor implements HttpInterceptor {
    private exceptions: string[] = ['login'];

    constructor(private httpStateService: HttpStateService) {}

    // Intercepts all requests
    //  - in case of an error (network errors included) it will retry once before failing
    //  - all other error can be handled an error specific case and redirects into
    //    specific error pages if necessary
    //
    // There is an exception list for specific URL patterns that we don't want the application to act
    // automatically
    //
    // The interceptor also reports back to the httpStateService when a certain requests started and ended
    //
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.exceptions.every((term: string) => request.url.indexOf(term) === -1)) {
            return next.handle(request).pipe(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                tap(
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    (response: any) => {},
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    error => {}
                )
            );
        }

        this.httpStateService.state.next({
            url: request.url,
            state: HttpProgressState.start
        });

        return next.handle(request).pipe(
            finalize(() => {
                this.httpStateService.state.next({
                    url: request.url,
                    state: HttpProgressState.end
                });
            })
        );
    }
}
