/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.indexOf('login.vm') > 0 ) {
            return next.handle(request);
        }

        let finalRequest = request;

        if (this.authService.isLoggedIn()) {
            const hashPart = 'authhash=' + this.authService.getAuthHash();
            const anIdPart = '&s=' + this.authService.getAnId();
            const separator = request.url.indexOf('?') > -1 ? '&' : '?';
            finalRequest = request.clone({
                url: request.url + separator + hashPart + anIdPart
            });
        }

        return next.handle(finalRequest).pipe(
              tap((event: any) => {
                if (event instanceof HttpResponse) {
                     if ((event.body && event.body.errorCode && event.body.errorCode === '403')  || event.headers.get('status') === "401") {
                        this.authService.logout();
                    }
                }
              }),
              catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401 || err.status === 403) {
                        const protocolRegex = /\/protocols\/(.+)/;
                        const match = request.url.match(protocolRegex);
                        if (match) {
                            const protocolId = match[1];
                            return this.authService.getProtocolSSOProvider(protocolId).pipe(
                              tap(response => this.authService.redirectToSSOProvider(response)),
                                // If getProtocolSSOProvider throws, we catch and re-throw the original error
                                catchError(() => throwError(err))
                            );
                        }
                        else {
                          this.authService.logout();
                        }
                    }
                }
                return throwError(err);
            })        
        );                  
    }
}
