import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';

import * as dashboardCategoriesActions from '../actions/dashboard-categories.action';

import * as fromService from '../../../../services/dashboard.service';

import { of, Observable } from 'rxjs';

export interface PayloadAction {
    type: string;
    payload: any;
}

@Injectable()
export class DashboardCategoriesEffects {
    constructor(private actions$: Actions, private dashboardService: fromService.DashboardService) {}

    @Effect()
    loadDashboardCategories$ = this.actions$.pipe(
        ofType(dashboardCategoriesActions.PROT_LOAD_DASHBOARD_CATEGORIES),
        switchMap(() => {
            return this.dashboardService.getAllCategoriesV2().pipe(
                map(response => {
                    const categories = response;
                    return new dashboardCategoriesActions.LoadDashboardCategoriesSucces({ categories });
                }),
                catchError(error => of(new dashboardCategoriesActions.LoadDashboardCategoriesFail(error)))
            );
        })
    );
}
