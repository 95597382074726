import { ProtocolsComponent } from './protocols/protocols.component';
import { ProtocolsDetailComponent } from './protocols-detail/protocols-detail.component';
import { ProtocolsFormComponent } from './protocols-form/protocols-form.component';
import { ProtocolsImportComponent } from './protocols-import/protocols-import.component';
import { FlowchartBuilderComponent } from './flowchart-builder/flowchart-builder.component';

export const containers: any[] = [
    ProtocolsComponent,
    ProtocolsDetailComponent,
    ProtocolsFormComponent,
    ProtocolsImportComponent,
    FlowchartBuilderComponent
];

export * from './protocols/protocols.component';
export * from './protocols-detail/protocols-detail.component';
export * from './protocols-form/protocols-form.component';
export * from './protocols-import/protocols-import.component';
export * from './flowchart-builder/flowchart-builder.component';
