import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as clipboard from 'clipboard-polyfill';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-save-patient-link-modal',
    templateUrl: './save-patient-link-modal.component.html',
    styleUrls: ['./save-patient-link-modal.component.scss']
})
export class SavePatientLinkModalComponent implements OnInit {
    link: string;

    @Input()
    patientId:string;

    constructor(
        public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        public translateService: TranslateService
    ) {}

    ngOnInit() {
        this.link = this.updateUrlParameter(document.location.toString(), 'populationId', this.patientId);
    }

    copyLink(): void {
        const plain = this.link;
        const dt = new clipboard.DT();

        dt.setData('text/plain', plain);
        clipboard.write(dt);

        this.translateService.get('TEXT-EDIT.TEXT-COPIED').subscribe(message => this.toastr.success(message));
    }

    // Add / Update a key-value pair in the URL query parameters
    updateUrlParameter(uri: string, key, value) {
    // remove the hash part before operating on the uri
        var i = uri.indexOf('#');
        var hash = i === -1 ? ''  : uri.substr(i);
            uri = i === -1 ? uri : uri.substr(0, i);

        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            uri = uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            uri = uri + separator + key + "=" + value;
        }
        return uri + hash;  // finally append the hash as well
    }
}
