import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';

import * as fromActions from '../actions/eventbus-channel.action';

export interface EventbusChannelState {
    messages: any[];
}

export const initialState: EventbusChannelState = {
    messages: []
};

export function reducer(state = initialState, action: fromActions.EventbusChannelAction): EventbusChannelState {
    switch (action.type) {
        case fromActions.PUBLISH_MESSAGE: {
            const { payload } = action;
            const { channel, message } = payload;
            const newState = produce(state, draft => {
                draft.messages.push({
                    uuid: uuidv4(),
                    channel,
                    message
                });
            });
            return { ...state, ...newState };
        }
        case fromActions.RECEIVED_MESSAGE: {
            const { payload } = action;
            const { uuid } = payload;
            const newState = produce(state, draft => {
                draft.messages.splice(draft.messages.findIndex(m => m.uuid === uuid), 1);
            });
            return { ...state, ...newState };
        }
    }
    return state;
}

export const getChannelMessages = (state: EventbusChannelState) => state.messages;
