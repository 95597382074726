<div class="page__section  -small-bottom-padding -no-margin" id="documentinfo">
    <section id="message">
        <section id="info" >
            <div class="row wysiwyg" *ngIf="protocol.protocol_status.originalProtocol && version == 'Current'">
                <div class="col-7">
                    <ng-container
                        [ngTemplateOutlet]='originalProtocolInfo'>
                    </ng-container>
                </div>
                <div class="col-5">
                    <ng-container
                        [ngTemplateOutlet]='thisProtocolInfo'>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="!protocol.protocol_status.originalProtocol || version !== 'Current'"
                [ngTemplateOutlet]='thisProtocolInfo'>
            </ng-container>
        </section>        
    </section>
    <section *ngIf="displayPreviewButton || protocol.protocol_actions?.length" id="actions">
        <h4 *ngIf="protocol.infoBox.footerTitle" [innerHTML]="safeHtml(protocol.infoBox.footerTitle)"></h4>
        <p *ngIf="protocol.infoBox.footer" [innerHTML]="safeHtml(protocol.infoBox.footer)"></p>
        <a *ngIf="displayPreviewButton"
           class="btn btn-info cursor-pointer mr-2"
           role="button"
           [routerLink]="['/protocols', protocol.id]"
           [queryParams]="{ version: 'Preview' }"
           target="_blank"
        >
            Preview
        </a>
        <ng-container *ngFor="let action of protocol.protocol_actions">
            <div *ngIf="action.dashboards" ngbDropdown class="d-inline-block">
                <button class="btn btn-info mr-2" id="dropdown-dashboard" ngbDropdownToggle>
                    {{action.text}}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdown-dashboard">
                    <button *ngFor="let dashboard of action.dashboards" ngbDropdownItem
                            (click)="handleClickAction(action, dashboard.categoryId)">{{dashboard.label}}</button>
                </div>
            </div>
            <a *ngIf="action.action === 'rejectDraft'"
                class="btn btn-info cursor-pointer mr-2"
                role="button"
                (click)="handleRejectAction()"
             >
                 {{action.text}}
            </a>
            <a *ngIf="!action.dashboards && action.action != 'rejectDraft'"
               class="btn btn-info cursor-pointer mr-2"
               role="button"
               (click)="handleClickAction(action)"
            >
                {{action.text}}
            </a>
        </ng-container>
    </section>
</div>

<ng-template #originalProtocolInfo>
    <h3>{{ 'INFOBOX.ORIGINAL.HEADER' | translate}}</h3>
    <div class="row">
        <div class="col-3" *ngIf="protocol.protocol_status.originalProtocol.originalOwners.size > 0 && protocol.protocol_status.originalProtocol.originalOwners[0].organizationLogo">
            <img [src]="protocol.protocol_status.originalProtocol.originalOwners[0].organizationLogo" 
                class="owner-image"
            />
        </div>
        <div class="col-9">
            <p>{{ 'INFOBOX.ORIGINAL.INTRO' | translate}}: {{protocol.protocol_status.originalProtocol.title}}</p>
            
                <ul>
                    <li *ngFor="let author of protocol.protocol_status.originalProtocol.originalOwners">
                        {{author.name}} {{author.role}}
                    </li>
                    <li *ngIf="protocol.protocol_status.originalProtocol.Published_to_current_timestamp">
                        {{'INFOBOX.ORIGINAL.DATE' | translate}} {{protocol.protocol_status.originalProtocol.Published_to_current_timestamp}}
                    </li>
                </ul>
            

        </div>
    </div>
</ng-template>

<ng-template #thisProtocolInfo>
    <h3 *ngIf="protocol.infoBox.title" [innerHTML]="safeHtml(protocol.infoBox.title)"></h3>
    <p *ngIf="protocol.infoBox.intro" [innerHTML]="safeHtml(protocol.infoBox.intro)"></p>

    <ng-container *ngIf="protocol.infoBox.linkIntro && protocol.infoBox.linkText">
        <p [innerHTML]="safeHtml(protocol.infoBox.linkIntro)"></p>
        <p>
            <ng-container *ngIf="protocol.infoBox.url.startsWith('https://'); else innerLink">
                <a class="btn btn-info" role="button"
                   (click)="onClickUrl(protocol.infoBox.url)">{{protocol.infoBox.linkText | titlecase}}</a>
            </ng-container>
            <ng-template #innerLink>
                <a class="btn btn-info" role="button"
                   [routerLink]="protocol.infoBox.url">{{protocol.infoBox.linkText | titlecase}}</a>
            </ng-template>
        </p>
    </ng-container>

    <h4>Status</h4>
    <ul>
        <li class="mb-2">
            <span class="ml-2">{{status}}</span>
        </li>
        <li class="mb-2" *ngFor="let status of protocolStatuses">
            <span class="ml-2">{{status.k}}: {{status.v}}</span>
        </li>
        <li *ngIf="protocol.protocol_status.changed_paragraphs?.length">
                <span (click)="onClickToggleListChangesPar()"
                      class="table__link subnavigation__link cursor-pointer">
                    Changed paragraphs: {{ protocol.protocol_status.changed_paragraphs.length }}
                </span>
        </li>
        <li *ngIf="protocol.protocol_status.new_paragraphs?.length">
                <span (click)="onClickToggleListChangesPar()"
                      class="table__link subnavigation__link cursor-pointer">
                    New paragraphs: {{ protocol.protocol_status.new_paragraphs.length }}
                </span>
        </li>
        <li *ngIf="protocol.protocol_status.deleted_paragraphs?.length">
                <span (click)="onClickToggleListChangesPar()"
                      class="table__link subnavigation__link cursor-pointer">
                     Deleted paragraphs: {{ protocol.protocol_status.deleted_paragraphs.length }}
                </span>
        </li>
    </ul>

    <ng-container *ngIf="version !== 'Current' && protocol.ownerlist?.length">
        <h4>{{'SETTINGS.OWNER' | translate}}</h4>
        <div class="dashboard-items-container">
            <div class="dashboard-items row">
                <div class="dashboard__item" *ngFor="let owner of protocol.ownerlist">
                    <article class="card">
                        <header class="card__header min-height-0">
                            <span class="card__header-heading" [innerHTML]="owner.name"></span>
                        </header>
                    </article>
                </div>
            </div>
        </div>
    </ng-container>        
    
    <ng-container *ngIf="version !== 'Current' && protocol.authorlist?.length">
        <h4>{{'SETTINGS.AUTHORS' | translate}}</h4>
        <div class="dashboard-items-container">
            <div class="dashboard-items row">
                <div class="dashboard__item" *ngFor="let author of protocol.authorlist">
                    <article class="card">
                        <header class="card__header min-height-0">
                            <span class="card__header-heading" [innerHTML]="author.name"></span>
                        </header>
                    </article>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="version !== 'Current' && protocol.protocol_status.review_board?.length && protocol.reviewerlist?.length == 0">
        <h4>Review Board</h4>
        <div class="dashboard-items-container">
            <div class="dashboard-items row">
                <div class="dashboard__item" *ngFor="let reviewer of protocol.protocol_status.review_board">
                    <article class="card">
                        <header class="card__header min-height-0">
                            <span class="card__header-heading"> {{ reviewer.name }} </span>
                        </header>
                    </article>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="version !== 'Current' && protocol.reviewerlist?.length">
        <h4>{{'SETTINGS.PROTOCOLREVIEWBOARD' | translate}}</h4>
        <div class="dashboard-items-container">
            <div class="dashboard-items row">
                <div class="dashboard__item" *ngFor="let reviewer of protocol.reviewerlist">
                    <article class="card">
                        <header class="card__header min-height-0">
                            <span class="card__header-heading"> {{ reviewer.name }} </span>
                        </header>
                    </article>
                </div>
            </div>
        </div>
    </ng-container>


    <section [ngbCollapse]="!isListChangesParCollapsed">
        <div>
            <h4>Table of changes</h4>
            <section class="page__section -no-bottom">
                <div class="table-responsive">
                    <table class="table -aliiTable all_changes_from_draft">
                        <thead>
                        <tr>
                            <th class="table__text table__title table__column-40" scope="col">Paragraph</th>
                            <th class="table__text table__title table__column-20" scope="col">Status</th>
                            <th class="table__text table__column-20" scope="col">Comments</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            *ngFor="
                                        let parChange of protocol.protocol_status.changed_paragraphs;
                                        trackBy: trackByFn
                                    "
                        >
                            <td class="table__text table__title table__actions">
                                        <span
                                            (click)="onClickChangeParTitle(parChange.id)"
                                            class="table__link subnavigation__link"
                                        >
                                            {{ parChange.title }}
                                        </span>
                            </td>
                            <td class="table__text">Changed</td>
                            <td class="table__text">
                                <div class="button-group -borderless -toolbar">
                                    <a
                                        class="button -nopaddingLeft"
                                        (click)="onClickChangeParTitle(parChange.id)"
                                    >
                                        <span class="icon-alii-message-square"></span>
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr
                            *ngFor="
                                        let parChangeNew of protocol.protocol_status.new_paragraphs;
                                        trackBy: trackByFn
                                    "
                        >
                            <td class="table__text table__title table__actions">
                                        <span
                                            (click)="onClickChangeParTitle(parChangeNew.id)"
                                            class="table__link subnavigation__link"
                                        >
                                            {{ parChangeNew.title }}
                                        </span>
                            </td>
                            <td class="table__text">Added</td>
                            <td class="table__text">
                                <div class="button-group -borderless -toolbar">
                                    <a
                                        class="button -nopaddingLeft"
                                        (click)="onClickChangeParTitle(parChangeNew.id)"
                                    >
                                        <span class="icon-alii-message-square"></span>
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr
                            *ngFor="
                                        let parChangeDeleted of protocol.protocol_status.deleted_paragraphs;
                                        trackBy: trackByFn
                                    "
                        >
                            <td class="table__text table__title table__actions">
                                        <span
                                            (click)="onClickChangeParTitle(parChangeDeleted.id)"
                                            class="table__link subnavigation__link"
                                        >
                                            {{ parChangeDeleted.title }}
                                        </span>
                            </td>
                            <td class="table__text">Deleted</td>
                            <td class="table__text">
                                <div class="button-group -borderless -toolbar">
                                    <a
                                        class="button -nopaddingLeft"
                                        (click)="onClickChangeParTitle(parChangeDeleted.id)"
                                    >
                                        <span class="icon-alii-message-square"></span>
                                    </a>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
        <hr class="page__divider" />
    </section>


</ng-template>
