import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromTeams from '../reducers/teams.reducer';

export const getTeamsState = createSelector(
    fromFeature.getOrganisationFeatureState,
    (state: fromFeature.OrganisationsFeatureState) => state.teams
);
export const getTeams = createSelector(
    getTeamsState,
    fromTeams.getTeams
);

export const getTeamsLoaded = createSelector(
    getTeamsState,
    fromTeams.getTeamsLoaded
);

export const getTeamsLoading = createSelector(
    getTeamsState,
    fromTeams.getTeamsLoading
);
