import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Output,
    OnDestroy,
    OnInit
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { GetOutcome, GetOutcomeRO } from '@alii-web/models/outcome.interface';
import { TreeviewConfig, TreeviewItem } from '../../../../shared';
import { convertTreeview } from '../../components/libs';
import { RenderHTML } from '../../../literatures/components/reviews/render-html.component';
import { EditHTML } from '../../../literatures/components/reviews/edit-html.component';

export interface OutcomeTreeviewModalData {
    outcome: GetOutcome;
    notifyGetOutcomeRO$: BehaviorSubject<GetOutcomeRO>;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-outcome-treeview-modal',
    templateUrl: './outcome-treeview.component.html',
    styleUrls: ['./outcome-treeview.component.scss']
})
export class OutcomeTreeviewModalComponent implements OnInit, OnDestroy {

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    data: OutcomeTreeviewModalData;

    outcomes: GetOutcome[] = [];

    config = TreeviewConfig.create({
        hasAllCheckBox: true,
        hasFilter: false,
        hasCollapseExpand: false,
        decoupleChildFromParent: false,
        maxHeight: 500
    });

    items: TreeviewItem[][] = [];

    loading: boolean;

    subscriptions: Subscription[] = [];
    activeTab: string;

    frameworkComponents;

    constructor(
        public activeModal: NgbActiveModal, 
        private cdr: ChangeDetectorRef, 
        private sanitizer: DomSanitizer
        ) {this.frameworkComponents = {
            renderHTML: RenderHTML,
            editHTML: EditHTML,
          };
        }

    ngOnInit() {
        this._handleOutcomes();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }


    // eslint-disable-next-line
    onSelectedChange(_event: any, _index: number) {}

    // eslint-disable-next-line
    onFilterChange(_event: any, _index: number) {}

    handleEventBus(event) {
        const { type, payload } = event;
        switch (type) {
            case 'onClickArticle':
                this.onClickArticle(payload);
                break;
            default:
                this.eventBus.emit(event);
                break;
        }
    }

    defaultColDef: any = {
        resizable: true
      };

    onUpdateKQ() {
        const action = {
            type: 'handleUpdateKeyQuestion',
            payload: {
                ppdId: this.data.outcome.id
            }
        };
        this.eventBus.emit(action);
    }

    toggleShowSources(conclusionId) {
        this.sourcesVisible[ conclusionId ] = !this.sourcesVisible[ conclusionId ];
    }

    formatGradeKey(key: string) {
        return key.replace(/_/g, ' ').replace('GRADE - ', '');
    }

    sourcesVisible( conclusionId) {
        return conclusionId in this.sourcesVisible && this.sourcesVisible[ conclusionId ];
    }

    onClickArticle(payload) {
        const { articleId } = payload;
        const action = {
            type: 'handleLoadProtocolArticle',
            payload: {
                protocolId: 0, // why?
                articleId
            }
        };
        this.eventBus.emit(action);
    }

    

    summaryOfFinding(finding) {
        const ignoreColumns = ['action', 'id', 'user', 'user_name', 'status', 'actionLabel'];

        return Object.keys(finding)
            .filter(key => !ignoreColumns.includes(key))
            .filter(key => finding[key] !== null)
            .filter(key => finding[key].toString() !== '')
            .map(key => {
                // TODO: fix this in backend
                const value = finding[key].toString().replace('illegal reference: ', '');
                return {
                    key,
                    value
                };
            });
    }

    numberToList(num) {
        return Array.from(Array(+num), (x, i) => i);
    }
    
    safeText(text): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(text);
    }

    private _handleOutcomes() {
        this.loading = true;

        this.subscriptions.push(
            this.data.notifyGetOutcomeRO$.subscribe(getOutcomeRO => {
                if (getOutcomeRO) {
                    if (getOutcomeRO.Outcome) {
                        this.outcomes.push(getOutcomeRO.Outcome);
                    }
                    if (getOutcomeRO.relatedOutcome) {
                        this.outcomes.push(getOutcomeRO.relatedOutcome);
                    }
                    
                    this.outcomes.forEach(outcome => {
                        if(outcome.branches) {
                            this.items.push(convertTreeview(outcome.branches, true));
                        }
                    });
                    this.cdr.markForCheck();
                    this.loading = false;

                    if (this.outcomes.length > 0 &&  this.outcomes[0].text || this.outcomes[0].expanded_text) { 
                        this.activeTab = 'content';
                    } else if (this.outcomes.length > 0 && this.outcomes[0].keyQuestionTitle) {
                        this.activeTab = 'details';
                    }
                    else {
                        this.activeTab = 'situations';
                    };
                }
            })
        );

    }
}
