import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

// Translation stuff, see: https://github.com/ngx-translate/core
import { TranslateModule } from '@ngx-translate/core';

import { NavigationComponent } from './navigation/navigation.component';
import { LabelToIconPipe } from '../pipes/label-to-icon.pipe';
import { AvatarComponent } from './avatar/avatar.component';
import { SearchBoxModule } from '../modules/search/modules';
import { LanguageSwitcherComponent } from '../components';
import { SpinnerLoadingComponent } from './spinner-loading/spinner-loading.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        RouterModule,
        SearchBoxModule,
        TypeaheadModule.forRoot(),
        TranslateModule.forChild({ extend: true })
    ],
    declarations: [
        AvatarComponent,
        HeaderComponent,
        LabelToIconPipe,
        LanguageSwitcherComponent,
        NavigationComponent,
        SpinnerLoadingComponent
    ],
    exports: [
        AvatarComponent,
        HeaderComponent,
        CommonModule,
        FormsModule,
        NavigationComponent,
        RouterModule,
        SpinnerLoadingComponent
    ]
})
export class PartialsModule {}
