import * as fromShop from '../actions/shop-template-set.action';

import { ShopTemplateSet, ShopTemplateSetUpdateRO } from '../../../../models/shop-template-set.interface';

export interface ShopTemplateSetState {
    data: ShopTemplateSet;
    loaded: boolean;
    loading: boolean;
    error: any;
    updated: ShopTemplateSetUpdateRO;
}

export const initialState: ShopTemplateSetState = {
    data: null,
    loaded: false,
    loading: false,
    error: null,
    updated: null
};

export function reducer(state = initialState, action: fromShop.StoreTemplateSetAction): ShopTemplateSetState {
    switch (action.type) {
        case fromShop.LOAD_SHOP_TEMPLATE_SET: {
            return initialState;
        }

        case fromShop.LOAD_SHOP_TEMPLATE_SET_SUCCESS: {
            return {
                ...state,
                loading: false,
                loaded: true,
                data: action.payload
            };
        }

        case fromShop.LOAD_SHOP_TEMPLATE_SET_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            };
        }

        case fromShop.UPDATE_SHOP_TEMPLATE_SET: {
            return {
                ...state,
                loaded: false,
                loading: true,
                error: null,
                updated: null
            };
        }

        case fromShop.UPDATE_SHOP_TEMPLATE_SET_SUCCESS: {
            return {
                ...state,
                loading: false,
                loaded: true,
                data: {
                    ...state.data,
                    ...action.payload
                },
                updated: action.payload
            };
        }

        case fromShop.UPDATE_SHOP_TEMPLATE_SET_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false,
                updated: action.payload
            };
        }
    }
    return state;
}

export const getShopTemplateSet = (state: ShopTemplateSetState) => state.data;
export const getShopTemplateSetLoading = (state: ShopTemplateSetState) => state.loading;
export const getShopTemplateSetLoaded = (state: ShopTemplateSetState) => state.loaded;
export const updateShopTemplateSet = (state: ShopTemplateSetState) => state.updated;
export const updateShopTemplateSetLoading = (state: ShopTemplateSetState) => state.loading;
export const updateShopTemplateSetLoaded = (state: ShopTemplateSetState) => state.loaded;
