import { 
    Component, 
    Input, 
    OnInit, 
    EventEmitter, 
    Output,} from '@angular/core';
import { Protocol } from '../../../../../models/protocol.interface';

@Component({
    selector: 'alii-web-protocol-literature',
    templateUrl: './protocol-literature.component.html',
    styleUrls: ['./protocol-literature.component.scss']
})
export class ProtocolLiteratureComponent implements OnInit {
    @Input() 
    protocol: Protocol;
    
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
    }

 
    onClickArticle(articleId) {
        const action = {
            type: 'handleLoadProtocolArticle',
            payload: {
                protocolId: this.protocol.id,
                articleId
            }
        };
        this.eventBus.emit(action);
    }

}
