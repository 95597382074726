/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Injectable, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { SortColumn, SortDirection } from './search-sortable-header.directive';
import { DataGridRow } from './search-data-grid.model';
import { SearchPage } from '@alii-web/modules/search/models';

interface SearchResult {
    rows: DataGridRow[];
    total: number;
}

interface State {
    rows: DataGridRow[];
    page: number;
    pageSize: number;
    pageSizes: number[];
    searchTerm: string;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
}

const PAGE = 1;
const PAGESIZE = 50;
const PAGESIZES = [25, 50, 100, 250, 1000];

@Injectable({ providedIn: 'root' })
export class SearchDataGridService {
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _rows$ = new BehaviorSubject<DataGridRow[]>([]);
    private _total$ = new BehaviorSubject<number>(0);

    private _initState: State = {
        rows: [],
        page: PAGE,
        pageSize: PAGESIZE,
        pageSizes: PAGESIZES,
        searchTerm: '',
        sortColumn: '',
        sortDirection: ''
    };

    private _state: State;

    constructor(private pipe: DecimalPipe) {
        this._state = this._initState;
        this._search$
            .pipe(
                tap(() => this._loading$.next(true)),
                debounceTime(200),
                switchMap(() => this._search()),
                delay(200),
                tap(() => this._loading$.next(false))
            )
            .subscribe(result => {
                this._rows$.next(result.rows);
                this._total$.next(result.total);
            });
    }

    init(rows: DataGridRow[] = [], page = PAGE, pageSize = PAGESIZE, pageSizes = PAGESIZES) {
        this._state = {
            ...this._initState,
            rows,
            page,
            pageSize,
            pageSizes
        };

        this._search$.next();
    }

    get rows$() {
        return this._rows$.asObservable();
    }

    get total$() {
        return this._total$.asObservable();
    }

    get loading$() {
        return this._loading$.asObservable();
    }

    get rows() {
        return this._state.rows.length;
    }

    get page() {
        return this._state.page;
    }

    get pageSize() {
        return this._state.pageSize;
    }

    get pageSizes() {
        return this._state.pageSizes;
    }

    get searchTerm() {
        return this._state.searchTerm;
    }

    set page(page: number) {
        this._set({ page });
    }

    set pageSize(pageSize: number) {
        this._set({ pageSize });
    }

    set searchTerm(searchTerm: string) {
        this._set({ searchTerm });
    }

    set sortColumn(sortColumn: SortColumn) {
        this._set({ sortColumn });
    }

    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
    }

    set searchPage(searchPage: SearchPage) {
        const patch = {};
        if (this._state.page !== searchPage.page) {
            patch['page'] = searchPage.page;
        }
        if (this._state.pageSize !== searchPage.pageSize) {
            patch['pageSize'] = searchPage.pageSize;
        }
        if (this._state.sortDirection !== searchPage.sortEvent.sortDirection) {
            patch['sortDirection'] = searchPage.sortEvent.sortDirection;
        }
        if (this._state.sortColumn !== searchPage.sortEvent.sortColumn) {
            patch['sortColumn'] = searchPage.sortEvent.sortColumn;
        }
        this._set(patch);
    }

    private _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<SearchResult> {
        const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

        // 1. sort
        let rows = this._sort(this._state.rows, sortColumn, sortDirection);

        // 2. filter
        rows = rows.filter(row => this._matches(row, searchTerm, this.pipe));
        const total = rows.length;

        // 3. paginate
        rows = rows.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        return of({ rows: rows, total });
    }

    private _compare = (v1: string | number | string[] | number[], v2: string | number | string[] | number[]) => {
        if (Array.isArray(v1)) {
            v1 = v1.join(',');
        }
        if (Array.isArray(v2)) {
            v2 = v2.join(',');
        }
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
    };

    private _sort(rows: DataGridRow[], column: SortColumn, direction: string): DataGridRow[] {
        if (direction === '' || column === '') {
            return rows;
        } else {
            return [...rows].sort((a, b) => {
                const res = this._compare(a[column], b[column]);
                return direction === 'asc' ? res : -res;
            });
        }
    }

    private _matches(row: DataGridRow, term: string, pipe: PipeTransform) {
        let found = false;
        term = term.toLowerCase();

        Object.values(row).forEach(value => {
            if (!found) {
                switch (typeof value) {
                    case 'string':
                        found = value.toLowerCase().includes(term);
                        break;
                    case 'number':
                        found = pipe.transform(value).includes(term);
                        break;
                    default:
                        if (Array.isArray(value)) {
                            found = value.join(',').includes(term);
                        }
                        break;
                }
            }
        });

        return found;
    }
}
