import * as fromRegister from '../actions/register.action';
import { FormField, RegisterDTO, RegisterRO } from '../../../../models/register.model';

export interface RegisterState {
    init: {
        formFields: FormField[];
        banner: string;
        queryParams: null;
        loaded: boolean;
        loading: boolean;
        error: string;
    };
    register: RegisterDTO;
    result: RegisterRO;
    loaded: boolean;
    loading: boolean;
    error: string;
}

export const initialState: RegisterState = {
    init: {
        formFields: [],
        banner: null,
        queryParams: null,
        loaded: false,
        loading: false,
        error: null
    },
    register: {
        firstName: null,
        lastName: null,
        email: null,
        telephone: null,
        hospitalId: null,
        hospitalName: null,
        status: null,
        sector: null,
        teamId: null,
        teamName: null,
        acceptTerms: false
    },
    result: {
        message: null,
        userId: null,
        welcome_message: null
    },
    loaded: false,
    loading: false,
    error: null
};

export function reducer(state = initialState, action: fromRegister.RegisterAction): RegisterState {
    switch (action.type) {
        case fromRegister.REGISTER_INIT: {
            const {
                payload: { queryParams }
            } = action as any;
            return {
                ...initialState,
                init: {
                    ...state.init,
                    formFields: [],
                    queryParams,
                    loaded: false,
                    loading: true
                }
            };
        }

        case fromRegister.REGISTER_INIT_SUCCESS: {
            const {
                payload: { formFields, banner }
            } = action as any;
            return {
                ...state,
                init: {
                    ...state.init,
                    formFields: [...formFields],
                    banner,
                    loaded: true,
                    loading: false
                }
            };
        }

        case fromRegister.REGISTER_INIT_FAIL: {
            const {
                payload: { message }
            } = action as any;

            return {
                ...state,
                init: {
                    ...state.init,
                    loaded: false,
                    loading: false,
                    error: message
                }
            };
        }

        case fromRegister.REGISTER: {
            const {
                payload: { register }
            } = action as any;
            return {
                ...state,
                register,
                loaded: false,
                loading: true,
                error: null
            };
        }

        case fromRegister.REGISTER_SUCCESS: {
            const {
                payload: { message, userId, welcome_message }
            } = action as any;
            return {
                ...state,
                result: {
                    message,
                    userId,
                    welcome_message
                },
                loaded: true,
                loading: false,
                init: {
                    formFields: [],
                    banner: null,
                    queryParams: null,
                    loaded: false,
                    loading: false,
                    error: null
                }
            };
        }

        case fromRegister.REGISTER_FAIL: {
            const {
                payload: { message }
            } = action as any;

            return {
                ...state,
                loaded: false,
                loading: false,
                error: message
            };
        }
    }
    return state;
}

export const getRegisterInit = (state: RegisterState) => state.init;
export const getRegisterInitLoading = (state: RegisterState) => state.init.loading;
export const getRegisterInitLoaded = (state: RegisterState) => state.init.loaded;
export const getRegisterInitError = (state: RegisterState) => state.init.error;
export const getRegisterLoading = (state: RegisterState) => state.loading;
export const getRegisterLoaded = (state: RegisterState) => state.loaded;
export const getRegisterError = (state: RegisterState) => state.error;
export const getRegisterWelcomeMessage = (state: RegisterState) => state.result.welcome_message;
