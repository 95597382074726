import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationService } from 'apps/web/src/app/services';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UploadService } from '../../../../services';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'upload-image-modal',
    templateUrl: './upload-image.component.html'
})
export class UploadImageModalComponent implements OnInit {
    form: FormGroup;
    uploadResponse: any = { status: '', message: '', filePath: '', error: '' };
    file: any;
    @Input() type: string;
    @Input() entity: any;

    @Output()
    eventBus?: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private uploadService: UploadService,
        private organisationService: OrganisationService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            file: ['']
        });
    }
    onFileChange(event) {
        if (event.target.files.length > 0) {
            this.file = event.target.files[0];
            this.form.get('file').setValue(this.file);
        }
    }

    onSubmit(type) {
        const formData = new FormData();
        formData.append('file', this.form.get('file').value);

        this.uploadService
            .uploadToBucket({ formData })
            .pipe(
                map(response => {
                    const { url = null } = response;
                    if (url) {
                        return this.setImageUrl(response.url);
                    }
                })
            )
            .subscribe();
    }

    setImageUrl(url) {
        if(this.type === 'team') {
            let payload = {
                id: this.entity.id,
                name: this.entity.name,
                status: this.entity.status,
                language: this.entity.language,
                accountType: this.entity.accountType,
                description: this.entity.description,
                imageUrl: url 
            }
            this.organisationService.UpdateTeam( { payload } ).subscribe(); // this should be upload team
        } else {
            let payload = {
                id: this.entity.id,
                name: this.entity.name,
                ssoName: this.entity.ssoName,
                ssoUrl: this.entity.ssoUrl,
                imageUrl: url 
            }
            this.organisationService.UpdateOrganization({payload}).subscribe(); // this should be upload team
        }
        location.reload();
        this.activeModal.close({ url });
    }
}
