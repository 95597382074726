import { ElementRef, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';

import { SelectedFootnote } from '../../../../models/paragraph.interface';

/**
 * This function will find all spans having class 'footnote' contained within the given host element and replace them
 * with a linkable superscript footnote. The span has the following format:
 *
 *      `<span class='footnote' id='' elementid='' articleid='{articleid|url}' type='{article|link}'>*</span>
 *
 *  The enclosed text represented by the asterisk is replaced by a subscript tag with the following format:
 *
 *      `<sup><a href='#'>icon</sup>` where icon is <span class='icon-alii-book'></span>
 *
 *  where a click handler is attached which will call `selectedFootnote$.next()` with the extracted values of id,
 *  elementid, articleid and type.
 *
 *  Note that id and elementid are not used for the time being.
 *
 * @param {ElementRef} hostElement
 * @param {Renderer2} renderer
 * @param {Subject<SelectedFootnote>>} selectedFootnote$
 * @returns {void}
 *
 */
export const renderFootnote = (
    hostElement: ElementRef,
    renderer: Renderer2,
    selectedFootnote$: Subject<SelectedFootnote>
) => {
    const el = hostElement.nativeElement;
    if (el) {
        const spans = el.querySelectorAll('.footnote');
        const len = spans.length;
        if (spans && len) {
            // IMPORTANT: IE11 does NOT support forEach()!
            for (let i = 0; i < len; i++) {
                const span = spans[i];
                renderer.removeClass(span, 'footnote');
                if (span.firstChild) {
                    renderer.removeChild(span, span.firstChild);
                }
                const id = span.getAttribute('id');
                const elementId = span.getAttribute('data-elementid');
                const articleId = span.getAttribute('data-articleid');
                const type = span.getAttribute('data-type');
                const sup = renderer.createElement('sup');
                const a = renderer.createElement('a');
                renderer.listen(a, 'click', event => {
                    // Footnote click will open bookmarks accordion and highlight fade associated
                    // link in the paragraph footer.
                    selectedFootnote$.next({ id, elementId, articleId, type });
                    event.stopPropagation();
                    return false;
                });
                const icon = renderer.createElement('span');
                renderer.addClass(icon, 'icon-arrow-right');
                renderer.setAttribute(a, 'href', '#');
                renderer.setStyle(a, 'text-decoration', 'none');
                renderer.appendChild(a, icon);
                renderer.appendChild(sup, a);
                renderer.appendChild(span, sup);
            }
        }
    } else {
        console.error('renderFootnote() cannot find host element');
    }
};
