import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromShop from '../reducers/shop.reducer';

export const getShopState = createSelector(
    fromFeature.getShopFeatureState,
    (state: fromFeature.ShopFeatureState) => {
        return state.shop;
    }
);

export const getShop = createSelector(
    getShopState,
    fromShop.getShop
);

export const getShopLoaded = createSelector(
    getShopState,
    fromShop.getShopLoaded
);

export const getShopLoading = createSelector(
    getShopState,
    fromShop.getShopLoading
);

export const getShopHospital = createSelector(
    getShopState,
    fromShop.getShopHospital
);
