import { Action } from '@ngrx/store';

export const CREATE_FLOWCHART = '[Flowchart Builder] Create Flowchart';
export const CREATE_FLOWCHART_FAIL = '[Flowchart Builder] Create Flowchart Fail';
export const CREATE_FLOWCHART_SUCCESS = '[Flowchart Builder] Create Flowchart Success';

export class CreateFlowchart implements Action {
    readonly type = CREATE_FLOWCHART;

    constructor(public payload: any) {
    }
}

export class CreateFlowchartFail implements Action {
    readonly type = CREATE_FLOWCHART_FAIL;

    constructor(public payload: any) {
    }
}

export class CreateFlowchartSucces implements Action {
    readonly type = CREATE_FLOWCHART_SUCCESS;

    constructor(public payload: { protocolId: string, scheme: string }) {
    }
}

export const LOAD_CARDS = '[Flowchart Builder] Load Cards';
export const LOAD_CARDS_FAIL = '[Flowchart Builder] Load Cards Fail';
export const LOAD_CARDS_SUCCESS = '[Flowchart Builder] Load Cards Success';

export class LoadCards implements Action {
    readonly type = LOAD_CARDS;

    constructor(public payload: { cards: any[]; startPoint: string; scheme: string, yesText: string, noText: string }) {
    }
}

export const CREATE_STARTPOINT = '[Flowchart Builder] Create StartPoint';
export const CREATE_STARTPOINT_FAIL = '[Flowchart Builder] Create StartPoint Fail';
export const CREATE_STARTPOINT_SUCCESS = '[Flowchart Builder] Create StartPoint Success';

export class CreateStartpoint implements Action {
    readonly type = CREATE_STARTPOINT;

    constructor(public payload: { ppdId: string; protocolId: string }) {
    }
}

export class CreateStartpointFail implements Action {
    readonly type = CREATE_STARTPOINT_FAIL;

    constructor(public payload: any) {
    }
}

export class CreateStartpointSucces implements Action {
    readonly type = CREATE_STARTPOINT_SUCCESS;

    constructor(public payload: { protocolId: string }) {
    }
}

export const UPDATE_CARD = '[Flowchart Builder] Update Card';
export const UPDATE_CARD_FAIL = '[Flowchart Builder] Update Card Fail';
export const UPDATE_CARD_SUCCESS = '[Flowchart Builder] Update Card Success';

export class UpdateCard implements Action {
    readonly type = UPDATE_CARD;

    constructor(public payload: any) {
    }
}

export class UpdateCardFail implements Action {
    readonly type = UPDATE_CARD_FAIL;

    constructor(public payload: any) {
    }
}

export class UpdateCardSucces implements Action {
    readonly type = UPDATE_CARD_SUCCESS;

    constructor(public payload: { protocolId: string }) {
    }
}

export const DELETE_FLOWCHART = '[Flowchart Builder] Delate Flowchart';
export const DELETE_FLOWCHART_FAIL = '[Flowchart Builder] Delate Flowchart Fail';
export const DELETE_FLOWCHART_SUCCESS = '[Flowchart Builder] Delate Flowchart Success';

export class DeleteFlowchart implements Action {
    readonly type = DELETE_FLOWCHART;

    constructor(public payload: any) {
    }
}

export class DeleteFlowchartFail implements Action {
    readonly type = DELETE_FLOWCHART_FAIL;

    constructor(public payload: any) {
    }
}

export class DeleteFlowchartSucces implements Action {
    readonly type = DELETE_FLOWCHART_SUCCESS;

    constructor(public payload: { protocolId: string }) {
    }
}

export type FlowchartAction =
    | CreateFlowchart
    | CreateFlowchartFail
    | CreateFlowchartSucces
    | LoadCards
    | CreateStartpoint
    | CreateStartpointFail
    | CreateStartpointSucces
    | UpdateCard
    | UpdateCardFail
    | UpdateCardSucces
    | DeleteFlowchart
    | DeleteFlowchartFail
    | DeleteFlowchartSucces;
