import { Injectable } from '@angular/core';


@Injectable()
export class UserService {
    constructor() {}

    isAliiAdmin() {
        const aliiAdmin = "AliiAdmin";
        const token = localStorage.getItem('authHash');
        if(!token) {
            return false;
        }
        try {
            var payload = JSON.parse(atob(token.split('.')[1]))
            var roles = payload.role;
            if(Array.isArray(roles)) {
                if(roles.includes(aliiAdmin)) {
                    return true;
                }
            }

            if(roles === aliiAdmin) {
                return true;
            }
            return false;
        } catch (error) {
            return false
        }
    }
}