import {Component} from "@angular/core";

import {ICellRendererParams} from "@ag-grid-community/core";
import { ControllersService } from "ag-grid-community";

@Component({
   selector: 'render-html-component',
   styleUrls: ['./render-html-component.scss'],
   template: `
        <div 
            (click)=openMiniModal() 
            class="clipped"
            [innerHTML]='cellValue'
            >
         </div>
         <div 
            (click)=closeMiniModal() 
            (blur)=closeMiniModal() 
            class="miniModal"
            [hidden]="!showText"
            [innerHTML]='cellValue'
            >
        </div>
   `
})

export class RenderHTML {
    params: any;
    public cellValue: string;
    public showText: boolean;


   // gets called once before the renderer is used
   agInit(params: ICellRendererParams): void {
        this.params = params
        this.cellValue = this.getValueToDisplay(params);   
   }

   openMiniModal() {
       if(this.params.colDef.cellEditor != "editHTML") {
        const strippedHtml = this.cellValue.replace(/<[^>]+>/g, '');
        if(strippedHtml.length > 100) {     
                this.showText = true
                this.params.api.resetRowHeights()
                this.params.api.onRowHeightChanged() 
            }
        }

   }

    closeMiniModal() {
        this.showText = false
        this.params.api.resetRowHeights()
        this.params.api.onRowHeightChanged()
   }

   // gets called whenever the cell refreshes
   refresh(params: ICellRendererParams) {
       // set value into cell again
       this.cellValue = this.getValueToDisplay(params);
   }

   getValueToDisplay(params: ICellRendererParams) {
       return params.valueFormatted ? params.valueFormatted : params.value;
   }
}