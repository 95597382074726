import * as fromLogin from '../actions/login.action';
import { AuthService } from '../../../../services/auth.service';

import { SSO } from '../../../../models/sso.interface';

export interface SSOState {
    login: {
        form: {
            loading: boolean;
            loaded: boolean;
            isError: boolean;
            message: string;
        };
    };
    data: SSO[];
    loaded: boolean;
    loading: boolean;
}

export const initialState: SSOState = {
    login: {
        form: {
            loading: false,
            loaded: false,
            isError: false,
            message: ''
        }
    },
    data: [],
    loaded: false,
    loading: false
};

export function reducer(state = initialState, action: fromLogin.LoginAction): SSOState {
    switch (action.type) {
        case fromLogin.LOGIN: {
            return {
                ...state,
                login: {
                    form: {
                        ...state.login.form,
                        loading: true,
                        isError: false
                    }
                }
            };
        }

        case fromLogin.LOGIN_SUCCESS: {
            return {
                ...state,
                login: {
                    form: {
                        ...state.login.form,
                        loading: false,
                        loaded: true
                    }
                }
            };
        }

        case fromLogin.LOGIN_FAIL: {
            const {
                payload: { message }
            } = action as any;

            return {
                ...state,
                login: {
                    form: {
                        loading: false,
                        loaded: true,
                        isError: true,
                        message
                    }
                }
            };
        }
        case fromLogin.LOAD_SSO: {
            return { ...state, loading: true };
        }

        case fromLogin.LOAD_SSO_SUCCESS: {
            return { ...state, loading: false, loaded: true, data: action.payload };
        }

        case fromLogin.LOAD_SSO_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
    }
    return state;
}

export const getSSOOptions = (state: SSOState) => state.data;
export const getLoginFormLoading = (state: SSOState) => state.login.form.loading;
export const getLoginFormLoaded = (state: SSOState) => state.login.form.loaded;
export const getLoginFormIsError = (state: SSOState) => state.login.form.isError;
export const getLoginFormMessage = (state: SSOState) => state.login.form.message;
