import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';

import { Protocol } from '../../../../models/protocol.interface';
import { User } from '../../../../models/user.interface';
import { Literatures } from '../../../../models/literature.interface';

import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'protocol-detail-print',
    templateUrl: './protocol-detail-print.component.html'
})
export class ProtocolDetailPrintComponent {
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    loading = true;

    @Input()
    content: Protocol;

    @Input()
    isFlowchart: boolean;

    @Input()
    isChartView?: boolean;

    @Input()
    isTextView?: boolean;

    @Input()
    versionId?: string;

    @Input()
    version?: string;

    config: ExportAsConfig = {
        type: 'pdf', // the type you want to download
        elementIdOrContent: 'protocolContent' // the id of html/table element
    };
    constructor(private exportAsService: ExportAsService) {}

    printPdf() {
        this.exportAsService.save(this.config, this.content.title);
        this.exportAsService.get(this.config).subscribe(content => {
          });
    }


    closePrintPdf() {
        const action = { type: 'close-print', payload: {} };
        this.eventBus.emit(action);
    }

    trackByFn = (index, item) => item.id || index;
}
