import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromStore from '../../store';
import { User } from '../../../../models/user.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    profile$: Observable<User>;
    loading$: Observable<boolean>;
    userId: string;
    saved: boolean;
    isAllowedToEdit: boolean;

    constructor(
        private store: Store<fromStore.ProfileFeatureState>,
        private router: Router,
        private permissionsService: NgxPermissionsService,
        private route: ActivatedRoute
        ) {
        this.route.paramMap.subscribe(params => {
            this.userId = params.get('userId');
        });
    }

    ngOnInit() {
        if(!this.userId) {
            this.isAllowedToEdit = true
            this.loading$ = this.store.select(fromStore.getProfileLoading);
            this.profile$ = this.store.select(fromStore.getProfile);
            this.store.dispatch(new fromStore.LoadProfile('')); }
        else {
            this.loading$ = this.store.select(fromStore.getProfileLoading);
            this.profile$ = this.store.select(fromStore.getProfile);
            this.store.dispatch(new fromStore.LoadProfile(this.userId)); 
        }
    }
}
