import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from './../../store';
import { User } from '../../../../models/user.interface';
import * as fromService from '../../../../services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-profile-edit',
    providers: [fromService.DashboardService],
    templateUrl: './profile-edit.component.html'
})
export class ProfileEditComponent implements OnInit {
    profile$: Observable<User>;
    dashboards$: Observable<any>;
    loading$: Observable<boolean>;
    isProfile: boolean;
    userId: string;

    constructor(
        private store: Store<fromStore.ProfileFeatureState>,
        private DashboardService: fromService.DashboardService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.route.paramMap.subscribe(params => {
            this.userId = params.get('userId'); 
        });
    }

    ngOnInit() {
        this.dashboards$ = this.DashboardService.getDashboard();
        this.profile$ = this.store.select(fromStore.getProfile);
        this.store.dispatch(new fromStore.LoadProfile(''));
    }

    handleSubmit(event) {
        const data = event;
        if(!this.userId) { 
            data.myProfile = true
            }
        this.store.dispatch(new fromStore.UpdateProfile(data));

        // if(this.userId) {
        //    this.router.navigate(['team/users']);
        // } else {
        //     this.router.navigate(['profile']);
        // }
    }
}
