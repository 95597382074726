import { Action } from '@ngrx/store';

export enum ProtocolActionTypes {
    GetProtocol = '[Literature] Get Protocol',
    GetProtocolSucces = '[Literature] Get Protocol Success',
    GetProtocolFail = '[Literature] Get Protocol Fail'
}

export class GetProtocol implements Action {
    readonly type = ProtocolActionTypes.GetProtocol;

    constructor(public payload: { protocolId: string }) {}
}

export class GetProtocolSucces implements Action {
    readonly type = ProtocolActionTypes.GetProtocolSucces;

    constructor(public payload: any) {}
}

export class GetProtocolFail implements Action {
    readonly type = ProtocolActionTypes.GetProtocolFail;

    constructor(public payload: any) {}
}

export type ProtocolActions = GetProtocol | GetProtocolSucces | GetProtocolFail;
