<div class="modal-header">
    <h4 class="modal-title">{{'LITERATURE.ADDREFERENCEMODAL.TITLE' | translate}}</h4>
    
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p *ngIf="!search">{{'LITERATURE.ADDREFERENCEMODAL.TAGLINESELECT' | translate}}
    <br><br>
    <input type="text" placeholder="Filter resultaten..." class="form-control" (input)="onQuickFilterChanged($event)"></p>
    
    <div class="container">
        <ul class="nav nav-tabs mb-3" id="myTab" role="tablist" *ngIf="search">
            <li class="nav-item">
                <a
                    [ngClass]="{ active: activeTab === 'search-form' }"
                    class="nav-link"
                    role="tab"
                    (click)="onClickTab('search-form')"
                >
                    Search for article
                </a>
            </li>
            <li class="nav-item">
                <a
                    [ngClass]="{ active: activeTab === 'nonpubmed-form' }"
                    class="nav-link"
                    role="tab"
                    (click)="onClickTab('nonpubmed-form')"
                >
                    Non Pubmed Article
                </a>
            </li>
        </ul>
        <div class="tab-content">
            <ng-container *ngIf="sourceTable">
                <ag-grid-angular
                    *ngIf="filteredSourceTable[0].rowData && filteredSourceTable[0].rowData.length"
                    #agGrid0
                    style="width: 100%; height: 80vh;"
                    [alwaysShowHorizontalScroll]="true"
                    scrollbarWidth="10"
                    class="ag-theme-alpine x-scroll"
                    [rowData]="filteredSourceTable[0].rowData"
                    [columnDefs]="filteredSourceTable[0].columnDefs"
                    domLayout='normal'
                    [frameworkComponents]="frameworkComponents"
                    rowSelection='multiple'                       
                    (cellClicked)="showActions()"
                    >
                </ag-grid-angular>
                <ag-grid-angular
                    *ngIf="filteredSourceTable[1] && filteredSourceTable[1].rowData && filteredSourceTable[1].rowData.length"
                    #agGrid1
                    style="width: 100%; height: 80vh;"
                    class="ag-theme-alpine x-scroll"
                    [alwaysShowHorizontalScroll]="true"
                    scrollbarWidth="10"
                    [rowData]="filteredSourceTable[1].rowData"
                    [columnDefs]="filteredSourceTable[1].columnDefs"
                    domLayout='normal'
                    [frameworkComponents]="frameworkComponents"
                    rowSelection='multiple'                       
                    (cellClicked)="showActions()"
                    >
                </ag-grid-angular>

                <span *ngIf="getSelectedRowData()">
                    {{ getSelectedRowData().length }} {{'LITERATURE.SOURCESSELECTED' | translate}}
                </span>
                <button class="btn btn-info" type="button" (click)="onSubmitAddSources()">Submit</button>
                <button class="btn btn-default" type="button" (click)="onCancel()">Cancel</button>
                
            </ng-container>
            <ng-container *ngIf="!sourceTable">
                <div  [ngClass]="{ 'tab-pane fade': activeTab !== 'search-form' }">
                    <ng-form *ngIf="search" [formGroup]="searchForm" (keyup.enter)="onSubmitSearch()">
                        <div class="form-row">
                            <div class="col-10">
                                <input
                                    type="text"
                                    formControlName="query"
                                    class="form-control"
                                    placeholder="Search reference"
                                />
                            </div>
                            <div class="col">
                                <button type="submit" class="btn btn-info mb-2" (click)="onSubmitSearch()">Search</button>
                            </div>
                        </div>
                    </ng-form>
                    <div *ngIf="searchLoading" id="loadingSearchPubmed" style="display: block;">
                        <div class="spinner">
                            <div class="dot1"></div>
                            <div class="dot2"></div>
                        </div>
                    </div>

                    <div class="row" *ngIf="!searchLoading">
                            <div *ngIf="pubmeds.length === 0 && query !== ''">
                                There were no references found on PubMed matching the search criteria.
                            </div>
                            <ng-form [formGroup]="form" *ngIf="pubmeds.length > 0">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">-</th>
                                            <th scope="col">Reference Title</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let pubmed of pubmeds; let i = index; trackBy: trackByFn">
                                            <ng-container *ngIf=!isIncluded(pubmed)>
                                                <td scope="row">
                                                    <div class="form-check" *ngIf="pubmed.uid">
                                                        <input
                                                            class="form-check-input"
                                                            [formControlName]="pubmed.uid"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                    <div class="form-check" *ngIf="pubmed.id">
                                                        <input
                                                            class="form-check-input"
                                                            [formControlName]="pubmed.id"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </td>
                                                <td>{{ pubmed.label }}</td>
                                                <td>{{ pubmed.title }}</td>
                                                <td *ngIf="sourceTable">{{ showSourceInfo(pubmed.id) }}</td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>

                                <button class="btn btn-info" type="button" (click)="onSubmitAddReferences()">Submit</button>
                                <button class="btn btn-default" type="button" (click)="onCancel()">Cancel</button>
                            </ng-form>
                    </div>
                </div>
            </ng-container>
            <div [ngClass]="{ 'tab-pane fade': activeTab !== 'nonpubmed-form' }">
                <ng-form [formGroup]="nonPubmedForm" (keyup.enter)="onSubmitNonPubmedForm()">
                    
                    <div class="form-group">

                        <div class="form-group">
                            <label for="TitleInput">Title</label>
                            <input
                                formControlName="title"
                                type="text"
                                class="form-control"
                                id="TitleInput"
                                placeholder="Enter title"
                            />
                        </div>

                        <label for="urlInput">Link</label>
                        <input
                            formControlName="url"
                            type="text"
                            class="form-control"
                            id="urlInput"
                            placeholder="https://"
                        />
                    </div>

                    <div class="form-group">
                        <label for="authorInput">Author (optional)</label>
                        <input
                            formControlName="author"
                            type="text"
                            class="form-control"
                            id="authorInput"
                            placeholder="Enter author"
                        />
                    </div>
                    <div class="form-group">
                        <label for="YearInput">Year (optional)</label>
                        <input
                            formControlName="year"
                            type="text"
                            class="form-control"
                            id="YearInput"
                            placeholder="Enter year"
                        />
                    </div>

                    <button [disabled]="disabled" class="btn btn-info text-capitalize mr-1" type="submit"
                            (click)="onSubmitNonPubmedForm()">
                        Submit
                    </button>
                    <button class="btn btn-info text-capitalize" type="submit" (click)="onCancel()">Cancel</button>
                </ng-form>
            </div>
        </div>
    </div>
</div>
