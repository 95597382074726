<div id="navigation-sidemenu">
    <div id="header-branding" class="header__branding"><h1 class="logo">Alii</h1></div>
    <div class="header__navigation">
        <div class="header__sub-navigation">
            <alii-web-search-box
                *ngIf="current !== 'search'"
                id="main-navigation"
                placeholder="{{ 'NAVIGATION.SEARCH' | translate }}"
                [max]="maxSearchResults"
                [query]="query"
                [protocolId]="protocolId"
                [enterKey]="true"
                (focus)="onFocus()"
                (blur)="onBlur()"
                (select)="onSelect($event)"
                filter="topResults"
            ></alii-web-search-box>
            <div *ngIf="current === 'search'" class="search-box-placeholder"></div>
            <nav class="navigation">
                <ul class="navigation__items">
                    <li *ngFor="let tab of (tabs$ | async); let index = index;" class="navigation__item -mobile-compact">
                        <a ngbTooltip="{{( collapsed ? tab.title : '') | translate }}" class="navigation__link"
                           [class.-is-active]="current === tab.label" [routerLink]="tab.url">
                            <span class="navigation__icon"> <span class="{{ tab.label | labelToIcon }}"></span> </span>
                            <span class="navigation__label">{{ tab.title | translate }}</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="header__sub-navigation">
            <nav class="navigation">
                <ul class="navigation__items">
                    <li class="navigation__item -mobile-compact">
                        <a ngbTooltip="{{ (collapsed ? 'NAVIGATION.PROFILE' : '') | translate }}" class="navigation__link"
                           [class.-is-active]="current === 'profile'" routerLink="/profile">
                            <span class="navigation__icon"> <span class="icon-user"></span> </span>
                            <span class="navigation__label">{{ 'NAVIGATION.PROFILE' | translate}}</span>
                        </a>
                    </li>
                    <li class="navigation__item -mobile-compact">
                        <a ngbTooltip="{{(collapsed ? 'NAVIGATION.HELP' : '') | translate}}" class="navigation__link"
                           [class.-is-active]="current === 'help'" routerLink="/help">
                            <span class="navigation__icon"> <span class="icon-question-circle-o"></span> </span>
                            <span class="navigation__label">{{ 'NAVIGATION.HELP' | translate}}</span>
                        </a>
                    </li>
                    <li class="navigation__item -mobile-compact" id="nav-logout">
                        <a ngbTooltip="{{ (collapsed ? 'NAVIGATION.LOGOUT' : '') | translate }}" class="navigation__link cursor-pointer"
                           (click)="verifyLogout()">
                            <span class="navigation__icon"> <span class="icon-arrow-circle-o-left"></span> </span>
                            <span class="navigation__label">{{ 'NAVIGATION.LOGOUT' | translate}}</span>
                        </a>
                    </li>
                    <li class="navigation__item -mobile-compact" id="nav-toggleLanguage">
                        <a ngbTooltip="{{ (collapsed ? 'NAVIGATION.LANGUAGE' : '') | translate }}" class="navigation__link">
                            <span class="navigation__icon"> <span class="icon-globe"></span> </span>
                            <span class="navigation__label"><alii-web-language-switcher></alii-web-language-switcher></span>
                        </a>
                    </li>
                    <li class="navigation__item -desktop-only">
                        <a *ngIf="!collapsed" class="navigation__link cursor-pointer" (click)="collapse()">
                            <span class="navigation__icon"> <span class="icon-chevron-left"></span> </span>
                            <span class="navigation__label">{{ 'NAVIGATION.COLLAPSE' | translate }}</span>
                        </a>
                        <a *ngIf="collapsed" class="navigation__link cursor-pointer" ngbTooltip="{{ 'NAVIGATION.EXPAND' | translate }}"
                           (click)="expand()">
                            <span class="navigation__icon"> <span class="icon-chevron-right"></span> </span>
                            <span class="navigation__label"></span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
