import {
    Component,
    ChangeDetectionStrategy,
    Input,
    AfterViewInit,
    ViewChild,
    Output,
    EventEmitter,
    ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { ThemingService } from 'apps/web/src/app/services';

import * as flowchartLib from 'flowchart.js';

import { getFlowchartOptions } from '../../flowchart-builder/flowchart-area/flowchart-options';
import { initSvgPanZoom } from '../../libs';

const cn = 'ProtocolFlowchartComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'flowchart-detail',
    templateUrl: './flowchart-detail.component.html',
    styleUrls: ['./flowchart-detail.component.scss']
})
export class ProtocolFlowchartComponent implements AfterViewInit {
    @Input() scheme: string;
    @Input() flowchart: any;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('canvas')
    canvas: any;

    private chart: any;
    private svgEl: any;
    public zoomEl: any;
    public beforePan: any;
    public platformWidth: any;

    constructor(private router: Router, private hostElement: ElementRef, private themingService: ThemingService) {
    }

    public ngAfterViewInit() {
        let palette = this.themingService.getCurrentPalette()
        let primaryColor = ""
        let secondaryColor = ""
        palette.forEach(color => {
            if (color.name == '--primary-color') { primaryColor = color.hex }
            if (color.name == '--secondary-color') { secondaryColor = color.hex}
        })
        if (this.scheme && this.scheme !== '') {
            const flowchartOptions = getFlowchartOptions(this.flowchart, primaryColor, secondaryColor);
            this.chart = flowchartLib.parse(this.scheme);
            this.chart.drawSVG(this.canvas.nativeElement, flowchartOptions);
            this.initAnchors();
             /*initSvgPanZoom(cn, this.hostElement, true);

           
                this is a fix for Safari, which cant handle relative urls in SVG when there is a <base>-tag defined
                with this fix all marker-end will be updated to a url with the current url prepended
            */
            const baseUrl = window.location.href.replace(window.location.hash, '');
            [].slice.call(document.querySelectorAll('path[*|marker-end]')).forEach((element) => {
                element.setAttribute('marker-end', element.getAttribute('marker-end').replace('#', baseUrl + '#'));
            });
        }
    }

    private initAnchors() {
        const interactive = this.canvas.nativeElement.querySelectorAll('a');
        if (interactive) {
            Array.prototype.forEach.call(interactive, element => {
                element.addEventListener('click', event => {
                    event.preventDefault();
                    const destination = element.href.baseVal;
                    if (destination.includes('/protocol/') || destination.includes('/protocols/')) {
                        this.router.navigateByUrl(destination.replace('/protocol/', '/protocols/'));
                    }
                    else {
                        const paragraphId = destination.substring(destination.indexOf('-') + 1);
                        if (paragraphId) {
                            const action = { type: 'handleClickParagraphCard', payload: { paragraphId } };
                            this.eventBus.emit(action);
                        } 
                    }
                });
            });
        }
    }
}
