import { createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';
import * as fromParagraphPopulations from '../reducers/paragraphs-populations.reducer';

export const getParPopulationsState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.parPopulations
);
export const getModelLoading = createSelector(
    getParPopulationsState,
    fromParagraphPopulations.getModelLoading
);

export const loadPopulationId = createSelector(
    getParPopulationsState,
    fromParagraphPopulations.loadPopulationId
);
export const getCopiedPopulation = createSelector(
    getParPopulationsState,
    fromParagraphPopulations.getCopiedPopulation
);
