import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap, withLatestFrom, take } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromActions from '../actions';
import * as fromService from '../../../../services';
import * as eventbusChannelActions from '../actions/eventbus-channel.action';
import * as ModelOutcomesAction from '../actions/models-outcomes.action';
import * as gradeActions from '../actions/grade-assessment.action';

const selectStore = (selectors: any) => {
    return switchMap(action => {
        return of([]).pipe(
            withLatestFrom(...selectors, (initial, ...selectorsState) => {
                return [action, ...selectorsState];
            }),
            take(1) // needed, since without it, subsription not killed until next action that hit effect
        );
    });
};

@Injectable()
export class ProtocolAllOutcomesEffects {
    constructor(private actions$: Actions, private ModelService: fromService.ModelsService) {}

    @Effect()
    UpdatePopulationNameEffect$ = this.actions$.pipe(
        ofType(fromActions.UPDATE_POPULATION_NAME),
        switchMap(action => {
            const {
                payload: { outcomeId, populationId, name }
            } = action as any;
            return this.ModelService.updatePopulationName(populationId, name).pipe(
                map(outcome => new fromActions.UpdatePopulationNameSuccess({ outcomeId, populationId, name })),
                catchError(error => of(new fromActions.UpdatePopulationNameFail(error)))
            );
        })
    );

    @Effect()
    AddPopulationEffect$ = this.actions$.pipe(
        ofType(fromActions.ADD_POPULATION),
        switchMap(action => {
            const { payload } = action as any;
            const { ppdId, populationId, outcomeId } = payload;

            return this.ModelService.addPopulation(populationId, outcomeId).pipe(
                map(response => {
                    const outcome = response.paragraphs[0];
                    return new fromActions.AddPopulationSuccess({ ppdId, outcomeId, outcome });
                }),
                catchError(error => of(new fromActions.AddPopulationFail(error)))
            );
        })
    );

    @Effect()
    CreatePopulationEffect$ = this.actions$.pipe(
        ofType(fromActions.CREATE_POPULATION),
        switchMap(action => {
            const { payload } = action as any;
            const { ppdId, outcome, findings } = payload;

            return this.ModelService.createPopulation({}).pipe(
                map(response => {
                    const populationId = response['message list']['populationId'];
                    return new fromActions.CreatePopulationSuccess({
                        population: { id: populationId },
                        outcome,
                        ppdId,
                        findings
                    });
                }),
                catchError(error => of(new fromActions.CreatePopulationFail(error)))
            );
        })
    );

    @Effect()
    CopyPopulationEffect$ = this.actions$.pipe(
        ofType(fromActions.COPY_POPULATION),
        switchMap(action => {
            const { payload } = action as any;
            const { ppdId, outcome, population, findings } = payload;

            return this.ModelService.copyPopulation(population.id).pipe(
                map(response => {
                    const populationId = response['message list']['populationId'];
                    return new fromActions.CopyPopulationSuccess({
                        population: {
                            ...population,
                            id: populationId,
                            oldId: population.id
                        },
                        outcome,
                        findings,
                        ppdId
                    });
                }),
                catchError(error => of(new fromActions.CopyPopulationFail(error)))
            );
        })
    );

    @Effect()
    CreatePopulationSuccessEffect$ = this.actions$.pipe(
        ofType(fromActions.CREATE_POPULATION_SUCCESS, fromActions.COPY_POPULATION_SUCCESS),
        mergeMap(action => {
            const { payload } = action as any;
            const { population, outcome, ppdId, findings } = payload;

            return [
                new eventbusChannelActions.PublishMessage({
                    channel: 'openEditPopulationModal',
                    message: {
                        population,
                        outcome,
                        findings,
                        ppdId
                    }
                }),
                new fromActions.SetCopiedPopulation({ population, outcome, ppdId, findings })
            ];
        })
    );

    @Effect()
    DeletePopulationEffect$ = this.actions$.pipe(
        ofType(fromActions.DELETE_POPULATION),
        switchMap(action => {
            const { payload } = action as any;
            const { ppdId, outcomeId, populationId } = payload;

            return this.ModelService.deletePopulation(populationId).pipe(
                map(response => {
                    const outcome = response.paragraphs[0];
                    return new fromActions.DeletePopulationSuccess({ ppdId, outcomeId, outcome });
                }),
                catchError(error => of(new fromActions.DeletePopulationFail(error)))
            );
        })
    );

    @Effect()
    ReplacePopulationStartEffect$ = this.actions$.pipe(
        ofType(fromActions.REPLACE_POPULATION_START),
        mergeMap(action => {
            const { payload } = action as any;
            const { ppdId, outcomeId, oldPopulationId, newPopulationId, name } = payload;
            return [
                new fromActions.ReplacePopulation({
                    ppdId,
                    outcomeId,
                    oldPopulationId,
                    newPopulationId,
                    name
                })
            ];
        })
    );

    @Effect()
    ReplacePopulationEffect$ = this.actions$.pipe(
        ofType(fromActions.REPLACE_POPULATION),
        switchMap(action => {
            const { payload } = action as any;
            const { ppdId, outcomeId, oldPopulationId, newPopulationId, name } = payload;

            return this.ModelService.replacePopulation(oldPopulationId, newPopulationId, name).pipe(
                map(response => {
                    const outcome = response.paragraphs[0];
                    return new fromActions.ReplacePopulationSuccess({
                        ppdId,
                        outcomeId,
                        outcome: { ...outcome, editOptions: 'populations' }
                    });
                }),
                catchError(error => of(new fromActions.ReplacePopulationFail(error)))
            );
        })
    );

    @Effect()
    ReplacePopulationSuccessEffect$ = this.actions$.pipe(
        ofType(
            fromActions.REPLACE_POPULATION_SUCCESS,
            fromActions.ADD_POPULATION_SUCCESS,
            fromActions.DELETE_POPULATION_SUCCESS
        ),
        mergeMap(action => {
            const { payload } = action as any;
            const { ppdId, outcomeId, outcome } = payload;

            const gradePopulations = (outcome.populations || []).reduce((accumulate, population) => {
                const gradePopulation = population.gradeAssessment
                    ? {
                          [population.id]: {
                              id: population.gradeAssessment.id ? population.gradeAssessment.id : null,
                              action: population.gradeAssessment.action,
                              values: population.gradeAssessment.questions ? population.gradeAssessment.questions : {},
                              type: 'population'
                          }
                      }
                    : {};
                return { ...accumulate, ...gradePopulation };
            }, {});

            return [
                new gradeActions.MapGradesReviews({
                    gradeAssessment: { ...gradePopulations }
                }),
                new ModelOutcomesAction.UpdateOutcomeSucces({
                    parentId: ppdId,
                    ppdId: outcomeId,
                    outcome
                }),
                new fromActions.ClearCopiedPopulation({})
            ];
        })
    );
}
