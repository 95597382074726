<div class="modal-header">
    <h4 class="modal-title text-capitalize">{{ selectedQuestion.title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container">
        <ng-container>
            <div class="row" *ngIf="rowData && rowData.length">
                <div class="col-md">
                    <h4>{{'GRADEREVIEW.SUPPORTING_EVIDENCE' | translate}}</h4>
                    <ag-grid-angular
                        #agGrid
                        style="width: 100%"
                        class="ag-theme-alpine"
                        [columnDefs]="readOnlycolumnDefs || selectedQuestion.columnDefs"
                        [rowData]="rowData"
                        [defaultColDef]="defaultColDef"
                        [frameworkComponents]="frameworkComponents"
                        domLayout='autoHeight'
                        singleClickEdit="true"
                        [pagination]=true
                        [paginationPageSize]=50
                        (eventBus)="handleEventBus($event)"
                        [getRowNodeId]="getRowNodeId"
                    >
                    </ag-grid-angular>
                </div>
            </div>

                <div class="row">
                    <div class="col-md" *ngIf="!selectedQuestion.text">
                        {{ selectedQuestion.text }}
                    </div>
                </div>
                <div class="row" *ngIf="selectedQuestion.imageUrl || waitingForUpload">
                    <div class="col-md">
                        <h4>{{'GRADEREVIEW.DOCUMENTATION' | translate}}</h4>
                        <p>
                            <img  
                                *ngIf="!waitingForUpload"
                                class="gradeImage" 
                                [class.expanded]="expandedImage"
                                (click)="expandedImage=!expandedImage" 
                                [src]="selectedQuestion.imageUrl" />
                            <span *ngIf="waitingForUpload">{{'GRADEREVIEW.WAITING' | translate}}</span>
                        </p>
                        <a *ngIf="!showUploadForm && edit" class="button" (click)="showUploadForm=!showUploadForm">{{'GRADEREVIEW.CHANGE_DOCUMENTATION' | translate}}</a>
                    </div>
                </div>

            <ng-container *ngIf="selectedQuestion.type != 'summary'">
                <div class="row" *ngIf="!selectedQuestion.imageUrl && !showUploadForm && edit">
                    <div class="col-md" style="margin-top: 1em;">
                        <a class="button" (click)="showUploadForm=!showUploadForm">{{'GRADEREVIEW.ADD_DOCUMENTATION' | translate}}</a>
                    </div>
                </div>
                <div class="row" *ngIf="showUploadForm  && edit">
                    <div class="col-md" style="margin-top: 1em;">
                        <div *ngIf="uploadResponse.error">{{ uploadResponse.error.message }}</div>
                        <div *ngIf="uploadResponse.status === 'uploadResponse.error'">{{ uploadResponse.message }}</div>
                        <div *ngIf="uploadResponse.status === 'progress'">
                            <div
                                role="progressbar"
                                [style.width.%]="uploadResponse.message"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            >
                                {{ uploadResponse.message }}%
                            </div>
                        </div>
                        <ng-form [formGroup]="fileForm" (ngSubmit)="onSubmitFile()">

                            <label for="file-upload" class="custom-file-upload" *ngIf="!file">
                                {{'PLEASE_SELECT' | translate}}
                            </label>
                            <label for="file-upload" class="custom-file-upload" *ngIf="file">
                                {{file.name}}
                            </label>
                            <input id="file-upload" type="file" name="file" (change)="onFileChange($event)" />
                            <button class="btn btn-info" type="submit" (click)="onSubmitFile()"><i class="fa fa-cloud-upload"></i> Upload</button>
                        </ng-form>
                    </div>
                </div>
            </ng-container>
            <div class="row">

                <div class="col-md" style="margin-top: 1em;" *ngIf="!edit && (!!selectedQuestion.comment || selectedQuestion?.options?.length) ">
                    <h4>{{'GRADEREVIEW.CHOOSE_ANSWER' | translate}}</h4>
                    
                    <ng-container *ngFor="let option of selectedQuestion.options; trackBy: trackByFn" >
                        <div *ngIf="option.selected">
                            {{ option.title }}
                        </div>
                    </ng-container>

                    <ng-container  *ngIf="!!selectedQuestion.comment">
                        <br><b>Opmerkingen</b><br>
                        {{selectedQuestion.comment}}
                    </ng-container >   
                </div>

                <div class="col-md" style="margin-top: 1em;" *ngIf="edit">
                    <h4>{{'GRADEREVIEW.CHOOSE_ANSWER' | translate}}</h4>
                    <ng-container *ngIf="selectedQuestion.type === 'summary' && edit">
                        <ag-grid-angular
                        #agGrid
                        style="width: 100%"
                        class="ag-theme-alpine"
                        [columnDefs]="selectedQuestion.fields.columnDefs"
                        [rowData]="fieldsRowData"
                        [defaultColDef]="summaryColDef"
                        [frameworkComponents]="frameworkComponents"
                        domLayout='autoHeight'
                        singleClickEdit="true"
                        [pagination]=true
                        [paginationPageSize]=50
                        (eventBus)="handleEventBus($event)"
                        >
                        </ag-grid-angular>
                        
                    </ng-container>

                    <ng-container *ngIf="selectedQuestion.type !== 'summary'">
                        <ng-form [formGroup]="commentForm">
                            <div class="form-group">
                                <label for="comment">{{ 'GRADEREVIEW.COMMENT' | translate}}:</label>
                                <textarea id="comment" class="form-control" formControlName="comment">
                                    {{selectedQuestion.comment}}
                                </textarea>
                            </div>
                        </ng-form>

                        <ng-form [formGroup]="form">
                            <select formControlName="select" class="custom-select" (change)="onChangeValue($event.target.value)">
                                <option
                                    *ngFor="let option of selectedQuestion.options; trackBy: trackByFn"
                                    [value]="option.value"
                                    [selected]="option.selected"
                                >
                                    {{ option.title }}
                                </option>
                            </select>
                        </ng-form>
                    </ng-container>
                </div>

                <div *ngIf="selectedQuestion.type === 'summary' && !edit">
                    <table>
                        <tr *ngFor="let row of selectedQuestion.fields.rowData">
                            <td>{{ row.label }} &nbsp; </td>
                            <td> {{ row.value }}</td>
                        </tr>
                    </table>

                </div>
            </div>
        </ng-container>
        <figure class="highlight"></figure>
    </div>
</div>
<div class="modal-footer">
    <button
        *ngIf="previousQuestionIndex !== null"
        class="btn btn-info text-capitalize mr-auto"
        type="button"
        (click)="navigateQuestion(previousQuestionIndex)"
    >
        previous
    </button>
    <button
        *ngIf="nextQuestionIndex"
        class="btn btn-info text-capitalize"
        type="button"
        (click)="navigateQuestion(nextQuestionIndex)"
    >
        next
    </button>
    <button
    *ngIf="!nextQuestionIndex && edit"
    class="btn btn-info text-capitalize"
    type="button"
    (click)="closeModal()"
    >
        save
    </button>
    
    <button
    *ngIf="!nextQuestionIndex && !edit"
    class="btn btn-info text-capitalize"
    type="button"
    (click)="closeModal()"
    >
        close
    </button>

</div>
