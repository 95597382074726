import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as fromStore from '../../store';
import { Shop } from '../../../../models/shop.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-shop',
    templateUrl: './shop.component.html',
    styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit, OnDestroy {
    shop$: Observable<Shop>;
    loading$: Observable<boolean>;

    subscriptions: Subscription[] = [];

    id: string;

    constructor(
        private route: ActivatedRoute,
        private store: Store<fromStore.ShopFeatureState>
    ) {
    }

    ngOnInit() {
        // Respond to changed params (id).
        this.subscriptions.push(
            this.route.params.subscribe(params => {
                this.id = params['id'];
                this.store.dispatch(new fromStore.LoadShop(this.id));
            })
        );

        this.loading$ = this.store.select(fromStore.getShopLoading);
        this.shop$ = this.store.select(fromStore.getShop);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }
}
