import * as fromActions from '../actions/users.action';

export interface UsersState {
    users: any[];
}

export const initialState: UsersState = {
    users: []
};

export function reducer(state = initialState, action: fromActions.UsersAction): UsersState {
    switch (action.type) {
        case fromActions.LOAD_USERS: {
            return { ...state };
        }

        case fromActions.LOAD_USERS_SUCCESS: {
            const data = action.payload;
            return { ...state, users: action.payload };
        }

        case fromActions.LOAD_USERS_FAIL: {
            return { ...state };
        }
    }
    return state;
}

export const getUsers = (state: UsersState) => state.users;
