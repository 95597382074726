<div class="layout">
    <div class="layout__page">
        <main id="content" class="layout__content ">
            <div>
                <div class="row page-title center-content mt-4 mb-3">
                    <i>{{'REGISTER.TITLE' | translate }}</i>
                </div>
                <div class="row center-all">
                    <div class="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
                        <div class="card card-primary panel-primary">
                            <div class="card-body">
                                <div class="alert alert-danger" role="alert" *ngIf="(registerError$ | async) as error">
                                    {{ 'ERROR' | translate }}: {{ error }}
                                </div>
                                <div class="alert alert-success" role="alert"
                                     *ngIf="(registerWelcomeMessage$ | async) as welcomeMessage">
                                    <p>{{ welcomeMessage }}</p>
                                    <a href="#" class="btn btn-primary" (click)="login($event)">{{ 'REGISTER.LOGIN' | translate}}</a>
                                </div>
                                <form *ngIf="!(registerLoaded$ | async)"
                                      class="cx_form cx_registerForm"
                                      [formGroup]="registerForm"
                                      (keyup.enter)="handleSubmit()"
                                      (ngSubmit)="handleSubmit()"
                                      name="main"
                                >
                                    <div class="form-group">
                                        <label for="firstName" class="font-bold">{{'REGISTER.FIRSTNAME' | translate}}:</label>
                                        <input
                                            formControlName="firstName"
                                            type="text"
                                            class="form-control first-name"
                                            id="firstName"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="lastName" class="font-bold">{{'REGISTER.LASTNAME' | translate}}:</label>
                                        <input
                                            formControlName="lastName"
                                            type="text"
                                            class="form-control last-name"
                                            id="lastName"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="email" class="font-bold">{{'REGISTER.EMAIL' | translate }}:</label>
                                        <input
                                            formControlName="email"
                                            type="email"
                                            class="form-control email"
                                            id="email"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="telephone" class="font-bold">{{'REGISTER.TELEPHONE' | translate}}:</label>
                                        <input
                                            formControlName="telephone"
                                            type="text"
                                            class="form-control telephone"
                                            id="telephone"
                                        />
                                    </div>
                                    <div class="form-group" *ngIf="isLoggedIn()">
                                        <label for="status" class="font-bold">Status:</label>
                                        <select id="status"
                                                class="form-control"
                                                formControlName="status">
                                            <option value="Trial">
                                                Trial
                                            </option>
                                            <option value="Active">
                                                Active
                                            </option>
                                            
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="hospitalIndex" class="font-bold">{{ 'REGISTER.HOSPITAL' | translate}}:</label>
                                        <select id="hospitalIndex"
                                                [ngClass]="{ success: validHospital, error: !validHospital}"
                                                (change)="changeHospital($event)"
                                                class="form-control hospital-index"
                                                formControlName="hospitalIndex">
                                            <option
                                                *ngFor="let hospital of hospitals; let index = index; trackBy:
                                                trackByFn"
                                                [value]="index">
                                                {{ hospital.hospitalName }}
                                            </option>
                                        </select>
                                    </div>
                                    <div [class.hidden]="!selectedHospital || !newOrganization"
                                         class="form-group hospital-name">
                                        <label for="hospitalName" class="font-bold">{{selectedHospital?.choose}}:</label>
                                        <input id="hospitalName" class="form-control hospital-name"
                                               formControlName="hospitalName"
                                               type="text" />
                                    </div>
                                    <div *ngIf="!(teams.length === 1 && newTeam)"
                                        class="form-group">
                                        <label for="teamIndex" class="font-bold">{{ 'REGISTER.INTEREST' | translate }}:</label>
                                        <select
                                            #selectTeamId
                                            id="teamIndex"
                                            [ngClass]="{ success: validTeam, error: !validTeam}"
                                            (change)="changeTeam($event)"
                                            class="form-control team-index"
                                            formControlName="teamIndex">
                                            <option
                                                *ngFor="let team of teams; let index = index; trackBy: trackByFn"
                                                [selected]="selectedTeam.teamId === team.teamId"
                                                [value]="index">
                                                {{ team.teamName }}
                                            </option>
                                        </select>
                                    </div>
                                    <div [class.hidden]="!selectedTeam || !newTeam"
                                         class="form-group">
                                        <label for="teamName">{{selectedTeam?.choose}}</label>
                                        <input id="teamName" class="form-control team-name" type="text"
                                               formControlName="teamName" />
                                    </div>
                                    <div *ngIf="terms" class="form-check my-4">
                                        <input
                                            formControlName="acceptTerms"
                                            type="checkbox"
                                            class="form-check-input accept-terms"
                                            id="accept-terms"
                                            value="true"
                                            checked=""
                                        />
                                        <label class="form-check-label" for="accept-terms">
                                            {{ 'REGISTER.TERMS' | translate }}
                                            <a [href]="terms.link" target="_blank" title="{{ 'REGISTER.GOTO-TERMS' | translate }}"
                                            ><span class="button__icon"><span
                                                class="icon-question"></span></span></a>
                                        </label>
                                    </div>
                                    <button
                                        id="submit-button"
                                        class="btn btn-lg btn-block btn-primary"
                                        type="button"
                                        [disabled]="(registerLoading$ | async) || registerForm.invalid"
                                        (click)="handleSubmit()"
                                    >
                                        <span
                                            class="spinner-grow spinner-grow-sm"
                                            role="status"
                                            aria-hidden="true"
                                            *ngIf="(registerLoading$ | async)"
                                        ></span>
                                        {{ 'SIGNUP' | translate }}
                                    </button>
                                    <div class="cx_spacer"></div>
                                    <br />
                                    <div class="row">
                                        <div class="col ml-2">
                                            <a href="#" (click)="login($event)">{{ 'REGISTER.GOTO-LOGIN' | translate}}</a>
                                        </div>
                                        <div class="col">
                                            <div class="row justify-content-end mr-2">
                                                <a href="#" (click)="reset($event)">{{ 'RESET' | translate}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
