export const toolbarButtons = [
    [
        // Default
        'fullscreen',
        'bold',
        'italic',
        'formatUL',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'insertFootnote',
        'removeStyling',
        '-',
        'fontFamily',
        'fontSize',
        'color',
        'inlineClass',
        'inlineStyle',
        'paragraphStyle',
        'lineHeight',
        '-',
        'paragraphFormat',
        'align',
        'formatOL',
        'outdent',
        'indent',
        'quote',
        '-',
        'insertLink',
        'insertImage',
        'insertVideo',
        'embedly',
        'insertFile',
        'insertTable',
        '-',
        'emoticons',
        'fontAwesome',
        'specialCharacters',
        'insertHR',
        'selectAll',
        'clearFormatting',
        '-',
        'print',
        'getPDF',
        'spellChecker',
        'help',
        'html',
        '-',
        'undo',
        'redo'
    ],
    {
        // Fancy
        moreText: {
            buttons: [
                'bold',
                'italic',
                'underline',
                'superscript',
            ],
            align: 'left',
            buttonsVisible: 4
        },
        moreParagraph: {
            buttons: [
                'formatOL',
                'formatUL',
            ],
            align: 'left',
            buttonsVisible: 2
        },
        moreRich: {
            buttons: [
                'insertLink',
                'insertImage',
                'insertFootnote',
                'removeStyling',
                'html', 
            ],
            align: 'left',
            buttonsVisible: 5
        },
        moreMisc: {
            buttons: ['undo', 
                'redo', 
                'strikeThrough',
                'subscript',
                'superscript',
                'fontFamily',
                'fontSize',
                'textColor',
                'backgroundColor',
                'inlineClass',
                'inlineStyle',
                'clearFormatting',
                'alignLeft',
                'alignCenter',
                'formatOLSimple',
                'alignRight',
                'alignJustify',
                'paragraphFormat',
                'paragraphStyle',
                'lineHeight',
                'outdent',
                'indent',
                'quote',
                'insertVideo',
                'insertTable',
                'emoticons',
                'fontAwesome',
                'specialCharacters',
                'embedly',
                'insertFile',
                'insertHR',
                'print', 
                'getPDF', 
                'spellChecker', 
                'selectAll', 
                'help'],
            align: 'right',
            buttonsVisible: 2
        }
    }
];
