import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromUsers from '../reducers/users.reducer';

export const getUsersState = createSelector(
    fromFeature.getOrganisationFeatureState,
    (state: fromFeature.OrganisationsFeatureState) => state.users
);
export const getUsers = createSelector(
    getUsersState,
    fromUsers.getUsers
);

export const getUsersLoaded = createSelector(
    getUsersState,
    fromUsers.getUsersLoaded
);

export const getUsersLoading = createSelector(
    getUsersState,
    fromUsers.getUsersLoading
);
