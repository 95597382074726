<div class="modal-header">
    <h4 class="modal-title">Add Article</h4>
    <button type="button" class="close" aria-label="Close" (click)="onCancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container">
        <ng-form [formGroup]="searchForm" (keyup.enter)="onSubmitSearch()">
            <div class="form-row">
                <div class="col-10">
                    <input type="text" formControlName="query" class="form-control" placeholder="Search article" />
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-info mb-2" (click)="onSubmitSearch()">Search</button>
                </div>
            </div>
        </ng-form>
        <div *ngIf="searchLoading" id="loadingSearchPubmed" style="display: block;">
            <div class="spinner">
                <div class="dot1"></div>
                <div class="dot2"></div>
            </div>
        </div>

        <div class="row" *ngIf="!searchLoading">
            <div *ngIf="pubmeds.length === 0">There were no articles found on PubMed matching the search criteria.</div>
            <ng-form [formGroup]="form" *ngIf="pubmeds.length > 0">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">-</th>
                            <th scope="col">Article Title</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pubmed of pubmeds; let i = index; trackBy: trackByFn">
                            <td scope="row">
                                <div class="form-check">
                                    <input class="form-check-input" [formControlName]="pubmed.uid" type="checkbox" />
                                </div>
                            </td>
                            <td>{{ pubmed.title }}</td>
                        </tr>
                    </tbody>
                </table>

                <button class="btn btn-info" type="button" (click)="onSubmitAddArticles()" [disabled]="!formIsValid">
                    Submit
                </button>
                <button class="btn btn-default" type="button" (click)="onCancel()">Cancel</button>
            </ng-form>
        </div>
    </div>
</div>
