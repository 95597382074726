import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { Protocol } from '../../../../models/protocol.interface';
import { User } from '../../../../models/user.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-protocol-pdf',
    templateUrl: './protocol-pdf.component.html',
    styleUrls: ['./protocol-pdf.component.scss']
})
export class ProtocolPdfComponent {
    @Input()
    protocol: Protocol;

    @Input()
    users: User[];

    @Input()
    pane: string;

    @Input()
    loading = true;

    @Input()
    versionId?: string;

    @Input()
    version?: string;

    @Input()
    currentVersion: any;

    @Input()
    draftVersion: any;

    @Input()
    deprecatedVersions: any[] = [];

    @Input()
    storedDraftVersions: any[] = [];

    @Input()
    isEditAble?: boolean;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    handleEventBus(event) {
        const { type, payload } = event;
        switch (type) {
            default:
                this.eventBus.emit(event);
                break;
        }
    }
}
