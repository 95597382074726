<section class="page__section" style="margin: 1em;">
    <h3>{{ 'SEARCH' | translate }}</h3>
    <form>
        <div class="form-group row">
            <alii-web-search-box
                id="search-page"
                class="search-box"
                placeholder=""
                [query]="query"
                [hideDropdown]="true"
                [selectText]="selectText"
                [filter]="searchPage.type"
                (focus)="onFocus()"
                (blur)="onBlur()"
            ></alii-web-search-box>
        </div>
        <div class="form-group row" *ngIf="navigatingTo || (searchGetLoading$ | async)">
            <alii-web-spinner-loading></alii-web-spinner-loading>
        </div>
        <div class="form-group row" *ngIf="(searchGetFail$ | async) as message">
            <div class="alert alert-danger" role="alert">
                {{ 'ERROR' | translate }}: {{message}}
            </div>
        </div>

        <div *ngIf="!navigatingTo && (searchGetSuccess$ | async)" class="form-group row">
            <div class="col-12 align">
                <div *ngFor="let type of showTypes; trackBy: trackByFn"
                     class="form-check form-check-inline">
                    <input
                        [id]="'radio-type-' + type"
                        class="form-check-input ml-3"
                        type="radio"
                        name="radio-type"
                        [checked]="type === searchPage.type"
                        (click)="changeTypeFilter(type)"> {{type | convertType2Text | titlecase}}
                    <label
                        [for]="'radio-type' + type" class="hidden">
                    </label>
                </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-10 ">
                <ngb-pagination
                    *ngIf="(dataGridService.total$ | async) > dataGridService.pageSize"
                    [collectionSize]="(dataGridService.total$ | async)"
                    [(page)]="dataGridService.page"
                    [pageSize]="dataGridService.pageSize"
                    (pageChange)="changePage($event)">
                </ngb-pagination>
            </div>

            <div class="col-2 ">
                <label for="select-page-size" class="hidden"></label>
                <select *ngIf="(dataGridService.total$ | async) > dataGridService.pageSizes[0]"
                        id="select-page-size"
                        class="custom-select"
                        name="pageSize"
                        (change)="changePageSize($event)"
                        [(ngModel)]="dataGridService.pageSize">
                    <option *ngFor="let pageSize of dataGridService.pageSizes"
                            [ngValue]="pageSize">{{pageSize}}</option>
                </select>
            </div>
        </div>
        <table *ngIf="(dataGridService.total$ | async)" class="table table-striped">
            <thead>
            <tr>
                <th id="th-title" class="table__text table__title table__column-50" scope="col" sortable="title"
                    (sort)="onSort($event)">
                    <div *ngIf="sortColumn === 'title' && (dataGridService.loading$ | async)"
                         class="spinner-grow spinner-grow-sm"
                         role="status"></div>
                    <span>{{ 'TITLE' | translate }}</span>
                </th>
                <th id="th-type" class="table__text table__title table__column-10" scope="col" sortable="type"
                    (sort)="onSort($event)">
                    <div *ngIf="sortColumn === 'type' && (dataGridService.loading$ | async)"
                         class="spinner-grow spinner-grow-sm"
                         role="status"></div>
                    <span>{{ 'TYPE' | translate }}</span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of (dataGridService.rows$ | async); trackBy: trackByFn"
                class="hover-highlight"
                [class.hilighted]="row['index'] === searchPage.selected"
                (click)="onSelect(row)">
                <td class="table__text table__title">
                    <ngb-highlight [result]="row.title"
                                   [term]="query"></ngb-highlight>
                </td>
                <td class="table__text">
                    {{row.type | convertType2Text | titlecase}}
                </td>
            </tr>
        </table>
        <div *ngIf="!pagedResults.length && !(searchGetLoading$ | async)">
            {{ 'NO-RESULTS' | translate }}
        </div>
    </form>
</section>
