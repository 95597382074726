import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import { DashboardTag } from '@alii-web/models/dashboard-tag.model';
import { DashboardTagActions, DashboardTagActionTypes } from './../actions/dashboard-tag.action';

export interface DashboardTagState extends EntityState<DashboardTag> {
    entities: Dictionary<any>;
    selectedDashboardTagId: string | number | null;
}
export function selectDashboardTagId(a: DashboardTag): string {
    return a.id;
}

export const adapter: EntityAdapter<DashboardTag> = createEntityAdapter<DashboardTag>({
    selectId: selectDashboardTagId
});

export const initialState: DashboardTagState = adapter.getInitialState({
    selectedDashboardTagId: null
});

export function reducer(state = initialState, action: DashboardTagActions): DashboardTagState {
    switch (action.type) {
        case DashboardTagActionTypes.AddDashboardTag: {
            return adapter.addOne(action.payload.dashboardTag, state);
        }

        case DashboardTagActionTypes.UpdateDashboardTag: {
            return adapter.updateOne(action.payload.dashboardTag, state);
        }

        case DashboardTagActionTypes.UpdateDashboardTagSuccess: {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.payload.dashboardTag.id]: action.payload.dashboardTag
                }
            };
        }

        case DashboardTagActionTypes.SortDashboardTag: {
            return adapter.updateOne(action.payload.dashboardTag, state);
        }

        case DashboardTagActionTypes.SortDashboardTagSuccess: {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.payload.dashboardTag.id]: action.payload.dashboardTag
                }
            };
        }

        case DashboardTagActionTypes.DeleteDashboardTag: {
            return adapter.removeOne(action.payload.id, state);
        }

        case DashboardTagActionTypes.LoadDashboardTags: {
            return adapter.addAll(action.payload.dashboardTags, state);
        }

        case DashboardTagActionTypes.ClearDashboardTags: {
            return adapter.removeAll(state);
        }

        case DashboardTagActionTypes.SelectDashboardTag: {
            return {
                ...state,
                selectedDashboardTagId: action.payload.tagId
            };
        }

        default: {
            return state;
        }
    }
}

export const getSelectedDashboardTagId = (state: DashboardTagState) => state.selectedDashboardTagId;

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectDashboardExistingIds = selectIds;

// select the dictionary of user entities
export const selectDashboardExistingEntities = selectEntities;

// select the array of users
export const selectAllDashboardExistings = selectAll;

// select the total user count
export const selectDashboardExistingTotal = selectTotal;
