<div class="calendar_card" style="width:100% !important">
    <article class="card">
        <header class="card__header"><strong [innerHTML]="item.title"></strong><br /></header>
        <section class="calendar_text card-body">
            <div><strong [innerHTML]="formattedDateString"></strong><br /></div>
            <br />
            <div [innerHTML]="item.expanded_text ? item.expanded_text : item.text"></div>
        </section>
        <footer class="card__footer">
            <div *ngIf="item.location">Locatie: {{ item.location }}</div>
        </footer>
    </article>
</div>
