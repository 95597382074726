import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromShop from './shop.reducer';
import * as fromShopTemplateSet from './shop-template-set.reducer';

export interface ShopFeatureState {
    shop: fromShop.ShopState;
    shopTemplateSet: fromShopTemplateSet.ShopTemplateSetState;
}

export const reducers: ActionReducerMap<ShopFeatureState> = {
    shop: fromShop.reducer,
    shopTemplateSet: fromShopTemplateSet.reducer
};

export const getShopFeatureState = createFeatureSelector<ShopFeatureState>('ShopFeature');
