import * as fromSearch from '../actions/search.action';
import { SearchDTO, SearchPage, SearchRO } from '../../models';

export interface SearchState {
    payload: SearchDTO;
    loading: boolean;
    success: SearchRO;
    fail: string;
    searchPage: SearchPage;
}

const initialSearchPage: SearchPage = {
    page: 1,
    pageSize: 50,
    pageSizes: [25, 50, 100, 250, 1000],
    type: 'all',
    sortEvent: {
        sortColumn: 'title',
        sortDirection: 'asc'
    },
    selected: 0
};

export const initialState: SearchState = {
    payload: null,
    loading: false,
    success: null,
    fail: null,
    searchPage: initialSearchPage
};

export function reducer(state = initialState, action: fromSearch.SearchAction): SearchState {
    switch (action.type) {
        case fromSearch.SEARCH_GET: {
            return {
                ...state,
                payload: action.payload,
                loading: true,
                success: null,
                fail: null
            };
        }

        case fromSearch.SEARCH_GET_SUCCESS: {
            return {
                ...state,
                loading: false,
                success: action.payload
            };
        }

        case fromSearch.SEARCH_GET_FAIL: {
            return {
                ...state,
                loading: false,
                fail: action.payload
            };
        }

        case fromSearch.SEARCH_GET_CLEAR: {
            return {
                ...initialState
            };
        }

        case fromSearch.SEARCH_SET_ANCHORID: {
            return {
                ...state,
                payload: {
                    ...state.payload,
                    anchorId: action.payload
                }
            };
        }

        case fromSearch.SEARCH_PAGE_SET: {
            return {
                ...state,
                searchPage: {
                    ...state.searchPage,
                    ...action.payload
                }
            };
        }

        case fromSearch.SEARCH_PAGE_RESET: {
            return {
                ...state,
                searchPage: initialSearchPage
            };
        }
    }
    return state;
}

export const getSearchGetPayload = (state: SearchState) => state.payload;
export const getSearchGetQuery = (state: SearchState) => (state.payload ? state.payload.q : null);
export const getSearchGetLoading = (state: SearchState) => state.loading;
export const getSearchGetSuccess = (state: SearchState) => state.success;
export const getSearchGetError = (state: SearchState) => state.fail;
export const getSearchGetSearchPage = (state: SearchState) => state.searchPage;
