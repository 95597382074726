import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromRegister from '../reducers/register.reducer';

export const getRegisterState = createSelector(
    fromFeature.getRegisterFeatureState,
    (state: fromFeature.RegisterFeatureState) => {
        return state.register;
    }
);

export const getRegisterInit = createSelector(
    getRegisterState,
    fromRegister.getRegisterInit
);

export const getRegisterInitLoaded = createSelector(
    getRegisterState,
    fromRegister.getRegisterInitLoaded
);

export const getRegisterInitLoading = createSelector(
    getRegisterState,
    fromRegister.getRegisterInitLoading
);

export const getRegisterInitError = createSelector(
    getRegisterState,
    fromRegister.getRegisterInitError
);

export const getRegisterLoaded = createSelector(
    getRegisterState,
    fromRegister.getRegisterLoaded
);

export const getRegisterLoading = createSelector(
    getRegisterState,
    fromRegister.getRegisterLoading
);

export const getRegisterError = createSelector(
    getRegisterState,
    fromRegister.getRegisterError
);

export const getRegisterWelcomeMessage = createSelector(
    getRegisterState,
    fromRegister.getRegisterWelcomeMessage
);
