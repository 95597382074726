<div class="modal-header">
    <h4 class="modal-title">Insert a Footnote</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="container">
        <div *ngIf="!references.length && !links.length">
            <div class="row m-4">
                There are currently no references available for this paragraph.
            </div>
            <div class="row m-4">
                Click on the cog wheel at the right of this paragraph in order to add an article.
            </div>
        </div>

        <div *ngIf="references.length || links.length">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" [destroyOnHide]="false" class="nav-tabs">
                <li [ngbNavItem]="'articles'">
                    <a ngbNavLink ngbTooltip="PubMed articles">Articles</a>
                    <ng-template ngbNavContent>
                        <ng-form *ngIf="references.length" class="form" [formGroup]="formReferences">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Label</th>
                                    <th scope="col">Title</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let reference of references; let index = index; trackBy: trackByFn">
                                    <td>
                                        <label for="reference"></label>
                                        <input id="reference" type="radio" name="reference" formControlName="reference"
                                               [value]="index">
                                    </td>
                                    <td>{{ reference.label }}</td>
                                    <td>{{ reference.title }}</td>
                                </tr>
                                </tbody>
                            </table>
                            <button [disabled]="!formReferences.valid" class="btn btn-info" type="button"
                                    (click)="onSubmit('reference')">
                                Submit
                            </button>
                            <button class="btn btn-default" type="button" (click)="onCancel('references')">Cancel
                            </button>
                        </ng-form>
                        <div *ngIf="!references.length">
                            <div class="row m-4">
                                There are currently no articles available for this paragraph.
                            </div>
                            <div class="row m-4">
                                Click on the cog wheel at the right of this paragraph in order to add a reference.
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="'links'">
                    <a ngbNavLink ngbTooltip="Non-PubMed articles">Links</a>
                    <ng-template ngbNavContent>
                        <ng-form *ngIf="links.length" class="form" [formGroup]="formLinks">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Url</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let link of links; let index = index;trackBy: trackByFn">
                                    <td>
                                        <label for="link"></label>
                                        <input id="link" type="radio" name="link" formControlName="link"
                                               [value]="index">
                                    </td>
                                    <td>{{ link.publication_name }}</td>
                                    <td>{{ link.title }}</td>
                                    <td>{{ link.url }}</td>
                                </tr>
                                </tbody>
                            </table>
                            <button [disabled]="!formLinks.valid" class="btn btn-info" type="button"
                                    (click)="onSubmit('links')">Submit
                            </button>
                            <button class="btn btn-default" type="button" (click)="onCancel('links')">Cancel</button>
                        </ng-form>
                        <div *ngIf="!links.length">
                            <div class="row m-4">
                                There are currently no non pubmed articles available for this paragraph.
                            </div>
                            <div class="row m-4">
                                Click on the cog wheel at the right of this paragraph in order to add an article.
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
</div>
