import { Action } from '@ngrx/store';

export const LOAD_PROTOCOL_WITH_ALL_OUTCOMES = '[Organisation] Load Protocol with all outcomes';
export const LOAD_PROTOCOL_WITH_ALL_OUTCOMES_FAIL = '[Organisation] Load Protocol with all outcomes Fail';
export const LOAD_PROTOCOL_WITH_ALL_OUTCOMES_SUCCESS = '[Organisation] Load Protocol with all outcomes Success';

export class LoadProtocolWithAllOutcomes implements Action {
    readonly type = LOAD_PROTOCOL_WITH_ALL_OUTCOMES;
    constructor(public payload: { protocolId: string }) {}
}

export class LoadProtocolWithAllOutcomesSuccess implements Action {
    readonly type = LOAD_PROTOCOL_WITH_ALL_OUTCOMES_SUCCESS;
    constructor(public payload: { protocol: any; questions: any; populations: any }) {}
}

export class LoadProtocolWithAllOutcomesFail implements Action {
    readonly type = LOAD_PROTOCOL_WITH_ALL_OUTCOMES_FAIL;
    constructor(public payload: any) {}
}

export const UPDATE_POPULATION_NAME = '[Protocol] Update population name';
export const UPDATE_POPULATION_NAME_FAIL = '[Protocol] Update population name Fail';
export const UPDATE_POPULATION_NAME_SUCCESS = '[Protocol] Update population name Success';

export class UpdatePopulationName implements Action {
    readonly type = UPDATE_POPULATION_NAME;
    constructor(public payload: any) {}
}

export class UpdatePopulationNameFail implements Action {
    readonly type = UPDATE_POPULATION_NAME_FAIL;
    constructor(public payload: any) {}
}

export class UpdatePopulationNameSuccess implements Action {
    readonly type = UPDATE_POPULATION_NAME_SUCCESS;
    constructor(public payload: { outcomeId: string; populationId: string; name: string }) {}
}

export const CREATE_POPULATION = '[Protocol] Create Population';
export const CREATE_POPULATION_FAIL = '[Protocol] Create Population Fail';
export const CREATE_POPULATION_SUCCESS = '[Protocol] Create Population Success';

export class CreatePopulation implements Action {
    readonly type = CREATE_POPULATION;
    constructor(public payload: any) {}
}

export class CreatePopulationFail implements Action {
    readonly type = CREATE_POPULATION_FAIL;
    constructor(public payload: any) {}
}

export class CreatePopulationSuccess implements Action {
    readonly type = CREATE_POPULATION_SUCCESS;
    constructor(public payload: any) {}
}

export const COPY_POPULATION = '[Protocol] Copy population';
export const COPY_POPULATION_FAIL = '[Protocol] Copy population Fail';
export const COPY_POPULATION_SUCCESS = '[Protocol] Copy population Success';

export class CopyPopulation implements Action {
    readonly type = COPY_POPULATION;
    constructor(public payload: any) {}
}

export class CopyPopulationFail implements Action {
    readonly type = COPY_POPULATION_FAIL;
    constructor(public payload: any) {}
}

export class CopyPopulationSuccess implements Action {
    readonly type = COPY_POPULATION_SUCCESS;
    constructor(public payload: any) {}
}

export const ADD_POPULATION = '[Protocol] Add population';
export const ADD_POPULATION_FAIL = '[Protocol] Add population Fail';
export const ADD_POPULATION_SUCCESS = '[Protocol] Add population Success';

export class AddPopulation implements Action {
    readonly type = ADD_POPULATION;
    constructor(public payload: any) {}
}

export class AddPopulationFail implements Action {
    readonly type = ADD_POPULATION_FAIL;
    constructor(public payload: any) {}
}

export class AddPopulationSuccess implements Action {
    readonly type = ADD_POPULATION_SUCCESS;
    constructor(public payload: any) {}
}

export const DELETE_POPULATION = '[Protocol] Delete population';
export const DELETE_POPULATION_FAIL = '[Protocol] Delete population Fail';
export const DELETE_POPULATION_SUCCESS = '[Protocol] Delete population Success';

export class DeletePopulation implements Action {
    readonly type = DELETE_POPULATION;
    constructor(public payload: any) {}
}

export class DeletePopulationFail implements Action {
    readonly type = DELETE_POPULATION_FAIL;
    constructor(public payload: any) {}
}

export class DeletePopulationSuccess implements Action {
    readonly type = DELETE_POPULATION_SUCCESS;
    constructor(public payload: any) {}
}

export const REPLACE_POPULATION_START = '[Protocol] Start Replace population';
export const REPLACE_POPULATION = '[Protocol] Replace population';
export const REPLACE_POPULATION_FAIL = '[Protocol] Replace population Fail';
export const REPLACE_POPULATION_SUCCESS = '[Protocol] Replace population Success';

export class ReplacePopulationStart implements Action {
    readonly type = REPLACE_POPULATION_START;
    constructor(public payload: any) {}
}

export class ReplacePopulation implements Action {
    readonly type = REPLACE_POPULATION;
    constructor(public payload: any) {}
}

export class ReplacePopulationSuccess implements Action {
    readonly type = REPLACE_POPULATION_SUCCESS;
    constructor(public payload: any) {}
}

export class ReplacePopulationFail implements Action {
    readonly type = REPLACE_POPULATION_FAIL;
    constructor(public payload: any) {}
}

export type ProtocolAllOutcomesAction =
    | LoadProtocolWithAllOutcomes
    | LoadProtocolWithAllOutcomesFail
    | LoadProtocolWithAllOutcomesSuccess
    | UpdatePopulationName
    | UpdatePopulationNameFail
    | UpdatePopulationNameSuccess
    | CreatePopulation
    | CreatePopulationFail
    | CreatePopulationSuccess
    | CopyPopulation
    | CopyPopulationFail
    | CopyPopulationSuccess
    | AddPopulation
    | AddPopulationFail
    | AddPopulationSuccess
    | DeletePopulation
    | DeletePopulationFail
    | DeletePopulationSuccess
    | ReplacePopulationStart
    | ReplacePopulation
    | ReplacePopulationSuccess
    | ReplacePopulationFail;
