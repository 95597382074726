import {
    Component,
    ChangeDetectionStrategy,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    OnInit
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {AgGridAngular} from 'ag-grid-angular';
import { RenderHTML } from '../../../../../literatures/components/reviews/render-html.component';
import { EditHTML } from '../../../../../literatures/components/reviews/edit-html.component';
import * as fromService from '../../../../../../services';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'aggrid-table',
    templateUrl: './aggrid-table.component.html',
    styleUrls: ['./paragraph-evidence-table.component.scss']
})


export class AggridTableComponent {
    @Input() isEditAble: boolean;
    @Input() paragraphId: string;
    @Input() columnDefs: [];
    @Input() rowData: [];
    @Input() gradeColumnDefs: any[];
    @Input() gradeRowData: any[];
    @Input() index: number;
    @Input() hasNumberedIndex: boolean;
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('agGrid') agGrid: AgGridAngular;
    
    actionsShown: boolean;
    frameworkComponents;

    constructor(
        private modalService: NgbModal,
        private literaturesService: fromService.LiteraturesService) {
        this.frameworkComponents = {
            renderHTML: RenderHTML,
            editHTML: EditHTML,
          };
    } 

    onGridReady(params) {
        this.agGrid.columnApi.autoSizeAllColumns(true)
        this.agGrid.api.resetRowHeights()
    }

    onBtnExport() {

        let params: any = {
        fileName: 'export.csv',
        columnSeparator: ';',
        skipHeader: true,
        };
        params.processCellCallback = function(cellParams) {
            if(cellParams.value) {
                if (cellParams.value.includes("<span class='icon-external-link'")) {
                    let strip = cellParams.value.replace("' target='_blank'><span class='icon-external-link'></span></a>", "")
                    strip = strip.replace("<a href='", "")
                    return strip
                }
                return cellParams.value.replace(/(<([^>]+)>)/gi, "");
            }
           }
        this.agGrid.api.exportDataAsCsv(params);
            
      }

    deleteSelectedRows() {
        let data = this.getSelectedRowData()
        const action = {
            type: 'handleDeleteRows',
            payload: {
                data,
                ppdId: this.paragraphId
            }
        };
        this.eventBus.emit(action);
    }

    defaultColDef: any = {
        valueSetter: (params) => {
            let newVal = params.newValue;
            const field = params.colDef.field;
            var action = "updateReview"
         
            const eventPayload = {
              type: 'updateReview',
              payload: {
                    reviewId: params.data.id,
                    action, 
                    field,
                    ppdId: this.paragraphId,
                    value: newVal 
              }
          };
          this.eventBus.emit(eventPayload);
          return false;
        },
        resizable: true,
        cellStyle: {
          'align-items': 'center'
        }
      };

    showActions() {
        this.actionsShown = true
    }

    handleEventBus(event) {
        this.eventBus.emit(event);
    }

    getSelectedRowData() {
        let selectedNodes = this.agGrid.api.getSelectedNodes();       
        let selectedData = selectedNodes.map(node => node.data);
        return selectedData;
    }


    openArticle() {
        let data = this.getSelectedRowData()
        let articleId = data[0].articleId
        const viewArticleAction = {
            type: 'onClickArticle',
            payload: {
                articleId
            }
        };
        this.eventBus.emit(viewArticleAction);

    }

    trackByFn = (index, item) => item.id || index;
}
