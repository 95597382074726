import { Component, ChangeDetectionStrategy, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, ControlContainer } from '@angular/forms';

import { FieldConfig } from '../../../../models/field-config.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'dynamicForm',
    selector: 'dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnChanges, OnInit {
    @Input()
    config: FieldConfig[] = [];

    @Output()
    submit: EventEmitter<any> = new EventEmitter<any>();

    form: FormGroup;

    wizardStyle = true;
    currentStep = 1;
    stepCount = 3;

    get controls() {
        return this.config.filter(({ type }) => type !== 'button');
    }
    get changes() {
        return this.form.valueChanges;
    }
    get valid() {
        return this.form.valid;
    }
    get value() {
        return this.form.value;
    }

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.createGroup();

        if (this.config && this.config.length > 0) {
            this.goToStep(1);
        }
    }

    ngOnChanges() {
        if (this.form) {
            const controls = Object.keys(this.form.controls);
            const configControls = this.controls.map(
                item => {
                    // special case, and should always be named the same
                    if (item.name ===  "paragraphId") {
                        return "paragraphId"
                    }
                    if(item.questionId) 
                        {
                            return item.questionId
                        }
                    else if(item.name) {
                            return item.name
                    } 
                    else {
                        return
                    }
                }
                );


            controls.forEach(control => {
                this.form.removeControl(control);
            });
            
            configControls.forEach(name => {
                if( name !== undefined) {
                    let config = this.config.find(control => control.questionId === name);
                    if (config == undefined) {
                        config = this.config.find(control => control.name === name);
                    } 
                    if (config) {
                        this.form.addControl(name, this.createControl(config));
                    }
                }
            });
        }
    }

    nextStep() {
        this.currentStep = this.currentStep + 1;
        this.goToStep(this.currentStep);
    }

    previousStep() {
        this.currentStep = this.currentStep - 1;
        this.goToStep(this.currentStep);
    }

    createGroup() {
        const steps = {};
        let i;
        for (i = 1; i <= this.stepCount; i++) {
            steps['step' + i] = new FormGroup({});
        }
        const group = this.fb.group(steps);
        this.controls.forEach(control => 
            {
                if (control.questionId) {
                    group.addControl(control.questionId, this.createControl(control))
                }
                else {
                    group.addControl(control.name, this.createControl(control))
                }  
            
            });
        return group;
    }

    createControl(config: FieldConfig) {
        const { disabled, validation, value } = config;
        return this.fb.control({ disabled, value }, validation);
    }

    handleSubmit(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.submit.emit(this.value);
    }

    handleCancel(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }

    setDisabled(name: string, disable: boolean) {
        if (this.form.controls[name]) {
            const method = disable ? 'disable' : 'enable';
            this.form.controls[name][method]();
            return;
        }

        this.config = this.config.map(item => {
            if (item.name === name) {
                item.disabled = disable;
            }
            return item;
        });
    }

    setValue(name: string, value: any) {
        this.form.controls[name].setValue(value, { emitEvent: true });
    }

    goToStep(step) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.currentStep = step;
    }
}
