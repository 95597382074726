import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { defaultLanguage, Language, supportedLanguages } from '../models/language.model';

const cn = 'LanguageService';

const KEY_LANGUAGE = 'language';

@Injectable()
export class LanguageService {
    private language: Language;
    private languageChanged$: BehaviorSubject<Language>;

    languageChanged: Observable<Language>;

    constructor(private translate: TranslateService) {
        this._init();
    }

    set(language: Language): boolean {
        if (supportedLanguages().includes(language)) {
            if (this.language !== language) {
                this.translate.use(language);
                this.languageChanged$.next(<Language>language);
                return true;
            } else {
                console.warn(`${cn} current language='${language}' => ignore`);
                return false;
            }
        } else {
            console.warn(`${cn} invalid language='${language}'`);
            return false;
        }
    }
    supportedLanguages(): Language[] {
        return supportedLanguages();
    }

    private _init() {
        this.language = <Language>localStorage.getItem(KEY_LANGUAGE);
        if (!(this.language && supportedLanguages().includes(this.language))) {
            this.language = defaultLanguage();
        }

        this.languageChanged$ = new BehaviorSubject<Language>(this.language);
        this.languageChanged = this.languageChanged$.asObservable();

        this.translate.onLangChange.subscribe((e: LangChangeEvent) => {
            const lang = <Language>e.lang;
            if (lang && supportedLanguages().includes(lang)) {
                this.language = lang;
                localStorage.setItem(KEY_LANGUAGE, lang);
                this.languageChanged$.next(<Language>lang);
            } else {
                console.warn(`${cn} unknown language='${lang}'`);
            }
        });

        setTimeout(() => this.translate.use(this.language), 200);
    }
}
