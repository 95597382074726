// See: https://github.com/MRebati/angular7-pubsub
/* eslint-disable @typescript-eslint/no-unused-vars,no-dupe-class-members */
import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, Subscription } from 'rxjs';

const sn = 'PubSub Service';

@Injectable()
export class PubSubService implements IPubSubService {
    private events = {};

    constructor() {}

    public $sub(event: string): Observable<any>;

    // eslint-disable-next-line no-dupe-class-members
    public $sub(event: string, callback: (value: any) => void): Subscription;

    public $sub(event: string, callback: (value: any) => void, error: (error: any) => void): Subscription;

    public $sub(
        event: string,
        callback: (value: any) => void,
        error: (error: any) => void,
        complete: () => void
    ): Subscription;

    public $sub(event: string, callback?: (value: any) => void, error?: (error: any) => void, complete?: () => void) {
        if (!event) {
            throw new Error(`[${sn}] => Subscription method must get event name.`);
        }

        if (this.events[event] === undefined) {
            this.events[event] = new ReplaySubject<any>();
        }

        if (typeof callback !== 'function') {
            return this.events[event].asObservable();
        } else {
            return this.events[event].asObservable().subscribe(callback, error, complete);
        }
    }

    public $pub(event: string, eventObject?: any) {
        if (!event) {
            throw new Error(`[${sn}] => Publish method must get event name.`);
        } else if (!this.events[event]) {
            return;
        }

        this.events[event].next(eventObject);
    }
}

export interface IPubSubService {
    $pub(event: string, eventObject?: any): void;
    $sub(event: string): Observable<any>;
    $sub(event: string, callback: (value: any) => void): Subscription;
    $sub(event: string, callback: (value: any) => void, error: (error: any) => void): Subscription;
    $sub(
        event: string,
        callback: (value: any) => void,
        error: (error: any) => void,
        complete: () => void
    ): Subscription;
}

interface I$sub {
    (event: string): Observable<any>;
    (event: string, callback: (value: any) => void): Subscription;
    (event: string, callback: (value: any) => void, error: (error: any) => void): Subscription;
    (event: string, callback: (value: any) => void, error: (error: any) => void, complete: () => void): Subscription;
}
