import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Protocol } from '../../../../models/protocol.model';

export interface LinkProtcolModalData {
    title: string;
    text: string;
    protocolId: string;
    protocols: any[];
    buttons: {
        confirm: string;
        cancel: string;
    };
}

type Order = null | 'asc' | 'desc';

interface Sorted {
    key: string;
    order: Order;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-link-protocol-modal',
    templateUrl: './link-protocol-modal.component.html',
    styleUrls: ['./link-protocol-modal.component.scss']
})
export class LinkProtocolModalComponent implements OnInit, OnDestroy {
    data: LinkProtcolModalData;

    keys: string[] = [];
    sorted: Sorted[] = [];
    page = 0;
    pageSize = 20;
    lastPage: number;

    form: FormGroup;

    filteredProtocols: Protocol[] = [];
    pagedProtocols: Protocol[] = [];

    subscriptions: Subscription[] = [];

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            search: [''],
            protocolId: ['', [Validators.required]]
        });
        this.subscriptions.push(this.form.get('search').valueChanges.subscribe(() => this._filterProtocols()));
        if (this.data.protocols && this.data.protocols.length) {
            this.keys = Object.keys(this.data.protocols[0]);
            this.keys.forEach(k => {
                this.sorted.push({ key: k, order: null });
            });
            this._setSorted('id');
        }
        this._filterProtocols();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    onSort(event) {
        const key = event.target.innerHTML;
        this._setSorted(key);
        this._filterProtocols();
    }

    onClickFirst() {
        this.page = 0;
        this._filterProtocols();
    }

    onClickPrevious() {
        if (this.page) {
            this.page = this.page - 1;
            this._filterProtocols();
        }
    }

    onClickNext() {
        if ((this.page + 1) * this.pageSize < this.filteredProtocols.length) {
            this.page = this.page + 1;
            this._filterProtocols();
        }
    }

    onClickLast() {
        this.page = this.lastPage;
        this._filterProtocols();
    }

    onSubmit() {
        this.activeModal.close({ connectProtocolId: this.form.get('protocolId').value });
    }

    _setSorted(key: string) {
        key = key.trim();
        let order = this.sorted.find(s => s.key === key).order;
        order = order ? (order === 'asc' ? 'desc' : null) : 'asc';
        this.sorted.forEach(s => (s.order = s.key === key ? order : null));
        this._filterProtocols();
    }

    _filterProtocols(reset = false) {
        const search = this.form.get('search').value;
        if (reset) {
            this.page = 0;
        }
        if (search.length) {
            this.filteredProtocols = this.data.protocols.filter(protocol => {
                let found = false;
                ['id', ...this.keys].forEach(k => {
                    if (!found) {
                        found = protocol[k].toLowerCase().indexOf(search.toLowerCase()) !== -1;
                    }
                });
                return found;
            });
        } else {
            this.filteredProtocols = this.data.protocols;
        }

        // Exclude the current protocol
        this.filteredProtocols = this.filteredProtocols.filter(p => p.id !== this.data.protocolId);

        // Sort
        const found = this.sorted.find(s => !!s.order);
        if (found) {
            const key = found.key;
            const f = found.order === 'asc' ? 1 : -1;
            this.filteredProtocols = this.filteredProtocols.sort((a, b) =>
                a[key] < b[key] ? -f : b[key] < a[key] ? f : 0
            );
        }

        // Paginate
        this.lastPage = Math.floor((this.filteredProtocols.length - 1) / this.pageSize);
        const offset = this.page * this.pageSize;
        this.pagedProtocols = this.filteredProtocols.filter(
            (_, index) => index >= offset && index < offset + this.pageSize
        );

        // Reset protocolId if no longer present in filtered list
        const ctrlProtocolId = this.form.get('protocolId');
        const protocolId = ctrlProtocolId.value;
        if (protocolId && !this.pagedProtocols.find(p => p.id === protocolId)) {
            ctrlProtocolId.reset();
        }
    }

    trackByFn = (index, item) => item.id || index;
}
