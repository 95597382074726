import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { DashboardTag } from '@alii-web/models/dashboard-tag.model';

export enum DashboardTagActionTypes {
    LoadDashboardTags = '[DashboardTag] Load DashboardTags',
    AddDashboardTag = '[DashboardTag] Add DashboardTag',
    UpdateDashboardTag = '[DashboardTag] Update DashboardTag',
    UpdateDashboardTagSuccess = '[DashboardTag] Update DashboardTag Success',
    SortDashboardTag = '[DashboardTag] Sort DashboardTag',
    SortDashboardTagSuccess = '[DashboardTag] Sort DashboardTag Success',
    DeleteDashboardTag = '[DashboardTag] Delete DashboardTag',
    DeleteDashboardTagSuccess = '[DashboardTag] Delete DashboardTag Success',
    ClearDashboardTags = '[DashboardTag] Clear DashboardTags',
    SelectDashboardTag = '[DashboardTag] Select DashboardTag'
}

export class LoadDashboardTags implements Action {
    readonly type = DashboardTagActionTypes.LoadDashboardTags;

    constructor(public payload: { dashboardTags: DashboardTag[] }) {}
}

export class AddDashboardTag implements Action {
    readonly type = DashboardTagActionTypes.AddDashboardTag;

    constructor(public payload: { dashboardTag: DashboardTag }) {}
}

export class UpdateDashboardTag implements Action {
    readonly type = DashboardTagActionTypes.UpdateDashboardTag;

    constructor(public payload: { dashboardTag: Update<DashboardTag> }) {}
}

export class UpdateDashboardTagSuccess implements Action {
    readonly type = DashboardTagActionTypes.UpdateDashboardTagSuccess;

    constructor(public payload: { dashboardTag: Update<DashboardTag> }) {}
}

export class SortDashboardTag implements Action {
    readonly type = DashboardTagActionTypes.SortDashboardTag;

    constructor(public payload: { dashboardTag: any }) {}
}

export class SortDashboardTagSuccess implements Action {
    readonly type = DashboardTagActionTypes.SortDashboardTagSuccess;

    constructor(public payload: { dashboardTag: any }) {}
}

export class DeleteDashboardTag implements Action {
    readonly type = DashboardTagActionTypes.DeleteDashboardTag;

    constructor(public payload: { id: string }) {}
}

export class DeleteDashboardTagSuccess implements Action {
    readonly type = DashboardTagActionTypes.DeleteDashboardTagSuccess;
}

export class ClearDashboardTags implements Action {
    readonly type = DashboardTagActionTypes.ClearDashboardTags;
}
export class SelectDashboardTag implements Action {
    readonly type = DashboardTagActionTypes.SelectDashboardTag;
    constructor(public payload: { tagId: string | number }) {}
}

export type DashboardTagActions =
    | LoadDashboardTags
    | AddDashboardTag
    | UpdateDashboardTag
    | UpdateDashboardTagSuccess
    | SortDashboardTag
    | SortDashboardTagSuccess
    | DeleteDashboardTag
    | DeleteDashboardTagSuccess
    | ClearDashboardTags
    | SelectDashboardTag;
