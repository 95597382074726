import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromStore from './../../store';
import { Category } from '../../../../models/category.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-organisations',
    templateUrl: './protocols.component.html',
    styleUrls: ['./protocols.component.scss']
})
export class ProtocolsComponent implements OnInit {
    loading$: Observable<boolean>;
    protocolCategories$: Observable<Category[]>;

    constructor(private store: Store<fromStore.ProtocolsFeatureState>) {
    }

    ngOnInit() {
        this.loading$ = this.store.select(fromStore.getProtocolCategoriesLoading);
        this.protocolCategories$ = this.store.select(fromStore.getProtocolCategories);
        this.store.dispatch(new fromStore.LoadProtocolCategories());
    }

    trackByFn = (index, item) => item.id || index;
}
