import { createSelector } from '@ngrx/store';

import * as fromOutcomeReducer from '../reducers/outcome.reducer';
import * as fromReducers from '../reducers';
import { Outcomes } from '../reducers/outcome.reducer';

export const getOutcomeState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.outcome
);

export const getOutcomeEntries = createSelector(
    getOutcomeState,
    fromOutcomeReducer.getOutcomeEntries
);

export const getAllOutcomes = createSelector(getOutcomeEntries, entries => {
    return Object.keys(entries).map(id => entries[id]);
});

export const getAllOutcomeIds = createSelector(getAllOutcomes, entries => {
    return entries ? entries.filter(entry => !!entry).map(entry => entry.Outcome.id) : [];
});

export const getOutcomeEntry = () => createSelector(
    getOutcomeEntries,
    (entities: Outcomes, ppdId: string) => entities[ppdId]
);
