import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { TermsGetRO } from '../models/terms.interface';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TermsService {
    constructor(private http: HttpClient) {}

    get(): Observable<TermsGetRO> {
        const url = `${environment.baseUrl}/api/protocol/organization/getTerms.vm`;
        return this.http.get<TermsGetRO>(url);
    }

    accept(): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/user/updateUser.vm`;
        const body = { acceptTerms: true };
        return this.http.post<TermsGetRO>(url, body);
    }
}
