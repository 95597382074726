import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

// Translation stuff, see: https://github.com/ngx-translate/core
import { TranslateModule } from '@ngx-translate/core';

import * as fromContainers from './containers';
import * as fromServices from './services';
import { reducers, effects } from './store';
import { PartialsModule } from '../../partials/partials.module';
import { SearchBoxModule } from './modules';
import { ConvertType2Text } from './pipes';
import { SearchRoutingModule } from './search-routing.module';

@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forFeature(effects),
        FormsModule,
        NgbModule,
        PartialsModule,
        SearchBoxModule,
        SearchRoutingModule,
        StoreModule.forFeature('SearchFeature', reducers),
        TranslateModule.forChild({ extend: true })
    ],
    providers: [...fromServices.services],
    declarations: [...fromContainers.containers, ConvertType2Text],
    exports: [...fromContainers.containers]
})
export class SearchModule {}
