import * as fromActions from '../actions';
import produce from 'immer';
import { Action } from '@ngrx/store';

export interface ProtocolAllOutcomesState {
    selected: any;
    loaded: boolean;
    loading: boolean;
}

export const initialState: ProtocolAllOutcomesState = {
    selected: {},
    loaded: false,
    loading: false
};

export const producer = (draft, action: fromActions.ProtocolAllOutcomesAction) => {
    const { type, payload } = action;
    switch (type) {
        case fromActions.LOAD_PROTOCOL_WITH_ALL_OUTCOMES: {
            draft.loading = true;
            break;
        }

        case fromActions.LOAD_PROTOCOL_WITH_ALL_OUTCOMES_SUCCESS: {
            const { protocol, questions, populations } = payload;
            draft.loading = false;
            draft.loaded = true;
            draft.selected.protocol = protocol;
            draft.selected.questions = questions;
            draft.selected.populations = populations;
            break;
        }
        case fromActions.LOAD_PROTOCOL_WITH_ALL_OUTCOMES_FAIL: {
            draft.loading = false;
            draft.loaded = false;
            break;
        }

        case fromActions.UPDATE_POPULATION_NAME: {
            const { outcomeId, populationId, name } = payload;

            const populationIndex = draft.selected.populations[outcomeId]
                ? draft.selected.populations[outcomeId].findIndex(population => population.id === populationId)
                : null;
            if (populationIndex) draft.selected.populations[outcomeId][populationIndex].name = name;
            break;
        }

        case fromActions.DELETE_POPULATION: {
            break;
        }
    }
};

export function reducer(state = initialState, action: Action) {
    return produce(producer, initialState)(state, action as fromActions.ProtocolAllOutcomesAction);
}

export const getProtocolWithAllOutcomes = (state: ProtocolAllOutcomesState) => state.selected;
export const getProtocolPopulations = (state: ProtocolAllOutcomesState) => state.selected.populations;
export const getProtocolWithAllOutcomesLoading = (state: ProtocolAllOutcomesState) => state.loading;
export const getProtocolWithAllOutcomesLoaded = (state: ProtocolAllOutcomesState) => state.loaded;
