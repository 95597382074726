import { Action } from '@ngrx/store';

import { SetDashboardDTO, SetDashboardRO } from '../../models/set-dashboard.model';

export const SET_DASHBOARD = '[Set Dashboard] Set';
export const SET_DASHBOARD_FAIL = '[Set Dashboard] Fail';
export const SET_DASHBOARD_SUCCESS = '[Set Dashboard] Success';

export class SetDashboard implements Action {
    readonly type = SET_DASHBOARD;

    constructor(public payload: SetDashboardDTO) {}
}

export class SetDashboardSucces implements Action {
    readonly type = SET_DASHBOARD_SUCCESS;

    constructor(public payload: SetDashboardRO) {}
}

export class SetDashboardFail implements Action {
    readonly type = SET_DASHBOARD_FAIL;

    constructor(public payload: { message: string }) {}
}

export type SetDashboardAction = SetDashboard | SetDashboardSucces | SetDashboardFail;
