import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { debounceTime, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromStore from '../../store';
import { addTextFieldToSearchResults, SearchBoxMatch, SearchDTO, SearchRO, SearchType } from '../../models';
import { convertSelect } from '../../lib';
import { forkJoin } from 'rxjs';
import { LanguageService } from 'apps/web/src/app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() id: string;
    @Input() placeholder: string;
    @Input() protocolId = null;
    @Input() query: string;
    @Input() hideDropdown: boolean;
    @Input() selectText: boolean;
    @Input() filter: SearchType = 'all';
    // Maximum number of items to display in the dropdown.
    @Input() max: number;
    // Enter key goes to search instead of select.
    @Input() enterKey: boolean;

    @Output() select = new EventEmitter<SearchBoxMatch>();
    @Output() focus = new EventEmitter<void>();
    @Output() blur = new EventEmitter<void>();

    @ViewChild('searchBox') searchBox: ElementRef;

    // Current query value updated by ngb-typeahead
    asyncSelected: string;
    datasource$: Observable<SearchRO>;

    searchDTO: SearchDTO;

    changeListener: () => void;

    // True if enterKey enabled and the enter key down event is detected.
    enterKeyDown: boolean;

    subscriptions: Subscription[] = [];

    constructor(
            private renderer: Renderer2, 
            private store: Store<fromStore.SearchFeatureState>,
            private translate: TranslateService) {}

    ngOnInit() {
        const ds$ = (searchDTO: SearchDTO) => {
            if (this.asyncSelected === '') {
                return EMPTY;
            } else {
                searchDTO.protocolId = searchDTO.protocolId || "";
                searchDTO.filter = this.filter !== 'all' ? (this.filter as SearchType) : null;
                this.store.dispatch(new fromStore.SearchGet(searchDTO));
                return this.store.select(fromStore.getSearchGetSuccess).pipe(
                    map(results => results || []),
                    // If max is passed then take only that number of results.
                    map(results => (this.max ? results.slice(0, this.max) : results)),
                    map(results => addTextFieldToSearchResults(results))
                );
            }
        };

        this.datasource$ = Observable.create(observer => {
            observer.next(this.asyncSelected);
        }).pipe(
            debounceTime(500),
            distinctUntilChanged(),
            mergeMap((q: string) => ds$({ q, protocolId: this.protocolId })),
            map(x => (this.hideDropdown ? [] : x)),
            // If max is passed then add to the end of the dropdown an item for going to the search page..
            map(results => (this.max ? this._appendSearchItem(results) : results))
        );

        this.subscriptions.push(
            this.store.select(fromStore.getSearchGetPayload).subscribe(payload => (this.searchDTO = payload))
        );
    }

    ngAfterViewInit() {
        // This seems to be the only way to detect when the query string is set to the empty string, there might
        // be a better way to do this but for now this works just fine. IMPORTANT: Do not forget to call
        // changeListener() on destroy.
        const el: HTMLInputElement = this.searchBox.nativeElement;
        if (this.changeListener) {
            this.changeListener();
        }
        this.changeListener = this.renderer.listen(el, 'input', () => {
            if (this.asyncSelected === '') {
                this.store.dispatch(new fromStore.SearchGetClear());
            }
        });

        if (this.enterKey) {
            this.searchBox.nativeElement.onkeydown = e => {
                // Triggered by keydown enter so go to search page instead.
                if (e.key === 'Enter') {
                    this.select.next({ type: 'go to' });
                    e.stopPropagation();
                    return false;
                }
            };
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        const selectText = changes.selectText;
        if (selectText && selectText.currentValue) {
            const el: HTMLInputElement = this.searchBox.nativeElement;
            setTimeout(() => {
                el.focus();
                el.select();
            }, 1000);
        }

        const filter = changes.filter;
        if (filter && filter.currentValue && !filter.firstChange) {
            const searchDTO: SearchDTO = { ...this.searchDTO };
            searchDTO.filter = filter.currentValue !== 'all' ? (filter.currentValue as SearchType) : null;
            this.store.dispatch(new fromStore.SearchGet(searchDTO));
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];

        if (this.changeListener) {
            this.changeListener();
        }
    }

    onFocus(): void {
        this.focus.next();
    }

    onBlur(): void {
        this.blur.next();
    }

    typeaheadOnSelect(e: TypeaheadMatch): void {
        const match = convertSelect(e.item);
        if (match) {
            this.select.next(match);
        }
    }

    private _appendSearchItem(results): any {
        // Add to the end of the dropdown an item for going to the search page, it has the type 'go to'.
        const keys = Object.keys(results).sort();
        const key = keys.length ? +keys[keys.length - 1] + 1 : 0;
        let searchTitle = ''
        let searchText = ''
 
        forkJoin({
            title: this.translate.get('SEARCHTITLE'),
            text: this.translate.get('SEARCHTEXT')
        }).subscribe({
            next({ title, text }) {
                searchTitle = title;
                searchText = text;
            }
        });
 
        results[key] = { title: searchTitle, id: '', type: 'go to', text: searchText };
        return results;
    }
}
