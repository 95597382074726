import { createSelector } from '@ngrx/store';

import * as fromModelOutcomesReducer from '../reducers/models-outcomes.reducer';
import * as fromReducers from '../reducers';

export const getModelOutcomeState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.modelsOutcomes
);

export const getModelOutcomeEntries = createSelector(
    getModelOutcomeState,
    fromModelOutcomesReducer.getModelOutcomeEntries
);
