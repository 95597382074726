<alii-web-block-ui-spinner [filterBy]="blockUiFilterBy">
    <div class="layout">
        <alii-web-header></alii-web-header>
        <div class="layout__page">
            <main id="content" class="layout__content">
                <div class="page -small-top">
                    <div class="page__content">
                        <router-outlet></router-outlet>
                    </div>
                    <div class="page__corner -top -right"></div>
                </div>
            </main>
        </div>
    </div>
    <alii-web-log-out-button></alii-web-log-out-button>
</alii-web-block-ui-spinner>
