import { createSelector } from '@ngrx/store';

import * as fromGradeAssessmentReducer from '../reducers/grade-assessment.reducer';
import * as fromReducers from '../reducers';

export const getGradeAssessmentState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.gradeAssessment
);

export const getGradeAssessment = createSelector(
    getGradeAssessmentState,
    fromGradeAssessmentReducer.getGradesAssessment
);
