import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromLiteraturesCollection from './literatures-collection.reducer';
import * as fromPubmed from './pubmed.reducer';
import * as fromProtocol from './protocol.reducer';

export interface LiteraturesFeatureState {
    literaturesCollection: fromLiteraturesCollection.LiteratureCollectionState;
    pubmeds: fromPubmed.PubmedState;
    protocol: fromProtocol.ProtocolState;
}

export const reducers: ActionReducerMap<LiteraturesFeatureState> = {
    literaturesCollection: fromLiteraturesCollection.reducer,
    pubmeds: fromPubmed.reducer,
    protocol: fromProtocol.reducer
};

export const getLiteraturesFeatureState = createFeatureSelector<LiteraturesFeatureState>('LiteraturesFeature');
