import { Action } from '@ngrx/store';
import { TermsGetRO } from '@alii-web/models/terms.interface';

export const TERMS_GET = '[Terms] Terms Get';
export const TERMS_GET_FAIL = '[Terms] Terms Get Fail';
export const TERMS_GET_SUCCESS = '[Terms] Terms Get Success';

export class TermsGet implements Action {
    readonly type = TERMS_GET;

    constructor() {}
}

export class TermsGetFail implements Action {
    readonly type = TERMS_GET_FAIL;

    constructor(public payload: { message: string }) {}
}

export class TermsGetSucces implements Action {
    readonly type = TERMS_GET_SUCCESS;

    constructor(public payload: TermsGetRO) {}
}

export const TERMS_ACCEPT = '[Terms] Terms Accept';
export const TERMS_ACCEPT_FAIL = '[Terms] Terms Accept Fail';
export const TERMS_ACCEPT_SUCCESS = '[Terms] Terms Accept Success';

export class TermsAccept implements Action {
    readonly type = TERMS_ACCEPT;

    constructor() {}
}

export class TermsAcceptFail implements Action {
    readonly type = TERMS_ACCEPT_FAIL;

    constructor(public payload: { message: string }) {}
}

export class TermsAcceptSucces implements Action {
    readonly type = TERMS_ACCEPT_SUCCESS;

    constructor() {}
}

export type TermsAction = TermsGet | TermsGetFail | TermsGetSucces | TermsAccept | TermsAcceptFail | TermsAcceptSucces;
