<section class="page__section -no-bottom -transparent">
    <a
    class="float-right"
    [ngbTooltip]="tooltip['dashboard']"
    (click)="onDashboard()"
    >
        {{ 'DASHBOARD-MANAGEMENT' | translate }}
    </a>
    <div class="filter-header sectionTitle -small-bottom">
        <div class="sectionTitle__title">
            {{itemName + '.MANAGE.TITLE' | translate }}
        </div>
    </div>
    
</section>

<section class="page__section">
    <div class="col-md-12">
        <span *ngIf="loadingListItem || loadingAllListItems" class="spinner-grow spinner-grow-sm"></span>
        <h2>{{'TITLE' | translate}}</h2>
        <form *ngIf="!(loadingListItem || loadingAllListItems)" [formGroup]="form"
              (submit)="onSubmit()">
            <div *ngIf="editMode !== 'create'" class="form-group pull-right">
                <div class="btn-group col-5" ngbDropdown>
                    <button id="selectCurrentListItem" type="button"
                            class="button -light"
                            ngbDropdownToggle
                    >
                        <span *ngIf="!currentListItem?.id">{{ itemName + '.MANAGE.SELECT' | translate }}</span>
                        <ng-container *ngIf="currentListItem?.id">
                            <span>{{ currentListItem?.id}} | {{ currentListItem?.title }}</span>
                            <span
                                class="badge badge-pill badge-secondary float-none m-1 ml-3">{{ getMetaValue(currentListItem, 'protocols') }}</span>
                        </ng-container>
                    </button>
                    <ul class="dropdown-menu right" ngbDropdownMenu>
                        <ng-container *ngxPermissionsOnly="[permissionKeys.createListItem]">
                            <li
                                (click)="onCreate()">
                                <a class="dropdown-item cursor-pointer">
                                    <span
                                        *ngIf="editMode !== 'create'">{{ itemName + '.MANAGE.SELECT-CREATE' | translate }}</span>
                                </a>
                            </li>
                            <li>
                                <div class="dropdown-divider"></div>
                            </li>
                        </ng-container>
                        <ng-container *ngFor="let item of allListItems; trackBy: trackByFn">
                            <li *ngIf="currentListItem?.id !== item.id"
                                (click)="selectListItem(item.id)">
                                <a class="dropdown-item cursor-pointer">
                                    <span>{{ item.id}} | {{ item.title }}</span>
                                    <span
                                        class="badge badge-pill badge-secondary">{{ getMetaValue(item, 'protocols') }}</span>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
            <div *ngIf="editMode === 'create'" class="form-group">
                <div class="btn-group" ngbDropdown>
                    <button id="selectCurrentListItemCreate" type="button"
                            class="button -light"
                    >
                        <span *ngIf="form.value.title">{{ form.value.title }}</span>
                    </button>
                </div>
            </div>
            <ng-container *ngIf="editMode !== 'list'">
                <div class="form-group col-md-6">
                    <input
                        placeholder="enter title"
                        id="titleUpdate"
                        type="text"
                        class="form-control"
                        formControlName="title"
                        [readonly]="!permissions[permissionKeys.updateTitle]"
                        [size]="maxlength"
                        [maxlength]="maxlength"
                    />
                    <small id="titleUpdateHelp"
                           class="form-text text-muted">{{itemName + '.MANAGE.MAXLEN' | translate}} {{maxlength}}
                        .</small>
                </div>

                <div class="form-group">
                    <h2>{{'_CATEGORY.MANAGE.SUBITEMS' | translate}}</h2>
                    <div class="row">
                        <div class="col-md-6">
                            <span><label
                                for="selectedSubItems">{{ itemName + '.MANAGE.SUBITEM-LIST.SELECTED' | translate}}</label>:</span>
                            <span
                                class="badge badge-pill badge-secondary">{{ selectedSubItems?.length }}</span>
                            <ng-container *ngxPermissionsOnly="[permissionKeys.setPosition]">
                                <ng-container *ngIf="indexSelectedSubItem && selectedSubItems.length > 1">
                                    <button type="button" class="btn btn-sm btn-outline-secondary m-1"
                                            [disabled]="indexSelectedSubItem === 1"
                                            (click)="onUp()"><i class="fa fa-caret-up"></i></button>
                                    <button type="button" class="btn btn-sm btn-outline-secondary m-1"
                                            [disabled]="!indexSelectedSubItem || indexSelectedSubItem > selectedSubItems.length - 1"
                                            (click)="onDown()"><i class="fa fa-caret-down"></i></button>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col-md-6">
                            <span><label
                                for="availableSubItems">{{ itemName + '.MANAGE.SUBITEM-LIST.AVAILABLE' | translate}}</label>:</span>
                            <span
                                class="badge badge-pill badge-secondary">{{ availableSubItems?.length }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <select
                                id="selectedSubItems"
                                class="form-control custom-select"
                                formControlName="subItems"
                                [size]="sizeSelected"
                            >
                                <option
                                    *ngFor="let subItem of selectedSubItems; trackBy: trackByFn"
                                    (dblclick)="dblclickSelectedSubItem(subItem)"
                                    (click)="clickSelectedSubItem(subItem)"
                                    [value]="subItem.id">{{subItem.position}} | {{subItem.title}} ({{subItem.id}})
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <select
                                id="availableSubItems"
                                class="form-control custom-select"
                                [size]="sizeAvailable"
                            >
                                <option *ngFor="let subItem of availableSubItems; trackBy: trackByFn"
                                        (dblclick)="dblclickAvailableSubItem(subItem)"
                                        (click)="clickAvailableSubItem(subItem)"
                                        [value]="subItem.id">{{ subItem.id }} | {{subItem.title}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" role="group">
                            <button
                                type="submit"
                                class="btn btn-primary m-1"
                                [ngbTooltip]="tooltip['update']"
                                [disabled]="form.invalid || loadingUpdate || loadingCreate"
                            >
                        <span *ngIf="loadingCreate || loadingUpdate" class="spinner-grow spinner-grow-sm"
                              role="status"></span>
                                {{ (editMode === 'create' ? 'CREATE' : 'UPDATE') | translate }}
                            </button>

                            <button
                                type="button"
                                class="btn btn-outline-secondary m-1"
                                [ngbTooltip]="tooltip['reset']"
                                (click)="onReset()"
                            >
                                {{ 'RESET' | translate }}
                            </button>

                            <button
                                type="button"
                                class="btn btn-outline-secondary m-1"
                                *ngIf="editMode === 'create'"
                                (click)="onCancel()"
                            >
                                {{ 'CANCEL' | translate }}
                            </button>



                            <ng-container *ngxPermissionsOnly="[permissionKeys.removeListItem]">
                                <button
                                    type="button"
                                    class="btn btn-danger m-1 ml-4 float-right"
                                    *ngIf="editMode === 'update'"
                                    [ngbTooltip]="tooltip['delete']"
                                    [disabled]="allListItems?.length === 1 || loadingDelete"
                                    (click)="onDelete()"
                                >
                                        <span *ngIf="loadingDelete" class="spinner-grow spinner-grow-sm"
                                              role="status"></span>
                                    {{ 'DELETE' | translate }}
                                </button>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" role="group">
                            <button
                                type="button"
                                class="btn btn-primary m-1 float-right"
                                *ngIf="['update', 'create'].includes(editMode) && (indexAvailableSubItem || indexSelectedSubItem)"
                                [ngbTooltip]="tooltip['manage']"
                                (click)="onManageSubItem()"
                            >
                                {{ itemName + '.MANAGE.MANAGE' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="development">
                <div class="row mt-2">
                    {{ debugPermissions() }}
                </div>
            </ng-container>
        </form>
    </div>
</section>
