import * as fromUsers from '../actions/users.action';

import { User } from '../../../../models/user.interface';

export interface UsersState {
    data: User[];
    loaded: boolean;
    loading: boolean;
}

export const initialState: UsersState = {
    data: [],
    loaded: false,
    loading: false
};

export function reducer(state = initialState, action: fromUsers.UsersAction): UsersState {
    switch (action.type) {
        case fromUsers.LOAD_USERS: {
            return { ...state, loading: true };
        }

        case fromUsers.LOAD_USERS_SUCCESS: {
            const data = action.payload;
            return { ...state, loading: false, loaded: true, data: action.payload };
        }

        case fromUsers.LOAD_USERS_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
    }
    return state;
}

export const getUsers = (state: UsersState) => state.data;
export const getUsersLoading = (state: UsersState) => state.loading;
export const getUsersLoaded = (state: UsersState) => state.loaded;
