<alii-web-spinner-loading *ngIf="(loading$ | async)"></alii-web-spinner-loading>
<ng-container *ngIf="!(loading$ | async)">
    <section class="page__section -no-bottom -transparent">
        <div class="protocollist sectionTitle">
            <div class="sectionTitle__title">All protocols</div>
            <div class="sectionTitle__actions">
                <label for="search"></label>
                <input
                    class="tabel-search form-control"
                    type="text"
                    id="search"
                    placeholder="Filter"
                />
            </div>
        </div>
        <div class="button-group float-right"><a [routerLink]="['/protocols/create']">New</a></div>
    </section>

    <ng-container *ngFor="let category of (protocolCategories$ | async); trackBy: trackByFn">
        <section class="page__section -no-bottom -transparent">
            <div class="dashboard__title">{{ category.label }}</div>
        </section>
        <section class="page__section -no-bottom">
            <div class="dashboard">
                <section class="page__section -no-bottom">
                    <div class="table-responsive">
                        <table class="table -aliiTable">
                            <thead>
                            <tr>
                                <th
                                    class="table__text table__title table__column-40"
                                    scope="col"
                                >
                                    Protocol
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let protocol of category.protocols; trackBy: trackByFn">
                                <td class="table__text table__title table__actions">
                                    <a
                                        [routerLink]="['/protocols/', protocol.id]"
                                        class="table__link"
                                        [innerHTML]="protocol.title"
                                    >
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </section>
    </ng-container>
</ng-container>
