import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from './../../store';
import * as fromService from '../../../../services/dashboard.service';
import * as fromOrganisationService from '../../../../services/organisation.service';
import { Router } from '@angular/router';

@Component({
    selector: 'alii-web-profile-edit',
    providers: [fromService.DashboardService],
    templateUrl: './add-user.component.html'
})
export class AddUserComponent implements OnInit {
    dashboards$: Observable<any>;
    organisation$: Observable<any>;

    constructor(
        private store: Store<fromStore.OrganisationsFeatureState>,
        private DashboardService: fromService.DashboardService,
        private OrganisationService: fromOrganisationService.OrganisationService,
        private router: Router
    ) {}

    ngOnInit() {
        this.dashboards$ = this.DashboardService.getDashboard();
        this.organisation$ = this.OrganisationService.getOrganization();
    }

    handleSubmit(event) {
        const data = {
            email: event.email,
            firstName: event.first_name,
            lastName: event.last_name,
            prefix: event.prefix,
            hospitalId: event.hospitalid,
            dashboardId: event.dashboard,
            teamId: event.teamId
        };

        if (event.setAdmin && event.setAdmin === 'yes') {
            data['setAdmin'] = 'yes';
        }

        this.store.dispatch(new fromStore.CreateUser(data));
        this.router.navigate(['organization/teams/', event.teamId, 'users']);
    }
}
