import { NgModule } from '@angular/core';
import { UrlSegment, Routes, RouterModule, UrlMatchResult } from '@angular/router';

import * as fromContainers from './containers';


const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
// Matches on '', '\d+', guid, or 'create
function dashboardManageV2Matcher(segments: UrlSegment[]): UrlMatchResult {

    if (!segments.length || (segments.length === 1 && (segments[0].path.match(/^\d+|create$/i)) || segments[0].path.match(guidPattern))) {
        const posParams: { [key: string]: UrlSegment } = {};
        const consumed: UrlSegment[] = [];

        if (segments.length) {
            posParams['id'] = segments[0];
            consumed.push(segments[0]);
        }

        return { consumed, posParams };
    }

    return null;
}

// Matches on 'category', 'category/\d+', guid, or 'category/create
function categoryManageV2Matcher(segments: UrlSegment[]): UrlMatchResult {
    if ((segments.length === 1 || segments.length === 2) && segments[0].path.match(/^category$/i)) {
        const posParams: { [key: string]: UrlSegment } = {};
        const consumed: UrlSegment[] = [segments[0]];

        if (segments.length === 2) {
            if (segments[1].path.match(/^\d+|create$/i) || segments[1].path.match(guidPattern)) {
                posParams['id'] = segments[1];
                consumed.push(segments[1]);
            } else {
                return null;
            }
        }

        return { consumed, posParams };
    }

    return null;
}

const routes: Routes = [
    {
        // Matches on '', '\d+' or 'create
        matcher: dashboardManageV2Matcher,
        component: fromContainers.DashboardManageV2Component
    },
    {
        // Matches on 'category', 'category/\d+' or 'category/create
        matcher: categoryManageV2Matcher,
        component: fromContainers.CategoryManageV2Component
    },
    {
        path: 'tag/:id/edit',
        component: fromContainers.DashboardTagEditContainerComponent
    },
    {
        path: 'tag/manage',
        component: fromContainers.DashboardTagManageComponent
    },
    {
        path: 'statistics',
        component: fromContainers.DashboardStatisticsComponent
    },
    {
        path: ':id/edit',
        component: fromContainers.DashboardEditContainer
    },
    {
        path: ':id/tags',
        component: fromContainers.DashboardTagManageComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule {}
