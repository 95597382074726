import * as fromDashboard from '../actions/dashboard-summary.action';
import { DashboardSummary } from '@alii-web/models/dashboard-summary.model';

export interface DashboardSummaryState {
    data: DashboardSummary;
    loaded: boolean;
    loading: boolean;
    permissions: [];
}

export const initialState: DashboardSummaryState = {
    data: {},
    loaded: false,
    loading: false,
    permissions: []
};

export function reducer(state = initialState, action: fromDashboard.DashboardSummaryAction): DashboardSummaryState {
    switch (action.type) {
        case fromDashboard.LOAD_DASHBOARD_SUMMARY: {
            return {
                ...state,
                loading: true
            };
        }

        case fromDashboard.LOAD_DASHBOARD_SUMMARY_SUCCESS: {
            const data = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                data
            };
        }

        case fromDashboard.LOAD_DASHBOARD_SUMMARY_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false
            };
        }
        case fromDashboard.LOAD_DASHBOARD_PERMISSION: {
            const {
                payload: { permissions }
            } = action;
            return {
                ...state,
                permissions
            };
        }
    }

    return state;
}

export const getDashboardLoading = (state: DashboardSummaryState) => state.loading;
export const getDashboardLoaded = (state: DashboardSummaryState) => state.loaded;
export const getDashboard = (state: DashboardSummaryState) => state.data;
export const getDashboardCategory = (state: DashboardSummaryState) => state.data.dashboard_categories;
export const getDashboardPermissions = (state: DashboardSummaryState) => state.permissions;
export const getDashboardView = (state: DashboardSummaryState) => state.data.display_dashboard;
