import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as ProfileActions from '../actions/profile.action';
import * as fromService from '../../../../services/profile.service';
import { of } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class ProfileEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private profileService: fromService.ProfileService,
        private toastr: ToastrService
    ) {}

    @Effect()
    loadProfile$ = this.actions$.pipe(
        ofType(ProfileActions.LOAD_PROFILE, ProfileActions.UPDATE_PROFILE_SUCCESS),
        switchMap(action => {
            const userId = (action as any).userId
            return this.profileService.getUser(userId).pipe(
                map(profile => new ProfileActions.LoadProfileSucces(profile)),
                catchError(error => of(new ProfileActions.LoadProfileFail(error)))
            );
        })
    );

    @Effect()
    updateProfile$ = this.actions$.pipe(
        ofType(ProfileActions.UPDATE_PROFILE),
        switchMap(action => {
            const data = (action as any).payload;
            return this.profileService.updateUser(data).pipe(
                map(response => {
                    this.toastr.success('', 'Profile succesfully saved');
                    if(data.myProfile) {
                        this.router.navigate(['profile']);
                    } else {
                        this.router.navigate(['organization/teams/', data.teamId, 'users']);
                    }
                    return new ProfileActions.UpdateProfileSucces(response);
                }),
                catchError(error => of(new ProfileActions.UpdateProfileFail(error)))
            );
        })
    );
}
