import { Action } from '@ngrx/store';

export const MAP_MODELS_OUTCOMES = '[Protocol-Model] Map Model Outcomes';

export class MapModelOutcomes implements Action {
    readonly type = MAP_MODELS_OUTCOMES;

    constructor(public payload: { outcomes: any[] }) {}
}

export const MAP_MODELS_TAGLIST = '[Protocol-Model] Map Model TagList';

export class MapModelTagList implements Action {
    readonly type = MAP_MODELS_TAGLIST;

    constructor(public payload: { tagList: any[] }) {}
}

export const SORT_OUTCOMES = '[Protocol-Model] Sort Outcomes by Score';

export class SortOutcomes implements Action {
    readonly type = SORT_OUTCOMES;

    constructor() {}
}

export const SHOW_ALL_OUTCOMES = '[Protocols] Show All Outcomes';
export const SHOW_ALL_OUTCOMES_FAIL = '[Protocols] Show All Outcomes Fail';
export const SHOW_ALL_OUTCOMES_SUCCESS = '[Protocols] Show All Outcomes Success';

export class ShowAllOutcome implements Action {
    readonly type = SHOW_ALL_OUTCOMES;

    constructor(public payload: any) {}
}

export class ShowAllOutcomeFail implements Action {
    readonly type = SHOW_ALL_OUTCOMES_FAIL;

    constructor(public payload: any) {}
}

export class ShowAllOutcomeSucces implements Action {
    readonly type = SHOW_ALL_OUTCOMES_SUCCESS;

    constructor(public payload: { outcomes: any, tagList? : any }) {}
}

export const SHOW_OUTCOMES_BY_POPULATION_ID = '[Protocols] Show Outcomes By PopulationId';
export const SHOW_OUTCOMES_BY_POPULATION_ID_FAIL = '[Protocols] Show Outcomes By PopulationId Fail';
export const SHOW_OUTCOMES_BY_POPULATION_ID_SUCCESS = '[Protocols] Show Outcomes By PopulationId Success';

export class ShowOutcomesByPopulationId implements Action {
    readonly type = SHOW_OUTCOMES_BY_POPULATION_ID;

    constructor(public payload: any) {}
}

export class ShowOutcomesByPopulationIdFail implements Action {
    readonly type = SHOW_OUTCOMES_BY_POPULATION_ID_FAIL;

    constructor(public payload: any) {}
}

export class ShowOutcomesByPopulationIdSucces implements Action {
    readonly type = SHOW_OUTCOMES_BY_POPULATION_ID_SUCCESS;

    constructor(public payload: { outcomes: any }) {}
}

export const ADD_OUTCOME = '[Protocols] Add Outcome';
export const ADD_OUTCOME_FAIL = '[Protocols] Add Outcome Fail';
export const ADD_OUTCOME_SUCCESS = '[Protocols] Add Outcome Success';

export class AddOutcome implements Action {
    readonly type = ADD_OUTCOME;

    constructor(public payload: any) {}
}

export class AddOutcomeFail implements Action {
    readonly type = ADD_OUTCOME_FAIL;

    constructor(public payload: any) {}
}

export class AddOutcomeSucces implements Action {
    readonly type = ADD_OUTCOME_SUCCESS;

    constructor(public payload: { parentId: string; outcomes: any }) {}
}

export const UPDATE_OUTCOME = '[Protocols] Update Outcome';
export const UPDATE_OUTCOME_FAIL = '[Protocols] Update Outcome Fail';
export const UPDATE_OUTCOME_SUCCESS = '[Protocols] Update Outcome Success';

export class UpdateOutcome implements Action {
    readonly type = UPDATE_OUTCOME;

    constructor(public payload: any) {}
}

export class UpdateOutcomeFail implements Action {
    readonly type = UPDATE_OUTCOME_FAIL;

    constructor(public payload: any) {}
}

export class UpdateOutcomeSucces implements Action {
    readonly type = UPDATE_OUTCOME_SUCCESS;

    constructor(public payload: { parentId: string; ppdId: string; outcome: any }) {}
}

export const UPDATE_OUTCOME_TITLE = '[Protocols] Update Outcome Title';
export const UPDATE_OUTCOME_TITLE_FAIL = '[Protocols] Update Outcome Title Fail';
export const UPDATE_OUTCOME_TITLE_SUCCESS = '[Protocols] Update Outcome Title Success';

export class UpdateOutcomeTitle implements Action {
    readonly type = UPDATE_OUTCOME_TITLE;

    constructor(public payload: any) {}
}

export class UpdateOutcomeTitleFail implements Action {
    readonly type = UPDATE_OUTCOME_TITLE_FAIL;

    constructor(public payload: any) {}
}

export class UpdateOutcomeTitleSucces implements Action {
    readonly type = UPDATE_OUTCOME_TITLE_SUCCESS;

    constructor(public payload: { parentId: string; ppdId: string; outcome: any }) {}
}

export const DELETE_OUTCOME = '[Protocols] Delete Outcome';
export const DELETE_OUTCOME_FAIL = '[Protocols] Delete Outcome Fail';
export const DELETE_OUTCOME_SUCCESS = '[Protocols] Delete Outcome Success';

export class DeleteOutcome implements Action {
    readonly type = DELETE_OUTCOME;

    constructor(
        public payload: {
            protocolId: string;
            ppdId: string;
            parentId: string;
        }
    ) {}
}

export class DeleteOutcomeSucces implements Action {
    readonly type = DELETE_OUTCOME_SUCCESS;

    constructor(
        public payload: {
            parentId: string;
            ppdId: string;
        }
    ) {}
}

export class DeleteOutcomeFail implements Action {
    readonly type = DELETE_OUTCOME_FAIL;

    constructor(public payload: any) {}
}

export const DELETE_POPULATION_TEST = '[Protocols] Delete pop test';

export class DeletePopulationTest implements Action {
    readonly type = DELETE_POPULATION_TEST;

    constructor(
        public payload: {
            populationId: string;
        }
    ) {}
}

export const UPDATE_OUTCOMES = '[Protocols] Update Outcomes';

export class UpdateOutcomes implements Action {
    readonly type = UPDATE_OUTCOMES;

    constructor(public payload: { outcomes: any[] }) {}
}

export const UPDATE_TAGLIST = '[Protocols] Update Taglist';

export class UpdateTagList implements Action {
    readonly type = UPDATE_TAGLIST;

    constructor(public payload: { tagList: any[] }) {}
}

export type ModelsOutcomesAction =
    | MapModelOutcomes
    | SortOutcomes
    | ShowAllOutcome
    | ShowAllOutcomeFail
    | ShowAllOutcomeSucces
    | ShowOutcomesByPopulationId
    | ShowOutcomesByPopulationIdFail
    | ShowOutcomesByPopulationIdSucces
    | AddOutcome
    | AddOutcomeFail
    | AddOutcomeSucces
    | UpdateOutcome
    | UpdateOutcomeFail
    | UpdateOutcomeSucces
    | UpdateOutcomeTitle
    | UpdateOutcomeTitleFail
    | UpdateOutcomeTitleSucces
    | DeleteOutcome
    | DeleteOutcomeFail
    | DeleteOutcomeSucces
    | DeletePopulationTest
    | UpdateOutcomes
    | UpdateTagList
    | MapModelTagList;
