import { Component, ChangeDetectionStrategy, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Pubmed } from '../../../../models/pubmed.interface';
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'add-non-pubmed-article-modal',
    templateUrl: './add-non-pubmed-article.component.html'
})
export class AddNonPubmedArticleModalComponent implements OnInit {
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    form: FormGroup;
    searchForm: FormGroup;
    formIsValid: boolean;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            title: ['', Validators.required],
            url: ['', Validators.required],
            date: ['', Validators.required],
            author: ['', Validators.required],
            publication: []
        });

        this.formIsValid = false;
        this.form.valueChanges.subscribe(() => {});
    }

    onAddNonPubmedArticle() {
        const data = { ...this.form.value };
        const event = { type: 'handleAddNonPubmedArticle', payload: data };
        this.eventBus.emit(event);
    }

    onCancel() {
        this.activeModal.close();
    }
}
