import { Action } from '@ngrx/store';
import { SearchDTO, SearchPage, SearchRO } from '../../models';

export const SEARCH_GET = '[Search] Get';
export const SEARCH_GET_SUCCESS = '[Search] Get Success';
export const SEARCH_GET_FAIL = '[Search] Fail';
export const SEARCH_GET_CLEAR = '[Search] Clear';
export const SEARCH_SET_ANCHORID = '[Search] Set AnchorId';

export class SearchGet implements Action {
    readonly type = SEARCH_GET;

    constructor(public payload: SearchDTO) {
    }
}

export class SearchGetSucces implements Action {
    readonly type = SEARCH_GET_SUCCESS;

    constructor(public payload: SearchRO) {
    }
}

export class SearchGetFail implements Action {
    readonly type = SEARCH_GET_FAIL;

    constructor(public payload: string) {
    }
}

export class SearchGetClear implements Action {
    readonly type = SEARCH_GET_CLEAR;

    constructor() {
    }
}

export class SearchSetAnchorId implements Action {
    readonly type = SEARCH_SET_ANCHORID;

    constructor( public payload: string) {
    }
}

export const SEARCH_PAGE_SET = '[Search] Page Set';
export const SEARCH_PAGE_RESET = '[Search] Page Reset';

export class SearchPageSet implements Action {
    readonly type = SEARCH_PAGE_SET;

    constructor(public payload: SearchPage) {
    }
}

export class SearchPageReset implements Action {
    readonly type = SEARCH_PAGE_RESET;

    constructor() {
    }
}

export type SearchAction =
    SearchGet |
    SearchGetSucces |
    SearchGetFail |
    SearchGetClear |
    SearchSetAnchorId |
    SearchPageSet |
    SearchPageReset;
