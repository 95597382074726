import { FormButtonComponent } from '../components/dynamic-form/form-button/form-button.component';
import { FormHiddenComponent } from '../components/dynamic-form/form-hidden/form-hidden.component';
import { FormInputComponent } from '../components/dynamic-form/form-input/form-input.component';
import { FormSelectComponent } from '../components/dynamic-form/form-select/form-select.component';
import { FormParagraphIdComponent } from '../components/dynamic-form/form-paragraphId/form-paragraphId.component';
import { FormPopulationIdComponent } from '../components/dynamic-form/form-populationId/form-populationId.component';
import { FormTextareaComponent } from '../components/dynamic-form/form-textarea/form-textarea.component';
import { FormSwitchComponent } from '../components/dynamic-form/form-switch/form-switch.component';
import { FormEditorComponent } from '../components/dynamic-form/form-editor/form-editor.component';

import { SearchArticlesModalComponent } from './search-articles-modal/search-articles-modal.component';
import { AddNonPubmedArticleModalComponent } from './add-non-pubmed-article/add-non-pubmed-article.component';

import { UploadFileModalComponent } from './upload-file/upload-file.component';

export const entryComponents = [
    FormButtonComponent,
    FormHiddenComponent,
    FormInputComponent,
    FormSelectComponent,
    FormParagraphIdComponent,
    FormPopulationIdComponent,
    FormTextareaComponent,
    FormSwitchComponent,
    FormEditorComponent,
    SearchArticlesModalComponent,
    AddNonPubmedArticleModalComponent,
    UploadFileModalComponent
];

export * from '../components/dynamic-form/form-button/form-button.component';
export * from '../components/dynamic-form/form-hidden/form-hidden.component';
export * from '../components/dynamic-form/form-input/form-input.component';
export * from '../components/dynamic-form/form-select/form-select.component';
export * from '../components/dynamic-form/form-paragraphId/form-paragraphId.component';
export * from '../components/dynamic-form/form-textarea/form-textarea.component';
export * from '../components/dynamic-form/form-switch/form-switch.component';
export * from '../components/dynamic-form/form-editor/form-editor.component';

export * from './search-articles-modal/search-articles-modal.component';
export * from './add-non-pubmed-article/add-non-pubmed-article.component';

export * from './upload-file/upload-file.component';
