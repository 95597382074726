<a class="button -primary pull-right" href="/messages/manage" *ngxPermissionsOnly="['Admin']">
    Nieuwsberichten beheren</a
>

<h1>Nieuws</h1>

<input
    type="text"
    placeholder="Doorzoek nieuws"
    class="form-control search-field__input"
    style="border: 1px solid #ddd;"
    size="100"
    #searchQuery
    (keyup)="onSearch(searchQuery.value)"
/>
<br />

<!--
<pre>
    {{ (messages$ | async)?.messages | json }}
</pre>
-->

<div class="card-group">
    <div class="timeline_card  w-100" *ngFor="let message of (messages$ | async)?.messages; trackBy: trackByFn">
        <article class="card">
            <header class="card__header">
                <span class="tag -primary" [innerHTML]="message.category" *ngIf="message.category"></span>
                <span
                    class="tag"
                    style="margin-left: 3px;"
                    [innerHTML]="tag.label"
                    *ngFor="let tag of message.tags"
                ></span>
            </header>
            <section class="timeline_text card-body">
                <strong [innerHTML]="message.title"></strong><br />
                <div [innerHTML]="message.text"></div>
            </section>
            <footer class="card__footer" [innerHTML]="message.date?.slice(0, 10)"></footer>
        </article>
    </div>
</div>

<br style="clear: both;" />
&nbsp;
