import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromLogin from './login.reducer';

export interface LoginFeatureState {
    sso: fromLogin.SSOState;
}

export const reducers: ActionReducerMap<LoginFeatureState> = {
    sso: fromLogin.reducer
};

export const getLoginFeatureState = createFeatureSelector<LoginFeatureState>('LoginFeature');
