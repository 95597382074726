import { createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';
import * as fromProtocolArticles from '../reducers/protocol-articles.reducer';

export const getProtocolArticlesState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.protocolArticles
);

export const getProtocolArticles = createSelector(
    getProtocolArticlesState,
    fromProtocolArticles.getProtocolArticles
);

export const getProtocolArticleSelected = createSelector(
    getProtocolArticlesState,
    fromProtocolArticles.getProtocolArticleSelected
);

export const getProtocolArticlesLoaded = createSelector(
    getProtocolArticlesState,
    fromProtocolArticles.getProtocolArticlesLoaded
);

export const getProtocolArticlesLoading = createSelector(
    getProtocolArticlesState,
    fromProtocolArticles.getProtocolArticlesLoading
);
