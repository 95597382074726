<h1>Manage categories</h1>

<h3>Add new category</h3>
<ng-form [formGroup]="createTagForm" class="form-inline">
    <p>
        New category title:
        <input class="form-control-s" type="text" formControlName="title" /> &nbsp;
        <button class="btn btn-info createNewPop" type="submit" (click)="handleSubmit()">
            submit
        </button>
    </p>
</ng-form>

<h3>Modify existing category</h3>
<div>
    <ul>
        <li *ngFor="let tag of (tags$ | async); trackBy: trackByFn">
            <a [routerLink]="['/dashboard', 'tag', tag.id, 'edit']" [innerHtml]="tag.title"></a>
        </li>
    </ul>
</div>
