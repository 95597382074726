import * as fromActions from '../actions/paragraphs-populations.actions';

export interface ParPopulationsPropState {
    populationId: string;
    updatedPars: any[];
    loading: boolean;
    copiedPopulation: any;
}

export const initialState: ParPopulationsPropState = {
    populationId: null,
    loading: false,
    updatedPars: [],
    copiedPopulation: null
};

export function reducer(state = initialState, action: fromActions.PopulationActions): ParPopulationsPropState {
    switch (action.type) {
        case fromActions.PopulationActionTypes.SetPopulationId: {
            const { populationId } = action.payload;
            // In case we want to reset a population
            if (populationId === '') {
                return initialState;
            }
            return { ...state, populationId };
        }

        case fromActions.PopulationActionTypes.SetCopiedPopulation: {
            const { ppdId, outcome, population, findings } = action.payload;
            return { ...state, copiedPopulation: { ppdId, outcome, population, findings } };
        }

        case fromActions.PopulationActionTypes.UpdateCopiedPopulationSuccess: {
            const { findings } = action.payload;
            return { ...state, copiedPopulation: { ...state.copiedPopulation, findings } };
        }

        case fromActions.PopulationActionTypes.UpdateCopiedPopulationName: {
            const { name } = action.payload;
            return {
                ...state,
                copiedPopulation: {
                    ...state.copiedPopulation,
                    population: {
                        ...state.copiedPopulation.population,
                        name
                    }
                }
            };
        }

        case fromActions.PopulationActionTypes.ClearCopiedPopulation: {
            return { ...state, copiedPopulation: null };
        }

        case fromActions.PopulationActionTypes.UserUpdateModelSuccess: {
            const { populationId } = action.payload;
            return { ...state, loading: true, ...(populationId && { populationId }) };
        }

        case fromActions.PopulationActionTypes.ModelCalculatedSuccess: {
            const { populationId, updated_paragraphs } = action.payload;
            return {
                ...state,
                loading: false,
                populationId,
                updatedPars: { ...state.updatedPars, ...updated_paragraphs }
            };
        }
        case fromActions.PopulationActionTypes.ModelCalculatedFail: {
            return { ...state, loading: false };
        }

        case fromActions.PopulationActionTypes.SelectOutcomeList: {
            return { ...state, loading: true };
        }

        case fromActions.PopulationActionTypes.SelectOutcomeListSuccess: {
            return {
                ...state,
                loading: false
            };
        }
    }
    return state;
}

export const loadPopulationId = (state: ParPopulationsPropState) => state.populationId;
export const getModelLoading = (state: ParPopulationsPropState) => state.loading;
export const getCopiedPopulation = (state: ParPopulationsPropState) => state.copiedPopulation;
