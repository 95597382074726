<section class="page__section -small-bottom">
    <div class="protocollist sectionTitle">
        <div class="sectionTitle__title">{{ name }}</div>
        <div class="sectionTitle__actions" *ngxPermissionsOnly="['Admin']">
            <a role="button" class="btn btn-info" routerLink="add-user"> create a new user </a>
            &nbsp;
            <a role="button" class="btn btn-info" routerLink="/organization/groups"> manage groups </a>
        </div>
    </div>
    <user-list [users]="users$ | async" (eventBus)="handleEventBus($event)"></user-list>
</section>
