import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnChanges
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeyQuestionImport } from '../../../../../../models/keyquestion-import.interface';
import { ConnectKeyQuestionModalComponent } from '../connect-keyquestion-modal/connect-keyquestion-modal.component';
import {
    LinkProtocolModalComponent,
    LinkProtcolModalData,
    UploadFileModalComponent,
    SearchPubmedModalComponent,
    EditTagsModalComponent
} from '../../../../entry-components';
import { ProtocolsService } from '@alii-web/services';
import * as fromStore from './../../../../store';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Protocol } from 'apps/web/src/app/models/protocol.interface';
import { filter, map, take } from 'rxjs/operators';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-paragraph-edit-options',
    templateUrl: './paragraph-edit-options.component.html'
})
export class ParagraphEditOptionsComponent implements OnChanges {
    @Input()
    channelMessages?: any;
    @Input() paragraph: any
    @Input() ppdId: string;
    @Input() protocolId: string;
    @Input() keyQuestionId: string;
    @Input() updateKeyQuestion: boolean;
    @Input() level: number;
    @Input() type?: string;
    @Input() isModel?: boolean;
    @Input() isSummary?: boolean;
    @Input() viewByPopulation? = false;
    @Input() parentId: string;
    @Input() grandParentId: string;
    @Input() gradeAssessmentPar: any;
    @Input() conclusionTags: string;

    @Input()
    keyQuestionImportList: KeyQuestionImport[];
    @Input()
    pubmeds?: any;

    @Input()
    sources?: any;

    @Input()
    sourceInfo?: any;

    @Input()
    parentSources?: any;

    @Input()
    parentSourceTable?: any;

    @Input()
    referencedArticles?: any;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    selectedQuestionIndex = 0;
    isCreateGrade = false;

    handleKeyQuestionImportLoading = false;

    searchQuery = '';
    searchParagraphQuery = '';
    searchModelQuery = '';


    constructor(private modalService: NgbModal, private protocolsService: ProtocolsService, private store: Store<fromStore.ProtocolsFeatureState>,
        ) {}

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.pubmeds && changes.pubmeds && !changes.pubmeds.firstChange) {
            if (!changes.pubmeds.currentValue.loading && changes.pubmeds.currentValue.loaded) {
                setTimeout(() => {
                    this.handleOpenSearch();
                });
            }
        }

        if (changes.keyQuestionImportList && !changes.keyQuestionImportList.firstChange) {
            setTimeout(() => {
                this.handleKeyQuestionImportLoading = false;
                this.modalService.dismissAll();
                this.openKeyQuestionImportModal();
            });
        }

    }

    onClickAddPopulation() {}

    onClickOpenSheetModelImportModal() {
        const action = {
            type: 'onClickOpenSheetModelImportModal',
            payload: {
                parentId: this.ppdId
            }
        };
        this.eventBus.emit(action);
    }

    onClickOpenSheetLiteratureUpdateModal() {
        const action = {
            type: 'onClickOpenSheetLiteratureUpdateModal',
            payload: {
                parentId: this.ppdId
            }
        };
        this.eventBus.emit(action);
    }


    onClickOpenSheetModelUpdateModal() {
        const action = {
            type: 'onClickOpenSheetModelUpdateModal',
            payload: {}
        };
        this.eventBus.emit(action);
    }

    onClickCreateCalculationFormula() {
        const action = {
            type: 'onClickCreateCalculationFormula',
            payload: { ppdId: this.ppdId }
        };
        this.eventBus.emit(action);
    }

    onClickCreateGrade() {
        const action = {
            type: 'onClickCreateGrade',
            payload: {
                ppdId: this.ppdId,
                parentId: this.parentId
            }
        };
        this.eventBus.emit(action);
    }

    onClickAddOutcome() {
        const action = {
            type: 'onClickAddOutcome',
            payload: {}
        };
        this.eventBus.emit(action);
    }

    onClickAddFinding() {
        const action = {
            type: 'onClickAddFinding',
            payload: {}
        };
        this.eventBus.emit(action);
    }

    onClickConnectKQ() {
        this.openKeyQuestionImportModal();
        this.handleKeyQuestionImportLoading = true;
    }

    onUpdateKQ() {
        const action = {
            type: 'handleUpdateKeyQuestion',
            payload: {
                ppdId: this.ppdId,
                parentId: this.parentId,
                protocolId: this.protocolId
            }
        };
        this.eventBus.emit(action);
    }

    openKeyQuestionImportModal() {
        const modalRef = this.modalService.open(ConnectKeyQuestionModalComponent, { size: 'lg' });
        modalRef.componentInstance.protocolId = this.protocolId;
        modalRef.componentInstance.updateKeyQuestion = this.updateKeyQuestion;
        modalRef.componentInstance.keyQuestions = this.keyQuestionImportList ? this.keyQuestionImportList : [];
        modalRef.componentInstance.searchLoading = false;

        modalRef.componentInstance.eventBus.subscribe(event => {
            const { type, payload } = event;
            switch (type) {
                case 'handleGetKeyQSearch':
                    this.handleGetKeyQSearch(payload);
                    break;
                case 'handleSubmitConnectKeyQuestion':
                    this.handleSubmitConnectKeyQuestion(payload);
                    break;
                default:
                    break;
            }
        });
    }


    onClickAddFile() {
        const modalRef = this.modalService.open(UploadFileModalComponent, { size: 'lg' });

        modalRef.componentInstance.eventBus.subscribe(eventAction => {
            this.eventBus.emit({
                ...eventAction, // handleUploadFile
                payload: {
                    ...eventAction.payload,
                    protocolId: this.protocolId,
                    ppdId: this.ppdId
                }
            });
            modalRef.close();
        });
        modalRef.result.then(
            () => {}, // on close with result
            () => {} // on dismiss with reason
        );
    }

    onClickEditTags() {
        const modalRef = this.modalService.open(EditTagsModalComponent, { size: 'lg' });
        modalRef.componentInstance.conclusionId = this.ppdId + "";
        modalRef.componentInstance.tags = this.conclusionTags.replace("|", "//")
        modalRef.result.then(
            () => {}, // on close with result
            () => {} // on dismiss with reason
        );



    }

    onClickDeletePar() {
        const actionData = {
            type: 'handleDeletePar',

            payload: {
                ppdId: this.ppdId,
                parentId: this.parentId,
                grandParentId: this.grandParentId,
                protocolId: this.protocolId
            }
        };

        this.eventBus.emit(actionData);
    }

    onClickAddModel() {
        const action = {
            type: 'handleUpdateParToModel',
            payload: {
                ppdId: this.ppdId,
                parentId: this.parentId,
                protocolId: this.protocolId
            }
        };
        this.eventBus.emit(action);
    }

    onClickConvertToSummary() {
        const action = {
            type: 'handleChangeTypeOfPar',
            payload: {
                ppdId: this.ppdId,
                action: 'setSummary',
                protocolId: this.protocolId
            }
        };
        this.eventBus.emit(action);
    }

    onClickConvertToKeyQuestion() {
        const action = {
            type: 'handleChangeTypeOfPar',
            payload: {
                ppdId: this.ppdId,
                action: 'setKeyQuestion',
                protocolId: this.protocolId
            }
        };
        this.eventBus.emit(action);
    }

    onClickConvertNormalPar() {
        const action = {
            type: 'handleChangeTypeOfPar',
            payload: {
                ppdId: this.ppdId,
                action: 'unsetSummary',
                protocolId: this.protocolId
            }
        };
        this.eventBus.emit(action);
    }

    getModelSearch(payload) {
        const { query } = payload;
        this.searchModelQuery = query;
        const event = {
            type: 'handleLoadModelImport',
            payload: {
                qType: 'Model',
                qQuery: query
            }
        };
        this.eventBus.emit(event);
    }

    handleGetKeyQSearch(payload) {
        const { query } = payload;
        this.searchQuery = query;
        const event = {
            type: 'handleLoadKeyQuestion',
            payload: {
                qType: 'KeyQuestion',
                qQuery: query
            }
        };
        this.eventBus.emit(event);
    }

    handleSubmitConnectKeyQuestion(payload) {

        const { keyQuestionId } = payload;
        const actionData = {
            type: 'handleSubmitConnectKeyQuestion',
            payload: {
                protocolId: this.protocolId,
                ppdId: this.ppdId,
                keyQuestionId
            }
        };
        this.eventBus.emit(actionData);
        this.searchModelQuery = '';
    }

    onClickAddReference() {
        this.handleOpenSearch();
    }

    onClickImportReferences() {
        const action = {
            type: 'handleOpenImportReferencesModal',
            payload: {ppdId: this.ppdId}
        };
        this.eventBus.emit(action);
    }

    onClickDeleteGrade() {
        const action = {
        type: 'onClickDeleteGrade',
        payload: {
            ppdId: this.ppdId,
            parentId: this.parentId
        }
    };
    this.eventBus.emit(action);}

    handleSelectArticles() {
        this.modalService.dismissAll();
    
        const protocol$: Observable<Protocol> = this.store.select(fromStore.getSelected);
    
        // if the parentsourcetable was not populated, retrieve and only after open the modal
        if (!this.parentSourceTable) {
            this.eventBus.emit({
                type: 'handleLoadEvidence',
                payload: {
                    protocolId: this.protocolId,
                    ppdId: this.parentId,
                    parentId: this.grandParentId
                }
            });
    
            protocol$.pipe(
                map(res => {
                    const keyQuestion = res.paragraphs.find(x => x.id == this.parentId);
                    return keyQuestion?.allSourceTable;
                }),
                filter(sourceTable => !!sourceTable),
                take(1)
            ).subscribe(sourceTable => {
                this.parentSourceTable = sourceTable;
                this.openSelectArticleModal();
            });
        } else {
            this.openSelectArticleModal();
        }
    }
    
    private openSelectArticleModal() {
        const modalRef = this.modalService.open(SearchPubmedModalComponent, { size: 'lg' });
        modalRef.componentInstance.sourceTable = this.parentSourceTable;
        modalRef.componentInstance.referencedArticles = this.referencedArticles;
        modalRef.componentInstance.included = this.sourceInfo.ids;
        modalRef.componentInstance.parentId = this.parentId;
        modalRef.componentInstance.ppdId = this.ppdId;
        modalRef.componentInstance.searchLoading = false;
        modalRef.componentInstance.search = false;
    
        modalRef.componentInstance.eventBus.subscribe(event => {
            switch (event.type) {
                case 'handleAddReference':
                this.handleAddReference(event.payload);
                modalRef.dismiss();
                    break;
                default:
                    break;
            }
        });
    }
    

    handleOpenSearch() {
        this.modalService.dismissAll();
        const modalRef = this.modalService.open(SearchPubmedModalComponent, { size: 'lg' });
        modalRef.componentInstance.pubmeds = this.pubmeds && this.pubmeds.results ? this.pubmeds.results : [];
        modalRef.componentInstance.ppdId = this.pubmeds && this.pubmeds.ppdId ? this.pubmeds.ppdId : this.ppdId;
        modalRef.componentInstance.parentId =   this.pubmeds && this.pubmeds.parentId ? this.pubmeds.parentId : this.parentId;
        modalRef.componentInstance.query = this.pubmeds && this.pubmeds.query ? this.pubmeds.query : this.searchQuery;
        modalRef.componentInstance.searchLoading = false;
        modalRef.componentInstance.search = true;

        modalRef.componentInstance.eventBus.subscribe(event => {
            switch (event.type) {
                case 'handleGetPubmedSearch':
                    this.getSearch(event.payload);
                    break;
                case 'handleAddReference':
                    this.handleAddReference(event.payload);
                    modalRef.dismiss();
                    break;
                case 'handleAddNonPubmedReference':
                    this.handleAddNonPubmedReference(event.payload);
                    modalRef.dismiss();
                    break;
                default:
                    break;
            }
        });
    }
    onClickRemoveLinkProtocol(paragraph) {
        if(paragraph.paragraphId) {
            const event = {
                type: 'removeLinkToProtocol',
                payload: {
                    paragraphId: paragraph.paragraphId,
                    ppdId: this.ppdId
                }
            };
            this.eventBus.emit(event);
        }
    }
    onClickLinkProtocol() {
        this.protocolsService.getAll().subscribe(protocols => {
            this.modalService.dismissAll();

            const modalRef = this.modalService.open(LinkProtocolModalComponent, { size: 'lg' });
            modalRef.componentInstance.data = {
                title: 'Link to Protocol',
                text: 'Select a protocol that you would like to link to this paragraph',
                protocolId: this.protocolId.toString(),
                protocols,
                buttons: {
                    confirm: 'Link',
                    cancel: 'Cancel'
                }
            } as LinkProtcolModalData;

            modalRef.result.then(
                values => this.handleLinkToProtocol(values),
                () => {}
            );
        });
    }

    handleLinkToProtocol(values: any) {
        const connectProtocolId = values.connectProtocolId;
        const event = {
            type: 'handleLinkToProtocol',
            payload: {
                protocolId: this.protocolId,
                ppdId: this.ppdId,
                connectProtocolId
            }
        };
        this.eventBus.emit(event);
    }

    getSearch(payload) {
        const { query } = payload;
        this.searchQuery = query;
        const event = {
            type: 'handleGetPubmedSearch',
            payload: {
                parentId: this.parentId,
                ppdId: this.ppdId,
                query
            }
        };
        this.eventBus.emit(event);
    }

    handleAddNonPubmedReference(payload) {
        this.searchQuery = '';
        const event = {
            type: 'handleAddNonPubmedReference',
            payload: {
                ...payload,
                protocolId: this.protocolId,
                parentId: this.parentId
            }
        };
        this.eventBus.emit(event);
    }

    handleAddReference(payload) {
        this.searchQuery = '';
        let reloadId = ''
        if(this.parentId) {
            reloadId = this.parentId }
        else {
            reloadId = payload.parentId
        }

        const event = {
            type: 'handleAddReference',
            payload: {
                ...payload,
                protocolId: this.protocolId,
                articleIds: payload.uids,
                parentId: reloadId
            }
        };
        this.eventBus.emit(event);
    }

    onClickOpenModelEditor() {
        const action = {
            type: 'onOpenModelEditor',
            payload: {}
        };
        this.eventBus.emit(action);
    }

    onClickCreateNewModel() {
        const action = {
            type: 'onClickCreateNewModel',
            payload: {}
        };
        this.eventBus.emit(action);
    }

    onClickEditSummary() {
        const action = {
            type: 'onClickEditSummary',
            payload: {}
        };
        this.eventBus.emit(action);
    }
}
