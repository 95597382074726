import { DashboardCategoriesEffects } from './dashboard-categories.effect';
import { EventbusChannelEffects } from './eventbus-channel.effect';
import { FlowchartEffects } from './flowchart.effect';
import { GradeAssessmentEffects } from './grade-assessment.effect';
import { ModelEffects } from './model.effect';
import { ModelsFindingsEffects } from './models-findings.effect';
import { ModelsOutcomesEffects } from './models-outcomes.effect';
import { OutcomeEffects } from './outcome.effect';
import { ParPopulationsEffects } from './paragraphs-populations.effect';
import { ParagraphsEffects } from './paragraphs.effect';
import { ParagraphsFilesEffects } from './paragraphs-files.effect';
import { ProtocolAllOutcomesEffects } from './protocols-all-outcomes.effect';
import { ProtocolArticlesEffects } from './protocol-articles.effect';
import { ProtocolsEffects } from './protocols.effect';
import { ProtocolsExistingEffects } from './protocols-existing.effect';
import { PubmedEffects } from './pubmed.effect';
import { UsersEffects } from './users.effect';

export const effects = [
    DashboardCategoriesEffects,
    EventbusChannelEffects,
    FlowchartEffects,
    GradeAssessmentEffects,
    ModelEffects,
    ModelsFindingsEffects,
    ModelsOutcomesEffects,
    OutcomeEffects,
    ParPopulationsEffects,
    ParagraphsEffects,
    ParagraphsFilesEffects,
    ProtocolAllOutcomesEffects,
    ProtocolArticlesEffects,
    ProtocolsEffects,
    ProtocolsExistingEffects,
    PubmedEffects,
    UsersEffects
];

export * from './dashboard-categories.effect';
export * from './eventbus-channel.effect';
export * from './flowchart.effect';
export * from './grade-assessment.effect';
export * from './model.effect';
export * from './models-findings.effect';
export * from './models-outcomes.effect';
export * from './outcome.effect';
export * from './paragraphs-files.effect';
export * from './paragraphs-populations.effect';
export * from './paragraphs.effect';
export * from './protocol-articles.effect';
export * from './protocols-existing.effect';
export * from './protocols.effect';
export * from './pubmed.effect';
export * from './users.effect';
