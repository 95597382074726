import { Action } from '@ngrx/store';
import { HelpGetRO } from '../../../../models/help.interface';

export const HELP_GET = '[Help] Help Get';
export const HELP_GET_FAIL = '[Help] Help Get Fail';
export const HELP_GET_SUCCESS = '[Help] Help Get Success';

export class HelpGet implements Action {
    readonly type = HELP_GET;

    constructor() {}
}

export class HelpGetFail implements Action {
    readonly type = HELP_GET_FAIL;

    constructor(public payload: { message: string }) {}
}

export class HelpGetSucces implements Action {
    readonly type = HELP_GET_SUCCESS;

    constructor(public payload: HelpGetRO) {}
}

export type HelpAction = HelpGet | HelpGetFail | HelpGetSucces;
