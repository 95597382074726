import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromActions from '../actions/';
import * as fromService from '../../../../services';

@Injectable()
export class ProtocolsExistingEffects {
    constructor(private actions$: Actions, private DashboardService: fromService.DashboardService) {}

    @Effect()
    loadExistingProtocolEffect$ = this.actions$.pipe(
        ofType(fromActions.LOAD_PROTOCOLS_EXISTING),
        switchMap(() => {
            return this.DashboardService.getAllDocuments().pipe(
                map(documents => {
                    return new fromActions.LoadProtocolsExistingSucces(documents.filter(x => x.status !== "Deleted"));                }),
                catchError(error => of(new fromActions.LoadProtocolsExistingFail(error)))
            );
        })
    );
}
