import { Component, ChangeDetectionStrategy, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

interface Link {
    publication_name: string;
    url: string;
    title: string;
    author: string;
    year: string;
}

interface Reference {
    articleId: string;
    label: string;
    title: string;
    finding: any[];
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-insert-footnote-modal',
    templateUrl: './insert-footnote-modal.component.html',
    styleUrls: ['./insert-footnote-modal.component.scss']
})
export class InsertFootnoteModalComponent implements OnInit {
    sources: any[] = [];
    parId: string;
    parentId: string;

    activeTab: string;

    formReferences: FormGroup;
    formLinks: FormGroup;
    references: Reference[] = [];
    links: Link[] = [];
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}
    ngOnInit() {
        this.activeTab = 'articles';
        this.references = this.sources.filter(source => source.articleId) as Reference[];
        
        this.formReferences = this.fb.group({
            reference: ['', Validators.required]
        });

        this.links = this.sources.filter(source => source.publication_name) as Link[];
        
        this.formLinks = this.fb.group({
            link: ['', Validators.required]
        });
    
    }

    onSubmit(form: string) {
        if (form === 'reference' && this.formReferences.valid) {
            const index = +this.formReferences.value.reference;
            const result = { type: 'reference', value: this.references[index] };
            this.activeModal.close(result);
        }
        if (form === 'links' && this.formLinks.valid) {
            const index = +this.formLinks.value.link;
            const result = { type: 'link', value: this.links[index] };
            this.activeModal.close(result);
        }
    }

    onCancel(form: string) {
        this.activeModal.dismiss(`Form '${form}' cancelled by user`);
    }

    trackByFn = (index, item) => item.id || index;
}
