<div class="modal-header">
    <h4 class="modal-title">
        {{ 'MODEL.OUTCOME.EDIT.MODALTITLE' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
    
</div>

<div class="modal-body">
    <div *ngIf="loading" id="loadingContent" class="row m-2">
        <div class="spinner">
            <div class="dot1"></div>
            <div class="dot2"></div>
        </div>
    </div>
    <div class="row m-2 wysiwyg">
       <p>
            {{ 'MODEL.OUTCOME.EDIT.MODALINTRO' | translate }}
        </p>
        <textarea
            id="summaryText"
            type="text"
            class="form-control outcomeTextForm"
        >{{outcome.summaryText}}</textarea>

    </div>
</div>
<div class="modal-footer">
    <a class="btn btn-info text-capitalize" type="submit" (click)=storeText()>{{'SUBMIT' | translate}}</a>
</div>
