import { Action } from '@ngrx/store';
import { GetModelDTO, GetModelRO } from '../../../../models/outcome.interface';

export const GET_MODEL = '[Protocols] Get Model';
export const GET_MODEL_FAIL = '[Protocols] Get Model Fail';
export const GET_MODEL_SUCCESS = '[Protocols] Get Model Success';

export class GetModel implements Action {
    readonly type = GET_MODEL;

    constructor(public payload: GetModelDTO) {
    }
}

export class GetModelFail implements Action {
    readonly type = GET_MODEL_FAIL;

    constructor(public payload: { ppdId: string, error: { message: string } }) {
    }
}

export class GetModelSucces implements Action {
    readonly type = GET_MODEL_SUCCESS;

    constructor(public payload: { ppdId: string, getModelRO: GetModelRO }) {
    }
}

export type ModelAction =
    | GetModel
    | GetModelFail
    | GetModelSucces;
