    <div *ngIf="showTable()">
    <ag-grid-angular
        #agGrid
        style="width: 100%"
        class="ag-theme-alpine"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [immutableData]="true"
        [defaultColDef]="defaultColDef"
        [frameworkComponents]="frameworkComponents"
        domLayout='autoHeight'
        [gridOptions]="gridOptions"
        (eventBus)="handleEventBus($event)"
        [getRowNodeId]="getRowNodeId"
        [animateRows]="true"
        [rowSelection]="edit ? 'single' : null" 
        (stopEditing)="resetRowHeight()"
        >
    </ag-grid-angular>
</div>
