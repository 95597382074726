import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromDashboardSummary from './dashboard.reducer';
import * as fromDashboardCategory from './dashboard-category.reducer';
import * as fromDashboardPatient from './dashboard-patient.reducer';
import * as fromDashboardForm from './dashboard-form.reducer';
import * as fromDashboardTag from './dashboard-tag.reducer';
import * as fromExistingDashboard from './dashboard-existing.reducer';

export interface DashboardState {
    dashboardSummary: fromDashboardSummary.DashboardSummaryState;
    dashboardTag: fromDashboardTag.DashboardTagState;
    dashboardCategories: fromDashboardCategory.DashboardCategoryState;
    dashboardPatients: fromDashboardPatient.DashboardPatienState;
    dashboardForm: fromDashboardForm.DashboarFormState;
    existingDashboards: fromExistingDashboard.HelloState;
}

export const reducers: ActionReducerMap<DashboardState> = {
    dashboardSummary: fromDashboardSummary.reducer,
    dashboardTag: fromDashboardTag.reducer,
    dashboardCategories: fromDashboardCategory.reducer,
    dashboardPatients: fromDashboardPatient.reducer,
    dashboardForm: fromDashboardForm.reducer,
    existingDashboards: fromExistingDashboard.reducer
};

export const getDashboardState = createFeatureSelector<DashboardState>('dashboard');
