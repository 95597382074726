import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { PatientOption } from '../models/dashboard-patient-option.model';

@Injectable()
export class PatientService {
    constructor(private http: HttpClient) {}

    getAllPatient(): Observable<PatientOption[]> {
        const url = `${environment.baseUrl}/api/protocol/organization/getPatients.vm`;
        return this.http.get<PatientOption[]>(url).pipe(
            map(response => {
                return response;
            }),
            catchError((error: any) => throwError(error))
        );
    }
    addPatient(protocolId, patientName) {
        const url = `${environment.baseUrl}/api/protocol/organization/editDashboard.vm`;
        const data = {
            protocolId,
            patientName,
            action: 'addNewPatient'
        };
        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }
    addPatientToProtocol(protocolId, populationId) {
        const url = `${environment.baseUrl}/api/protocol/organization/editDashboard.vm`;
        const data = {
            protocolId,
            populationId,
            action: 'addPatient'
        };
        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }
    updatePatient(id, title) {
        return this.getAllPatient();
        // const url = `${environment.baseUrl}/api/protocol/setDashboards.vm`;
        // const data = {
        // };
        // return this.http
        //     .post<any>(url, data)
        //     .pipe(catchError((error: any) => throwError(error)));
    }
    test(tagId) {}
    removePatient(tagId) {
        const url = `${environment.baseUrl}/api/protocol/organization/editDashboard.vm`;
        const data = {
            tagId: tagId,
            action: 'removePatient'
        };
        return this.http.post<any>(url, data).pipe(
            catchError((error: any) => {
                throw error.json();
            })
        );
    }
}
