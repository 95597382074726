<form>
    <div *ngIf="pagination" class="form-group row">
        <div class="col-3 search-field__container">
            <form class="search-field__form">
                <label for="search" class="search-field__label"><span class="icon-search"></span></label>
                <input id="search" class="form-control ml-2" type="text" name="searchTerm"
                       [(ngModel)]="dataGridService.searchTerm" placeholder="{{ 'SEARCH' | translate }}" />
            </form>
        </div>
        <div class="col-6 d-flex justify-content-center">
            <ngb-pagination
                *ngIf="(dataGridService.total$ | async) > dataGridService.pageSize"
                [collectionSize]="(dataGridService.total$ | async)" [(page)]="dataGridService.page"
                [pageSize]="dataGridService.pageSize">
            </ngb-pagination>
        </div>

        <div class="col-3 text-right">
            <label for="select-page-size" class="hidden"></label>
            <select *ngIf="(dataGridService.total$ | async) > dataGridService.pageSizes[0]" id="select-page-size"
                    class="custom-select"
                    name="pageSize"
                    [(ngModel)]="dataGridService.pageSize">
                <option *ngFor="let pageSize of dataGridService.pageSizes"
                        [ngValue]="pageSize">{{pageSize}}</option>
            </select>
        </div>
    </div>
    <table *ngIf="(dataGridService.total$ | async)" class="table">
        <thead>
        <tr>
            <th *ngIf="showCategoryColumn" id="th-category"
                class="table__text table__title table__column-30" scope="col" sortable="category"
                (sort)="onSort($event)">
                <div *ngIf="sortColumn === 'category' && (dataGridService.loading$ | async)"
                     class="spinner-grow spinner-grow-sm"
                     role="status"></div>
                <span>{{ 'CATEGORY' | translate }}</span>
            </th>
            <th id="th-title" class="table__text table__title table__column-30" scope="col" sortable="title"
                [disableSorting]="showCategoryColumn"
                (sort)="onSort($event)">
                <div *ngIf="sortColumn === 'title' && (dataGridService.loading$ | async)"
                     class="spinner-grow spinner-grow-sm"
                     role="status"></div>
                <span>{{ 'TITLE' | translate }}</span>
            </th>
            <th id="th-authors" class="table__text table__title table__column-20 -hide-column-small-screen" scope="col" sortable="authors"
                [disableSorting]="showCategoryColumn"
                (sort)="onSort($event)">
                <div *ngIf="sortColumn === 'authors' && (dataGridService.loading$ | async)"
                     class="spinner-grow spinner-grow-sm"
                     role="status"></div>
                <span>{{ 'AUTHORS' | translate }}</span>
            </th>
            <th *ngIf="showVersionsColumn" id="th-versions" class="table__text table__title table__column-20 -hide-column-small-screen"
                [disableSorting]="showCategoryColumn"
                scope="col" sortable="versions"
                (sort)="onSort($event)">
                <div *ngIf="sortColumn === 'versions' && (dataGridService.loading$ | async)"
                     class="spinner-grow spinner-grow-sm"
                     role="status"></div>
                <span>{{ 'VERSIONS' | translate }}</span>
            </th>
        </tr>
        </thead>
        <tbody>
        <ng-container
            *ngFor="let row of (dataGridService.rows$ | async) as rows; let index = index; trackBy: trackByFn"
        >
            <tr *ngIf="rowStatus[row.id]?.first || rowStatus[row.id]?.expanded">
                <td *ngIf="showCategoryColumn" class="table__text td-category">
                    <ng-container *ngIf="!index || (index && row.category !== rows[index - 1].category)">
                        <div *ngIf="rowStatus[row.id].expandable" class="cursor-pointer"
                             (click)="toggleCategory(row.id)">
                            <ngb-highlight
                                [result]="row.category"
                                [term]="dataGridService.searchTerm"></ngb-highlight>
                            <i *ngIf="rowStatus[row.id]?.expanded" class="fa fa-caret-up ml-2"></i>
                            <i *ngIf="!rowStatus[row.id]?.expanded" class="fa fa-caret-down ml-2"></i>
                        </div>
                        <div *ngIf="!rowStatus[row.id].expandable">
                            <ngb-highlight
                                [result]="row.category"
                                [term]="dataGridService.searchTerm"></ngb-highlight>
                        </div>
                    </ng-container>
                </td>
                <td class="table__text table__title">
                    <ng-container *ngIf="isCollapsed(row.id); else protocolExpanded">
                    <div class="protocol-icon-container">
                        <span class="protocol-icon icon-folder-o"></span>
                    </div>
                        <span>{{showNumberOfDocuments(row.id)}}</span>
                    </ng-container>
                    <ng-template #protocolExpanded>
                    <div class="protocol-icon-container">
                        <span *ngIf="row.icon" [class]="row.icon"
                              [class.protocol-icon-flowchart]="isFlowchart(row)"
                              class="protocol-icon cursor-pointer" (click)="clickProtocol($event, row)">
                        </span>
                        <span *ngIf="!row.icon && getProtocolProperty(row, 'external_url')"
                              class="protocol-icon cursor-pointer icon-external-link"
                              (click)="clickProtocol($event, row)"></span>
                    </div>
                        <a
                            href="#"
                            class="table__link"
                            (click)="clickProtocol($event, row)"
                        >
                            <ngb-highlight [result]="row.title"
                                           [term]="dataGridService.searchTerm"></ngb-highlight>
                        </a>
                    </ng-template>
                </td>
                <td class="table__text -hide-column-small-screen">
                    <ng-container *ngIf="isCollapsed(row.id); else authorsExpanded">
                        {{showAllUniqueAuthors(row.id)}}
                    </ng-container>
                    <ng-template #authorsExpanded>
                        <alii-web-avatar [ownerlist]="getProtocolProperty(row, 'ownerList')"
                                         [small]="true" [avatar]="false"></alii-web-avatar>
                    </ng-template>
                </td>
                <td *ngIf="showVersionsColumn" class="table__actions -hide-column-small-screen">
                    <ng-container *ngIf="isCollapsed(row.id); else versionsExpanded">
                        <a
                            href="#"
                            class="tag mr-1 border"
                            *ngFor="let version of getAllUniqueVersions(row.id)"
                            [ngClass]="{ '-primary': version.version === 'current', '-secondary': version.version === 'draft' }"
                            (click)="clickNothing($event)"
                        >
                            {{ version.version.toUpperCase() | translate }}
                        </a>
                    </ng-container>
                    <ng-template #versionsExpanded>
                        <a
                            href="#"
                            class="tag mr-1 border"
                            *ngFor="let version of getProtocolProperty(row, 'versions')"
                            [ngClass]="{ '-primary': version.version === 'current', '-secondary': version.version === 'draft' }"
                            (click)="clickVersion($event, row, version.version)"
                        >
                            {{ version.version.toUpperCase() | translate }}
                        </a>
                    </ng-template>
                </td>
            </tr>
        </ng-container>
    </table>
</form>
