import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { GetModelDTO, GetModelRO } from '../models/outcome.interface';

// Development only
export type ModelsRequestAction = 'updateModel';
export type ModelsRequestState = 'loading' | 'success' | 'fail';

export interface ModelsRequest {
    action: ModelsRequestAction;
    state: ModelsRequestState;
    response?: any;
    error?: any;
}

const cn = 'ModelsService';

@Injectable()
export class ModelsService {
    // Development only
    private development: boolean;
    private _request$ = new BehaviorSubject<ModelsRequest>(null);
    request$ = this._request$.asObservable();

    constructor(private http: HttpClient) {
        this.development = !environment.production;
    }

    setPopulationIdSession(populationId) {
        if (window.sessionStorage) {
            sessionStorage.setItem('populationId', populationId);
        }
    }

    getModel(data: GetModelDTO): Observable<GetModelRO> {
        const { ppdId } = data;
        const url = `${environment.baseUrl}/api/protocol/par/getModel.vm?ppdId=${ppdId}`;
        return this.http.get<GetModelRO>(url).pipe(
            map(response => response['message list']),
            catchError((error: any) => throwError(error))
        );
    }

    updateModel(data): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/population/updateModel.vm?app=web`;
        const { ppdId, findings, populationId, calculate } = data;
        this._notify('updateModel', 'loading');
        return this.http
            .post<any>(url, { ppdId, findings, populationId, calculate })
            .pipe(
                map(resp => {
                    const response = resp['message list'];
                    if (this.development) {
                        //     console.log(`${cn} updateModel() response`, response);
                        //     response.updated_paragraphs.forEach((paragraph, i) => {
                        //         const questions = paragraph.questions;
                        // console.log(`${JSON.stringify(questions.map(q => q.hide))} ${cn} updateModel() ${i}`);
                        //     });
                        this._notify('updateModel', 'success', response);
                    }
                    return response;
                }),
                catchError((error: any) => {
                    this._notify('updateModel', 'fail', error);
                    return throwError(error);
                })
            );
    }

    // TODO: This is no longer used and can be removed, but be careful.
    showAllChildren(populationId, outcomeId, modelId): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/population/updateOutcome.vm?app=web`;
        const action = 'showAllChildren';
        return this.http
            .post<any>(url, { outcomeId, modelId, populationId, action })
            .pipe(
                map(response => {
                    return response['message list'];
                }),
                catchError((error: any) => throwError(error))
            );
    }

    selectOutcome(populationId, outcomeId, selected): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/population/selectOutcome.vm`;

        return this.http
            .post<any>(url, { populationId, outcomeId, selected })
            .pipe(
                map(response => {
                    return response['message list'];
                }),
                catchError((error: any) => throwError(error))
            );
    }

    updateOutcomeSummaryText(outcomeId, populationId, text, modelId): Observable<any> {

        const url = `${environment.baseUrl}/api/protocol/population/updateOutcomeSummaryText.vm`;
        return this.http
            .post<any>(url, {populationId, outcomeId, text, modelId })
            .pipe(
                map(response => {
                    return response['message list'];
                }),
                catchError((error: any) => throwError(error))
            );
    }
    

    selectOutcomeList(populationId, outcomeId, modelId, action): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/population/updateOutcome.vm?app=web`;

        return this.http
            .post<any>(url, { populationId, outcomeId, modelId, action })
            .pipe(
                map(response => {
                    return response['message list'];
                }),
                catchError((error: any) => throwError(error))
            );
    }

    updatePopulationName(populationId, name) {
        const populationUrl = `${environment.baseUrl}/api/protocol/population/updatePopulation.vm`;

        return this.http
            .post<any>(populationUrl, { populationId, name })
            .pipe(catchError((error: any) => throwError(error)));
    }

    createPopulation(data): Observable<any> {
        const populationUrl = `${environment.baseUrl}/api/protocol/population/createNewPopulation.vm`;

        return this.http.post<any>(populationUrl, data).pipe(catchError((error: any) => throwError(error)));
    }

    addPopulation(populationId, outcomeId) {
        const populationUrl = `${environment.baseUrl}/api/protocol/population/updatePopulation.vm`;

        return this.http
            .post<any>(populationUrl, { populationId, outcomeId })
            .pipe(
                map(response => {
                    return response['message list'];
                }),
                catchError((error: any) => throwError(error))
            );
    }

    copyPopulation(populationId) {
        const populationUrl = `${environment.baseUrl}/api/protocol/population/createNewPopulation.vm`;

        return this.http
            .post<any>(populationUrl, { copyPopulationId: populationId })
            .pipe(catchError((error: any) => throwError(error)));
    }

    deletePopulation(populationId) {
        const populationUrl = `${environment.baseUrl}/api/protocol/population/updatePopulation.vm`;

        return this.http
            .post<any>(populationUrl, { populationId, action: 'remove' })
            .pipe(
                map(response => {
                    return response['message list'];
                }),
                catchError((error: any) => throwError(error))
            );
    }

    replacePopulation(oldPopulationId, newPopulationId, name?) {
        const populationUrl = `${environment.baseUrl}/api/protocol/population/updatePopulation.vm`;

        return this.http
            .post<any>(populationUrl, {
                populationId: newPopulationId,
                replacePopulationId: oldPopulationId,
                ...(name && { name })
            })
            .pipe(
                map(response => {
                    return response['message list'];
                }),
                catchError((error: any) => throwError(error))
            );
    }

    // Development only
    private _notify(action: ModelsRequestAction, state: ModelsRequestState, respOrErr: any = null) {
        if (this.development) {
            if (state === 'success') {
                this._request$.next({ action, state, response: respOrErr });
            } else if (state === 'fail') {
                this._request$.next({ action, state, error: respOrErr });
            } else {
                this._request$.next({ action, state });
            }
        }
    }
}
