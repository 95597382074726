import { Action } from '@ngrx/store';
import { DeleteProtocolDTO, DeleteProtocolRO, Protocol } from '../../../../models/protocol.interface';
import { Paragraph } from '../../../../models/paragraph.interface';

export const CREATE_PROTOCOL = '[Protocols] Create Protocol';
export const CREATE_PROTOCOL_FAIL = '[Protocols] Create Protocol Fail';
export const CREATE_PROTOCOL_SUCCESS = '[Protocols] Create Protocol Success';

export class CreateProtocol implements Action {
    readonly type = CREATE_PROTOCOL;

    constructor(public payload: { title: string; category?: string; html?: string }) {}
}

export class CreateProtocolSuccess implements Action {
    readonly type = CREATE_PROTOCOL_SUCCESS;

    constructor(public payload: { protocolId: string }) {}
}

export class CreateProtocolFail implements Action {
    readonly type = CREATE_PROTOCOL_FAIL;

    constructor(public payload: any) {}
}

export const DELETE_PROTOCOL = '[Protocols] Delete Protocol';
export const DELETE_PROTOCOL_FAIL = '[Protocols] Delete Protocol Fail';
export const DELETE_PROTOCOL_SUCCESS = '[Protocols] Delete Protocol Success';

export class DeleteProtocol implements Action {
    readonly type = DELETE_PROTOCOL;

    constructor(public payload: DeleteProtocolDTO) {}
}

export class DeleteProtocolSuccess implements Action {
    readonly type = DELETE_PROTOCOL_SUCCESS;

    constructor(public payload: Partial<DeleteProtocolRO>) {}
}

export class DeleteProtocolFail implements Action {
    readonly type = DELETE_PROTOCOL_FAIL;

    constructor(public payload: Partial<DeleteProtocolRO>) {}
}

export const CREATE_LOCAL_PROTOCOL = '[Protocols] Create Local Protocol';
export const CREATE_LOCAL_PROTOCOL_FAIL = '[Protocols] Create Local Protocol Fail';
export const CREATE_LOCAL_PROTOCOL_SUCCESS = '[Protocols] Create Local Protocol Success';

export class CreateLocalProtocol implements Action {
    readonly type = CREATE_LOCAL_PROTOCOL;

    constructor(public payload: { parentId: string }) {}
}

export class CreateLocalProtocolSuccess implements Action {
    readonly type = CREATE_LOCAL_PROTOCOL_SUCCESS;

    constructor(public payload: { protocolId: string }) {}
}

export class CreateLocalProtocolFail implements Action {
    readonly type = CREATE_LOCAL_PROTOCOL_FAIL;

    constructor(public payload: any) {}
}

export const COPY_PROTOCOL = '[Protocols] Copy Protocol';
export const COPY_PROTOCOL_FAIL = '[Protocols] Copy Protocol Fail';
export const COPY_PROTOCOL_SUCCESS = '[Protocols] Copy Protocol Success';

export class CopyProtocol implements Action {
    readonly type = COPY_PROTOCOL;

    constructor(public payload: { parentId: string }) {}
}

export class CopyProtocolSuccess implements Action {
    readonly type = COPY_PROTOCOL_SUCCESS;

    constructor(public payload: { protocolId: string }) {}
}

export class CopyProtocolFail implements Action {
    readonly type = COPY_PROTOCOL_FAIL;

    constructor(public payload: any) {}
}

export const GET_PROTOCOLS_BY_QUERY = '[Protocols] Get Protocol By Query';
export const GET_PROTOCOLS_BY_QUERY_FAIL = '[Protocols] Get Protocol By Query Fail';
export const GET_PROTOCOLS_BY_QUERY_SUCCESS = '[Protocols] Get Protocol By Query Success';

export class GetProtocolsByQuery implements Action {
    readonly type = GET_PROTOCOLS_BY_QUERY;

    constructor(public payload: { query: string }) {}
}

export class GetProtocolsByQueryFail implements Action {
    readonly type = GET_PROTOCOLS_BY_QUERY_FAIL;

    constructor(public payload: any) {}
}

export class GetProtocolsByQuerySuccess implements Action {
    readonly type = GET_PROTOCOLS_BY_QUERY_SUCCESS;

    constructor(public payload: { protocols: Protocol[] }) {}
}

export const LOAD_PROTOCOL = '[Protocols] Load Protocol';
export const LOAD_PROTOCOL_FAIL = '[Protocols] Load Protocol Fail';
export const LOAD_PROTOCOL_SUCCESS = '[Protocols] Load Protocol Success';
export const RELOAD_PROTOCOL = '[Protocols] Reload Protocol';

export class LoadProtocol implements Action {
    readonly type = LOAD_PROTOCOL;

    constructor(
        public payload: {
            protocolId: string;
            versionId?: string;
            version?: string;
            populationId?: string;
            showAllOutcomes?: boolean;
            viewByPopulation?: boolean;
            compareVersion?: boolean;
        }
    ) {}
}

export class LoadProtocolFail implements Action {
    readonly type = LOAD_PROTOCOL_FAIL;

    constructor(public payload: any) {}
}

export class LoadProtocolSuccess implements Action {
    readonly type = LOAD_PROTOCOL_SUCCESS;

    constructor(public payload: Protocol) {}
}

export class ReloadProtocol implements Action {
    readonly type = RELOAD_PROTOCOL;

    constructor() {}
}

export const UPDATE_CURRENT_VIEW = '[Protocol] Update the current view on Protocol';

export class UpdateCurrentView implements Action {
    readonly type = UPDATE_CURRENT_VIEW;

    constructor(public payload: { view: string; paragraphId: string; scrollPos: { x: number; y: number } }) {}
}

export const SUBMIT_FLOW_ACTION = '[Protocols] Submit flow action';
export const SUBMIT_FLOW_ACTION_FAIL = '[Protocols] Submit flow action Fail';
export const SUBMIT_FLOW_ACTION_SUCCESS = '[Protocols] Submit flow action Success';

export class SubmitFlowAction implements Action {
    readonly type = SUBMIT_FLOW_ACTION;

    constructor(public payload: { action: string; protocolId; categoryId; comment: string, versionId: string }) {}
}

export class SubmitFlowActionSuccess implements Action {
    readonly type = SUBMIT_FLOW_ACTION_SUCCESS;

    constructor(public payload: { action: string; protocolId; categoryId: string; response: any}) {}
}

export class SubmitFlowActionFail implements Action {
    readonly type = SUBMIT_FLOW_ACTION_FAIL;

    constructor(public payload: any) {}
}

export const UPDATE_SETTING = '[Protocols] Update protocol setting';
export const UPDATE_SETTING_FAIL = '[Protocols] Update protocol setting Fail';
export const UPDATE_SETTING_SUCCESS = '[Protocols] Update protocol setting Success';

export class UpdateSetting implements Action {
    readonly type = UPDATE_SETTING;

    constructor(public payload: { protocolId: string | number; options: any }) {}
}

export class UpdateSettingSuccess implements Action {
    readonly type = UPDATE_SETTING_SUCCESS;

    constructor(public payload: { protocolId: string; versionId?: string; version?: string }) {}
}

export class UpdateSettingFail implements Action {
    readonly type = UPDATE_SETTING_FAIL;

    constructor(public payload: any) {}
}

export const SET_GRADE_STATUS = '[Store Protocols] Set Grade Assessment Status';
export class SetGradeStatus implements Action {
    readonly type = SET_GRADE_STATUS;
    constructor(public payload: {  ppdId: string; status?: string, parentId?: string}) {}
}


export const STORE_UPDATE_GRADE_ASSESSMENT = '[Store Protocols] Update Grade Assessment';

export class StoreUpdateGradeAssessment implements Action {
    readonly type = STORE_UPDATE_GRADE_ASSESSMENT;

    constructor(public payload: { ppdId: string; key: string; value: number }) {}
}

export const STORE_EVIDENCE = '[Store Protocols] Store Evidence';
export const STORE_EVIDENCE_SUCCESS = '[Store Protocols] Store Evidence Success';

export class StoreEvidence implements Action {
    readonly type = STORE_EVIDENCE;
    constructor(public payload: { evidence: any; ppdId: string; protocolId?: string; parentId?: string}) {}
}

export class StoreEvidenceSuccess implements Action {
    readonly type = STORE_EVIDENCE_SUCCESS;
    constructor(public payload: { evidence: any; ppdId: string; protocolId?: string; parentId?: string}) {}
}


export const STORE_ADD_PARAGRAPH = '[Store Protocols] Add Paragraph';

export class StoreAddParagraph implements Action {
    readonly type = STORE_ADD_PARAGRAPH;

    constructor(public payload: { parentId: string; paragraph: Paragraph }) {}
}

export const STORE_UPDATE_PARAGRAPH = '[Store Protocols] Update Paragraph';

export class StoreUpdateParagraph implements Action {
    readonly type = STORE_UPDATE_PARAGRAPH;

    constructor(public payload: { parentId?: string; paragraph: Paragraph }) {}
}

export const STORE_DELETE_PARAGRAPH = '[Store Protocols] Delete Paragraph';

export class StoreDeleteParagraph implements Action {
    readonly type = STORE_DELETE_PARAGRAPH;

    constructor(public payload: { parentId: string; ppdId: string }) {}
}

export type ProtocolAction =
    | CreateProtocol
    | CreateProtocolFail
    | CreateProtocolSuccess
    | DeleteProtocol
    | DeleteProtocolFail
    | DeleteProtocolSuccess
    | CreateLocalProtocol
    | CreateLocalProtocolFail
    | CreateLocalProtocolSuccess
    | CopyProtocol
    | CopyProtocolFail
    | CopyProtocolSuccess
    | GetProtocolsByQuery
    | GetProtocolsByQueryFail
    | GetProtocolsByQuerySuccess
    | LoadProtocol
    | LoadProtocolFail
    | UpdateCurrentView
    | LoadProtocolSuccess
    | ReloadProtocol
    | SubmitFlowAction
    | SubmitFlowActionFail
    | SubmitFlowActionSuccess
    | UpdateSetting
    | UpdateSettingFail
    | UpdateSettingSuccess
    | StoreUpdateGradeAssessment
    | SetGradeStatus
    | StoreEvidence
    | StoreEvidenceSuccess
    | StoreAddParagraph
    | StoreUpdateParagraph
    | StoreDeleteParagraph;
