import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChildren
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core/core';

import { OwnerlistItem } from '@alii-web/models/protocol.model';
import { DashboardCategory } from '@alii-web/models/dashboard-category.model';

// Data grid service
import { CategoryListDataGridService } from './category-list-data-grid.service';
import { DataGridRow } from './category-list-data-grid.model';
import { CategoryListSortableHeaderDirective, SortEvent } from './category-list-sortable-header.directive';

interface Version {
    version: string;
    link: string;
}

interface ProtocolItem {
    id: string;
    index: number;
    icon: string;
    title: string;
    isLiterature: boolean;
    ownerList: OwnerlistItem[];
    versions: Version[];
    queryParams: { version: string };
    external_url: string;
}

interface CategoryItem {
    category: {
        id: string;
        name: string;
        position: number;
    };
    protocol: ProtocolItem;
}

const SEPARATOR = '~';

const cn = 'CategoryListComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-dashboard-category-list',
    templateUrl: './category-list.component.html',
    styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    dashboardCategories: DashboardCategory[];

    @Input() pagination = true;

    @ViewChildren(CategoryListSortableHeaderDirective) headers: QueryList<CategoryListSortableHeaderDirective>;

    categories: CategoryItem[];
    sortColumn: string;
    showVersionsColumn: boolean;
    showCategoryColumn: boolean;

    // Indicates whether or not a given row is the first one for a given category, if it is expandable, and
    // whether or not it is expanded (vs. collapsed).
    rowStatus: Record<string, { first: boolean; expandable: boolean; expanded: boolean }> = {};

    subscriptions: Subscription[] = [];

    // Translations
    langKeys = ['document', 'documents'];
    lang = {};

    constructor(
        public dataGridService: CategoryListDataGridService,
        private router: Router,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        // Setup translations
        this._onLangChange();
        this.subscriptions.push(this.translateService.onLangChange.subscribe(() => this._onLangChange()));
    }

    ngOnChanges(changes: SimpleChanges) {
        const dashboardCategories = changes.dashboardCategories;
        if (dashboardCategories && dashboardCategories.currentValue) {
            this.categories = [];
            // Sanity check that category names are unique, just in case.
            const categoryNames = {};
            [...dashboardCategories.currentValue]
                // Strip out category 'My protocols'
                .filter(category => category.name !== 'My protocols')
                // Sort categories by position
                .sort((a, b) => a.position - b.position)
                .forEach(category => {
                    if (categoryNames[category.name]) {
                        categoryNames[category.name].count++;
                    } else {
                        categoryNames[category.name] = { count: 1 };
                    }
                    [...category.protocols]
                        // Within each category sort protocols by index
                        .sort((a, b) => a.index - b.index)
                        .forEach(protocol => {
                            this.categories.push({
                                category: {
                                    id: category.id.toString(),
                                    name: category.name,
                                    position: category.position
                                },
                                protocol: {
                                    id: protocol.id.toString(),
                                    index: protocol.index,
                                    icon: protocol.icon,
                                    isLiterature: protocol.isLiterature,
                                    title: protocol.title,
                                    ownerList: protocol.ownerlist,
                                    versions: protocol.versions,
                                    queryParams: this._queryParams(protocol.versions),
                                    external_url: protocol.external_url
                                }
                            });
                        });
                });

            // Sanity check
            Object.keys(categoryNames).forEach(key => {
                const count = categoryNames[key].count;
                if (count > 1) {
                    console.warn(`${cn} ngOnChanges() category name '${key}' appears ${count} times`);
                }
            });

            // Remaining protocols for the data grid.
            const data: DataGridRow[] = [];
            const categoryIds = [];
            this.rowStatus = {};
            this.categories.forEach(item => {
                const categoryId = item.category.id.toString();
                const id = this._setId(item);
                let first;
                if (categoryIds.includes(categoryId)) {
                    first = false;
                } else {
                    categoryIds.push(categoryId);
                    first = true;
                }
                // Initially all rows are expanded, if in fact a given row is expandable.
                this.rowStatus[id] = {
                    first,
                    // Row is not expandable if the category only has one protocol.
                    expandable: this.categories.filter(item => item.category.id === categoryId).length > 1,
                    expanded: true
                };
                data.push({
                    id,
                    icon: item.protocol.icon,
                    title: item.protocol.title,
                    index: item.protocol.index,
                    category: item.category.name,
                    authors: item.protocol.ownerList.map(owner => owner.name),
                    versions: item.protocol.versions.map(version => version.version)
                });
            });

            // Initialize row status.
            this._initRowStatus();

            // Don't show versions column if only 'current' versions displayed.
            this.showVersionsColumn = data
                .map(d => d.versions.length === 1 && d.versions[0] === 'current')
                .includes(false);

            if (!this.pagination) {
                this.dataGridService.init(data);
            } else {
                this.dataGridService.init(data, 1, 1000, [1000]);
            }

            // Hide category column if there is only one category.
            this.showCategoryColumn = this.dashboardCategories && this.dashboardCategories.length > 1;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    onSort({ column, direction }: SortEvent) {
        if (this.headers) {
            // Reset other headers
            this.headers.forEach(header => {
                if (header.sortable !== column) {
                    header.reset();
                }
            });

            this.dataGridService.sortColumn = column;
            this.dataGridService.sortDirection = direction;

            this.sortColumn = column;
        } else {
            console.warn(`${cn} onSort() headers still undefined`);
        }
    }

    clickProtocol(event: MouseEvent, row: DataGridRow): boolean {
        event.stopPropagation();
        const id = row.id;
        const [categoryId, protocolId] = this._getIds(id);
        const found = this.categories.find(item => item.category.id === categoryId && item.protocol.id === protocolId);
        if (found) {
            const protocol = found.protocol;
            if (protocol.external_url) {
                window.open(protocol.external_url, '_blank');
            } 
            else if (protocol.isLiterature) {
                this.router.navigate(['/protocols', protocol.id, 'literatures'],  { queryParams: [] });
            }
            else {
                this.router.navigate(['/protocols', protocol.id], { queryParams: protocol.queryParams });
            }
        } else {
            console.warn(`${cn} clickProtocol() cannot find category list item for id='${id}'`);
        }
        return false;
    }

    getProtocolProperty(row: DataGridRow, property: string) {
        let result = null;
        const allowed = ['id', 'title', 'ownerList', 'versions', 'queryParams', 'external_url'];

        if (allowed.includes(property)) {
            const id = row.id;
            const [categoryId, protocolId] = this._getIds(id);
            const found = this.categories.find(
                item => item.category.id === categoryId && item.protocol.id === protocolId
            );
            if (found) {
                result = found.protocol[property];
            }
        }

        return result;
    }

    clickVersion(event: Event, row: DataGridRow, version: string) {
        event.stopPropagation();
        const id = this.getProtocolProperty(row, 'id');
        const url = this.getProtocolProperty(row, 'external_url');
        if (id) {
            version = version.charAt(0).toUpperCase() + version.slice(1);
            if(version == "Literature_version") {
                this.router.navigate(['/protocols', id, 'literatures'  ]);
            } else if (version == "Open link") {
                window.open(url, '_blank');
            } else {
                this.router.navigate(['/protocols', id], { queryParams: { version } });
            }
        } else {
            console.warn(`${cn} clickVersion() cannot find id for row='${JSON.stringify(row)}'`);
        }
        return false;
    }

    isFlowchart(row: DataGridRow): boolean {
        return !!row.icon.match(/flowchart$/i);
    }

    toggleCategory(rowId: string) {
        const [categoryId] = this._getIds(rowId);
        // Toggle all rows with same category id.
        Object.keys(this.rowStatus).forEach(_rowId => {
            const [_categoryId] = this._getIds(_rowId);
            if (_categoryId === categoryId) {
                this.rowStatus[_rowId].expanded = !this.rowStatus[_rowId].expanded;
            }
        });
    }

    showNumberOfDocuments(rowId: string) {
        const [categoryId] = this._getIds(rowId);
        let tot = 0;
        Object.keys(this.rowStatus).forEach(_rowId => {
            const [_categoryId] = this._getIds(_rowId);
            if (_categoryId === categoryId) {
                tot++;
            }
        });
        return tot + ' ' + (tot === 1 ? this.lang['document'].toLowerCase() : this.lang['documents']).toLowerCase();
    }

    showAllUniqueAuthors(rowId: string) {
        const MAX = 2;
        const [categoryId] = this._getIds(rowId);
        const authors: string[] = [];
        this.categories
            .filter(item => item.category.id === categoryId)
            .forEach(c => {
                c.protocol.ownerList.forEach(owner => {
                    if (!authors.includes(owner.name)) {
                        authors.push(owner.name);
                    }
                });
            });

        // If too many authors, only first few with ellipsis.
        return authors.length > MAX ? authors.splice(0, MAX).join(',') + '...' : authors.join(',');
    }

    getAllUniqueVersions(rowId: string) {
        const [categoryId] = this._getIds(rowId);
        const versions: Version[] = [];
        this.categories
            .filter(item => item.category.id === categoryId)
            .forEach(c => {
                c.protocol.versions.forEach(version => {
                    if (!versions.find(v => v.version === version.version)) {
                        versions.push(version);
                    }
                });
            });
        return versions;
    }

    isCollapsed(rowId: string) {
        return this.rowStatus[rowId].first && !this.rowStatus[rowId].expanded;
    }

    clickNothing(event: Event) {
        event.stopPropagation();
        return false;
    }

    trackByFn = (index, item) => item.id || index;

    private _onLangChange() {
        // langKeys = ['document', 'documents'];
        this.lang = {};
        this.translateService.get(this.langKeys.map(key => key.toUpperCase())).subscribe(results => {
            this.langKeys.forEach(key => (this.lang[key] = results[key.toUpperCase()]));
        });
    }

    private _queryParams(versions: Version[]): { version: string } {
        if (versions && versions.length === 0) {
            return { version: 'Draft' };
        }
        const current = versions.find(v => v.version === 'current');
        const draft = versions.find(v => v.version === 'draft');
        const preview = versions.find(v => v.version === 'preview');
        if (current) {
            return { version: 'Current' };
        }
        if (draft) {
            return { version: 'Draft' };
        }
        if (preview) {
            return { version: 'Preview' };
        }
        if (!current && !draft) {
            return { version: versions[0].version };
        }
        return null;
    }

    private _initRowStatus() {
        // Initial setup for the category rows based on a number of simple rules, only if the category
        // column is not hidden.
        if (this.showCategoryColumn) {
            const MAX = 10; // Maximum number of total rows which are visible
            const MIN = 3; // Minimum number of rows per category which are visible.

            // If total number of visible rows greater than MAX start collapsing categories.
            const ids = Object.keys(this.rowStatus);
            let expandedRows = ids.length;
            if (expandedRows > MAX) {
                const categoryIds: Record<string, string[]> = {};
                const idsList: string[] = [];
                ids.forEach(id => {
                    const [categoryId, protocolId] = this._getIds(id);
                    if (!categoryIds[categoryId]) {
                        idsList.push(categoryId);
                        categoryIds[categoryId] = [];
                    }
                    categoryIds[categoryId].push(protocolId);
                });
                idsList
                    .sort((a, b) => categoryIds[b].length - categoryIds[a].length)
                    .forEach(categoryId => {
                        if (expandedRows > MAX && categoryIds[categoryId].length > MIN) {
                            // Collapse
                            expandedRows = this._collapseCategory(categoryId, ids, expandedRows);
                        }
                    });
            }
        }
    }

    private _collapseCategory(categoryId: string, ids: string[], expandedRows: number) {
        ids.forEach(id => {
            const [_categoryId] = this._getIds(id);
            if (_categoryId === categoryId) {
                expandedRows--;
                this.rowStatus[id].expanded = false;
            }
        });
        return expandedRows;
    }

    private _setId = (item: CategoryItem): string => `${item.category.id}${SEPARATOR}${item.protocol.id}`;

    private _getIds = (value: string): [string, string] => {
        const ids = value.split(SEPARATOR);
        return [ids[0].toString(), ids[1].toString()];
    };
}
