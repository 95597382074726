import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-title-edit',
    styleUrls: ['./title-edit.component.css'],
    templateUrl: './title-edit.component.html'
})
export class TitleEditComponent implements OnInit {
    @Input() protocolId: string;
    @Input() id: string;
    @Input() parentId?: string;
    @Input() title: string;
    @Input() level: string;
    @Input() index: string;

    openForm = false;
    titleForm: FormGroup;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('titleInput') titleField: ElementRef;

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.titleForm = this.fb.group({
            title: [this.title, Validators.required]
        });
    }

    onClickOpenForm() {
        return this.handleOpenForm();
    }

    onClickCloseForm() {
        return this.handleCloseForm();
    }

    onSubmit() {
        if (this.titleForm.valid) {
            const action = {
                type: 'handleUpdateParTitle',
                payload: {
                    options: { ...this.titleForm.value },
                    protocolId: this.protocolId,
                    ppdId: this.id,
                    parentId: this.parentId
                }
            };

            this.eventBus.emit(action);
            this.handleCloseForm();
        }
    }

    private handleOpenForm() {
        this.openForm = true;
        setTimeout(() => this.titleField.nativeElement.focus(), 200);
        return this.openForm;
    }

    private handleCloseForm() {
        this.openForm = false;
        return this.openForm;
    }
}
