import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromActions from '../actions';
import * as protocolActions from '../actions/protocols.action';
import * as fromService from '../../../../services';

@Injectable()
export class ModelsOutcomesEffects {
    constructor(private actions$: Actions, private ParagraphsService: fromService.ParagraphsService) {}

    @Effect()
    ShowAllOutcomesEffect$ = this.actions$.pipe(
        ofType(fromActions.SHOW_ALL_OUTCOMES),
        switchMap(action => {
            const {
                payload: { modelId: ppdId }
            } = action as any;
            return this.ParagraphsService.getParWithAllOutcomes({ ppdId }).pipe(
                map(paragraph => {
                    const outcomes = {
                        [paragraph.id]: paragraph.outcomes ? [...paragraph.outcomes] : []
                    };
                    const tagList = {
                        [paragraph.id]: paragraph.tagList ? [...paragraph.tagList] : []
                    };
                    return new fromActions.ShowAllOutcomeSucces({ outcomes, tagList });
                }),
                catchError(error => of(new fromActions.ShowAllOutcomeFail(error)))
            );
        })
    );

    @Effect()
    ShowOutcomesBypopulationIdEffect$ = this.actions$.pipe(
        ofType(fromActions.SHOW_OUTCOMES_BY_POPULATION_ID),
        switchMap(action => {
            const {
                payload: { modelId: ppdId, populationId }
            } = action as any;

            return this.ParagraphsService.getParWithOutcomesByPopulationId({ ppdId, populationId }).pipe(
                map(paragraph => {
                    const outcomes = {
                        [paragraph.id]: paragraph.outcomes ? [...paragraph.outcomes] : []
                    };
                    return new fromActions.ShowOutcomesByPopulationIdSucces({ outcomes });
                }),
                catchError(error => of(new fromActions.ShowOutcomesByPopulationIdFail(error)))
            );
        })
    );

    @Effect()
    AddOutcomeEffect$ = this.actions$.pipe(
        ofType(fromActions.ADD_OUTCOME),
        switchMap(action => {
            const {
                payload: { protocolId, parentId, type, title, min, max }
            } = action as any;

            return this.ParagraphsService.addOutcome({ protocolId, parentId, type, title, min, max }).pipe(
                map(outcomes => new fromActions.AddOutcomeSucces({ parentId, outcomes })),
                catchError(error => of(new fromActions.AddOutcomeFail(error)))
            );
        })
    );

    @Effect()
    UpdateOutcomeEffect$ = this.actions$.pipe(
        ofType(fromActions.UPDATE_OUTCOME),
        switchMap(action => {
            const {
                payload: { protocolId, ppdId, parentId, min, max }
            } = action as any;

            return this.ParagraphsService.updateOutcome({ protocolId, ppdId, min, max }).pipe(
                map(outcome => new fromActions.UpdateOutcomeSucces({ parentId, ppdId, outcome })),
                catchError(error => of(new fromActions.UpdateOutcomeFail(error)))
            );
        })
    );
    @Effect()
    UpdateOutcomeTitleEffect$ = this.actions$.pipe(
        ofType(fromActions.UPDATE_OUTCOME_TITLE),
        switchMap(action => {
            const {
                payload: { protocolId, ppdId, parentId, title }
            } = action as any;

            return this.ParagraphsService.updateOutcomeTitle({ protocolId, ppdId, title }).pipe(
                map(outcome => new fromActions.UpdateOutcomeTitleSucces({ parentId, ppdId, outcome })),
                catchError(error => of(new fromActions.UpdateOutcomeTitleFail(error)))
            );
        })
    );
}
