import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromRegister from './register.reducer';

export interface RegisterFeatureState {
    register: fromRegister.RegisterState;
}

export const reducers: ActionReducerMap<RegisterFeatureState> = {
    register: fromRegister.reducer
};

export const getRegisterFeatureState = createFeatureSelector<RegisterFeatureState>('RegisterFeature');
