import * as fromAction from '../actions/paragraphs.action';
import { KeyQuestionImport } from '../../../../models/keyquestion-import.interface';

export interface ParagraphState {
    addParagraph: {
        values: {
            protocolId: string;
            parentId?: string;
            options: {};
        };
        loading: boolean;
        loaded: boolean;
    };
    loadEvidence: {
        values: {
            protocolId: string;
            parentId: string;
        },
        loading: boolean;
        loaded: boolean
    };
    updateParagraph: {
        values: {
            protocolId: string;
            ppdId: string;
            parentId: string;
            options: {};
        };
        loading: boolean;
        loaded: boolean;
    };
    deleteParagraph: {
        values: {
            protocolId: string;
            ppdId: string;
            parentId: string;
        };
        loading: boolean;
        loaded: boolean;
    };
    addModel: {
        loading: boolean;
        loaded: boolean;
    };
    keyQuestionImport: KeyQuestionImport[];
    updateModelFromSheetId: {
        values: {
            protocolId: string;
            ppdId: string;
            parentId: string;
            sheetId: string;
        };
        loading: boolean;
        loaded: boolean;
        error: any;
    };
    linkToProtocol: {
        values: {
            protocolId: string;
            ppdId: string;
            connectProtocolId: string;
        };
        loading: boolean;
        loaded: boolean;
        error: any;
    };
}

export const initialState: ParagraphState = {
    addParagraph: {
        values: {
            protocolId: null,
            parentId: null,
            options: {}
        },
        loading: false,
        loaded: false
    },
    loadEvidence: {
        values: {
            protocolId: null,
            parentId: null,
        },
        loading: false,
        loaded: false
    },
    updateParagraph: {
        values: {
            protocolId: null,
            ppdId: null,
            parentId: null,
            options: {}
        },
        loading: false,
        loaded: false
    },
    deleteParagraph: {
        values: {
            protocolId: null,
            ppdId: null,
            parentId: null
        },
        loading: false,
        loaded: false
    },
    addModel: {
        loading: false,
        loaded: false
    },
    keyQuestionImport: [],
    updateModelFromSheetId: {
        values: {
            protocolId: null,
            ppdId: null,
            parentId: null,
            sheetId: null
        },
        loading: false,
        loaded: false,
        error: null
    },
    linkToProtocol: {
        values: {
            protocolId: null,
            ppdId: null,
            connectProtocolId: null
        },
        loading: false,
        loaded: false,
        error: null
    }
};

export function reducer(state = initialState, action: fromAction.ParagraphsAction): ParagraphState {
    switch (action.type) {
        case fromAction.ADD_PARAGRAPH: {
            const {
                payload: { protocolId, parentId, options }
            } = action;
            return {
                ...state,
                addParagraph: {
                    values: {
                        protocolId,
                        parentId,
                        options
                    },
                    loading: true,
                    loaded: false
                }
            };
        }

        case fromAction.ADD_PARAGRAPH_SUCCESS: {
            return {
                ...state,
                addParagraph: {
                    values: {
                        protocolId: null,
                        parentId: null,
                        options: {}
                    },
                    loading: false,
                    loaded: true
                }
            };
        }

        case fromAction.ADD_PARAGRAPH_FAIL: {
            return {
                ...state,
                addParagraph: {
                    ...state.addParagraph,
                    loading: false,
                    loaded: false
                }
            };
        }

        case fromAction.UPDATE_PARAGRAPH: {
            const {
                payload: { protocolId, ppdId, parentId, options }
            } = action;
            return {
                ...state,
                updateParagraph: {
                    values: {
                        protocolId,
                        ppdId,
                        parentId,
                        options
                    },
                    loading: true,
                    loaded: false
                }
            };
        }

        case fromAction.UPDATE_PARAGRAPH_SUCCESS: {
            return {
                ...state,
                updateParagraph: {
                    values: {
                        protocolId: null,
                        ppdId: null,
                        parentId: null,
                        options: {}
                    },
                    loading: false,
                    loaded: true
                }
            };
        }

        case fromAction.UPDATE_PARAGRAPH_FAIL: {
            return {
                ...state,
                updateParagraph: {
                    ...state.updateParagraph,
                    loading: false,
                    loaded: false
                }
            };
        }

        case fromAction.UPDATE_PARAGRAPH_TO_MODEL: {
            return {
                ...state,
                updateParagraph: {
                    ...state.updateParagraph,
                    loading: true,
                    loaded: false
                }
            };
        }

        case fromAction.UPDATE_PARAGRAPH_TO_MODEL_SUCCESS: {
            return {
                ...state,
                updateParagraph: {
                    ...state.updateParagraph,
                    loading: false,
                    loaded: true
                }
            };
        }

        case fromAction.UPDATE_PARAGRAPH_TO_MODEL_FAIL: {
            return {
                ...state,
                updateParagraph: {
                    ...state.updateParagraph,
                    loading: false,
                    loaded: false
                }
            };
        }

        case fromAction.DELETE_PARAGRAPH: {
            const {
                payload: { protocolId, ppdId, parentId }
            } = action;
            return {
                ...state,
                deleteParagraph: {
                    values: {
                        protocolId,
                        ppdId,
                        parentId
                    },
                    loading: true,
                    loaded: false
                }
            };
        }

        case fromAction.DELETE_PARAGRAPH_SUCCESS: {
            return {
                ...state,
                deleteParagraph: {
                    values: {
                        protocolId: null,
                        ppdId: null,
                        parentId: null
                    },
                    loading: false,
                    loaded: true
                }
            };
        }

        case fromAction.DELETE_PARAGRAPH_FAIL: {
            return {
                ...state,
                deleteParagraph: {
                    ...state.updateParagraph,
                    loading: false,
                    loaded: false
                }
            };
        }

        case fromAction.LOAD_EVIDENCE: {
            return state;
        }

        case fromAction.LOAD_EVIDENCE_FAIL: {
            return { ...state };
        }

        case fromAction.LOAD_KEYQUESTION_IMPORT: {
            return state;
        }

        case fromAction.LOAD_KEYQUESTION_IMPORT_SUCCESS: {
            const { payload } = action;
            return { ...state, keyQuestionImport: payload };
        }
        case fromAction.LOAD_KEYQUESTION_IMPORT_FAIL: {
            return { ...state };
        }

        case fromAction.UPDATE_MODEL_BY_SHEET_ID: {
            const {
                payload: { protocolId, ppdId, parentId, sheetId }
            } = action;
            return {
                ...state,
                updateModelFromSheetId: {
                    values: {
                        protocolId,
                        ppdId,
                        parentId,
                        sheetId
                    },
                    loading: true,
                    loaded: false,
                    error: null
                }
            };
        }

        case fromAction.UPDATE_MODEL_BY_SHEET_ID_SUCCESS: {
            return {
                ...state,
                updateModelFromSheetId: {
                    ...state.updateModelFromSheetId,
                    loading: false,
                    loaded: true
                }
            };
        }

        case fromAction.UPDATE_MODEL_BY_SHEET_ID_FAIL: {
            const {
                payload: { message }
            } = action;
            return {
                ...state,
                updateModelFromSheetId: {
                    ...state.updateModelFromSheetId,
                    loading: false,
                    loaded: false,
                    error: message
                }
            };
        }

        case fromAction.LINK_TO_PROTOCOL: {
            const {
                payload: { protocolId, ppdId, connectProtocolId }
            } = action;
            return {
                ...state,
                linkToProtocol: {
                    values: {
                        protocolId,
                        ppdId,
                        connectProtocolId
                    },
                    loading: true,
                    loaded: false,
                    error: null
                }
            };
        }

        case fromAction.LINK_TO_PROTOCOL_SUCCESS: {
            return {
                ...state,
                linkToProtocol: {
                    ...state.linkToProtocol,
                    loading: false,
                    loaded: true
                }
            };
        }

        case fromAction.LINK_TO_PROTOCOL_FAIL: {
            const {
                payload: { error }
            } = action;
            return {
                ...state,
                linkToProtocol: {
                    ...state.linkToProtocol,
                    loading: false,
                    loaded: false,
                    error
                }
            };
        }
    }
    return state;
}

export const getKeyQuestionImport = (state: ParagraphState) => state.keyQuestionImport;
export const getUpdateModelFromSheetId = (state: ParagraphState) => state.updateModelFromSheetId;
export const getLinkToProtocol = (state: ParagraphState) => state.linkToProtocol;
