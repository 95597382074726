import { Injectable } from '@angular/core';

@Injectable()
export class ParagraphHelperService {
    constructor() {}
    flattenParagraph(nestedParagraphs) {
        const propExclude = 'children';
        const childPars = nestedParagraphs.reduce((acc, par) => (par.children ? [...acc, ...par.children] : []), []);
        const grandChildPars = childPars.reduce((acc, par) => (par.children ? [...acc, ...par.children] : []), []);

        const pars = nestedParagraphs.reduce((acc, par) => {
            return [
                ...acc,
                Object.keys(par).reduce((object, key) => {
                    if (key !== propExclude) {
                        object[key] = par[key];
                    }
                    return object;
                }, {})
            ];
        }, []);
        return [...pars, ...childPars, ...grandChildPars];
        
    }

}
