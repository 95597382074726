<div class="modal-header">
    <h4 class="modal-title">{{ 'FEEDBACK.TITLE' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-form [formGroup]="form">
        <div class="form-group">
            <label for="message">{{ 'FEEDBACK.MESSAGE' | translate}}:</label>
            <textarea id="message" class="form-control" formControlName="description"></textarea>
        </div>

        <div class="form-group">
            <label for="name">{{ 'FEEDBACK.NAME' | translate }}:</label>
            <input id="name" class="form-control" formControlName="name" />
        </div>

        <div class="form-group">
            <label for="email">{{ 'FEEDBACK.EMAIL' | translate }}:</label>
            <input id="email" class="form-control" formControlName="email" />
        </div>

        <div class="form-group">
            <div *ngIf="feedbackSent" class="alert alert-success text-center" role="alert">
                {{ 'FEEDBACK.THANKS' | translate }}
            </div>
            <button [disabled]="form.invalid" class="btn btn-info" type="button"
                    (click)="handleSubmit()">{{ 'SUBMIT' | translate }}</button>
        </div>

    </ng-form>
</div>
