<section class="page__section -no-bottom -transparent">
    <article class="card">
        <header class="card__header">
            <span class="card__header-heading">{{ 'NOT-FOUND.HEADING' | translate }}</span>
            <span class="card__header-sub-heading">{{ 'NOT-FOUND.SUBHEADING' | translate }}.</span>
        </header>
        <footer class="card__footer">
            <a href="#" class="btn btn-info" role="button" routerLink="/home">
                {{ 'NOT-FOUND.HOME' | translate }}
            </a>
        </footer>
    </article>
</section>
