<div class="search-field -desktop-only">
    <div class="search-field__container">
        <form class="search-field__form">
            <label class="search-field__label" [for]="'search-box-' + id"><span class="icon-search"></span></label>
            <input
                #searchBox
                [id]="'search-box-' + id"
                [(ngModel)]="asyncSelected"
                [typeaheadAsync]="true"
                [typeahead]="datasource$"
                (typeaheadOnSelect)="typeaheadOnSelect($event)"
                typeaheadOptionField="title"
                typeaheadGroupField="text"
                [placeholder]="placeholder"
                class="form-control search-field__input"
                name="search-box"
                container="body"
                autocomplete="off"
                [value]="query"
                (focus)="onFocus()"
                (blur)="onBlur()"
            />
        </form>
    </div>
</div>
