import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromActions from '../actions';
import * as fromService from '../../../../services';

@Injectable()
export class OutcomeEffects {
    constructor(private actions$: Actions, private ParagraphsService: fromService.ParagraphsService) {
    }

    @Effect()
    GetOutcomeEffect$ = this.actions$.pipe(
        ofType(fromActions.GET_OUTCOME),
        switchMap(action => {
            const {
                payload: { ppdId, populationId }
            } = action as any;
            return this.ParagraphsService.getOutcome({ ppdId, populationId }).pipe(
                map(result => {
                    const outcome = result['message list'];
                    if (outcome) {
                        return new fromActions.GetOutcomeSucces({ ppdId, outcome });
                    } else {
                        return new fromActions.GetOutcomeFail({ ppdId, error: { message: 'Cannot get outcome' } });
                    }
                }),
                catchError(error => of(new fromActions.GetOutcomeFail({ ppdId, error })))
            );
        })
    );
}
