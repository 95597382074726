import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'alii-web-iframe-modal',
  template: `
   <div class="modal-header">
     <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
       <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="height: calc(100vh - 100px);">
      <iframe [src]="safeExternalUrl" width="100%" height="100%" style="border: none; padding: 0; margin: 0"></iframe>
    </div>
  `,
})
export class IframeModalComponent {
  @Input() externalUrl: string;
  safeExternalUrl: SafeResourceUrl;

  constructor(public activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.safeExternalUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.externalUrl);
    window.addEventListener('message', this.handleMessage.bind(this), false);
  }

  handleMessage(event: MessageEvent) {
    if (event.data === 'FormSubmittedSuccessfully') {
      setTimeout(() => {this.activeModal.close();}, 3000);
    }
  }
}