<div class="modal-header">
    <h4 class="modal-title">Edit sources</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container">
        <ag-grid-angular
            #agGrid
            style="width: 100%"
            class="ag-theme-alpine"
            [rowData]="clonedData"
            [columnDefs]="clonedColumns"
            domLayout='autoHeight'
            rowHeight='150'
            (cellEditingStopped)="onCellEditingStopped($event)"
        >
        </ag-grid-angular>
    </div>
</div>

