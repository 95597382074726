/* eslint-disable @typescript-eslint/no-explicit-any */
import { DashboardCategory } from '@alii-web/models/dashboard-category.model';
import { Protocol } from '@alii-web/models/protocol.interface';

// --- Action Types --- //

type ActionType =
    // Dashboard
    | 'add_category'
    | 'setPosition'
    | 'remove'
    | 'createDashboard'
    | 'switchDashboard'

    // Category
    | 'addProtocol'
    | 'switchCategory'

    // Dashboard and category
    | 'updateTitle'
    | 'createCategory'
    | 'remove_category';

export const listActionTypes = [
    // Dashboard
    'add_category',
    'setPosition',
    'remove',
    'createDashboard',
    'switchDashboard',

    // Category
    'addProtocol',
    'switchCategory',

    // Dashboard and category
    'updateTitle',
    'createCategory',
    'remove_category'
];

// ---  Tag --- //

export interface DashboardV2Tag {
    id: string;
    // Sometimes a name and sometimes a title!
    name?: string;
    title?: string;
    protocols?: Protocol[];
}

// --- Action --- //

export interface DashboardV2Action {
    label: string;
    action: ActionType;
    value: boolean;
    // action = 'add_category'
    tags?: DashboardV2Tag[];
    // action = 'addProtocol'
    teams?: { id: string; title: string };
    // action = 'switch_category'
    categories: Array<{ id: string; name: string }>;
    // action = 'switchDashboard'
    dashboards: Array<{ id: string; title: string }>;
}

// --- Item --- //

export interface DashboardV2Item {
    id: string;
    title: string;
    position?: number;
    meta?: Record<string, unknown>;
}

// -- Dashboard Crud --- /

export type GetDashboardV2DTO = string;
export interface GetDashboardV2RO {
    dashboard_id: string;
    dashboard_title: string;
    is_exclusive_to_team: boolean;
    categories?: DashboardCategory[];
    actions?: DashboardV2Action[];
    message?: string;
    error?: any;
}

export interface UpdateDashboardV2DTO {
    title: string;
    isExclusiveToTeam: boolean;
    categoryIds: string[];
}

export interface UpdateDashboardV2RO {
    title_updated: string;
    dashboard: GetDashboardV2RO;
    message?: string;
    error?: any;
}

export interface CreateDashboardV2DTO {
    title: string;
    isExclusiveToTeam: boolean;
    categoryIds: string[];
}

export interface DashboardV2 {
    id: string;
    name: string;
    tags: DashboardV2Tag[];
}

export interface GetDashboardsV2RO {
    dashboards: DashboardV2[];
    tags: DashboardV2Tag[];
}

export type CreateDashboardV2RO = UpdateDashboardV2RO;

// --- Category crud --- //

export interface GetCategoryV2RO {
    category: {
        id: string;
        name: string;
        editable: boolean;
        protocols: Protocol[];
    };
    actions: DashboardV2Action[];
    message?: string;
    error?: any;
}

export interface CreateCategoryV2DTO {
    title: string;
    protocolIds: string[];
}

export type GetAllCategoriesV2RO = DashboardV2Tag[];
export type CreateCategoryV2RO = UpdateCategoryV2RO;
export type UpdateCategoryV2RO = GetCategoryV2RO;
export type UpdateCategoryV2DTO = CreateCategoryV2DTO;
