import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromStore from '../../../help/store';
import { HelpGetRO } from '../../../../models/help.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
    loading$: Observable<boolean>;
    loaded$: Observable<HelpGetRO>;
    error$: Observable<string>;

    constructor(private store: Store<fromStore.HelpFeatureState>) {}

    ngOnInit() {
        if (window.localStorage.getItem('customStyle') === 'rapp') {
            window.location.href = "/protocols/785f55df-0929-49d9-b45f-2849b759e218";
        }

        this.loading$ = this.store.select(fromStore.helpGetLoading);
        this.loaded$ = this.store.select(fromStore.helpGetLoaded);
        this.error$ = this.store.select(fromStore.helpGetError);

        // Call get help
        this.store.dispatch(new fromStore.HelpGet());
    }
}
