import { Component } from '@angular/core';

@Component({
    selector: 'alii-web-block-ui-template',
    templateUrl: './block-ui-template.component.html',
    styleUrls: ['./block-ui-template.component.scss']
})
export class BlockUiTemplateComponent {
    message: string;
    spinner = false;

    constructor() {}
}
