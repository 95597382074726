<div class='modal-header'>
    <h4 class='modal-title text-capitalize'>Compare Paragraph Versions</h4>
    <button type='button' class='close' aria-label='Close' (click)='activeModal.close()'>
        <span aria-hidden='true'>&times;</span>
    </button>
</div>
<div class='modal-body'>
    <ng-container *ngIf='identicalTitles && identicalTexts; else different'>
        <div class='container'>
            <div class='row mb-3'>The current and the previous version are identical.</div>
        </div>
    </ng-container>
    <ng-template #different>
        <ul ngbNav #nav='ngbNav' [(activeId)]='activeTab' [destroyOnHide]='false' class='nav-tabs'
            (activeIdChange)='tabChange($event)'>
            <li [ngbNavItem]="'compare'">
                <a ngbNavLink ngbTooltip='Previous and current paragraphs side by side'>Compare</a>
                <ng-template ngbNavContent>
                    <ng-container
                        [ngTemplateOutlet]='tabContent'
                        [ngTemplateOutletContext]="{versions:['previous', 'current']}">
                    </ng-container>
                </ng-template>
            </li>
            <li [ngbNavItem]="'previous'">
                <a ngbNavLink ngbTooltip='Previous paragraph only'>Previous</a>
                <ng-template ngbNavContent>
                    <ng-container
                        [ngTemplateOutlet]='tabContent'
                        [ngTemplateOutletContext]="{versions:['previous']}">
                    </ng-container>
                </ng-template>
            </li>
            <li [ngbNavItem]="'current'">
                <a ngbNavLink ngbTooltip='Current paragraph only'>Current</a>
                <ng-template ngbNavContent>
                    <ng-container
                        [ngTemplateOutlet]='tabContent'
                        [ngTemplateOutletContext]="{versions:['current']}">
                    </ng-container>
                </ng-template>
            </li>
            <li [ngbNavItem]="'differences'">
                <a ngbNavLink ngbTooltip='Differences between previous and current paragraphs'>Differences</a>
                <ng-template ngbNavContent>
                    <ng-container
                        [ngTemplateOutlet]='tabDifferences'>
                    </ng-container>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]='nav'></div>
        <div class='hidden'>
            <div [id]='idCurrentTitle' [innerHTML]='safeHtml(paragraphs.current.title)'></div>
            <div [id]='idCurrentText' [innerHTML]='safeHtml(paragraphs.current.text)'></div>
            <div [id]='idPreviousTitle' [innerHTML]='safeHtml(paragraphs.previous.title)'></div>
            <div [id]='idPreviousText' [innerHTML]='safeHtml(paragraphs.previous.text)'></div>
        </div>
    </ng-template>
</div>

<div class='modal-footer'>
    <a class='btn btn-info text-capitalize' type='submit' (click)='activeModal.close()'>Back</a>
</div>

<ng-template #tabContent let-versions='versions'>
    <div class='row mt-3 mr-2 auto-scroll'>
        <div *ngFor='let version of versions' class='col' [ngClass]="{'col-12': versions.length === 1,
        'col-6': versions.length === 2}">
            <div class='border-left pl-2 ml-2'
                 [ngClass]='{ h1: level === 1, h3: level === 3 || level === 5 }'
                 [innerHTML]='paragraphs[version].title'>
            </div>
            <div class='border-left pl-2 ml-2'
                 [innerHTML]='paragraphs[version].text'>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #tabDifferences>
    <div class='row m-2 pb-2 border-bottom'>
        <div *ngFor='let l of legendDisplay; let index = index' class='p-1'>
            <a href="#" [class]="'badge p-2 ' + l.class" [class.selected]='l.selected'
               (click)='clickLegend($event, index)'>{{l.name}}</a>
        </div>
    </div>
    <div class='row mt-3 mr-2 auto-scroll'>
        <div class='col col-12'>
            <div [id]='idDiffTitle'
                 class='border-left pl-2 ml-2 '
                 [ngClass]='{ h1: level === 1, h3: level === 3 || level === 5 }'>
            </div>
            <div [id]='idDiffText'
                 class='border-left pl-2 ml-2'>
            </div>
        </div>
    </div>
</ng-template>
