import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

// Translation stuff, see: https://github.com/ngx-translate/core
import { TranslateModule } from '@ngx-translate/core';

import { reducers, effects } from './store';
import * as fromServices from '../../services';
import * as fromContainers from './containers';
import * as fromComponents from './components';
import * as fromEntryComponents from './entry-components';
import { PartialsModule } from '../../partials/partials.module';
import { OrganisationRoutingModule } from './organisation-routing.module';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        StoreModule.forFeature('organisationFeature', reducers),
        EffectsModule.forFeature(effects),
        OrganisationRoutingModule,
        NgxPermissionsModule.forChild(),
        PartialsModule,
        TranslateModule.forChild({ extend: true })
    ],
    providers: [...fromServices.services],
    declarations: [...fromComponents.components, ...fromContainers.containers, ...fromEntryComponents.entryComponents],
    exports: [...fromContainers.containers]
})
export class OrganisationModule {}
