<div class="dashboard">
    <div *ngIf="onboarding.onboardingHeader" class="dashboard__title">{{onboarding.onboardingHeader}}</div>
    <div class="dashboard__items">
        <div class="dashboard__item" *ngFor="let step of onboarding.onboardingSteps; trackBy: trackByFn">
            <article class="card">
                <header #onboardingCard class="card__header">
                    <span class="pull-right"><i class="fa fa-lightbulb-o"></i></span>
                    <span class="card__header-heading">{{ step.title }}</span>
                    <span class="card__header-subheading"></span>
                    <span class="card__main">
                        <div class="row">
                            <div class="col"> {{step.text || 'No description.'}}</div>
                            <div class="col" *ngIf="step.image_url">
                                <img *ngIf="step.image_url" alt="Step image">
                            </div>
                        </div>
                   </span>
                </header>
                <footer class="card__footer">
                    <a class="btn btn-light" [routerLink]="[step.url]">{{step.buttonLabel}}</a>
                </footer>
            </article>
        </div>
    </div>
</div>
