import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import * as dashboardExistingActions from '../actions/dashboard-existing.action';

import * as fromService from '../../../../services/dashboard.service';

@Injectable()
export class DashboardExistingEffects {
    constructor(private actions$: Actions, private dashboardService: fromService.DashboardService) {}

    @Effect()
    updateDashboardExisting$ = this.actions$.pipe(
        ofType(dashboardExistingActions.DashboardExistingActionTypes.UpdateDashboardExisting),
        switchMap(action => {
            const {
                payload: { dashboardExisting }
            } = action as any;
            return this.dashboardService
                .editDashboard(dashboardExisting.id, dashboardExisting.name)
                .pipe(map(() => new dashboardExistingActions.UpdateDashboardExistingSuccess({ dashboardExisting })));
        })
    );

    @Effect()
    removeDashboardExisting$ = this.actions$.pipe(
        ofType(dashboardExistingActions.DashboardExistingActionTypes.DeleteDashboardExisting),
        switchMap(action => {
            const {
                payload: { id }
            } = action as any;
            return this.dashboardService
                .removeDashboard(id)
                .pipe(map(() => new dashboardExistingActions.DeleteDashboardExistingSuccess()));
        })
    );
}
