<h3>Edit category</h3>
<p>
    <b>Title </b>: {{ values?.title }}
    <a (click)="handleOpenForm()" class="tag cursor-pointer"> edit title </a>
</p>

<div class="table-responsive">
    <table class="table -aliiTable">
        <thead>
        <tr>
            <th class="table__text table__title table__column-50" scope="col">
                Protocols in this category
            </th>
            <th class="table__text table__column-10" scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let protocol of tagProtocols; trackBy: trackByFn">
            <td class="table__text table__title table__actions">{{ protocol.title }}</td>
            <td class="table__text table__title table__actions">
                <a class="tag cursor-pointer" (click)="removeProtocol(protocol.id)">
                    remove
                </a>
            </td>
        </tr>
        </tbody>
    </table>
</div>

<div>
    <div class="col-sm-8">
        <ng-form [formGroup]="addOrgProtocolForm">
            <div class="input-group mb-3">
                <label class="hidden" for="protocol-id"></label>
                <select id="protocol-id" class="form-control" formControlName="protocolId">
                    <option value="null" selected>Add protocol from your organization:</option>
                    <option
                        *ngFor="let item of orgProtocols; trackBy: trackByFn"
                        [value]="item.id"
                    >{{ item.title }}</option
                    >
                </select>
                <div class="input-group-append">
                    <button class="btn btn-info" type="submit" (click)="handleSubmitOrg()">
                        Submit
                    </button>
                </div>
            </div>
        </ng-form>
    </div>
    <div class="col-sm-8">
        <ng-form [formGroup]="addNonOrgProtocolForm">
            <div class="input-group mb-3">
                <label class="hidden" for="protocol-id-non"></label>
                <select id="protocol-id-non" class="form-control" formControlName="protocolId">
                    <option value="null" selected
                    >Add protocol from outside your organization:
                    </option>
                    <option
                        *ngFor="let item of nonOrgProtocols; trackBy: trackByFn"
                        [value]="item.id"
                    >{{ item.title }}</option
                    >
                </select>
                <div class="input-group-append">
                    <button class="btn btn-info" type="submit" (click)="handleSubmitNonOrg()">
                        Submit
                    </button>
                </div>
            </div>
        </ng-form>
    </div>
</div>
