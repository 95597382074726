import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromStore from '../../store';

import * as fromService from '../../../../services';
import { DashboardTag } from '../../../../models/dashboard-tag.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-dashboard-tag-edit',
    templateUrl: './dashboard-tag-edit-container.component.html'
})
export class DashboardTagEditContainerComponent implements OnInit {
    id: string;
    tag$: Observable<DashboardTag> = this.store.select(fromStore.getSelectedDashboardTag);
    protocols: any;
    all: any;

    constructor(
        private route: ActivatedRoute,
        private DashboardService: fromService.DashboardService,
        private store: Store<fromStore.DashboardState>,
        private router: Router
    ) {
        this.route.paramMap.subscribe(params => {
            this.id = params.get('id');
        });
        this.store.dispatch(new fromStore.LoadDashboardSummary());
        this.store.dispatch(new fromStore.SelectDashboardTag({ tagId: this.id }));

        this.DashboardService.getProtocolsByTagId(this.id).subscribe(response => {
            this.protocols = response;
        });
        this.DashboardService.getAllProtocols().subscribe(response => {
            this.all = response;
        });
    }

    ngOnInit() {}

    handleEventBus(event) {
        const { type, payload } = event;

        switch (type) {
            case 'updateTag':
                this.updateTag(payload);
                break;

            case 'removeTag':
                this.removeTag(payload);
                break;

            case 'addProtocol':
                this.addProtocol(payload);
                break;

            case 'removeProtocol':
                this.removeProtocol(payload);
                break;

            default:
                break;
        }
    }

    updateTag(payload) {
        this.store.dispatch(new fromStore.UpdateDashboardTag({ dashboardTag: payload }));
    }

    removeTag(payload) {
        const { tagId } = payload;
        const id = tagId;
        this.store.dispatch(new fromStore.DeleteDashboardTag({ id }));
        this.router.navigate(['/dashboard/tag/manage']);
    }

    addProtocol(payload) {
        const { protocolId } = payload;
        this.DashboardService.addProtocolToTag(protocolId, this.id).subscribe(response => {
            this.DashboardService.getProtocolsByTagId(this.id).subscribe(response => {
                this.protocols = response;
            });
        });
    }

    removeProtocol(payload) {
        const { protocolId } = payload;
        this.DashboardService.removeProtocolFromTag(protocolId, this.id).subscribe(response => {
            this.DashboardService.getProtocolsByTagId(this.id).subscribe(response => {
                this.protocols = response;
            });
        });
    }
}
