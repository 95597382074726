import * as fromActions from '../actions/models-outcomes.action';

export interface ModelsOutcomesState {
    entries: {};
}

export const initialState: ModelsOutcomesState = {
    entries: {}
};

export function reducer(state = initialState, action: fromActions.ModelsOutcomesAction): ModelsOutcomesState {
    switch (action.type) {
        case fromActions.MAP_MODELS_OUTCOMES:
        case fromActions.SHOW_ALL_OUTCOMES_SUCCESS:
        case fromActions.SHOW_OUTCOMES_BY_POPULATION_ID_SUCCESS:
        case fromActions.UPDATE_OUTCOMES: {
            const { payload } = action;
            const { outcomes } = payload;
            return {
                ...state,
                entries: {
                    ...state.entries,
                    ...outcomes,
                }
            };
        }
        case fromActions.SORT_OUTCOMES: {
            const { entries } = state;
            const sorted_outcomes = Object.keys(entries).reduce((acc, entryId) => {
                return {
                    ...acc,
                    [entryId]: entries[entryId].slice().sort((a, b) => b.score - a.score)
                };
            }, {});

            return {
                ...state,
                entries: sorted_outcomes
            };
        }
        case fromActions.ADD_OUTCOME_SUCCESS: {
            const { parentId, outcomes } = action.payload;

            return {
                ...state,
                entries: {
                    ...state.entries,
                    [parentId]: outcomes
                }
            };
        }
        case fromActions.UPDATE_OUTCOME_SUCCESS:
        case fromActions.UPDATE_OUTCOME_TITLE_SUCCESS: {
            const { payload } = action;
            const { parentId, ppdId, outcome } = payload;
            const targetIndex = state.entries[parentId].findIndex(obj => obj.id + '' === ppdId + '');

            return {
                ...state,
                entries: {
                    ...state.entries,
                    [parentId]: Object.assign([...state.entries[parentId]], { [targetIndex]: outcome })
                }
            };
        }
        case fromActions.DELETE_OUTCOME_SUCCESS: {
            const { parentId, ppdId } = action.payload;

            return {
                ...state,
                entries: {
                    ...state.entries,
                    [parentId]: [...state.entries[parentId].filter(f => f.id !== ppdId)]
                }
            };
        }
    }
    return state;
}
export const getModelOutcomeEntries = (state: ModelsOutcomesState) => state.entries;
