import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Output,
    OnDestroy,
    OnInit,
    Input
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GetOutcome, GetOutcomeRO } from '@alii-web/models/outcome.interface';


@Component({
    selector: 'alii-web-outcome-edit-modal',
    templateUrl: './outcome-edit.component.html',
    styleUrls: ['./outcome-edit.component.scss']
})
export class OutcomeEditModalComponent implements OnInit, OnDestroy {

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    outcome: any;
    populationId: string;
    loading: boolean;
    modelId: string;

    subscriptions: Subscription[] = [];
    
    constructor(public activeModal: NgbActiveModal, private cdr: ChangeDetectorRef, private sanitizer: DomSanitizer) {}

    ngOnInit() {
        
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }


    storeText() {
        var inputValue = (<HTMLInputElement>document.getElementById('summaryText')).value;
        const outcomeId = this.outcome.id;
        const populationId = this.populationId;
        const action = {
            type: 'handleOutcomeEdit',
            payload: {
                populationId,
                outcomeId,
                text: inputValue,
                modelId: this.modelId
            }
        };
        this.eventBus.emit(action);
    }

}
