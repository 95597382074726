import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { RenderHTML } from '../../../../../literatures/components/reviews/render-html.component';
import { EditHTML } from '../../../../../literatures/components/reviews/edit-html.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'view-article-modal',
    templateUrl: './view-article-modal.component.html'
})
export class ViewArticleModalComponent implements OnInit {
    article: any;
    frameworkComponents;

    @Output() eventBus = new EventEmitter<any>();
    regex = /_/gi;

    constructor(
        public activeModal: NgbActiveModal, 
        private sanitizer: DomSanitizer) {
            this.frameworkComponents = {
                renderHTML: RenderHTML,
                editHTML: EditHTML,
              };
        } 

    ngOnInit() {}
    sanitize(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    summaryOfReviews(reviews) {
        const ignoreColumns = ['action', 'id', 'type'];
        return this.createSummary(ignoreColumns, reviews);
    }
    createSummary(ignoreColumns, entries) {
        return Object.keys(entries)
            .filter(key => !ignoreColumns.includes(key))
            .filter(key => entries[key] !== null)
            .filter(key => entries[key].toString() !== '')
            .map(key => {
                const value = entries[key].toString().replace('illegal reference: ', ''); //todo: fix this in backend
                return {
                    key,
                    value
                };
            });
    }

    trackByFn = (index, item) => item.id || index;
}
