import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../../../../models/field.interface';
import { FieldConfig } from '../../../../../models/field-config.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'form-paragraphId',
    template: `
        <div class="dynamic-field form-select" [formGroup]="group">
            <span class="icon-arrows-h" (click)="toggleClass()">&nbsp;</span>
            <label>{{ config.label }}</label>
            <input 
                class="form-control" id="myFilter" 
                (keyup)=filterResults() placeholder="filter" 
                *ngIf="config.options.length > 10"
                style="margin-bottom: 4px; height: 1.8em;"  
              >
            <div *ngIf="config.info" class="field-info">{{ config.info }}</div>

            <select 
              (ngModelChange)="onChange($event)"
              [formControlName]="config.name" class="form-control" multiple id="optionList">
                <option *ngFor="let option of config.options" [value]="option.value"> {{ option.title }} </option>
            </select>

            <div *ngIf="conclusionsList.length" style="margin-top: 8px">
            <label>Select conclusion (optional)</label>
              <select 
                   (ngModelChange)="onChangeChild($event)"
                  [formControlName]="config.name" class="form-control" multiple id="optionList">
                  <option *ngFor="let option of getSubOptions()" [value]="option.value"> {{ option.title }} </option>
              </select>
            </div>
        </div>
    `
})
export class FormParagraphIdComponent implements Field {
    config: FieldConfig;
    group: FormGroup;

    conclusionsList: any[] = [];
    optionSelected: boolean = false;
    parentsSelected = [];

    public filterResults() {
        var input, filter, ul, li, a, i;
        input = document.getElementById("myFilter");
        filter = input.value.toUpperCase();
        let div = document.getElementById("optionList");
        let options = div.getElementsByTagName("option");
        for (i = 0; i < options.length; i++) {
          let txtValue = options[i].textContent || a[i].innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            options[i].style.display = "";
          } else {
            options[i].style.display = "none";
          }
        }

    }

    public onChangeChild(event) {
      this.parentsSelected.forEach(parent => this.group.controls["paragraphId"].value.push(parent));
    }

    public onChange(event) {
      this.optionSelected = true
      let res = []
      this.conclusionsList =[]
      this.parentsSelected =[]
      event.forEach(element => {
        res.push(this.config.options.filter(o => o.value === element)[0]);  
      });
       
      res.forEach(element => {
        this.parentsSelected.push(element.value);   
        element.conclusions.forEach(conclusion => { 
          let option = {'value': conclusion['protocol_paragraph.id'], 'title': conclusion['protocol_paragraph.title']}
          this.conclusionsList.push(option) 
        }); 
      });
    }

    public getSelectedParents() {
        return this.parentsSelected;
    }

    public getSubOptions() {
      return(this.conclusionsList)
    }

    public toggleClass() {
      let container = document.getElementById("reviewBox")
      if(container.classList.contains("w-100")) {
          container.classList.remove("w-100")
          container.classList.add("expand")
        } else {
          container.classList.add("w-100")
          container.classList.remove("expand")
        }
    }
}
