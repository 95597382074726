<div
    class="user-text as-html summaryText"
    [froalaEditor]="textOptions"
    [(froalaModel)]="text"
    id="froala-content-{{ parId }}"
    ngbTooltip="{{ ((text && showIsEditAbleEnabled) ? 'TEXT-EDIT.CLICK-TO-EDIT' : '') | translate}}" >
</div>

<div *ngIf="saving" class="spinner-grow spinner-grow-sm float-left mt-2" role="status">
    <span class="sr-only">{{ 'LOADING' | translate }}...</span>
</div>
<p *ngIf="linkedProtocol"><br />
    <button class="btn btn-primary"
            [routerLink]="'/protocols/' + linkedProtocol">{{ 'TEXT-EDIT.OPEN-DOCUMENT' | translate}}</button>
</p>

<div *ngIf="isSummary && !isIntermediateSummary" class="btn-group mt-2" role="group">
    <span (click)="copyText(parId, true)" class="button cursor-pointer"><span
        class="icon-copy"></span> {{ 'TEXT-EDIT.COPY-TEXT' | translate }}</span>

    <!--
    <span (click)="copyLink(parId)" class="button cursor-pointer ml-2"><span
        class="icon-link"></span> {{ 'TEXT-EDIT.COPY-LINK' | translate }}</span>
    -->
</div>
