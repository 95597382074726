import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-flowchart-delete-modal',
    templateUrl: './flowchart-delete.component.html'
})
export class FlowchartDeleteComponent implements OnInit {
    name: string;

    form: FormGroup;

    title = 'Delete Flowchart';
    text = 'You are about to delete the flowchart, are you sure?';

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({});
    }
    handleSubmit() {
        if (this.form.valid) {
            this.activeModal.close({});
        }
    }
}
