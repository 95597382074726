import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Protocol } from '../../../../../models/protocol.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'detail-form-settings',
    template: `
        <div class="modal-header">
            <h4 class="modal-title">Change settings</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="text-align: left;" class="wysiwyg">
                <h3>Change settings</h3>

                <p></p>

                <div id="formTitle" class="cx_form">
                    <form [formGroup]="myForm">
                        <div class="form-text message">
                            Please enter the new title: <br />
                            <textarea formControlName="title" class="form-control" cols="40" rows="2"></textarea>
                        </div>
                        <div class="form-text message">
                            External url: <br />
                            <textarea formControlName="externalUrl" class="form-control" cols="40" rows="2"></textarea>
                        </div>
                        <div class="form-text message">
                            Type: <br />
                            <select formControlName="type" class="form-control">
                                <option value="Protocol">{{'DOCUMENT' | translate}}</option>
                                <option value="Guideline">{{'GUIDELINE' | translate}}</option>
                            </select>
                        </div>
                        <div class="form-text message">
                            {{'SETTINGS.LITERATURE' | translate}}: <br />
                            <select formControlName="literature" class="form-control">
                                <option [ngValue]="true">{{'SETTINGS.TRUE' | translate}}</option>
                                <option [ngValue]="false">{{'SETTINGS.FALSE' | translate}}</option>
                            </select>
                        </div>
                        <div class="form-text message">
                            {{'SETTINGS.FLOWCHART' | translate}}: <br />
                            <select formControlName="showFlowchart" class="form-control">
                                <option [ngValue]="true">{{'SETTINGS.TRUE' | translate}}</option>
                                <option [ngValue]="false">{{'SETTINGS.FALSE' | translate}}</option>
                            </select>
                        </div>
                        <div class="form-text message">
                            {{'SETTINGS.INTERACTIVE' | translate}}: <br />
                            <select formControlName="showInteractive" class="form-control">
                                <option [ngValue]="true">{{'SETTINGS.TRUE' | translate}}</option>
                                <option [ngValue]="false">{{'SETTINGS.FALSE' | translate}}</option>
                            </select>
                        </div>
                        <div class="form-text message">
                        Visibility: <br />
                            <select formControlName="visibility" class="form-control">
                                <option value="True">{{'SETTINGS.ALLTHETEAMMEMBERS' | translate}}</option>
                                <option value="Only to authors">{{'SETTINGS.AUTHORSONLY' | translate}}</option>
                            </select>
                        </div>
                    </form>
                </div>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" (click)="handleSubmit()">Submit</button>
            </div>
        </div>
    `
})
export class FormSettingsComponent implements OnInit {
    @Input() protocol: Protocol;

    @Output() submit: EventEmitter<Protocol> = new EventEmitter<Protocol>();
    myForm: FormGroup;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}
    ngOnInit() {
        this.createForm();
    }
    private createForm() {
        this.myForm = this.fb.group({
            title: [this.protocol.title],
            type: [this.protocol.type],
            showFlowchart: [this.protocol.showFlowchart],
            showInteractive: [this.protocol.showInteractive],
            externalUrl: [this.protocol.externalUrl],
            visibility: [this.protocol.visibility],
            literature: [this.protocol.isLiterature]
        });
    }
    handleSubmit() {
        this.activeModal.close(this.myForm.value);
    }
}
