import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../../../../models/field.interface';
import { FieldConfig } from '../../../../../models/field-config.interface';

import { environment } from '../../../../../../environments/environment';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'form-editor',
    templateUrl: './form-editor.component.html',
    styleUrls: ['./form-editor.component.scss']
})
export class FormEditorComponent implements Field {
    config: FieldConfig;
    group: FormGroup;
    public options = {
        key: environment.froala.key,
        attribution: false,
        heightMin: 120,
        quickInsertTags: [''],
        pastePlain: true,
        charCounterCount: false,
        toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'formatUL', 'formatOL'],
        events: {
            // Froala bugfix insert table from google sheets
            'paste.afterCleanup': html => html.replace(';width:0px;', ';')
        }
    };

    getControlName(config) {
        if (config.questionId)
            return config.questionId;
        else {
            return config.name;
        }
    }

    getOptions(config) {
        return {
            key: environment.froala.key,
            attribution: false,
            heightMin: 120,
            quickInsertTags: [''],
            pastePlain: true,
            charCounterCount: false,
            toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'formatUL', 'formatOL'],
            events: {
                // Froala bugfix insert table from google sheets
                'paste.afterCleanup': html => html.replace(';width:0px;', ';')
            },
            placeholderText: config.placeholder
        };
    }
}
