import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromHelp from '../reducers/help.reducer';

export const getHelpState = createSelector(
    fromFeature.getHelpFeatureState,
    (state: fromFeature.HelpFeatureState) => {
        return state.help;
    }
);
export const helpGetLoaded = createSelector(getHelpState, fromHelp.getHelpLoaded);
export const helpGetLoading = createSelector(getHelpState, fromHelp.getHelpLoading);
export const helpGetError = createSelector(getHelpState, fromHelp.getHelpError);
