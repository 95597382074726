<div class="modal-header">
    <h4 class="modal-title">{{ 'DASHBOARD.PATIENTS.ADD-EXISTING-CASE' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-form [formGroup]="form">
        <div class="form-group">
            <label for="case-id">{{ 'CASE' | translate }}:</label>
            <select id="case-id" class="form-control" formControlName="caseId">
                <option *ngFor="let c of cases" [value]="c.id" [innerHtml]="c.name"></option>
            </select>
        </div>
        <div class="form-group">
            <label for="protocol-id">{{ 'DOCUMENT' | translate }}:</label>
            <select id="protocol-id" class="form-control" formControlName="protocolId">
                <option
                    *ngFor="let protocol of protocols"
                    [value]="protocol.id"
                    [innerHtml]="protocol.title"
                ></option>
            </select>
        </div>

        <button class="btn btn-primary" type="submit" [disabled]="!form.valid" (click)="handleSubmit()">{{ 'SUBMIT' | translate }}</button>
    </ng-form>
</div>
