import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationService } from 'apps/web/src/app/services/organisation.service';
import { Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { Organization } from 'apps/web/src/app/models/organization.interface';
import { Store } from '@ngrx/store';
import * as fromStore from './../../store';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
    messages$: Observable<any>;
    permissions$: Observable<[]>;
    organization$: Observable<Organization>;

    constructor(
        private service: OrganisationService,
        private permissionsService: NgxPermissionsService,
        private store: Store<fromStore.OrganisationsFeatureState>
    ) {}

    ngOnInit() {
        this.messages$ = this.service.getMessages();
        this.store.dispatch(new fromStore.LoadOrganization());
        this.organization$ = this.store.select(fromStore.getOrganization);

        this.organization$.subscribe(organization => {
            if (organization) {
                const roles = organization.roles.reduce((acc, rl: any) => {
                    return [...acc, rl.role];
                }, []);
                this.permissionsService.addPermission(roles);
            }
        });
    }

    onSearch(query) {
        this.messages$ = this.service.getMessages(query);
    }

    trackByFn = (index, item) => item.id || index;
}
