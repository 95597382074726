import { Action } from '@ngrx/store';
import { Params } from '@angular/router';

import { FormField, RegisterDTO, RegisterRO } from '../../../../models/register.model';

export const REGISTER_INIT = '[REGISTER] Register Init';
export const REGISTER_INIT_FAIL = '[REGISTER] Register Init Fail';
export const REGISTER_INIT_SUCCESS = '[REGISTER] Register Init Success';

export class RegisterInit implements Action {
    readonly type = REGISTER_INIT;

    constructor(public payload: { queryParams: Params }) {}
}

export class RegisterInitFail implements Action {
    readonly type = REGISTER_INIT_FAIL;

    constructor(public payload: { message: string }) {}
}

export class RegisterInitSucces implements Action {
    readonly type = REGISTER_INIT_SUCCESS;

    constructor(public payload: { formFields: FormField[] }) {}
}

export const REGISTER = '[REGISTER] Register';
export const REGISTER_FAIL = '[REGISTER] Register Fail';
export const REGISTER_SUCCESS = '[REGISTER] Register Success';

export class Register implements Action {
    readonly type = REGISTER;

    constructor(public payload: RegisterDTO) {}
}

export class RegisterFail implements Action {
    readonly type = REGISTER_FAIL;

    constructor(public payload: { message: string }) {}
}

export class RegisterSucces implements Action {
    readonly type = REGISTER_SUCCESS;

    constructor(public payload: RegisterRO) {}
}

export type RegisterAction =
    | RegisterInit
    | RegisterInitFail
    | RegisterInitSucces
    | Register
    | RegisterFail
    | RegisterSucces;
