import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-calendar-item',
    templateUrl: './calendar-item.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarItemComponent implements OnInit {
    @Input() item: any;
    public startDate: Date;
    public endDate: Date;
    public isAllday: boolean;

    public showExpandedText = false;

    public formattedDateString = '';

    constructor() {}

    ngOnInit() {
        // Set dates and date strings on init
        const monthNames = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];
        const start = this.item.startDate.replace(/[ :]/g, '-').split('-');
        const end = this.item.endDate.replace(/[ :]/g, '-').split('-');

        const startDate = new Date(start[0], start[1] - 1, start[2], start[3], start[4], start[5] || 0);
        const endDate = new Date(end[0], end[1] - 1, end[2], end[3], end[4], end[5] || 0);
        const allDay = this.isAllday ? this.isAllday : this.isAllDayCheck(startDate, endDate);
        let dateString;

        // Set date strings
        if (this.datesAreOnSameDay(startDate, endDate)) {
            dateString = startDate.getDate() + ' ' + monthNames[startDate.getMonth()] + ' ' + startDate.getFullYear();

            if (!allDay) {
                dateString +=
                    ' ' +
                    this.pad(startDate.getHours(), 2) +
                    ':' +
                    this.pad(startDate.getMinutes(), 2) +
                    ' - ' +
                    this.pad(endDate.getHours(), 2) +
                    ':' +
                    this.pad(endDate.getMinutes(), 2);
            }
        } else {
            dateString = startDate.getDate() + ' ' + monthNames[startDate.getMonth()] + ' ' + startDate.getFullYear();

            if (!allDay) {
                dateString += ' ' + this.pad(startDate.getHours(), 2) + ':' + this.pad(startDate.getMinutes(), 2);
            }

            dateString +=
                ' - ' + endDate.getDate() + ' ' + monthNames[endDate.getMonth()] + ' ' + endDate.getFullYear();

            if (!allDay) {
                dateString += ' ' + this.pad(endDate.getHours(), 2) + ':' + this.pad(endDate.getMinutes(), 2);
            }
        }

        // Save
        this.formattedDateString = dateString;
        this.startDate = startDate;
        this.endDate = endDate;
    }

    /**
     * User clicked the read more button
     */
    public clickedReadMore() {
        this.showExpandedText = true;
    }

    /**
     * Helper function to check if two dates are on the same date
     * @param first Date object
     * @param second Date object
     */
    public datesAreOnSameDay(first, second) {
        return (
            first.getFullYear() === second.getFullYear() &&
            first.getMonth() === second.getMonth() &&
            first.getDate() === second.getDate()
        );
    }

    /**
     * Helper function to check if it is an all-day event
     * @param first Date object
     * @param second Date object
     */
    public isAllDayCheck(first, second) {
        return (
            first.getHours() === 0 && first.getMinutes() === 0 && second.getHours() === 0 && second.getMinutes() === 0
        );
    }

    /**
     * Helper function to pad string with zeroes
     * @param number input number
     * @param size total number of characters
     */
    public pad(number, size) {
        let output = number + '';
        while (output.length < size) {
            output = '0' + output;
        }
        return output;
    }
}
