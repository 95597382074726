import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { SearchDTO, SearchRO } from '../../models';

@Injectable()
export class SearchService {
    private searchDTO: SearchDTO = null;

    // Components can subscribe to query changes.
    private _query: BehaviorSubject<SearchDTO> = new BehaviorSubject(this.searchDTO);
    public readonly query: Observable<SearchDTO> = this._query.asObservable();

    constructor(private http: HttpClient) {}

    getSearch(payload: SearchDTO): Observable<SearchRO> {
        this.searchDTO = payload;
        this._query.next(this.searchDTO);
        if (payload.q && payload.q.length) {
            const protocolId = payload.protocolId ? `&protocolId=${payload.protocolId}` : '';
            const filter = payload.filter ? `&filter=${payload.filter}` : '';
            const url = `${environment.baseUrl}/api/protocol/search.vm?q=${payload.q}${protocolId}${filter}`;
            return this.http.get<SearchRO>(url).pipe(
                map(response => response),
                catchError((error: any) => throwError(error))
            );
        } else {
            return of([]);
        }
    }
}
