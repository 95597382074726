import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as setDashboardActions from '../actions/set-dashboard.action';
import * as startingPageActions from '../actions/starting-page.action';
import { StartingPageService } from '../../services';

@Injectable()
export class SetDashboardEffects {
    constructor(private actions$: Actions, private startingPageService: StartingPageService) {}

    @Effect()
    setDashboard$ = this.actions$.pipe(
        ofType(setDashboardActions.SET_DASHBOARD),
        switchMap(action => {
            const { payload } = action as any;
            return this.startingPageService.setDashboard(payload).pipe(
                map(dashboard => new setDashboardActions.SetDashboardSucces(dashboard)),
                catchError(error => of(new setDashboardActions.SetDashboardFail(error)))
            );
        })
    );

    @Effect()
    setDashboardSuccess$ = this.actions$.pipe(
        ofType(setDashboardActions.SET_DASHBOARD_SUCCESS),
        mergeMap(action => {
            const { payload } = action as any;
            return [new startingPageActions.StartingPageResetDashboard(payload)];
        })
    );
}
