<div class="layout">
    <div class="layout__page">
        <main id="content" class="layout__content ">
            <div class="page">
                <div class="page__content">
                    <div id="loadingContent" *ngIf="categoriesLoading || createProtocolLoading">
                        <div class="spinner">
                            <div class="dot1"></div>
                            <div class="dot2"></div>
                        </div>
                    </div>
                    <div
                        id="protocolContent"
                        class="mainContentArea"
                        *ngIf="!categoriesLoading && !createProtocolLoading"
                    >
                        <div class="container page__section -small-bottom">
                            <div class="row"><h3>{{ 'DASHBOARD.ACTIONS.MANAGE.DOCUMENT-CREATE' | translate }}</h3></div>
                            <form [formGroup]="form" (submit)="onSubmit()">
                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <button [disabled]="form.invalid" type="submit"
                                                class="btn btn-primary pull-right">
                                            {{ 'SUBMIT' | translate }}
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="title" class="col-sm-2 col-form-label">{{ 'TITLE' | translate }}</label>
                                    <div class="col-sm-10">
                                        <input
                                            id="title"
                                            type="text"
                                            class="form-control"
                                            formControlName="title"
                                        />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <label for="category"
                                               class="col-sm-2 col-form-label">{{ 'CATEGORY' | translate }}</label>
                                        <div *ngIf="maxCategories === -1 || (categories && categories.length <=
                                        maxCategories)" class="col-sm-10">
                                            <div
                                                class="form-check"
                                                *ngFor="let category of categories; trackBy: trackByFn"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    formControlName="category"
                                                    id="categoryRadios{{ category.id }}"
                                                    [value]="category.id"
                                                />
                                                <label class="form-check-label" for="categoryRadios{{ category.id }}">
                                                    {{ category.name }}
                                                </label>
                                            </div>
                                        </div>
                                        <div *ngIf="maxCategories !== -1 && categories && categories.length >
                                        maxCategories" class="col-sm-10">
                                            <input
                                                id="category"
                                                type="text"
                                                class="form-control"
                                                formControlName="category"
                                                placeholder="{{ 'SELECT' | translate }}"
                                                [(ngModel)]="model"
                                                [ngbTypeahead]="search"
                                                (focus)="focus$.next($any($event).target.value)"
                                                (click)="click$.next($any($event).target.value)"
                                                #inputCategory="ngbTypeahead"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <label for="html">
                                        {{ 'PROTOCOLS.CREATE.PASTE-CONTENT' | translate }} <a
                                        href="#"
                                        (click)="onShowHelpText()"
                                        title="{{ 'MORE-INFORMATION' | translate }}"
                                    >
                                        <span class="button__icon"><span class="icon-question"></span></span>
                                    </a>
                                    </label>
                                    <textarea id="html" [froalaEditor]="options" formControlName="html" class="as-html mt-2"></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
