<div class="modal-header">
    <h4 *ngIf="data.title" class="modal-title">{{data.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row m-2"></div>
</div>
<div class="modal-footer">
    <a class="btn btn-info text-capitalize" type="submit" (click)="activeModal.close()">Back</a>
</div>
