import { ProtocolActions, ProtocolActionTypes } from '../actions/protocol.actions';

export interface ProtocolState {
    protocolId: string;
    selected: any;
}

export const initialState: ProtocolState = {
    protocolId: null,
    selected: null
};

export function reducer(state = initialState, action: ProtocolActions): ProtocolState {
    switch (action.type) {
        case ProtocolActionTypes.GetProtocol: {
            const {
                payload: { protocolId }
            } = action as any;
            return { ...state, protocolId };
        }
        case ProtocolActionTypes.GetProtocolSucces: {
            const {
                payload: { protocol }
            } = action as any;
            return { ...state, selected: protocol };
        }

        default: {
            return state;
        }
    }
}

export const SelectProtocolId = (state: ProtocolState) => state.protocolId;
export const SelectProtocol = (state: ProtocolState) => state.selected;
