<div class="layout">
    <div style="margin: 2em; position: absolute; z-index: 10;" *ngIf="protocol">
        <span class="icon-arrow-left"></span>
            <a
                [routerLink]= "'/protocols/' + protocol.id"
                [queryParams] = "{ version: 'Draft' }"
                style="cursor: pointer;"
                >

                {{'PROTOCOLS.MARGIN.BUTTON.DRAFT' | translate}}
            </a>
    </div>
    <div class="layout__page">
        <main id="content" class="layout__content">
            <div class="page">
                <div class="page__content">
                    
                    <div class="page__section -small-bottom-padding">
                        <div id="loadingContent" *ngIf="protocolLoading || flowchartLoading">
                            <div class="spinner">
                                <div class="dot1"></div>
                                <div class="dot2"></div>
                            </div>
                        </div>
                        <div *ngIf="!(protocolLoading || flowchartLoading)">
                            <h2>Flowchart</h2>
                            <div>Tap on the boxes to edit</div>
                            <div class="row mt-3">
                                <div class="col-11">
                                    <div class="btn-group">
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-back"
                                            ngbTooltip="Go back to protocol page"
                                            [routerLink]="['/protocols', id]"
                                            [queryParams]="{ version: 'Draft' }"
                                        >Back
                                        </button>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div *ngIf="flowchart.cards.length">
                                        <button type="button" class="btn btn-danger btn-delete pull-right"
                                                ngbTooltip="Delete flowchart"
                                                (click)="handleOpenDelete()">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row overflow-x-auto">
                                <alii-web-flowchart-area
                                    [scheme]="scheme | asHtml"
                                    [flowchart]="flowchart"
                                    (eventBus)="handleEventBus($event)"
                                >
                                </alii-web-flowchart-area>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
