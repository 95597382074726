import { Component, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Options } from 'sortablejs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-ordering-par-modal',
    templateUrl: './ordering-par-modal.component.html'
})
export class OrderingParModalComponent {
    @Output() eventBus = new EventEmitter<any>();
    paragraphs: any[];
    protocolId: string;

    options: Options = {
        group: 'listParagraph',
        dataIdAttr: 'data-id',
        onAdd: (event: any) => {
            const ppdId = event.item.getAttribute('data-id');
            const parentId = event.to.getAttribute('data-id');
            const payload = {
                protocolId: this.protocolId,
                options: { position: event.newIndex + 1 },
                ppdId,
                ...(parentId != 0 && { parentId })
            };
            
            this.handleUpdateParPosition(payload);
        },
        onUpdate: (event: any) => {
            const ppdId = event.item.getAttribute('data-id');
            const parentId = event.item.getAttribute('data-parentId');
            const payload = {
                protocolId: this.protocolId,
                options: { position: event.newIndex + 1 },
                ppdId,
                ...(parentId != 0 && { parentId })
            };
            
            this.handleUpdateParPosition(payload);
        }
    };

    constructor(public activeModal: NgbActiveModal) {}

    getOptionLev2() {
        return { ...this.options, group: 'listParagraphLev2' };
    }

    getParChildren(pars, parentId) {
        return pars.map(par => {
            return { ...par, parentId };
        });
    }

    handleUpdateParPosition(payload) {
        this.eventBus.emit({
            type: 'handleUpdateParPosition',
            payload
        });
    }

    trackByFn = (index, item) => item.id || index;
}
