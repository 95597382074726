import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'protocol-paragraph-add',
    templateUrl: './protocol-paragraph-add.component.html'
})
export class ProtocolParagraphAddComponent implements OnInit {
    @Input() protocolId: string;
    @Input() parentId: string;
    @Input() type: string;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    addParForm: FormGroup;

    hover = false;

    constructor(
        private fb: FormBuilder,
        private translateService: TranslateService
        ) {}
        
    ngOnInit() {
        this.createForm();
    }

    private createForm() {
        var title = ''
        if (this.type === 'conclusion') {
            this.translateService.get('PARAGRAPH.CONCLUSION').subscribe(translation => title = translation)
        } else if (this.type === 'recommendation') {
            this.translateService.get('PARAGRAPH.RECOMMENDATION').subscribe(translation => title = translation)
        } else if (this.type === 'consideration') {
            this.translateService.get('PARAGRAPH.CONSIDERATION').subscribe(translation => title = translation)
        } else {
            this.translateService.get('TAP_TO_CHANGE_TITLE').subscribe(translation => title = translation)
        }
        this.addParForm = this.fb.group({
            protocolId: [this.protocolId, Validators.required],
            parentId: '' + [this.parentId],
            title: [title],
            type: this.type
        });
    }
    handleSubmit() {
        if (this.addParForm.valid) {
            const action = {
                type: 'handleAddPar',
                payload: this.addParForm.value
            };
            this.eventBus.emit(action);
        }
    }
}
