import * as fromActions from '../actions/grade-assessment.action';

export interface GradesState {
    selected: {};
    entries: {};
}

export const initialState: GradesState = {
    selected: null,
    entries: {}
};
const EDIT_GRADE_ACTION_KEY = 'editGradeAssessment';
export function reducer(state = initialState, action: fromActions.GradesAssesmentAction): GradesState {
    switch (action.type) {
        case fromActions.MAP_GRADEASSESSMENT_REVIEWS: {
            const { gradeAssessment } = action.payload;

            return { ...state, entries: { ...state.entries, ...gradeAssessment } };
        }

        case fromActions.ADD_NEW_GRADEASSESSMENT_ENTRY: {
            const { paragraph } = action.payload;
            const entries = {
                ...state.entries,
                [paragraph.id]: []
            };
            return { ...state, entries };
        }
        case fromActions.ADD_NEW_MODEL_GRADEASSESSMENT_ENTRY: {
            const { paragraph } = action.payload;
            const entries = {
                ...state.entries,
                [paragraph.id]: paragraph.gradeAssessment
                    ? {
                          id: paragraph.gradeAssessment.id ? paragraph.gradeAssessment.id : null,
                          action: paragraph.gradeAssessment.action,
                          values: paragraph.gradeAssessment.questions ? paragraph.gradeAssessment.questions : {},
                          type: 'paragraph'
                      }
                    : {},
                ...{
                    ...paragraph.outcomes.reduce((acumulate, outcome) => {
                        const gradeOutcome = outcome.gradeAssessment
                            ? {
                                  [outcome.id]: {
                                      id: outcome.gradeAssessment.id ? outcome.gradeAssessment.id : null,
                                      action: outcome.gradeAssessment.action,
                                      values: outcome.gradeAssessment.questions
                                          ? outcome.gradeAssessment.questions
                                          : {},
                                      type: 'outcome'
                                  }
                              }
                            : {};
                        const gradePopulations = (outcome.populations || []).reduce((accumulate, population) => {
                            const gradePopulation = population.gradeAssessment
                                ? {
                                      [population.id]: {
                                          id: population.gradeAssessment.id ? population.gradeAssessment.id : null,
                                          action: population.gradeAssessment.action,
                                          values: population.gradeAssessment.questions
                                              ? population.gradeAssessment.questions
                                              : {},
                                          type: 'population'
                                      }
                                  }
                                : {};
                            return { ...accumulate, ...gradePopulation };
                        }, {});
                        return { ...acumulate, ...gradeOutcome, ...gradePopulations };
                    }, {})
                }
            };
            return { ...state, entries };
        }

        case fromActions.LOAD_GRADEASSESSMENT: {
            return { ...state, selected: null };
        }

        case fromActions.LOAD_GRADEASSESSMENT_SUCCESS: {
            const { ppdId, questions, gradeRowData, gradeColumnDefs } = action.payload;
            return {
                ...state,
                selected: { ppdId, ...state.entries[ppdId], questions, gradeRowData, gradeColumnDefs },
                entries: { ...state.entries, 
                    [ppdId]: { ...state.entries[ppdId], 
                            questions, 
                            gradeRowData, 
                            gradeColumnDefs } }
            };
        }

        case fromActions.LOAD_GRADEASSESSMENT_FAIL: {
            return { ...state };
        }

        case fromActions.CREATE_GRADEASSESSMENT_SUCCESS: {
            const { ppdId, questions, gradeRowData, gradeColumnDefs, gradeId } = action.payload;
            // const fields = state.entries[ppdId].questions.reduce((obj, q) => {
            //     obj[q.field] = '';
            //     return obj;
            // }, {});
            return {
                ...state,
                selected: {
                    ...state.selected,
                    id: gradeId,
                    action: EDIT_GRADE_ACTION_KEY,
                    questions: questions
                },
                entries: Object.assign(
                    { ...state.entries },
                    {
                        [ppdId]: {
                            ...state.entries[ppdId],
                            id: gradeId,
                            action: EDIT_GRADE_ACTION_KEY,
                            questions: questions,
                            gradeColumnDefs: gradeColumnDefs,
                            gradeRowData: gradeRowData
                        }
                    }
                )
            };
        }
        case fromActions.UPDATE_GRADEASSESSMENT_SUCCESS: {
            const { ppdId, gradeColumnDefs, gradeRowData, questions } = action.payload;
            return {
                ...state,
                entries: Object.assign(
                    { ...state.entries },
                    {
                        [ppdId]: {
                            ...state.entries[ppdId],
                            questions: questions,
                            gradeColumnDefs: gradeColumnDefs,
                            gradeRowData: gradeRowData
                        }
                    }
                )
            };
        }
        case fromActions.DELETE_GRADEASSESSMENT_SUCCESS: {
            const { ppdId } = action.payload;
            return {
                ...state,
                entries: Object.assign(
                    { ...state.entries },
                    {
                        [ppdId]: {
                            ...state.entries[ppdId],
                            questions: null,
                            gradeColumnDefs: [],
                            gradeRowData: []
                        }
                    }
                )
            };
        }
    }
    return state;
}

export const getGradesAssessment = (state: GradesState) => state;
