import { createSelector } from '@ngrx/store';

import * as fromIndexReducers from '../reducers';
import * as fromReducer from '../reducers/dashboard-patient.reducer';

const getDashboardPatientState = createSelector(
    fromIndexReducers.getDashboardState,
    (state: fromIndexReducers.DashboardState) => state.dashboardPatients
);
export const getDashboardPatient = createSelector(
    getDashboardPatientState,
    fromReducer.selectAll
);
export const getDashboardPatientOptions = createSelector(
    getDashboardPatientState,
    fromReducer.getPatientOptions
);
export const getDashboardPatientProtocols = createSelector(
    getDashboardPatientState,
    fromReducer.getPatientProtocols
);
