<ng-container>
    <div class="dashboard" *ngFor="let category of categories">
        <div class="dashboard__title">
            {{ category.title }}
        </div>
        <div class="dashboard__items">
            <div class="dashboard__item" *ngFor="let templateSet of category.templateSets; trackBy: trackByFn">
                <article #card class="card">
                    <header class="card__header">
                        <a
                            class="card__header-link"
                            [routerLink]="templateSet.type === 'parent' ? ['/shop', templateSet.id, 'sets'] : ['/shop', templateSet.id]"
                        >
                            <span class="card__header-heading underline">{{ templateSet.title }}</span>
                            <span class="card__header-subheading" [innerHTML]="safeHtml(templateSet.text || '')"></span>
                            <span class="card__main">
                                <div class="row">
                                    <div class="col">
                                        {{templateSet.number_of_children}}
                                        {{ templateSet.type === 'parent' ? 'set' : 'document'}}{{+templateSet.number_of_children === 1 ? '' : 's'}}
                                    </div>
                                    <div class="col">
                                        <img *ngIf="templateSet.owner_image_url"
                                             [src]="templateSet.owner_image_url"
                                             class="float-right owner-image"
                                             alt="Owner image">
                                        <p *ngIf="templateSet.owner_text"
                                           class="float-right" [innerHTML]="safeHtml(templateSet.owner_text)"></p>
                                    </div>
                                </div>
                            </span>
                        </a>
                    </header>
                    <footer class="card__footer">
                        <a
                            class="tag"
                            [ngClass]="{
                                '-primary': templateSet.status === 'active',
                                '-secondary': templateSet.status == 'inactive'
                            }"
                            [routerLink]="templateSet.type === 'parent' ? ['/shop', templateSet.id, 'sets'] : ['/shop', templateSet.id]"
                        >
                            {{ templateSet.status | titlecase }}
                        </a>
                    </footer>
                </article>
            </div>
        </div>
    </div>
</ng-container>
