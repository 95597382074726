import { Injectable } from '@angular/core';
import * as Bowser from 'bowser';

@Injectable()
export class BrowserService {
    isIE11: boolean;
    isChrome: boolean;
    isFirefox: boolean;
    isSafari: boolean;

    constructor() {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const browserName = browser.getBrowserName();
        const browserVersion = browser.getBrowserVersion();

        // console.log(`BrowserService name='${browserName}' version='${browserVersion}'`);

        this.isIE11 = browserName === 'Internet Explorer' && browserVersion === '11.0';
        this.isChrome = browserName === 'Chrome';
        this.isFirefox = browserName === 'Firefox';
        this.isSafari = browserName === 'Safari';

        if (this.isIE11) {
            console.log('Detected IE11');
        }
    }
}
