import { Action } from '@ngrx/store';

export const MAP_MODELS_FINDINGS = '[Protocol-Model] Map Models Findings';

export class MapModelsFindings implements Action {
    readonly type = MAP_MODELS_FINDINGS;

    constructor(public payload: { findings: any[] }) {}
}

export const ADD_FINDING = '[Protocols] Add Finding';
export const ADD_FINDING_FAIL = '[Protocols] Add Finding Fail';
export const ADD_FINDING_SUCCESS = '[Protocols] Add Finding Success';

export class AddFinding implements Action {
    readonly type = ADD_FINDING;

    constructor(public payload: any) {}
}

export class AddFindingFail implements Action {
    readonly type = ADD_FINDING_FAIL;

    constructor(public payload: any) {}
}

export class AddFindingSucces implements Action {
    readonly type = ADD_FINDING_SUCCESS;

    constructor(public payload: { parentId: string; findings: any }) {}
}

export const UPDATE_FINDING = '[Protocols] Update Finding';
export const UPDATE_FINDING_FAIL = '[Protocols] Update Finding Fail';
export const UPDATE_FINDING_SUCCESS = '[Protocols] Update Finding Success';

export class UpdateFinding implements Action {
    readonly type = UPDATE_FINDING;

    constructor(
        public payload: {
            protocolId: string;
            ppdId: string;
            parentId: string;
            options: {
                title?: string;
            };
        }
    ) {}
}

export class UpdateFindingFail implements Action {
    readonly type = UPDATE_FINDING_FAIL;

    constructor(public payload: any) {}
}

export class UpdateFindingSucces implements Action {
    readonly type = UPDATE_FINDING_SUCCESS;

    constructor(public payload: { parentId: string; ppdId: string; finding: any }) {}
}

export const DELETE_FINDING = '[Protocols] Delete Finding';
export const DELETE_FINDING_FAIL = '[Protocols] Delete Finding Fail';
export const DELETE_FINDING_SUCCESS = '[Protocols] Delete Finding Success';

export class DeleteFinding implements Action {
    readonly type = DELETE_FINDING;

    constructor(
        public payload: {
            protocolId: string;
            ppdId: string;
            parentId: string;
        }
    ) {}
}

export class DeleteFindingSucces implements Action {
    readonly type = DELETE_FINDING_SUCCESS;

    constructor(
        public payload: {
            parentId: string;
            ppdId: string;
        }
    ) {}
}

export class DeleteFindingFail implements Action {
    readonly type = DELETE_FINDING_FAIL;

    constructor(public payload: any) {}
}

export const UPDATE_FINDINGS = '[Protocols] Update Findings';

export class UpdateFindings implements Action {
    readonly type = UPDATE_FINDINGS;
    constructor(public payload: { findings?: any[] }) {}
}

export const USER_UPDATE_MODEL = '[Protocols] User updates a Model';
export class UserUpdateModel implements Action {
    readonly type = USER_UPDATE_MODEL;

    constructor(
        public payload: {
            modelId: string;
            findingId: string;
            option: any;
            multiple?: boolean;
            populationId?: string;
            isCopiedPopulation: boolean;
        }
    ) {}
}

export const RESET_MODEL = '[Protocols] User resets a Model';

export class ResetModel implements Action {
    readonly type = RESET_MODEL;

    constructor(public payload: { modelId }) {}
}

export const ADD_VALUE_OPTION = '[Protocols] Add Value Option';
export const ADD_VALUE_OPTION_FAIL = '[Protocols] Add Value Option Fail';
export const ADD_VALUE_OPTION_SUCCESS = '[Protocols] Add Value Option Success';

export class AddValueOption implements Action {
    readonly type = ADD_VALUE_OPTION;

    constructor(public payload: any) {}
}

export class AddValueOptionFail implements Action {
    readonly type = ADD_VALUE_OPTION_FAIL;

    constructor(public payload: any) {}
}

export class AddValueOptionSucces implements Action {
    readonly type = ADD_VALUE_OPTION_SUCCESS;

    constructor(
        public payload: {
            protocolId: string;
            modelId: string;
            findingId: string;
            valueOptionId: string;
            title: string;
            value: string;
        }
    ) {}
}

export const UPDATE_VALUE_OPTION = '[Protocols] Update Value Option';
export const UPDATE_VALUE_OPTION_FAIL = '[Protocols] Update Value Option Fail';
export const UPDATE_VALUE_OPTION_SUCCESS = '[Protocols] Update Value Option Success';

export class UpdateValueOption implements Action {
    readonly type = UPDATE_VALUE_OPTION;

    constructor(public payload: any) {}
}

export class UpdateValueOptionFail implements Action {
    readonly type = UPDATE_VALUE_OPTION_FAIL;

    constructor(public payload: any) {}
}

export class UpdateValueOptionSucces implements Action {
    readonly type = UPDATE_VALUE_OPTION_SUCCESS;

    constructor(
        public payload: {
            protocolId: string;
            modelId: string;
            findingId: string;
            valueOptionId: string;
            title: string;
            value: string;
        }
    ) {}
}

export const REMOVE_VALUE_OPTION = '[Protocols] Remove Value Option';
export const REMOVE_VALUE_OPTION_FAIL = '[Protocols] Remove Value Option Fail';
export const REMOVE_VALUE_OPTION_SUCCESS = '[Protocols] Remove Value Option Success';

export class RemoveValueOption implements Action {
    readonly type = REMOVE_VALUE_OPTION;

    constructor(public payload: { protocolId: string; modelId: string; findingId: string; valueOptionId: string }) {}
}

export class RemoveValueOptionSucces implements Action {
    readonly type = REMOVE_VALUE_OPTION_SUCCESS;

    constructor(
        public payload: {
            protocolId: string;
            modelId: string;
            findingId: string;
            valueOptionId: string;
        }
    ) {}
}

export class RemoveValueOptionFail implements Action {
    readonly type = REMOVE_VALUE_OPTION_FAIL;

    constructor(public payload: any) {}
}

export const ADD_SLIDER = '[Protocols] Add Slider';
export const ADD_SLIDER_FAIL = '[Protocols] Add Slider Fail';
export const ADD_SLIDER_SUCCESS = '[Protocols] Add Slider Success';

export class AddSlider implements Action {
    readonly type = ADD_SLIDER;

    constructor(public payload: any) {}
}

export class AddSliderFail implements Action {
    readonly type = ADD_SLIDER_FAIL;

    constructor(public payload: any) {}
}

export class AddSliderSucces implements Action {
    readonly type = ADD_SLIDER_SUCCESS;

    constructor(
        public payload: {
            protocolId: string;
            modelId: string;
            findingId: string;
            valueOptionId: string;
            type: string;
            min: string;
            max: string;
            step: string;
        }
    ) {}
}

export const UPDATE_SLIDER = '[Protocols] Update Slider';
export const UPDATE_SLIDER_FAIL = '[Protocols] Update Slider Fail';
export const UPDATE_SLIDER_SUCCESS = '[Protocols] Update Slider Success';

export class UpdateSlider implements Action {
    readonly type = UPDATE_SLIDER;

    constructor(public payload: any) {}
}

export class UpdateSliderFail implements Action {
    readonly type = UPDATE_SLIDER_FAIL;

    constructor(public payload: any) {}
}

export class UpdateSliderSucces implements Action {
    readonly type = UPDATE_SLIDER_SUCCESS;

    constructor(
        public payload: {
            protocolId: string;
            modelId: string;
            findingId: string;
            valueOptionId: string;
            type: string;
            min: string;
            max: string;
            step: string;
        }
    ) {}
}

export type ModelsFindingsAction =
    | MapModelsFindings
    | AddFinding
    | AddFindingFail
    | AddFindingSucces
    | UpdateFinding
    | UpdateFindingFail
    | UpdateFindingSucces
    | DeleteFinding
    | DeleteFindingFail
    | DeleteFindingSucces
    | UpdateFindings
    | UserUpdateModel
    | ResetModel
    | AddValueOption
    | AddValueOptionFail
    | AddValueOptionSucces
    | UpdateValueOption
    | UpdateValueOptionFail
    | UpdateValueOptionSucces
    | RemoveValueOption
    | RemoveValueOptionFail
    | RemoveValueOptionSucces
    | AddSlider
    | AddSliderFail
    | AddSliderSucces
    | UpdateSlider
    | UpdateSliderFail
    | UpdateSliderSucces;
