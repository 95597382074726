<div *ngIf="protocol.search_term" class="page__section  -small-bottom-padding -no-margin" id="guidelineliterature">
    <section id="searchTerm">
        <section id="searchInfo">
            <h3>{{'LITERATURE.SEARCHSTRATEGY_HEADER' | translate}}</h3>
            <p *ngIf="protocol.protocolSearchInfo.searchTerm" >
                {{'LITERATURE.SEARCHSTRATEGY_TEXT' | translate}} : {{ protocol.protocolSearchInfo.searchTerm }}
            </p>
            <ul>
                <li *ngIf="protocol.protocolSearchInfo.totalCount" >
                    {{'LITERATURE.SEARCHSTRATEGY_RESULTS_TOTAL' | translate}} : {{ protocol.protocolSearchInfo.totalCount }}
                </li>
                <li *ngIf="protocol.protocolSearchInfo.excludedCount" >
                    {{'LITERATURE.SEARCHSTRATEGY_RESULTS_EXCLUDED' | translate}} : {{ protocol.protocolSearchInfo.excludedCount }}
                </li>
                <li *ngIf="protocol.protocolSearchInfo.includedCount" >
                    {{'LITERATURE.SEARCHSTRATEGY_RESULTS_INCLUDED' | translate}} : {{ protocol.protocolSearchInfo.includedCount }}
                </li>
            </ul>

            <ng-container *ngIf="protocol.protocolSearchInfo.sourceList.length">
                <h4>{{'LITERATURE.SOURCELIST' | translate}}</h4>
                <div *ngFor="let article of protocol.protocolSearchInfo.sourceList; let i = index; trackBy: trackByFn">
                    <div class="underlined">
                        <span
                            [id]="'span-article-' + i"
                            class="wysiwyg small cursor-pointer"
                            (click)="onClickArticle(article.id)"
                        >{{ article.label }}
                        </span>
                    </div>

                    <ng-container *ngIf="!article.articleId">
                        <div *ngFor="let finding of article.finding; let i = index" class="underlined">
                            <span [id]="'span-link-' + i" [class.highlight-fade]="highlightLink(article)" class="wysiwyg small">
                                <a target="_blank" [href]="article.url" *ngIf="!!article.url">
                                    {{ i < 1 ? article.author : '' }} {{ i < 1 ? article.year : '' }}
                                </a>
                                <span *ngIf="!article.url">
                                    {{ i < 1 ? article.author : '' }} {{ i < 1 ? article.year : '' }}
                                </span>
                            </span>
                        </div>
                    </ng-container>

                </div>
            </ng-container>
        </section>
    </section>
</div>