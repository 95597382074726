<div *ngIf="avatar &&  !showInLine && !showAsTag" class="row container">
    <ng-container *ngIf="ownerlist?.length; else empty">
        <div *ngFor="let owner of ownerlist" class="col">
            <div *ngIf="owner.authorImageUrl" class="row">
                <img
                    [src]="owner.authorImageUrl" [width]="width" [height]="height"
                    class="rounded-circle" [alt]="owner.name"
                    [ngbTooltip]="small ? owner.name : ''"
                />
            </div>
            <div *ngIf="!small || !owner.authorImageUrl" class="row text-center">{{(owner.name || 'UNKNOWN') | translate }}</div>
        </div>
    </ng-container>
    <ng-template #empty>
        {{ 'NONE' | translate }}
    </ng-template>
</div>
<div *ngIf="showAsTag" class="row container">
    <ng-container *ngIf="ownerlist?.length; else empty">
        <div *ngFor="let owner of ownerlist" class="ownerTag row">
            <div class="col-2 authorImage" *ngIf="owner.authorImageUrl" >
                <img
                    [src]="owner.authorImageUrl" [width]="width / 2" [height]="height / 2"
                    class="rounded-circle" [alt]="owner.name"
                    [ngbTooltip]="small ? owner.name : ''"
                />
            </div>
            <div class="col-2 authorImage" *ngIf="!owner.authorImageUrl && owner.organizationLogo" >
                <img
                    [src]="owner.organizationLogo" [width]="width / 2" [height]="height / 2"
                    class="rounded-circle" [alt]="owner.name"
                    [ngbTooltip]="small ? owner.name : ''"
                />
            </div>
            <div class="col-2 authorImage" *ngIf="!owner.authorImageUrl && !owner.organizationLogo" >
                <span class="icon-user" style="padding: 4px 10px"></span>
            </div>
            <div class="col-10" *ngIf="owner.role">
                <span class="row">{{(owner.name || 'UNKNOWN') | translate }}</span>
                <span class="row">{{owner.role}}</span>
            </div>
            <div class="col-10" *ngIf="!owner.role">
                <span class="row" style="padding-top: 4px">{{(owner.name || 'UNKNOWN') | translate }}</span>
            </div>
        </div>
    </ng-container>
    <ng-template #empty>
        {{ 'NONE' | translate }}
    </ng-template>
</div>
<ng-container *ngIf="showInLine">
    <ng-container *ngIf="ownerlist?.length; else empty">
        <ng-container *ngFor="let owner of ownerlist">
            <ng-container class="authorImage" *ngIf="owner.authorImageUrl" >
                <img
                    [src]="owner.authorImageUrl" [width]="width / 3" [height]="height / 3"
                    class="rounded-circle" [alt]="owner.name"
                    [ngbTooltip]="small ? owner.name : ''"
                />
            </ng-container>
            <ng-container class="authorImage" *ngIf="!owner.authorImageUrl && owner.organizationLogo" >
                <img
                    [src]="owner.organizationLogo" [width]="width / 3" [height]="height / 3"
                    class="rounded-circle" [alt]="owner.name"
                    [ngbTooltip]="small ? owner.name : ''"
                />
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-template #empty>
        {{ 'NONE' | translate }}
    </ng-template>
</ng-container>


<div *ngIf="!avatar" class="row container">
    {{ names }}
</div>
