import { Action } from '@ngrx/store';

export enum PopulationActionTypes {
    SetPopulationId = '[Protocols] Set Population Id',
    SetCopiedPopulation = '[Protocols] Set Copied Population',
    UpdateCopiedPopulation = '[Protocols] Update Copied Population',
    UpdateCopiedPopulationName = '[Protocols] Update Copied Population Name',
    UpdateCopiedPopulationSuccess = '[Protocols] Update Copied Population Success',
    ClearCopiedPopulation = '[Protocols] Clear Copied Population',
    UserUpdateModelSuccess = '[Protocols] User Update Model Success',
    ModelCalculatedSuccess = '[Protocols] Model Calculation Success',
    ModelCalculatedFail = '[Protocols] Model Calculation Fail',
    SelectOutcome = '[Protocols] Select Outcome',
    SelectOutcomeSuccess = '[Protocols] Select Outcome Success',
    SelectOutcomeList = '[Protocols] Select Outcome List',
    SelectOutcomeListSuccess = '[Protocols] Select Outcome List Success',
    UpdateOutcomeSummaryText = '[Protocols] Update Outcome Summary Text'
}

export class SetPopulationId implements Action {
    readonly type = PopulationActionTypes.SetPopulationId;

    constructor(public payload: { populationId: string }) {}
}

export class SetCopiedPopulation implements Action {
    readonly type = PopulationActionTypes.SetCopiedPopulation;

    constructor(
        public payload: {
            ppdId: string;
            population: any;
            outcome: any;
            findings: any;
        }
    ) {}
}

export class UpdateCopiedPopulation implements Action {
    readonly type = PopulationActionTypes.UpdateCopiedPopulation;

    constructor(public payload: any) {}
}

export class UpdateCopiedPopulationName implements Action {
    readonly type = PopulationActionTypes.UpdateCopiedPopulationName;

    constructor(public payload: { name: string }) {}
}

export class UpdateCopiedPopulationSuccess implements Action {
    readonly type = PopulationActionTypes.UpdateCopiedPopulationSuccess;

    constructor(public payload: any) {}
}

export class ClearCopiedPopulation implements Action {
    readonly type = PopulationActionTypes.ClearCopiedPopulation;

    constructor(public payload: any) {}
}

export class UserUpdateModelSuccess implements Action {
    readonly type = PopulationActionTypes.UserUpdateModelSuccess;

    constructor(public payload: any) {}
}

export class ModelCalculatedSucces implements Action {
    readonly type = PopulationActionTypes.ModelCalculatedSuccess;

    constructor(public payload: any) {}
}

export class ModelCalculatedFail implements Action {
    readonly type = PopulationActionTypes.ModelCalculatedFail;

    constructor(public payload: any) {}
}

export class SelectOutcome implements Action {
    readonly type = PopulationActionTypes.SelectOutcome;

    constructor(public payload: any) {}
}

export class UpdateOutcomeSummaryText implements Action {
    readonly type = PopulationActionTypes.UpdateOutcomeSummaryText;

    constructor(public payload: any) {}
}

export class SelectOutcomeSuccess implements Action {
    readonly type = PopulationActionTypes.SelectOutcomeSuccess;

    constructor(public payload: any) {}
}

export class SelectOutcomeList implements Action {
    readonly type = PopulationActionTypes.SelectOutcomeList;

    constructor(public payload: any) {}
}

export class SelectOutcomeListSuccess implements Action {
    readonly type = PopulationActionTypes.SelectOutcomeListSuccess;

    constructor(public payload: any) {}
}


export type PopulationActions =
    | SetPopulationId
    | SetCopiedPopulation
    | UpdateCopiedPopulation
    | UpdateCopiedPopulationSuccess
    | UpdateCopiedPopulationName
    | ClearCopiedPopulation
    | UserUpdateModelSuccess
    | ModelCalculatedSucces
    | ModelCalculatedFail
    | SelectOutcome
    | SelectOutcomeSuccess
    | SelectOutcomeList
    | SelectOutcomeListSuccess
    | UpdateOutcomeSummaryText;
