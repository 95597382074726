import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from '@alii-web/services';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ``
})
export class CallbackComponent {
    authHash: string;
    returnUrl: string;

    constructor(private route: ActivatedRoute, private authService: AuthService) {
        this.authService.resetCustomStyling();

        this.route.paramMap.subscribe(params => {
            this.authHash = params.get('code');
        });

        this.route.queryParamMap.subscribe(queries => {
            this.returnUrl = queries.get('returnUrl');
        });
        if (this.authHash) {
            this.authService.loginByAuthHash({
                authHash: this.authHash,
                returnUrl: this.returnUrl ? this.returnUrl : '/home'
            });
        }
    }
}
