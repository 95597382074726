import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as fromEntryComponents from '../../entry-components';

import { Protocol } from '../../../../models/protocol.interface';

import { ParagraphHelperService } from '../../../../shared/helpers';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'flowchart-builder',
    templateUrl: './flowchart-builder.component.html',
    styleUrls: ['./flowchart-builder.component.scss']
})
export class FlowchartBuilderComponent implements OnChanges {
    @Input()
    id: string;

    @Input()
    protocol: Protocol;

    @Input()
    protocolLoading: boolean;

    @Input()
    scheme: string;

    @Input()
    flowchart: any;

    @Input()
    flowchartLoading: boolean;

    @Input()
    existingProtocols: any;

    // --- Navigation bar --- //

    @Input()
    versionId: string;

    @Input()
    version: string;

    @Input()
    currentVersion: string;

    @Input()
    draftVersion: string;

    @Input()
    deprecatedVersions: any[] = [];

    @Input()
    storedDraftVersions: any[] = [];

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    flatenParagraphs: any[];

    constructor(private modalService: NgbModal, public paragraphHelper: ParagraphHelperService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.protocol && changes.protocol.currentValue && changes.protocol.currentValue.paragraphs) {
            this.flatenParagraphs =
                this.protocol && this.protocol.paragraphs
                    ? this.paragraphHelper.flattenParagraph(this.protocol.paragraphs)
                    : [];
        }
    }

    getEditDropdownOptions() {
        return {
            paragraphs: this.flatenParagraphs,
            protocols: this.existingProtocols,
            cards: this.flowchart.cards,
            steps: []
        };
    }

    handleEventBus(event) {
        const { type, payload } = event;
        switch (type) {
            case 'handleOpentEdit':
                this.handleOpenEdit(payload);
                break;
            default:
                break;
        }
    }

    handleOpenEdit(payload) {
        const { paragraphId } = payload;
        const cardIndex = this.flowchart.cards.findIndex(c => c.id + '' === paragraphId) + '';

        if (cardIndex) {
            this.handleOpenEditCardModal(this.flowchart.cards[cardIndex]);
        }
    }

    handleOpenEditCardModal(card) {
        const modalRef = this.modalService.open(fromEntryComponents.FlowchartEditComponent, {
            size: 'xl' as 'lg'
        });
        modalRef.componentInstance.card = card;
        modalRef.componentInstance.paragraphs = this.flatenParagraphs;
        modalRef.componentInstance.contentOptions = this.getEditDropdownOptions();
        modalRef.result.then(
            payload => {
                this.handleSubmitEditCard(payload);
            },
            () => {
            }
        );
    }

    handleSubmitEditCard(payload) {
        const action = {
            type: 'handleSubmitEditCard',
            payload
        };
        this.eventBus.emit(action);
    }

    handleOpenDelete() {
        const modalRef = this.modalService.open(fromEntryComponents.FlowchartDeleteComponent);
        modalRef.result.then(
            () => {
                this.handleSubmitDelete();
            },
            () => {
            }
        );
    }

    handleSubmitDelete() {
        const idIndex = this.flowchart.cards.findIndex(c => c.startOfFlowChart);
        const action = {
            type: 'handleFlowchartSubmitDelete',
            payload: {
                protocolId: this.protocol.id,
                id: this.flowchart.cards[idIndex].id
            }
        };
        this.eventBus.emit(action);
    }
}
