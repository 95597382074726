import { Action } from '@ngrx/store';
import { User } from '../../../../models/user.interface';

export const LOAD_USERS = '[Protocols] Load Users';
export const LOAD_USERS_FAIL = '[Protocols] Load Users Fail';
export const LOAD_USERS_SUCCESS = '[Protocols] Load Users Success';

export class LoadUsers implements Action {
    readonly type = LOAD_USERS;
    constructor() {}
}

export class LoadUsersFail implements Action {
    readonly type = LOAD_USERS_FAIL;
    constructor(public payload: any) {}
}

export class LoadUsersSucces implements Action {
    readonly type = LOAD_USERS_SUCCESS;
    constructor(public payload: User[]) {}
}

export type UsersAction = LoadUsers | LoadUsersFail | LoadUsersSucces;
