import * as fromActions from '../actions/paragraphs-references.action';

export interface ParagraphsReferencesState {
    protocolId: string;
    ppdId: string;
    parentId: string;
    articleIds: string[];
    sources: any[];
    nonpubmed: {
        url: string;
        anchorText: string;
        author: string;
        year: string;
        title: string;
    };
    footnote: boolean;
    loading: boolean;
    loaded: boolean;
    error: any;
}

export const initialState: ParagraphsReferencesState = {
    protocolId: null,
    ppdId: null,
    parentId: null,
    articleIds: [],
    sources: [],
    nonpubmed: null,
    footnote: false,
    loading: false,
    loaded: false,
    error: null
};

export function reducer(
    state = initialState,
    action: fromActions.ParagraphsReferencesAction
): ParagraphsReferencesState {
    switch (action.type) {
        case fromActions.ADD_REFERENCES_TO_PARAGRAPH: {
            const { protocolId, ppdId, parentId, articleIds, footnote } = action.payload;
            return {
                ...state,
                protocolId,
                ppdId,
                parentId,
                articleIds,
                sources: [],
                nonpubmed: null,
                footnote: !!footnote,
                loading: true,
                loaded: false,
                error: null
            };
        }

        // case fromActions.ADD_REFERENCES_TO_PARAGRAPH_SUCCESS: {
        //     const { paragraph } = action.payload;
        //     return {
        //         ...state,
        //         sources: [...paragraph.sources],
        //         loading: false,
        //         loaded: true,
        //         error: null
        //     };
        // }


        case fromActions.ADD_REFERENCES_TO_PARAGRAPH_FAIL: {
            const { error } = action.payload;
            return {
                ...state,
                loading: false,
                loaded: false,
                error
            };
        }
        case fromActions.REMOVE_REFERENCES_FROM_PARAGRAPH: {
            const { protocolId, ppdId, parentId, articleIds, footnote } = action.payload;
            return {
                ...state,
                protocolId,
                ppdId,
                parentId,
                articleIds,
                sources: [],
                nonpubmed: null,
                footnote: !!footnote,
                loading: true,
                loaded: false,
                error: null
            };
        }

        // case fromActions.REMOVE_REFERENCES_FROM_PARAGRAPH_SUCCESS: {
        //     const { paragraph } = action.payload;
        //     return {
        //         ...state,
        //         sources: [...paragraph.sources],
        //         loading: false,
        //         loaded: true,
        //         error: null
        //     };
        // }

        case fromActions.REMOVE_REFERENCES_FROM_PARAGRAPH_FAIL: {
            const { error } = action.payload;
            return {
                ...state,
                loading: false,
                loaded: false,
                error
            };
        }


        case fromActions.ADD_NON_PUBMED_REFERENCES: {
            const { protocolId, ppdId, parentId, url, anchorText, author, year, title, footnote } = action.payload;
            return {
                ...state,
                protocolId,
                ppdId,
                parentId,
                articleIds: [],
                sources: [],
                nonpubmed: {
                    url,
                    anchorText,
                    author,
                    year,
                    title
                },
                footnote: !!footnote,
                loading: true,
                loaded: false,
                error: null
            };
        }
        // case fromActions.ADD_NON_PUBMED_REFERENCES_SUCCESS: {
        //     const { paragraph } = action.payload;
        //     return {
        //         ...state,
        //         sources: [...paragraph.sources],
        //         loading: false,
        //         loaded: true,
        //         error: null
        //     };
        // }
        case fromActions.ADD_NON_PUBMED_REFERENCES_FAIL: {
            const { error } = action.payload;
            return {
                ...state,
                loading: false,
                loaded: false,
                error
            };
        }
    }
    return state;
}

export const getParagraphReferences = (state: ParagraphsReferencesState) => state;
