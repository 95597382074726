import { Component, ChangeDetectionStrategy, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'trigger-gpt-full-text-modal',
    template: `

    <div [formGroup]="form">
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" *ngIf="true == true">
          <h5> {{ modalHeader }} </h5>

          <span class="table-span protocolSetting"> {{ modalContent }}</span>

        </div>
        <div class="modal-footer">
            <button class="btn btn-info" type="button" (click)="trigger()">Submit</button>
        </div>
        </div>
    `
})
export class ModalTriggerGptFullTextComponent {
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();
    modalHeader: string
    group: FormGroup
    modalContent: string;
    title: string;
    keyQuestions: []
    form: FormGroup;
    constructor(
        public activeModal: NgbActiveModal,
        ) {}
    ngOnInit() {
        this.form = new FormGroup({
        });
    }

    trigger() {
        const event = {
             type: 'askGptToVoteOnInclusion',
        };
        this.eventBus.emit(event);
    }
}