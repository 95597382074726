import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import * as dashboardPatientActions from '../actions/dashboard-patient.actions';

import * as fromService from '../../../../services';

@Injectable()
export class DashboardPatientEffects {
    constructor(
        private actions$: Actions,
        private PatientService: fromService.PatientService,
        private DashboardService: fromService.DashboardService
    ) {}

    @Effect()
    LoadDashboardPatientOptionsEfeect$ = this.actions$.pipe(
        ofType(dashboardPatientActions.DashboardPatientActionTypes.LoadDashboardPatientOptions),
        switchMap(() => {
            return this.PatientService.getAllPatient().pipe(
                map(response => new dashboardPatientActions.LoadDashboardPatientOptionsSuccess(response))
            );
        })
    );

    @Effect()
    LoadDashboardPatientProtocolsEfeect$ = this.actions$.pipe(
        ofType(dashboardPatientActions.DashboardPatientActionTypes.LoadDashboardPatientProtocols),
        switchMap(() => {
            return this.DashboardService.getAllProtocols().pipe(
                map(response => new dashboardPatientActions.LoadDashboardPatientProtocolsSuccess(response))
            );
        })
    );

    @Effect()
    addDashboardPatientToProtocol$ = this.actions$.pipe(
        ofType(dashboardPatientActions.DashboardPatientActionTypes.AddDashboardPatientToProtocol),
        switchMap(action => {
            const {
                payload: { patientId, protocolId }
            } = action as any;
            return this.PatientService.addPatientToProtocol(protocolId, patientId).pipe(
                map(() => new dashboardPatientActions.AddDashboardPatientToProtocolSuccess())
            );
        })
    );

    @Effect()
    addDashboardPatient$ = this.actions$.pipe(
        ofType(dashboardPatientActions.DashboardPatientActionTypes.AddDashboardPatient),
        switchMap(action => {
            const {
                payload: {
                    dashboardPatient: { protocol, population }
                }
            } = action as any;
            return this.PatientService.addPatient(protocol.id, population.name).pipe(
                map(() => new dashboardPatientActions.AddDashboardPatientSuccess())
            );
        })
    );

    @Effect()
    updateDashboardPatient$ = this.actions$.pipe(
        ofType(dashboardPatientActions.DashboardPatientActionTypes.UpdateDashboardPatient),
        switchMap(action => {
            const {
                payload: { dashboardPatient }
            } = action as any;
            return this.PatientService.updatePatient(dashboardPatient.id, dashboardPatient.name).pipe(
                map(() => new dashboardPatientActions.UpdateDashboardPatientSuccess({ dashboardPatient }))
            );
        })
    );

    @Effect()
    removeDashboardPatient$ = this.actions$.pipe(
        ofType(dashboardPatientActions.DashboardPatientActionTypes.DeleteDashboardPatient),
        switchMap(action => {
            const {
                payload: { id }
            } = action as any;
            return this.PatientService.removePatient(id).pipe(
                map(() => new dashboardPatientActions.DeleteDashboardPatientSuccess())
            );
        })
    );
}
