import { Component, Input, OnInit } from '@angular/core';
import { OwnerlistItem } from '../../models/protocol.model';

@Component({
    selector: 'alii-web-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
    @Input() ownerlist: OwnerlistItem[];
    @Input() small = false;
    @Input() avatar = true;
    @Input() showAsTag = false;
    @Input() showInLine: boolean;

    constructor() {}

    width: number;
    height: number;
    names: string;

    ngOnInit() {
        this.width = this.small ? 25 : 50;
        this.height = this.small ? 25 : 50;
        this.names = this.ownerlist ? this.ownerlist.map(owner => owner.name).join(', ') : '';
    }
}
