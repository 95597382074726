import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { Protocol } from '@alii-web/models/protocol.model';

import { DashboardPatient } from '@alii-web/models/dashboard-patient.model';
import {
    PatientAddModalComponent,
    PatientIncludeModalComponent,
    EditTitleModalComponent,
    RemoveDataModalComponent
} from '../../../dashboard/entry-components';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-dashboard-patients',
    templateUrl: './dashboard-patient.component.html',
    styleUrls: ['./dashboard-patient.component.scss']
})
export class DashboardPatientComponent implements OnInit {
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    patients: DashboardPatient[] = [];

    @Input()
    cases: any[] = [];

    @Input()
    protocols: Protocol[] = [];

    constructor(private modalService: NgbModal, private translate: TranslateService) {}

    ngOnInit() {}

    handleOpenAddPatient() {
        const modalRef = this.modalService.open(PatientAddModalComponent);
        modalRef.result.then(
            result => {
                const action = {
                    type: 'handleSubmitAddPatient',
                    payload: result
                };
                this.eventBus.emit(action);
            },
            () => {}
        );
        modalRef.componentInstance.protocols = this.protocols;
    }

    handleOpenIncludePatient() {
        const modalRef = this.modalService.open(PatientIncludeModalComponent);
        modalRef.result.then(
            result => {
                const action = {
                    type: 'handleIncludeAddPatient',
                    payload: result
                };
                this.eventBus.emit(action);
            },
            () => {}
        );
        modalRef.componentInstance.cases = this.cases;
        modalRef.componentInstance.protocols = this.protocols;
    }

    handleEditPatient(title) {
        const modalRef = this.modalService.open(EditTitleModalComponent);
        modalRef.result.then(
            result => {
                const action = {
                    type: 'handleEditPatient',
                    payload: result
                };
                this.eventBus.emit(action);
            },
            () => {}
        );
        modalRef.componentInstance.headerTitle = 'Change the name of an existing case';
        modalRef.componentInstance.titleValue = title;
        modalRef.componentInstance.titleLabel = 'Case';
    }

    handleOpenDelete(id) {
        const modalRef = this.modalService.open(RemoveDataModalComponent);
        modalRef.result.then(
            () => {
                const action = {
                    type: 'handleRemovePatient',
                    payload: {
                        patientId: id
                    }
                };
                this.eventBus.emit(action);
            },
            () => {}
        );
        forkJoin({
            title: this.translate.get('DASHBOARD.PATIENTS.DELETE.TITLE'),
            text: this.translate.get('DASHBOARD.PATIENTS.DELETE.TEXT')
        }).subscribe({
            next({ title, text }) {
                modalRef.componentInstance.headerTitle = title;
                modalRef.componentInstance.bodyText = text;
            }
        });
    }

    trackByFn = (index, item) => item.id || index;
}
