import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromStore from '../../store';

import { DashboardCategory } from '../../../../models/dashboard-category.model';
import * as fromService from '../../../../services';
import { DashboardExisting } from '../../../../models/dashboard-existing.model';
import { DashboardSummary } from '../../../../models/dashboard-summary.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-dashboard-edit',
    templateUrl: './dashboard-edit-container.component.html'
})
export class DashboardEditContainer implements OnInit {
    id: string;
    categories$: Observable<DashboardCategory[]> = this.store.select(fromStore.getDashboardCategories);
    allCategories$: Observable<DashboardSummary>;
    dashboard$: Observable<DashboardExisting> = this.store.select(fromStore.getSelectedExistingDashboard);

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<fromStore.DashboardState>,
        private dashboardService: fromService.DashboardService
    ) {}

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.id = params.get('id');
        });
        this.store.dispatch(new fromStore.LoadDashboardSummary());
        this.store.dispatch(new fromStore.SelectDashboardExisting({ dashboardId: this.id }));
        this.store.dispatch(new fromStore.LoadDashboardCategories(this.id));
        this.allCategories$ = this.dashboardService.getAllCategories();
    }

    handleEventBus(event) {
        const { type, payload } = event;
        switch (type) {
            case 'submit':
                this.handleSubmit(payload);
                break;
            case 'delete':
                this.handleDelete(payload);
                break;
            case 'handleSubmitCreateDashboardTag':
                this.handleSubmitCreateDashboardTag(payload);
                break;
            case 'handleSubmitAddDashboardTag':
                this.handleSubmitAddDashboardTag(payload);
                break;
            case 'handleRemoveDashboardTag':
                this.handleRemoveDashboardTag(payload);
                break;

            default:
                break;
        }
    }
    handleSubmit(event) {
        this.store.dispatch(new fromStore.UpdateDashboardExisting({ dashboardExisting: event }));
    }
    handleDelete(event) {
        const { id } = event;
        this.store.dispatch(new fromStore.DeleteDashboardExisting({ id }));
        this.router.navigate(['/dashboard/create']);
    }
    handleSubmitCreateDashboardTag(payload) {
        const { dashboardId, title } = payload;
        this.store.dispatch(new fromStore.CreateCategory({ dashboardId, title }));
    }
    handleSubmitAddDashboardTag(payload) {
        const { dashboardId, tagId } = payload;
        this.store.dispatch(new fromStore.AddDashboardCategory({ dashboardId, tagId }));
    }
    handleRemoveDashboardTag(payload) {
        const { dashboardId, tagId } = payload;
        this.store.dispatch(new fromStore.RemoveDashboardCategory({ dashboardId, tagId }));
    }
}
