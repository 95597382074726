import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromStore from './../../store';
import { ShopTemplateSet } from '../../../../models/shop-template-set.interface';
import { TemplateSetUpdateModalComponent } from '../../entry-components';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-shop-template-set-modal',
    templateUrl: './template-set.component.html',
    styleUrls: ['./template-set.component.scss']
})
export class TemplateSetComponent implements OnInit, OnDestroy {
    loading$: Observable<boolean>;
    templateSet$: Observable<ShopTemplateSet>;

    subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private router: Router,
        private store: Store<fromStore.ShopFeatureState>
    ) {}

    ngOnInit() {
        // Respond to changed params (id).
        this.subscriptions.push(
            this.route.params.subscribe(params => {
                if (params['id']) {
                    const id = params['id'];
                    this.store.dispatch(new fromStore.LoadShopTemplateSet(id));
                }
            })
        );

        // Respond to shop template set updates.
        this.subscriptions.push(
            this.store.select(fromStore.updateShopTemplateSet).subscribe(result => this._handleUpdate(result))
        );

        this.loading$ = this.store.select(fromStore.getShopTemplateSetLoading);
        this.templateSet$ = this.store.select(fromStore.getShopTemplateSet);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    submit(templateSet: ShopTemplateSet) {
        const modalRef = this.modalService.open(TemplateSetUpdateModalComponent);
        modalRef.componentInstance.templateSet = templateSet;
        modalRef.result.then(
            result => this.store.dispatch(new fromStore.UpdateShopTemplateSet(result)),
            () => {}
        );
    }

    trackByFn = (index, item) => item.id || index;

    private _handleUpdate(result) {
        if (result) {
            if (result.error) {
                this.toastr.error('', result.error || 'Cannot update template set');
            } else {
                if (result.message) {
                    this.toastr.success('', result.message || 'Successfully updated template set');
                }
                if (result.url) {
                    setTimeout(() => this.router.navigate([result.url]), 1000);
                }
            }
        }
    }
}
