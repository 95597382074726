import { createSelector } from '@ngrx/store';

import * as fromIndexReducers from '../reducers';
import * as fromReducer from '../reducers/protocol.reducer';

const getProtocolState = createSelector(
    fromIndexReducers.getLiteraturesFeatureState,
    (state: fromIndexReducers.LiteraturesFeatureState) => state.protocol
);
export const SelectProtocolId = createSelector(
    getProtocolState,
    fromReducer.SelectProtocolId
);
export const SelectProtocol = createSelector(
    getProtocolState,
    fromReducer.SelectProtocol
);
