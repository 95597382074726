import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'inclusion-criteria',
    template: `
        <div class="modal-header">
            <h4 class="modal-title">Inclusion criteria</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <span class="table-span protocolSetting" [innerHTML] = "inclusionCriteria"></span>
        </div>
    `
})
export class ModalInclusionCriteriaComponent {
    inclusionCriteria: string;
    constructor(public activeModal: NgbActiveModal) {
    }
}