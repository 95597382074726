import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnInit,
    Output,
    EventEmitter,
    Inject,
    OnDestroy,
    NgZone,
    AfterViewInit,
    ChangeDetectorRef,
    Renderer2
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import * as clipboard from 'clipboard-polyfill';
import FroalaEditor from 'froala-editor';

import { environment } from '../../../../../../../environments/environment';
import { AuthService } from '../../../../../../services';
import * as fromStore from '../../../../store';
import { Paragraph, Source } from '../../../../../../models/paragraph.interface';
import { InsertFootnoteModalComponent } from '../../../../entry-components/insert-footnote-modal/insert-footnote-modal.component';
import { toolbarButtons } from './toolbar-buttons';
import { TranslateService } from '@ngx-translate/core';

let self;

const cn = 'TextEditComponent';

const SHOW_IS_EDITABLE = 'show-is-editable';

// Special key codes for shortcuts
const KEY_CODE_F = 70;
const KEY_CODE_S = 83;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-text-edit',
    templateUrl: './text-edit.component.html',
    styleUrls: ['./text-edit.component.scss']
})
export class TextEditComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() parId: string; // Same as the ppdId (?)
    @Input() text: string;
    @Input() title: string;
    @Input() parentId: string;
    @Input() populationId?: string = null;

    @Input() isEditAble: boolean;
    @Input() isSummary: boolean;
    @Input() isIntermediateSummary: boolean;
    @Input() sources: Source[];
    @Input() linkedProtocol: string;
    @Input() showIsEditAble: boolean;

    @Output() eventBus: EventEmitter<any> = new EventEmitter<any>();
    @Output() updated = new EventEmitter<void>();

    saving: boolean;

    froalaContent: HTMLElement;

    authHash = '';
    placeholderTranslatedText = '';

    public options = {
        key: environment.froala.key,
        attribution: false,
        initOnClick: true,
        charCounterCount: false,
        pasteDeniedAttrs: ['class', 'style'],
        events: {
            initialized: (editor: { _editor: () => void }): void => {
                this.saving = false;
                if (editor) {
                    self.editor = editor._editor;
                    if (self.editor) {
                        self.editor.toolbar.hide();
                    } else {
                        console.warn(`${cn} event 'initialized' self.editor is undefined, editor = `, editor);
                    }
                } else {
                    console.warn(`${cn} event 'initialized' editor is undefined`);
                }
            },
            'paste.afterCleanup': html => {
                // Froala bugfix insert table from google sheets
                html = html.replace(';width:0px;', ';');
                const [stripped, num] = this._stripChars(html, 7, '<span class="stripped">¿</span>');
                return num ? stripped : html;
            },
            focus: () => {
                if (self.editor && self.editor.toolbar) {
                    self.editor.toolbar.hide();
                }
            },
            blur: () => {
                // self.editor.save();
            },
            'save.before': html => {
                // Manipulate the text here if needed.
                this.ngZone.run(() => {
                    // Display the saving spinner.
                    this.saving = true;
                    this.cdr.markForCheck();
                });
                return html;
            },
            'save.after': (e) => {
                this.ngZone.run(() => {
                    setTimeout(() => {
                        // Hide the saving spinner.
                        this.saving = false;
                        this.cdr.markForCheck();
                    }, 1000);
                });
            },
            'save.error': (error, response) => {
                console.warn(`error='${error}' response='${response}'`);
                this.saving = false;
            }
        },

        saveURL: environment.froala.saveURL + '?authhash=' + this.authService.getAuthHash(),
        saveMethod: 'POST',
        saveInterval: 2500,

        imageUploadParam: 'file',
        imageUploadURL: environment.froala.imageUploadURL,
        imageUploadMethod: 'POST',
        requestWithCORS: false,
        toolbarInline: true,

        // Only display the toolbar when a section of the text has been selected.
        toolbarVisibleWithoutSelection: false,

        toolbarButtons: toolbarButtons[1]
    };

    public titleOptions;
    public textOptions;

    subscriptions: Subscription[] = [];
    paragraphs: Paragraph[] = [];

    showIsEditAbleEnabled: boolean;

    listeners: Array<() => void> = [];

    constructor(
        @Inject(DOCUMENT) private document,
        private authService: AuthService,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private ngZone: NgZone,
        private cdr: ChangeDetectorRef,
        private store: Store<fromStore.ProtocolAction>,
        private translateService: TranslateService,
        private renderer: Renderer2
    ) {}

    ngOnInit() {
        self = this;

        this.showIsEditAbleEnabled = this.showIsEditAble;

        this.translateService.get('TYPESOMETHING').subscribe(translation => this.placeholderTranslatedText = translation);
        
        this.textOptions = {
            ...this.options,

            placeholderText: this.placeholderTranslatedText,
            // Set the save param.
            saveParam: 'text',
            saveParams: { ppdId: this.parId, app: 'web' },
            sources: () => this.sources,
            parId: this.parId,
            parentId: this.parentId
        };

        if (this.isSummary) {
            this.textOptions = {
                ...this.options,
                enter : 2, //enters to <br> not <p>. This way we can keep reports a lot more compact
                saveParams: { summaryId: this.parId, app: 'web', populationId: this.populationId },
                parId: this.parId,

                // Don't wait for focus to init froala in case of a summary. This prevents a bug where froala
                // doesn't initialize properly.
                initOnClick: false
            };
        }

        this.titleOptions = {
            ...this.options,
            saveParam: 'title',
            saveParams: { ppdId: this.parId, app: 'web' },
            toolbarButtons: [],
            htmlAllowedTags: [''],
            htmlRemoveTags: ['.*']
        };

        // Define the custom button for inserting footnotes.
        FroalaEditor.DefineIcon('insertFootnote', { NAME: 'asterisk', template: 'font_awesome' });
        FroalaEditor.RegisterCommand('insertFootnote', {
            title: 'Insert Footnote',
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback() {
                // Run the callback code in Angular zone
                self.ngZone.run(() => {
                    self._handleInsertFootnote(this, this.opts.sources(), this.opts.parId, this.opts.parentId);
                });
            }
        });

        // Define the custom button for remove styling.
        FroalaEditor.DefineIcon('removeStyling', { NAME: 'window-close', template: 'font_awesome' });
        FroalaEditor.RegisterCommand('removeStyling', {
            title: 'Remove styling',
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback() {
                // Run the callback code in Angular zone
                self.ngZone.run(() => {
                    self._handleRemoveStyling(this);
                });
            }
        });

        // Ctrl+Alt+F is the shortcut key for inserting footnote
        FroalaEditor.RegisterShortcut(KEY_CODE_F, 'insertFootnote', null, 'F', false, true);

        // Ctrl+Alt+S is the shortcut key for removing styling
        FroalaEditor.RegisterShortcut(KEY_CODE_S, 'removeStyling', null, 'S', false, true);
    }

    ngAfterViewInit() {
        if (this.showIsEditAble) {
            setTimeout(() => {
                this.froalaContent = document.getElementById('froala-content-' + this.parId);
                if (this.froalaContent) {
                    this.renderer.addClass(this.froalaContent, SHOW_IS_EDITABLE);
                    const froalaView = this.froalaContent.querySelector('.fr-wrapper > .fr-view');
                    if (froalaView) {
                        this.listeners.push(
                            this.renderer.listen(froalaView, 'focus', () => this._onFocusFroalaView(this))
                        );
                        this.listeners.push(
                            this.renderer.listen(froalaView, 'blur', () => this._onBlurFroalaView(this))
                        );
                    } else {
                        console.warn(`${cn} ngAfterViewInit() cannot find froala view element`);
                    }
                } else {
                    console.warn(`${cn} ngAfterViewInit() cannot find froala content element`);
                }
            }, 500);
        }
    }

    ngOnDestroy() {
        // Unsubscribe
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];

        // Unlisten
        this.listeners.forEach(listener => listener());
        this.listeners = [];
    }

    copyText(id: number, withFormatting = true): void {
        const str = this.document.getElementById('froala-content-' + id).innerHTML;
        const plain = this.document.getElementById('froala-content-' + id).innerText;
        const dt = new clipboard.DT();

        dt.setData('text/plain', plain);
        if (withFormatting) {
            dt.setData('text/html', str);
        }
        
        clipboard.write(dt);

        this.translateService.get('TEXT-EDIT.TEXT-COPIED').subscribe(message => this.toastr.success(message));
    }

    copyLink(id: string): void {
        const link = window.location.href.replace(/\?.*$/, '') + '#paragraph-' + id;
        const dt = new clipboard.DT();

        dt.setData('text/plain', link);
        clipboard.write(dt);

        this.translateService
            .get('TEXT-EDIT.LINK-COPIED')
            .subscribe(message => this.toastr.success(`${message}: "${link}"`));
    }

    updateParText(event: MouseEvent) {
        const target = (event.target || event.srcElement || event.currentTarget) as HTMLSpanElement;
        const response = target.getAttribute('data-response');
        const parentId = target.getAttribute('data-parentId');
        const paragraph = JSON.parse(response);
        this.store.dispatch(new fromStore.StoreUpdateParagraph({ parentId, paragraph }));
    }

    private _onFocusFroalaView(_self) {
        _self.renderer.removeClass(_self.froalaContent, SHOW_IS_EDITABLE);
        this.showIsEditAbleEnabled = false;
    }

    private _onBlurFroalaView(_self) {
        _self.renderer.addClass(_self.froalaContent, SHOW_IS_EDITABLE);
        this.showIsEditAbleEnabled = true;
        this.cdr.markForCheck();
    }

    private _handleInsertFootnote(editor, sources, parId, parentId) {
        if(sources) {this.initiateModel(editor, sources, parId, parentId)}
        else {
            const loadFindingsAction = {
                type: 'handleLoadEvidence',
                payload: {
                    protocolId: undefined,
                    ppdId: parId,
                    parentId: this.parentId
                }
            };
            this.eventBus.emit(loadFindingsAction);

            let modelOpened = false
            setInterval(() => { 
                if(this.sources && !modelOpened) {
                    modelOpened = true
                    this.initiateModel(editor, this.sources, parId, parentId)}
                }, 600)
            }
        }
    
    initiateModel(editor, sources, parId, parentId) {
        const modalRef = this.modalService.open(InsertFootnoteModalComponent, { size: 'lg' });
        modalRef.componentInstance.sources = sources;
        modalRef.componentInstance.parId = parId;
        modalRef.componentInstance.parentId = parentId;
        modalRef.result.then(
            result => {
                let span = '';
                if (result.type === 'reference') {
                    span = this._createSpan(result.value.articleId, 'article');
                } else if (result.type === 'link') {
                    span = this._createSpan(result.value.url, result.type);
                } else {
                    console.error(`${cn} _handleInsertFootnote unknown result.type='${result.type}'`);
                }
                editor.html.insert('&nbsp;' + span + '&nbsp;');
                editor.save.save();
            },
            () => {}
        );
        modalRef.componentInstance.eventBus.subscribe(event => {
            this.eventBus.emit(event)
        });

    }

    private _handleRemoveStyling(editor) {
        // Save previous html to undo buffer in case we want to restore.
        editor.undo.saveStep();
        editor.html.set(editor.html.get(true).replace(/ ?style=['"](.*?)['"]/gi, ''));
        editor.save.save();
    }

    private _createSpan(articleId: string, type: string) {
        // eslint-disable-next-line max-len
        return `<span id="" class="footnote" data-elementid="" data-articleid="${articleId}" data-type="${type}">[${type}|${articleId}]</span>`;
    }

    private _stripChars(text: string, max: number, replace: string): [string, number] {
        let cnt = 0;
        let result = '';
        const len = text.length;
        for (let i = 0; i < len; i++) {
            const charCode = text.charCodeAt(i);
            if (charCode <= max) {
                result += replace;
                cnt++;
            } else {
                result += String.fromCharCode(charCode);
            }
        }
        if (cnt) {
            this.toastr.warning(
                `Replaced ${cnt} non-ascii character${cnt === 1 ? '' : 's'} with a red upside-down question mark.`,
                'Warning'
            );
        }
        return [result, cnt];
    }
}
