import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class ArticleService {
    constructor(private http: HttpClient) {}

    getArticle(payload): Observable<any> {
        const { protocolId, articleId } = payload;
        const url = `${
            environment.baseUrl
        }/api/protocol/article/getArticle.vm?protocolId=${protocolId}&articleId=${articleId}`;
        return this.http.get<any>(url).pipe(
            map(article => article),
            catchError((error: any) => throwError(error))
        );
    }
    addReview(payload) {
        const url = `${environment.baseUrl}/api/protocol/article/saveReview.vm?app=web`;
        const { protocolId, ppdId, articleId, action, sourceId } = payload;
        const postData = {
            protocolId: protocolId,
            articleId: articleId,
            action: action,
            ppdId,
            sourceId: sourceId
        };

        return this.http.post<any>(url, postData).pipe(
            map(response => {
                const {
                    'message list': { paragraphs }
                } = response;

                return paragraphs[0];
            }),

            catchError((error: any) => throwError(error))
        );
    }
    removeReview(payload) {
        return this.addReview(payload);
    }
}
