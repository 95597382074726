import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { environment } from '../../../../../../environments/environment';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Protocol } from '../../../../../models/protocol.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'detail-form-settings',
    template: `
        <div class="modal-header">
            <h4 class="modal-title">Edit inclusion criteria</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="text-align: left;">

                <p></p>

                <div id="formTitle" class="cx_form">
                    <form>
                        <div class="form-text message">
                            Please enter the new inclusion criteria: <br />
                            <textarea [froalaEditor]="options" [(froalaModel)]="inclusionCriteria" class="form-control" cols="40" rows="1"></textarea>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" (click)="handleSubmit()">Submit</button>
            </div>
        </div>
    `
})
export class FormInclusionCriteriaComponent implements OnInit {
    @Input() protocol: Protocol;

    @Output() submit: EventEmitter<Protocol> = new EventEmitter<Protocol>();
    myForm: FormGroup;
    inclusionCriteria: string;
    public options = {
        key: environment.froala.key,
        attribution: false,
        heightMin: 120,
        quickInsertTags: [''],
        pastePlain: true,
        charCounterCount: false,
        toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'formatUL', 'formatOL', 'insertLink'],
        events: {
            // Froala bugfix insert table from google sheets
            'paste.afterCleanup': html => html.replace(';width:0px;', ';')
        }
    };

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}
    ngOnInit() {
        this.inclusionCriteria = this.protocol.inclusionCriteria
    }
    handleSubmit() {
        this.activeModal.close(this.inclusionCriteria);
    }
}
