import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'alii-web-support-form-modal',
    templateUrl: './support-form-modal.html',
    styleUrls: ['./support-form-modal.scss']
})
export class SupportFormModalComponent implements OnInit {
    title = 'Support formulier';

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit() {}
}
