import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as usersActions from '../actions/users.action';
import * as fromService from '../../../../services';

@Injectable()
export class UsersEffects {
    constructor(private actions$: Actions, private OrganisationsService: fromService.OrganisationService) {}

    @Effect()
    loadUsersEffect$ = this.actions$.pipe(
        ofType(usersActions.LOAD_USERS),
        switchMap(action => {
            return this.OrganisationsService.getUsers().pipe(
                map(users => new usersActions.LoadUsersSucces(users)),
                catchError(error => of(new usersActions.LoadUsersFail(error)))
            );
        })
    );
}
