import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Translation stuff, see: https://github.com/ngx-translate/core
import { TranslateModule } from '@ngx-translate/core';

import { reducers, effects } from './store';
import * as fromServices from '../../services';
import * as fromComponents from './components';
import * as fromContainers from './containers';
import * as fromEntryComponents from './entry-components';
import { PartialsModule } from '../../partials/partials.module';
import { ShopRoutingModule } from './shop-routing.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        ShopRoutingModule,
        StoreModule.forFeature('ShopFeature', reducers),

        EffectsModule.forFeature(effects),
        PartialsModule,
        TranslateModule.forChild({ extend: true })
    ],
    providers: [...fromServices.services],
    declarations: [...fromComponents.components, ...fromContainers.containers, ...fromEntryComponents.entryComponents],
    exports: [...fromContainers.containers, ...fromComponents.components]
})
export class ShopModule {}
