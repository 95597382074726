import { ElementRef, Renderer2 } from '@angular/core';

/**
 * Scroll highlight navigation links.
 * Capture scrolling of the window and highlight given navigation item based on changed page positions closest headings.
 *
 * @param {ElementRef} scrollRef
 * @param {Renderer2} renderer
 * @param {(paragraphId: number) => void} callback
 * @returns {() => void} unlistener
 *
 */
export const setupScrollNavigationLinks = (
    scrollRef: ElementRef,
    renderer: Renderer2,
    callback: (paragraphId: string) => void
): (() => void) => {
    interface ScrollParagraph {
        id: string;
        y: number;
        text: string;
    }

    // DX allow for difference between anchor point and actual heading position.
    const DX = 50;

    let paragraphs: ScrollParagraph[] = [];
    let paragraph: ScrollParagraph;
    let scrollListener = null;

    document.querySelectorAll('*[id^="heading-"]').forEach((el: HTMLElement) => {
        const id = el.id;
        const m = id.match(/heading-(.+)/);
        if (m) {
            paragraphs.push({
                id: m[1],
                y: el.offsetTop,
                text: el.innerText
            });
        }
    });

    if (paragraphs.length) {
        paragraphs = paragraphs.sort((a, b) => (a.y < b.y ? -1 : b.y < a.y ? 1 : 0));

        paragraph = paragraphs[0];
        callback(paragraph.id);

        scrollListener = renderer.listen(scrollRef.nativeElement, 'scroll', event => {
            const y = event.target.scrollTop;
            let found = paragraphs[0];
            paragraphs.forEach(p => {
                if (p.y <= y + DX) {
                    found = p;
                }
            });
            if (paragraph.id !== found.id) {
                paragraph = found;
                callback(paragraph.id);
            }
        });
    }

    return scrollListener;
};
