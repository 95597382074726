import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from '../../services';
import { AuthGuard } from './auth.guard';

import * as fromContainers from './containers';
import { AuthRoutingModule } from './auth-routing.module';

@NgModule({
    imports: [CommonModule, AuthRoutingModule],
    providers: [AuthService, AuthGuard],
    declarations: [fromContainers.CallbackComponent],
    exports: [fromContainers.CallbackComponent]
})
export class AuthModule {}
