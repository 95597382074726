import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { RemoveDataModalComponent } from '../../entry-components/remove-data-modal/remove-data-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UserService } from 'apps/web/src/app/services';

@Component({
    selector: 'user-list',
    templateUrl: './user-list.component.html'
})
export class UserListComponent {
    @Input() users: [];
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();
    values: any;
    teamId: string;

    constructor(    
        private modalService: NgbModal, 
        private router: Router,
        private userService: UserService
    ) {}

    onClickRemoveUser(user) {
        const modalRef = this.modalService.open(RemoveDataModalComponent, { size: 'lg' });
        modalRef.result.then(
            () => {
                this.triggerChangeEvent("removeUser", user.id);
            },
            reason => {}
        );
        modalRef.componentInstance.record = [
            { key: 'First Name', value: user.first_name },
            { key: 'Last Name', value: user.last_name },
            { key: 'Email', value: user.email }
        ];
        modalRef.componentInstance.headerTitle = 'Remove user';
        modalRef.componentInstance.bodyText = 'Are you sure you want to remove this user?';
    }

    canAssignAliiAdmin() {
        return this.userService.isAliiAdmin();
    }
    isAdmin(user) {
        let value = false
        user.roles.forEach(element => {
            if (element.title == 'Admin') {
                value = true
            } });
        return value
    }

    isAliiAdmin(user) {
        let value = false
        user.roles.forEach(element => {
            if (element.title == 'AliiAdmin') {
                value = true
            } });
        return value
    }

    isReviewer(user) {
        let value = false
        user.roles.forEach(element => {
            if (element.title == 'Review board') {
                value = true
            } });
        return value
    }

    isLiteratureReviewer(user) {
        let value = false
        user.roles.forEach(element => {
            if (element.title == 'Literature reviewer') {
                value = true
            } });
        return value
    }

    isLibraryAdmin(user) {
        let value = false
        user.roles.forEach(element => {
            if (element.title == 'Library Admin') {
                value = true
            } });
        return value
    }

    getRoles(user) {
        let roleList = []
        user.roles.forEach(element => {
            if (roleList.findIndex(role => role.title == element.title) < 0) {
                roleList.push(element)
            }         
        });
        return roleList
    }

    isStatistics(user) {
        let value = false
        user.roles.forEach(element => {
            if (element.title == 'Statistics') {
                value = true
            } });
        return value
    }

    isReviewReader(user) {
        let value = false
        user.roles.forEach(element => {
            if (element.title == 'Review Reader') {
                value = true
            } });
        return value
    }

    manageUser(user, event) {
        switch (event.target.value) {
            case 'onClickRemoveUser': this.onClickRemoveUser(user);
                break;
            case 'editUserDetails': this.editUserDetails(user.id);
                break;
            default:
                this.triggerChangeEvent(event.target.value, user.id)
                break;
    }
}

    triggerChangeEvent(e: string, userId: string){
        const event = {
            action: e,
            payload: {
                userId
            }
        };
        this.eventBus.emit(event);
    }

    editUserDetails(userId) {
        this.router.navigate(['/team', userId]);
    }
    trackByFn = (index, item) => item.id || index;
}
