import { Action } from '@ngrx/store';

import { Category } from '../../../../models/category.interface';

export const LOAD_PROTOCOL_CATEGORIES = '[Protocols] Load Protocol Categories ';
export const LOAD_PROTOCOL_CATEGORIES_FAIL = '[Protocols] Load Protocol Categories  Fail';
export const LOAD_PROTOCOL_CATEGORIES_SUCCESS = '[Protocols] Load Protocol Categories  Success';

export class LoadProtocolCategories implements Action {
    readonly type = LOAD_PROTOCOL_CATEGORIES;
}

export class LoadProtocolCategoriesFail implements Action {
    readonly type = LOAD_PROTOCOL_CATEGORIES_FAIL;
    constructor(public payload: any) {}
}

export class LoadProtocolCategoriesSucces implements Action {
    readonly type = LOAD_PROTOCOL_CATEGORIES_SUCCESS;
    constructor(public payload: Category[]) {}
}

export type ProtocolCategoriesAction =
    | LoadProtocolCategories
    | LoadProtocolCategoriesFail
    | LoadProtocolCategoriesSucces;
