import svgPanZoom from 'svg-pan-zoom';
import { ElementRef } from '@angular/core';

/**
 * This function initializes the SVG panning and zooming capabilities for a given svg element using the
 * svg-pan-zoom library (https://github.com/ariutta/svg-pan-zoom).
 *
 * The selector of the SVG element must be findable via the `.canvas > svg` selector from the host element
 * parameter.
 *
 * Optionally define the `controlIconsEnabled` parameter as true in order to show the control zoom-in, zook-out
 * and reset icons in the upper right-hand corner of the canvas.
 *
 * An interval timer is used to poll every 200 msecs or the presence of the SVG element until it is found or
 * a maximum time of 5 seconds is passed.
 *
 * @param {string} cn
 * @param {ElementRef} hostElement
 * @param {boolean} controlIconsEnabled
 * @returns {void}
 *
 */
export const initSvgPanZoom = (cn: string, hostElement: ElementRef, controlIconsEnabled = false): void => {
    const MAX = 5000;
    const TIMEOUT = 200;
    let msecs = 0;
    const selSvg = '.canvas > svg';
    const timerId = setInterval(() => {
        let elSvg;
        try {
            // Note that the call to querySelector() will throw an exception if it fails.
            elSvg = hostElement.nativeElement.querySelector(selSvg);
        } catch (e) {
        }
        if (elSvg) {
            try {
                // TODO: sanity check just in case an exception is generated by svgPanZoom, which
                // can happen if protocol switch view from flowchart to edit on fragment anchor id.
                svgPanZoom(selSvg, {
                    // See: https://github.com/ariutta/svg-pan-zoom#how-to-use
                    // viewportSelector: '.svg-pan-zoom_viewport',
                    // panEnabled: true,
                    // controlIconsEnabled: false,
                    controlIconsEnabled: false,
                    // zoomEnabled: true,
                    zoomEnabled: true,
                    // dblClickZoomEnabled: true,
                    dblClickZoomEnabled: false,
                    // mouseWheelZoomEnabled: true,
                    mouseWheelZoomEnabled: false,
                    // preventMouseEventsDefault: true,
                    // zoomScaleSensitivity: 0.2,
                    // minZoom: 0.5,
                    // maxZoom: 10,
                    // fit: true,
                    fit: true,
                    // contain: false,
                    // center: true,
                    center: true
                    // refreshRate: 'auto',
                    // beforeZoom: () => {},
                    // onZoom: () => {},
                    // beforePan: () => {},
                    // onPan: () => {},
                    // onUpdatedCTM: () => {},
                    // customEventsHandler: () => {},
                    // eventsListenerElement: null
                });
                if (controlIconsEnabled) {
                    // Move the zoom buttons to the top right, default bottom right.
                    let elControls;
                    const selControls = '#svg-pan-zoom-controls';
                    try {
                        // Note that a call to querySelector() will throw an exception if it fails.
                        elControls = elSvg.querySelector(selControls);
                    } catch (e) {
                    }
                    if (elControls) {
                        elControls.setAttribute(
                            'transform',
                            `translate(${elSvg.getAttribute('width') - 70} 6) scale(0.75)`
                        );
                    } else {
                        console.warn(`${cn} initSvgPanZoom() cannot find element with sel='${selControls}'`);
                    }
                }
            } catch (e) {
                console.warn(`${cn} initSvgPanZoom() cannot find element with sel='${selSvg}'`);
            }
            clearInterval(timerId);
        } else {
            msecs += TIMEOUT;
            if (msecs >= MAX) {
                console.warn(`${cn} initSvgPanZoom() cannot find element with sel='${selSvg}'`);
                clearInterval(timerId);
            }
        }
    }, TIMEOUT);
};
