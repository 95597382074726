import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { OrganisationService } from 'apps/web/src/app/services/organisation.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'usergroup',
    templateUrl: './usergroup.component.html',
    styleUrls: ['./usergroup.component.scss']
})
export class UserGroupComponent implements OnInit {
    @Input() users: [];
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    usergroups$: Observable<any>;

    constructor(private service: OrganisationService, private router: Router) {}

    ngOnInit() {
        this.usergroups$ = this.service.getUserGroups();
    }

    onCreateUserGroup(title: string) {
        this.service.createUserGroup(title).subscribe(response => {
            this.router.navigate(['/organization/groups/', response.usergroupId]);
        });
    }

    trackByFn = (index, item) => item.id || index;
}
