import * as fromProfile from '../actions/profile.action';

import { User } from '../../../../models/user.interface';

export interface ProfileState {
    data: User;
    loaded: boolean;
    loading: boolean;
    message: any;
}

export const initialState: ProfileState = {
    data: {},
    loaded: false,
    loading: false,
    message: {}
};

export function reducer(state = initialState, action: fromProfile.ProfileAction): ProfileState {
    switch (action.type) {
        case fromProfile.LOAD_PROFILE: {
            return { ...state, loading: true };
        }

        case fromProfile.LOAD_PROFILE_SUCCESS: {
            const data = action.payload;
            return { ...state, loading: false, loaded: true, data: action.payload };
        }

        case fromProfile.LOAD_PROFILE_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
        case fromProfile.UPDATE_PROFILE: {
            return { ...state, loading: true };
        }

        case fromProfile.UPDATE_PROFILE_SUCCESS: {
            const data = action.payload;
            return { ...state, loading: false, loaded: true, message: data.message };
        }

        case fromProfile.UPDATE_PROFILE_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
    }
    return state;
}

export const getProfile = (state: ProfileState) => state.data;
export const getProfileLoading = (state: ProfileState) => state.loading;
export const getProfileLoaded = (state: ProfileState) => state.loaded;
