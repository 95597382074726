import { Component, ChangeDetectionStrategy, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'trigger-gpt-abstract-modal',
    template: `
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
          <h5> {{ modalHeader }} </h5>
          <span class="table-span protocolSetting"> {{ modalContent }}</span>
        </div>
        <div class="modal-footer">
            <button class="btn btn-info" type="button" (click)="trigger()">Submit</button>
        </div>
    `
})
export class ModalTriggerGptAbstractComponent {
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();
    modalHeader: string
    modalContent: string;
    title: string;
    constructor(public activeModal: NgbActiveModal) {
    }

    trigger() {
        const event = { type: 'askGptToVoteOnInclusion' };
        this.eventBus.emit(event);
    }
}