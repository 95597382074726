import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ShopActions from '../actions/shop.action';
import * as fromService from '../../../../services/shop.service';

@Injectable()
export class ShopEffects {
    constructor(
        private actions$: Actions,
        private shopService: fromService.ShopService
    ) {
    }

    @Effect()
    loadShop$ = this.actions$.pipe(
        ofType(ShopActions.LOAD_SHOP),
        switchMap((action: any) => {
            return this.shopService.getShop(action.payload).pipe(
                map(shop => new ShopActions.LoadShopSucces(shop)),
                catchError(error => of(new ShopActions.LoadShopFail(error)))
            );
        })
    );
}
