import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-help-text-modal',
    templateUrl: './model-help-text.component.html',
    styleUrls: ['./model-help-text.component.scss']
})
export class ModelHelpTextModalComponent {
    bodyText: string;
    title: string;

    constructor(public activeModal: NgbActiveModal) {}
}
