<div class="modal-header">
    <h4 class="modal-title">Edit dashboard name</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-form [formGroup]="form">
        <div class="form-group">
            <label for="name">New dashboard name:</label> <input id="name" class="form-control" formControlName="name" />
        </div>

        <button class="btn btn-primary" type="submit" [disabled]="!form.valid" (click)="handleSubmit()">{{ 'SUBMIT' | translate }}</button>
    </ng-form>
</div>
