<div class="modal-header">
    <h5 class="modal-title" id="flowchart-start-label">{{title}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <ng-form [formGroup]="form">
        <div class="form-group">
            <label for="ppdId"></label>
            <select id="ppdId" class="form-control" formControlName="ppdId">
                <option value="new" selected>Create new starting point</option>
                <option *ngFor="let paragraph of paragraphs; trackBy: trackByFn" [value]="paragraph.id">
                    {{ paragraph['protocol_paragraph.title'] }}
                </option>
            </select>
        </div>
    </ng-form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="handleSubmit()">Submit</button>
</div>
