import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { environment } from '../../../environments/environment';

import { AuthService } from '../../services';

@Injectable()
export class AuthGuard implements CanLoad {
    constructor(private authService: AuthService, private router: Router) {}

    async canLoad(route: Route, segments: UrlSegment[]) {
        // if there is a ?client= in the URL go through the custom login-flow. 
        const customLogin = this.authService.isCustomLogin();
        if (customLogin) {
            await this.authService.customLogin(customLogin);
        }

        // logged in? continue
        if (this.authService.isLoggedIn() || segments[0].path === 'register') {
            return true;
        }

        // not logged in, but opening a protocol-page? see if there is an SSO-provider and
        // redirect there.
        if (segments[0].path === 'protocols' && segments.length > 1) {
            const protocolId = segments[1].path;
            if (!this.authService.isLoggedIn()) {
                this.authService.getProtocolSSOProvider(protocolId).subscribe(response => {
                    this.authService.redirectToSSOProvider(response);
                });        
            }
        } else {
            this.redirect(segments)
        }
    }
    public redirect(segments) {
        const { search } = window.location;
        const returnUrl =
            segments.reduce((path, currentSegment) => {
                return `${path}/${currentSegment.path}`;
            }, '') + search;

        this.router.navigate(['users', 'login'], { queryParams: { returnUrl } });  
        return false;
    }        
}


