<section class="page__section -no-bottom -transparent">
    <div class="filter-header sectionTitle -small-bottom">
        <div class="sectionTitle__title">
            {{itemName + '.MANAGE.TITLE' | translate }}
        </div>
    </div>
</section>

<section class="page__section">
    <div class="col-md-12">
        <h2>
            {{'TITLE' | translate}}
        </h2>
        <span *ngIf="loadingDashboard || loadingAllDashboards" class="spinner-grow spinner-grow-sm"></span>
        <form *ngIf="!(loadingDashboard || loadingAllDashboards)" [formGroup]="form"
              (submit)="onSubmit()">
            <div *ngIf="editMode !== 'create'" class="form-group pull-right">
                <div class="btn-group col-5" ngbDropdown>
                    <button id="selectCurrentDashboard" type="button"
                            class="button -light"
                            ngbDropdownToggle
                    >
                        <span
                            *ngIf="!currentDashboard?.dashboard_id">{{ itemName + '.MANAGE.SELECT' | translate }}</span>
                        <ng-container *ngIf="currentDashboard?.dashboard_id">
                            <span>{{currentDashboard?.dashboard_id}} | {{ currentDashboard?.dashboard_title }}</span>
                            <span
                                class="badge badge-pill badge-secondary float-none m-1 ml-3">{{ getNumberOfProtocols(currentDashboard) }}</span>
                        </ng-container>
                    </button>
                    <ul class="dropdown-menu right" ngbDropdownMenu>
                        <ng-container *ngxPermissionsOnly="['createDashboard']">
                            <li
                                (click)="onCreate()">
                                <a class="dropdown-item cursor-pointer">
                                    <span
                                        *ngIf="editMode !== 'create'">{{ itemName + '.MANAGE.SELECT-CREATE' | translate }}</span>
                                </a>
                            </li>
                            <li>
                                <div class="dropdown-divider"></div>
                            </li>
                        </ng-container>
                        <ng-container *ngFor="let dashboard of allDashboards; trackBy: trackByFn">
                            <li *ngIf="currentDashboard?.dashboard_id !== dashboard.id"
                                (click)="selectDashboard(dashboard.id)">
                                <a class="dropdown-item cursor-pointer">
                                    <span>{{dashboard.id}} | {{ dashboard.name }}</span>
                                    <span
                                        class="badge badge-pill badge-secondary">{{ dashboard.number_of_protocols }}</span>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
            <ng-container *ngIf="editMode !== 'list'">
                <div class="form-group col-md-6">
                    <input
                        placeholder="enter title"
                        id="titleUpdate"
                        type="text"
                        class="form-control"
                        formControlName="title"
                        [readonly]="!permissions['updateTitle']"
                        [size]="maxlength"
                        [maxlength]="maxlength"
                    />
                    <small id="titleUpdateHelp"
                           class="form-text text-muted">{{itemName + '.MANAGE.MAXLEN' | translate}} {{maxlength}}
                        .</small>
                </div>

                <h2>
                    {{'DASHBOARD.MANAGE.SETTINGS' | translate}}
                </h2>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-text message">
                                {{'DASHBOARD.MANAGE.IS_EXCLUSIVE_TO_TEAM' | translate}}: <br />
                                <select formControlName="isExclusiveToTeam" class="form-control">
                                    <option [ngValue]="true">{{'SETTINGS.TRUE' | translate}}</option>
                                    <option [ngValue]="false">{{'SETTINGS.FALSE' | translate}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <h2>
                    {{'CATEGORIES' | translate}}
                </h2>
                <p>
                    {{'DASHBOARD.MANAGE.EXPLAIN_EDIT_CATEGORIES' | translate}}
                </p>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6">
                            <span><label
                                for="selectCategories">{{itemName + '.MANAGE.SUBITEM-LIST.SELECTED' | translate}}</label>:</span>
                            <span
                                class="badge badge-pill badge-secondary">{{ selectedCategoryItems?.length }}</span>
                            <ng-container *ngxPermissionsOnly="['setPosition']">
                                <ng-container *ngIf="selectedIndex && selectedCategoryItems.length > 1">
                                    <button type="button" class="btn btn-sm btn-outline-secondary m-1"
                                            [disabled]="selectedIndex === 1"
                                            (click)="onUp()"><i class="fa fa-caret-up"></i></button>
                                    <button type="button" class="btn btn-sm btn-outline-secondary m-1"
                                            [disabled]="!selectedIndex || selectedIndex > selectedCategoryItems.length - 1"
                                            (click)="onDown()"><i class="fa fa-caret-down"></i></button>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col-md-6">
                            <span><label
                                for="selectAllCategories">{{itemName + '.MANAGE.SUBITEM-LIST.AVAILABLE' | translate}}</label>:</span>
                            <span
                                class="badge badge-pill badge-secondary">{{ availableCategoryItems?.length }}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <select
                                id="selectCategories"
                                class="form-control custom-select"
                                formControlName="categories"
                                [size]="sizeSelectCategories"
                            >
                                <option
                                    *ngFor="let category of selectedCategoryItems; trackBy: trackByFn"
                                    (dblclick)="dblclickSelectedCategories(category)"
                                    (click)="onSelectCategory(category)"
                                    [value]="category.id">{{category.position}} | {{category.name}} ({{category.id }})
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <select
                                id="selectAllCategories"
                                class="form-control custom-select"
                                [size]="sizeSelectAllCategories"
                            >
                                <option *ngFor="let category of availableCategoryItems; trackBy: trackByFn"
                                        (dblclick)="dblclickAvailableCategories(category)"
                                        (click)="onSelectAvailableCategory(category)"
                                        [value]="category.id">{{ category.id }} | {{category.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group" role="group">
                            <button
                                type="button"
                                class="btn btn-primary m-1"
                                *ngIf="['update', 'create'].includes(editMode)"
                                [ngbTooltip]="tooltip['manage']"
                                (click)="onManageCategories()"
                            >
                                {{ itemName + '.MANAGE.EDIT_CATEGORY' | translate }}
                            </button>
                            <ng-container *ngxPermissionsOnly="['createCategory']">
                                <button
                                    type="button"
                                    class="btn btn-outline-secondary m-1"
                                    *ngIf="['update', 'create'].includes(editMode)"
                                    [ngbTooltip]="tooltip['create']"
                                    (click)="onCreateCategory()"
                                >
                                    {{ 'DASHBOARD.MANAGE.CREATE_CATEGORY' | translate }}
                                </button>
                            </ng-container>
                        </div>
                    </div>

                </div>
                <hr/>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group" role="group">
                            <button
                                type="submit"
                                class="btn btn-primary m-1"
                                [ngbTooltip]="tooltip['update']"
                                [disabled]="form.invalid || loadingUpdate || loadingCreate"
                            >
                        <span *ngIf="loadingCreate || loadingUpdate" class="spinner-grow spinner-grow-sm"
                              role="status"></span>
                                {{ (editMode === 'create' ? 'CREATE' : 'UPDATE') | translate }}
                            </button>

                            <button
                                type="button"
                                class="btn btn-outline-secondary m-1"
                                [ngbTooltip]="tooltip['reset']"
                                (click)="onReset()"
                            >
                                {{ 'RESET' | translate }}
                            </button>

                            <button
                                type="button"
                                class="btn btn-outline-secondary m-1"
                                *ngIf="editMode === 'create'"
                                (click)="onCancel()"
                            >
                                {{ 'CANCEL' | translate }}
                            </button>

                            <ng-container *ngxPermissionsOnly="['remove']">
                                <button
                                    type="button"
                                    class="btn btn-danger m-1 ml-4 float-right"
                                    *ngIf="editMode === 'update'"
                                    [ngbTooltip]="tooltip['delete']"
                                    [disabled]="allDashboards?.length === 1 || loadingDelete"
                                    (click)="onDelete()"
                                >
                                        <span *ngIf="loadingDelete" class="spinner-grow spinner-grow-sm"
                                              role="status"></span>
                                    {{ 'DASHBOARD.MANAGE.DELETE_DASHBOARD' | translate }}
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="development">
                <div class="row mt-2">
                    {{ debugPermissions() }}
                </div>
            </ng-container>
        </form>
    </div>
</section>
