<section class="page__section -small-bottom">
    <h1>Beheer gebruikersgroep {{ (usergroup$ | async)?.title }}</h1>

    <form>
        <div class="form-group row">
            <div class="col-sm-8">
                <input
                    type="text"
                    placeholder="{{ (usergroup$ | async)?.title }}"
                    class="form-control"
                    style="border: 1px solid #ddd"
                    #updateName
                />
            </div>
            <button
                class="btn btn-info"
                style="height: 2.25rem"
                type="submit"
                (click)="onUpdateName(updateName.value)"
            >
                Naam aanpasen
            </button>
        </div>
    </form>

    <br />
    <h3>Voeg lid toe</h3>
    <form>
        Voer de naam van het lid in die u aan de groep wil toevoegen.
        <input
            type="text"
            placeholder="Zoek op naam van lid om toe te voegen"
            class="form-control search-field__input"
            style="border: 1px solid #ddd"
            #searchQuery
        />
        <button
            class="btn btn-info"
            style="margin-top: 5px"
            type="submit"
            (click)="onSearch(searchQuery.value)"
        >
            Zoeken
        </button>

        <ul style="margin-top: 15px;">
            <li
                *ngFor="let user of (searchresults$ | async); trackBy: trackByFn"
                (click)="addToGroup(user.id)"
                style="cursor: pointer; text-decoration: underline; line-height: 1.875em;"
            >
                {{ user.first_name }} {{ user.prefix }} {{ user.last_name }} &lt;{{
                user.email
                }}&gt; aan deze groep toevoegen.
            </li>
        </ul>
    </form>
    <br /><br />

    <h3>Overzicht leden: {{ (usergroup$ | async)?.title }}</h3>
    <table style="width: 90%;">
        <tr>
            <td style="width:70%"></td>
            <td></td>
        </tr>
        <tr *ngFor="let user of (users$ | async); trackBy: trackByFn">
            <td>
                {{ user.first_name }} {{ user.prefix }} {{ user.last_name }} &lt;{{
                user.email
                }}&gt;
            </td>
            <td>
                <button class="btn btn-info" type="submit" (click)="removeFromGroup(user.id)">
                    Verwijder uit groep
                </button>
            </td>
        </tr>
    </table>
</section>
