<form [formGroup]="addUserForm" (ngSubmit)="handleSubmit()" name="main" class="card_main">
    <h1 class="sectionTitle__title">Add User</h1>
    <div class="card col-sm-12">
        <br />
        <table class="table table-borderless">
            <tr>
                <td>First Name</td>
                <td>
                    <label for="first-name"></label>
                    <input id="first-name" type="text" formControlName="first_name" class="form-control"
                           name="first_name" />
                </td>
            </tr>
            <tr>
                <td>Prefix</td>
                <td>
                    <label for="prefix"></label>
                    <input id="prefix" type="text" formControlName="prefix" class="form-control" name="prefix" />
                </td>
            </tr>
            <tr>
                <td>Last Name</td>
                <td>
                    <label for="last-name"></label>
                    <input id="last-name" type="text" formControlName="last_name" class="form-control"
                           name="last_name" />
                </td>
            </tr>
            <tr>
                <td>E-mail</td>
                <td>
                    <label for="email"></label>
                    <input id="email" type="text" formControlName="email" class="form-control" name="email" />
                </td>
            </tr>
            <tr>
                <td>Hospital</td>
                <td>
                    <div *ngIf="loadingOrganisation">
                        <span class="spinner-grow spinner-grow-sm"></span>
                    </div>
                    <label for="hospital-id"></label>
                    <select *ngIf="!loadingOrganisation" formControlName="hospitalid" class="form-control"
                            id="hospital-id" name="hospitalid">
                        <option value="" disabled>-- Please select --</option>
                        <option
                            *ngFor="let hospital of organisation?.teams[0].participating_hospitals"
                            [value]="hospital.id"
                        >
                            {{ hospital.name }}
                        </option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>Team</td>
                <td>
                    <div *ngIf="loadingOrganisation">
                        <span class="spinner-grow spinner-grow-sm"></span>
                    </div>
                    <label for="hospital-id"></label>
                    <select *ngIf="!loadingOrganisation" formControlName="teamId" class="form-control"
                            id="teamId" name="teamId">
                        <option value="" disabled>-- Please select --</option>
                        <option
                            *ngFor="let team of organisation?.teams"
                            [value]="team.id"
                        >
                            {{ team.name }}
                        </option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>Default dashboard &nbsp;</td>
                <td>
                    <div *ngIf="loadingDashboards">
                        <span class="spinner-grow spinner-grow-sm"></span>
                    </div>
                    <label for="dashboard-name"></label>
                    <select id="dashboard-name" *ngIf="!loadingDashboards" formControlName="dashboard"
                            class="form-control"
                            name="dashboard">
                        <option value="" disabled>-- Please select --</option>
                        <option
                            *ngFor="let dashboard of dashboards"
                            [value]="dashboard.id"
                        >
                            {{ dashboard.name }}
                        </option>
                    </select>
                </td>
            </tr>

            <tr>
                <td>Give user administrator rights? &nbsp;</td>
                <td>
                    <label for="setAdmin"></label>
                    <select id="setAdmin" formControlName="setAdmin" class="form-control" name="setAdmin">
                        <option value="no" selected>No</option>
                        <option value="yes">Yes</option>
                    </select>
                </td>
            </tr>

        </table>

        <br />
        <div class="col-sm-4 pl-0 mb-4">
            <button type="button" (click)="handleSubmit()" class="btn btn-info" [disabled]="!addUserForm.valid">
                Save user
            </button>
        </div>
        <br />
    </div>
</form>
