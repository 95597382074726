<div class="dashboard">
    <div *ngIf="dashboardTitle" class="dashboard__title">{{dashboardTitle}}</div>
    <div class="row">
        <div class="col-9">
            <div *ngIf="messageTitle" class="message-title">{{messageTitle}}</div>
            <p *ngIf="messageBody" class="message-body">{{messageBody}}</p>
        </div>
        <div *ngIf="ownerExists" class="col-3">
            <div class="row">
                <div class="col">
                    <img *ngIf="ownerImageUrl" class="owner-image" [src]="ownerImageUrl"
                         alt="Owner image" />
                    <div *ngIf="!ownerImageUrl" class="owner-image"></div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p *ngIf="ownerName" class="owner-name">{{ ownerName }}</p>
                    <p *ngIf="ownerText" class="owner-text">{{ ownerText }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-9">
        </div>
        <div class="col-3">
            <button class="btn btn-info" (click)="getSupport()">{{ 'HELP.PROBLEM' | translate }}</button>
        </div>
    </div>
</div>
