<div [hidden]="!(config && config.length > 0)">
    <form [formGroup]="form" (submit)="handleSubmit($event)" style class="w-100 dynamic-form">
        <div *ngIf="wizardStyle">
            <div style="padding: 10px 2px; font-weight:bold">Step {{ currentStep }} / 3</div>

            <div id="step1" [ngStyle]="{ display: currentStep == 1 ? 'block' : 'none' }">
                <ng-container *ngFor="let field of config">
                    <div
                        *ngIf="field.page === 1"
                        dynamicField
                        [config]="field"
                        [group]="form"
                        class="review-form-field"
                    ></div>
                </ng-container>
            </div>

            <div id="step2" [ngStyle]="{ display: currentStep == 2 ? 'block' : 'none' }">
                <ng-container *ngFor="let field of config">
                    <div
                        *ngIf="field.page === 2"
                        dynamicField
                        [config]="field"
                        [group]="form"
                        class="review-form-field"
                    ></div>
                </ng-container>
            </div>

            <div id="step3" [ngStyle]="{ display: currentStep == 3 ? 'block' : 'none' }">
                <ng-container *ngFor="let field of config">
                    <div
                        *ngIf="field.page === 3 || field.page === 0"
                        dynamicField
                        [config]="field"
                        [group]="form"
                        class="review-form-field"
                    ></div>
                </ng-container>
            </div>

            <div *ngIf="config && config.length > 0">
                <button class="btn btn-info" (click)="previousStep()" *ngIf="currentStep > 1">Previous</button>
                <button class="btn btn-info" (click)="nextStep()" *ngIf="currentStep < stepCount" style="float:right">
                    Next
                </button>
            </div>
            <div class="clearfix"></div>
        </div>

        <div *ngIf="!wizardStyle">
            <div id="step1">
                <div
                    *ngFor="let field of (config | slice: 0:end)"
                    dynamicField
                    [config]="field"
                    [group]="form"
                    class="review-form-field"
                >
                </div>

            </div>

            <div class="clearfix"></div>
        </div>
    </form>
</div>
