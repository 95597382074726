import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-flowchart-start-modal',
    templateUrl: './flowchart-start.component.html'
})
export class FlowchartStartComponent implements OnInit {
    form: FormGroup;

    paragraphs: any[];

    title = 'Set starting point';

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            ppdId: ['new']
        });
    }

    handleSubmit() {
        if (this.form.valid) {
            const data = {
                ...this.form.value
            };

            this.activeModal.close(data);
        }
    }

    handleCancel() {
        this.activeModal.dismiss({});
    }

    trackByFn = (index, item) => item.id || index;
}
