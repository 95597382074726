<alii-web-spinner-loading *ngIf="termsGetLoading$ | async"></alii-web-spinner-loading>
<ng-container *ngIf="(termsGetError$ | async) as message">
    <div class="container mt-4">
        <p class="row">{{ 'ERROR' | translate }}: {{message}}</p>
    </div>
</ng-container>
<ng-container *ngIf="(termsGetLoaded$ | async) as termsGetRO">
    <section class="page__section -no-bottom -transparent">
        <div class="sectionTitle">
            <div class="sectionTitle__title">{{ 'TERMS.TITLE' | translate}}</div>
        </div>
    </section>
    <section class="page__section -small-bottom">
        <div #scroll class="auto-scroll" [innerHTML]="safeHtml(termsGetRO.terms)"></div>
        <div *ngIf="!termsGetRO.acceptedTerms" class="row">
            <form [formGroup]="termsForm" class="cx_form cx_registerForm" (ngSubmit)="submit()"
            >
                <div class="form-check m-3 mt-4">
                    <input
                        formControlName="acceptTerms"
                        type="checkbox"
                        class="form-check-input accept-terms"
                        id="accept-terms"
                        value="true"
                        checked=""
                    />
                    <label class="form-check-label" for="accept-terms">
                        {{ 'TERMS.CONFIRMATION' | translate }}.
                    </label>
                </div>
                <button
                    class="btn btn-lg btn-primary m-3"
                    type="submit"
                    [disabled]="!(termsForm.value.acceptTerms && readTerms)"
                >
                                        <span
                                            class="spinner-grow spinner-grow-sm"
                                            role="status"
                                            aria-hidden="true"
                                            *ngIf="(termsAcceptLoading$ | async)"
                                        ></span>
                    Submit
                </button>
            </form>
        </div>
        <div *ngIf="termsGetRO.acceptedTerms" class="row">
            <div class="row m-3">
                {{ 'TERMS.ACCEPTED-ON' | translate}}
                : {{ (termsGetLoaded$ | async)?.acceptedTermsTimeStamp | date:'medium' }}
            </div>
        </div>
        <div *ngIf="(termsAcceptLoaded$ | async) && showBanner">
            <ngb-alert class="banner" (close)="closeBanner()">
                {{ 'TERMS.THANKS' | translate }}.
            </ngb-alert>
        </div>
    </section>
</ng-container>



