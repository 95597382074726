import { Action } from '@ngrx/store';

export const MAP_SUMMARY_ROWS = '[Protocol-Summary] Map Summary Rows';

export class MapSummaryRows implements Action {
    readonly type = MAP_SUMMARY_ROWS;
    constructor(public payload: { rows: any[] }) {}
}

export const ADD_ROW = '[Protocols] Add Row';
export const ADD_ROW_FAIL = '[Protocols] Add Row Fail';
export const ADD_ROW_SUCCESS = '[Protocols] Add Row Success';

export class AddRow implements Action {
    readonly type = ADD_ROW;
    constructor(public payload: any) {}
}

export class AddRowFail implements Action {
    readonly type = ADD_ROW_FAIL;
    constructor(public payload: any) {}
}
export class AddRowSucces implements Action {
    readonly type = ADD_ROW_SUCCESS;
constructor(public payload: { parentId: string; findings: any }) { }
}

export const UPDATE_ROW = '[Protocols] Update Row';
export const UPDATE_ROW_FAIL = '[Protocols] Update Row Fail';
export const UPDATE_ROW_SUCCESS = '[Protocols] Update Row Success';

export class UpdateRow implements Action {
    readonly type = UPDATE_ROW;
    constructor(public payload: {
        protocolId: string;
        ppdId: string;
        parentId: string;
        options: {
            title?: string;
        };

    }) {}
}

export class UpdateRowFail implements Action {
    readonly type = UPDATE_ROW_FAIL;
    constructor(public payload: any) {}
}

export class UpdateRowSucces implements Action {
    readonly type = UPDATE_ROW_SUCCESS;
    constructor(public payload: { parentId: string;ppdId: string, finding: any }) {}
}

export const DELETE_ROW = '[Protocols] Delete Row';
export const DELETE_ROW_FAIL = '[Protocols] Delete Row Fail';
export const DELETE_ROW_SUCCESS = '[Protocols] Delete Row Success';

export class DeleteRow implements Action {
    readonly type = DELETE_ROW;
    constructor(
        public payload: {
            protocolId: string;
            ppdId: string;
            parentId: string;
        }
    ) {}
}

export class DeleteRowSucces implements Action {
    readonly type = DELETE_ROW_SUCCESS;
    constructor(
        public payload: {
            parentId: string;
            ppdId: string;
        }
    ) {}
}

export class DeleteRowFail implements Action {
    readonly type = DELETE_ROW_FAIL;
    constructor(public payload: any) {}
}

export type SummaryRowsAction =
    | MapSummaryRows
    | AddRow
    | AddRowFail
    | AddRowSucces
    | UpdateRow
    | UpdateRowFail
    | UpdateRowSucces
    | DeleteRow
    | DeleteRowFail
    | DeleteRowSucces;
