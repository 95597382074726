import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    HostListener,
    Input,
    OnInit,
    QueryList,
    Renderer2,
    ViewChildren
} from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { DashboardTeam } from '@alii-web/modules/starting-page/models';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-choose-team',
    templateUrl: './choose-team.component.html',
    styleUrls: ['./choose-team.component.scss']
})
export class ChooseTeamComponent implements OnInit, AfterViewInit {
    @Input() teamList: DashboardTeam;

   
    @HostListener('window:resize', ['$event']) handleResize() {
        //this._handleResize();
    }

    constructor( 
        private store: Store<fromStore.StartingPageFeatureState>,
        private renderer: Renderer2) {}

    ngOnInit() {}

    ngAfterViewInit() {
        //this._handleResize();
    }

    handleSelectDashboard(dashboardId) {
        const id = dashboardId.toString();
        this.store.dispatch(new fromStore.SetDashboard({ id }));
    }

    trackByFn = (index, item) => item.id || index;

    // todo handleResize
}
