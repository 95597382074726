import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromOrganization from './organization.reducer';
import * as fromUsers from './users.reducer';
import * as fromTeams from './teams.reducer';

export interface OrganisationsFeatureState {
    users: fromUsers.UsersState;
    teams: fromTeams.TeamsState;
    organization: fromOrganization.OrganizationState
}

export const reducers: ActionReducerMap<OrganisationsFeatureState> = {
    users: fromUsers.reducer,
    teams: fromTeams.reducer,
    organization: fromOrganization.reducer
};

export const getOrganisationFeatureState = createFeatureSelector<OrganisationsFeatureState>('organisationFeature');
