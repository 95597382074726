import { Action } from '@ngrx/store';

import { ShopTemplateSet } from '../../../../models/shop-template-set.interface';

export const LOAD_SHOP_TEMPLATE_SET = '[Shop] Load Template Set';
export const LOAD_SHOP_TEMPLATE_SET_FAIL = '[Shop] Load Template Set Fail';
export const LOAD_SHOP_TEMPLATE_SET_SUCCESS = '[Shop] Load Template Set Success';
export const UPDATE_SHOP_TEMPLATE_SET = '[Shop] Update Template Set';
export const UPDATE_SHOP_TEMPLATE_SET_FAIL = '[Shop] Update Template Set Fail';
export const UPDATE_SHOP_TEMPLATE_SET_SUCCESS = '[Shop] Update Template Set Success';

export class LoadShopTemplateSet implements Action {
    readonly type = LOAD_SHOP_TEMPLATE_SET;

    constructor(public payload: any) {
    }
}

export class LoadShopTemplateSetFail implements Action {
    readonly type = LOAD_SHOP_TEMPLATE_SET_FAIL;

    constructor(public payload: any) {
    }
}

export class LoadShopTemplateSetSuccess implements Action {
    readonly type = LOAD_SHOP_TEMPLATE_SET_SUCCESS;

    constructor(public payload: ShopTemplateSet) {
    }
}

export class UpdateShopTemplateSet implements Action {
    readonly type = UPDATE_SHOP_TEMPLATE_SET;

    constructor(public payload: { id: string; action: string; teamId: string }) {
    }
}

export class UpdateShopTemplateSetFail implements Action {
    readonly type = UPDATE_SHOP_TEMPLATE_SET_FAIL;

    constructor(public payload: any) {
    }
}

export class UpdateShopTemplateSetSuccess implements Action {
    readonly type = UPDATE_SHOP_TEMPLATE_SET_SUCCESS;

    constructor(public payload: any) {
    }
}

export type StoreTemplateSetAction =
    | LoadShopTemplateSet
    | LoadShopTemplateSetFail
    | LoadShopTemplateSetSuccess
    | UpdateShopTemplateSet
    | UpdateShopTemplateSetFail
    | UpdateShopTemplateSetSuccess;
