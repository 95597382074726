import * as fromTeams from '../actions/teams.action';

import { Team } from '../../../../models/team.interface';

export interface TeamsState {
    data: Team[];
    loaded: boolean;
    loading: boolean;
}

export const initialState: TeamsState = {
    data: [],
    loaded: false,
    loading: false
};

export function reducer(state = initialState, action: fromTeams.TeamsAction): TeamsState {
    switch (action.type) {
        case fromTeams.LOAD_TEAMS: {
            return { ...state, loading: true };
        }

        case fromTeams.LOAD_TEAMS_SUCCESS: {
            const data = action.payload;
            return { ...state, loading: false, loaded: true, data: action.payload };
        }

        case fromTeams.LOAD_TEAMS_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
    }
    return state;
}

export const getTeams = (state: TeamsState) => state.data;
export const getTeamsLoading = (state: TeamsState) => state.loading;
export const getTeamsLoaded = (state: TeamsState) => state.loaded;
