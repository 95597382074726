import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { APP_BASE_HREF, DecimalPipe } from '@angular/common';
import { HttpClientModule, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { AgGridModule } from 'ag-grid-angular';

// NgRx
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools'; // not used in production

// Ngx
import { SortablejsModule } from 'ngx-sortablejs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ExportAsModule } from 'ngx-export-as';
import { ToastrModule } from 'ngx-toastr';

// Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Froala editor, see: https://froala.com/wysiwyg-editor/docs/framework-plugins/angularjs-2-4/
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/froala_editor.min.js';

// Translation stuff, see: https://github.com/ngx-translate/core
import {
    TranslateModule,
    TranslateLoader,
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
    TranslateService
} from '@ngx-translate/core';

import { en, nl } from './translation';

export class TranslateLoaderClass implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        return of(lang === 'nl' ? nl : lang === 'en' ? en : {});
    }
}
let translateLoaded = false;

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        if (translateLoaded) {
            console.warn(`Cannot find translation key='${params.key}`);
        }
        return '';
    }
}

// Google analytics, see: https://github.com/maxandriani/ngx-google-analytics
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { AuthInterceptor } from './shared/auth.interceptor';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { reducers, CustomSerializer } from './store';

import { AuthModule } from './modules/auth/auth.module';

import * as fromComponents from './components';

import { environment } from '@environments/environment';

import { PartialsModule } from './partials/partials.module';

import * as fromHelpers from './shared/helpers';

// Services
import { AuthService, ThemingService, BrowserService, LanguageService, NavigationService, PubSubModule } from './services';

// Block UI Spinner, see: https://github.com/kuuurt13/ng-block-ui
import { BlockUiSpinnerInterceptor } from './components/block-ui/block-ui-spinner.interceptor';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUiTemplateComponent } from './components/block-ui/block-ui-template.component';
import { BlockUiSpinnerComponent } from './components/block-ui/block-ui-spinner.component';

// Search
import { SearchModule } from './modules/search/search.module';

// Sentry
import * as Sentry from '@sentry/browser';

if (environment.production) {
    Sentry.init({
        dsn: 'https://0a5d971850b94936aa698e34c11b69e6@o377148.ingest.sentry.io/5198849',
        // prevent double error logging for failed xhr: https://github.com/getsentry/sentry-javascript/issues/2292
        ignoreErrors: ['Non-Error exception captured'],
        environment: environment.name,
        integrations: [
            new Sentry.Integrations.TryCatch({
                XMLHttpRequest: false
            })
        ]
    });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {}

    extractError(error) {
        // Try to unwrap zone.js error.
        // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
        if (error && error.ngOriginalError) {
            error = error.ngOriginalError;
        }

        // We can handle messages and Error objects directly.
        if (typeof error === 'string' || error instanceof Error) {
            return error;
        }

        // If it's http module error, extract as much information from it as we can.
        if (error instanceof HttpErrorResponse) {
            // The `error` property of http exception can be either an `Error` object, which we can use directly...
            if (error.error instanceof Error) {
                return error.error;
            }

            // ... or an`ErrorEvent`, which can provide us with the message but no stack...
            if (error.error instanceof ErrorEvent) {
                return error.error.message;
            }

            // ...or the request body itself, which we can use as a message instead.
            if (typeof error.error === 'string') {
                return `Server returned code ${error.status} with body "${error.error}"`;
            }

            // If we don't have any detailed information, fallback to the request message itself.
            return error.message;
        }

        // Skip if there's no error, and let user decide what to do with it.
        return null;
    }

    handleError(error) {
        const extractedError = this.extractError(error) || 'Handled unknown error';

        // Capture handled exception and send it to Sentry.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const eventId = Sentry.captureException(extractedError);

        // When in development mode, log the error to console for immediate feedback.
        if (!environment.production) {
            console.error(error);
        }

        // cache busting. todo: this could be improved
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            caches.keys().then(async function (names) {
                const cach_cleared = await Promise.all(names.map(name => caches.delete(name)));
                if (cach_cleared) {
                    window.location.reload();
                }
            });
        }
    }
}

const appProviders = [
    {
        provide: RouterStateSerializer,
        useClass: CustomSerializer
    },
    {
        provide: APP_BASE_HREF,
        useValue: ''
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: BlockUiSpinnerInterceptor,
        multi: true
    },
    AuthService,
    BrowserService,
    DecimalPipe,
    LanguageService,
    ThemingService,
    NavigationService,
    ...fromHelpers.helpers
];

if (environment.production) {
    appProviders.unshift({ provide: ErrorHandler, useClass: SentryErrorHandler });
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true
                // TODO
                // strictStateSerializability: true,
                // strictActionSerializability: true,
                // strictActionWithinNgZone: true,
                // strictActionTypeUniqueness: true
            }
        }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot(),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],
        AppRoutingModule,
        NgxPermissionsModule.forRoot(),
        AgGridModule.withComponents([]),

        PubSubModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 2000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),

        PartialsModule,
        SearchModule,
        AuthModule,
        TypeaheadModule.forRoot(),
        SortablejsModule.forRoot({
            animation: 200
        }),
        BlockUIModule.forRoot({
            delayStart: 1000,
            delayStop: 1000,
            template: BlockUiTemplateComponent
        }),
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        ExportAsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLoaderClass
                // useFactory: HttpLoaderFactory,
                // deps: [HttpClient]
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler }
        }),
        NgxGoogleAnalyticsModule.forRoot(environment.ga),
        NgxGoogleAnalyticsRouterModule,
        NgbModule
    ],
    providers: appProviders,
    declarations: [AppComponent, BlockUiSpinnerComponent, BlockUiTemplateComponent, ...fromComponents.components],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private translateService: TranslateService) {
        this.translateService.onLangChange.pipe(take(1)).subscribe(() => (translateLoaded = true));
    }
}
