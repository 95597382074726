import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProtocolsService } from '../../../../../services';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-user-feedback-modal',
    templateUrl: './user-feedback-modal.component.html',
    styleUrls: ['./user-feedback-modal.component.scss']
})
export class UserFeedbackModalComponent implements OnInit {
    description: string;
    titleLabel: string;
    protocolId: string;
    feedbackSent = false;

    form: FormGroup;

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private ProtocolService: ProtocolsService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            description: ['', Validators.required],
            name: [''],
            email: [''],
            protocolId: this.protocolId,
            protocol: [window.location.href],
            browser: navigator.userAgent
        });
    }

    handleSubmit() {
        if (this.form.valid) {
            const data = {
                ...this.form.value
            };

            this.feedbackSent = true;
            this.ProtocolService.submitUserFeedback(data).subscribe(() => {
                setTimeout(() => this.activeModal.close(data), 2000);
            });
        }
    }
}
