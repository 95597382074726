<div class="page__section paragraph-detail py-0" 
    [ngClass]="+paragraph.level === 1 ? '-small-bottom-padding' : '-tiny-bottom-padding'">
    <a [id]="paragraph.anchorId" [name]="paragraph.anchorId"></a>
    <div class="pull-right">
        <alii-web-paragraph-status-label
            (eventBus)="handleEventBus($event)"
            [paragraph]="paragraph"
        >
        </alii-web-paragraph-status-label>
    </div>
    <h1
        *ngIf="+paragraph.level === 1"
        [id]="'heading-' + paragraph.id"
        class="-no-margin"
        [innerHTML]="getTitle()"
    ></h1>
    <h3
        *ngIf="+paragraph.level === 3"
        [id]="'heading-' + paragraph.id"
        [innerHTML]="getTitle()"
    ></h3>

    <h5
        *ngIf="+paragraph.level === 5 && !paragraph.quality"
        [id]="'heading-' + paragraph.id"
        [innerHTML]="getTitle()"
    ></h5>

    <h6
        *ngIf="+paragraph.level === 6 && !paragraph.quality"
        [id]="'heading-' + paragraph.id"
        [innerHTML]="getTitle()"
    ></h6>

    <div class="row" *ngIf="!!paragraph.quality">
        <div class="col-sm-10" >
            <h5
                *ngIf="+paragraph.level === 5"
                [id]="'heading-' + paragraph.id"
                [innerHTML]="getTitle()"
            ></h5>

            <h6
                *ngIf="+paragraph.level === 6"
                [id]="'heading-' + paragraph.id"
                [innerHTML]="getTitle()"
            ></h6>
        </div>

        <div class="col-sm-2" style="padding: 0">
            <div class="m-2">
                <span class="tag" *ngIf="paragraph.quality == 1">{{'PARAGRAPH.EVIDENCE.VERYLOWQUALITY'|translate}}</span>
                <span class="tag" *ngIf="paragraph.quality == 2">{{'PARAGRAPH.EVIDENCE.LOWQUALITY'|translate}}</span>
                <span class="tag" *ngIf="paragraph.quality == 3">{{'PARAGRAPH.EVIDENCE.MODERATEQUALITY'|translate}}</span>
                <span class="tag" *ngIf="paragraph.quality == 4">{{'PARAGRAPH.EVIDENCE.HIGHQUALITY'|translate}}</span>
                <span class="tag" *ngIf="!isScore(paragraph.quality)">{{paragraph.quality}}</span>
            </div>
        </div>
    </div>

    <div class="clearfix">
        <alii-web-text-detail
            [paragraph]="paragraph"
            [parId]="paragraph.id"
            [isEditAble]="isEditAble"
            [title]="paragraph['protocol_paragraph.title']"
            [text]="paragraph['protocol_paragraph.text']"
            [expanded_text]="paragraph['protocol_paragraph.expanded_text']"
            [linkedProtocol]="paragraph['linked_protocol']"
        >
        </alii-web-text-detail>


        <alii-web-text-edit
            [text]="paragraph.summary"
            [populationId]="populationId"
            [isSummary]="true"
            [parId]="paragraph.id"
            [isEditAble]="true"
            [title]=""
            (updated)="textUpdated()"
            *ngIf="paragraph.summary && paragraph.level <= 3"
            [showIsEditAble]="paragraph.is_summary"
        >
        </alii-web-text-edit>

        <alii-web-text-edit
            [text]="paragraph.summary"
            [populationId]="populationId"
            [isIntermediateSummary]="true"
            [isSummary]="true"
            [parId]="paragraph.id"
            [isEditAble]="true"
            [title]=""
            (updated)="textUpdated()"
            *ngIf="paragraph.summary && paragraph.level > 3"
            [showIsEditAble]="paragraph.is_summary"
        >
        </alii-web-text-edit>


        <alii-web-model-detail
            *ngIf="paragraph.is_model"
            (eventBus)="handleEventBus($event)"
            [findings]="modelFindings[paragraph.id]"
            [outcomes]="modelOutcomes[paragraph.id]"
            [tagList]="modelTagList[paragraph.id]"
            [modelSource]="paragraph.model_source"
            [viewByPopulation]="viewByPopulation"
            [populations]="populations[paragraph.id]"
            [paragraphId]="paragraph.id"
            [populationId]="populationId"
            [paragraph]="paragraph"
            [parentId]="parentId"
            [sidebarCollapsed]="sidebarCollapsed"
        >
        </alii-web-model-detail>

        <summary-detail *ngIf="paragraph.is_summary" [rows]="paragraph.rows"></summary-detail>

    <alii-web-paragraph-recommendation-table
            *ngIf="paragraph.type === 'recommendation' 
                    && paragraph.recommendationList 
                    && paragraph.recommendationList.rowData"
            [columnDefs]="paragraph.recommendationList.columnDefs"
            [rowData]="paragraph.recommendationList.rowData"
            [index]="index"
            [hasNumberedIndex]="hasNumberedIndex"
            (eventBus)="handleEventBus($event)"
            [parentId]="paragraphId"
            [protocolId]="protocolId"
            [defaultColDef]="defaultColDef"
        >
        </alii-web-paragraph-recommendation-table>

        <ng-container
            *ngIf="(paragraph.files && paragraph.files.length) || (paragraph.sourceInfo.count && paragraph.type != 'keyQuestion')">
                <alii-web-paragraph-footer
                    (eventBus)="handleEventBus($event)"
                    [isEditAble]="isEditAble"
                    [paragraphId]="paragraph.id"
                    [paragraph]="paragraph"
                    [keyQuestionId]="paragraph.keyQuestionId"
                    [comments]="paragraph.comments"
                    [files]="paragraph.files"
                    [version]=version
                    [sources]="paragraph.sources"
                    [sourceInfo]="paragraph.sourceInfo"
                    [parentId]="parentId"
                    [populations]="paragraph.populations"
                    [sourceTable]="getSourceTable(paragraph)"
                    [gradeAssessmentPar]="getGradeAssessmentPar(paragraph.id)"
                    [selectedFootnote]="selectedFootnote$|async"
                >
                </alii-web-paragraph-footer>
                <div class="button-group -borderless -toolbar"></div>
        </ng-container>

        <div id="showNewFile-{{ paragraph.id }}"></div>

        <hr class="page__divider paragraphContent mt-2 mb-4"
            *ngIf="paragraph.children?.length && paragraph.level == 1" />

        <ng-container *ngIf="paragraph.children">
            <ng-container *ngFor="let child of getChildren(paragraph, 'main'); index as i trackBy: trackByFn">
                <alii-web-paragraph-detail
                    (eventBus)="handleEventBus($event)"
                    [protocolId]="protocolId"
                    [parentId]="''+paragraph.id"
                    [paragraph]="child"
                    [version]=version
                    [isEditAble]="isEditAble"
                    [index]="i"
                    [parentIndex]="index"
                    [paragraphSelectedId]="paragraphSelectedId"
                    [modelMessages]="modelMessages"
                    [modelFindings]="modelFindings"
                    [modelOutcomes]="modelOutcomes"
                    [modelTagList]="modelTagList"
                    [sourceTable]="getSourceTable(child)"
                    [gradeAssessment]="gradeAssessment"
                    [viewByPopulation]="viewByPopulation"
                    [populations]="populations"
                    [populationId]="populationId"
                    [sidebarCollapsed]="sidebarCollapsed"
                ></alii-web-paragraph-detail>
            </ng-container>
            <ng-container *ngIf="paragraph.type === 'keyQuestion'">
                <ng-container *ngFor="let type of getKeyQuestionSubTypes(); index as subTypeIndex; trackBy: trackByFn">
                    <h4 [id]="'heading-' + type.type + '-' + paragraph.id" *ngIf="hasNumberedIndex && getChildren(paragraph, type.type).length">
                        {{index + 1}}.{{subTypeIndex + 1}} {{type.title}}</h4>
                    <h4 [id]="'heading-' + type.type + '-' + paragraph.id" *ngIf="!hasNumberedIndex && getChildren(paragraph, type.type).length">
                        {{type.title}}</h4>
                    <div
                        *ngFor="let listChild of getChildren(paragraph, type.type); index as i; trackBy: trackByFn"
                        data-id="{{ listChild.id }}"
                        [attr.parentId]="paragraph.id"
                    >
                        <alii-web-paragraph-detail
                            (eventBus)="handleEventBus($event)"
                            [protocolId]="protocolId"
                            [parentId]="''+paragraph.id"
                            [paragraph]="listChild"
                            [version]=version
                            [isEditAble]="isEditAble"
                            [paragraphSelectedId]="paragraphSelectedId"
                            [index]="i"
                            [parentIndex]="index"
                            [keyQuestionTypeIndex]="subTypeIndex"
                            [modelMessages]="modelMessages"
                            [modelFindings]="modelFindings"
                            [modelOutcomes]="modelOutcomes"
                            [modelTagList]="modelTagList"
                            [sourceTable]="getSourceTable(listChild)"
                            [gradeAssessment]="gradeAssessment"
                            [viewByPopulation]="viewByPopulation"
                            [populations]="populations"
                            [populationId]="populationId"
                            [sidebarCollapsed]="sidebarCollapsed"
                            [hasNumberedIndex]="hasNumberedIndex"
                        ></alii-web-paragraph-detail>
                    </div>
                    <ng-container *ngIf="type.type === 'recommendation' 
                                            && paragraph.recommendationList 
                                            && paragraph.recommendationList.rowData">
                        <h4 [id]="'heading-' + type.type + '-' + paragraph.id" *ngIf="hasNumberedIndex">
                            {{index + 1}}.{{subTypeIndex + 1}} {{type.title}}</h4>
                        <h4 [id]="'heading-' + type.type + '-' + paragraph.id" *ngIf="!hasNumberedIndex">
                            {{type.title}}</h4>
                    </ng-container>

                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
