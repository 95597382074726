import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthService } from '../../../services';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromStore from '../store';
import { SSO } from '../../../models/sso.interface';
import { environment } from '@environments/environment';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loginForm: FormGroup;
    public ssoLoginForm: FormGroup;

    sso$: Observable<SSO[]>;
    loginFormLoading$: Observable<boolean>;
    loginFormIsError$: Observable<boolean>;
    loginFormMessage$: Observable<string>;
    loading: boolean;
    returnUrl: string;

    showRegisterLink: boolean;
    forgotPasswordUrl: string;

    constructor(
        private store: Store<fromStore.LoginFeatureState>,
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

        this.loading = true;
        this.validateAuthHash();
        this.checkIsLogin();

        this.sso$ = this.store.select(fromStore.getSSOOptions);
        this.loginFormLoading$ = this.store.select(fromStore.getLoginFormLoading);
        this.loginFormIsError$ = this.store.select(fromStore.getLoginFormIsError);
        this.loginFormMessage$ = this.store.select(fromStore.getLoginFormMessage);

        this.store.dispatch(new fromStore.LoadSSO());

        this.buildForms();

        this.showRegisterLink = !environment.production;
        this.forgotPasswordUrl = this._getForgotPasswordUrl(environment.name);
    }

    _getForgotPasswordUrl(env: string) {
        switch (env) {
            case "development-new": 
                return "https://localhost:5001/Identity/Account/ForgotPassword";
            case "development": 
                return "https://testing-api.alii.care/Identity/Account/ForgotPassword";
            case "acceptance": 
                return "https://acceptance-api.alii.care/Identity/Account/ForgotPassword";
            default:
                return "https://api.alii.care/Identity/Account/ForgotPassword";
        }
    }

    validateAuthHash() {
        if (this.authService.isLoggedIn()) {
            this.authService.validateAuthHash().subscribe(isValid => {
                if (!isValid) {
                    this.authService.logout();
                }
            });
        }
    }

    checkIsLogin() {
        if (this.authService.isLoggedIn()) {
            this.router.navigateByUrl(this.returnUrl);
        } else {
            this.loading = false;
        }
    }

    buildForms() {
        this.loginForm = this.fb.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });

        this.ssoLoginForm = this.fb.group({
            loginUrl: ['', [Validators.required]]
        });
    }

    handleSubmit() {
        if (!this.loginForm.valid) {
            alert('Please fill all fields');
            return;
        }

        const { username, password } = this.loginForm.value;

        this.store.dispatch(new fromStore.Login({ username, password, returnUrl: this.returnUrl }));
    }

    handleSubmitSSO() {
        const url = this.ssoLoginForm.value.loginUrl;
        if (url.indexOf("https") !== -1) {
            window.location.href = this.ssoLoginForm.value.loginUrl;
        }
        else {
            window.location.href = environment.baseUrl + this.ssoLoginForm.value.loginUrl;
        }
        
    }

    register(event: MouseEvent) {
        this.router.navigate(['/register']);
        event.stopPropagation();
        return false;
    }

    selectSSO(event) {
        console.log(event.target.value);
    }

    trackByFn = (index, item) => item.id || index;
}
