import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'reject-modal',
    template: `
        <div class="modal-header">
            <h4 class="modal-title">{{ headerTitle }}</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ng-form [formGroup]="commentAddForm">
                <div class="form-group">
                    <label>{{'INFOBOX.ACTIONS.REJECT_REASON' | translate}}:</label> 
                    <textarea class="form-control" formControlName="message"></textarea>
                </div>
                <button class="btn btn-primary" type="submit" (click)="handleSubmit()">Submit</button>
            </ng-form>
        </div>
    `
})
export class RejectModalComponent implements OnInit {
    commentAddForm: FormGroup;
    comment?: any;
    headerTitle: string;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.headerTitle = this.comment ? 'Reply comment' : 'Add Comment';
        this.commentAddForm = this.fb.group({
            message: ['', Validators.required]
        });
    }
    handleSubmit() {
        if (this.commentAddForm.valid) {
            const data = {
                ...this.commentAddForm.value,
                ...(this.comment ? { comment: { ...this.comment } } : {})
            };
            this.activeModal.close(data);
        }
    }
}
