import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'remove-data-modal',
    template: `
        <div class="modal-header">
            <h4 class="modal-title" [innerHTML]="headerTitle"></h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group" [innerHTML]="bodyText"></div>
            <form>
                <div class="form-group row" *ngFor="let item of record">
                    <label [for]="item.key" class="col-sm-2 col-form-label text-titlecase">{{ item.key }}</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" [id]="item.key" [value]="item.value" disabled />
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <ng-form [formGroup]="form">
                <button class="btn btn-info" type="button" (click)="onClickSubmit()">Submit</button>
                <button class="btn btn-default" type="button" (click)="onClickCancel()">Cancel</button>
            </ng-form>
        </div>
    `
})
export class RemoveDataModalComponent implements OnInit {
    headerTitle: string;
    bodyText: string;
    record: {}[];

    form: FormGroup;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({});
    }
    onClickSubmit() {
        if (this.form.valid) {
            const data = {
                ...this.form.value
            };
            this.activeModal.close(data);
        }
    }
    onClickCancel() {
        this.activeModal.dismiss('Cancelled by user');
    }
}
