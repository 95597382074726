import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import {
    CreateCategoryV2DTO,
    DashboardV2Action,
    DashboardV2Item,
    GetCategoryV2RO,
    listActionTypes,
    UpdateCategoryV2DTO
} from '@alii-web/models/dashboard-v2.model';
import { ConfirmModalComponent } from '@alii-web/modules/protocols/entry-components';
import { DashboardService } from '@alii-web/services';
import { switchMap } from 'rxjs/operators';

type EditMode = 'list' | 'update' | 'create';

interface SavedState {
    id: string;
    title: string;
    selected: DashboardV2Item[];
    available: DashboardV2Item[];
}

const cn = 'CategoryManageComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-category-manage',
    templateUrl: './category-manage.component.html',
    styleUrls: ['../common/common.scss']
})
export class CategoryManageV2Component implements OnInit, OnDestroy {
    currentListItem: DashboardV2Item;

    loadingListItem: boolean;
    loadingAllListItems: boolean;

    loadingCreate: boolean;
    loadingUpdate: boolean;
    loadingDelete: boolean;

    allListItems: DashboardV2Item[];
    allSubItems: DashboardV2Item[];

    selectedSubItems: DashboardV2Item[];
    availableSubItems: DashboardV2Item[];

    form: FormGroup;

    maxlength = 256;
    sizeSelected = 10;
    sizeAvailable = 10;

    subscriptions: Subscription[] = [];

    editMode: EditMode;

    indexSelectedSubItem = 0;
    indexAvailableSubItem = 0;

    savedStateKeys = ['update', 'create'];
    savedState: { [key: string]: SavedState } = {};

    permissions = {};
    permissionKeys = {
        updateTitle: 'updateTitle',
        createListItem: 'createCategory',
        removeListItem: 'remove_category',
        addSubItem: 'addProtocol',
        setPosition: 'setPosition',
        removeSubItem: 'removeProtocol'
    };

    tooltipKeys = ['update', 'reset', 'delete', 'manage', 'dashboard'];
    tooltip = {};

    urlSegments = ['dashboard', 'category'];

    itemName = '_CATEGORY';

    development = false; // !environment.production;

    constructor(
        private modalService: NgbModal,
        private fb: FormBuilder,
        private dashboardService: DashboardService,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private cdr: ChangeDetectorRef,
        private permissionsService: NgxPermissionsService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            title: ['', Validators.required],
            subItems: []
        });

        // params['id'] indicates the edit mode for this page.
        //   null   => 'list'
        //   create => 'create'
        //   else   => 'update' and listItemId = params['id']
        this.subscriptions.push(
            this.route.params.subscribe(params => {
                const id = params['id'];
                const editMode: EditMode = id ? (id === 'create' ? 'create' : 'update') : 'list';
                this._switchEditMode(editMode, id);
            })
        );

        // Setup translations
        this._onLangChange();
        this.subscriptions.push(this.translateService.onLangChange.subscribe(() => this._onLangChange()));

        this.subscriptions.push(
            this.permissionsService.permissions$.subscribe(permissions => (this.permissions = permissions))
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    getMetaValue(_item: DashboardV2Item, name: string) {
        const found = (this.allListItems || []).find(item => item.id === _item.id);
        return found ? (found.meta && found.meta[name] ? found.meta[name] : '') : '';
    }

    selectListItem(id: string, navigate = true) {
        if (navigate) {
            const urlSegments = [...this.urlSegments];
            urlSegments.push(id);
            this._routerNavigate(urlSegments);
        }
    }

    dblclickSelectedSubItem(subItem: DashboardV2Item) {
        if (this.permissions[this.permissionKeys.removeSubItem]) {
            // Move selected subitem back to available subitem list
            this.availableSubItems.push(subItem);

            // If we are moving the selected item then deselect.
            if (this.indexSelectedSubItem === this._findIndexById(this.selectedSubItems, subItem.id)) {
                this.indexSelectedSubItem = 0;
            }

            // Sort all available subitems by id.
            this.availableSubItems = this._sortSubItemsById(this.availableSubItems);

            // Remove selected subitem from selected subitems list
            this.selectedSubItems = this.selectedSubItems
                .filter(c => c.id !== subItem.id)
                .map((c, i) => ({ ...c, position: i + 1 }));

            if (!this.selectedSubItems.length) {
                this.indexSelectedSubItem = 0;
            }
        }
    }

    dblclickAvailableSubItem(subItem: DashboardV2Item) {
        if (this.permissions[this.permissionKeys.addSubItem]) {
            // Get the highest position from the selected subitems list, if any.
            const sortedSubItems = this._sortSubItemsByPosition(this.selectedSubItems);
            subItem.position = sortedSubItems.length ? sortedSubItems[sortedSubItems.length - 1].position + 1 : 1;

            // Move subitem from available to selected subitems list
            this.selectedSubItems.push(subItem);

            // If we are moving the selected item then deselect.
            if (this.indexAvailableSubItem === this._findIndexById(this.availableSubItems, subItem.id)) {
                this.indexAvailableSubItem = 0;
            }

            // Remove subitem from available items list
            this.availableSubItems = this.availableSubItems.filter(c => c.id !== subItem.id);

            if (!this.availableSubItems.length) {
                this.indexAvailableSubItem = 0;
            }
        }
    }

    onSubmit() {
        switch (this.editMode) {
            case 'create':
                this._handleOnSubmitCreate();
                break;
            case 'update':
                this._handleOnSubmitUpdate();
                break;
            default:
                console.warn(`${cn} onSubmit() unknown editMode='${this.editMode}'`);
                break;
        }
    }

    onReset() {
        switch (this.editMode) {
            case 'create':
                this._handleOnResetCancel();
                break;
            case 'update':
                this._handleOnResetUpdate();
                break;
            default:
                console.warn(`${cn} onReset() unknown editMode='${this.editMode}'`);
                break;
        }
    }

    onCreate() {
        switch (this.editMode) {
            case 'list':
                this._handleOnCreateCreate();
                break;
            case 'update':
                this._handleOnCreateUpdate();
                break;
            default:
                console.warn(`${cn} onCreate() unknown editMode='${this.editMode}'`);
                break;
        }
    }

    onCancel() {
        switch (this.editMode) {
            case 'create':
                this._handleOnCancelCreate();
                break;
            default:
                console.warn(`${cn} onCancel() unknown editMode='${this.editMode}'`);
                break;
        }
    }

    onDashboard() {
        const dashboardId = this.route.snapshot.queryParams['dashboardId'];
        if (dashboardId) {
            this.router.navigate(['/dashboard', dashboardId]);
        } else {
            this.router.navigate(['/dashboard']);
        }
    }

    onDelete() {
        switch (this.editMode) {
            case 'update':
                this._handleOnDeleteUpdate();
                break;
            default:
                console.warn(`${cn} onDelete() unknown editMode='${this.editMode}'`);
                break;
        }
    }

    clickSelectedSubItem(subItem: DashboardV2Item) {
        this.indexSelectedSubItem = this._findIndexById(this.selectedSubItems, subItem.id);
    }

    clickAvailableSubItem(subItem: DashboardV2Item) {
        this.indexAvailableSubItem = this._findIndexById(this.availableSubItems, subItem.id);
    }

    onUp() {
        if (this.indexSelectedSubItem > 1) {
            this._shiftIndexBy(-1);
        } else {
            console.warn(`${cn} onUp() selectedIndex='${this.indexSelectedSubItem}'`);
        }
    }

    onDown() {
        if (this.indexSelectedSubItem && this.indexSelectedSubItem <= this.selectedSubItems.length - 1) {
            this._shiftIndexBy(1);
        } else {
            console.warn(`${cn} onDown() selectedIndex='${this.indexSelectedSubItem}'`);
        }
    }

    onManageSubItem() {
        let id: string;
        if (this.indexAvailableSubItem) {
            id = this.availableSubItems[this.indexAvailableSubItem - 1].id;
        } else if (this.indexSelectedSubItem) {
            id = this.selectedSubItems[this.indexSelectedSubItem - 1].id;
        }

        if (id) {
            this.router.navigate(['protocols', id]);
        } else {
            console.warn(`${cn} onManageSubItem() cannot find id`);
        }
    }

    debugPermissions() {
        const result = listActionTypes
            .map(permission => this.permissions[permission])
            .filter(permission => !!permission)
            .map(permission => permission.name);

        return result && result.length ? 'Permissions: ' + result.join(', ') : '';
    }

    trackByFn = (index, item) => item.id || index;

    private _onLangChange() {
        // Redefine the button tooltips based on the current language.
        const prefix = this.itemName + '.MANAGE.TOOLTIP.';
        this.translateService.get(this.tooltipKeys.map(key => prefix + key.toUpperCase())).subscribe(results => {
            this.tooltipKeys.forEach(key => (this.tooltip[key] = results[prefix + key.toUpperCase()]));
        });
    }

    private _handleOnSubmitCreate() {
        const payload: CreateCategoryV2DTO = {
            title: this.form.value.title,
            protocolIds: this.selectedSubItems.map(c => c.id)
        };

        this.loadingCreate = true;
        this.cdr.markForCheck();
        this.dashboardService.createCategoryV2(payload).subscribe(
            response => {
                this._showToastr('create', true);
                const urlSegments = [...this.urlSegments];
                if (response && response.category) {
                    const category = response.category;
                    this.allListItems.push({
                        id: category.id.toString(),
                        title: category.name
                    });
                    this.savedState['create'] = null;
                    urlSegments.push(response.category.id.toString());
                }
                this._routerNavigate(urlSegments);
            },
            () => this._showToastr('create', false),
            () => {
                this.loadingCreate = this._cancelLoading();
            }
        );
    }

    private _handleOnSubmitUpdate() {
        const payload: UpdateCategoryV2DTO = {
            title: this.form.value.title,
            protocolIds: this.selectedSubItems.map(c => c.id)
        };

        this.loadingUpdate = true;
        this.dashboardService.updateCategoryV2(this.currentListItem.id, payload).subscribe(
            () => {
                this._handleOnSubmitUpdateSucceeded();
                this._showToastr('update', true);
            },
            () => this._showToastr('update', false),
            () => {
                this.loadingUpdate = this._cancelLoading();
            }
        );
    }

    private _handleOnSubmitUpdateSucceeded() {
        // Update the title of the current listItem and in the listItem list.
        const title = this.form.value.title;
        const id = this.currentListItem.id;
        this.currentListItem.title = title;
        this.allListItems = this.allListItems.map(listItem => ({
            ...listItem,
            name: listItem.id.toString() === id ? title : listItem.title
        }));
        this._saveStateUpdate(id, title);
    }

    private _handleOnResetCancel() {
        this._switchEditModeCreate();
    }

    private _handleOnResetUpdate() {
        this._restoreStateUpdate();
    }

    private _handleOnCreateCreate() {
        const urlSegments = [...this.urlSegments];
        urlSegments.push('create');
        this._routerNavigate(urlSegments);
    }

    private _handleOnCreateUpdate() {
        const urlSegments = [...this.urlSegments];

        // Save the current listItem id and navigate to create path
        this._saveStateCreate(this.currentListItem.id, this.currentListItem.title);

        urlSegments.push('create');
        this._routerNavigate(urlSegments);
    }

    private _handleOnCancelCreate() {
        const urlSegments = [...this.urlSegments];

        // Return to previous listItem if present otherwise go to main listing page.
        const savedStateCreate = this.savedState['create'];
        if (savedStateCreate) {
            const id = savedStateCreate.id;
            this.savedState['create'] = null;
            urlSegments.push(id);
        }
        this.router.navigate(urlSegments);
    }

    private _handleOnDeleteUpdate() {
        // Double-check just in case and if verified do actual delete.
        const modalRef = this.modalService.open(ConfirmModalComponent);
        if (modalRef) {
            modalRef.componentInstance.data = {
                title: this.itemName + '.MANAGE.DELETE.TITLE',
                text: this.itemName + '.MANAGE.DELETE.TEXT'
            };
            modalRef.result.then(
                () => this._handleOnDeleteUpdateVerified(),
                () => {}
            );
        } else {
            console.error(`${cn} _handleOnDeleteUpdate() cannot open confirm modal component`);
            return false;
        }
    }

    private _handleOnDeleteUpdateVerified() {
        this.loadingDelete = true;
        this.dashboardService.deleteDashboardV2(this.currentListItem.id).subscribe(
            () => {
                this.allListItems = this.allListItems.filter(
                    d => d.id.toString() !== this.currentListItem.id.toString()
                );
                this.currentListItem = null;
                this.router.navigate(this.urlSegments);
                this.form.reset();
                this._showToastr('delete', true);
            },
            () => this._showToastr('delete', false),
            () => {
                this.loadingDelete = this._cancelLoading();
            }
        );
    }

    private _saveStateUpdate(id: string, title: string) {
        // Save initial state in case of reset.
        this._saveState('update', id, title);
    }

    private _saveStateCreate(id: string, title: string) {
        // Save initial state in case of reset.
        this._saveState('create', id, title);
    }

    private _saveState(key: string, id: string, title: string) {
        if (this.savedStateKeys.includes(key)) {
            this.savedState[key] = {
                id,
                title,
                selected: [...this.selectedSubItems],
                available: [...this.availableSubItems]
            };
        } else {
            console.warn(`${cn} _saveState() invalid key='${key}'`);
        }
    }

    private _restoreStateUpdate(del = false) {
        this._restoreState('update', del);
    }

    private _restoreStateCreate(del = false) {
        this._restoreState('create', del);
    }

    private _restoreState(key: string, del = false) {
        if (this.savedStateKeys.includes(key)) {
            const savedState = this.savedState[key];
            if (savedState) {
                this.form.patchValue({ title: savedState.title });
                this.selectedSubItems = [...savedState.selected];
                this.availableSubItems = [...savedState.available];

                if (del) {
                    delete this.savedState[key];
                }

                // Flag change detection
                this.cdr.markForCheck();
            } else {
                console.warn(`${cn} _restoreState() saved state for key='${key}' does not exist`);
            }
        } else {
            console.warn(`${cn} _restoreState() invalid key='${key}'`);
        }
    }

    private _saveStateClear() {
        this.savedStateKeys.forEach(key => (this.savedState[key] = null));
    }

    private _shiftIndexBy(n: number) {
        const index = this.indexSelectedSubItem - 1;
        const x = [...this.selectedSubItems];
        const [y] = x.splice(index, 1);
        x.splice(index + n, 0, y);
        this.selectedSubItems = x.map((c, i) => ({ ...c, position: i + 1 }));
        this.indexSelectedSubItem = this.indexSelectedSubItem + n;
    }

    private _findIndexById = (subItems: DashboardV2Item[], id: string) =>
        subItems.findIndex(item => item.id === id) + 1;

    private _switchEditMode(editMode: EditMode, id: string) {
        this.editMode = editMode;
        this.indexSelectedSubItem = 0;

        // Need to wait until all listItems and subItems have been loaded.
        const timerId = setInterval(() => {
            if (!this.loadingAllListItems) {
                switch (editMode) {
                    case 'list':
                        this._switchEditModeList();
                        break;
                    case 'update':
                        this._switchEditModeUpdate(id);
                        break;
                    case 'create':
                        this._switchEditModeCreate();
                        break;
                    default:
                        console.warn(`${cn} _switchEditMode() unknown editMode='${editMode}'`);
                        break;
                }
                clearInterval(timerId);
                setTimeout(() => this.cdr.markForCheck(), 200);
            }
        }, 200);
    }

    private _switchEditModeList() {
        this.currentListItem = null;
        this.selectedSubItems = [];

        if (!this.allListItems) {
            // Get all categories and protocols.
            this.loadingAllListItems = true;
            this.cdr.markForCheck();
            setTimeout(
                () =>
                    this.dashboardService.getAllCategoriesV2().subscribe(
                        response =>
                            (this.allListItems = response.map(category => ({
                                id: category.id,
                                title: category.name
                            }))),
                        error => console.warn(`${cn} _switchEditModeList() getAllCategoriesV2() failed`, error),
                        () => (this.loadingAllListItems = this._cancelLoading())
                    ),
                0
            );
        }
    }

    private _switchEditModeUpdate(id: string) {
        this.loadingListItem = true;
        this.cdr.markForCheck();
        setTimeout(
            () =>
                this.dashboardService.getCategoryV2(id).subscribe(
                    category => this._loadListItem(category),
                    () => console.warn(`${cn} _switchEditModeUpdate() getCategoryV2() id='${id}' failed`),
                    () => (this.loadingListItem = this._cancelLoading())
                ),
            0
        );
    }

    private _switchEditModeCreate() {
        this.currentListItem = null;
        this.selectedSubItems = [];
        this.allListItems = [];
        if (!this.allSubItems) {
            this.dashboardService
                .getAllCategoriesV2()
                .pipe(switchMap(categories => this.dashboardService.getCategoryV2(categories[0].id)))
                .subscribe(
                    category => this._handleCategoryCreate(category),
                    () => console.warn(`${cn} _switchEditModeCreate() getAllCategoriesV2() failed`),
                    () => this.cdr.markForCheck()
                );
        } else {
            this._loadAvailableSubItems();
            this.form.reset();
        }
    }

    private _handleCategoryCreate(getCategoryV2RO: GetCategoryV2RO) {
        const actions = getCategoryV2RO.actions;

        // Load all protocols
        this._loadAllSubItems(actions);

        // Load all available protocols
        this._loadAvailableSubItems();

        // Load all the permissions
        this._loadPermissions(actions);
    }

    private _loadAvailableSubItems() {
        this.availableSubItems = (this.allSubItems || []).map(c => ({
            id: c.id,
            title: c.title,
            position: 0
        }));
        this.availableSubItems = this._sortSubItemsById(this.availableSubItems);
        this.cdr.markForCheck();
    }

    private _loadListItem(getCategoryV2RO: GetCategoryV2RO) {
        if (!(getCategoryV2RO && getCategoryV2RO.category && getCategoryV2RO.category.id)) {
            this._showToastr('load', false);
            this.router.navigate(this.urlSegments);
            return;
        }

        const category = getCategoryV2RO.category;
        const actions = getCategoryV2RO.actions;
        this.currentListItem = {
            id: category.id.toString(),
            title: category.name,
            meta: {
                badge: (category.protocols || []).length
            }
        };
        this.form.patchValue({ title: category.name });

        // Load all categories
        this._loadAllListItems(actions);

        // Load all protocols
        this._loadAllSubItems(actions);

        // Load all available protocols
        this._loadAvailableSubItems();

        // Load all the permissions
        this._loadPermissions(actions);

        // Filter out those protocols which are already used by the category.
        const subItemIds = category.protocols.map(protocol => protocol.id.toString());

        // Setup available protocols list.
        this.availableSubItems = this.availableSubItems.filter(subItem => !subItemIds.includes(subItem.id));

        // Sort available protocols by id.
        this.availableSubItems = this._sortSubItemsById(this.availableSubItems);

        this.selectedSubItems = category.protocols
            .filter(subItem => subItemIds.includes(subItem.id.toString()))
            .map((p, i) => ({
                id: p.id.toString(),
                title: p.title,
                position: p['position'] || i + 1
            }));

        // Sort selected subitems by position.
        this.selectedSubItems = this._sortSubItemsByPosition(this.selectedSubItems);

        this._saveStateUpdate(category.id.toString(), category.name);

        // If we are returning from a cancelled create, we need to restore state.
        if (this.savedState['create']) {
            this._restoreStateCreate(true);
        }
    }

    private _loadAllListItems(actions: DashboardV2Action[] = []) {
        this.allListItems = [];
        const actionName = 'switchCategory';
        const found = actions.find(action => action.action === actionName);
        if (found) {
            if (found.value) {
                found.categories.forEach(category => {
                    // Skip possible duplicates
                    if (!this.allListItems.find(item => item.id === category.id.toString())) {
                        this.allListItems.push({
                            id: category.id.toString(),
                            title: category.name
                        });
                    }
                });
                this.allListItems = this._sortSubItemsById(this.allListItems);
            }
        } else {
            console.warn(`${cn} _loadAllListItems() cannot find action='${actionName}'`);
        }
    }

    private _loadAllSubItems(actions: DashboardV2Action[] = []) {
        this.allSubItems = [];
        const actionName = 'addProtocol';
        const found = actions.find(action => action.action === actionName);
        if (found) {
            if (found.value) {
                Object.keys(found.teams).forEach(name => {
                    found.teams[name].protocols.forEach(protocol => {
                        // Do not push duplicates.
                        if (!this.allSubItems.find(item => item.id === protocol.id)) {
                            this.allSubItems.push({
                                id: protocol.id,
                                title: protocol.title
                            });
                        }
                    });
                });
                this.allSubItems = this._sortSubItemsById(this.allSubItems);
            }
        } else {
            console.warn(`${cn} _loadAllSubItems() cannot find action='${actionName}'`);
        }
    }

    private _loadPermissions(actions: DashboardV2Action[] = []) {
        this.permissionsService.addPermission(
            actions.filter(action => action.action && action.value).map(action => action.action)
        );
        actions
            .filter(action => action.action && !action.value)
            .map(action => action.action)
            .forEach(permission => this.permissionsService.removePermission(permission));
    }

    private _cancelLoading(): boolean {
        this.cdr.markForCheck();
        return false;
    }

    private _routerNavigate(urlSegments: string[]) {
        const dashboardId = this.route.snapshot.queryParams['dashboardId'];
        if (dashboardId) {
            this.router.navigate(urlSegments, { queryParams: { dashboardId } });
        } else {
            this.router.navigate(urlSegments);
        }
        setTimeout(() => this.cdr.markForCheck(), 200);
    }

    private _sortSubItemsById(subItems: DashboardV2Item[] = []): DashboardV2Item[] {
        return this._sortSubItems(subItems, 'id');
    }

    private _sortSubItemsByName(subItems: DashboardV2Item[] = []): DashboardV2Item[] {
        return this._sortSubItems(subItems, 'name');
    }

    private _sortSubItemsByPosition(subItems: DashboardV2Item[] = []): DashboardV2Item[] {
        return this._sortSubItems(subItems, 'position');
    }

    private _sortSubItems(subItems: DashboardV2Item[] = [], attr: string): DashboardV2Item[] {
        return attr === 'id'
            ? subItems.sort((a, b) => (+a[attr] < +b[attr] ? -1 : +b[attr] < +a[attr] ? 1 : 0))
            : subItems.sort((a, b) => (a[attr] < b[attr] ? -1 : b[attr] < a[attr] ? 1 : 0));
    }

    private _showToastr(prefix: string, success: boolean) {
        const keys = [
            this.itemName + `.MANAGE.ACTION.${prefix.toUpperCase()}.${success ? 'OK' : 'NOK'}`,
            `${success ? 'SUCCESS' : 'ERROR'}`
        ];
        this.translateService.get(keys).subscribe(key => {
            if (success) {
                this.toastr.success(key[keys[0]], key[keys[1]]);
            } else {
                this.toastr.error(key[keys[0]], key[keys[1]]);
            }
        });
    }
}
