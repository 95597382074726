import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromStore from './../../store';
import { ModelHelpTextModalComponent } from '../../entry-components';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-protocols-form',
    template: `
        <alii-web-protocol-form
            [categories]="categories$ | async"
            [categoriesLoading]="categoriesLoading$ | async"
            [createProtocolLoading]="createProtocolLoading$ | async"
            (eventBus)="handleEventBus($event)"
        ></alii-web-protocol-form>
    `
})
export class ProtocolsFormComponent implements OnInit {
    categories$: Observable<any[]>;
    categoriesLoading$: Observable<boolean>;
    createProtocolLoading$: Observable<boolean>;

    constructor(private store: Store<fromStore.ProtocolsFeatureState>, private modalService: NgbModal) {}

    ngOnInit() {
        this.categories$ = this.store.select(fromStore.getDashboardCategories);
        this.categoriesLoading$ = this.store.select(fromStore.getDashboardCategoriesLoading);
        this.createProtocolLoading$ = this.store.select(fromStore.getCreateProtocolLoading);
        this.store.dispatch(new fromStore.LoadDashboardCategories());
    }

    handleEventBus(event) {
        const { type, payload } = event;
        switch (type) {
            case 'handleCreateProtocol':
                this.handleCreateProtocol(payload);
                break;
            case 'openHelpText':
                this.openHelpText(payload);
                break;
            default:
                break;
        }
    }

    handleCreateProtocol(payload) {
        this.store.dispatch(new fromStore.CreateProtocol(payload));
    }

    openHelpText(payload) {
        const { title, helpText } = payload;
        const modalRef = this.modalService.open(ModelHelpTextModalComponent, { size: 'lg' });
        modalRef.componentInstance.bodyText = helpText;
        modalRef.componentInstance.title = title || 'Help';
    }
}
