import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromLiteratures from '../reducers/literatures-collection.reducer';

const getLiteraturesCollectionState = createSelector(
    fromFeature.getLiteraturesFeatureState,
    (state: fromFeature.LiteraturesFeatureState) => state.literaturesCollection
);
export const getLiteraturesCollection = createSelector(
    getLiteraturesCollectionState,
    fromLiteratures.getLiteraturesCollection
);

export const selectProtocolId = createSelector(
    getLiteraturesCollectionState,
    fromLiteratures.selectProtocolId
);

export const getLiteraturesCollectionLoading = createSelector(
    getLiteraturesCollectionState,
    fromLiteratures.getLiteraturesCollectionLoading
);

export const getParagraphs = createSelector(
    getLiteraturesCollectionState,
    fromLiteratures.getParagraphs
);
export const getPopulations = createSelector(
    getLiteraturesCollectionState,
    fromLiteratures.getPopulations
);
export const getSteps = createSelector(
    getLiteraturesCollectionState,
    fromLiteratures.getSteps
);
export const getAddedArticles = createSelector(
    getLiteraturesCollectionState,
    fromLiteratures.getAddedArticles
);
export const getIds = createSelector(
    getLiteraturesCollectionState,
    fromLiteratures.getIds
);
export const getVersions = createSelector(
    getLiteraturesCollectionState,
    fromLiteratures.getVersions
);

export const getProtocolDetails = createSelector(
    getLiteraturesCollectionState,
    fromLiteratures.getProtocolDetails
);

export const getSelectedLiterature = createSelector(
    getLiteraturesCollectionState,
    fromLiteratures.getSelectedLiterature
);
export const getUploadEvent = createSelector(
    getLiteraturesCollectionState,
    fromLiteratures.getUploadEvent
);
