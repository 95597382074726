import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromProtocolReducer from '../reducers/protocol-all-outcomes.reducer';

export const getProtocolAllOutcomesState = createSelector(
    fromFeature.getProtocolsFeatureState,
    (state: fromFeature.ProtocolsFeatureState) => state.protocolAllOutcomes
);
export const getProtocolWithAllOutcomes = createSelector(
    getProtocolAllOutcomesState,
    fromProtocolReducer.getProtocolWithAllOutcomes
);

export const getProtocolPopulations = createSelector(
    getProtocolAllOutcomesState,
    fromProtocolReducer.getProtocolPopulations
);

export const getProtocolWithAllOutcomesLoaded = createSelector(
    getProtocolAllOutcomesState,
    fromProtocolReducer.getProtocolWithAllOutcomesLoaded
);

export const getProtocolWithAllOutcomesLoading = createSelector(
    getProtocolAllOutcomesState,
    fromProtocolReducer.getProtocolWithAllOutcomesLoading
);
