import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

// Translation stuff, see: https://github.com/ngx-translate/core
import { TranslateModule } from '@ngx-translate/core';

import { reducers, effects } from './store';
import * as fromServices from '../../services';
import * as fromContainers from './containers';
import * as fromComponents from './components';
import * as fromEntryComponents from './entry-components';
import { PartialsModule } from '../../partials/partials.module';
import { LiteratureRoutingModule } from './literature-routing.module';
import { AgGridModule } from 'ag-grid-angular';
import { RenderHTML } from './components/reviews/render-html.component';
import {EditHTML} from './components/reviews/edit-html.component';
import { ModalTriggerGptFullTextComponent } from './components/literatures-detail/trigger-gpt/modal-trigger-gpt-full-text';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        AgGridModule.withComponents([RenderHTML, EditHTML]),
        StoreModule.forFeature('LiteraturesFeature', reducers),
        EffectsModule.forFeature(effects),
        LiteratureRoutingModule,
        PartialsModule,
        TranslateModule.forChild({ extend: true })
    ],
    providers: [...fromServices.services],

    declarations: [ModalTriggerGptFullTextComponent, ...fromContainers.containers, ...fromComponents.components, ...fromEntryComponents.entryComponents, RenderHTML, EditHTML],

    exports: [...fromContainers.containers, ...fromComponents.components]
})
export class LiteraturesModule {}
