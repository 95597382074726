import { Action } from '@ngrx/store';
import { DashboardDetail } from '@alii-web/models/dashboard-detail.model';

export const LOAD_DASHBOARD_FORM = '[Dashboard] Load Dashboard Form';

export class LoadDashboardForm implements Action {
    readonly type = LOAD_DASHBOARD_FORM;
    constructor(public payload: DashboardDetail) {}
}

export type DashboardFormAction = LoadDashboardForm;
