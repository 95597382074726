<flowchart-builder
    [id]="id"
    [protocol]="protocol$ | async"
    [protocolLoading]="protocolLoading$ | async"
    [scheme]="scheme$ | async"
    [flowchart]="flowchart$ | async"
    [flowchartLoading]="flowchartLoading$ | async"
    [existingProtocols]="existingProtocols$ | async"
    [versionId]=""
    [version]=""
    [currentVersion]=""
    [draftVersion]=""
    [deprecatedVersions]=""
    [storedDraftVersions]=""
    (eventBus)="handleEventBus($event)"
></flowchart-builder>
