import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-dashboard-statistics',
    templateUrl: './dashboard-statistics.component.html'
})
export class DashboardStatisticsComponent implements OnInit {
    loading$: Observable<boolean>;
    id: string;

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.loading$ = of(false);
    }
}
