import * as fromShop from '../actions/shop.action';

import { Shop } from '../../../../models/shop.interface';

export interface ShopState {
    data: Shop;
    loaded: boolean;
    loading: boolean;
}

export const initialState: ShopState = {
    data: {},
    loaded: false,
    loading: false,
};

export function reducer(state = initialState, action: fromShop.StoreAction): ShopState {
    switch (action.type) {
        case fromShop.LOAD_SHOP: {
            return { ...state, loading: true };
        }

        case fromShop.LOAD_SHOP_SUCCESS: {
            return { ...state, loading: false, loaded: true, data: action.payload };
        }

        case fromShop.LOAD_SHOP_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
    }
    return state;
}

export const getShop = (state: ShopState) => state.data;
export const getShopLoading = (state: ShopState) => state.loading;
export const getShopLoaded = (state: ShopState) => state.loaded;
export const getShopHospital = (state: ShopState) => ({ id: state.data.hospital_id, name: state.data.hospital_name});
