import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    ViewChildren,
    QueryList,
    ElementRef,
    Input,
    Renderer2
} from '@angular/core';

import { ShopCategory } from '../../../../models/shop-category.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-help-library-cards',
    templateUrl: './library-cards.component.html',
    styleUrls: ['./library-cards.component.scss']
})
export class LibraryCardsComponent implements OnInit {
    @ViewChildren('card') cards: QueryList<ElementRef>;

    @Input() categories: ShopCategory[];

    constructor(private renderer: Renderer2) {}

    ngOnInit() {}

    ngAfterViewInit() {
        setTimeout(() => {
            const cards = this.cards.toArray();
            let max = 0;
            // Get max height
            cards.forEach(card => {
                const height = card.nativeElement.offsetHeight;
                if (max < height) {
                    max = height;
                }
            });
            // Adjust all card height to max
            cards.forEach(card => this.renderer.setStyle(card.nativeElement, 'height', max + 'px'));
        }, 200);
    }

    trackByFn = (index, item) => item.id || index;
}
