import {
    Component,
    ChangeDetectionStrategy,
    EventEmitter,
    Input,
    Output,
    OnChanges,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GradeFormModalComponent } from '../../../../entry-components';
import { CommentAddModalComponent } from '../../../protocol-edit/paragraph-edit/footer-edit/comment-add-modal/comment-add-modal.component';
import { Paragraph, SelectedFootnote } from '../../../../../../models/paragraph.interface';
import * as fromService from '../../../../../../services';
import { RenderHTML } from '../../../../../literatures/components/reviews/render-html.component';
import { EditHTML } from '../../../../../literatures/components/reviews/edit-html.component';
import {AgGridAngular} from 'ag-grid-angular';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-paragraph-footer',
    templateUrl: './paragraph-footer.component.html',
    styleUrls: ['./paragraph-footer.component.scss']
})
export class ParagraphFooterComponent implements OnChanges {
    @Input() isEditAble: boolean;
    @Input() paragraphId: string;
    @Input() paragraph: Paragraph;
    @Input() keyQuestionId: string;
    @Input() comments: any[] = [];
    @Input() files: any[] = [];
    @Input() sources: any;
    @Input() sourceInfo?: any;
    @Input() populations: any[] = [];
    @Input() gradeAssessmentPar: any;
    @Input() title: string;
    @Input() version: string;
    @Input() parentId: string;
    @Input() sourceTable: any[];
    @Input() gradeColumnDefs: any[];
    @Input() gradeRowData: any[];
    @Input() selectedFootnote: SelectedFootnote;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    isCommentsCollapsed = false;
    footnoteShown: any;
    isFilesCollapsed = false;
    isSourcesCollapsed = false;
    isPopulationsCollapsed = false;
    supportedImageExt = ['bmp', 'gif', 'jpg', 'jpeg', 'png', 'svg'];
    selectedQuestionIndex: number;
    rowclass: string;
    frameworkComponents;
    actionsShown: boolean;

    

    constructor(
        private modalService: NgbModal,
        private literaturesService: fromService.LiteraturesService) {
            this.frameworkComponents = {
                renderHTML: RenderHTML,
                editHTML: EditHTML,
              };
        }

    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('agGridSources') agGridSources: AgGridAngular;

    // If a footnote is clicked, open sources so that hilighted item is visible.
    ngOnChanges(changes: SimpleChanges) {
        if (this.selectedFootnote) {
            this.isSourcesCollapsed = true;
            if(!this.sources) {
                this.loadEvidence(); 
            } else {
                let articleId =  decodeURIComponent(this.selectedFootnote.articleId)     
                const elements = document.getElementsByClassName(articleId)
                let scrollDone = false
                if(elements.length) {
                    this.scrollToFootnote(elements, articleId)
                }
                else {
                    this.footnoteShown = setInterval(() => { 
                    if(this.selectedFootnote) {
                        let articleId =  decodeURIComponent(this.selectedFootnote.articleId)     
                        const elements = document.getElementsByClassName(articleId)
                        if(elements.length && !scrollDone) 
                            {
                                this.scrollToFootnote(elements, articleId)
                            }
                        }
                    }, 600)
                }
            }
        }
    }

    scrollToFootnote(elements, articleId) {
        clearInterval(this.footnoteShown)
        this.selectedFootnote = undefined
        for (let i=0; i<elements.length; i++)  {
            elements[i].classList.add("icon-arrow-right");
            };
        this.scrollIntoView(elements[0])
        setTimeout(function(){
        var elements = document.getElementsByClassName(articleId)
        for (let i=0; i<elements.length; i++)  {
            elements[i].classList.remove("icon-arrow-right");
            };
        }, 3000);
        
    }

    hideIrrelevantColumns(columns) {
        if (!columns) 
            return;
        let filteredColumns = [];
        let hideFromHere = false;
        columns.forEach(col => {
            const toHide = col['hide'] || hideFromHere;
            filteredColumns.push({...col, hide:  toHide});

            if(!hideFromHere && (col.field == "Outcomes" || col.field == "Uitkomsten")) {
                hideFromHere = true;
            }
            
        });

        return filteredColumns;
    }

    toggleCollapsed(target) {
        const origin = this[target];
        this.isCommentsCollapsed = false;
        this.isFilesCollapsed = false;
        this.isSourcesCollapsed = false;
        this.isPopulationsCollapsed = false;

        if (!origin) {
            this[target] = true;
        }
        if (target === 'isSourcesCollapsed' ) {
            this.loadEvidence();
        }
        if (target === 'isSourcesCollapsed') {
            setTimeout( () => {
                if(this.agGrid) {
                    this.agGrid.api.resetRowHeights()
                }
                if(this.agGridSources) {
                    this.agGridSources.api.resetRowHeights()
                }
             }, 0 );      
        }
    }

    loadEvidence() {
        const loadFindingsAction = {
            type: 'handleLoadEvidence',
            payload: {
                protocolId: undefined,
                ppdId: this.paragraphId,
                parentId: this.parentId
            }
        };
        this.eventBus.emit(loadFindingsAction);
    }

    loadGradeQuestions() {
        const getQuestionsAction = {
            type: 'handleGetGradeQuestions',
            payload: {
                gradeId: this.gradeAssessmentPar.id,
                ppdId: this.paragraphId
            }
        };
        this.eventBus.emit(getQuestionsAction);
    }
    
    openOutcomeTreeview(event, paragraph) {
        const openTreeAction = {
            type: 'openOutcomeTreeview',
            payload: {
                outcome: paragraph,
                populationId: null
            }
        };
        this.eventBus.emit(openTreeAction);
        event.stopPropagation();
        return false;
    }

    isReferencesLinkActive() {
        const action = this.gradeAssessmentPar ? this.gradeAssessmentPar.action : '';
        return this.sources.length > 0 || action === 'editGradeAssessment';
    }

    commingSoonGreeting() {
        alert('Comming soon!');
    }

    formatGradeKey(key: string) {
        return key.replace(/_/g, ' ');
    }

    handleOpenCommentAddModal() {
        const modalRef = this.modalService.open(CommentAddModalComponent);
        modalRef.result.then(
            result => {
                const action = {
                    type: 'handleAddComment',
                    payload: {
                        ppdId: this.paragraphId,
                        parentId: this.parentId,
                        text: result.message
                    }
                };
                this.eventBus.emit(action);
            },
            () => {}
        );
    }


    onClickShowGrade(index) {
        this.selectedQuestionIndex = 0;
        this.handleOpenGradeModal(false);
    }

    handleOpenGradeModal(edit) {
        let rowData = ''
        if(this.gradeAssessmentPar.questions[this.selectedQuestionIndex].rowData) {
            rowData = JSON.parse(JSON.stringify(this.gradeAssessmentPar.questions[this.selectedQuestionIndex].rowData));
        }
        let fieldsRowData = ''
        if (this.gradeAssessmentPar.questions[this.selectedQuestionIndex].fields) {
            fieldsRowData = JSON.parse(JSON.stringify(this.gradeAssessmentPar.questions[this.selectedQuestionIndex].fields.rowData));
        }
        
        const modalRef = this.modalService.open(GradeFormModalComponent, { size: 'xl' });
        modalRef.componentInstance.edit = edit;
        modalRef.componentInstance.ppdId = this.paragraphId;
        modalRef.componentInstance.isCreateGrade = false;
        modalRef.componentInstance.rowData = rowData;
        modalRef.componentInstance.fieldsRowData = fieldsRowData;
        modalRef.componentInstance.gradeAssessmentPar = this.gradeAssessmentPar;
        modalRef.componentInstance.selectedQuestion = this.gradeAssessmentPar.questions[this.selectedQuestionIndex];
        modalRef.componentInstance.selectedQuestionIndex = this.selectedQuestionIndex;

        modalRef.componentInstance.handleNavigate.subscribe(index => {
            modalRef.close();
            this.selectedQuestionIndex = index;
            this.handleOpenGradeModal(edit);

        });

        modalRef.componentInstance.eventBus.subscribe(event => {
            this.eventBus.emit(event)
        });


        modalRef.result.then(
            () => {this.loadGradeQuestions();}, // on close with result
            () => {this.loadGradeQuestions();} // on dismiss with reason
        );
    }


    handleOpenReplyCommentModal(comment) {
        const modalRef = this.modalService.open(CommentAddModalComponent);
        modalRef.componentInstance.comment = comment;
        modalRef.result.then(
            result => {
                const action = {
                    type: 'handleReplyComment',
                    payload: {
                        ppdId: this.paragraphId,
                        text: result.message,
                        comment: result.comment,
                        parentId: this.parentId,
                    }
                };
                this.eventBus.emit(action);
            },
            () => {} // on dismiss with reason
        );
    }

    onClickArticle(article) {
        const { articleId } = article;

        const viewArticleAction = {
            type: 'onClickArticle',
            payload: {
                articleId
            }
        };
        this.eventBus.emit(viewArticleAction);
    }

    getSelectedRowData() {
        let selectedNodes = this.agGridSources.api.getSelectedNodes();
        let selectedData = selectedNodes.map(node => node.data);
        return selectedData;
    }



    onCellClicked() {
        this.actionsShown = true
    }
    
    openArticle() {
        let articleId =  this.getSelectedRowData()[0].articleId

        const viewArticleAction = {
            type: 'onClickArticle',
            payload: {
                articleId
            }
        };
        this.eventBus.emit(viewArticleAction);

    }


    // TODO: Duplicated code model-detail.component.ts
    summaryOfFinding(finding) {
        const ignoreColumns = ['action', 'id', 'user', 'user_name', 'status', 'actionLabel'];

        return Object.keys(finding)
            .filter(key => !ignoreColumns.includes(key))
            .filter(key => finding[key] !== null)
            .filter(key => finding[key].toString() !== '')
            .map(key => {
                const value = finding[key].toString().replace('illegal reference: ', ''); // todo: fix this in backend
                return {
                    key,
                    value
                };
            });
    }


    highlightLink(article: any): boolean {
        return (
            this.selectedFootnote &&
            this.selectedFootnote.type === 'link' &&
            decodeURIComponent(article.url) === decodeURIComponent(this.selectedFootnote.articleId)
        );
    }

    // If element is below bottom of the window scroll up into view.
    scrollIntoView(el) {
        if (el) {
            // Timeout is required in order to allow accordion element to show
            setTimeout(() => {
                const bounding = el.getBoundingClientRect();
                if (bounding.bottom > window.innerHeight) {
                    el.scrollIntoView({ block: 'center' });
                }
            }, 200);
        }
    }

    isSupportedImage(filename: string): boolean {
        if (!filename) {
            return false;
        }
        const fileExt = filename.split('.').pop();
        return this.supportedImageExt.includes(fileExt);
    }

    showFindings(article: any): boolean {
        return !!article.finding.find(f => this.summaryOfFinding(f).length);
    }

    trackByFn = (index, item) => item.id || index;
}
