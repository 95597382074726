import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromLogin from '../reducers/login.reducer';

export const getLoginState = createSelector(
    fromFeature.getLoginFeatureState,
    (state: fromFeature.LoginFeatureState) => {
        return state.sso;
    }
);
export const getSSOOptions = createSelector(
    getLoginState,
    fromLogin.getSSOOptions
);

export const getLoginFormLoaded = createSelector(
    getLoginState,
    fromLogin.getLoginFormLoaded
);

export const getLoginFormLoading = createSelector(
    getLoginState,
    fromLogin.getLoginFormLoading
);

export const getLoginFormIsError = createSelector(
    getLoginState,
    fromLogin.getLoginFormIsError
);

export const getLoginFormMessage = createSelector(
    getLoginState,
    fromLogin.getLoginFormMessage
);
