import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import { DashboardExisting } from '@alii-web/models/dashboard-existing.model';
import { DashboardExistingActions, DashboardExistingActionTypes } from '../actions/dashboard-existing.action';

export interface HelloState extends EntityState<DashboardExisting> {
    entities: Dictionary<any>;
    selectedDashboardExistingId: string | number | null;
}

export function selectDashboardExistingId(a: DashboardExisting): string {
    return a.id;
}
export const adapter: EntityAdapter<DashboardExisting> = createEntityAdapter<DashboardExisting>({
    selectId: selectDashboardExistingId
});

export const initialState: HelloState = adapter.getInitialState({
    selectedDashboardExistingId: null
});

export function reducer(state = initialState, action: DashboardExistingActions): HelloState {
    switch (action.type) {
        case DashboardExistingActionTypes.AddDashboardExisting: {
            return adapter.addOne(action.payload.dashboardExisting, state);
        }

        case DashboardExistingActionTypes.UpdateDashboardExisting: {
            return adapter.updateOne(action.payload.dashboardExisting, state);
        }

        case DashboardExistingActionTypes.UpdateDashboardExistingSuccess: {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.payload.dashboardExisting.id]: action.payload.dashboardExisting
                }
            };
        }

        case DashboardExistingActionTypes.DeleteDashboardExisting: {
            return adapter.removeOne(action.payload.id, state);
        }

        case DashboardExistingActionTypes.LoadDashboardExistings: {
            return adapter.addAll((action as any).payload, state);
        }

        case DashboardExistingActionTypes.ClearDashboardExistings: {
            return adapter.removeAll(state);
        }

        case DashboardExistingActionTypes.SelectDashboardExisting: {
            return {
                ...state,
                selectedDashboardExistingId: action.payload.dashboardId
            };
        }

        default: {
            return state;
        }
    }
}

export const getSelectedDashboardExistingId = (state: HelloState) => state.selectedDashboardExistingId;

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectDashboardExistingIds = selectIds;

// select the dictionary of user entities
export const selectDashboardExistingEntities = selectEntities;

// select the array of users
export const selectAllDashboardExistings = selectAll;

// select the total user count
export const selectDashboardExistingTotal = selectTotal;
