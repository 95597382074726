import { Action } from '@ngrx/store';

export const PUBLISH_MESSAGE = '[Protocols] Publish an event bus message  ';
export const RECEIVED_MESSAGE = '[Protocols] Mark a message as read after received ';

export class PublishMessage implements Action {
    readonly type = PUBLISH_MESSAGE;
    constructor(public payload: any) {}
}
export class ReceivedMessage implements Action {
    readonly type = RECEIVED_MESSAGE;
    constructor(public payload: any) {}
}

export type EventbusChannelAction = PublishMessage | ReceivedMessage;
