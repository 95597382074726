import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as HelpActions from '../actions/help.action';
import * as fromOrganisation from '../../../../services/organisation.service';

@Injectable()
export class HelpEffects {
    constructor(private actions$: Actions, private organisationService: fromOrganisation.OrganisationService) {}

    @Effect()
    HelpGetEffect$ = this.actions$.pipe(
        ofType(HelpActions.HELP_GET),
        switchMap(() => {
            return this.organisationService.getHelpPage().pipe(
                map(response => {
                    return new HelpActions.HelpGetSucces(response);
                }),
                catchError(() => {
                    const message = 'Get help failed';
                    return of(new HelpActions.HelpGetFail({ message }));
                })
            );
        })
    );
}
