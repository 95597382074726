import * as fromActions from '../actions/protocols-existing.action';
import { Category } from '../../../../models/category.interface';
import { Protocol } from '../../../../models/protocol.interface';

export interface ProtocosExistingState {
    protocosExisting: Protocol[];
    loaded: boolean;
    loading: boolean;
}

export const initialState: ProtocosExistingState = {
    protocosExisting: [],
    loaded: false,
    loading: false
};

export function reducer(state = initialState, action: fromActions.ProtocolExistingAction): ProtocosExistingState {
    switch (action.type) {
        case fromActions.LOAD_PROTOCOLS_EXISTING: {
            return { ...state, loading: true };
        }

        case fromActions.LOAD_PROTOCOLS_EXISTING_SUCCESS: {
            const protocosExisting = action.payload;
            return { ...state, loading: false, loaded: true, protocosExisting };
        }

        case fromActions.LOAD_PROTOCOLS_EXISTING_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
    }
    return state;
}

export const getProtocosExisting = (state: ProtocosExistingState) => state.protocosExisting;
export const getProtocosExistingLoading = (state: ProtocosExistingState) => state.loading;
export const getProtocosExistingLoaded = (state: ProtocosExistingState) => state.loaded;
