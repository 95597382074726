<div class="page">
    <div class="page__content">
        <div>
            <div class="page__section -small-bottom">
                <div class="card-block wysiwyg" style="margin-bottom: -20px;">
                    <h3 id="general">General settings</h3>
                    <div>
                        <div class="row">
                            <div class="protocolSettingHeader col-md-8">{{'SETTINGS.TITLE' | translate}}:</div>
                            <div
                                id="protocol-title"
                                class="protocolSetting col-md-4"
                                [innerHTML]="protocol.title"
                            ></div>
                        </div>
                        <div class="row">
                            <div class="col-md-8 protocolSettingHeader">External url:</div>
                            <div id="protocol-type" class="protocolSetting col-md-4" [innerHTML]="protocol.externalUrl"></div>
                        </div>
                        <div class="row">
                            <div class="col-md-8 protocolSettingHeader">Type:</div>
                            <div id="protocol-type" class="protocolSetting col-md-4" [innerHTML]="protocol.type"></div>
                        </div>
                        <div class="row">
                            <div class="col-md-8 protocolSettingHeader">{{'SETTINGS.LITERATURE' | translate}}</div>
                            <ng-container *ngIf="protocol.isLiterature">
                                <div id="protocol-type" class="protocolSetting col-md-4">{{'SETTINGS.TRUE' | translate}}</div>
                            </ng-container>
                            <ng-container *ngIf="!protocol.isLiterature">
                                <div id="protocol-type" class="protocolSetting col-md-4">{{'SETTINGS.FALSE' | translate}}</div>
                            </ng-container>
                        </div>
                        <div class="row">
                            <div class="protocolSettingHeader col-md-8">{{'SETTINGS.FLOWCHART' | translate}}:</div>
                            <ng-container *ngIf="protocol.showFlowchart">
                                <div id="protocol-type" class="protocolSetting col-md-4">{{'SETTINGS.TRUE' | translate}}</div>
                            </ng-container>
                            <ng-container *ngIf="!protocol.showFlowchart">
                                <div id="protocol-type" class="protocolSetting col-md-4">{{'SETTINGS.FALSE' | translate}}</div>
                            </ng-container>
                        </div>
                        <div class="row">
                            <div class="protocolSettingHeader col-md-8">{{'SETTINGS.INTERACTIVE' | translate}}</div>
                            <ng-container *ngIf="protocol.showInteractive">
                                <div id="protocol-type" class="protocolSetting col-md-4">{{'SETTINGS.TRUE' | translate}}</div>
                            </ng-container>
                            <ng-container *ngIf="!protocol.showInteractive">
                                <div id="protocol-type" class="protocolSetting col-md-4">{{'SETTINGS.FALSE' | translate}}</div>
                            </ng-container>
                        </div>
                        <div class="row">
                            <div class="protocolSettingHeader col-md-8">Status:</div>
                            <div class="protocolSetting col-md-4" [innerHTML]="protocol.status"></div>
                        </div>
                    </div>

                    <hr class="page__divider" style="margin-bottom: 0px;"/>
                    <span class="button float-right" (click)="openHeaderForm()">
                        Change settings
                    </span>
                </div>
            </div>
        </div>

        <div class="page__section -small-bottom">
            <div class="card-block wysiwyg" style="margin-bottom: 40px;">
                <h3 id="categories">Categories</h3>
                <hr class="page__divider"/>

                <ul id="tags">
                    <li *ngFor="let tag of protocol.tags; trackBy: trackByFn">
                        {{ tag.title }}
                        <a id="remove-5" class="removeTag" style="color: #499c94; text-decoration: none;" (click)="removeTag(tag.id)">
                            <span class="icon-remove"></span>
                        </a>
                    </li>
                </ul>

                <h4>Add protocol to categories</h4>
                <ul id="possibleTags">
                    <li *ngFor="let oTag of protocol.organization_tags; trackBy: trackByFn">
                        {{ oTag.title }}
                        <a id="add-5" class="addTag" style="color: #499c94; text-decoration: none;" (click)="addTag(oTag.id)">
                            <span class="icon-plus"></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="page__section -small-bottom" *ngIf="protocol.isLiterature">
            <div class="card-block wysiwyg" style="margin-bottom: 40px;">
                <h3 id="search">Search terms for literature</h3>

                <ng-container *ngIf="protocol.search_term">
                For this protocol we use the following search term to get the latest articles from PubMed.
                <ul>
                    <li>
                        <span class="table-span protocolSetting">{{ protocol.search_term }}</span>
                        <span
                            type="button"
                            id="modalButton-search-term"
                            class="button float-right"
                            (click)="openSearchTermForm()"
                        >
                        Edit search term
                        </span>
                    </li>
                </ul>
                </ng-container>
                <ng-container *ngIf="!protocol.search_term">
                    <span
                    type="button"
                    id="modalButton-search-term"
                    class="button float-right"
                    (click)="openSearchTermForm()"
                    >
                        Add search term
                    </span>
                </ng-container>
            </div>
        </div>

        <div class="page__section -small-bottom" *ngIf="protocol.isLiterature">
            <div class="card-block wysiwyg" style="margin-bottom: 40px;">
                <h3 id="search">Inclusion criteria for literature</h3>

                <ng-container *ngIf="protocol.inclusionCriteria">
                <ul>
                    <li>
                        <span class="table-span protocolSetting" [innerHTML] = "protocol.inclusionCriteria"></span>
                        <span
                            type="button"
                            id="modalButton-inclusion-criteria"
                            class="button float-right"
                            (click)="openInclusionCriteriaForm()"
                        >
                        Edit inclusion criteria
                        </span>
                    </li>
                </ul>
                </ng-container>
                <ng-container *ngIf="!protocol.inclusion_criteria">
                    <span
                    type="button"
                    id="modalButton-inclusionCriteria"
                    class="button float-right"
                    (click)="openInclusionCriteriaForm()"
                    >
                        Add inclusion criteria
                    </span>
                </ng-container>
            </div>
        </div>

        <div class="page__section -small-bottom">
            <div class="card-block wysiwyg" style="margin-bottom: 40px;">
                <h3 id="users">User management</h3>
                <h4>{{'SETTINGS.USERS.HEADER' | translate}}</h4>
                <p>{{'SETTINGS.USERS.INTRO' | translate}}</p>
                <ng-container *ngIf="protocol.ownerlist.length">
                    {{'SETTINGS.OWNER' | translate}}
                    <ul>
                        <li *ngFor="let owner of protocol.ownerlist; trackBy: trackByFn">
                            <div class="button-group float-right" *ngIf="protocol.ownerlist.length">
                                <a
                                    class="button -secondary"
                                    role="button"
                                    style="cursor: pointer;"
                                    (click)="removeOwner(owner.id)"
                                >
                                    {{ 'REMOVE' | translate }}
                                </a>
                            </div>
                            <span style="line-height: 2.4em;">{{ owner.name }} (Owner)</span>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="protocol.protocol_status.review_board?.length">
                    {{'SETTINGS.TEAMREVIEWBOARD' | translate}}
                    <ul>
                    <li *ngFor="let owner of protocol.protocol_status.review_board; trackBy: trackByFn">
                        <span style="line-height: 2.4em;">{{ owner.name }}</span>
                    </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="protocol.reviewerlist?.length" >
                    {{'SETTINGS.PROTOCOLREVIEWBOARD' | translate}}
                    <ul>
                    <li *ngFor="let owner of protocol.reviewerlist; trackBy: trackByFn">
                        <div class="button-group float-right" *ngIf="protocol.reviewerlist.length">
                            <a
                                class="button -secondary"
                                role="button"
                                style="cursor: pointer;"
                                (click)="removeReviewer(owner.id)"
                            >
                                {{ 'REMOVE' | translate }}
                            </a>
                        </div>
                        <span style="line-height: 2.4em;">{{ owner.name }}</span>
                    </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="protocol.notificationlist?.length" >
                    {{'SETTINGS.PROTOCOLSUBSCRIBERS' | translate}}
                    <ul>
                    <li *ngFor="let owner of protocol.notificationlist; trackBy: trackByFn">
                        <div class="button-group float-right">
                            <a
                                class="button -secondary"
                                role="button"
                                style="cursor: pointer;"
                                (click)="removeSubscriber(owner.id)"
                            >
                                {{ 'REMOVE' | translate }}
                            </a>
                        </div>
                        <span style="line-height: 2.4em;">{{ owner.name }}</span>
                    </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="protocol.authorlist?.length">
                    {{'SETTINGS.AUTHORS' | translate}}
                    <ul>
                    <li *ngFor="let author of protocol.authorlist; trackBy: trackByFn">
                        <div class="button-group float-right">
                            <a
                                class="button -secondary"
                                role="button"
                                style="cursor: pointer;"
                                (click)="removeAuthor(author.id)"
                            >
                                {{'SETTINGS.REMOVEAUTHOR' | translate }}
                            </a>
                            <a
                                class="button -secondary"
                                role="button"
                                style="cursor: pointer;"
                                *ngIf="!hasRole(author, 'owner')"
                                (click)="setOwner(author.id)"
                            >
                                {{'SETTINGS.SETOWNER' | translate }}   
                            </a>
                            <a
                                class="button -secondary"
                                role="button"
                                style="cursor: pointer;"
                                *ngIf="!hasRole(author, 'reviewer')"
                                (click)="setReviewer(author.id)"
                            >
                                {{'SETTINGS.SETREVIEWER' | translate }}   
                            </a>
                            <a
                                class="button -secondary"
                                role="button"
                                style="cursor: pointer;"
                                *ngIf="!hasRole(author, 'subscriber')"
                                (click)="setSubscriber(author.id)"
                            >
                                {{'SETTINGS.USERS.ADDNOTIFIEDUSERS' | translate }}   
                            </a>
                        </div>
                        <span style="line-height: 2.4em;">{{ author.name }} (Author)</span>
                    </li>
                    </ul>
                </ng-container>

                <h3>{{'SETTINGS.ADDUSER' | translate }}</h3>
                <div class="add-modality-container">
                    <form [formGroup]="userForm">
                        
                            <input
                                type="text"
                                name="userId"
                                class="form-control"
                                data-width="fit"
                                title="Select a user"
                                formControlName="setAuthor"
                                required
                                #setAuthor
                                (keyup)="selectAuthor($event)"
                            >
                            <div *ngIf="required('setAuthor')">{{'SETTINGS.SELECT' | translate }} </div>
                            <br/>
                            <ul *ngIf="(searchResults$ | async) as users" class="list-group">
                                <li
                                    *ngFor="let user of users"
                                >
                                    {{ user.first_name }} {{ user.prefix }} {{ user.last_name }} 
                                    <span 
                                        *ngIf="!hasRole(user, 'author')"
                                        (click)="addAuthor(user)"
                                        style="cursor: pointer; font-size: 1em;"
                                        class="tag">
                                        {{'SETTINGS.USERS.ADDAUTHOR' | translate }}
                                    </span>
                                    <span 
                                        *ngIf="!hasRole(user, 'owner')"
                                        (click)="setOwner(user.id)"
                                        style="cursor: pointer; font-size: 1em;"
                                        class="tag">
                                        {{'SETTINGS.SETOWNER' | translate }}
                                    </span>
                                    <span 
                                        *ngIf="!hasRole(user, 'reviewer')"
                                        (click)="setReviewer(user.id)"
                                        style="cursor: pointer; font-size: 1em;"
                                        class="tag">
                                        {{'SETTINGS.USERS.ADDREVIEWER' | translate }}
                                    </span> 
                                    <span 
                                        *ngIf="!hasRole(user, 'subscriber')"
                                        (click)="setSubscriber(user.id)"
                                        style="cursor: pointer; font-size: 1em;"
                                        class="tag">
                                        {{'SETTINGS.USERS.ADDNOTIFIEDUSERS' | translate }}
                                    </span>
                                </li>
                            </ul>

                    </form>
                </div>
            </div>
        </div>

        <div class="page__section -small-bottom">
            <div class="card-block wysiwyg" style="margin-bottom: 40px;">
                <h3>{{'SETTINGS.DELETE' | translate }}</h3>

                {{'SETTINGS.DELETEWARNING' | translate }}
                <p class="mt-2">
                    <button
                        id="modalButton-delete"
                        type="button"
                        class="btn btn-danger"
                        (click)="deleteProtocol()"
                    >
                        {{'SETTINGS.DELETE' | translate }}
                    </button>
                </p>
            </div>
        </div>
    </div>
</div>

<div class="page__corner -top -right" style="z-index: 400; display: none;">
    <div class="button-group">
        <a href="#" class="button -primary toFlow"
        ><span class="button__icon"><span class="icon-alii-flowchart"></span></span> Flow</a
        >
        <a href="#" class="button -light toText"
        ><span class="button__icon"><span class="icon-alii-text"></span></span> Text</a
        >
    </div>
</div>
