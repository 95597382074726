import { createSelector } from '@ngrx/store';

import * as fromModelFindingsReducer from '../reducers/models-findings.reducer';
import * as fromReducers from '../reducers';

export const getModelFindingState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.modelsFindings
);

export const getModelFindingEntries = createSelector(
    getModelFindingState,
    fromModelFindingsReducer.getModelFindingEntries
);

export const getModelFindingEntriesByIda = id =>
    createSelector(
        getModelFindingEntries,
        entries => entries[id]
    );

export const getModelFindingSimplified = id =>
    createSelector(
        getModelFindingEntries,
        entries => {
            return Object.keys(entries[id]).map(ppdId => {
                const finding = entries[id][ppdId];

                const options = finding.options ? Object.values(finding.options) : [];
                const findingOptions = options.reduce((acc: [], option: any) => {
                    const isSelected = 'selected' in option && option.selected;

                    return [
                        ...acc,
                        {
                            id: option.id,
                            selected: isSelected,
                            ...((!!option.value || option.value === 0) && { value: option.value })
                        }
                    ];
                }, []);

                return { id: finding.id, options: findingOptions };
            });
        }
    );
