<div class="dashboard">
    <!--<div class="dashboard__title">
        {{ 'DASHBOARD.PATIENTS.TITLE' | translate }}
        <div class="button-group pull-right" style="font-size: 80%;">
            <a
                class="button -primary cursor-pointer"
                id="addPatient"
                (click)="handleOpenAddPatient()"
                *ngxPermissionsOnly="['addCase']"
            >
                {{ 'DASHBOARD.PATIENTS.ADD-NEW-CASE' | translate }}
            </a>
            <a class="button -light cursor-pointer ml-2" id="addExistingCase" (click)="handleOpenIncludePatient()">
                {{ 'DASHBOARD.PATIENTS.ADD-EXISTING-CASE' | translate }}
            </a>
        </div>
    </div>
    -->
    <section class="page__section -no-bottom" *ngIf="patients && patients.length">
        <div class="table-responsive">
            <table class="table -aliiTable">
                <thead>
                <tr>
                    <th class="table__text table__title table__column-40" scope="col">{{ 'CASE' | translate }}</th>
                    <th class="table__text table__title table__column-20" scope="col">{{ 'DOCUMENT' | translate }}</th>
                    <!--<th class="table__text table__column-20" scope="col">{{ 'OPTIONS' | translate }}</th>-->
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let patient of patients; trackBy: trackByFn">
                    <td class="table__text table__title table__actions">
                        <a
                            [routerLink]="['/protocols', patient.protocol.id]"
                            class="table__link"
                            [innerHTML]="patient.population.name"
                            [queryParams]="{ populationId: patient.population.id }"
                        ></a>
                    </td>
                    <td class="table__text" [innerHTML]="patient.protocol.title"></td>
                    <!-- <td class="table__actions">
                        <a style="cursor: pointer;" class="removeCase tag" (click)="handleOpenDelete(patient.id)">
                            {{ 'DELETE' | translate }}
                        </a>
                         <a
                            style="cursor: pointer;"
                            class="editCase tag"
                            (click)="handleEditPatient(patient.population.name)"
                        >
                            edit
                        </a>
                    </td> -->
                </tr>
                </tbody>
            </table>
        </div>
    </section>
</div>
<br />
