import {
    Component,
    ChangeDetectionStrategy,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    OnInit
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {AgGridAngular} from 'ag-grid-angular';
import { RenderHTML } from '../../../../../literatures/components/reviews/render-html.component';
import { EditHTML } from '../../../../../literatures/components/reviews/edit-html.component';
import * as fromService from '../../../../../../services';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-paragraph-evidence-table',
    templateUrl: './paragraph-evidence-table.component.html',
    styleUrls: ['./paragraph-evidence-table.component.scss']
})

export class ParagraphEvidenceTableComponent {
    @Input() isEditAble: boolean;
    @Input() paragraphId: string;
    @Input() sources: any[] = [];
    @Input() sourceTable: any[];
    @Input() gradeColumnDefs: any[];
    @Input() gradeRowData: any[];
    @Input() index: number;
    @Input() hasNumberedIndex: boolean;
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    constructor() {} 

    handleEventBus(action) {
        this.eventBus.emit(action);
    }

    checkSourceTable() {
        let hasSources = false
        if(this.sourceTable) {
            this.sourceTable.forEach(element => {
                if(element.rowData.length) {
                    hasSources = true
                }
            });  
        }
        return hasSources
    }

    trackByFn = (index, item) => item.id || index;
}
