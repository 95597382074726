// Search response object
export type SearchRO = Array<SearchResult>;

// Type 'category' is not yet implemented, type 'tag' is the same as type 'category'
export type SearchType =
    | 'all'
    | 'protocol'
    | 'topResults'
    | 'in this protocol'
    | 'literature'
    | 'paragraph'
    | 'category'
    | 'article'
    | 'file'
    | 'tag'
    | 'go to'
    | 'outcome';

export interface SearchResult {
    type: SearchType;
    // Filled by calling to searchTypeToText
    title: string;
    // type = 'protocol' | 'file'
    id?: string;
    // type = 'outcome' | 'paragraph' | 'in this protocol'
    protocol?: string;
    articleId?: string;
    anchorId?: string;
    // type = 'file'
    protocolId?: string;
    ppdId?: string;
    version?: string;
}

// Table for converting the search type to the appropriate text field.
const convertTable: Array<{ type: SearchType; text: string }> = [
    { type: 'protocol', text: 'document' },
    { type: 'topResults', text: 'top results' },
    { type: 'in this protocol', text: 'in this document' },
    { type: 'paragraph', text: 'paragraph' },
    { type: 'article', text: 'article' },
    { type: 'category', text: 'category' },
    { type: 'file', text: 'file' },
    { type: 'tag', text: 'tag' },
    { type: 'outcome', text: 'outcome' }
];

// Convert field type to text.
const searchTypeToText = (type: SearchType): string =>
    (convertTable.find(item => item.type === type) || { text: type }).text;

// Adds an extra 'text' field to be grouped by in the search results dropdown.
export const addTextFieldToSearchResults = (searchResults: SearchResult[]): SearchResult[] =>
    searchResults.map(result => ({ ...result, text: searchTypeToText(result.type) }));

// Convert type = 'tag' to type = 'category' (they are the same)
export const convertTypeTagToCategory = (searchResults: SearchResult[]): SearchResult[] =>
    searchResults.map(result => ({
        ...result,
        type: result.type === 'tag' ? 'category' : result.type
    }));
