import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromStore from './../../store';
import { TermsGetRO } from '../../../../models/terms.interface';

const cn = 'TermsComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit, AfterViewInit {
    @ViewChild('scroll') scrollWindow: ElementRef;

    termsForm: FormGroup;
    readTerms = false;
    showBanner = true;

    // Get terms
    termsGetLoading$: Observable<boolean>;
    termsGetLoaded$: Observable<TermsGetRO>;
    termsGetError$: Observable<string>;

    // Accept terms
    termsAcceptLoading$: Observable<boolean>;
    termsAcceptLoaded$: Observable<boolean>;
    termsAcceptError$: Observable<string>;

    constructor(
        private store: Store<fromStore.TermsFeatureState>,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        // Get terms selectors
        this.termsGetLoading$ = this.store.select(fromStore.getTermsGetLoading);
        this.termsGetLoaded$ = this.store.select(fromStore.getTermsGetLoaded);
        this.termsGetError$ = this.store.select(fromStore.getTermsGetError);

        // Accept terms selectors
        this.termsAcceptLoading$ = this.store.select(fromStore.getTermsAcceptLoading);
        this.termsAcceptLoaded$ = this.store.select(fromStore.getTermsAcceptLoaded);
        this.termsAcceptError$ = this.store.select(fromStore.getTermsAcceptError);

        // Build form
        this.termsForm = this.fb.group({
            acceptTerms: [false, [Validators.required]]
        });

        // Call get terms
        this.store.dispatch(new fromStore.TermsGet());
    }

    ngAfterViewInit() {
        // Attempt to access the scroll window every 200ms until either it is found or max time has been reached.
        const TIMEOUT = 200;
        const MAX = 5000;
        let msecs = 0;
        // TODO: Replace with RXJS timer take until which is more elegant.
        const timerId = setInterval(() => {
            if (this.scrollWindow && this.scrollWindow.nativeElement) {
                const height = this.scrollWindow.nativeElement.offsetHeight;
                this.scrollWindow.nativeElement.addEventListener('scroll', e => {
                    const el = e.target;
                    if (el.scrollHeight - el.scrollTop <= height) {
                        this.readTerms = true;
                        this.cdr.markForCheck();
                    }
                });
                clearInterval(timerId);
            } else {
                msecs += TIMEOUT;
                if (msecs >= MAX) {
                    console.warn(`${cn} Cannot find the scroll window`);
                }
            }
        }, TIMEOUT);
    }

    safeHtml(html: string) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    closeBanner() {
        this.showBanner = false;
    }

    submit() {
        this.store.dispatch(new fromStore.TermsAccept());
    }
}
