import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as GradeActions from '../actions/grade-assessment.action';
import * as ParagraphsFilesActions from '../actions/paragraphs-files.action';
import * as fromService from '../../../../services';

@Injectable()
export class ParagraphsFilesEffects {
    constructor(private actions$: Actions, private UploadService: fromService.UploadService) {}

    @Effect()
    UploadFileToBucketEffect$ = this.actions$.pipe(
        ofType(ParagraphsFilesActions.UPLOAD_FILE_TO_BUCKET),
        switchMap(action => {
            const { payload } = action as any;
            const { formData, ppdId, protocolId, title, reviewQuestion } = payload;
            return this.UploadService.uploadToBucket({ formData }).pipe(
                map(response => {
                    const { status, progress, url = null, message = '' } = response;
                    switch (status) {
                        case 'progress':
                            return new ParagraphsFilesActions.UploadFileToBucketUploading({ ppdId, progress });
                        case 'failed':
                            return new ParagraphsFilesActions.UploadFileToBucketFail({ message });
                        case 'success':
                            if (url) {
                                return new ParagraphsFilesActions.UploadFileToBucketSuccess({
                                    ppdId,
                                    protocolId,
                                    title,
                                    reviewQuestion,
                                    url: response.url
                                });
                            }
                            return new ParagraphsFilesActions.UploadFileToBucketFail({ message: 'no url defined' });
                        default:
                            return new ParagraphsFilesActions.UploadFileToBucketFail({
                                message: `unknown status='${status}'`
                            });
                    }
                }),
                catchError(error => of(new ParagraphsFilesActions.UploadFileToBucketFail(error)))
            );
        })
    );
    @Effect()
    UploadFileToBucketSuccessEffect$ = this.actions$.pipe(
        ofType(ParagraphsFilesActions.UPLOAD_FILE_TO_BUCKET_SUCCESS),
        mergeMap(action => {
            const {
                payload: { ppdId, protocolId, url, title, reviewQuestion }
            } = action as any;
            if (reviewQuestion == undefined) {
                return [new ParagraphsFilesActions.AddFileToParagraph({ ppdId, protocolId, url, title, reviewQuestion })];
            } else {
                return [new ParagraphsFilesActions.AddFileToGrade({ ppdId, protocolId, url, title, reviewQuestion })];
            }
        })
    );
    @Effect()
    AddFileToGradeEffect$ = this.actions$.pipe(
        ofType(ParagraphsFilesActions.ADD_FILE_TO_GRADE),
        switchMap(action => {
            const {
                payload: { ppdId, protocolId, url, title, reviewQuestion }
            } = action as any;
            return this.UploadService.saveUpload({ ppdId, protocolId, url, title, reviewQuestion }).pipe(
                map(
                    message =>
                        new GradeActions.LoadGrades({ppdId})
                            ),
                catchError(error => of(new ParagraphsFilesActions.AddFileToParagraphFail(error)))
            );
        })
    );


    @Effect()
    AddFileToParagraphEffect$ = this.actions$.pipe(
        ofType(ParagraphsFilesActions.ADD_FILE_TO_PARAGRAPH),
        switchMap(action => {
            const {
                payload: { ppdId, protocolId, url, title, reviewQuestion }
            } = action as any;
            return this.UploadService.saveUpload({ ppdId, protocolId, url, title, reviewQuestion }).pipe(
                map(
                    message =>
                        new ParagraphsFilesActions.AddFileToParagraphSuccess({
                            ppdId,
                            protocolId,
                            reviewQuestion,
                            url,
                            title,
                            id: message.id
                        })
                ),
                catchError(error => of(new ParagraphsFilesActions.AddFileToParagraphFail(error)))
            );
        })
    );
    @Effect()
    DeleteFileEffect$ = this.actions$.pipe(
        ofType(ParagraphsFilesActions.DELETE_FILE),
        switchMap(action => {
            const {
                payload: { ppdId, protocolId, fileId }
            } = action as any;
            return this.UploadService.deleteFile({ ppdId, protocolId, fileId }).pipe(
                map(
                    message =>
                        new ParagraphsFilesActions.DeleteFileSuccess({
                            ppdId,
                            protocolId,
                            fileId
                        })
                ),
                catchError(error => of(new ParagraphsFilesActions.DeleteFileFail(error)))
            );
        })
    );
}
