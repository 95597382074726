import { Action } from '@ngrx/store';
import { DashboardSummary } from '@alii-web/models/dashboard-summary.model';

export const LOAD_DASHBOARD_SUMMARY = '[Dashboard] Load Dashboard Summary';
export const LOAD_DASHBOARD_SUMMARY_FAIL = '[Dashboard] Load Dashboard Summary Fail';
export const LOAD_DASHBOARD_SUMMARY_SUCCESS = '[Dashboard] Load Dashboard Summary Success';

export class LoadDashboardSummary implements Action {
    readonly type = LOAD_DASHBOARD_SUMMARY;
}

export class LoadDashboardSummaryFail implements Action {
    readonly type = LOAD_DASHBOARD_SUMMARY_FAIL;
    constructor(public payload: any) {}
}

export class LoadDashboardSummarySucces implements Action {
    readonly type = LOAD_DASHBOARD_SUMMARY_SUCCESS;
    constructor(public payload: DashboardSummary) {}
}

export const LOAD_DASHBOARD_PERMISSION = '[Dashboard] Load Dashboard Permission';

export class LoadDashboardPermission implements Action {
    readonly type = LOAD_DASHBOARD_PERMISSION;
    constructor(public payload: any) {}
}

export type DashboardSummaryAction =
    | LoadDashboardSummary
    | LoadDashboardSummaryFail
    | LoadDashboardSummarySucces
    | LoadDashboardPermission;
