<div class="modal-header">
    <h4 class="modal-title text-capitalize">Edit tags</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container">
        <div class="row">
            <br>
            If you want to input more than one tag, separate tags using "//"/. For example: tag1//tag2.
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <input type="text" name="tags" formControlName="tags" class="form-control" />
                <br>
                <input class="btn btn-primary" type="submit" name="Save">
            </form>
        </div>
    </div>
</div>