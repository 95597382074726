<div class="modal-header">
    <h4 class="title">{{ 'HELP.MODAL' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()"><span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="container">
        <div class="row">
            <div class="col-md">
                <iframe src='https://alii.mojohelpdesk.com/widgets/create_request#/ticket-form/37128'
                        style='width: 100%; height: 700px; overflow: hidden; border: 0;'
                        onload="this.contentWindow.postMessage(
                        {'css': '#content_for_layout{} .section-head {display: none;} ' +
                         '.padding-for-content { padding: 0; } label {} ' +
                          '.form-control {} ' +
                          '.mojo_link_footer {display: none;} ' +
                           '.btn-success {} '},
                           '*')"
                >
                </iframe>
            </div>
        </div>
    </div>
</div>
