import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromActions from '../actions';
import * as fromService from '../../../../services';

@Injectable()
export class ModelEffects {
    constructor(private actions$: Actions, private ModelsService: fromService.ModelsService) {
    }

    @Effect()
    GetModelEffect$ = this.actions$.pipe(
        ofType(fromActions.GET_MODEL),
        switchMap(action => {
            const {
                payload: { ppdId }
            } = action as any;
            return this.ModelsService.getModel({ ppdId }).pipe(
                map(getModelRO => {
                    if (getModelRO) {
                        return new fromActions.GetModelSucces({ ppdId, getModelRO });
                    } else {
                        return new fromActions.GetModelFail({ ppdId, error: { message: 'Cannot get model' } });
                    }
                }),
                catchError(error => of(new fromActions.GetModelFail({ ppdId, error })))
            );
        })
    );
}
