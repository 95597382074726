import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { AuthService } from '../../services';

const cn = 'HeaderComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    loggedIn: boolean;

    subscriptions: Subscription[] = [];

    constructor(public authService: AuthService, private router: Router, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.loggedIn = false;
        this.subscriptions.push(
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe((event: NavigationEnd) => {
                    this.loggedIn = this.authService.isLoggedIn();
                    this.cdr.markForCheck();
                })
        );
        this.authService.authHash$.subscribe(authHash => {
            this.loggedIn = !!authHash;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }
}
