import { Component, Input, OnInit } from '@angular/core';
import { ProtocolFile } from '../../../../../models/protocol.interface';

@Component({
  selector: 'alii-web-protocol-files',
  templateUrl: './protocol-files.component.html',
  styleUrls: ['./protocol-files.component.scss']
})
export class ProtocolFilesComponent implements OnInit {
  @Input()
  files: ProtocolFile[];

  constructor() { }

  ngOnInit() {
  }

}
