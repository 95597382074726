<div class="row" *ngIf="actionsShown">
    <div class="pull-right col-md" >
        <span class="pull-right button small -primary m-2" (click)="deleteSelectedRows()">delete row</span> 
        <span class="pull-right button small -primary m-2" (click)="openArticle()">open article</span>
    </div>
</div>
<div class="row" *ngIf="!actionsShown && isEditAble">
    <div class="pull-right col-md" >
        <span class="pull-right button small -primary m-2" (click)="onBtnExport()">download data</span> 
    </div>
</div>


<ag-grid-angular
    #agGrid
    style="width: 100%"
    class="ag-theme-alpine"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    singleClickEdit="true"
    suppressRowTransform="true"
    domLayout='autoHeight'
    rowSelection='single'   
    (eventBus)="handleEventBus($event)"
    (gridReady)="onGridReady($event)"
    [frameworkComponents]="frameworkComponents"
    (cellClicked)="showActions()"
    >
</ag-grid-angular>

