import { OrganisationComponent } from './organisation/organisation.component';
import { AddUserComponent } from './add-user/add-user.component';
import { WhoIsWhoComponent } from './whoiswho/whoiswho.component';
import { UserGroupComponent } from './usergroup/usergroup.component';
import { UserGroupEditComponent } from './usergroup/usergroup-edit.component';
import { TimelineComponent } from './timeline/timeline.component';
import { ManageMessagesComponent } from './manage-messages/manage-messages.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ManageCalendarComponent } from './manage-calendar/manage-calendar.component';

export const containers = [
    OrganisationComponent,
    AddUserComponent,
    WhoIsWhoComponent,
    UserGroupComponent,
    UserGroupEditComponent,
    ManageMessagesComponent,
    CalendarComponent,
    ManageCalendarComponent,
    TimelineComponent
];

export * from './organisation/organisation.component';
export * from './add-user/add-user.component';
export * from './whoiswho/whoiswho.component';
export * from './usergroup/usergroup.component';
export * from './usergroup/usergroup-edit.component';
export * from './timeline/timeline.component';
export * from './manage-messages/manage-messages.component';
export * from './calendar/calendar.component';
export * from './manage-calendar/manage-calendar.component';
