import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from 'apps/web/src/app/services';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UploadService } from '../../../../services';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'upload-avatar-modal',
    templateUrl: './upload-avatar.component.html'
})
export class UploadAvatarModalComponent implements OnInit {
    form: FormGroup;
    uploadResponse: any = { status: '', message: '', filePath: '', error: '' };
    file: any;
    @Input()
    userId: string;

    @Output()
    eventBus?: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private uploadService: UploadService,
        private profileService: ProfileService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            file: ['']
        });
    }
    onFileChange(event) {
        if (event.target.files.length > 0) {
            this.file = event.target.files[0];
            this.form.get('file').setValue(this.file);
        }
    }

    onSubmit() {
        const formData = new FormData();
        formData.append('file', this.form.get('file').value);

        this.uploadService
            .uploadToBucket({ formData })
            .pipe(
                map(response => {
                    const { url = null } = response;
                    if (url) {
                        return this.setAvatarUrl(response.url);
                    }
                })
            )
            .subscribe();
    }

    setAvatarUrl(url) {
        this.profileService.updateUser({ image_url: url, userId: this.userId }).subscribe();
        this.activeModal.close({ url });
    }
}
