import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';

import * as fromStore from './../../store';
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'whoiswho-container',
    templateUrl: './whoiswho.component.html'
})
export class WhoIsWhoComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
