import { createSelector } from '@ngrx/store';

import * as fromIndexReducers from '../reducers';
import * as fromReducer from '../reducers/pubmed.reducer';

const getPubmedsState = createSelector(
    fromIndexReducers.getLiteraturesFeatureState,
    (state: fromIndexReducers.LiteraturesFeatureState) => state.pubmeds
);
export const getPubmeds = createSelector(
    getPubmedsState,
    fromReducer.LoadPubmeds
);
