import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';

import { EditDashboardModalComponent, RemoveDataModalComponent, EditTitleModalComponent } from '../../entry-components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardDetail } from '../../../../models/dashboard-detail.model';
import { DashboardCategory } from '../../../../models/dashboard-category.model';
import * as fromStore from '../../store';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Options } from 'sortablejs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dashboard-edit',
    templateUrl: './dashboard-edit.component.html'
})
export class DashboardEditComponent implements OnInit {
    @Input()
    dashboard: DashboardDetail;

    @Input()
    categories: DashboardCategory[];

    @Input()
    //allCategories: DashboardCategory[]; todo: make prod build compile with this line
    allCategories: any;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    items;

    options: Options = {
        onUpdate: (event: any) => {
            const payload = {
                dashboardId: this.dashboard.id,
                position: event.newIndex,
                tagId: event.item.id
            };
            this.store.dispatch(new fromStore.SortDashboardTag({ dashboardTag: payload }));
        }
    };

    addTagForm: FormGroup;

    constructor(
        private modalService: NgbModal,
        private fb: FormBuilder,
        private store: Store<fromStore.DashboardState>
    ) {}

    ngOnInit() {
        this.addTagForm = this.fb.group({
            tagId: ['', Validators.required]
        });
    }

    onClickEditDashboard() {
        const modalRef = this.modalService.open(EditDashboardModalComponent);
        modalRef.result.then(
            result => {
                const action = {
                    type: 'submit',
                    payload: {
                        id: this.dashboard.id,
                        name: result.name
                    }
                };
                this.eventBus.emit(action);
            },
            reason => {}
        );
        modalRef.componentInstance.name = this.dashboard.name;
    }

    onClickDeleteDashboard() {
        const modalRef = this.modalService.open(RemoveDataModalComponent);
        modalRef.result.then(
            result => {
                const action = {
                    type: 'delete',
                    payload: {
                        id: this.dashboard.id
                    }
                };
                this.eventBus.emit(action);
            },
            reason => {}
        );
        modalRef.componentInstance.headerTitle = 'Remove dashboard';
        modalRef.componentInstance.bodyText = 'Are you sure you want to delete this dashboard?';
    }

    onClickCreateDashboardTag() {
        this.handleOpenTitleModal();
    }

    handleOpenTitleModal() {
        const modalRef = this.modalService.open(EditTitleModalComponent);

        modalRef.componentInstance.headerTitle = 'Create new category';
        modalRef.componentInstance.titleValue = '';
        modalRef.componentInstance.titleLabel = 'Category';

        modalRef.result.then(
            result => {
                const { title } = result;
                const payload = { dashboardId: this.dashboard.id, title };
                const action = { type: 'handleSubmitCreateDashboardTag', payload };
                this.eventBus.emit(action);
            },
            reason => {}
        );
    }

    onClickRemoveDashboardTag(tagId) {
        const payload = { dashboardId: this.dashboard.id, tagId };
        const action = { type: 'handleRemoveDashboardTag', payload };
        this.eventBus.emit(action);
    }

    onSubmitAddDashboardTag() {
        if (this.addTagForm.valid) {
            const payload = { ...this.addTagForm.value, dashboardId: this.dashboard.id };
            const action = {
                type: 'handleSubmitAddDashboardTag',
                payload
            };
            this.eventBus.emit(action);
        }
    }

    trackByFn = (index, item) => item.id || index;
}
