import { Action } from '@ngrx/store';
import { Organization } from '../../../../models/organization.interface';

export const LOAD_ORGANIZATION = '[Organisation] Load Organization';
export const LOAD_ORGANIZATION_FAIL = '[Organisation] Load Organization Fail';
export const LOAD_ORGANIZATION_SUCCESS = '[Organisation] Load Organization Success';

export class LoadOrganization implements Action {
    readonly type = LOAD_ORGANIZATION;
}

export class LoadOrganizationFail implements Action {
    readonly type = LOAD_ORGANIZATION_FAIL;
    constructor(public payload: any) {}
}

export class LoadOrganizationSuccess implements Action {
    readonly type = LOAD_ORGANIZATION_SUCCESS;
    constructor(public payload: Organization) {}
}

export const UPDATE_ORGANIZATION = '[Organisation] Update Organization';
export const UPDATE_ORGANIZATION_FAIL = '[Organisation] Update Organization Fail';
export const UPDATE_ORGANIZATION_SUCCESS = '[Organisation] Update Organization Success';

export class UpdateOrganization implements Action {
    readonly type = UPDATE_ORGANIZATION;
    constructor(public payload: any) {}
}

export class UpdateOrganizationFail implements Action {
    readonly type = UPDATE_ORGANIZATION_FAIL;
    constructor(public payload: any) {}
}

export class UpdateOrganizationSuccess implements Action {
    readonly type = UPDATE_ORGANIZATION_SUCCESS;
    constructor(public payload: Organization) {}
}

export type OrganizationAction = 
    LoadOrganization 
    | LoadOrganizationFail 
    | LoadOrganizationSuccess
    | UpdateOrganization 
    | UpdateOrganizationFail 
    | UpdateOrganizationSuccess;
