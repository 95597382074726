import { Action } from '@ngrx/store';

export const ADD_REFERENCES_TO_PARAGRAPH = '[Protocols] Add references to paragraph';
export const ADD_REFERENCES_TO_PARAGRAPH_SUCCESS = '[Protocols] Add references to paragraph success';
export const ADD_REFERENCES_TO_PARAGRAPH_FAIL = '[Protocols] Add references to paragraph fail';

export class AddReferenceToParagraph implements Action {
    readonly type = ADD_REFERENCES_TO_PARAGRAPH;
    constructor(public payload: any) {}
}
export class AddReferenceToParagraphSuccess implements Action {
    readonly type = ADD_REFERENCES_TO_PARAGRAPH_SUCCESS;
    constructor(public payload: any) {}
}
export class AddReferenceToParagraphFail implements Action {
    readonly type = ADD_REFERENCES_TO_PARAGRAPH_FAIL;
    constructor(public payload: any) {}
}



export const REMOVE_REFERENCES_FROM_PARAGRAPH = '[Protocols] Remove references from paragraph';
export const REMOVE_REFERENCES_FROM_PARAGRAPH_SUCCESS = '[Protocols] Remove references from paragraph success';
export const REMOVE_REFERENCES_FROM_PARAGRAPH_FAIL = '[Protocols] Remove references from paragraph fail';

export class RemoveReferenceFromParagraph implements Action {
    readonly type = REMOVE_REFERENCES_FROM_PARAGRAPH;
    constructor(public payload: any) {}
}
export class RemoveReferenceFromParagraphSuccess implements Action {
    readonly type = REMOVE_REFERENCES_FROM_PARAGRAPH_SUCCESS;
    constructor(public payload: any) {}
}
export class RemoveReferenceFromParagraphFail implements Action {
    readonly type = REMOVE_REFERENCES_FROM_PARAGRAPH_FAIL;
    constructor(public payload: any) {}
}

export const EDIT_REFERENCE_FOR_PARAGRAPH = '[Protocols] Edit reference for paragraph';
export const EDIT_REFERENCE_FOR_PARAGRAPH_SUCCESS = '[Protocols] Edit reference forparagraph success';
export const EDIT_REFERENCE_FOR_PARAGRAPH_FAIL = '[Protocols] Edit reference for paragraph fail';

export class EditReferenceForParagraph implements Action {
    readonly type = EDIT_REFERENCE_FOR_PARAGRAPH;
    constructor(public payload: any) {}
}
export class EditReferenceForParagraphSuccess implements Action {
    readonly type = EDIT_REFERENCE_FOR_PARAGRAPH_SUCCESS;
    constructor(public payload: any) {}
}
export class EditReferenceForParagraphFail implements Action {
    readonly type = EDIT_REFERENCE_FOR_PARAGRAPH_FAIL;
    constructor(public payload: any) {}
}



export const ADD_NON_PUBMED_REFERENCES = '[Protocols] Add Non Pubmed references to paragraph';
export const ADD_NON_PUBMED_REFERENCES_SUCCESS = '[Protocols] Add Non Pubmed references to paragraph success';
export const ADD_NON_PUBMED_REFERENCES_FAIL = '[Protocols] Add Non Pubmed references to paragraph fail';

export class AddNonPubmedReference implements Action {
    readonly type = ADD_NON_PUBMED_REFERENCES;
    constructor(public payload: any) {}
}
export class AddNonPubmedReferenceSuccess implements Action {
    readonly type = ADD_NON_PUBMED_REFERENCES_SUCCESS;
    constructor(public payload: any) {}
}
export class AddNonPubmedReferenceFail implements Action {
    readonly type = ADD_NON_PUBMED_REFERENCES_FAIL;
    constructor(public payload: any) {}
}

export type ParagraphsReferencesAction =
    | AddReferenceToParagraph
    | AddReferenceToParagraphSuccess
    | AddReferenceToParagraphFail
    | RemoveReferenceFromParagraph
    | RemoveReferenceFromParagraphSuccess
    | RemoveReferenceFromParagraphFail
    | EditReferenceForParagraph
    | EditReferenceForParagraphSuccess
    | EditReferenceForParagraphFail
    | AddNonPubmedReference
    | AddNonPubmedReferenceSuccess
    | AddNonPubmedReferenceFail;
