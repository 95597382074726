import * as fromDashboardCategoriesAction from '../actions/dashboard-categories.action';

export interface DashboardCategoriesState {
    entries: any;
    loaded: boolean;
    loading: boolean;
}

export const initialState: DashboardCategoriesState = {
    entries: [],
    loaded: false,
    loading: false
};

export function reducer(
    state = initialState,
    action: fromDashboardCategoriesAction.DashboardCategoryAction
): DashboardCategoriesState {
    switch (action.type) {
        case fromDashboardCategoriesAction.PROT_LOAD_DASHBOARD_CATEGORIES: {
            return {
                ...state,
                loading: true
            };
        }

        case fromDashboardCategoriesAction.PROT_LOAD_DASHBOARD_CATEGORIES_SUCCESS: {
            const { categories } = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                entries: categories
            };
        }

        case fromDashboardCategoriesAction.PROT_LOAD_DASHBOARD_CATEGORIES_FAIL: {
            return {
                ...state,
                loading: false,
                loaded: false
            };
        }
    }

    return state;
}

export const getDashboardCategoriesLoading = (state: DashboardCategoriesState) => state.loading;
export const getDashboardCategoriesLoaded = (state: DashboardCategoriesState) => state.loaded;
export const getDashboardCategoriesEntry = (state: DashboardCategoriesState) => state.entries;
