import { Action } from '@ngrx/store';
import { SSO } from '../../../../models/sso.interface';

export const LOGIN = '[Login] Login';
export const LOGIN_FAIL = '[Login] Login Fail';
export const LOGIN_SUCCESS = '[Login] Login Success';

export class Login implements Action {
    readonly type = LOGIN;
    constructor(public payload: { username: string; password: string; returnUrl: string }) {}
}

export class LoginFail implements Action {
    readonly type = LOGIN_FAIL;
    constructor(public payload: { message: string }) {}
}

export class LoginSucces implements Action {
    readonly type = LOGIN_SUCCESS;
    constructor() {}
}
export const LOAD_SSO = '[Login] Load SSO Login';
export const LOAD_SSO_FAIL = '[Login] Load SSO Login Fail';
export const LOAD_SSO_SUCCESS = '[Login] Load SSO Login Success';

export class LoadSSO implements Action {
    readonly type = LOAD_SSO;
    constructor() {}
}

export class LoadSSOFail implements Action {
    readonly type = LOAD_SSO_FAIL;
    constructor(public payload: any) {}
}

export class LoadSSOSucces implements Action {
    readonly type = LOAD_SSO_SUCCESS;
    constructor(public payload: SSO[]) {}
}

export type LoginAction = Login | LoginFail | LoginSucces | LoadSSO | LoadSSOFail | LoadSSOSucces;
