import { createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';
import * as fromProtocolsExisting from '../reducers/protocols-existing.reducer';

export const getProtocolsExistingState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.protocolsExisting
);

export const getProtocosExisting = createSelector(
    getProtocolsExistingState,
    fromProtocolsExisting.getProtocosExisting
);

export const getProtocosExistingLoaded = createSelector(
    getProtocolsExistingState,
    fromProtocolsExisting.getProtocosExistingLoaded
);

export const getProtocosExistingLoading = createSelector(
    getProtocolsExistingState,
    fromProtocolsExisting.getProtocosExistingLoading
);
