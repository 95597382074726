import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Protocol } from '../../../../models/protocol.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-patient-include-modal',
    templateUrl: './patient-include-modal.component.html'
})
export class PatientIncludeModalComponent implements OnInit {
    cases: Array<{ name: string; id: string }>;
    protocols: Protocol[];
    form: FormGroup;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            caseId: ['', Validators.required],
            protocolId: ['', Validators.required]
        });
    }

    handleSubmit() {
        if (this.form.valid) {
            const data = {
                ...this.form.value
            };
            this.activeModal.close(data);
        }
    }
}
