import { Component, ChangeDetectionStrategy, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../../../../models/field.interface';
import { FieldConfig } from '../../../../../models/field-config.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'form-input',
    template: `
        <div class="dynamic-field form-input" [formGroup]="group" class="form-group">
            <label>{{ config.label }}</label>
            <div *ngIf="config.info" class="field-info">{{ config.info }}</div>
            <input
                type="text"
                class="form-control"
                [attr.placeholder]="config.placeholder"
                [formControlName]="getControlName(config)"
            />
        </div>
    `
})
export class FormInputComponent implements Field {
    config: FieldConfig;
    group: FormGroup;
    
    getControlName(config) {
        if (config.questionId)
            return config.questionId
        else {
            return config.name
        }
    }
}
