<div class="modal-header">
    <h4 class="modal-title text-capitalize">{{data.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row m-4">
        <div class="wysiwyg" width="100%" style="overflow: scroll;" [innerHTML]="data.text"></div>
    </div>
    <div *ngIf="loading" id="loadingContent" class="row m-2">
        <div class="spinner">
            <div class="dot1"></div>
            <div class="dot2"></div>
        </div>
        <div class="text-center">
            {{lang.wait}}
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-info text-capitalize" type="submit" (click)="activeModal.close()">{{lang.back}}</button>
</div>
