import { Injectable, ChangeDetectorRef } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { LanguageService } from '../../../../services/language.service';
import { ThemingService } from '../../../../services/theming.service';
import { Language } from '../../../../models/language.model';
import * as LoginActions from '../actions/login.action';
import * as fromAuth from '../../../../services/auth.service';
import { of } from 'rxjs';

@Injectable()
export class LoginEffects {
    constructor(private actions$: Actions, 
        private authService: fromAuth.AuthService, 
        private languageService: LanguageService,
        private themingService: ThemingService
        ) {}

    @Effect()
    LoginEffect$ = this.actions$.pipe(
        ofType(LoginActions.LOGIN),
        switchMap(action => {
            const {
                payload: { username, password, returnUrl }
            } = action as any;
            return this.authService.loginv2(username, password).pipe(
                map(response => {
                    const { errorCode, message } = response;

                    if (errorCode) {
                        return new LoginActions.LoginFail({
                            message: message || 'You have entered an invalid username or password.'
                        });
                    }

                    const {
                        user: { authhash, user_id, language, theme }
                    } = response;

                    this.authService.setUserId(user_id);
                    this.authService.setAuthHash(authhash);
                    localStorage.setItem('language', language.toLowerCase())
                    this.themingService.setTheme(theme)

                    this.authService.redirectAfterLogin(returnUrl);

                    return new LoginActions.LoginSucces();
                }),
                catchError(() => {
                    const message = 'Login failed';
                    return of(new LoginActions.LoginFail({ message }));
                })
            );
        })
    );

    @Effect()
    loadSSOEffect$ = this.actions$.pipe(
        ofType(LoginActions.LOAD_SSO),
        switchMap(() => {
            return this.authService.getSSO().pipe(
                map(sso => new LoginActions.LoadSSOSucces(sso)),
                catchError(error => of(new LoginActions.LoadSSOFail(error)))
            );
        })
    );
}
