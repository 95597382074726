<div class="modal-header">
    <h4 class="modal-title">Paragraph Ordering</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container">
        <div class="list-group" [sortablejs]="getParChildren(paragraphs, 0)" [sortablejsOptions]="options">
            <div
                type="button"
                class="list-group-item list-group-item-action mt-2"
                *ngFor="let paragraph of paragraphs; trackBy: trackByFn"
                [attr.data-id]="paragraph.id"
                [attr.data-parentId]="0"
            >
                {{ paragraph['protocol_paragraph.title'] }}

                <div
                    class="list-group"
                    [sortablejs]="getParChildren(paragraph.children, paragraph.id)"
                    [sortablejsOptions]="getOptionLev2()"
                    [attr.data-id]="paragraph.id"
                >
                    <div
                        *ngFor="let child of paragraph.children; trackBy: trackByFn"
                        type="button"
                        class="list-group-item list-group-item-action mt-2"
                        [attr.data-id]="child.id"
                        [attr.data-parentId]="paragraph.id"
                    >
                        {{ child['protocol_paragraph.title'] }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
