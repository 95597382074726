import * as fromHelp from '../actions/help.action';
import { HelpGetRO } from '../../../../models/help.interface';

export interface HelpState {
    loaded: HelpGetRO;
    loading: boolean;
    error: string;
}

export const initialState: HelpState = {
    loaded: null,
    loading: false,
    error: null
};

export function reducer(state = initialState, action: fromHelp.HelpAction): HelpState {
    switch (action.type) {
        case fromHelp.HELP_GET: {
            return {
                ...state,
                loaded: null,
                loading: true,
                error: null
            };
        }

        case fromHelp.HELP_GET_SUCCESS: {
            return {
                ...state,
                loaded: action.payload,
                loading: false,
                error: null
            };
        }

        case fromHelp.HELP_GET_FAIL: {
            const {
                payload: { message }
            } = action as any;

            return {
                ...state,
                loaded: null,
                loading: false,
                error: message
            };
        }
    }
    return state;
}

export const getHelpLoading = (state: HelpState) => state.loading;
export const getHelpLoaded = (state: HelpState) => state.loaded;
export const getHelpError = (state: HelpState) => state.error;
