<div style="width: 100%">
    <div class="dialogue-wrapper" #cardList>
        <ng-container *ngFor="let card of cardsShown">   
            <div class="dialogue" [id]="'card-' + card.id">
                <div class="dialogue-header"
                    [ngClass]="hasOptions(card) ? 'orangeHeader' : 'greenHeader'"
                    (click)="openParagraph(card.paragraphId)"
                    >
                    <h1 [innerHTML]="card.title"></h1>
                </div>

                <div class="dialogue-content" 
                    *ngIf=" card.content || card.contentLink" 
                    >
                    <p [innerHTML]="card.content">
                        
                    </p>
                    <div
                        class="button"
                        style="text-align: center;"
                        *ngIf="card.contentLink"
                        [routerLink]="'/protocols/' + card.contentLink">Open Document
                    </div>
                </div>
            </div>
            <div
                    *ngIf="card.options.length > 0"
                    class="dialogue-buttons">
                    <ng-container *ngIf="card.options.length === 1 && card.options[0].target">
                        <span
                        (click)="scrollToNextCard(card.options[0].target)"
                        ><i class="icon-chevron-down"></i></span>
                    </ng-container>
                    <ng-container *ngIf="card.options.length > 1">
                        <ng-container *ngFor="let option of card.options">
                            
                            <div
                                [ngClass]="{active: isActive(option.target)}"
                                class="button cardAction"
                                *ngIf="option.target"
                                (click)="addCard(option.target, card.options)"
                                [innerHTML]="option.targetTitle || option.title">
                            </div>

                            <div
                                class="button cardAction"
                                *ngIf="option.action"
                                (click)="executeAction(option.action)"
                                [innerHTML]="option.targetTitle || option.title">
                        </div>
                        </ng-container>
                    </ng-container>
                </div>
        </ng-container>
    </div>
</div>