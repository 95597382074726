import * as fromActions from '../actions/protocol-categories.action';
import { Category } from '../../../../models/category.interface';

export interface ProtocolCategoriesState {
    protocolCategories: Category[];
    loaded: boolean;
    loading: boolean;
}

export const initialState: ProtocolCategoriesState = {
    protocolCategories: [],
    loaded: false,
    loading: false
};

export function reducer(state = initialState, action: fromActions.ProtocolCategoriesAction): ProtocolCategoriesState {
    switch (action.type) {
        case fromActions.LOAD_PROTOCOL_CATEGORIES: {
            return { ...state, loading: true };
        }

        case fromActions.LOAD_PROTOCOL_CATEGORIES_SUCCESS: {
            const protocolCategories = action.payload;

            return { ...state, loading: false, loaded: true, protocolCategories };
        }

        case fromActions.LOAD_PROTOCOL_CATEGORIES_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
    }
    return state;
}

export const getProtocolCategories = (state: ProtocolCategoriesState) => state.protocolCategories;
export const getProtocolCategoriesLoading = (state: ProtocolCategoriesState) => state.loading;
export const getProtocolCategoriesLoaded = (state: ProtocolCategoriesState) => state.loaded;
