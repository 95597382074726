import { Component, ChangeDetectionStrategy, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../../../../models/field.interface';
import { FieldConfig } from '../../../../../models/field-config.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'form-hidden',
    template: `
        <div class="dynamic-field form-input" [formGroup]="group" class="form-group">
            <input type="hidden" class="form-control" [formControlName]="config.name" [value]="config.value" />
        </div>
    `
})
export class FormHiddenComponent implements Field {
    config: FieldConfig;
    group: FormGroup;
}
