import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './modules/auth/auth.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'dashboard',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'help',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule)
    },
    {
        path: 'home',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/starting-page/starting-page.module').then(m => m.StartingPageModule)
    },
    {
        path: 'profile',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
    },
    {
        path: 'profile/:userId',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
    },
    {
        path: 'team/:userId',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
    },
    {
        path: 'protocol',
        redirectTo: 'protocols'
    },
    {
        path: '',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/organisation/organisation.module').then(m => m.OrganisationModule)
    },
    {
        path: 'protocols',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/protocols/protocols.module').then(m => m.ProtocolsModule)
    },
    {
        path: 'protocols/:protocolId/literatures',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/literatures/literature.module').then(m => m.LiteraturesModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule)
    },
    {
        path: 'search',
        loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule)
    },
    {
        path: 'shop',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/shop/shop.module').then(m => m.ShopModule)
    },
    {
        path: 'terms',
        loadChildren: () => import('./modules/terms/terms.module').then(m => m.TermsModule)
    },
    {
        path: 'user/terms',
        redirectTo: 'terms'
    },
    {
        path: '**',
        loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
