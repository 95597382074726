<div class="row">
    <div class="button-group -borderless -toolbar">
        <i
            *ngIf="!comments.length"
            class="button is-empty -nopaddingLeft cursor-pointer"
            (click)="toggleCollapsed('isCommentsCollapsed')"
        >
            <span class="icon-alii-message-square"></span> +
        </i>
        <a
            *ngIf="comments.length"
            class="button is-empty -nopaddingLeft collapsed cursor-pointer"
            data-toggle="collapse"
            aria-expanded="false"
            (click)="toggleCollapsed('isCommentsCollapsed')"
        >
            <span class="icon-alii-message-square"></span> {{'FOOTER.COMMENTS' | translate }} ({{ comments.length }})
        </a>
        <a
            *ngIf="keyQuestionId"
            class="button is-empty -nopaddingLeft collapsed cursor-pointer"
            [ngClass]="{'warning': paragraph.updateKeyQuestion}"
            placement="right"
            [disableTooltip]= "!paragraph.updateKeyQuestion"
            (click)="openOutcomeTreeview($event, paragraph)"
        >
            <span class="icon-alii-text"></span> {{'FOOTER.KEYQUESTION' | translate }}
            <span *ngIf="paragraph.updateKeyQuestion">
                !
            </span>
        </a>
        <a 
            *ngIf="filesPar.length"
            class="button is-empty -nopaddingLeft cursor-pointer"
            (click)="toggleCollapsed('isFilesCollapsed')"
        >
            <span *ngIf="isUploading()" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            <span class="icon-alii-file"></span> {{'FOOTER.FILES' | translate }} ({{ filesPar.length }})
        </a>

        <a
            *ngIf="getSources()"
            class="button is-empty -nopaddingLeft cursor-pointer"
            (click)="toggleCollapsed('isSourcesCollapsed')"
        >
            <ng-container *ngIf="sourceInfo && sourceInfo.type == 'footnote'"><span class="icon-alii-book"></span> {{'FOOTER.REFERENCES' | translate }}  ({{ getSourceCount() }})</ng-container>
            <ng-container *ngIf="sourceInfo && sourceInfo.type == 'articleConclusion'"><span class="icon-alii-book"></span> {{'LITERATURE.SUMMARYOFFINDINGS' | translate }}</ng-container>

        </a>
    </div>
</div>
<div class="row">
    <div class="clearfix">
        <div id="commentsCollapsed" [ngbCollapse]="!isCommentsCollapsed">
            <div class="">
                <div class="row">
                    <ng-container *ngFor="let comment of comments; trackBy: trackByFn">
                        <div class="row w-100 comment">
                            <div class="showComment wysiwyg col-md-10">
                                <strong>{{ comment.user_name }}</strong> : {{ comment.review_details }}
                                <ng-container *ngIf="comment.replies.length">
                                    <h6>{{'COMMENT.REPLIES' | translate}}</h6>
                                </ng-container>
                                <div class="showReply" *ngFor="let reply of comment.replies">
                                    <strong>{{ reply.user_name }}</strong> : {{ reply.review_details }}
                                </div>
                            </div>
                            <div class="commentActions wysiwyg col-md-2">
                                <span
                                    class="button pull-right rowButton"
                                    (click)="handleMarkComment(comment, 'done')"
                                    *ngIf="comment.status === 'open'"
                                >
                                    {{'COMMENT.MARKASDONE' | translate}}
                                </span>
                                
                                <span  class="button pull-right doneButton" *ngIf="comment.status === 'done'">
                                    {{'COMMENT.DONE' | translate}}
                                </span>


                                <span
                                    class="pull-right reopenButton button"
                                    *ngIf="comment.status === 'done'"
                                    (click)="handleMarkComment(comment, 'undone')"
                                >
                                    {{'COMMENT.REOPEN' | translate}}
                                </span>
                            </div>
                        </div>
                        <span
                            (click)="handleOpenReplyCommentModal(comment)"
                            class="rowButton button pull-right"
                        >
                            {{'COMMENT.REPLY' | translate}}
                        </span>
                        <hr class="w-100" />
                    </ng-container>

                    <a (click)="handleOpenCommentAddModal()" role="button" class="btn btn-info"> Add comment </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="collapse mt-2" [ngbCollapse]="!isFilesCollapsed">
        <div class="dashboard">
            <div class="dashboard-items">
                <article class="card mb-2" *ngFor="let file of filesPar; trackBy: trackByFn">
                    <header class="card__header">
                        <button type="button" class="pull-right" (click)="onClickDeleteFile(file)">
                            <i class="fa fa-trash-o"></i>
                        </button>
                        <span class="card__header-heading">
                            <a href="{{ file.file_url }}" class="card__header__link" target="_blank" rel="noopener">
                                {{ file.title }}
                            </a>
                        </span>
                        <div class="card__main-inner">
                            <a
                                *ngIf="isSupportedImage(file.file_url)"
                                href="{{ file.file_url }}"
                                target="_blank"
                                rel="noopener"
                            >
                                <img src="{{ file.file_url }}" class="img-fluid" alt="{{ file.title }}" />
                            </a>
                        </div>
                    </header>
                </article>
            </div>
        </div>
    </div>
</div>

<div class="container collapse mt-2" [ngbCollapse]="!isSourcesCollapsed">
    <div class="row">
        <div class="row" *ngIf="!actionsShown">
            <div class="pull-right col-md" >
                <span class="pull-right button small -primary m-2" (click)="onBtnExport()">download data</span> 
            </div>
        </div>

        <div class="row" *ngIf="actionsShown">
            <div class="pull-right col-md" >
                <span class="pull-right button small -primary m-2" (click)="deleteSelectedRows()">delete row</span> 
                <span class="pull-right button small -primary m-2" (click)="openArticle()">open article</span>
            </div>
        </div>
        <ng-container *ngFor="let sourceData of getSourceTable()">
            <ag-grid-angular
                #agGrid
                style="width: 100%"
                class="ag-theme-alpine"
                [rowData]="sourceData.rowData"
                [columnDefs]="sourceData.columnDefs"
                rowSelection='single'
                domLayout='autoHeight'
                [frameworkComponents]="frameworkComponents"
                singleClickEdit="true" 
                (eventBus)="handleEventBus($event)"
                [defaultColDef]="defaultColDef"
                (cellClicked)="showActions()"
                >
            </ag-grid-angular>
        </ng-container>

        <div *ngIf="getReferencedArticles()?.length > 0">
            <h4>{{ 'LITERATURE.SUPPORTING_ARTICLES' | translate }}</h4>
        </div>        
        <ng-container *ngFor="let referencedArticle of getReferencedArticles()">
            <ag-grid-angular
                #agGrid
                style="width: 100%"
                class="ag-theme-alpine"
                [rowData]="referencedArticle.rowData"
                [columnDefs]="referencedArticle.columnDefs"
                rowSelection='single'
                domLayout='autoHeight'
                [frameworkComponents]="frameworkComponents"
                singleClickEdit="true" 
                (eventBus)="handleEventBus($event)"
                [defaultColDef]="defaultColDef"
                (cellClicked)="showActions()"
                >
            </ag-grid-angular>
        </ng-container>
    </div>

    <div class="row" *ngIf="gradeAssessmentPar && gradeAssessmentPar.gradeRowData && gradeAssessmentPar.gradeRowData.length">
        <h4>{{ 'LITERATURE.PARAGRAPH.GRADE' | translate }}</h4>
        
        <ag-grid-angular
            *ngIf="gradeAssessmentPar.gradeRowData && gradeAssessmentPar.gradeRowData.length"
            #agGridGrade
            style="width: 100%"
            class="ag-theme-alpine"
            [rowData]="gradeAssessmentPar.gradeRowData"
            [columnDefs]="gradeAssessmentPar.gradeColumnDefs"
            domLayout='autoHeight'
            [frameworkComponents]="frameworkComponents"
            (cellClicked)="onCellClicked($event)"
            >
        </ag-grid-angular>
    </div>
</div>
