import { Pubmed } from '../../../../models/pubmed.interface';
import { PubmedActions, PubmedActionTypes } from '../actions/pubmed.actions';

export interface PubmedState {
    query: string;
    results: Pubmed[];
    ppdId: string;
    loading: boolean;
    loaded: boolean;
}

export const initialState: PubmedState = { query: '', results: [], ppdId: null, loading: false, loaded: false };

export function reducer(state = initialState, action: PubmedActions): PubmedState {
    switch (action.type) {
        case PubmedActionTypes.LoadPubmeds: {
            const { query } = action.payload;
            return { ...state, loading: true, loaded: false, query, results: [] };
        }
        case PubmedActionTypes.LoadPubmedsSucces: {
            return { ...state, loading: false, loaded: true, results: action.payload.pubmeds };
        }

        case PubmedActionTypes.LoadPubmedsFail: {
            return { ...state, loading: false, loaded: false, results: [], ppdId: null };
        }

        case PubmedActionTypes.ClearPubmeds: {
            return { ...state, loaded: false, query: '', results: [], ppdId: null };
        }

        default: {
            return state;
        }
    }
}

export const LoadPubmeds = (state: PubmedState) => state;
