<ng-container *ngIf="parentId">
    <div
        class=" p-3 bg-white rounded"
        style="cursor: pointer;"
    > 
        <h3 class="add-paragraph" role="button" (click)="handleSubmit()" *ngIf="!type">
            <i class="fa fa-plus-circle"></i> {{'PARAGRAPH.EDIT.ADDPARAGRAPH' | translate}}
        </h3>
        <h3 class="add-paragraph" role="button" (click)="handleSubmit()" *ngIf="type === 'conclusion'">
            <i class="fa fa-plus-circle"></i> {{'PARAGRAPH.EDIT.ADDCONCLUSION' | translate}}
        </h3>
        <h3 class="add-paragraph" role="button" (click)="handleSubmit()" *ngIf="type === 'consideration'">
            <i class="fa fa-plus-circle"></i> {{'PARAGRAPH.EDIT.ADDCONSIDERATION' | translate}}
        </h3>
        <h3 class="add-paragraph" role="button" (click)="handleSubmit()" *ngIf="type === 'recommendation'">
            <i class="fa fa-plus-circle"></i> {{'PARAGRAPH.EDIT.ADDRECOMMENDATION' | translate}}
        </h3>
        <h3 class="add-paragraph" role="button" (click)="handleSubmit()" *ngIf="type === 'literature'">
            <i class="fa fa-plus-circle"></i> {{'PARAGRAPH.EDIT.ADDPARAGRAPH' | translate}}
        </h3>

    </div>
</ng-container>
<ng-container *ngIf="!parentId">
    <div
        class=" p-3 bg-white rounded"
        style="cursor: pointer;"
    >
        <h2 class="add-paragraph" role="button" (click)="handleSubmit()">
            <i class="fa fa-plus-circle"></i> {{'PARAGRAPH.EDIT.ADDCHAPTER' | translate}}
        </h2>
    </div>
</ng-container>
