import { createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';
import * as fromParagraph from '../reducers/paragraphs.reducer';

export const getParagraphState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.paragraphs
);

export const getKeyQuestionImport = createSelector(getParagraphState, fromParagraph.getKeyQuestionImport);
export const getUpdateModelBySheetId = createSelector(getParagraphState, fromParagraph.getUpdateModelFromSheetId);
export const getLinkToProtocol = createSelector(getParagraphState, fromParagraph.getLinkToProtocol);
