import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';

import { TreeviewConfig, TreeviewItem } from '../../../../shared';
import { convertTreeview } from '../../components/libs';
import { Branch, GetModelRO } from '../../../../models/outcome.interface';

export interface CompareModel {
    title: string;
    text: string;
}

export interface CompareModels {
    previous: CompareModel;
    current: CompareModel;
}

export interface CompareModelsData {
    notifyGetModelRO$: Observable<GetModelRO>;
    language: string;
    level: number;
    title: string;
    text: string;
    paragraphs: CompareModels;
}

const cn = 'CompareModelsModalComponent';

// Language translations
const translation = {
    nl: {
        current: 'Nieuwe versie',
        previous: 'Oude versie',
        wait: 'Even geduld svp, dit kan een tijdje duren ...',
        back: 'Terug'
    },
    en: {
        current: 'Current versie',
        previous: 'Previous versie',
        wait: 'Please be patient, this can take quite awhile ...',
        back: 'Back'
    }
};

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-compare-models-modal',
    templateUrl: './compare-models.component.html',
    styleUrls: ['./compare-models.component.scss']
})
export class CompareModelsModalComponent implements OnInit, OnDestroy {
    // Data passed to this modal
    data: CompareModelsData;

    config = TreeviewConfig.create({
        hasAllCheckBox: false,
        hasFilter: false,
        hasCollapseExpand: false,
        decoupleChildFromParent: false,
        maxHeight: 999999
    });

    // Language translation, by default dutch (nl).
    lang = translation['nl'];

    // meta is an array containing strings: 'all', 'new', 'updated', etc.
    items: Array<{ meta: string[], current: TreeviewItem[], previous: TreeviewItem[] }> = [];

    loading: boolean;

    filter = 'all';

    filterByButtons = [
        { title: 'all', class: '', selected: false },
        { title: 'new', class: '-primary', selected: false },
        { title: 'updated', class: '-secondary', selected: false }
    ];

    subscriptions: Subscription[] = [];

    constructor(
        public activeModal: NgbActiveModal,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this._handleLanguage();
        //this._handleOutcomes();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    onSelectedChange(event: any, tree: string, index: number) {
    }

    onFilterChange(event: any, tree: string, index: number) {
    }

    filterBy(title: string) {
        if (title !== this.filter) {
            this.filter = title;
            this.filterByButtons.forEach(button => button.selected = button.title === title);
        }
    }

    private _handleLanguage() {
        if (['en', 'nl'].includes(this.data.language)) {
            this.lang = translation[this.data.language];
        } else {
            console.warn(`${cn} ngOnInit() invalid language='${this.data.language}' using 'nl'`);
        }
    }

    private _handleOutcomes() {
        this.loading = true;

        this.subscriptions.push(
            this.data.notifyGetModelRO$.subscribe(getModelRO => {
                if (getModelRO && getModelRO.outcomes) {
                    getModelRO.outcomes.forEach(outcome => {
                        const meta: string[] = ['all'];
                        const current = convertTreeview([outcome] as any, true, meta);
                        const previous = outcome.relatedOutcome ? convertTreeview([outcome.relatedOutcome] as any, true) : null;
                        this.items.push({
                            meta,
                            current,
                            previous
                        });
                    });
                    this.cdr.markForCheck();
                    this.loading = false;
                }
            })
        );
    }

}
