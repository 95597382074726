import { Branch } from '../../../../models/outcome.interface';
import { TreeviewItem } from '../../../../shared';

/**
 * Convert the outcome response format returned by the backend to the required tree view format which
 * is expected by the ngx-treeview component:
 *
 * {
 *     title => text: string;
 *      => value: number;
 *    branches => children: Children[];
 * }
 *
 * where Children = { text, value, children[] }
 *
 * An item that is 'selected' will be highlighted, and if the item is 'new' or 'updated' the appropriate label
 * will be added.
 *
 * If collapsed is true, then the tree will be completely collapsed, otherwise default all open.
 *
 * @param {Branch[]} branches
 * @param {boolean} collapsed
 * @param {string[]} meta array (optional)
 * @returns {TreeviewItem[]}
 *
 */
export const convertTreeview = (branches: Branch[], collapsed: boolean, meta: string[] = null): TreeviewItem[] => {

    const convertBranchToList = (branch: Branch, level, parentId, _items) => {
        const id = Math.floor(Math.random() * 100000) + 1;
        const title = branch.title;
        const _branches = branch.branches && branch.branches.length ? branch.branches.length : 0;
        const meta = { updated: branch.updated, new: branch.new };
        _items.push({ level, id, parentId, title, meta, branches: _branches });
        if (_branches) {
            branch.branches.forEach(b => {
                convertBranchToList(b, level + 1, id, _items);
            });
        }
    };

    const convertListToTreeview = (_items) => {
        const mappingObject = {};
        const tv = [];

        // Convert text to highlighted if selected and to 'title (draft) (updated) (new) if present.
        const _text = (item): string => {
            let result = item.title;
            if (item.selected) {
                result = `<span class="treeview-item-selected">${result}</span>`;
            }
            ['updated', 'new'].forEach(state => {
                if (item.meta[state]) {
                    result += ` <a class="tag -${state === 'updated' ? 'secondary' : 'primary'} mr-2">${state}</a>`;
                    if (meta && !meta.includes(state)) {
                       meta.push(state);
                    }
                }
            });
            return result;
        };

        _items.forEach((item, index) => {
            mappingObject[item.id] = index;
            item.children = [];
        });

        _items.forEach(item => {
            const parentId = item.parentId;
            if (parentId !== 0) {
                _items[mappingObject[parentId]].children.push({
                    text: _text(item),
                    value: item.id,
                    collapsed,
                    children: item.children
                });
            } else {
                tv.push({
                    text: _text(item),
                    value: item.id,
                    collapsed,
                    children: item.children
                });
            }
        });
        return tv;
    };

    let items = [];
    branches.forEach(branch => {
        convertBranchToList(branch, 0, 0, items);
    });

    // Sort so that the parents come first.
    items = items.sort((a, b) => a.level < b.level ? -1 : b.level < a.level ? 1 : 0);

    const treeview = convertListToTreeview(items);
    return treeview.map(tv => new TreeviewItem(tv));
};
