import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromTerms from '../reducers/terms.reducer';

export const getTermsState = createSelector(
    fromFeature.getTermsFeatureState,
    (state: fromFeature.TermsFeatureState) => {
        return state.terms;
    }
);
export const getTermsGetLoaded = createSelector(getTermsState, fromTerms.getTermsGetLoaded);
export const getTermsGetLoading = createSelector(getTermsState, fromTerms.getTermsGetLoading);
export const getTermsGetError = createSelector(getTermsState, fromTerms.getTermsGetError);
export const getTermsAcceptLoaded = createSelector(getTermsState, fromTerms.getTermsAcceptLoaded);
export const getTermsAcceptLoading = createSelector(getTermsState, fromTerms.getTermsAcceptLoading);
export const getTermsAcceptError = createSelector(getTermsState, fromTerms.getTermsAcceptError);
