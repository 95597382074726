import { createSelector } from '@ngrx/store';

import * as fromIndexReducers from '../reducers';
import * as fromReducer from '../reducers/dashboard.reducer';

const getDashboardDataState = createSelector(
    fromIndexReducers.getDashboardState,
    (state: fromIndexReducers.DashboardState) => state.dashboardSummary
);

export const getAllDashboardData = createSelector(getDashboardDataState, fromReducer.getDashboard);
export const getCategory = createSelector(getDashboardDataState, dashboard => {
    return dashboard.data.dashboard_categories;
});
export const getSelectedDashboard = createSelector(getDashboardDataState, dashboard => {
    const {
        data: {
            dashboard_id,
            dashboard_title,
            manage_dashboard,
            display_dashboard,
            manage_patients,
            banner,
            onboardingSteps,
            onboardingHeader
        }
    } = dashboard;
    return {
        id: dashboard_id,
        name: dashboard_title,
        manage_dashboard,
        display_dashboard,
        manage_patients,
        banner,
        onboardingSteps,
        onboardingHeader
    };
});
export const getAllDashboardDataLoaded = createSelector(getDashboardDataState, fromReducer.getDashboardLoaded);
export const getAllDashboardDataLoading = createSelector(getDashboardDataState, fromReducer.getDashboardLoading);
export const getDashboardPermissions = createSelector(getDashboardDataState, fromReducer.getDashboardPermissions);
export const getDashboardView = createSelector(getDashboardDataState, fromReducer.getDashboardView);
