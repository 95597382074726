<a class="button -primary pull-right" href="/calendar/manage" *ngxPermissionsOnly="['Admin']"> Agenda beheren</a>

<h1>Agenda</h1>

<div ngbDropdown placement="bottom-left" class="d-inline-block" style="margin-bottom: 20px;">
    <a class="button -primary" ngbDropdownToggle>Selecteer periode</a>
    <ul ngbDropdownMenu>
        <li ngbDropdownItem *ngFor="let filter of (filters | async); index as i; trackBy: trackByFn"
            (click)="selectFilter(i)">
            {{ filter.filter }}
        </li>
    </ul>
</div>

<div class="card-group" *ngFor="let item of (calendarItems$ | async); trackBy: trackByFn">
    <alii-web-calendar-item [item]="item" style="width:100%"></alii-web-calendar-item>
</div>

<br />
<br style="clear: both;" />
&nbsp;
