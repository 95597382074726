import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap, withLatestFrom, take, tap } from 'rxjs/operators';
import {of, forkJoin, Observable } from 'rxjs';

import * as literaturesCollectionAction from '../actions/literatures-collection.action';
import * as pubmedsAction from '../actions/pubmed.actions';
import * as fromService from '../../../../services';
import { ToastrService } from 'ngx-toastr';
import { Store, select } from '@ngrx/store';
import * as LiteratureSelector from '../selectors/literatures-collection.selector';

const selectStore = (selectors: any) => {
    return switchMap(action => {
        return of([]).pipe(
            withLatestFrom(...selectors, (initial, ...selectorsState) => {
                return [action, ...selectorsState];
            }),
            take(1) // needed, since without it, subsription not killed until next action that hit effect
        );
    });
};

@Injectable()
export class LiteraturesEffects {
    constructor(
        private store$: Store<any>,
        private router: Router,
        private actions$: Actions,
        private literaturesService: fromService.LiteraturesService,
        private UploadService: fromService.UploadService,
        private toastr: ToastrService
    ) {}

    @Effect()
    loadLiteraturesCollectionEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.LOAD_LITERATURES),
        selectStore([
            this.store$.pipe(select(LiteratureSelector.selectProtocolId)),
            this.store$.pipe(select(LiteratureSelector.getLiteraturesCollection))
        ]),
        mergeMap(([action, storeProtocolId, literas]) => {
            const { payload } = action as any;
            const { protocolId } = payload;
            if (storeProtocolId === protocolId && Object.entries(literas).length > 0) {
                return [];
            }
            return [new literaturesCollectionAction.LoadLiteraturesCollectionContinue({ protocolId })];
        })
    );

    @Effect()
    ContinueLoadLiteraturesCollectionEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.LOAD_LITERATURES_CONTINUE),
        switchMap(action => {
            const { payload } = action as any;
            const { protocolId } = payload;
            return this.literaturesService.getLiteratures({ protocolId }).pipe(
                map(literatures => new literaturesCollectionAction.LoadLiteraturesCollectionSucces(literatures)),
                catchError(error => of(new literaturesCollectionAction.LoadLiteraturesCollectionFail(error)))
            );
        })
    );

    @Effect()
    GetArticleEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.GET_LITERATURE),
        switchMap(action => {
            const { payload } = action as any;
            const { protocolId, literatureId } = payload;
            return this.literaturesService.getLiterature({ literatureId, protocolId }).pipe(
                map(
                literature =>
                         new literaturesCollectionAction.GetLiteratureSucces({
                            literature,
                            literatureId
                    })
                ),
            );
        })
    );

    @Effect()
    SelectLiteratureEffect$ = this.actions$.pipe(

        ofType(literaturesCollectionAction.SELECT_LITERATURE),
        selectStore([this.store$.pipe(select(LiteratureSelector.getLiteraturesCollection))]),
        switchMap(([action, literas]) => {
            const { payload } = action as any;
            if (Object.entries(literas).length > 0) {
                return new Observable(() => {});
            }

            const { protocolId } = payload;
            return this.literaturesService.getLiteratures({ protocolId }).pipe(
                map(literatures => new literaturesCollectionAction.LoadLiteraturesCollectionSucces(literatures)),
                catchError(error => of(new literaturesCollectionAction.LoadLiteraturesCollectionFail(error)))
            );
        })
    );

    @Effect()
    GoToStepFirstArticleEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.GO_TO_STEP_FIRST_ARTICLE),
        selectStore([this.store$.pipe(select(LiteratureSelector.getLiteraturesCollection))]),
        switchMap(([action, literatures]) => {
            const { payload } = action as any;
            const { stepId, protocolId } = payload;
            const entries = literatures[stepId].entries ? literatures[stepId].entries : [];

            const entry = entries[0] ? entries[0] : null;

            return new Observable(() => {
                if (entry) this.router.navigate(['/protocols', protocolId, 'literatures', entry.id]);
            });
        })
    );

    @Effect()
    loadPubmedEffect$ = this.actions$.pipe(
        ofType(pubmedsAction.PubmedActionTypes.LoadPubmeds),
        switchMap(action => {
            const {
                payload: { query }
            } = action as any;
            return this.literaturesService.searchArticles(query).pipe(
                map(pubmeds => new pubmedsAction.LoadPubmedsSucces({ pubmeds })),
                catchError(error => of(new pubmedsAction.LoadPubmedsFail(error)))
            );
        })
    );

    @Effect()
    loadLiteraturesSuccessEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.LOAD_LITERATURES_SUCCESS),
        mergeMap(action => {
            const {
                payload: { paragraphs, populations }
            } = action as any;
            return [
                new literaturesCollectionAction.LoadParagraphs(paragraphs),
                new literaturesCollectionAction.LoadPopulations(populations)
            ];
        })
    );

    @Effect()
    submitLiteratureActionEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.SUBMIT_LITERATURE_ACTION),
        switchMap(effectAction => {
            const { payload } = effectAction as any;
            const { protocolId, articleId, values, nextArticleId, populationId, paragraphId, isVote, action, reviewId, findingIds } = payload;

            return this.literaturesService.updateReview({ articleId, populationId, paragraphId, protocolId, action, values, reviewId, findingIds}).pipe(
                map(
                    literature =>
                        new literaturesCollectionAction.SubmitLiteratureActionSucces({
                            literature,
                            protocolId,
                            articleId,
                            nextArticleId,
                            reviewId,
                            isVote
                        })
                ),
                catchError(error => of(new literaturesCollectionAction.SubmitLiteratureActionFail(error)))
            );
        })
    );
    @Effect()
    IsVoteSubmitActionEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.SUBMIT_LITERATURE_ACTION_SUCCESS),
        mergeMap(action => {
            const {
                payload: { literature, protocolId, articleId, nextArticleId, isVote }
            } = action as any;

            if (isVote) this.toastr.success('answer saved');

            if (nextArticleId) this.router.navigate(['/protocols', protocolId, 'literatures', nextArticleId]);

            return [
                new literaturesCollectionAction.GetLiteratureSucces({
                    literature,
                    literatureId: articleId
                })
            ];
        })
    );

    @Effect()
    AddArticlesEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.ADD_ARTICLES),
        switchMap(action => {
            const {
                payload: { protocolId, articleIds }
            } = action as any;
            const { payload } = action as any;
            return forkJoin(
                articleIds.map(articleId => {
                    return this.literaturesService.addArticle({ protocolId, articleId });
                })
            ).pipe(
                map(() => new literaturesCollectionAction.AddArticlesSucces(payload)),
                catchError(error => of(new literaturesCollectionAction.AddArticlesFail(error)))
            );
        })
    );

    @Effect()
    UpdateAddedArticlesEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.ADD_ARTICLES_SUCCESS),
        mergeMap(action => {
            const {
                payload: { articleIds }
            } = action as any;
            return [new literaturesCollectionAction.UpdateAddedArticles({ articleIds })];
        })
    );
    @Effect()
    UploadFileToWebApiEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.UPLOAD_FILE_TO_WEB_API),
        switchMap(action => {
            const { payload } = action as any;

            const { formData, articleId, protocolId } = payload;

            return this.literaturesService.uploadFullTextArticle({ formData, articleId }).pipe(
                map(_ => {
                    return new literaturesCollectionAction.UploadFileToWebApiSuccess({
                        articleId: articleId,
                        protocolId: protocolId
                    });

                }),
                catchError(error => of(new literaturesCollectionAction.UploadFileToWebApiFail(error)))
            );
        })
    );

    @Effect()
    UploadFileToWebApiSuccessEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.UPLOAD_FILE_TO_WEB_API_SUCCESS),
        mergeMap(action => {
            const {
                payload: { articleId, protocolId }
            } = action as any;
            return [new literaturesCollectionAction.GetLiterature({ protocolId, literatureId: articleId })];
        })
    );

    @Effect()
    UploadFileToBucketEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.UPLOAD_FILE_TO_BUCKET),
        switchMap(action => {
            const { payload } = action as any;
            const { formData, articleId, protocolId, title } = payload;
            return this.UploadService.uploadToBucket({ formData }).pipe(
                map(response => {
                    const { url = null } = response;
                    if (url)
                        return new literaturesCollectionAction.UploadFileToBucketSuccess({
                            articleId,
                            protocolId,
                            title,
                            url: response.url
                        });
                    return new literaturesCollectionAction.UploadFileToBucketUploading({
                        articleId
                    });
                }),
                catchError(error => of(new literaturesCollectionAction.UploadFileToBucketFail(error)))
            );
        })
    );
    @Effect()
    UploadFileToBucketSuccessEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.UPLOAD_FILE_TO_BUCKET_SUCCESS),
        mergeMap(action => {
            const {
                payload: { articleId, protocolId, url, title }
            } = action as any;
            return [new literaturesCollectionAction.AddFileToArticle({ articleId, protocolId, url, title })];
        })
    );
    @Effect()
    AddFileToArticleEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.ADD_FILE_TO_ARTICLE),
        switchMap(action => {
            const {
                payload: { articleId, protocolId, url, title }
            } = action as any;
            return this.UploadService.saveUpload({ articleId, protocolId, url, title }).pipe(
                map(
                    message =>
                        new literaturesCollectionAction.AddFileToArticleSuccess({
                            articleId,
                            protocolId,
                            url,
                            title,
                            id: message.id
                        })
                ),
                catchError(error => of(new literaturesCollectionAction.AddFileToArticleFail(error)))
            );
        })
    );

    @Effect()
    AddRisArticleEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.ADD_RIS_ARTICLE),
        switchMap(action => {
            const {
                payload: { protocolId, url }
            } = action as any;
            return this.UploadService.saveRisArticle({ protocolId, url }).pipe(
                map(
                    message =>
                        new literaturesCollectionAction.AddRisArticleSuccess({
                            protocolId,
                            url
                        })
                ),
                catchError(error => of(new literaturesCollectionAction.AddRisArticleFail(error)))
            );
        })
    );

    @Effect({dispatch: false})
    TriggerRandomGPTTestEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.TRIGGER_RANDOM_ARTICLES_GPT_TEST),
        switchMap(action => {
            const { payload } = action as any;
            const { protocolId } = payload;
            return this.literaturesService.triggerRandomGPTTest({ protocolId }).pipe(
                map(() => {
                    this.toastr.success('Successfully Triggered');
                }),
                catchError(error => {
                    this.toastr.error('Something went wrong when starting process');
                    throw error;
                })

            );
        })
    );


    @Effect({dispatch: false})
    TriggerRandomGPTFullTextTestEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.TRIGGER_RANDOM_FULL_TEXT_ARTICLES_GPT_TEST),
        switchMap(action => {
            const { payload } = action as any;
            const { protocolId, keyQuestions } = payload;
            return this.literaturesService.triggerRandomGPTFullTextTest({ protocolId, keyQuestions }).pipe(
                map(() => {
                    this.toastr.success('Successfully Triggered');
                }),
                catchError(error => {
                    this.toastr.error(error?.error?.detail);
                    throw error;
                })

            );
        })
    );


    @Effect({dispatch: false})
    GptVoteOnAbstract$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.GPT_VOTE_ON_ABSTRACT_ACTION),
        switchMap(action => {
            const { payload } = action as any;
            const { protocolId, articleId } = payload;
            return this.literaturesService.gptVoteOnInclusion({ protocolId, articleId }).pipe(
                map(() => {
                    this.toastr.success('Successfully Started Voting Process');
                }),
                catchError(error => {
                    this.toastr.error('Something went wrong when starting process');
                    throw error;
                })
            );
        })
    );


    @Effect({dispatch: false})
    GptVoteOnFullText$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.GPT_VOTE_ON_FULL_TEXT_ACTION),
        switchMap(action => {
            const { payload } = action as any;
            const { protocolId, articleId, keyQuestions } = payload;
            return this.literaturesService.gptVoteOnFullTextInclusion({ protocolId, articleId, keyQuestions }).pipe(
                map(() => {
                    this.toastr.success('Successfully Started Voting Process');
                }),
                catchError(error => {
                    this.toastr.error(error?.error?.detail);
                    throw error;
                })
            );
        })
    );

    @Effect({dispatch: false})
    GptVoteOnAllAbstracst$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.GPT_VOTE_ON_ALL_ABSTRACTS_ACTION),
        switchMap(action => {
            const { payload } = action as any;
            const { protocolId } = payload;
            return this.literaturesService.gptVoteOnAllAbstractsInclusion({ protocolId }).pipe(
                map(() => {
                    this.toastr.success('Successfully Started Voting Process');
                }),
                catchError(error => {
                    this.toastr.error('Something went wrong when starting process');
                    throw error;
                })
            );
        })
    );

    @Effect({dispatch: false})
    GptVoteOnAllFullTextst$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.GPT_VOTE_ON_ALL_FULL_TEXT_ACTION),
        switchMap(action => {
            const { payload } = action as any;
            const { protocolId, keyQuestions } = payload;
            return this.literaturesService.gptVoteOnAllFullTextsInclusion({ protocolId, keyQuestions }).pipe(
                map(() => {
                    this.toastr.success('Successfully Started Voting Process');
                }),
                catchError(error => {
                    this.toastr.error('Something went wrong when starting process');
                    throw error;
                })
            );
        })
    );

    @Effect()
    UploadRisFileToBucketEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.UPLOAD_RIS_FILE_TO_BUCKET),
        switchMap(action => {
            const { payload } = action as any;
            const { formData, articleId, protocolId, title } = payload;
            return this.UploadService.uploadToBucket({ formData }).pipe(
                map(response => {
                    const { url = null } = response;
                    console.log(response);
                    if (url)
                        return new literaturesCollectionAction.UploadRisFileToBucketSuccess({
                            articleId,
                            protocolId,
                            title,
                            url: response.url
                        });
                    return new literaturesCollectionAction.UploadRisFileToBucketUploading({
                        articleId
                    });
                }),
                catchError(error => of(new literaturesCollectionAction.UploadRisFileToBucketFail(error)))
            );
        })
    );
    @Effect()
    UploadRisFileToBucketSuccessEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.UPLOAD_RIS_FILE_TO_BUCKET_SUCCESS),
        mergeMap(action => {
            const {
                payload: { protocolId, url }
            } = action as any;
            return [new literaturesCollectionAction.AddRisArticle({ protocolId, url })];
        })
    );

    @Effect()
    ReloadLiteraturesCollectionEffect$ = this.actions$.pipe(
        ofType(
            literaturesCollectionAction.ADD_ARTICLES_SUCCESS,
            literaturesCollectionAction.ADD_RIS_ARTICLE_SUCCESS),

        mergeMap(action => {
            const {
                payload: { protocolId }
            } = action as any;
            return [new literaturesCollectionAction.LoadLiteraturesCollectionContinue({ protocolId })];
        })
    );

    @Effect()
    ReloadLiteratureEffect$ = this.actions$.pipe(
        ofType(literaturesCollectionAction.ADD_FILE_TO_ARTICLE_SUCCESS),
        mergeMap(action => {
            const {
                payload: { articleId, protocolId }
            } = action as any;

            return [new literaturesCollectionAction.GetLiterature({ protocolId, literatureId: articleId })];
        })
    );
}
