import * as fromActions from '../actions/model.action';
import { GetModelRO } from '@alii-web/models/outcome.interface';

export interface Models {
    [ppdId: string]: GetModelRO;
}

export interface ModelState {
    entries: Models;
}

export const initialState: ModelState = {
    entries: {}
};

export function reducer(state = initialState, action: fromActions.ModelAction): ModelState {
    switch (action.type) {
        case fromActions.GET_MODEL: {
            const { payload } = action;
            const { ppdId } = payload;

            return {
                ...state,
                entries: {
                    ...state.entries,
                    [ppdId]: null
                }
            };
        }

        case fromActions.GET_MODEL_SUCCESS: {
            const { ppdId, getModelRO } = action.payload;

            return {
                ...state,
                entries: {
                    ...state.entries,
                    [ppdId]: getModelRO
                }
            };
        }

        case fromActions.GET_MODEL_FAIL: {
            const { ppdId, error } = action.payload;

            return {
                ...state,
                entries: {
                    ...state.entries,
                    [ppdId]: null
                }
            };
        }
    }
    return state;
}

export const getModelEntries = (state: ModelState) => state.entries;
