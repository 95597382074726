import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromActions from '../actions/flowchart.action';
import * as fromProtocolActions from '../actions/protocols.action';

import * as fromService from '../../../../services';

@Injectable()
export class FlowchartEffects {
    constructor(private actions$: Actions, private FlowchartService: fromService.FlowchartService) {
    }

    @Effect()
    CreateStartpointEffect$ = this.actions$.pipe(
        ofType(fromActions.CREATE_STARTPOINT),
        switchMap(action => {
            const { payload } = action as any;
            const { protocolId } = payload;
            return this.FlowchartService.createStartPoint(payload).pipe(
                map(() => new fromActions.CreateStartpointSucces({ protocolId })),
                catchError(error => of(new fromActions.CreateStartpointFail(error)))
            );
        })
    );

    @Effect()
    CreateStartpointSuccessEffect$ = this.actions$.pipe(
        ofType(fromActions.CREATE_STARTPOINT_SUCCESS),
        mergeMap(action => {
            const {
                payload: { protocolId }
            } = action as any;

            return [new fromProtocolActions.LoadProtocol({ protocolId, versionId: null, version: 'Draft' })];
        })
    );

    @Effect()
    UpdateCardEffect$ = this.actions$.pipe(
        ofType(fromActions.UPDATE_CARD),
        switchMap(action => {
            const { payload } = action as any;
            const { protocolId } = payload;
            return this.FlowchartService.updateCard(payload).pipe(
                map(() => new fromActions.UpdateCardSucces({ protocolId })),
                catchError(error => of(new fromActions.UpdateCardFail(error)))
            );
        })
    );
    @Effect()
    UpdateCardSuccessEffect$ = this.actions$.pipe(
        ofType(fromActions.UPDATE_CARD_SUCCESS),
        mergeMap(action => {
            const {
                payload: { protocolId }
            } = action as any;

            return [new fromProtocolActions.LoadProtocol({ protocolId, versionId: null, version: 'Draft' })];
        })
    );

    @Effect()
    CreateFlowchartEffect$ = this.actions$.pipe(
        ofType(fromActions.CREATE_FLOWCHART),
        switchMap(action => {
            const { payload } = action as any;
            const { protocolId } = payload;
            return this.FlowchartService.createFlowchart(payload).pipe(
                map(result => {
                    const scheme = result['message list'].scheme;
                    if (scheme) {
                        return new fromActions.CreateFlowchartSucces({ protocolId, scheme });
                    } else {
                        return new fromActions.CreateFlowchartFail({ error: 'No scheme returned' });
                    }
                }),
                catchError(error => of(new fromActions.CreateFlowchartFail(error)))
            );
        })
    );

    @Effect()
    CreateFlowchartSuccessEffect$ = this.actions$.pipe(
        ofType(fromActions.CREATE_FLOWCHART_SUCCESS),
        mergeMap(action => {
            const {
                payload: { protocolId }
            } = action as any;

            return [new fromProtocolActions.LoadProtocol({ protocolId, versionId: null, version: 'Draft' })];
        })
    );
    @Effect()
    DeleteFlowchartEffect$ = this.actions$.pipe(
        ofType(fromActions.DELETE_FLOWCHART),
        switchMap(action => {
            const { payload } = action as any;
            const { protocolId } = payload;
            return this.FlowchartService.delete(payload).pipe(
                map(() => new fromActions.DeleteFlowchartSucces({ protocolId })),
                catchError(error => of(new fromActions.DeleteFlowchartFail(error)))
            );
        })
    );

    @Effect()
    DeleteFlowchartSuccessEffect$ = this.actions$.pipe(
        ofType(fromActions.DELETE_FLOWCHART_SUCCESS),
        mergeMap(action => {
            const {
                payload: { protocolId }
            } = action as any;

            return [new fromProtocolActions.LoadProtocol({ protocolId, versionId: null, version: 'Draft' })];
        })
    );
}
