import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as RegisterActions from '../actions/register.action';
import * as fromAuth from '../../../../services/auth.service';

@Injectable()
export class RegisterEffects {
    constructor(private actions$: Actions, private authService: fromAuth.AuthService) {}

    @Effect()
    RegisterInitEffect$ = this.actions$.pipe(
        ofType(RegisterActions.REGISTER_INIT),
        switchMap(action => {
            const { payload } = action as any;
            return this.authService.registerInit(payload).pipe(
                map(response => {
                    const { message } = response;

                    if (message) {
                        return new RegisterActions.RegisterInitFail({
                            message
                        });
                    }

                    return new RegisterActions.RegisterInitSucces(response);
                }),
                catchError(() => {
                    const message = 'Register init failed';
                    return of(new RegisterActions.RegisterInitFail({ message }));
                })
            );
        })
    );

    @Effect()
    RegisterEffect$ = this.actions$.pipe(
        ofType(RegisterActions.REGISTER),
        switchMap(action => {
            const { payload } = action as any;
            return this.authService.register(payload).pipe(
                map(response => {
                    const { message } = response;

                    const prefix = 'fail: ';
                    if (message.indexOf(prefix) === 0) {
                        const errorMessage = message.substr(prefix.length);
                        return new RegisterActions.RegisterFail({
                            message: errorMessage
                        });
                    }

                    return new RegisterActions.RegisterSucces(response);
                }),
                catchError(() => {
                    const message = 'Register failed';
                    return of(new RegisterActions.RegisterFail({ message }));
                })
            );
        })
    );
}
