import { Component, ChangeDetectionStrategy, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../../../../models/field.interface';
import { FieldConfig } from '../../../../../models/field-config.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'form-input',
    template: `
        <div class="dynamic-field form-input" [formGroup]="group">
            <label>{{ config.label }}</label>
            <div *ngIf="config.info" class="field-info">{{ config.info }}</div>

            <textarea
                [attr.placeholder]="config.placeholder"
                [rows]="config.rows ? config.rows : 3"
                [formControlName]="config.name"
                class="form-control"
            ></textarea>
        </div>
    `
})
export class FormTextareaComponent implements Field {
    config: FieldConfig;
    group: FormGroup;
}
