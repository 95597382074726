import { createSelector } from '@ngrx/store';

import * as fromIndexReducers from '../reducers';
import * as fromReducer from '../reducers/set-dashboard.reducer';

const getSetDashboardState = createSelector(
    fromIndexReducers.getStartingPageFeatureState,
    (state: fromIndexReducers.StartingPageFeatureState) => state.setDashboard
);
export const getSetDashboardLoading = createSelector(getSetDashboardState, fromReducer.getSetDashboardLoading);
