import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgxPermissionsModule } from 'ngx-permissions';

// Translation stuff, see: https://github.com/ngx-translate/core
import { TranslateModule } from '@ngx-translate/core';

import { reducers, effects } from './store';
import * as fromServices from '../../services';
import * as fromComponents from './components';
import * as fromContainers from './containers';
import * as fromEntryComponents from './entry-components';
import { PartialsModule } from '../../partials/partials.module';
import { DashboardRoutingModule } from './dashboard-routing.module';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
        StoreModule.forFeature('dashboard', reducers),
        DashboardRoutingModule,
        NgxPermissionsModule.forChild(),
        SortablejsModule.forRoot({
            animation: 200
        }),

        EffectsModule.forFeature(effects),
        PartialsModule,
        SortablejsModule,
        TranslateModule.forChild({ extend: true })
    ],
    providers: [...fromServices.services],
    declarations: [...fromComponents.components, ...fromContainers.containers, ...fromEntryComponents.entryComponents],

    exports: [...fromContainers.containers]
})
export class DashboardModule {}
