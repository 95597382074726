<div>

    <div class="row" *ngIf="getSelectedRowData() && rowBased">
        <div class="pull-right col-md" >
            <span class="pull-right button small -primary m-2" (click)="createDraft()">{{'LITERATURE.REVIEW.EDITROW' | translate }}</span> 
        </div>
    </div>
     <ag-grid-angular
        #agGrid
        style="width: 100%"
        class="ag-theme-alpine"
        [columnDefs]="columnDefs"
        [immutableData]="true"
        [defaultColDef]="defaultColDef"
        [rowData]="rowData"
        [frameworkComponents]="frameworkComponents"
        domLayout='autoHeight'
        (selectionChanged)="getSelectedRowData()"
        rowHeight='150'
        rowSelection='multiple'
        suppressRowClickSelection='true'
        (eventBus)="handleEventBus($event)"
        [getRowNodeId]="getRowNodeId"
    >
    </ag-grid-angular>
</div>


