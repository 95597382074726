<div class="layout">
    <div class="layout__page">
        <main *ngIf="!(loading || (loginFormLoading$ | async))" id="content" class="layout__content">
            <div>
                <div class="row center-content mt-4 mb-3">
                    <i>{{'LOGIN.TITLE' | translate }}</i>
                </div>
                <div class="row center-content">
                    <div class="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
                        <div class="card card-primary panel-primary">
                            <div class="card-body">
                                <form [formGroup]="loginForm"
                                      class="cx_form cx_loginForm"
                                      (keyup.enter)="handleSubmit()"
                                      (ngSubmit)="handleSubmit()"
                                      name="main"
                                >
                                    <div class="form-group">
                                        <label for="usernameInput"
                                               class="font-bold">{{'LOGIN.USERNAME' | translate }}:</label>
                                        <input
                                            formControlName="username"
                                            type="text"
                                            class="form-control"
                                            id="usernameInput"
                                            autocomplete="username"
                                            autofocus=""
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="passwordInput"
                                               class="font-bold">{{ 'LOGIN.PASSWORD' | translate }}:</label>
                                        <input
                                            formControlName="password"
                                            type="password"
                                            class="form-control"
                                            id="passwordInput"
                                            autocomplete="current-password"
                                        />
                                    </div>
                                    <div *ngIf="(loginFormIsError$ | async)" class="form-group">
                                        <div class="alert alert-danger"
                                             role="alert">{{ loginFormMessage$ | async }}</div>
                                    </div>
                                    <div class="form-group">
                                        <button
                                            class="btn btn-lg btn-block btn-primary"
                                            type="button"
                                            [disabled]="!loginForm.valid"
                                            (click)="handleSubmit()"
                                        >
                                        <span
                                            class="spinner-grow spinner-grow-sm"
                                            role="status"
                                            aria-hidden="true"
                                            *ngIf="(loginFormLoading$ | async)"
                                        ></span>
                                            {{ 'SIGNIN' | translate}}
                                        </button>
                                    </div>
                                    <div class="form-group">
                                        <input
                                            id="cx_stayLoggedIn"
                                            type="checkbox"
                                            name="stayloggedin"
                                            value="true"
                                            checked=""
                                        />
                                        <label for="cx_stayLoggedIn" class="cx_stayLoggedIn ml-2">
                                            {{'LOGIN.STAY-LOGGED-IN' | translate}}
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <a href="{{forgotPasswordUrl}}">
                                            {{ 'LOGIN.FORGOT-PASSWORD' | translate}}
                                        </a>
                                    </div>
                                    <div *ngIf="showRegisterLink" class="form-group">
                                        <a href="#" (click)="register($event)"> {{'LOGIN.REGISTER' | translate}} </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end content -->
                <div class="row center-content mt-4 mb-3">
                    <i>{{'LOGIN.SSO' | translate}}</i>
                </div>
                <div class="row center-content">
                    <div class="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
                        <div class="card card-primary panel-primary">
                            <div class="card-body">
                                <form [formGroup]="ssoLoginForm">
                                    <div class="form-group">
                                        <label for="select-sso" class="font-bold">{{ 'SELECT' | translate }}:</label>
                                        <select id="select-sso" class="form-control" formControlName="loginUrl">
                                            <option value="{{ item.loginUrl }}"
                                                    *ngFor="let item of (sso$ | async); trackBy: trackByFn">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <button
                                            class="btn btn-lg btn-block btn-primary"
                                            type="button"
                                            [disabled]="!ssoLoginForm.valid"
                                            (click)="handleSubmitSSO()"
                                        >
                                        <span
                                            class="spinner-grow spinner-grow-sm"
                                            role="status"
                                            aria-hidden="true"
                                            *ngIf="(loginFormLoading$ | async)"
                                        ></span>
                                            {{ 'SINGLE-SIGNON' | translate}}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
