<div class="layout">
    <div class="layout__page">
        <main id="content" class="layout__content ">
            <div class="page">
                <div class="page__content">
                    <div id="protocolContent" class="mainContentArea">
                        <div class="container page__section -small-bottom">
                            <div class="row"><h3>{{ 'DASHBOARD.ACTIONS.MANAGE.DOCUMENT-IMPORT' | translate }}</h3></div>
                            <form [formGroup]="form" (submit)="onSubmit()">
                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <button type="submit" class="btn btn-primary pull-right">{{ 'SUBMIT' | translate }}</button>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-10">
                                        <label for="title" class="col-sm-2 col-form-label">{{ 'TITLE' | translate }}</label>
                                        <input
                                            id="title"
                                            formControlName="query"
                                            [typeaheadAsync]="true"
                                            [typeahead]="protocols"
                                            (typeaheadLoading)="changeTypeaheadLoading($event)"
                                            (typeaheadOnSelect)="typeaheadOnSelect($event)"
                                            typeaheadOptionField="title"
                                            placeholder="{{ 'PROTOCOLS.IMPORT.PLACEHOLDER' | translate}}"
                                            class="form-control"
                                            (input)="onSearch($event)"
                                            container="body"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
