import { createSelector } from '@ngrx/store';

import * as fromModelTagListReducer from '../reducers/models-taglist.reducer';
import * as fromReducers from '../reducers';

export const getModelTagListState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.modelsTagList
);

export const getModelTagListEntries = createSelector(
    getModelTagListState,
    fromModelTagListReducer.getModelTagListEntries
);
