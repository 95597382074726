import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output, Renderer2, OnInit } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'summary-detail',
    templateUrl: './summary-detail.component.html'
})
export class SummaryDetailComponent {
    @Input() rows: [];

    trackByFn = (index, item) => item.id || index;
}
