import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { RuleGroup } from '@alii-web/models/outcome.interface';

export interface RuleGroupsComponent {
    ruleGroups: RuleGroup[];
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-outcome-rule-groups',
    templateUrl: './rule-groups.component.html',
    styleUrls: ['./rule-group.component.scss']
})

export class RuleGroupsComponent implements OnInit {
  @Input() 
  ruleGroups: RuleGroup[];

  constructor(
    ) {
    }

    ngOnInit() {
    }

}