<div class="modal-header">
    <h4 class="modal-title text-capitalize"><i *ngIf="data.icon" [class]="'fa fa-' + data.icon"></i>
        {{ data.title | translate}}</h4>
    <button type="button" class="close" aria-label="Cancel" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container">
        <div class="row">
            <p>{{ data.text | translate }}</p>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary text-capitalize" aria-label="Cancel"
            (click)="activeModal.dismiss()">{{ 'CANCEL' | translate }}</button>
    <button type="button" class="btn btn-info text-capitalize" aria-label="Confirm"
            (click)="activeModal.close()">{{'YES' | translate}}</button>
</div>
