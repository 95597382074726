import { Action } from '@ngrx/store';
import { Paragraph } from '../../../../models/paragraph.interface';

export const MAP_PARAGRAPHS_FILES = '[Protocols] Map Paragraphs Files';
export const ADD_NEW_PARAGRAPHS_FILES_ENTRY = '[Protocols] Add New Paragraphs Files Entry';
export const UPLOAD_FILE_TO_BUCKET = '[Protocols] Upload file to bucket';
export const UPLOAD_FILE_TO_BUCKET_UPLOADING = '[Protocols] Upload file to bucket uploading';
export const UPLOAD_FILE_TO_BUCKET_SUCCESS = '[Protocols] Upload file to bucket success';
export const UPLOAD_FILE_TO_BUCKET_FAIL = '[Protocols] Upload file to bucket fail';
export const ADD_FILE_TO_PARAGRAPH = '[Protocols] Add file to paragraph';
export const ADD_FILE_TO_PARAGRAPH_SUCCESS = '[Protocols] Add file to paragraph success';
export const ADD_FILE_TO_PARAGRAPH_FAIL = '[Protocols] Add file to paragraph fail';
export const ADD_FILE_TO_GRADE = '[Protocols] Add file to grade';
export const ADD_FILE_TO_GRADE_SUCCESS = '[Protocols] Add file to grade success';
export const ADD_FILE_TO_GRADE_FAIL = '[Protocols] Add file to grade fail';

export class MapParagraphFiles implements Action {
    readonly type = MAP_PARAGRAPHS_FILES;
    constructor(public payload: { files: any[] }) {}
}
export class AddNewParagraphFilesEntries implements Action {
    readonly type = ADD_NEW_PARAGRAPHS_FILES_ENTRY;
    constructor(public payload: { paragraph: any }) {}
}

export class UploadFileToBucketSuccess implements Action {
    readonly type = UPLOAD_FILE_TO_BUCKET_SUCCESS;
    constructor(
        public payload: {
            status?: string;
            progress?: number;
            protocolId?: string;
            reviewQuestion?: string;
            ppdId?: string;
            url: string;
            title: string;
        }
    ) {}
}

export class UploadFileToBucket implements Action {
    readonly type = UPLOAD_FILE_TO_BUCKET;
    constructor(public payload: { formData: any; reviewQuestion?: string; protocolId?: string; ppdId: string; title: string }) {}
}

export class UploadFileToBucketUploading implements Action {
    readonly type = UPLOAD_FILE_TO_BUCKET_UPLOADING;
    constructor(public payload: { ppdId: string; progress: number; reviewQuestionId?: string; }) {}
}

export class UploadFileToBucketFail implements Action {
    readonly type = UPLOAD_FILE_TO_BUCKET_FAIL;
    constructor(public payload: any) {}
}
export class AddFileToParagraph implements Action {
    readonly type = ADD_FILE_TO_PARAGRAPH;
    constructor(public payload: { protocolId: string; ppdId: string; url: string; title: string, reviewQuestion?: string }) {}
}
export class AddFileToParagraphSuccess implements Action {
    readonly type = ADD_FILE_TO_PARAGRAPH_SUCCESS;
    constructor(public payload: { protocolId: string; ppdId: string; url: string; title: string; id: string, reviewQuestion: string }) {}
}
export class AddFileToParagraphFail implements Action {
    readonly type = ADD_FILE_TO_PARAGRAPH_FAIL;
    constructor(public payload: any) {}
}

export class AddFileToGrade implements Action {
    readonly type = ADD_FILE_TO_GRADE;
    constructor(public payload: { protocolId: string; ppdId: string; url: string; title: string, reviewQuestion?: string }) {}
}
export class AddFileToGradeSuccess implements Action {
    readonly type = ADD_FILE_TO_GRADE_SUCCESS;
    constructor(public payload: { protocolId: string; ppdId: string; url: string; title: string; id: string, reviewQuestion: string }) {}
}
export class AddFileToGradeFail implements Action {
    readonly type = ADD_FILE_TO_GRADE_FAIL;
    constructor(public payload: any) {}
}


export const DELETE_FILE = '[Protocols] Delete file ';
export const DELETE_FILE_SUCCESS = '[Protocols] Delete file success';
export const DELETE_FILE_FAIL = '[Protocols] Delete file fail';

export class DeleteFile implements Action {
    readonly type = DELETE_FILE;
    constructor(public payload: { protocolId: string; ppdId: string; fileId: string }) {}
}
export class DeleteFileSuccess implements Action {
    readonly type = DELETE_FILE_SUCCESS;
    constructor(public payload: { protocolId: string; ppdId: string; fileId: string }) {}
}
export class DeleteFileFail implements Action {
    readonly type = DELETE_FILE_FAIL;
    constructor(public payload: any) {}
}

export type ParagraphsFIlesAction =
    | MapParagraphFiles
    | AddNewParagraphFilesEntries
    | UploadFileToBucket
    | UploadFileToBucketUploading
    | UploadFileToBucketSuccess
    | UploadFileToBucketFail
    | AddFileToParagraph
    | AddFileToParagraphSuccess
    | AddFileToParagraphFail
    | DeleteFile
    | DeleteFileSuccess
    | DeleteFileFail;
