import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Organization } from '../models/organization.interface';
import { Team } from '../models/team.interface';
import { User } from '../models/user.interface';

@Injectable()
export class OrganisationService {
    constructor(private http: HttpClient) {}

    getOrganization(): Observable<Organization> {
        return this.http.get<any>(`${environment.baseUrl}/api/protocol/organization/getOrganization.vm`).pipe(
            map(response => {
                return response;
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getUsers(query = '', teamId?: string, documentId?: any): Observable<User[]> {
        const teamQuery = teamId !== undefined && teamId != null ? '&teamId=' + teamId : '';
        const searchQuery = query != '' ? `&query=${query}` : '';
        const documentQuery = query != '' ? `&documentId=${documentId}` : '';

        return this.http
            .get<any>(
                `${environment.baseUrl}/api/protocol/organization/getUsers.vm?dt=` +
                    Date.now() +
                    documentQuery +
                    searchQuery +                    
                    teamQuery
            )
            .pipe(
                map(response => {
                    return response.users;
                }),
                catchError((error: any) => throwError(error))
            );
    }

    UpdateTeam(payload): Observable<any> {
        const data = payload.payload;
        return this.http.post<any>(`${environment.baseUrl}/api/teams/updateTeam`, data).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    UpdateOrganization(payload): Observable<any> {
        const data = payload.payload;
        return this.http.post<any>(`${environment.baseUrl}/api/organizations/updateOrganization`, data).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    getTeam(teamId?: string): Observable<any> {
        const teamQuery = teamId !== undefined ? '&teamId=' + teamId : '';

        return this.http
            .get<any>(`${environment.baseUrl}/api/protocol/organization/getUsers.vm?dt=` + Date.now() + teamQuery)
            .pipe(
                map(response => {
                    return {
                        id: response.organizationId,
                        name: response.organization,
                        teams: response.teams
                    };
                }),
                catchError((error: any) => throwError(error))
            );
    }

    createUser(payload): Observable<any> {
        const data = payload;

        return this.http.post<any>(`${environment.baseUrl}/api/protocol/organization/createUser.vm`, data).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    removeUser(payload): Observable<any> {
        const { userId } = payload;

        return this.http.post<any>(`${environment.baseUrl}/api/protocol/organization/removeUser.vm`, { userId }).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }


    editRole(payload): Observable<any> {
        const { userId, action } = payload;

        return this.http.post<any>(`${environment.baseUrl}/api/protocol/organization/editUser.vm`, { userId, action: action  }).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    getCalendarItems(): Observable<any> {
        return this.http.get<any>(`${environment.baseUrl}/api/protocol/organization/getCalendarItems.vm?app=web`).pipe(
            map(response => {
                return response;
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getMessages(query = ''): Observable<any> {
        return this.http
            .get<any>(`${environment.baseUrl}/api/protocol/organization/getMessages.vm?query=${query}&app=web`)
            .pipe(
                map(response => {
                    return response;
                }),
                catchError((error: any) => throwError(error))
            );
    }

    removeMessage(id): Observable<any> {
        const data = { messageId: id, action: 'delete' };
        return this.http.post<any>(`${environment.baseUrl}/api/protocol/organization/editMessage.vm`, data).pipe(
            map(response => {
                return response;
            }),
            catchError((error: any) => throwError(error))
        );
    }

    updateUserGroupName(name: string, id: string) {
        const data = { usergroupId: id, title: name };
        return this.http.post<any>(`${environment.baseUrl}/api/protocol/organization/editUsergroup.vm`, data).pipe(
            map(response => {
                return response;
            }),
            catchError((error: any) => throwError(error))
        );
    }

    createUserGroup(title: string) {
        const data = { title };
        return this.http.post<any>(`${environment.baseUrl}/api/protocol/organization/createUserGroup.vm`, data).pipe(
            map(response => {
                return response;
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getUserGroups(): Observable<any> {
        return this.http.get<any>(`${environment.baseUrl}/api/protocol/organization/getUsergroups.vm`).pipe(
            map(response => {
                return response;
            }),
            catchError((error: any) => throwError(error))
        );
    }

    getUsersInGroup(group: string): any {
        return this.http
            .get<any>(
                `${environment.baseUrl}/api/protocol/organization/getUsers.vm?dt=` + Date.now() + `&usergroup=${group}`
            )
            .pipe(
                map(response => {
                    return response.users;
                }),
                catchError((error: any) => throwError(error))
            );
    }

    addUserToGroup(group: string, user: string): Observable<any> {
        const payload = { action: 'addTag', tagId: group, userId: user };
        return this.http.post<any>(`${environment.baseUrl}/api/protocol/user/updateUser.vm`, payload).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    removeUserFromGroup(group: string, user: string) {
        const payload = { action: 'deleteTag', tagId: group, userId: user };
        return this.http.post<any>(`${environment.baseUrl}/api/protocol/user/updateUser.vm`, payload).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    postMessage(payload: any) {
        const url = payload.messageId
            ? `${environment.baseUrl}/api/protocol/organization/editMessage.vm`
            : `${environment.baseUrl}/api/protocol/organization/createMessage.vm`;

        return this.http.post<any>(url, payload).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    editMessage(payload: any) {
        return this.http.post<any>(`${environment.baseUrl}/api/protocol/organization/editMessage.vm`, payload).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    getTeams(query = ''): Observable<Team[]> {
        return this.http
            .get<any>(
                `${environment.baseUrl}/api/protocol/organization/getTeams.vm?dt=` + Date.now() + `&query=${query}`
            )
            .pipe(
                map(response => {
                    return response.teams;
                }),
                catchError((error: any) => throwError(error))
            );
    }

    createTeam(payload): Observable<any> {
        const data = payload;

        return this.http.post<any>(`${environment.baseUrl}/api/protocol/organization/createNewTeam.vm`, data).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    removeTeam(payload): Observable<any> {
        const { teamId } = payload;

        return this.http.post<any>(`${environment.baseUrl}/api/protocol/organization/removeTeam.vm`, { teamId }).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    getHelpPage(): Observable<any> {
        return this.http
            .get<any>(
                `${environment.baseUrl}/api/protocol/organization/getHelpPage.vm`
            )
            .pipe(
                map(response => response),
                catchError((error: any) => throwError(error))
            );
    }

}
