import { Component, ChangeDetectionStrategy, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromStore from './../../store';

import { Protocol } from '../../../../models/protocol.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-flowchart-builder',
    templateUrl: './flowchart-builder.component.html'
})
export class FlowchartBuilderComponent implements OnInit, OnChanges {
    id: string;
    protocol$: Observable<Protocol>;
    protocolLoading$: Observable<boolean>;
    scheme$: Observable<string>;
    flowchart$: Observable<any>;
    flowchartLoading$: Observable<boolean>;

    existingProtocols$: Observable<any>;

    constructor(private route: ActivatedRoute, private store: Store<fromStore.ProtocolsFeatureState>) {
        const PARAM_PROTOCOL_ID = 'id';

        this.route.paramMap.subscribe(params => {
            this.id = params.get(PARAM_PROTOCOL_ID);
        });
        this.protocol$ = this.store.select(fromStore.getSelected);
        this.protocolLoading$ = this.store.select(fromStore.getSelectedLoading);
        this.scheme$ = this.store.select(fromStore.getSelectedScheme);
        this.flowchart$ = this.store.select(fromStore.getFlowchart);
        this.flowchartLoading$ = this.store.select(fromStore.getFlowchartLoading);
        this.existingProtocols$ = this.store.select(fromStore.getProtocosExisting);

        this.store.dispatch(new fromStore.LoadProtocol({ protocolId: this.id, versionId: null, version: 'Draft' }));
        this.store.dispatch(new fromStore.LoadProtocolsExisting());
    }

    ngOnInit() {}
    ngOnChanges(changes: SimpleChanges): void {}
    handleEventBus(event) {
        const { type, payload } = event;
        switch (type) {
            case 'handleFlowchartSubmitStart':
                this.handleFlowchartSubmitStart(payload);
                break;
            case 'handleSubmitEditCard':
                this.handleSubmitEditCard(payload);
                break;
            case 'handleFlowchartSubmitDelete':
                this.handleFlowchartSubmitDelete(payload);
                break;
            default:
                break;
        }
    }
    handleFlowchartSubmitStart(payload) {
        this.store.dispatch(new fromStore.CreateStartpoint({ ...payload, protocolId: this.id }));
    }
    handleSubmitEditCard(payload) {
        this.store.dispatch(new fromStore.UpdateCard({ ...payload, protocolId: this.id }));
    }
    handleFlowchartSubmitDelete(payload) {
        this.store.dispatch(new fromStore.DeleteFlowchart({ ...payload, protocolId: this.id }));
    }
}
