<h1>Agenda beheren</h1>

<h4>Nieuw agendaitem toevoegen</h4>
<form [formGroup]="messageForm">
    Titel <input type="text" class="form-control" formControlName="title" /> <br />

    Omschrijving
    <textarea [froalaEditor]="options" [(froalaModel)]="text" formControlName="text" class="as-html"></textarea> <br />
    <br />

    Locatie <input type="text" class="form-control" formControlName="location" /> <br />

    Evenement duurt hele dag
    <input type="checkbox" formControlName="isAllday" (change)="toggleisAllday($event)" /><br />

    <table>
        <tr>
            <td>Startdatum en -tijd &nbsp;</td>
            <td>
                <input
                    class="form-control"
                    placeholder="yyyy-mm-dd"
                    name="startDate"
                    formControlName="startDate"
                    ngbDatepicker
                    #sd="ngbDatepicker"
                    (click)="sd.toggle()"
                    (dateSelect)="onSelectingStartDate($event)"
                />
            </td>

            <td>
                <ngb-timepicker formControlName="startTime" [spinners]="false" [hidden]="isAllday"></ngb-timepicker>
            </td>
        </tr>
        <tr>
            <td>Einddatum en -tijd</td>
            <td>
                <input
                    class="form-control"
                    placeholder="yyyy-mm-dd"
                    name="endDate"
                    formControlName="endDate"
                    ngbDatepicker
                    #ed="ngbDatepicker"
                    (click)="ed.toggle()"
                    [minDate]="minDate"
                />
            </td>
            <td><ngb-timepicker formControlName="endTime" [spinners]="false" [hidden]="isAllday"></ngb-timepicker></td>
        </tr>
    </table>

    <br />

    <input type="hidden" formControlName="messageId" *ngIf="editing" />

    <button type="button" (click)="submit()" class="btn btn-info">Opslaan</button>
</form>

<br />
<br />

<h4>Agendaitems beheren</h4>
<br />

<ul>
    <li *ngFor="let item of ((calendarItems$ | async)?.calendar_items)[0].list; trackBy: trackByFn">
        {{ item.startDate.slice(0, 16) }} - <strong>{{ item.title }}</strong
        ><br />
        <a (click)="removeItem(item.id)" class="tag" href="#">Item verwijderen</a> &nbsp;
        <a href="/calendar/manage/{{ item.id }}" class="tag">Item aanpasssen</a><br /><br />
    </li>
</ul>
<br style="clear: both;" />
