import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-remove-data-modal',
    templateUrl: './remove-data-modal.component.html'
})
export class RemoveDataModalComponent implements OnInit {
    headerTitle: string;
    bodyText: string;

    form: FormGroup;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({});
    }
    handleSubmit() {
        if (this.form.valid) {
            const data = {
                ...this.form.value
            };
            this.activeModal.close(data);
        }
    }
    handleCancel() {
        this.activeModal.dismiss('Cancelled by user');
    }
}
