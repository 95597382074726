
<div id="fixedLogout" *ngIf="!gerimedicaUser">
    <a (click)="onLogout()"
        ><span class="logout_icon"><span class="icon-arrow-circle-o-left"></span></span
        ><span class="logout_label">Logout</span></a
    >
</div>

<div id="fixedLogout" class="gerimedica" *ngIf="gerimedicaUser">
    <a (click)="onLogout()"
        ><span class="logout_icon"><span class="icon-arrow-circle-o-left"></span></span
        ><span class="logout_label">Terug naar Gerimedica</span></a
    >
</div>