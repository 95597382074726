import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Shop } from '../models/shop.interface';
import { ShopTemplateSet } from '../models/shop-template-set.interface';

@Injectable()
export class ShopService {
    constructor(private http: HttpClient) {}

    getShop(id: string = null): Observable<Shop> {
        const url = `${environment.baseUrl}/api/protocol/organization/getTemplateSets.vm${
            id ? '?specialismSet=' + id : ''
        }`;
        return this.http.get<any>(url).pipe(catchError((error: any) => throwError(error)));
    }

    getShopTemplateSet(id: string): Observable<ShopTemplateSet> {
        const url = `${environment.baseUrl}/api/protocol/organization/getTemplateSet.vm?id=${id}`;
        return this.http.get<any>(url).pipe(catchError((error: any) => throwError(error)));
    }

    updateShopTemplateSet(payload: { id: string; action: string; teamId: string }): Observable<ShopTemplateSet> {
        const url = `${environment.baseUrl}/api/protocol/organization/updateTemplateSet.vm`;
        return this.http.post<any>(url, payload).pipe(catchError((error: any) => throwError(error)));
    }
}
