import { createSelector } from '@ngrx/store';

import * as fromFlowchartReducer from '../reducers/flowchart.reducer';
import * as fromReducers from '../reducers';

export const getFlowchartState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.flowchart
);

export const getFlowchart = createSelector(
    getFlowchartState,
    fromFlowchartReducer.getFlowchart
);

export const getFlowchartLoading = createSelector(
    getFlowchartState,
    fromFlowchartReducer.getFlowchartLoading
);
