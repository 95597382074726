import { Component, Input, OnInit } from '@angular/core';

import { HttpProgressState, HttpStateService, IHttpState } from '../../services/http-state.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
    selector: 'alii-web-block-ui-spinner',
    templateUrl: './block-ui-spinner.component.html',
    styleUrls: ['./block-ui-spinner.component.scss']
})
export class BlockUiSpinnerComponent implements OnInit {
    @Input() public filterBy: RegExp | null = null;
    @BlockUI() blockUI: NgBlockUI;

    constructor(private httpStateService: HttpStateService) {}

    // Receives all HTTP requests and filters them by the filterBy values provided
    ngOnInit() {
        this.httpStateService.state.subscribe((progress: IHttpState) => {
            let loading: boolean;
            if (progress && progress.url) {
                if (!this.filterBy) {
                    loading = progress.state === HttpProgressState.start;
                } else if (progress.url.match(this.filterBy)) {
                    loading = progress.state === HttpProgressState.start;
                }
            }
            if (loading) {
                this.blockUI.start('Loading ...');
            } else {
                this.blockUI.stop();
            }
        });
    }
}
