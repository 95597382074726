import * as fromActions from '../actions/flowchart.action';

export interface FlowchartState {
    protocolId: string;
    cards: any[];
    startPoint: string;
    scheme: string;
    loading: boolean;
    loaded: boolean;
    yesText: string;
    noText: string;
}

export const initialState: FlowchartState = {
    protocolId: null,
    cards: [],
    startPoint: null,
    scheme: null,
    loading: false,
    loaded: false,
    yesText: "yes",
    noText: "no"
};

export function reducer(state = initialState, action: fromActions.FlowchartAction): FlowchartState {
    switch (action.type) {
        case fromActions.LOAD_CARDS: {
            const { cards, startPoint, scheme, yesText, noText } = action.payload;
            return { ...state, cards, startPoint, scheme, yesText, noText };
        }

        case fromActions.CREATE_FLOWCHART: {
            return { ...state, loading: true, loaded: false };
        }

        case fromActions.CREATE_FLOWCHART_SUCCESS: {
            const { protocolId, scheme } = action.payload;
            return { ...state, protocolId, scheme, loading: false, loaded: true };
        }

        case fromActions.CREATE_FLOWCHART_FAIL: {
            return { ...state, loading: false, loaded: false };
        }

        case fromActions.CREATE_STARTPOINT: {
            return { ...state, loading: true, loaded: false };
        }

        case fromActions.CREATE_STARTPOINT_SUCCESS: {
            return { ...state, loading: false, loaded: true };
        }

        case fromActions.CREATE_STARTPOINT_FAIL: {
            return { ...state, loading: false, loaded: false };
        }

        case fromActions.UPDATE_CARD: {
            return { ...state, loading: true, loaded: false };
        }

        case fromActions.UPDATE_CARD_SUCCESS: {
            return { ...state, loading: false, loaded: true };
        }

        case fromActions.UPDATE_CARD_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
        case fromActions.DELETE_FLOWCHART: {
            return { ...state, loading: true, loaded: false };
        }

        case fromActions.DELETE_FLOWCHART_SUCCESS: {
            return { ...state, loading: false, loaded: true };
        }

        case fromActions.DELETE_FLOWCHART_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
    }
    return state;
}

export const getFlowchart = (state: FlowchartState) => state;
export const getFlowchartLoading = (state: FlowchartState) => state.loading;
