import { SearchBoxMatch, SearchResult } from '../models';

const cn = 'convertSelect()';

/**
 *  Convert from search result type to search-box match type.
 *
 *  @param {SearchResult} item
 *  @returns {SearchBoxMatch}
 *
 */
export const convertSelect = (item: SearchResult): SearchBoxMatch => {
    const type = item.type;

    switch (type) {
        case 'go to':
            // Go to the search page.
            return {
                type
            };
        case 'protocol':
            return {
                type,
                protocolId: item.id,
                version: item.version
            };
        case 'article':
            return {
                type,
                protocolId: item.protocolId,
                articleId: item.articleId
            };
        case 'outcome':
        case 'paragraph':
        case 'in this protocol':
            return {
                type,
                protocolId: item.protocol,
                anchorId: item.anchorId,
                version: item.version
            };
        case 'file':
            return {
                type,
                protocolId: item.protocolId,
                fileId: item.id,
                ppdId: item.ppdId
            };
        case 'literature':
            return {
                type,
                protocolId: item.id
            };
        case 'category':
            console.warn(`${cn} type='${type}' => ignore`);
            break;
        default:
            console.warn(`${cn} unknown type='${type}' => ignore`);
            break;
    }

    return null;
};
