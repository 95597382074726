<div class="modal-header">
    <h4 class="modal-title">Select a key question</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container">

        <ng-form [formGroup]="searchForm" (keyup.enter)="onSubmitSearch()">
            <div class="form-row">
                <div class="col-10">
                    <input type="text" formControlName="query" class="form-control" placeholder="Search model" />
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-info mb-2" (click)="onSubmitSearch()">Search</button>
                </div>
            </div>
        </ng-form>
        <div *ngIf="searchLoading" style="display: block;">
            <div class="spinner">
                <div class="dot1"></div>
                <div class="dot2"></div>
            </div>
        </div>

        <div class="row" *ngIf="!searchLoading">
            <div *ngIf="keyQuestions.length === 0 && query !== ''" class="col-md-12">
                no results {{query}}
            </div>
            <table *ngIf="keyQuestions.length > 0" class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Key Question</th>
                        <th scope="col">Document</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of keyQuestions; trackBy: trackByFn"
                        (click)="onClickKQ(item.id)"
                        style="cursor: pointer"
                    >
                        <td>{{ item.title }}</td>
                        <td>{{ item.type }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
