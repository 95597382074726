import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertType2Text'
})
export class ConvertType2Text implements PipeTransform {
    constructor() {}

    transform(type: string): any {
        const found = [
            { type: 'all', text: 'all' },
            { type: 'protocol', text: 'document' },
            { type: 'paragraph', text: 'paragraph' },
            { type: 'category', text: 'category' },
            { type: 'tag', text: 'category' },
            { type: 'outcome', text: 'outcome' }
        ].find(item => item.type === type);
        return found ? found.text : type;
    }
}
