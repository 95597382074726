import { createSelector } from '@ngrx/store';

import * as fromParFilesReducer from '../reducers/paragraphs-files.reducer';
import * as fromReducers from '../reducers';

export const getParagraphsFilesState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.pararagraphFiles
);

export const getParagraphsFiles = createSelector(
    getParagraphsFilesState,
    fromParFilesReducer.getParagraphsFiles
);
