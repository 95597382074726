import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../../../../models/field.interface';
import { FieldConfig } from '../../../../../models/field-config.interface';

@Component({
    selector: 'form-button',
    template: `
        <div class="dynamic-field form-button" style="float:right" [formGroup]="group">
            <button class="btn btn-info" [disabled]="config.disabled" type="submit">{{ config.label }}</button>
        </div>
    `
})
export class FormButtonComponent implements Field {
    config: FieldConfig;
    group: FormGroup;
}
