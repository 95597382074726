import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';
import { StartingPageActionDashboard, StartingPageDashboard } from '@alii-web/modules/starting-page/models';
import { AuthService } from '../../../../services';
import { environment } from '../../../../../environments/environment';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-dashboard-manage-menu',
    templateUrl: './manage-menu.component.html',
    styleUrls: ['./manage-menu.component.scss']
})
export class ManageMenuComponent implements OnInit {
    @Input() dashboard: StartingPageDashboard;
    @Input() dashboards: StartingPageActionDashboard[] = [];

    constructor(private authService: AuthService) {}

    ngOnInit() {}

    onClickOrganizationAdminPage() {
        let authHash = this.authService.getAuthHash();
        let url = environment.blazorUrl + "adminpages/organization" + "?token=" + authHash;
        window.open(url, "_blank");
    }
}
