import { Action } from '@ngrx/store';
import { User } from '../../../../models/user.interface';

export const LOAD_PROFILE = '[Profile] Load Profile';
export const LOAD_PROFILE_FAIL = '[Profile] Load Profile Fail';
export const LOAD_PROFILE_SUCCESS = '[Profile] Load Profile Success';

export class LoadProfile implements Action {
    readonly type = LOAD_PROFILE;
    constructor(public userId: string) {}
}

export class LoadProfileFail implements Action {
    readonly type = LOAD_PROFILE_FAIL;
    constructor(public payload: any) {}
}

export class LoadProfileSucces implements Action {
    readonly type = LOAD_PROFILE_SUCCESS;
    constructor(public payload: User) {}
}

export class LoadUserProfile implements Action {
    readonly type = LOAD_PROFILE;
    constructor(public userId: string) {}
}


export const UPDATE_PROFILE = '[Profile] Update Profile';
export const UPDATE_PROFILE_FAIL = '[Profile] Update Profile Fail';
export const UPDATE_PROFILE_SUCCESS = '[Profile] Update Profile Success';

export class UpdateProfile implements Action {
    readonly type = UPDATE_PROFILE;
    constructor(public payload: any) {}
}

export class UpdateProfileFail implements Action {
    readonly type = UPDATE_PROFILE_FAIL;
    constructor(public payload: any) {}
}

export class UpdateProfileSucces implements Action {
    readonly type = UPDATE_PROFILE_SUCCESS;
    constructor(public payload: any) {}
}

export type ProfileAction =
    | LoadProfile
    | LoadProfileFail
    | LoadProfileSucces
    | UpdateProfile
    | UpdateProfileFail
    | UpdateProfileSucces;
