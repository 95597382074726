import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import * as fromContainers from './containers';

const routes: Routes = [
    { path: '', component: fromContainers.ProtocolsComponent },
    { path: 'create', component: fromContainers.ProtocolsFormComponent },
    { path: ':id/settings', component: fromContainers.ProtocolsDetailComponent },
    { path: 'import', component: fromContainers.ProtocolsImportComponent },
    { path: ':id', component: fromContainers.ProtocolsDetailComponent },
    { path: ':id/flowchart', component: fromContainers.FlowchartBuilderComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProtocolsRoutingModule {}
