import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Protocol } from '../../../../models/protocol.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-patient-add-modal',
    templateUrl: './patient-add-modal.component.html'
})
export class PatientAddModalComponent implements OnInit {
    form: FormGroup;
    protocols: Protocol[];

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            title: ['', Validators.required],
            protocolId: ['', Validators.required]
        });
    }

    handleSubmit() {
        if (this.form.valid) {
            const data = {
                ...this.form.value
            };
            this.activeModal.close(data);
        }
    }
}
