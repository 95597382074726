import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    Renderer2
} from '@angular/core';
import { Subject } from 'rxjs';

import { Paragraph, SelectedFootnote } from '../../../../../models/paragraph.interface';
import { BrowserService } from '@alii-web/services';
import { renderFootnote } from '../../libs';
import { TranslateService } from '@ngx-translate/core';

const cn = 'ParagraphDetailComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-paragraph-detail',
    templateUrl: './paragraph-detail.component.html',
    styleUrls: ['./paragraph-detail.component.scss']
})
export class ParagraphDetailComponent implements AfterViewInit {
    @Input()
    protocolId: string;

    @Input()
    populationId: string;

    @Input()
    parentId: string;

    @Input()
    paragraph: Paragraph;
    
    @Input()
    version: string;

    @Input()
    paragraphSelectedId: string;

    @Input()
    isEditAble: boolean;
    
    @Input()
    hasNumberedIndex: boolean;

    @Input()
    index: number;

    @Input()
    parentIndex: number;
    
    @Input()
    keyQuestionTypeIndex: number;

    @Input()
    modelFindings: any;

    @Input()
    modelOutcomes: any;

    @Input()
    modelTagList: any;

    @Input()
    modelMessages: any;

    @Input()
    viewByPopulation: boolean;
    
    @Input()
    gradeAssessment: any;

    @Input()
    populations: any;

    @Input()
    sidebarCollapsed: boolean;
    
    @Input()
    sourceTable: [];
    
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    selectedFootnote$ = new Subject<SelectedFootnote>();

    // Sanity check whether there are indeed differences between current and previous despite when 'updated' is true,
    // meaning the update button will NOT be shown if there actually are no diffs.
    hasReallyChanged: boolean;

    constructor(
        private translateService: TranslateService,
        private renderer: Renderer2, 
        private browser: BrowserService, 
        private hostElement: ElementRef) {}

    ngAfterViewInit() {
        this.textUpdated();
    }
 
    handleEventBus(event) {
        const { type, payload } = event;
        switch (type) {
            case 'handleAddComment':
                this.eventBus.emit(event);
                break;

            case 'onClickArticle':
                this.onClickArticle(payload);
                break;

            default:
                this.eventBus.emit(event);
                break;
        }
    }

    
    getSourceTable(par) {
        if(par.sourceTable) {return par.sourceTable}
        let rowData = par.rowData
        let columnDefs = par.columnDefs
        let sources = {rowData, columnDefs}
        let sourceTable = [sources]
        return sourceTable
    }

    isScore(score) {
        if (score == 1) {
            return true
        }  else if (score == 2) {
            return true
        }  else if (score == 3) {
            return true
        }  else if (score == 4) {
            return true
        }
        return false
    }

    onClickArticle(payload) {
        const { articleId } = payload;
        const action = {
            type: 'handleLoadProtocolArticle',
            payload: {
                protocolId: this.protocolId,
                articleId
            }
        };
        this.eventBus.emit(action);
    }

    onClickCompareParagraphsModal() {}
    onClickCompareModelsModal() {}

    getGradeAssessmentPar(ppdId) {
        let assessments = this.gradeAssessment
        if (assessments !== undefined) {
            return this.gradeAssessment.entries ? this.gradeAssessment.entries[ppdId] : null;
        }
    }

    keyQuestionSubType() {
        if( this.paragraph.type === 'conclusion' ||
            this.paragraph.type === 'recommendation' ||
            this.paragraph.type === 'consideration' ||
            this.paragraph.grandParentId
        ) { 
            return true }
        else {
            return false
        }
    }

    getKeyQuestionSubTypes() {
        let title = ''
        let typeList = [
            {type:'conclusion', translate: 'PARAGRAPH.CONCLUSIONS'}, 
            {type: 'consideration', translate: 'PARAGRAPH.CONSIDERATIONS'}, 
            {type:'recommendation', translate: 'PARAGRAPH.RECOMMENDATIONS'}]
        let typeSet = []
        typeList.forEach(type => {
            if (this.getChildren(this.paragraph, type)) {
                this.translateService.get(type.translate).subscribe(translation => title = translation)
                let element = {
                    index: this.createIndex(), 
                    type: type.type,
                    title}
                typeSet.push(element)             
                }
            });
        return typeSet
    }

    getTitle() {
        let title = this.paragraph['protocol_paragraph.title']
        if (this.hasNumberedIndex) {
            //if (this.keyQuestionSubType()){
              //  title = this.createIndex()
            //}
            //else {
                title = this.createIndex() + ' ' + title
            //}
            
        } 
        return title
    }

    createIndex() {
        if(!this.hasNumberedIndex) { return undefined }
        let index = 0
        let indexString = ''
        if (this.parentIndex !== undefined) {index = this.parentIndex + 1; indexString = index + '.'}
        if (this.keyQuestionTypeIndex !== undefined) {index = this.keyQuestionTypeIndex + 1; indexString = indexString + index + '.'}
        index = this.index + 1
        if (indexString === '') {
            indexString = indexString + index + '.'
        } else {
            indexString = indexString + index
        }
        return indexString
    }

    getChildren(paragraph, type) {
        let childrenList = []
        if (type != 'main') {
            paragraph.children.forEach(child => {
                if (child.type === type) {
                    childrenList.push(child)
                } 
            });
        }
        if (type === 'main') {
            paragraph.children.forEach(child => {
                if (!child.type || child.type === 'keyQuestion') {
                    childrenList.push(child)
                } 
            });
        }
        return childrenList
    }

    textUpdated() {
        setTimeout(
            () => renderFootnote(this.hostElement, this.renderer, this.selectedFootnote$),
            this.browser.isIE11 ? 500 : 200
        );
    }

    trackByFn = (index, item) => item.id || index;
}
