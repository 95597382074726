import { Action } from '@ngrx/store';

export const LOAD_PROTOCOL_ARTICLE = '[Protocols] Load Protocol Article ';
export const LOAD_PROTOCOL_ARTICLE_FAIL = '[Protocols] Load Protocol Article  Fail';
export const LOAD_PROTOCOL_ARTICLE_SUCCESS = '[Protocols] Load Protocol Article  Success';

export class LoadProtocolArticle implements Action {
    readonly type = LOAD_PROTOCOL_ARTICLE;
    constructor(public payload: { protocolId: string; articleId: string }) {}
}

export class LoadProtocolArticleSucces implements Action {
    readonly type = LOAD_PROTOCOL_ARTICLE_SUCCESS;
    constructor(public payload: { article: any }) {}
}

export class LoadProtocolArticleFail implements Action {
    readonly type = LOAD_PROTOCOL_ARTICLE_FAIL;
    constructor(public payload: any) {}
}

export const ADD_PROTOCOL_ARTICLE = '[Protocols] Add Protocol Article ';
export const ADD_PROTOCOL_ARTICLE_FAIL = '[Protocols] Add Protocol Article  Fail';
export const ADD_PROTOCOL_ARTICLE_SUCCESS = '[Protocols] Add Protocol Article  Success';

export class AddProtocolArticle implements Action {
    readonly type = ADD_PROTOCOL_ARTICLE;
    constructor(
        public payload: {
            protocolId: string;
            ppdId: string;
            parentId: string;
            articleId: string;
            action: string;
            sourceId: string;
        }
    ) {}
}

export class AddProtocolArticleSucces implements Action {
    readonly type = ADD_PROTOCOL_ARTICLE_SUCCESS;
    constructor(public payload: any) {}
}

export class AddProtocolArticleFail implements Action {
    readonly type = ADD_PROTOCOL_ARTICLE_FAIL;
    constructor(public payload: any) {}
}

export const REMOVE_PROTOCOL_ARTICLE = '[Protocols] Remove Protocol Article ';
export const REMOVE_PROTOCOL_ARTICLE_FAIL = '[Protocols] Remove Protocol Article  Fail';
export const REMOVE_PROTOCOL_ARTICLE_SUCCESS = '[Protocols] Remove Protocol Article  Success';

export class RemoveProtocolArticle implements Action {
    readonly type = REMOVE_PROTOCOL_ARTICLE;
    constructor(
        public payload: {
            protocolId: string;
            ppdId: string;
            parentId: string;
            articleId: string;
        }
    ) {}
}

export class RemoveProtocolArticleSucces implements Action {
    readonly type = REMOVE_PROTOCOL_ARTICLE_SUCCESS;
    constructor(public payload: any) {}
}

export class RemoveProtocolArticleFail implements Action {
    readonly type = REMOVE_PROTOCOL_ARTICLE_FAIL;
    constructor(public payload: any) {}
}

export type ProtocolArticlesAction =
    | LoadProtocolArticle
    | LoadProtocolArticleFail
    | LoadProtocolArticleSucces
    | AddProtocolArticle
    | AddProtocolArticleFail
    | AddProtocolArticleSucces
    | RemoveProtocolArticle
    | RemoveProtocolArticleFail
    | RemoveProtocolArticleSucces;
