import * as fromActions from '../actions/outcome.action';

export interface Outcomes {
    [ppdId: string]: any;
}

export interface OutcomeState {
    entries: Outcomes;
}

export const initialState: OutcomeState = {
    entries: {}
};

export function reducer(state = initialState, action: fromActions.OutcomeAction): OutcomeState {
    switch (action.type) {
        case fromActions.GET_OUTCOME: {
            const { payload } = action;
            const { ppdId } = payload;

            return {
                ...state,
                entries: {
                    ...state.entries,
                    [ppdId]: null
                }
            };
        }

        case fromActions.GET_OUTCOME_SUCCESS: {
            const { ppdId, outcome } = action.payload;

            return {
                ...state,
                entries: {
                    ...state.entries,
                    [ppdId]: outcome
                }
            };
        }

        case fromActions.GET_OUTCOME_FAIL: {
            const { ppdId, error } = action.payload;

            return {
                ...state,
                entries: {
                    ...state.entries,
                    [ppdId]: null
                }
            };
        }
    }
    return state;
}

export const getOutcomeEntries = (state: OutcomeState) => state.entries;
