<div id="loadingContent" *ngIf="loading">
    <div class="spinner">
        <div class="dot1"></div>
        <div class="dot2"></div>
    </div>
</div>
<div class="row force-fit" *ngIf="!loading">    
    
    <div class="layout">

        <div class="menuBar">
                <alii-web-protocol-menubar
                *ngIf="protocol"
                (eventBus)="handleEventBus($event)"
                [isEditAble]="isEditAble"
                [protocol]="protocol"
                [views]="views"
                [version]=version
                style="width: 100%"
                >
            </alii-web-protocol-menubar>
        </div>

    <!--    <div class="layout__page">-->
    <alii-web-protocol-sidebar
            *ngIf="protocol"
            (eventBus)="handleEventBus($event)"
            [isEditAble]="true"
            [protocol]="protocol"
            [versionId]="versionId"
            [version]="version"
            [currentVersion]="currentVersion"
            [draftVersion]="draftVersion"
            [deprecatedVersions]="deprecatedVersions"
            [storedDraftVersions]="storedDraftVersions"
            [scrollParagraphId]="scrollParagraphId"
        >
    </alii-web-protocol-sidebar>
        <main id="content" class="layout__content " *ngIf="pane === 'content'">
            <div class="page">
                <div #scroll class="page__content">
                    <div id="loadingContent" style="display: block;" *ngIf="loading">
                        <div class="spinner">
                            <div class="dot1"></div>
                            <div class="dot2"></div>
                        </div>
                    </div>
                    <ng-container *ngIf="!loading">
                        <div id="protocolContent" class="mainContentArea">
                            <div [hidden]="currentView !== 'flowchart'" id="flowChart" class="container-fluid">
                                <flowchart-detail
                                    *ngIf="views.includes('flowchart')"
                                    [scheme]="protocol.flowchart?.scheme | asHtml"
                                    (eventBus)="handleEventBus($event)"
                                >
                                </flowchart-detail>
                            </div>
                            <div *ngIf="currentView == 'text' || currentView == 'populations'">
                                <alii-web-protocol-info
                                    [protocol]="protocol"
                                    [version]="version"
                                    (eventBus)="handleEventBus($event)"
                                ></alii-web-protocol-info>
                                <div class="page__section  -small-bottom-padding">
                                    <ng-container *ngIf="protocol.flowchart.cards.length">
                                        <div class="row">
                                            <div class="col">
                                                <div class="button-group mb-1 pull-right">
                                                    <div [id]="'cogwheel-flowchart-' + protocol.id" class="btn-group cogwheel-flowchart" ngbDropdown placement="bottom-right">
                                                        <button
                                                            type="button"
                                                            class="button -light"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            ngbDropdownToggle
                                                        >
                                                            <i class="fa fa-cog"></i>
                                                        </button>
                                                        <ul class="dropdown-menu right" ngbDropdownMenu>
                                                            <li>
                                                                <a
                                                                    class="dropdown-item"
                                                                    style="cursor: pointer;"
                                                                    (click)="deleteFlowchart($event)"
                                                                >
                                                                    Delete
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div *ngIf="false" class="pull-right">
                                                    <a class="tag -primary mr-2">New</a>
                                                    <a class="tag -secondary cursor-pointer mr-2"
                                                       title="Compare with previous version">Updated</a>
                                                </div>
                                            </div>
                                        </div>
                                        <alii-web-flowchart-area
                                            class="canvas"
                                            size="fit"
                                            [scheme]="protocol.flowchart.scheme | asHtml"
                                            [flowchart]="protocol.flowchart"
                                            (click)="openFlowchart($event)"
                                        >
                                        </alii-web-flowchart-area>
                                        <!--<div id="canvas" #canvas class="canvas" (click)="openFlowchart($event)"></div>-->
                                    </ng-container>
                                    <ng-container *ngIf="!protocol.flowchart.cards.length">
                                        <h2>
                                            <a
                                                href="#"
                                                class="cursor-pointer pl-3"
                                                (click)="createFlowchart($event)">
                                                <i class="fa fa-plus-circle"></i>
                                            </a>
                                            <a
                                                href="#"
                                                class="cursor-pointer pl-2 create-flowchart"
                                                (click)="createFlowchart($event)">Create flowchart</a>
                                        </h2>
                                    </ng-container>
                                </div>

                                <alii-web-paragraph-edit
                                    [isRoot]="true"
                                    (eventBus)="handleEventBus($event)"
                                    [channelMessages]="channelMessages"
                                    [updateModelBySheetIdArrayLoading]="updateModelBySheetIdArrayLoading"
                                    [protocolId]="protocol.id"
                                    [hasNumberedIndex]="protocol.hasNumberedIndex"
                                    [parentId]="null"
                                    [paragraph]="{ children: protocol.paragraphs }"
                                    [isEditAble]="isEditAble"
                                    [hasFindings]="protocol.hasFindings"
                                    [paragraphSelectedId]="paragraphSelectedId"
                                    [modelMessages]="modelMessages"
                                    [keyQuestionImportList]="keyQuestionImportList"
                                    [gradeAssessment]="gradeAssessment"
                                    [paragraphsFiles]="paragraphsFiles"
                                    [protocolArticle]="protocolArticle"
                                    [modelFindings]="modelFindings"
                                    [modelOutcomes]="modelOutcomes"
                                    [modelTagList]="modelTagList"
                                    [viewByPopulation]="viewByPopulation"
                                    [populations]="populations"
                                    [populationId]="populationId"
                                >
                                </alii-web-paragraph-edit>
                                <div class="page__section emptyPar" data-parid="0">
                                    <alii-web-paragraph-edit-options
                                        *ngIf="isEditAble"
                                        (eventBus)="handleEventBus($event)"
                                        [channelMessages]="channelMessages"
                                        [protocolId]="protocol.id"
                                        [ppdId]="null"
                                        [level]="0"
                                        [keyQuestionImportList]="keyQuestionImportList"
                                        [pubmeds]="pubmeds"
                                        [type]="type"
                                        class=" pull-right"
                                    ></alii-web-paragraph-edit-options>
                                    <protocol-paragraph-add
                                        (eventBus)="handleEventBus($event)"
                                        [protocolId]="protocol.id"
                                        [type]="'chapter'"
                                    ></protocol-paragraph-add>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="page__corner -top -right" style="z-index: 400" *ngIf="views.length > 1">
                <div class="button-group">
                    <a
                        class="button toFlow"
                        (click)="handleSwitchView('flowchart')"
                        [ngClass]="currentView == 'flowchart' ? '-primary' : '-light'"
                        *ngIf="views.includes('flowchart')"
                    >
                        <span class="button__icon"><span class="icon-alii-flowchart"></span></span> Flow
                    </a>
                    <a
                        class="button toPopulation"
                        (click)="handleSwitchView('populations')"
                        [ngClass]="currentView == 'populations' ? '-primary' : '-light'"
                        *ngIf="views.includes('populations')"
                    >
                        <span class="button__icon"><span class="icon-alii-populations"></span></span> Population
                    </a>
                    <a
                        class="button toText"
                        (click)="handleSwitchView('text')"
                        [ngClass]="currentView == 'text' ? '-primary' : '-light'"
                    >
                        <span class="button__icon"><span class="icon-alii-text"></span></span> Text
                    </a>
                </div>
            </div>
        </main>
        <main id="content" class="layout__content" *ngIf="pane === 'settings'">
            <alii-web-protocol-settings (eventBus)="handleEventBus($event)"
                                        [protocol]="protocol"></alii-web-protocol-settings>
        </main>
        <!--</div>-->
    </div>
</div>
