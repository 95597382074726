import { Component, ChangeDetectionStrategy, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { Paragraph } from '../../../../../../models/paragraph.interface';
import * as fromStore from '../../../../../search/store';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-text-detail',
    templateUrl: './text-detail.component.html',
    styleUrls: ['./text-detail.component.scss']
})
export class TextDetailComponent implements OnInit, OnChanges {
    @Input() paragraph: Paragraph;
    @Input() parId: string;
    @Input() text: string;
    @Input() title: string;
    @Input() expanded_text: string;
    @Input() isEditAble: boolean;
    @Input() linkedProtocol: string;

    combined: string;
    html: SafeHtml;

    constructor(private sanitizer: DomSanitizer, private store: Store<fromStore.SearchFeatureState>) {}

    ngOnInit() {
        // Transform the footnote placeholders '<span ...>[link|url]</span>' or <span...>[article|id]</span>
        // to '<span ...></span' in order to minimize flicker for paragraphs with many footnotes.
        // See the renderFootnote() function in lib/footnote.ts for more information.
        this.text = this.text.replace(/\[(article|link)\|(.*?)]/g, '*');
        if(this.expanded_text) {
            this.combined = this.text + "<br/>" + this.expanded_text;
            this.html = this.sanitizer.bypassSecurityTrustHtml(this.combined);
        } else {      
        this.html = this.sanitizer.bypassSecurityTrustHtml(this.text);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        const { paragraph } = changes;
        if (paragraph && paragraph.currentValue && paragraph.firstChange) {
            // If a search has been done and points to text in this paragraph, highlight all instances of the search
            // query string present in the paragraph.
            if (this.paragraph && this.paragraph.anchorId) {
                this.store
                    .select(fromStore.getSearchGetPayload)
                    .pipe(take(1))
                    .subscribe(searchDTO => {
                        if (
                            searchDTO &&
                            searchDTO.anchorId &&
                            this.paragraph.anchorId === searchDTO.anchorId.toString()
                        ) {
                            const regex = new RegExp(searchDTO.q, 'gi');
                            const match = this.text.match(regex);
                            if (match) {
                                const span = `<span style="background-color: yellow">${match[0]}</span>`;
                                this.html = this.sanitizer.bypassSecurityTrustHtml(this.text.replace(regex, span));
                            }
                        }
                    });
            }
        }
    }
}
