<div class="modal-header">
    <h4 class="modal-title text-capitalize">{{data.title}}</h4>
    <button type="button" class="close" aria-label="Cancel" (click)="activeModal.dismiss(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container">
        <div class="row">
            <p>{{data.text}}.</p>
        </div>
        <div class="row">
            <form [formGroup]="form">
                <div class="d-flex justify-content-between p-2">
                    <button [disabled]="page === 0" type="button" class="btn btn-primary col-1 px-2 text-capitalize"
                            (click)="onClickFirst()">
                        &lt; &lt;
                    </button>
                    <button [disabled]="page === 0" type="button" class="btn btn-primary col-1 px-2 text-capitalize"
                            (click)="onClickPrevious()"> &lt;
                    </button>
                    <input class="form-control col-8 px-2" type="text" name="searchTerm" formControlName="search"
                           placeholder="Search" />
                    <button [disabled]="page === lastPage" type="button"
                            class="btn btn-primary col-1 px-2 text-capitalize"
                            (click)="onClickNext()"
                    > &gt;
                    </button>
                    <button [disabled]="page === lastPage" type="button"
                            class="btn btn-primary col-1 px-2 text-capitalize"
                            (click)="onClickLast()"> &gt; &gt;
                    </button>
                </div>

                <table *ngIf="pagedProtocols?.length; else empty" class="table table-striped">
                    <thead>
                    <tr>
                        <th></th>
                        <th scope="col" class="w-20 cursor-pointer" *ngFor="let key of keys"
                            (click)="onSort($event)">{{key}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let protocol of pagedProtocols; trackBy: trackByFn">
                        <td><input type="radio" name="protocolId" formControlName="protocolId" [value]="protocol.id">
                        </td>
                        <td *ngFor="let key of keys" class="w-20">
                            {{protocol[key]}}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <ng-template #empty>
                    <div class="row text-center m-4">No protocols.</div>
                </ng-template>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button *ngIf="data.protocols?.length" [disabled]="form.invalid" type="button"
            class="btn btn-primary text-capitalize"
            aria-label="Confirm"
            (click)="onSubmit()">{{data.buttons.confirm}}</button>
    <button type="button" class="btn btn-info text-capitalize" aria-label="Cancel"
            (click)="activeModal.dismiss(false)">{{data.buttons.cancel}}</button>
</div>
