import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class ProtocolService {
    constructor(private http: HttpClient) {}

    getProtocol(payload): Observable<any> {
        const { protocolId } = payload;
        const endpointUrl = `${environment.baseUrl}/api/protocol/getProtocolContent.vm?protocolId=${protocolId}`;

        return this.http.get<any>(endpointUrl).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }
}
