import { createSelector } from '@ngrx/store';

import * as fromModelReducer from '../reducers/model.reducer';
import * as fromReducers from '../reducers';
import { Models } from '../reducers/model.reducer';

export const getModelState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.model
);

export const getModelEntries = createSelector(
    getModelState,
    fromModelReducer.getModelEntries
);

export const getAllModels = createSelector(getModelEntries, entries => {
    return Object.keys(entries).map(id => entries[id]);
});

export const getAllModelIds = createSelector(getModelEntries, entries => {
    return Object.keys(entries);
});

export const getModelEntry = () => createSelector(
    getModelEntries,
    (entities: Models, ppdId: string) => entities[ppdId]
);
