import { EditDashboardModalComponent } from './edit-dashboard-modal/edit-dashboard-modal.component';
import { EditTagModalComponent } from './edit-tag-modal/edit-tag-modal.component';
import { RemoveDataModalComponent } from './remove-data-modal/remove-data-modal.component';

import { PatientAddModalComponent } from './patient-add-modal/patient-add-modal.component';
import { PatientIncludeModalComponent } from './patient-include-modal/patient-include-modal.component';
import { EditTitleModalComponent } from './edit-title-modal/edit-title-modal.component';

export const entryComponents = [
    EditDashboardModalComponent,
    EditTagModalComponent,
    RemoveDataModalComponent,

    PatientAddModalComponent,
    PatientIncludeModalComponent,

    EditTitleModalComponent
];

export * from './edit-dashboard-modal/edit-dashboard-modal.component';
export * from './edit-tag-modal/edit-tag-modal.component';
export * from './remove-data-modal/remove-data-modal.component';

export * from './patient-add-modal/patient-add-modal.component';
export * from './patient-include-modal/patient-include-modal.component';
export * from './edit-title-modal/edit-title-modal.component';
