import { Action } from '@ngrx/store';

export const GET_OUTCOME = '[Protocols] Get Outcome';
export const GET_OUTCOME_FAIL = '[Protocols] Get Outcome Fail';
export const GET_OUTCOME_SUCCESS = '[Protocols] Get Outcome Success';

export class GetOutcome implements Action {
    readonly type = GET_OUTCOME;

    constructor(public payload: { ppdId: string, populationId: string }) {
    }
}

export class GetOutcomeFail implements Action {
    readonly type = GET_OUTCOME_FAIL;

    constructor(public payload: { ppdId: string, error: { message: string } }) {
    }
}

export class GetOutcomeSucces implements Action {
    readonly type = GET_OUTCOME_SUCCESS;

    constructor(public payload: { ppdId: string, outcome: any }) {
    }
}

export type OutcomeAction =
    | GetOutcome
    | GetOutcomeFail
    | GetOutcomeSucces;
