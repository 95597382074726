import { createSelector } from '@ngrx/store';

import * as fromIndexReducers from '../reducers';
import * as fromReducer from '../reducers/dashboard-form.reducer';

const getDashboardFormState = createSelector(
    fromIndexReducers.getDashboardState,
    (state: fromIndexReducers.DashboardState) => state.dashboardForm
);
export const getDashboardFormValues = createSelector(
    getDashboardFormState,
    fromReducer.getDashboardFormValues
);
