import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as fromStore from './../../store';
import { ShopTemplateSet, Step } from '../../../../models/shop-template-set.interface';
import { DashboardService, ShopService } from '../../../../services';
import { take } from 'rxjs/operators';

@Component({
    selector: 'alii-web-template-set-update-modal',
    templateUrl: './template-set-update-modal.html',
    styleUrls: ['./template-set-update-modal.scss']
})
export class TemplateSetUpdateModalComponent implements OnInit, OnDestroy {
    @Input() templateSet: ShopTemplateSet;

    currentStep: number;
    currentAction: number;

    title: string;
    steps: Step[];

    form: FormGroup;

    formNewTeam: FormGroup;
    selectedAction: string;
    selectedTeam: {
        label: string;
        text?: string;
        teamId: string;
        form?: Array<{ label: string; name: string; type: string }>;
    };

    // True if in settings modal.
    settings: boolean;

    loading$: Observable<boolean>;
    subscr: Subscription;

    hospital: { id: string; name: string };
    loadingNewTeam = false;

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private shopService: ShopService,
        private dashboardService: DashboardService,
        private store: Store<fromStore.ShopFeatureState>
    ) {}

    ngOnInit() {
        // TODO: Implement store in the correct way, now direct call to service.
        this.loading$ = this.store.select(fromStore.updateShopTemplateSetLoading);
        this.subscr = this.store.select(fromStore.updateShopTemplateSet).subscribe();

        this.title = this.templateSet.actions[0].label;
        this.settings = this.title === 'instellingen';
        this.steps = [...this.templateSet.actions[0].steps];
        this.steps = this.steps.sort((a, b) => (a.index > b.index ? 1 : a.index < b.index ? -1 : 0));
        // Find selected team id, if any.
        this.steps.forEach(step => {
            step.actions.forEach(action => {
                if (action.type === 'select') {
                    this.selectedTeam = action.options.find(option => option.selected);
                }
            });
        });

        this.currentStep = 0;
        this.currentAction = 0;

        this.form = this.fb.group({});
        this.formNewTeam = this.fb.group({});

        // Get current hospital, needed if new team is created.
        this.store
            .select(fromStore.getShopHospital)
            .pipe(take(1))
            .subscribe(hospital => (this.hospital = hospital));
    }

    ngOnDestroy() {
        if (this.subscr) {
            this.subscr.unsubscribe();
        }
    }

    onChangeTeam(indStep: number, indAction: number, value: string) {
        this.selectedTeam = this.steps[indStep].actions[indAction].options.find(option => option.label === value);
        const controlsConfig = {};
        if (this.selectedTeam.form) {
            // Build form dynamically and display
            this.selectedTeam.form
                .filter(c => c.type === 'input')
                .forEach(c => {
                    const validator = c.name.toLowerCase().includes('email') ? [Validators.email] : [];
                    controlsConfig[c.name] = new FormControl('', [Validators.required, ...validator]);
                });
        }
        this.formNewTeam = this.fb.group(controlsConfig);
        this.onAction(indStep, indAction)
    }

    onChangeAction(indStep: number, indAction: number, value: string) {
        this.selectedAction = value;
        this.onAction(indStep, indAction)
    }

    onAction(indStep: number, indAction: number) {
        const step = this.steps[indStep];
        const actions = step.actions;
        const action = actions[indAction];
        const actionCall = action.action;
        if (actionCall === 'submit') {
            this._handleSubmit('activate');
        } else if (this.settings && actionCall === 'deactivate') {
            if (window.confirm('Are you sure that you want to deactivate this template set?')) {
                this._handleSubmit(actionCall);
            }
        } else if (this.settings && actionCall === 'assign') {
            this._handleSubmit(actionCall);
        } else {
            if (indAction === actions.length - 1) {
                this.currentStep++;
                this.currentAction = -1;
            }
            this.currentAction++;
            if (this.currentStep === this.steps.length + 1) {
                this.activeModal.close(true);
            }
        }
    }

    submitNewTeam(indStep: number, indAction: number) {
        const payload = { ...this.formNewTeam.value, protocolSetId: this.templateSet.id };
        this.loadingNewTeam = true;
        this.dashboardService.createDashboard(payload).subscribe(
            result => {
                if (result.message === 'success') {
                    const options = this.steps[indStep].actions[indAction].options;
                    // Unselect all options in the team list
                    options.forEach(opt => delete opt.selected);
                    // Insert the new team at the top of the list and select it.
                    options.unshift({
                        // label: `${payload.teamName} (${this.hospital.name})`,
                        label: result.dashboardTitle,
                        teamId: result.dashboardId,
                        selected: true
                    });
                    // Reset the selected team to just created.
                    this.selectedTeam = this.steps[indStep].actions[indAction].options[0];
                }
            },
            error => console.error(error),
            () => (this.loadingNewTeam = false)
        );
    }

    cancelNewTeam() {
        this.formNewTeam = this.fb.group({});
        this.selectedTeam = null;
    }

    onCancel() {
        this.activeModal.dismiss();
    }

    // Private
    _handleSubmit(action: string) {
        const teamId = this.selectedTeam ? this.selectedTeam.teamId : null;
        this.activeModal.close({ id: this.templateSet.id, action: this.selectedAction, teamId });
    }
}
