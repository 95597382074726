import { createSelector } from '@ngrx/store';

import * as fromIndexReducers from '../reducers';
import * as fromReducer from '../reducers/dashboard-tag.reducer';

const getDashboardTagsState = createSelector(
    fromIndexReducers.getDashboardState,
    (state: fromIndexReducers.DashboardState) => state.dashboardTag
);
export const getDashboardTags = createSelector(
    getDashboardTagsState,
    fromReducer.selectAll
);
export const getSelectedDashboardTag = createSelector(
    getDashboardTagsState,
    fromReducer.getSelectedDashboardTagId,
    entities => {
        return entities.entities[entities.selectedDashboardTagId];
    }
);
