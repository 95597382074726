<div class="modal-header">
    <h5 class="modal-title" id="flowchart-delete-label">{{title}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <ng-form [formGroup]="form">
        <div class="form-group">{{text}}</div>
    </ng-form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
    <button [disabled]="form.invalid" type="button" class="btn btn-danger" (click)="handleSubmit()">Delete
    </button>
</div>
