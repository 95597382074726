import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Pubmed } from '../models/pubmed.interface';

@Injectable()
export class PubmedService {
    constructor(private http: HttpClient) {}

    search(query: string): Observable<Pubmed[]> {
        const url = `${environment.baseUrl}/api/protocol/article/search.vm?query=${query}`;
        return this.http.get<any>(url).pipe(
            map(response => {
                const { esummaryresult } = response;
                if (esummaryresult) {
                    const pubmeds: any[] = Object.values([]);
                    return pubmeds;
                } else {
                    const {
                        result: { uids, ...ids }
                    } = response;
                    const pubmeds: any[] = esummaryresult ? Object.values([]) : Object.values(ids);
                    return pubmeds;
                }
            }),
            catchError((error: any) => throwError(error))
        );
    }
}
