import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromStore from './../../store';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-protocols-import',
    template: `
        <alii-web-protocol-import
            [protocols]="protocols$"
            (eventBus)="handleEventBus($event)"
        ></alii-web-protocol-import>
    `
})
export class ProtocolsImportComponent implements OnInit {
    protocols$: Observable<any[]>;

    constructor(private store: Store<fromStore.ProtocolsFeatureState>) {}

    ngOnInit() {
        this.protocols$ = this.store.select(fromStore.getSearchProtocolEntries);
    }

    handleEventBus(event) {
        const { type, payload } = event;
        switch (type) {
            case 'handleGetProtocolsByQuery':
                this.handleGetProtocolsByQuery(payload);
                break;
            case 'handleImportProtocol':
                this.handleImportProtocol(payload);
                break;
            default:
                break;
        }
    }

    handleGetProtocolsByQuery(payload) {
        const { query } = payload;
        this.store.dispatch(new fromStore.GetProtocolsByQuery({ query }));
    }

    handleImportProtocol(payload) {
        const { protocolId } = payload;
        this.store.dispatch(new fromStore.CopyProtocol({ parentId: protocolId }));
    }
}
