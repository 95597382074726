import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgxPermissionsModule } from 'ngx-permissions';

// Translation stuff, see: https://github.com/ngx-translate/core
import { TranslateModule } from '@ngx-translate/core';

import { reducers, effects } from './store';
import * as fromServices from './services';
import * as fromComponents from './components';
import * as fromContainers from './containers';
import { PartialsModule } from '../../partials/partials.module';
import { StartingPageRoutingModule } from './starting-page-routing.module';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        StoreModule.forFeature('StartingPageFeature', reducers),
        StartingPageRoutingModule,
        NgxPermissionsModule.forChild(),
        EffectsModule.forFeature(effects),
        PartialsModule,
        TranslateModule.forChild({ extend: true })
    ],
    providers: [...fromServices.services],
    declarations: [...fromComponents.components, ...fromContainers.containers],

    exports: [...fromContainers.containers]
})
export class StartingPageModule {}
