import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-edit-dashboard-modal',
    templateUrl: './edit-dashboard-modal.component.html'
})
export class EditDashboardModalComponent implements OnInit {
    name: string;

    form: FormGroup;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            name: [this.name, Validators.required]
        });
    }
    handleSubmit() {
        if (this.form.valid) {
            const data = {
                ...this.form.value
            };
            this.activeModal.close(data);
        }
    }
}
