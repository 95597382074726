import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class FlowchartService {
    constructor(private http: HttpClient) {
    }

    createFlowchart(payload): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/flowchart/createFlowchart.vm`;
        return this.http.post<any>(url, payload).pipe(catchError((error: any) => throwError(error)));
    }

    createStartPoint(payload): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/flowchart/updateFlowchart.vm`;
        const { protocolId, ppdId, title = '' } = payload;
        const postData = {
            protocolId,
            startId: ppdId,
            startOfFlow: true,
            ...(ppdId === 'new' ? title : [])
        };

        return this.http.post<any>(url, postData).pipe(catchError((error: any) => throwError(error)));
    }

    mapOptionalUpdatePayload(payload) {
        return payload;
        // This function does not seem to be neccessary, so we removed it, also to improve compatibilty 
        // with the new backend.
        const optionalPayload = [
            'ppdId',
            'contentLink',
            'startOfFlow',
            'hideSteps',
            'shortRightArrow',
            'borderless',
            'parallel',
            'invertArrows',
            'yesArrowId',
            'yesArrowTitle',
            'yesArrowNewBlock',
            'yesArrowNoSteps',
            'yesContentLink',
            'noArrowId',
            'noArrowTitle',
            'noArrowNewBlock',
            'noArrowNoSteps',
            'noContentLink'
        ];
        return Object.keys(payload).reduce((acc, value) => {
            if (optionalPayload.includes(value) && payload[value] !== null) {
                return { ...acc, [value]: payload[value] };
            }
            return acc;
        }, {});
    }

    updateCard(payload): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/flowchart/updateFlowchart.vm`;
        const { protocolId, id, ppdId, title } = payload;
        const postData = {
            protocolId,
            id,
            title,
            ppdId,
            ...this.mapOptionalUpdatePayload(payload)
        };
        return this.http.post<any>(url, postData).pipe(catchError((error: any) => throwError(error)));
    }

    delete(payload): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/flowchart/updateFlowchart.vm`;
        const { protocolId, id } = payload;
        const postData = {
            protocolId,
            id,
            removeStart: true
        };
        return this.http.post<any>(url, postData).pipe(catchError((error: any) => throwError(error)));
    }
}
