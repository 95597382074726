<section class="page__section -no-bottom mb-3">
    <div class="dashboard">
        <div *ngIf="dashboardTitle" class="dashboard__title">{{dashboardTitle}}</div>
        <div class="row">
            <div class="col-9">
                <div *ngIf="messageTitle" class="message-title">{{messageTitle}}</div>
                <p *ngIf="messageBody" class="message-body" [innerHTML]="safeHtml(messageBody)"></p>
            </div>
            <div *ngIf="ownerExists" class="col-3">
                <div class="row">
                    <div class="col">
                        <img *ngIf="ownerImageUrl" class="owner-image" [src]="ownerImageUrl"
                             alt="Owner image" />
                        <div *ngIf="!ownerImageUrl" class="owner-image height-100"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p *ngIf="ownerName" class="owner-name">{{ ownerName }}</p>
                        <p *ngIf="ownerText" class="owner-text" [innerHTML]="safeHtml(ownerText)"></p>
                    </div>
                </div>
            </div>
        </div>
        <ng-content></ng-content>
    </div>
</section>
