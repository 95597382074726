import {
    Component,
    Input,
    ChangeDetectionStrategy,
    OnInit,
    ViewChildren,
    QueryList,
    AfterViewInit,
    ElementRef, Renderer2
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ShopCategory } from '../../../../../models/shop-category.interface';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-shop-category-card',
    templateUrl: './category-card.component.html',
    styleUrls: ['./category-card.component.scss']
})
export class CategoryCardComponent implements OnInit, AfterViewInit {

    @ViewChildren('card') cards: QueryList<ElementRef>;

    @Input() categories: ShopCategory[];

    constructor(private sanitizer: DomSanitizer, private renderer: Renderer2) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const cards = this.cards.toArray();
            let max = 0;
            // Get max height
            cards.forEach(card => {
                const height = card.nativeElement.offsetHeight;
                if (max < height) {
                    max = height;
                }
            });
            // Adjust all card height to max
            cards.forEach(card => this.renderer.setStyle(card.nativeElement, 'height', max + 'px'));

        }, 200);
    }

    safeHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    trackByFn = (index, item) => item.id || index;
}
