<alii-web-spinner-loading *ngIf="(loading$ | async)"></alii-web-spinner-loading>
<ng-container *ngIf="(error$ | async) as error">
    <div class="container mt-4">
        <p class="row">{{ 'ERROR' | translate}}: {{error}}</p>
    </div>
</ng-container>
<ng-container *ngIf="(loaded$ | async) as loaded">
    <section class="page__section -no-bottom pb-3 mb-3">
        <alii-web-help-users-guide
            [dashboardTitle]="loaded.hospital_name"
            [messageTitle]="loaded.message_title"
            [messageBody]="loaded.message_body"
            [ownerName]="loaded.owner"
            [ownerText]="loaded.owner_text"
            [ownerImageUrl]="loaded.owner_image_url"

        ></alii-web-help-users-guide>
    </section>
    <alii-web-help-library-cards [categories]="loaded.categories">
    </alii-web-help-library-cards>
</ng-container>
