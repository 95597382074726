<div class="layout">
    <div class="layout__page">
        <main id="content" class="layout__content ">
            <div class="page">
                <div class="page__content">
                    <div
                        class="message"
                        style="z-index: 999; position: fixed; top: 0; width:100%; padding: 20px; background: #fff;"
                    >
                        <h1>Print as PDF</h1>
                        <p>You can print the protocol as PDF by clicking Print and selecting PDF as the printer.</p>
                        <input
                            type="button"
                            class="button -primary"
                            (click)="printPdf()"
                            value="Print protocol as PDF"
                        />
                        <input
                            type="button"
                            class="button -primary"
                            (click)="closePrintPdf()"
                            value="Back to protocol"
                        />
                    </div>
                    <div id="protocolContent" class="mainContentArea">
                        <ng-container>
                            <alii-web-paragraph-detail
                                (eventBus)="handleEventBus($event)"
                                [protocolId]="content.id"
                                [parentId]="null"
                                [paragraph]="paragraph"
                                *ngFor="let paragraph of content.paragraphs; trackBy: trackByFn"
                                [isEditAble]="isEditAble"
                                [paragraphSelectedId]="paragraphSelectedId"
                            >
                            </alii-web-paragraph-detail>
                        </ng-container>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
