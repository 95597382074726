import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import * as fromContainers from './containers';
import { NotFoundRoutingModule } from './not-found-routing.module';

@NgModule({
    imports: [
        CommonModule,
        NotFoundRoutingModule,
        TranslateModule.forChild({ extend: true })
    ],
    declarations: [...fromContainers.containers],
    exports: [...fromContainers.containers]
})
export class NotFoundModule {}
