import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';

import * as dashboardActions from '../actions/dashboard-summary.action';
import * as dashboardCategoriesActions from '../actions/dashboard-category.action';
import * as setDashboardActions from '../../../starting-page/store/actions/set-dashboard.action';

import * as fromService from '../../../../services/dashboard.service';

import { of } from 'rxjs';

export interface PayloadAction {
    type: string;
    payload: any;
}

@Injectable()
export class DashboardEffects {
    constructor(private actions$: Actions, private dashboardService: fromService.DashboardService) {}

    @Effect()
    CreateCategoryEffect$ = this.actions$.pipe(
        ofType(dashboardCategoriesActions.CREATE_CATEGORY),
        switchMap(action => {
            const { payload } = action as any;
            const { dashboardId, title } = payload;

            return this.dashboardService.createTag({ title }).pipe(
                map(response => {
                    const { tag_id } = response;
                    return new dashboardCategoriesActions.CreateCategorySucces({
                        dashboardId,
                        tagId: tag_id
                    });
                }),
                catchError(error => of(new dashboardCategoriesActions.CreateCategoryFail(error)))
            );
        })
    );
    @Effect()
    CreateCategorySuccesEffect$ = this.actions$.pipe(
        ofType(dashboardCategoriesActions.CREATE_CATEGORY_SUCCESS),
        mergeMap(action => {
            const {
                payload: { dashboardId, tagId }
            } = action as any;

            return [new dashboardCategoriesActions.AddDashboardCategory({ dashboardId, tagId })];
        })
    );

    @Effect()
    AddDashboardCategoryEffect$ = this.actions$.pipe(
        ofType(dashboardCategoriesActions.ADD_DASHBOARD_CATEGORY),
        switchMap(action => {
            const { payload } = action as any;
            const { dashboardId, tagId } = payload;

            return this.dashboardService.addDashboardTag({ dashboardId, tagId }).pipe(
                map(response => {
                    return new dashboardCategoriesActions.AddDashboardCategorySucces(payload);
                }),
                catchError(error => of(new dashboardCategoriesActions.AddDashboardCategoryFail(error)))
            );
        })
    );

    @Effect()
    RemoveDashboardCategoryEffect$ = this.actions$.pipe(
        ofType(dashboardCategoriesActions.REMOVE_DASHBOARD_CATEGORY),
        switchMap(action => {
            const { payload } = action as any;
            const { dashboardId, tagId } = payload;

            return this.dashboardService.removeDashboardTag({ dashboardId, tagId }).pipe(
                map(response => {
                    return new dashboardCategoriesActions.RemoveDashboardCategorySucces(payload);
                }),
                catchError(error => of(new dashboardCategoriesActions.RemoveDashboardCategoryFail(error)))
            );
        })
    );

    @Effect()
    ReloadDashboardCategorySucces$ = this.actions$.pipe(
        ofType(
            dashboardCategoriesActions.ADD_DASHBOARD_CATEGORY_SUCCESS,
            dashboardCategoriesActions.REMOVE_DASHBOARD_CATEGORY_SUCCESS
        ),
        mergeMap(action => {
            const {
                payload: { dashboardId }
            } = action as any;

            return [new dashboardCategoriesActions.LoadDashboardCategories(dashboardId)];
        })
    );
}
