<div *ngIf="checkSourceTable()">    
    <h4>
        {{'LITERATURE.FINDINGSTOINCLUDE' | translate}}</h4>
    <ng-container *ngFor="let sourceData of sourceTable">
        <aggrid-table
            [rowData]="sourceData.rowData"
            [columnDefs]="sourceData.columnDefs"
            (eventBus)="handleEventBus($event)"
            [paragraphId]="paragraphId"
            [isEditAble]="isEditAble"
            >
        </aggrid-table>
    </ng-container>
    <h4 *ngIf="gradeRowData && gradeRowData.length">{{ 'LITERATURE.PARAGRAPH.GRADE' | translate }}</h4>
    <aggrid-table
        *ngIf="gradeRowData && gradeRowData.length"
        [rowData]="gradeRowData"
        [columnDefs]="gradeColumnDefs"
        (eventBus)="handleEventBus($event)"
        [paragraphId]="paragraphId"
        [isEditAble]="isEditAble"
        >
    </aggrid-table>
</div>
