import { createSelector } from '@ngrx/store';

import * as fromEventbusChannelReducer from '../reducers/eventbus-channel.reducer';
import * as fromReducers from '../reducers';

export const getEventbusChannelState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.eventbusChannel
);

export const getChannelMessages = createSelector(
    getEventbusChannelState,
    fromEventbusChannelReducer.getChannelMessages
);
