<div class="row">
    <ng-container *ngFor="let row of rows; trackBy: trackByFn">
        <div class="col-sm-8 wysiwyg">
            <div class="RowTitle">
                <h5>{{ row['title'] }}</h5>
            </div>
        </div>
        <div class="col-sm-4">
            <span class="small user-text" *ngIf="!!row['text']"> {{ row['text'] }} </span>
        </div>
    </ng-container>
</div>
