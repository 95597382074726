import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

// Translation stuff, see: https://github.com/ngx-translate/core
import { TranslateModule } from '@ngx-translate/core';

import { reducers, effects } from './store';
import * as fromContainers from './containers';
import * as fromServices from '../../services';
import { PartialsModule } from '../../partials/partials.module';
import { TermsRoutingModule } from './terms-routing.module';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        StoreModule.forFeature('TermsFeature', reducers),
        EffectsModule.forFeature(effects),
        FormsModule,
        PartialsModule,
        ReactiveFormsModule,
        TermsRoutingModule,
        TranslateModule.forChild({ extend: true })
    ],
    providers: [...fromServices.services],
    declarations: [...fromContainers.containers],
    exports: [...fromContainers.containers]
})
export class TermsModule {}
