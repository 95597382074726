import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromActions from '../actions';
import { StartingPageService } from '../../services';

@Injectable()
export class StartingPageEffects {
    constructor(private actions$: Actions, private startingPageService: StartingPageService) {}

    @Effect()
    StartingPageGetEffect$ = this.actions$.pipe(
        ofType(fromActions.STARTING_PAGE_GET),
        switchMap(() => {
            return this.startingPageService.get().pipe(
                map(response => {
                    return new fromActions.StartingPageGetSucces(response);
                }),
                catchError(error => {
                    return of(new fromActions.StartingPageGetFail(error));
                })
            );
        })
    );
}
