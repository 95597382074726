import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { OrganisationService } from 'apps/web/src/app/services';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'usergroup-edit',
    templateUrl: './usergroup-edit.component.html',
    styleUrls: ['./usergroup-edit.component.scss']
})
export class UserGroupEditComponent implements OnInit, OnDestroy {
    @Input() users: [];
    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();
    update = new BehaviorSubject<boolean>(false);

    usergroup$: Observable<any>;
    users$: Observable<any>;
    searchresults$: Observable<any>;
    private id;

    constructor(
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private service: OrganisationService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.fetchUserGroup();
        this.fetchUsersInGroup();
        this.update.subscribe(update => {
            update === true ? this.fetchUsersInGroup() : '';
        });
    }

    fetchUserGroup() {
        this.usergroup$ = this.service
            .getUserGroups()
            .pipe(map(groups => groups.usergroup.find(currentgroup => currentgroup.id === this.id)));
    }

    fetchUsersInGroup() {
        this.users$ = this.service.getUsersInGroup(this.id);
    }

    onSearch(name: string) {
        this.searchresults$ = this.service.getUsers(name);
    }

    addToGroup(user: string) {
        this.service.addUserToGroup(this.id, user).subscribe(response => {
            this.update.next(true);
            this.changeDetectorRef.detectChanges();
        });
    }

    removeFromGroup(user: string) {
        this.service.removeUserFromGroup(this.id, user).subscribe(response => {
            this.update.next(true);
            this.changeDetectorRef.detectChanges();
        });
    }

    onUpdateName(name: string) {
        this.service.updateUserGroupName(name, this.id).subscribe(response => {
            window.location.reload();
        });
    }

    ngOnDestroy() {
        this.update.next(true);
    }

    trackByFn = (index, item) => item.id || index;
}
