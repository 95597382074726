import { Action } from '@ngrx/store';
import { Team } from '../../../../models/team.interface';

export const LOAD_TEAMS = '[Organisation] Load Teams';
export const LOAD_TEAMS_FAIL = '[Organisation] Load Teams Fail';
export const LOAD_TEAMS_SUCCESS = '[Organisation] Load Teams Success';

export class LoadTeams implements Action {
    readonly type = LOAD_TEAMS;
}

export class LoadTeamsFail implements Action {
    readonly type = LOAD_TEAMS_FAIL;
    constructor(public payload: any) {}
}

export class LoadTeamsSucces implements Action {
    readonly type = LOAD_TEAMS_SUCCESS;
    constructor(public payload: Team[]) {}
}

export const CREATE_TEAM = '[Organisation] Create Team';
export const CREATE_TEAM_SUCCESS = '[Organisation] Create Team Success';
export const CREATE_TEAM_FAIL = '[Organisation] Create Team Fail';

export class CreateTeam implements Action {
    readonly type = CREATE_TEAM;
    constructor(public payload: any) {}
}

export class CreateTeamFail implements Action {
    readonly type = CREATE_TEAM_FAIL;
    constructor(public payload: any) {}
}

export class CreateTeamSuccess implements Action {
    readonly type = CREATE_TEAM_SUCCESS;
    constructor(public payload: any) {}
}

export const REMOVE_TEAM = '[Organisation] Remove Team';
export const REMOVE_TEAM_SUCCESS = '[Organisation] Remove Team Success';
export const REMOVE_TEAM_FAIL = '[Organisation] Remove Team Fail';

export class RemoveTeam implements Action {
    readonly type = REMOVE_TEAM;
    constructor(public payload: any) {}
}

export class RemoveTeamFail implements Action {
    readonly type = REMOVE_TEAM_FAIL;
    constructor(public payload: any) {}
}

export class RemoveTeamSuccess implements Action {
    readonly type = REMOVE_TEAM_SUCCESS;
    constructor(public payload: any) {}
}

export const UPDATE_TEAM = '[Organisation] Update Team';
export const UPDATE_TEAM_SUCCESS = '[Organisation] Update Team Success';
export const UPDATE_TEAM_FAIL = '[Organisation] Update Team Fail';

export class UpdateTeam implements Action {
    readonly type = UPDATE_TEAM;
    constructor(public payload: any) {}
}

export class UpdateTeamFail implements Action {
    readonly type = UPDATE_TEAM_FAIL;
    constructor(public payload: any) {}
}

export class UpdateTeamSuccess implements Action {
    readonly type = UPDATE_TEAM_SUCCESS;
    constructor(public payload: any) {}
}

export type TeamsAction =
    | LoadTeams
    | LoadTeamsFail
    | LoadTeamsSucces
    | CreateTeam
    | CreateTeamSuccess
    | CreateTeamFail
    | RemoveTeam
    | RemoveTeamSuccess
    | RemoveTeamFail
    | UpdateTeam
    | UpdateTeamSuccess
    | UpdateTeamFail;
