import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as OrganizationActions from '../actions/organization.action';
import * as fromService from '../../../../services/organisation.service';
import { of } from 'rxjs';

@Injectable()
export class OrganizationEffects {
    constructor(private actions$: Actions, private organisationService: fromService.OrganisationService) {}

    @Effect()
    loadOrganization$ = this.actions$.pipe(
        ofType(OrganizationActions.LOAD_ORGANIZATION),
        switchMap(() => {
            return this.organisationService.getOrganization().pipe(
                map(dashboard => new OrganizationActions.LoadOrganizationSuccess(dashboard)),
                catchError(error => of(new OrganizationActions.LoadOrganizationFail(error)))
            );
        })
    );

    @Effect()
    UpdateOrganizationffect$ = this.actions$.pipe(
        ofType(OrganizationActions.UPDATE_ORGANIZATION),
        switchMap(action => {
            const { payload } = action as any;
            return this.organisationService.UpdateOrganization({ payload }).pipe(
                map(response => new OrganizationActions.UpdateOrganizationSuccess (response)),
                catchError(error => of(new OrganizationActions.UpdateOrganizationFail(error)))
            );
        })
    );
}
