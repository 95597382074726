import {Component, ViewChild} from "@angular/core";
import { environment } from "apps/web/src/environments/environment";
import {ICellRendererParams} from "@ag-grid-community/core";

import {AgGridAngular} from 'ag-grid-angular';
import { RowController } from "ag-grid-community";
import { parseMessage } from "@angular/localize/src/utils";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { EditReferenceForParagraph } from "../../../protocols/store";


@Component({
   selector: 'edit-html-component',
   styleUrls: ['./edit-html.component.scss'],
   template: `
    <div class="superContainer">
      <div class="editHTMLContainer">
            
        <textarea #i 
              [froalaEditor]="options"
              [innerHTML]="cellValue"
          ></textarea>
          <span class="saveButton btn btn-outline-primary " (click)="save()">{{'SAVE' | translate}}</span> 
          <span class="closeButton btn btn-outline-secondary " (click)="close()">{{'CLOSE' | translate}}</span> 
      </div>
    </div>
   `
})



export class EditHTML {
   params: any;
   editor: any;
   width: number;
   shiftUp: boolean;
   public cellValue: string;
   @ViewChild('i') textInput;
   @ViewChild('agGrid') agGrid: AgGridAngular;
    
    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.cellValue = this.getValueToDisplay(params);
        this.params = params;
        this.width = params.column.getActualWidth()
        params.api.resetRowHeights();
        if(params.node.rowHeight < 300) {
          params.node.setRowHeight(300)
          params.api.onRowHeightChanged()
        }
    }
 
    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams) {
        // set value into cell again
        this.cellValue = this.getValueToDisplay(params);
    }

    getValueToDisplay(params: ICellRendererParams) {
        return params.valueFormatted ? params.valueFormatted : params.value;
    }

    save() {
      this.params.api.stopEditing();
    }

    close() {
      this.params.api.stopEditing(true);
    }

    getValue() {
        return this.textInput.nativeElement.value;
    }


  public options = {
    key: environment.froala.key,
    attribution: false,
    toolbarVisibleWithoutSelection: false,
    toolbarInline: true,  
    quickInsertTags: [],
    pastePlain: true,
    charCounterCount: false,
    toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'formatUL', 'formatOL'],
    events: {
        'paste.afterCleanup': html => html.replace(';width:0px;', ';'),
        'initialized': function (e) {
          e.getEditor().events.focus() 
        }
    }
};

}