import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-remove-data-modal',
    templateUrl: './edit-title-modal.component.html'
})
export class EditTitleModalComponent implements OnInit {
    headerTitle: string;
    titleValue: string;
    titleLabel: string;

    form: FormGroup;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            title: [this.titleValue, Validators.required]
        });
    }

    handleSubmit() {
        if (this.form.valid) {
            const data = {
                ...this.form.value
            };
            this.activeModal.close(data);
        }
    }
}
