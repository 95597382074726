import { createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';
import * as fromParagraphsReferences from '../reducers/paragraphs-references.reducer';

export const getParagraphsReferencesState = createSelector(
    fromReducers.getProtocolsFeatureState,
    (state: fromReducers.ProtocolsFeatureState) => state.paragraphsReferences
);

export const getParagraphsReferences = createSelector(
    getParagraphsReferencesState,
    fromParagraphsReferences.getParagraphReferences
);
