import * as fromActions from '../actions/protocol-articles.action';

export interface ProtocolArticlesState {
    entries: any;
    selected: any;
    loaded: boolean;
    loading: boolean;
}

export const initialState: ProtocolArticlesState = {
    entries: {},
    selected: {},
    loaded: false,
    loading: false
};

export function reducer(state = initialState, action: fromActions.ProtocolArticlesAction): ProtocolArticlesState {
    switch (action.type) {
        case fromActions.LOAD_PROTOCOL_ARTICLE: {
            return { ...state, loading: true };
        }

        case fromActions.LOAD_PROTOCOL_ARTICLE_SUCCESS: {
            const { article } = action.payload;

            return {
                ...state,
                loading: false,
                loaded: true,
                entries: { ...state.entries, [article.id]: article },
                selected: { ...article }
            };
        }

        case fromActions.LOAD_PROTOCOL_ARTICLE_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
    }
    return state;
}

export const getProtocolArticles = (state: ProtocolArticlesState) => state.entries;
export const getProtocolArticleSelected = (state: ProtocolArticlesState) => state.selected;
export const getProtocolArticlesLoading = (state: ProtocolArticlesState) => state.loading;
export const getProtocolArticlesLoaded = (state: ProtocolArticlesState) => state.loaded;
