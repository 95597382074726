import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    HostListener,
    Input,
    OnInit,
    QueryList,
    Renderer2,
    ViewChildren
} from '@angular/core';

import { StartingPageOnboarding } from '@alii-web/modules/starting-page/models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-dashboard-onboarding-steps',
    templateUrl: './onboarding-steps.component.html',
    styleUrls: ['./onboarding-steps.component.scss']
})
export class OnboardingStepsComponent implements OnInit, AfterViewInit {
    @Input() onboarding: StartingPageOnboarding;

    @ViewChildren('onboardingCard') onboardingCards: QueryList<OnboardingStepsComponent>;
    private nativeElement: HTMLElement;

    @HostListener('window:resize', ['$event']) handleResize() {
        this._handleResize();
    }

    constructor(private renderer: Renderer2) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this._handleResize();
    }

    trackByFn = (index, item) => item.id || index;

    private _handleResize() {
        let max = 0;
        setTimeout(() => {
            this.onboardingCards.toArray().forEach(c => {
                const h = c.nativeElement.offsetHeight;
                if (h > max) {
                    max = h;
                }
            });
            this.onboardingCards.toArray().forEach(c => this.renderer.setStyle(c.nativeElement, 'height', max + 'px'));
        }, 0);
    }
}
