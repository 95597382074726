<alii-web-spinner-loading *ngIf="loading$ | async"></alii-web-spinner-loading>
<ng-container *ngIf="( shop$| async) as shop">

    <alii-web-shop-page-section
        [dashboardTitle]="shop.hospital_name"
        [messageTitle]="shop.message_title"
        [messageBody]="shop.message_body"
        [ownerName]="shop.owner"
        [ownerText]="shop.owner_text"
        [ownerImageUrl]="shop.owner_image_url"
    >
    </alii-web-shop-page-section>

    <section class="page__section -no-bottom -transparent">
        <alii-web-shop-category-card
            [categories]="shop.categories"></alii-web-shop-category-card>
    </section>
</ng-container>
