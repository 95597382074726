import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { DataGridRow } from './search-data-grid.model';

export type SortColumn = keyof DataGridRow | '';
export type SortDirection = 'asc' | 'desc' | '';

const rotate: { [key: string]: SortDirection } = { asc: 'desc', desc: 'asc', '': 'asc' };

export interface SortEvent {
    sortColumn: SortColumn;
    sortDirection: SortDirection;
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'th[sortable]'
})
export class SearchSortableHeaderDirective implements OnInit, OnDestroy {
    @Input() sortable: SortColumn = '';
    @Input() direction: SortDirection = '';
    @Output() sort = new EventEmitter<SortEvent>();

    private listener: () => void;
    private el: HTMLElement;

    constructor(private host: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        this.el = this.host.nativeElement;
        this.listener = this.renderer.listen(this.el, 'click', event => {
            event.stopPropagation();
            this.direction = rotate[this.direction];
            ['asc', 'desc'].forEach(d => {
                if (d === this.direction) {
                    this.renderer.addClass(this.el, d);
                } else {
                    this.renderer.removeClass(this.el, d);
                }
            });
            this.sort.emit({ sortColumn: this.sortable, sortDirection: this.direction });
            return false;
        });
    }

    ngOnDestroy() {
        if (this.listener) {
            this.listener();
        }
    }

    reset() {
        ['asc', 'desc'].forEach(d => this.renderer.removeClass(this.el, d));
    }
}
