import { Action } from '@ngrx/store';
import { Pubmed } from '../../../../models/pubmed.interface';

export enum PubmedActionTypes {
    LoadPubmeds = '[Literature] Load Pubmeds',
    LoadPubmedsSucces = '[Literature] Load Pubmeds Success',
    LoadPubmedsFail = '[Literature] Load Pubmeds Fail',
    ClearPubmeds = '[Literature] Clear Pubmeds',
    SelectPubmed = '[Literature] Select Pubmed'
}

export class LoadPubmeds implements Action {
    readonly type = PubmedActionTypes.LoadPubmeds;

    constructor(public payload: { query: string }) {}
}

export class LoadPubmedsSucces implements Action {
    readonly type = PubmedActionTypes.LoadPubmedsSucces;

    constructor(public payload: { pubmeds: Pubmed[] }) {}
}

export class LoadPubmedsFail implements Action {
    readonly type = PubmedActionTypes.LoadPubmedsFail;

    constructor(public payload: any) {}
}

export class ClearPubmeds implements Action {
    readonly type = PubmedActionTypes.ClearPubmeds;
}

export class SelectPubmed implements Action {
    readonly type = PubmedActionTypes.SelectPubmed;
    constructor(public payload: { PubmedId: string | number }) {}
}

export type PubmedActions = LoadPubmeds | LoadPubmedsSucces | LoadPubmedsFail | ClearPubmeds | SelectPubmed;
