import { produce } from 'immer';
import * as fromActions from '../actions/models-outcomes.action';

export interface ModelsTagListState {
    entries: {
    };
}

export const initialState: ModelsTagListState = {
    entries: {
    }
};

export function reducer(state = initialState, action: fromActions.ModelsOutcomesAction): ModelsTagListState {
    switch (action.type) {
        case fromActions.MAP_MODELS_TAGLIST:
        case fromActions.UPDATE_TAGLIST:
        case fromActions.SHOW_ALL_OUTCOMES_SUCCESS: {
            const { payload } = action;
            const { tagList } = payload;
            return {
                ...state,
                entries: {
                    ...state.entries,
                    ...tagList,
                }
            };
        }
    }
    return state;
}
export const getModelTagListEntries = (state: ModelsTagListState) => state.entries;
