<div class="layout">
    <div class="layout__page">
        <alii-web-protocol-sidebar
            *ngIf="protocol"
            (eventBus)="handleEventBus($event)"
            [isEditAble]="isEditAble"
            [protocol]="protocol"
            [versionId]="versionId"
            [version]="version"
            [currentVersion]="currentVersion"
            [draftVersion]="draftVersion"
            [deprecatedVersions]="deprecatedVersions"
            [storedDraftVersions]="storedDraftVersions"
        >
        </alii-web-protocol-sidebar>
        <main id="content" class="layout__content " *ngIf="pane === 'content'" [ngClass]="{ waiting: loading }">
            <div class="page">
                <div class="page__content" id="pdf_area">
                    <div id="loadingContent" style="display: block;" *ngIf="loading">
                        <div class="spinner">
                            <div class="dot1"></div>
                            <div class="dot2"></div>
                        </div>
                    </div>

                    <ng-container>
                        <div class="embed-responsive embed-responsive-16by9" style="height: 85vh;">
                            <iframe
                                class="embed-responsive-item"
                                [src]="protocol.pdf_link | safe"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </ng-container>
                </div>
            </div>
        </main>
        <main id="content" class="layout__content" *ngIf="pane === 'settings'">
            <alii-web-protocol-settings [protocol]="protocol"
                                      (eventBus)="handleEventBus($event)"></alii-web-protocol-settings>
        </main>
    </div>
</div>
