import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromProfile from './profile.reducer';

export interface ProfileFeatureState {
    profile: fromProfile.ProfileState;
}

export const reducers: ActionReducerMap<ProfileFeatureState> = {
    profile: fromProfile.reducer
};

export const getProfileFeatureState = createFeatureSelector<ProfileFeatureState>('ProfileFeature');
