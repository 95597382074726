import { DashboardEffects } from './dashboard.effect';
import { DashboardSummaryEffects } from './dashboard-summary.effect';
import { DashboardExistingEffects } from './dashboard-existing.effect';
import { DashboardPatientEffects } from './dashboard-patient.effect';
import { DashboardTagEffects } from './dashboard-tag.effect';

export const effects: any[] = [
    DashboardEffects,
    DashboardSummaryEffects,
    DashboardExistingEffects,
    DashboardPatientEffects,
    DashboardTagEffects
];

export * from './dashboard.effect';
export * from './dashboard-summary.effect';
export * from './dashboard-existing.effect';
export * from './dashboard-patient.effect';
export * from './dashboard-tag.effect';
