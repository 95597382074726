import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromShopTemplateSet from '../reducers/shop-template-set.reducer';

export const getShopTemplateSetState = createSelector(
    fromFeature.getShopFeatureState,
    (state: fromFeature.ShopFeatureState) => {
        return state.shopTemplateSet;
    }
);

export const getShopTemplateSet = createSelector(
    getShopTemplateSetState,
    fromShopTemplateSet.getShopTemplateSet
);

export const getShopTemplateSetLoaded = createSelector(
    getShopTemplateSetState,
    fromShopTemplateSet.getShopTemplateSetLoaded
);

export const getShopTemplateSetLoading = createSelector(
    getShopTemplateSetState,
    fromShopTemplateSet.getShopTemplateSetLoading
);

export const updateShopTemplateSet = createSelector(
    getShopTemplateSetState,
    fromShopTemplateSet.updateShopTemplateSet
);

export const updateShopTemplateSetLoaded = createSelector(
    getShopTemplateSetState,
    fromShopTemplateSet.updateShopTemplateSetLoaded
);

export const updateShopTemplateSetLoading = createSelector(
    getShopTemplateSetState,
    fromShopTemplateSet.updateShopTemplateSetLoading
);
