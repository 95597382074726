<div class="modal-header">
    <h4 class="modal-title">Add Article manually</h4>
    <button type="button" class="close" aria-label="Close" (click)="onCancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container">
        <ng-form [formGroup]="form">
            <div class="form-row">
                <div class="col-10">
                    <div class="form-group">
                        <label for="title">Title</label>
                        <input type="text" formControlName="title" class="form-control" placeholder="Title" />
                    </div>
                    <div class="form-group">
                        <label for="url">URL</label>
                        <input type="text" formControlName="url" class="form-control" placeholder="URL" />
                    </div>
                    <div class="form-group">
                        <label for="author">Author</label>
                        <input type="text" formControlName="author" class="form-control" placeholder="Author" />
                    </div>
                    <div class="form-group">
                        <label for="publication">Publication</label>
                        <input
                            type="text"
                            formControlName="publication"
                            class="form-control"
                            placeholder="Publication"
                        />
                    </div>
                    <div class="form-group">
                        <label for="date">Publication Date</label>
                        <input type="text" formControlName="date" class="form-control" placeholder="Publication Date" />
                    </div>
                </div>
            </div>
            <button class="btn btn-info" type="button" (click)="onAddNonPubmedArticle()">Submit</button>
        </ng-form>
    </div>
</div>
