import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromActions from '../actions';
import * as fromService from '../../../../services';

@Injectable()
export class ModelsFindingsEffects {
    constructor(private actions$: Actions, private ParagraphsService: fromService.ParagraphsService) {}

    @Effect()
    AddFindingEffect$ = this.actions$.pipe(
        ofType(fromActions.ADD_FINDING),
        switchMap(action => {
            const {
                payload: { protocolId, parentId, type, title }
            } = action as any;

            return this.ParagraphsService.addFinding({ protocolId, parentId, type, title }).pipe(
                map(findings => new fromActions.AddFindingSucces({ parentId, findings })),
                catchError(error => of(new fromActions.AddFindingFail(error)))
            );
        })
    );
    @Effect()
    UpdateFindingEffect$ = this.actions$.pipe(
        ofType(fromActions.UPDATE_FINDING),
        switchMap(action => {
            const {
                payload: { protocolId, parentId, ppdId, options }
            } = action as any;

            return this.ParagraphsService.updateFinding({ protocolId, ppdId, options }).pipe(
                map(finding => new fromActions.UpdateFindingSucces({ parentId, ppdId, finding })),
                catchError(error => of(new fromActions.UpdateFindingFail(error)))
            );
        })
    );
    @Effect()
    DeleteFindingEffect$ = this.actions$.pipe(
        ofType(fromActions.DELETE_FINDING),
        switchMap(action => {
            const {
                payload: { protocolId, parentId, ppdId }
            } = action as any;

            return this.ParagraphsService.deleteFinding({ protocolId, ppdId }).pipe(
                map(() => new fromActions.DeleteFindingSucces({ parentId, ppdId })),
                catchError(error => of(new fromActions.DeleteFindingFail(error)))
            );
        })
    );

    @Effect()
    AddValueOptionEffect$ = this.actions$.pipe(
        ofType(fromActions.ADD_VALUE_OPTION),
        switchMap(action => {
            const {
                payload: { protocolId, modelId, findingId, title, value, type }
            } = action as any;

            return this.ParagraphsService.addValueOption({ protocolId, ppdId: findingId, title, value, type }).pipe(
                map(
                    response =>
                        new fromActions.AddValueOptionSucces({
                            protocolId,
                            modelId,
                            findingId,
                            valueOptionId: response.valueOptionId,
                            title,
                            value
                        })
                ),
                catchError(error => of(new fromActions.AddValueOptionFail(error)))
            );
        })
    );

    @Effect()
    UpdateValueOptionEffect$ = this.actions$.pipe(
        ofType(fromActions.UPDATE_VALUE_OPTION),
        switchMap(action => {
            const {
                payload: { protocolId, modelId, findingId, valueOptionId, title, value, type }
            } = action as any;

            return this.ParagraphsService.updateValueOption({
                protocolId,
                ppdId: findingId,
                valueOptionId,
                title,
                value,
                type
            }).pipe(
                map(
                    () =>
                        new fromActions.UpdateValueOptionSucces({
                            protocolId,
                            modelId,
                            findingId,
                            valueOptionId,
                            title,
                            value
                        })
                ),
                catchError(error => of(new fromActions.UpdateValueOptionFail(error)))
            );
        })
    );

    @Effect()
    RemoveValueOptionEffect$ = this.actions$.pipe(
        ofType(fromActions.REMOVE_VALUE_OPTION),
        switchMap(action => {
            const {
                payload: { protocolId, modelId, findingId, valueOptionId }
            } = action as any;

            return this.ParagraphsService.removeValueOption({
                protocolId,
                ppdId: findingId,
                valueOptionId
            }).pipe(
                map(
                    () =>
                        new fromActions.RemoveValueOptionSucces({
                            protocolId,
                            modelId,
                            findingId,
                            valueOptionId
                        })
                ),
                catchError(error => of(new fromActions.RemoveValueOptionFail(error)))
            );
        })
    );

    @Effect()
    AddSliderEffect$ = this.actions$.pipe(
        ofType(fromActions.ADD_SLIDER),
        switchMap(action => {
            const {
                payload: { protocolId, modelId, findingId, type, min, max, step }
            } = action as any;

            return this.ParagraphsService.addSlider({ protocolId, ppdId: findingId, type, min, max, step }).pipe(
                map(
                    response =>
                        new fromActions.AddSliderSucces({
                            protocolId,
                            modelId,
                            findingId,
                            valueOptionId: response.valueOptionId,
                            type,
                            min,
                            max,
                            step
                        })
                ),
                catchError(error => of(new fromActions.AddSliderFail(error)))
            );
        })
    );

    @Effect()
    UpdateSliderEffect$ = this.actions$.pipe(
        ofType(fromActions.UPDATE_SLIDER),
        switchMap(action => {
            const {
                payload: { protocolId, modelId, findingId, valueOptionId, type, min, max, step }
            } = action as any;

            return this.ParagraphsService.updateSlider({
                protocolId,
                ppdId: findingId,
                valueOptionId,
                type,
                min,
                max,
                step
            }).pipe(
                map(
                    () =>
                        new fromActions.UpdateSliderSucces({
                            protocolId,
                            modelId,
                            findingId,
                            valueOptionId,
                            type,
                            min,
                            max,
                            step
                        })
                ),
                catchError(error => of(new fromActions.UpdateSliderFail(error)))
            );
        })
    );

    @Effect()
    ModelSuccessEffect$ = this.actions$.pipe(
        ofType(
            fromActions.ADD_VALUE_OPTION_SUCCESS,
            fromActions.REMOVE_VALUE_OPTION_SUCCESS,
            fromActions.ADD_SLIDER_SUCCESS,
            fromActions.UPDATE_SLIDER_SUCCESS
        ),
        mergeMap(action => {
            const {
                payload: { protocolId }
            } = action as any;
            return [
                // new protocolActions.LoadProtocol({ protocolId, versionId: null, version: 'Draft' })
            ];
        })
    );
}
