<div class="modal-header">
    <h4 class="modal-title">{{ article.label }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container">
        <div class="row">
            <h5 class="wysiwyg">{{ article.title }}</h5>
            <hr class="w-100" />
        </div>

        <div class="row">
            <h3 class="wysiwyg">Abstract</h3>
            <hr class="w-100" />
        </div>
        <div class="row"><p class="text-justify" [innerHTML]="sanitize(article.intro)"></p></div>
        <hr class="w-100" />

        <div class="row">
            <a target="_blank" [href]="article.url">Open</a>
            <hr class="w-100" />
        </div>

        <div class="row">
            <h3 class="wysiwyg">Evidence table</h3>
            <hr class="w-100" />
        </div>
        <div class="row">
            <ng-container *ngFor="let review of article.reviews; trackBy: trackByFn">
                    <ng-container *ngIf="!review.hide">
                    <div class="wysiwyg" *ngIf="review.subType">
                        <h4 *ngIf="review.subType === 'addReview'">
                            {{'LITERATURE.REVIEWHEADER' | translate}}
                        </h4>
                        <h4 *ngIf="review.subType === 'addGrade'">
                            {{'LITERATURE.REVIEWGRADEHEADER' | translate}}
                        </h4>
                        <h4 *ngIf="review.subType != 'addReview' && review.subType != 'addGrade'">
                            {{review.subType}}
                        </h4>
                    </div>
                    <div class="wysiwyg" *ngIf="!review.subType">
                        <h4>{{review.type}}</h4>
                    </div>
                    <ng-container *ngIf="review.rowData">
                        <ag-grid-angular
                            #agGrid
                            style="width: 100%"
                            class="ag-theme-alpine"
                            [rowData]="review.rowData"
                            [columnDefs]="review.columnDefs"
                            domLayout='autoHeight'
                            [frameworkComponents]="frameworkComponents"
                            (cellEditingStopped)="onCellEditingStopped($event)" >
                        </ag-grid-angular>
                    </ng-container>
                    <ng-container *ngIf="!review.rowData">
                        <table class="table">
                            <tbody>
                                <tr *ngFor="let row of summaryOfReviews(review)">
                                    <td class="text-sentence-mix">{{ row.key.replace(regex, ' ') }}</td>
                                    <td [innerHTML]="sanitize(row.value)"></td>
                                </tr>
                            </tbody>
                            </table>
                    </ng-container>
                </ng-container>
            </ng-container>
       </div>
    </div>
</div>
<div class="modal-footer"></div>
