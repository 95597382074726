<div class="modal-header">
    <h4 class="modal-title text-capitalize">Upload file</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container">
        <div class="row">
            <div *ngIf="uploadResponse.error">{{ uploadResponse.error.message }}</div>
            <div *ngIf="uploadResponse.status === 'uploadResponse.error'">{{ uploadResponse.message }}</div>
            <div *ngIf="uploadResponse.status === 'progress'">
                <div
                    role="progressbar"
                    [style.width.%]="uploadResponse.message"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                >
                    {{ uploadResponse.message }}%
                </div>
            </div>

            <form [formGroup]="form" (ngSubmit)="onSubmit(type)">
                <input type="file" name="file" (change)="onFileChange($event)" />
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-info text-capitalize" type="submit" (click)="onSubmit(type)">Upload</button>
</div>
