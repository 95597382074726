import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as fromService from '../../../../services';

import * as fromStore from './../../store';
import { DashboardDetail } from '@alii-web/models/dashboard-detail.model';
import { Router } from '@angular/router';
import { DashboardExisting } from '@alii-web/models/dashboard-existing.model';
import { ToastrService } from 'ngx-toastr';

const cn = 'DashboardManageComponent';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-dashboard-manage',
    templateUrl: './dashboard-manage.component.html'
})
export class DashboardManageComponent implements OnInit {
    id: string;
    dashboards$: Observable<DashboardExisting[]>;
    entities$: Observable<any>;
    createDashboardForm: FormGroup;

    constructor(
        private store: Store<fromStore.DashboardState>,
        private fb: FormBuilder,
        private DashboardService: fromService.DashboardService,
        private router: Router,
        private toaster: ToastrService
    ) {}

    ngOnInit() {
        this.dashboards$ = this.store.select(fromStore.getExistingDashboards);
        this.entities$ = this.store.select(fromStore.getExistingDashboardsEntities);

        this.store.dispatch(new fromStore.LoadDashboardSummary());

        this.createDashboardForm = this.fb.group({
            title: ['', Validators.required]
        });
    }

    handleSubmit() {
        if (this.createDashboardForm.valid) {
            const data = this.createDashboardForm.value;
            this.DashboardService.createDashboard(data.title).subscribe(response => {
                if (response && response.dashboard_id) {
                    this.store.dispatch(new fromStore.LoadDashboardSummary());
                    this.router.navigate(['dashboard', response.dashboard_id, 'edit']);
                    this.toaster.success('', 'Dashboard created');
                } else {
                    console.warn(`${cn} handleSubmit() response${response ? '.dashboard' : ''} is undefined`);
                    this.toaster.error('Error', 'Failed to create dashboard');
                }
            });
        }
    }

    handleDashboardClicked(dashboard: DashboardDetail) {
        this.store.dispatch(new fromStore.LoadDashboardForm(dashboard));
        // this.router.navigate(['dashboard', dashboard.id, 'edit']);
    }

    trackByFn = (index, item) => item.id || index;
}
