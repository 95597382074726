import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FlowchartCard } from '../../../../../models/flowchart.interface';
import { BrowserService } from '../../../../../services';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-flowchart-edit-modal',
    templateUrl: './flowchart-edit.component.html',
    styleUrls: ['./flowchart-edit.component.scss']
})
export class FlowchartEditComponent implements OnInit {
    form: FormGroup;
    card: FlowchartCard;
    paragraphs: any[];
    contentOptions: any;

    blockType = 'decision';

    countOfArrow: number;
    isParallel: boolean;

    imgThumbnailWidth = 'auto';

    constructor(public activeModal: NgbActiveModal, private browserService: BrowserService, private fb: FormBuilder) {}

    private getCountOfArrow() {
        if (!this.card.yesArrowId) {
            return 0;
        }
        if (this.card.yesArrowId && !this.card.noArrowId) {
            return 1;
        }
        if (this.card.yesArrowId && this.card.noArrowId) {
            return 2;
        }
    }

    private getCardContentType(card) {
        if (card.contentLink) {
            return 'protocolContent';
        }
        return 'paragraphContent';
    }

    private getYesArrowContentType(card) {
        if (card.yesContentLink) {
            return 'protocolContent';
        }
        if (card.yesArrowId) {
            return 'paragraphContent';
        }
        return 'newContent';
    }

    private getNoArrowContentType(card) {
        if (card.noContentLink) {
            return 'protocolContent';
        }
        if (card.noArrowId) {
            return 'paragraphContent';
        }
        return 'newContent';
    }

    private updateContentTitle(title) {
        this.form.get('title').setValue(title);
    }

    private updateYesArrowTitle(title) {
        this.form.get('yesArrowTitle').setValue(title);
    }

    private updateNoArrowTitle(title) {
        this.form.get('noArrowTitle').setValue(title);
    }

    private createOptionsByCountOfArrow(value) {
        value.yesArrowTitle = this._cleanTitle(value.yesArrowTitle);
        value.noArrowTitle = this._cleanTitle(value.noArrowTitle);

        const {
            yesArrowContentType,
            yesArrowId,
            yesContentLink,
            yesArrowTitle,
            yesArrowNoSteps,
            yesArrowNewBlock,
            noArrowContentType,
            noArrowId,
            noContentLink,
            noArrowTitle,
            noArrowNoSteps,
            noArrowNewBlock
        } = value;
        const yesArrow = {
            ...(yesArrowContentType === 'newContent' ? { yesArrowId: 'new' } : {}),
            ...(yesArrowContentType === 'paragraphContent' ? { yesArrowId } : {}),
            ...(yesArrowContentType === 'protocolContent' ? { yesContentLink } : {}),
            yesArrowTitle,
            yesArrowNoSteps,
            yesArrowNewBlock
        };
        const noArrow = {
            ...(noArrowContentType === 'newContent' ? { noArrowId: 'new' } : {}),
            ...(noArrowContentType === 'paragraphContent' ? { noArrowId } : {}),
            ...(noArrowContentType === 'protocolContent' ? { noContentLink } : {}),
            noArrowTitle,
            noArrowNoSteps,
            noArrowNewBlock
        };
        switch (this.countOfArrow) {
            case 0:
                // return {};
                return { yesArrowId: null, noArrowId: null };
            case 1:
                return { ...yesArrow, noArrowId: null };
            case 2:
                return { ...yesArrow, ...noArrow };
            default:
                return {};
        }
    }

    ngOnInit() {
        if (this.browserService.isIE11) {
            this.imgThumbnailWidth = '246px';
        }

        this.countOfArrow = this.getCountOfArrow();
        this.isParallel = this.card.parallel ? this.card.parallel : false;

        this.blockType = this.getCardBlockType(this.countOfArrow, this.isParallel);

        this.form = this.fb.group({
            cardContentType: [this.getCardContentType(this.card)],
            yesArrowContentType: [this.getYesArrowContentType(this.card)],
            noArrowContentType: [this.getNoArrowContentType(this.card)],

            title: [this.card.title, Validators.required],
            ppdId: [this.card.ppdId],
            contentLink: [this.card.contentLink].toString(),
            shortRightArrow: [this.card.shortRightArrow],
            invertArrows: [this.card.invertArrows],
            borderless: [this.card.borderless],
            hideSteps: [this.card.hideSteps],
            parallel: [this.isParallel],
            yesArrowId: [this.card.yesArrowId].toString(),
            yesArrowTitle: [this.card.yesArrowTitle],
            yesArrowNoSteps: [this.card.yesArrowNoSteps],
            yesArrowNewBlock: [this.card.yesArrowNewBlock],
            yesContentLink: [this.card.yesContentLink].toString(),
            noArrowId: [this.card.noArrowId].toString(),
            noArrowTitle: [this.card.noArrowTitle],
            noArrowNoSteps: [this.card.noArrowNoSteps],
            noArrowNewBlock: [this.card.noArrowNewBlock],
            noContentLink: [this.card.noContentLink].toString()
        });
    }

    getCardBlockType(countOfArrow, isParallel) {
        switch (countOfArrow) {
            case 0:
                return 'endpoint';
            case 1:
                return 'step';
            case 2:
                return isParallel ? 'parallel' : 'decision';
            default:
                return '';
        }
    }

    onChangeYesArrow(event) {
        this.updateYesArrowTitle(event.target.selectedOptions[0].innerText);
    }

    onChangeNoArrow(event) {
        this.updateNoArrowTitle(event.target.selectedOptions[0].innerText);
    }

    onChangeContentLink(event) {
        this.updateContentTitle(event.target.selectedOptions[0].innerText);
    }

    onChangeContentParagraph(event) {
        this.updateContentTitle(event.target.selectedOptions[0].innerText);
    }

    onClickToggleBlockType(blockType: string, countArrow: number, isParallel: boolean) {
        const ctrlYesArrowTitle = this.form.get('yesArrowTitle');
        const ctrlNoArrowTitle = this.form.get('noArrowTitle');
        this.blockType = blockType;
        this.countOfArrow = countArrow;
        this.isParallel = isParallel;
        ctrlYesArrowTitle.setValue(null);
        ctrlYesArrowTitle.setValidators(null);
        ctrlNoArrowTitle.setValue(null);
        ctrlNoArrowTitle.setValidators(null);
        if (countArrow) {
            ctrlYesArrowTitle.setValidators([Validators.required]);
            if (countArrow > 1) {
                ctrlNoArrowTitle.setValidators([Validators.required]);
            }
        }
    }

    _cleanTitle(title: string): string {
        // Strip out leading and trailing whitespace and prefixed '(ppdId)' from the title.
        return title ? title.replace(/ \(\d+\)/g, '').replace(/^\s+|\s+$/g, '') : '';
    }

    handleSubmit() {
        if (this.form.valid) {
            const { value } = this.form;

            value.title = this._cleanTitle(value.title);

            const {
                ppdId,
                contentLink,
                cardContentType,
                title,
                shortRightArrow,
                invertArrows,
                borderless,
                hideSteps
            } = value;
            const options = this.createOptionsByCountOfArrow(value);
            const data = {
                id: this.card.id,
                title,
                ppdId,
                contentLink,
                shortRightArrow,
                invertArrows,
                borderless,
                hideSteps,
                parallel: this.isParallel,
                ...(cardContentType === 'paragraphContent'
                    ? { ppdId, contentLink: null }
                    : { contentLink, ppdId: null }),
                ...options
            };
            this.activeModal.close(data);
        }
    }

    handleCancel() {
        this.activeModal.dismiss({});
    }

    trackByFn = (index, item) => item.id || index;
}
