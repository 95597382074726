<div class="modal-header">
    <h4 class="title">{{title | titlecase}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="onCancel()"><span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="container">
        <ng-container>
            <div class="row">
                <div class="col-md">
                    <ng-form [formGroup]="form">
                        <div class="steps" *ngFor="let step of steps; let indStep = index">
                            <div *ngIf="!(settings && indStep === 2)" class="step"
                                 [class.disabled]="title === 'activeer' && indStep > currentStep">
                                <div class="title">{{step.title}}</div>
                                <div class="text">{{step.text}}</div>
                                <div *ngFor="let action of step.actions; let indAction = index" class="actions">
                                    <div class="action form-group">
                                        <div *ngIf="action.type === 'select'">
                                            <select class="custom-select"
                                                    (change)="onChangeAction(indStep, indAction, $event.target.value)">
                                                <option selected>-- {{'PLEASE_SELECT' | translate}}--</option>
                                                <option *ngFor="let option of action.options"
                                                    [value]="option.value">
                                                    {{option.label}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="action.type === 'selectTeam'">
                                            <select class="custom-select"
                                                    (change)="onChangeTeam(indStep, indAction, $event.target.value)">
                                                <option *ngIf="!selectedTeam" selected>-- {{'PLEASE_SELECT' | translate}}--</option>
                                                <option *ngFor="let option of action.options"
                                                        [id]="option.teamId"
                                                        >{{option.label}}</option>
                                            </select>
                                            <div class="accordion">
                                                <div    
                                                    [ngClass]="{ '-is-open': selectedTeam?.form}"
                                                    class="accordion__item accordion__item-index"
                                                    id="sidebar-version-item"
                                                >
                                                    <div *ngIf="selectedTeam?.form" class="accordion__content">
                                                        <div class="accordion__content-body">
                                                            <p>{{selectedTeam.text}}</p>
                                                            <form [formGroup]="formNewTeam" class="new-team">
                                                                <ng-container *ngFor="let control of selectedTeam.form">
                                                                    <div *ngIf="control.type === 'input'"
                                                                         class="form-group">
                                                                        <label [for]="control.name">{{control.label}}
                                                                            :</label><br/>
                                                                        <input class="form-control" [id]="control.name"
                                                                               type="text"
                                                                               [formControlName]="control.name"/>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngFor="let control of selectedTeam.form">
                                                                    <button *ngIf="control.type === 'button'"
                                                                            [disabled]="formNewTeam.invalid || loadingNewTeam"
                                                                            class="btn btn-primary"
                                                                            (click)="submitNewTeam(indStep, indAction)">
                                                                    <span *ngIf="loadingNewTeam"
                                                                          class="spinner-grow spinner-grow-sm"
                                                                          role="status"
                                                                          aria-hidden="true"></span>
                                                                        {{control.label}}
                                                                    </button>
                                                                </ng-container>
                                                                <button
                                                                    class="btn btn-primary"
                                                                    (click)="cancelNewTeam()">Cancel
                                                                </button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="action.type === 'button'">
                                            <button
                                                class="btn btn-primary"
                                                (click)="onAction(indStep, indAction)">{{action.label}}
                                            </button>
                                        </div>
                                        <div *ngIf="action.type === 'submit'">
                                            <button
                                                class="btn btn-primary" type="submit"
                                                (click)="onAction(indStep, indAction)">{{action.label}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-form>
                </div>
            </div>
        </ng-container>
    </div>
</div>


