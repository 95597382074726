import { Injectable } from "@angular/core";

export interface Color {
    name: string;
    hex: string;
  }

@Injectable()
export class ThemingService {
    palette: Color[] = [];
    constructor() {}

    getTheme() {
        const theme = localStorage.getItem('theme') ?? 'alii';
        return theme
    }

    getCurrentPalette() {
        let theme = this.getTheme()
        return this.getPalette(theme);
      }

    getPalette(theme) {
        if (theme == 'rapp') {
            this.palette = [
                {   name: '--primary-color',
                    hex:  '#58585a'},
                {   name: '--secondary-color',
                    hex:  '#9e9ac9'},
                {   name: '--primary-color-light',
                    hex:  '#b7c1db'},
            ]
        } else {
            this.palette = [
                {   name: '--primary-color',
                    hex:  '#7fc5ba'},
                {   name: '--secondary-color',
                    hex:  '#e7511f'},
                {   name: '--primary-color-light',
                    hex:  'rgba(127,197,186,.5)'},
            ]
        }
        return this.palette
    }

    setTheme(theme: string) {
        if(localStorage.getItem('theme') !== theme) {
            localStorage.setItem('theme', theme)
        }
        this.getPalette(theme)
        this.palette.forEach(color => {
            document.documentElement.style.setProperty(
            `${color.name}`,
            color.hex
            );
        });
    }
}