import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromSearch from './search.reducer';

export interface SearchFeatureState {
    search: fromSearch.SearchState;
}

export const reducers: ActionReducerMap<SearchFeatureState> = {
    search: fromSearch.reducer
};

export const getSearchFeatureState = createFeatureSelector<SearchFeatureState>('SearchFeature');
