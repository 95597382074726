<form [formGroup]="profileForm" (ngSubmit)="handleSubmit()" name="main" class="card_main">
    <h1 class="sectionTitle__title">Update Profile</h1>
    <div class="card col-sm-12">
        <br />
        <table class="table table-borderless" style="border-bottom: 0">
            <tr>
                <td>First Name</td>
                <td><input type="text" formControlName="first_name" class="form-control" /></td>
            </tr>
            <tr>
                <td>Prefix</td>
                <td><input type="text" formControlName="prefix" class="form-control" /></td>
            </tr>
            <tr>
                <td>Last Name</td>
                <td><input type="text" formControlName="last_name" class="form-control" /></td>
            </tr>
            <tr>
                <td>E-mail</td>
                <td><input type="text" formControlName="email" class="form-control" /></td>
            </tr>
            <tr>
                <td>Telefoonnummer</td>
                <td><input type="text" formControlName="telephone" class="form-control" /></td>
            </tr>
            <ng-container *ngIf="metaAvailable">
                <ng-container *ngFor="let meta of profile.userCard.meta">
                    <tr *ngIf="meta.field !== 'hospital'; else hospitalField">
                        <td>{{ meta.label | titlecase }}</td>
                        <td><input type="text" [formControlName]="meta.field" class="form-control" /></td>
                    </tr>
                    <ng-template #hospitalField>
                        <tr>
                            <td>{{ meta.label | titlecase }}</td>
                            <td>
                                <select class="form-control" [formControlName]="meta.field">
                                    <option
                                        *ngFor="let option of profile.participating_hospitals"
                                        [value]="option.hospitalId"
                                    >
                                        {{ option.name }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                    </ng-template>
                </ng-container>
            </ng-container>
            <tr>
                <td>Default dashboard &nbsp;</td>
                <td>
                    <select
                        formControlName="dashboard"
                        class="form-control"
                        [(ngModel)]="dashboards && dashboards.dashboard_id"
                    >
                        <option
                            *ngFor="let dashboard of dashboards?.dashboards; trackBy: trackByFn"
                            [value]="dashboard.id"
                        >
                            {{ dashboard.name }}
                        </option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>Avatar &nbsp;</td>
                <td>
                    <a (click)="onClickAddAvatar()">
                        <img [src]="avatarUrl" style="height: 100px" /><br />
                        Update avatar
                    </a>
                </td>
            </tr>
        </table>

        <br />
        <div class="col-sm-4" style="padding-left: 0; margin-bottom: 20px">
            <button type="button" (click)="handleSubmit()" class="btn btn-info" [disabled]="!profileForm.valid">
                Save profile
            </button>
        </div>

        <br />
    </div>
</form>

<form [formGroup]="updatePasswordForm" (ngSubmit)="handleUpdatePassword()" name="main" class="card_main">
    <br />
    <h3 class="sectionTitle__title">Update password</h3>
    <div class="card col-sm-12">
        <br />
        <table class="table table-borderless" style="border-bottom: 0">
            <tr>
                <td>New password (8 or more characters)</td>
                <td><input type="password" formControlName="password" class="form-control" minlength="8" /></td>
            </tr>
        </table>

        <br />
        <div class="col-sm-4" style="padding-left: 0; margin-bottom: 20px">
            <button
                type="button"
                (click)="handleUpdatePassword()"
                class="btn btn-info"
                [disabled]="!updatePasswordForm.valid"
            >
                Update password
            </button>
        </div>

        <br />
    </div>
</form>
