import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import * as fromContainers from './containers';

const routes: Routes = [

    {
        path: 'organization',
        component: fromContainers.WhoIsWhoComponent
    },
    {
        path: 'organization/teams/:id',
        component: fromContainers.UserGroupEditComponent
    },
    {
        path: 'organization/teams/:id/users',
        component: fromContainers.OrganisationComponent
    },
    {
        path: 'organization/teams/:id/users/add-user',
        component: fromContainers.AddUserComponent
    },
    {
        path: 'organization/groups',
        component: fromContainers.UserGroupComponent
    },
    {
        path: 'organization/groups/:id',
        component: fromContainers.UserGroupEditComponent
    },
    // old url just here for backwards compatibility
    {
        path: 'community',
        component: fromContainers.WhoIsWhoComponent
    },
    // end of old url
    {
        path: 'messages',
        component: fromContainers.TimelineComponent
    },
    {
        path: 'calendar',
        component: fromContainers.CalendarComponent
    },
    {
        path: 'calendar/manage/:id',
        component: fromContainers.ManageCalendarComponent
    },
    {
        path: 'calendar/manage',
        component: fromContainers.ManageCalendarComponent
    },
    {
        path: 'messages/manage/:id',
        component: fromContainers.ManageMessagesComponent
    },
    {
        path: 'messages/manage',
        component: fromContainers.ManageMessagesComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OrganisationRoutingModule {}
