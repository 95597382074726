import { Component, ChangeDetectionStrategy, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Paragraph } from '../../../../models/paragraph.interface';
import { CompareParagraphsModalComponent } from '../../entry-components';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-web-paragraph-status-label',
    templateUrl: './paragraph-status-label.component.html',
    styleUrls: ['./paragraph-status-label.component.scss']
})
export class ParagraphStatusLabelComponent implements OnInit {
    @Input() paragraph: Paragraph;

    @Output()
    eventBus: EventEmitter<any> = new EventEmitter<any>();

    // Sanity check whether there are indeed differences between current and previous despite when 'updated' is true,
    // meaning the update button will NOT be shown if there actually are no diffs.
    hasReallyChanged: boolean;
    hasReallyChangedComparedToDraft: boolean;

    constructor(private modalService: NgbModal) {
    }

    ngOnInit() {
        this.hasReallyChanged =
            this.paragraph['protocol_paragraph.title'] !== this.paragraph.previous_title ||
            this.paragraph['protocol_paragraph.text'] !== this.paragraph.previous_text;
        this.hasReallyChangedComparedToDraft = 
            this.paragraph['protocol_paragraph.title'] !== this.paragraph.previousDraft_title ||
            this.paragraph['protocol_paragraph.text'] !== this.paragraph.previousDraft_text;
    }

    openCompareParagraphsModal() {
        this.modalService.dismissAll();

        const modalRef = this.modalService.open(CompareParagraphsModalComponent, {
            windowClass: 'compare-paragraphs-modal'
        });
        modalRef.componentInstance.data = {
            level: +this.paragraph.level,
            paragraphs: {
                previous: {
                    title: this.paragraph.previous_title,
                    text: this.paragraph.previous_text
                },
                current: {
                    title: this.paragraph['protocol_paragraph.title'],
                    text: this.paragraph['protocol_paragraph.text']
                }
            }
        };
        modalRef.result.then(
            () => {
            },
            () => {
            }
        );
    }

    openCompareWithPreviousDraftParagraphsModal() {
        this.modalService.dismissAll();

        const modalRef = this.modalService.open(CompareParagraphsModalComponent, {
            windowClass: 'compare-paragraphs-modal'
        });
        modalRef.componentInstance.data = {
            level: +this.paragraph.level,
            paragraphs: {
                previous: {
                    title: this.paragraph.previousDraft_title,
                    text: this.paragraph.previousDraft_text
                },
                current: {
                    title: this.paragraph['protocol_paragraph.title'],
                    text: this.paragraph['protocol_paragraph.text']
                }
            }
        };
        modalRef.result.then(
            () => {
            },
            () => {
            }
        );
    }

    openUpdatedModelModal() {
        this.eventBus.emit({
            type: 'openUpdatedModel',
            payload: {
                paragraph: this.paragraph,
                language: 'nl'
            }
        });
    }
}
