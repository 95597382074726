import { Action } from '@ngrx/store';
import { DashboardCategory } from '@alii-web/models/dashboard-category.model';

export const LOAD_DASHBOARD_CATEGORIES = '[Dashboard] Load Dashboard Categories';
export const LOAD_DASHBOARD_CATEGORIES_FAIL = '[Dashboard] Load Dashboard Categories Fail';
export const LOAD_DASHBOARD_CATEGORIES_SUCCESS = '[Dashboard] Load Dashboard Categories Success';

export class LoadDashboardCategories implements Action {
    readonly type = LOAD_DASHBOARD_CATEGORIES;
    constructor(public dashboardId: string) {}
}

export class LoadDashboardCategoriesFail implements Action {
    readonly type = LOAD_DASHBOARD_CATEGORIES_FAIL;
    constructor(public payload: any) {}
}

export class LoadDashboardCategoriesSucces implements Action {
    readonly type = LOAD_DASHBOARD_CATEGORIES_SUCCESS;
    constructor(public payload: DashboardCategory[]) {}
}

export const CREATE_CATEGORY = '[Dashboard] Create Category';
export const CREATE_CATEGORY_FAIL = '[Dashboard] Create Category Fail';
export const CREATE_CATEGORY_SUCCESS = '[Dashboard] Create Category Success';

export class CreateCategory implements Action {
    readonly type = CREATE_CATEGORY;
    constructor(public payload: { dashboardId: string; title: string }) {}
}

export class CreateCategoryFail implements Action {
    readonly type = CREATE_CATEGORY_FAIL;
    constructor(public payload: any) {}
}

export class CreateCategorySucces implements Action {
    readonly type = CREATE_CATEGORY_SUCCESS;
    constructor(public payload: any) {}
}

export const ADD_DASHBOARD_CATEGORY = '[Dashboard] Add Dashboard Category';
export const ADD_DASHBOARD_CATEGORY_FAIL = '[Dashboard] Add Dashboard Category Fail';
export const ADD_DASHBOARD_CATEGORY_SUCCESS = '[Dashboard] Add Dashboard Category Success';

export class AddDashboardCategory implements Action {
    readonly type = ADD_DASHBOARD_CATEGORY;
    constructor(public payload: { dashboardId: string; tagId: string }) {}
}

export class AddDashboardCategoryFail implements Action {
    readonly type = ADD_DASHBOARD_CATEGORY_FAIL;
    constructor(public payload: any) {}
}

export class AddDashboardCategorySucces implements Action {
    readonly type = ADD_DASHBOARD_CATEGORY_SUCCESS;
    constructor(public payload: any) {}
}

export const REMOVE_DASHBOARD_CATEGORY = '[Dashboard] Remove Dashboard Category';
export const REMOVE_DASHBOARD_CATEGORY_FAIL = '[Dashboard] Remove Dashboard Category Fail';
export const REMOVE_DASHBOARD_CATEGORY_SUCCESS = '[Dashboard] Remove Dashboard Category Success';

export class RemoveDashboardCategory implements Action {
    readonly type = REMOVE_DASHBOARD_CATEGORY;
    constructor(public payload: { dashboardId: string; tagId: string }) {}
}

export class RemoveDashboardCategoryFail implements Action {
    readonly type = REMOVE_DASHBOARD_CATEGORY_FAIL;
    constructor(public payload: any) {}
}

export class RemoveDashboardCategorySucces implements Action {
    readonly type = REMOVE_DASHBOARD_CATEGORY_SUCCESS;
    constructor(public payload: any) {}
}

export type DashboardCategoriesAction =
    | LoadDashboardCategories
    | LoadDashboardCategoriesFail
    | LoadDashboardCategoriesSucces
    | CreateCategory
    | CreateCategoryFail
    | CreateCategorySucces
    | AddDashboardCategory
    | AddDashboardCategoryFail
    | AddDashboardCategorySucces
    | RemoveDashboardCategory
    | RemoveDashboardCategoryFail
    | RemoveDashboardCategorySucces;
